import moment from 'moment';
import React from 'react';
import styledComponent from '../../utils/styledComponent';
import SingleStopTable from './SingleStopTable';
import fridgeIcon from '../../atoms/icons/Fridge.png';
import wholesaleIcon from '../../atoms/icons/Retail.png';
import Sun from '../../atoms/icons/Sun';
import Moon from '../../atoms/icons/Moon';

const QRCode = require('qrcode.react');

const StyledDiv = styledComponent('div', {
  '& .header': {
    marginTop: '40px',
    fontSize: '26px',
    '& .line': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    '& .driverStop': {
      opacity: '0.54',
    },
    '& .driverRoute': {
      fontWeight: 'bold',
      fontSize: '28px',
    },
    '& .containerType': {
      opacity: '0.54',
    },
  },
  '& .headerTable': {
    width: '100%',
    marginTop: '8px',
    borderCollapse: 'collapse',
    '& tr': {
      background: '#ffffff',
    },
    '& td': {
      fontSize: '24px',
      fontWeight: '500',
      width: '25%',
      border: '3px solid #000',
      textAlign: 'left',
      padding: '16px 4px 16px 16px',
      '&:nth-child(3), &:nth-child(4)': {
        fontWeight: '900',
      },
    },
    '& .dayTimeCell': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& svg': {
        width: '32px',
        height: '32px',
        marginRight: '12px',
      },
    },
  },
  '& .main': {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    padding: '20px 0',
    '& .packingSlipIndex': {
      display: 'flex',
      alignItems: 'flexStart',
      fontSize: '120px',
      fontWeight: 'bold',
      letterSpacing: '10px',
      textAlign: 'left',
      marginBottom: '16px',
    },
    '& .qrCodeWrapper': {
      width: '180px',
    },
    '& .additionalInfoWrapper': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .last': {
        justifyContent: 'flex-end',
        marginTop: '8px',
      },
    },
    '& .bestBeforeText': {
      fontWeight: 'bold',
      fontSize: '24px',
    },
  },
  '& .description': {
    overflowWrap: 'anywhere',
    padding: '0 40px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .locationTypeWrapper img': {
      width: '32px',
      marginRight: '12px',
      opacity: '0.7',
    },
    '& .locationTypeSubWrapper': {
      flexDirection: 'column',
      display: 'flex',
    },
    '& .locationId': {
      fontSize: '30px',
      fontWeight: 'bold',
    },
    '& .deliveryNotes': {
      fontSize: '20px',
    },
  },
  '& .dotted': {
    width: '50%',
    height: '1px',
    borderBottom: '1px dotted #000000',
    margin: '0 auto',
  },
});

function renderSkuTable(skus, all_skus) {
  const skuList = Object.entries(skus).map(([sku, info]) => {
    // Access the shelfLife from all_skus using the SKU as the key
    const foundSku = all_skus.find((option) => option.id === sku);
    const shelfLife = foundSku.shelfLife || 0;

    const expirationDate = moment(info.productionDate).add(
      shelfLife + 2,
      'days',
    );
    const formattedExpirationDate = expirationDate.format('MM-DD-YYYY');

    return <div key={sku}>Best Before: {formattedExpirationDate}</div>;
  });

  return <div>{skuList}</div>;
}

/* eslint-disable react/prop-types */
const RouteStop = ({
  routeStop,
  layout,
  items,
  fridgeLocationState,
  orderRegion,
}) => {
  const {
    location,
    date,
    subPackingSlipIndex,
    dropOffDate,
    type,
    qrCode,
    uniqueId,
    driver,
    stop,
    costco,
    containerType,
    data_ids,
    locationType,
    deliverySpecialNotes,
    forks,
    spoons,
  } = routeStop;

  let formattedDropOffDate = '';
  if (dropOffDate) {
    formattedDropOffDate = moment(dropOffDate).format('MMMM Do');
  } else if (date) {
    formattedDropOffDate = moment(date).format('MMMM Do').toUpperCase();
  }

  let formattedDayOfWeek = 'Date not specified';
  if (dropOffDate) {
    formattedDayOfWeek = moment(dropOffDate).format('dddd');
  } else if (date) {
    formattedDayOfWeek = moment(date).format('dddd');
  }

  const formattedSubPackingSlipIndex =
    subPackingSlipIndex &&
    `${subPackingSlipIndex}/${routeStop.totalSubPackingSlips}`;
  const isLastPackingSlipIndex =
    subPackingSlipIndex === routeStop.totalSubPackingSlips;

  const findRouteAmOrPm = (route) => {
    // Check if route is a valid string
    if (!route || typeof route !== 'string') {
      return '—';
    }

    const parts = route.split('_');
    if (parts.includes('am')) {
      return 'AM';
    }
    if (parts.includes('pm')) {
      return 'PM';
    }
    return '—';
  };

  const findRouteCity = (route) => {
    if (typeof route !== 'string') {
      return '—';
    }

    const parts = route.split('_');
    if (!Array.isArray(parts) || parts.length < 2) {
      return '—';
    }

    return parts[1].toUpperCase();
  };

  const locationSplit = location?.split('&')[0];
  const businessRequestedLocation = locationSplit.includes(
    'IL_HMS_OHARE__1000_BALMORAL_AVE',
  );
  const routeDayTime = findRouteAmOrPm(driver); // AM or PM
  return (
    <StyledDiv
      data-test="single-stops-table"
      key={`${location}${date}${subPackingSlipIndex}${dropOffDate}`}
    >
      <div className="dotted" />
      <div className="header">
        <div className="line">
          <div className="date">{formattedDropOffDate}</div>
          <div>
            {stop !== 'NOT_NEEDED' && <div className="driverStop">{stop}</div>}
          </div>
        </div>
        <div className="line">
          {driver !== 'NOT_NEEDED' ? (
            <div className="driverRoute">{driver}</div>
          ) : (
            <div />
          )}
          <div id="containerType" className="containerType">
            {containerType || 'Box'}
          </div>
        </div>
      </div>

      <table className="headerTable">
        <tbody>
          <tr>
            <td>
              {type === 'ALLOCATION' && (
                <div>{fridgeLocationState[locationSplit]}</div>
              )}
              {type === 'ORDER' && <div>{orderRegion}</div>}
            </td>
            <td>{findRouteCity(driver)}</td>
            <td>{formattedDayOfWeek}</td>
            <td>
              <div className="dayTimeCell">
                {routeDayTime === 'AM' && <Sun />}
                {routeDayTime === 'PM' && <Moon />}
                <span>{routeDayTime}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="main">
        <div className="packingSlipIndex">{formattedSubPackingSlipIndex}</div>
        {location !== 'Summary' && (
          <div className="qrCodeWrapper">
            {qrCode !== undefined && qrCode !== null ? (
              <QRCode size={180} value={qrCode} data-testid={qrCode} />
            ) : (
              <QRCode size={180} value={uniqueId} data-testid={uniqueId} />
            )}
          </div>
        )}
        <div className="additionalInfoWrapper">
          <div className="line last">
            {isLastPackingSlipIndex && stop === '01' && (
              <div className="star" />
            )}
          </div>
          <div className="line last">
            {costco && <div className="textGreen">COSTCO</div>}
          </div>
          <div className="line last">
            {businessRequestedLocation && (
              <div className="bestBeforeText">
                {renderSkuTable(data_ids, items)}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="description">
        <div className="locationTypeWrapper">
          {locationType === 'FRIDGE' && <img src={fridgeIcon} alt="Fridge" />}
          {locationType === 'WHOLESALE' && (
            <img src={wholesaleIcon} alt="Wholesale" />
          )}
        </div>
        <div className="locationTypeSubWrapper">
          <div className="locationId">{locationSplit}</div>
          {location.includes('INTERNAL') && (
            <div className="deliveryNotes">{deliverySpecialNotes}</div>
          )}
        </div>
      </div>

      <SingleStopTable
        layout={layout}
        items={items}
        psId={uniqueId}
        data={data_ids}
        location={locationSplit}
        // forks={forks}
        // spoons={spoons}
      />
    </StyledDiv>
  );
};

export default RouteStop;
