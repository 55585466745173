//USE THIS DATA WHEN DEVELOPING TO SIMULATE THE RESPONSE FROM GQL

export const mockItems = {
  data: {
    items: [
      {
        id: 'USDA_CHICKEN_BACON_SALAD',
        prettyName: 'Chicken Bacon Ranch Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 3,
        },
      },
      {
        id: 'LACROIX_LIME',
        prettyName: 'LaCroix Lime',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 366,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: 'Beverage_Thick_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'B',
          sort: 6,
        },
      },
      {
        id: 'TURKEY_SANDWICH',
        prettyName: 'Smoked Turkey and White Cheddar Sandwich',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 6,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: 'Deep_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 2,
        },
      },
      {
        id: 'CORA_TESTING',
        prettyName: 'Cora Testing',
        categories: ['Breakfast', 'Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 2,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BERRY_GRANOLA_YOGURT',
        prettyName: 'Berries & Granola Greek Yogurt',
        categories: ['Breakfast'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Spoon',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '12oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'B',
          sort: 1,
        },
      },
      {
        id: 'USDA_PESTO_CHICKEN_SALAD_WRAP',
        prettyName: 'Pesto Chicken Salad Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Shallow_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 4,
        },
      },
      {
        id: 'PINEAPPLE_CHIA_BIG',
        prettyName: 'Pineapple Coconut Chia Pudding',
        categories: ['Breakfast', 'Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Spoon',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '12oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 1,
        },
      },
      {
        id: 'CHIPS_GUAC',
        prettyName: 'Chips & Guac',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: 'Medium_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 1,
        },
      },
      {
        id: 'PESTO_PASTA_BOWL',
        prettyName: 'Pesto Pasta Bowl',
        categories: ['Bowls'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '16oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 2,
        },
      },
      {
        id: 'SWEET_STREET_TOFFEE_BLONDIE',
        prettyName: 'Sweet Street Toffee Crunch Blondie',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 20,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product_No_Clip_Medium',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 5,
        },
      },
      {
        id: 'USDA_CAESAR_CHIC_SALAD',
        prettyName: 'Caesar Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'A',
          sort: 1,
        },
      },
      {
        id: 'CHOCOLATE_TRAIL_MIX',
        prettyName: 'Dark Chocolate Trail Mix',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 60,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: '4oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 3,
        },
      },
      {
        id: 'HAM_SANDWICH',
        prettyName: 'Italian Ham & Cheese Sandwich',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: 'Deep_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 3,
        },
      },
      {
        id: 'FSTG_SWEET_POTATO_CHIPS',
        prettyName: 'Food Should Taste Good Sweet Potato Chips',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 46,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 0,
        },
      },
      {
        id: 'BAJA_CHICKEN_WRAP_USDA_202209',
        prettyName: 'Baja Chicken Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SPINDRIFT_BLOOD_ORANGE',
        prettyName: 'Spindrift Blood Orange Tangerine',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 90,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: 'Beverage_Thick_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 1,
        },
      },
      {
        id: 'DIET_COKE',
        prettyName: 'Diet Coke',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 120,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'CHOCOLATE_CHIA_PUDDING_072023',
        prettyName: 'Chocolate Chia Pudding',
        categories: ['Breakfast', 'Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Spoon',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '12oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'A',
          sort: 6,
        },
      },
      {
        id: 'PESTO_CHICKEN_SALAD_WRAP',
        prettyName: 'Pesto Chicken Salad Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Shallow_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 4,
        },
      },
      {
        id: 'MENU_TRANSFER',
        prettyName: 'Menu Transfer',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 1,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BLUEBERRY_CHIA_OATS',
        prettyName: 'Blueberry Chia Overnight Oats',
        categories: ['Breakfast', 'Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Spoon',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '12oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 1,
        },
      },
      {
        id: 'MEXICAN_CAESAR_SALAD',
        prettyName: 'Mexican Style Caesar Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 5,
        },
      },
      {
        id: 'CORA_TEST_ZOEY',
        prettyName: 'Cora Test',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 55,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SWEET_STREET_MARSHMALLOW_BAR',
        prettyName: 'Sweet Street Chewy Marshmallow Bar',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 20,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product_No_Clip_Medium',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 4,
        },
      },
      {
        id: 'CODE_E',
        prettyName: 'CODE',
        categories: ['Breakfast'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: '',
          sort: null,
        },
      },
      {
        id: 'USDA_SW_CHILI_CHIX_SALAD',
        prettyName:
          'Southwestern Style Salad with chili lime seasoned chicken breast',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'A',
          sort: 3,
        },
      },
      {
        id: 'LACOLOMBE_OAT_LATTE',
        prettyName: 'La Colombe Oatmilk Vanilla Latte',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 120,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: 'Beverage_Thin_Tall_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 5,
        },
      },
      {
        id: 'LACROIX_GRAPEFRUIT',
        prettyName: 'LaCroix Pamplemousse',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 366,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: 'Beverage_Thick_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 8,
        },
      },
      {
        id: 'FSTG_TORTILLA_CHIPS',
        prettyName: 'Food Should Taste Good Multigrain Tortilla Chips',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 47,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 7,
        },
      },
      {
        id: 'THAI_NOODLE_BOWL',
        prettyName: 'Thai Noodle Bowl',
        categories: ['Bowls'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '16oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 4,
        },
      },
      {
        id: 'VEG_COBB_SALAD',
        prettyName: 'Cobb Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 7,
        },
      },
      {
        id: 'VITAL_FARMS_EGGS',
        prettyName: 'Vital Farms Hard Boiled Eggs',
        categories: ['Proteins', 'Breakfast'],
        salesChannel: ['FRIDGE'],
        shelfLife: 20,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 2,
        },
      },
      {
        id: 'TURKEY_SANDWICH_RETAIL',
        prettyName: 'Smoked Turkey and White Cheddar Sandwich ',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 6,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: 'Retail_Sandwich_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 0,
        },
      },
      {
        id: 'APPLE_PECAN_SALAD',
        prettyName: 'Apple Pecan Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 2,
        },
      },
      {
        id: 'JUSTINS_ALMOND_CUP',
        prettyName: "Justin's Dark Chocolate Almond Butter Cups",
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 60,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'CPG_Product_No_Clip_Small',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 3,
        },
      },
      {
        id: 'COSTCO_USDA_CHIX_CAESAR_SALAD',
        prettyName: 'Caesar Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Costco_Shipper',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: '',
          sort: 0,
        },
      },
      {
        id: 'COUPON_JAR',
        prettyName: 'FF Guest Gift ',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 14,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BAJA_BOWL',
        prettyName: 'Baja Bowl',
        categories: ['Bowls'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '16oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'A',
          sort: 2,
        },
      },
      {
        id: 'USDA_BAJA_CHIC_WRAP',
        prettyName: 'Baja-Style Chicken Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Shallow_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 2,
        },
      },
      {
        id: 'THE_BESTT',
        prettyName: 'The Best',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 12,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BAJA_CHICKEN_WRAP_202209',
        prettyName: 'Baja Chicken Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Shallow_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 7,
        },
      },
      {
        id: 'BBQ_RANCH_SALAD_USDA',
        prettyName: 'BBQ Chicken Ranch Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'GREEK_SALAD_NEW',
        prettyName: 'Greek Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 4,
        },
      },
      {
        id: 'COSTCO_MEX_STREET_CORN_SALAD',
        prettyName: 'Mexican Style Street Corn Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Costco_Shipper',
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'CAPRESE_SALAD_202305',
        prettyName: 'Caprese Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 3,
        },
      },
      {
        id: 'NAPA_CHICKPEA_WRAP',
        prettyName: 'Napa Chickpea Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 6,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Shallow_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'B',
          sort: 2,
        },
      },
      {
        id: 'CHICKEN_BACON_SALAD',
        prettyName: 'Chicken Bacon Ranch Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'B',
          sort: 3,
        },
      },
      {
        id: 'HAM_SANDWICH_RETAIL',
        prettyName: 'Italian Ham & Cheese Sandwich',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: 'Retail_Sandwich_Box',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 7,
        },
      },
      {
        id: 'COCA_COLA',
        prettyName: 'Coca Cola',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 120,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SPRITE',
        prettyName: 'Sprite',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 120,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'COSTCO_CAPRESE_PASTA_BOWL',
        prettyName: 'Caprese Pasta Bowl',
        categories: ['Bowls'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Costco_Shipper',
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'CODE_CODE',
        prettyName: 'Code',
        categories: ['Breakfast'],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 0,
        },
      },
      {
        id: 'CHICKEN_CAESAR_SALAD_USDA',
        prettyName: 'Grilled Chicken Caesar Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'GOOD_CRISP_SOUR_CREAM_ONION',
        prettyName: 'The Good Crisp Company Sour Cream & Onion Potato Crisps',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 47,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: 'Beverage_Thick_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 5,
        },
      },
      {
        id: 'SOUTHWEST_CHIX_SALAD',
        prettyName: 'Southwest Salad with Chili Lime Chicken',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 7,
        },
      },
      {
        id: 'LACOLOMBE_TRIPLE_DRAFT',
        prettyName: 'La Colombe Triple Draft Latte',
        categories: ['Drinks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 120,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Can_12OZ',
        tags: null,
        packagingType: 'Beverage_Thin_Tall_Can',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'A',
          sort: 0,
        },
      },
      {
        id: 'COSTCO_CAPRESE_SALAD',
        prettyName: 'Caprese Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: 'Costco_Shipper',
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'GREEN_GODDESS_SALAD',
        prettyName: 'Green Goddess Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 2,
          bay: 'A',
          sort: 0,
        },
      },
      {
        id: 'TURKEY_APPLE_WRAP_USDA',
        prettyName: 'Turkey, Apple & White Cheddar Wrap',
        categories: ['Sandwiches'],
        salesChannel: ['FRIDGE'],
        shelfLife: 6,
        sundryType: null,
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'CHEDDAR_CHEESE',
        prettyName: 'White Cheddar Cheese',
        categories: ['Snacks', 'Proteins'],
        salesChannel: ['FRIDGE'],
        shelfLife: 10,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: '4oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 0,
        },
      },
      {
        id: 'GRILLED_CHICKEN',
        prettyName: 'Grilled Chicken',
        categories: ['Proteins'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: '4oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'A',
          sort: 4,
        },
      },
      {
        id: 'MEDI_BOWL',
        prettyName: 'Mediterranean Bowl',
        categories: ['Bowls'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: '16oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 0,
        },
      },
      {
        id: 'CHICKEN_CAESAR_SALAD',
        prettyName: 'Grilled Chicken Caesar Salad',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'LIVE',
        jarSize: 'Large_Salad',
        tags: null,
        packagingType: '32oz_Jar',
        unitsPerPack: null,
        lineOrder: {
          zone: 1,
          bay: 'B',
          sort: 1,
        },
      },
      {
        id: 'EMPTY_SLOT_NEW',
        prettyName: 'Empty Slot',
        categories: ['Proteins'],
        salesChannel: ['FRIDGE'],
        shelfLife: 365,
        sundryType: 'None',
        status: 'LIVE',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DTC_TIP',
        prettyName: 'DTC Tip',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_FALAFEL_MEDI_TOMATO',
        prettyName: 'Bulk Falafel Medi Tomato',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_PUMPKIN_SEEDS',
        prettyName: 'Bulk Pumpkin Seeds',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SMOKED_TOFU',
        prettyName: 'Bulk Smoked Tofu',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'TORTILLA_CHIP',
        prettyName: 'Multigrain Tortilla Chips',
        categories: ['Bowls', 'Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 9,
        },
      },
      {
        id: 'BULK_NAPA_CABBAGE',
        prettyName: 'Bulk Napa Cabbage',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_GREEK',
        prettyName: 'Dressing Greek',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_5_BREAK',
        prettyName: 'LINE_5_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: '3PL_1DAY',
        prettyName: '3PL_1DAY',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SHREDDED_CHICKEN',
        prettyName: 'Bulk Shredded Chicken',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_BURRITO_VEGGIES',
        prettyName: 'Bulk Burrito Veggies',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SIDE_SALAD',
        prettyName: 'Side salad',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 3,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: 'Wrap_Box',
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_TURKEY',
        prettyName: 'Bulk Turkey',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'GINGERBREAD_BITES',
        prettyName: 'Gingerbread Cookie Dough Bites',
        categories: ['Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 21,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Small_Snack',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'HORIZON_HALF_GALLON',
        prettyName: 'Horizon Organic 2% Reduced Fat Milk, Half Gallon ',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'other',
          sort: 9,
        },
      },
      {
        id: 'BULK_TEST',
        prettyName: 'Bulk Test',
        categories: [],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SO_DELICIOUS_VANILLA_YOGURT',
        prettyName: 'So Delicious Dairy Free Vanilla Coconutmilk Yogurt',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'Other',
          sort: 9,
        },
      },
      {
        id: 'DRESSING_DARK_BALSAMIC',
        prettyName: 'Dressing Dark Balsamic',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_SESAME_GINGER',
        prettyName: 'Dressing Sesame Ginger',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_BROKEN_CHIPS',
        prettyName: 'Bulk Broken Chips',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_ROMAINE',
        prettyName: 'Bulk Romaine',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SOUTHWEST_QUINOA',
        prettyName: 'Bulk Southwest Quinoa',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_NAPA_TOPPINGS',
        prettyName: 'Bulk Napa Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_CHICKEN_SALAD',
        prettyName: 'Bulk Chicken Salad',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_BURRITO_GUAC_PICO',
        prettyName: 'Dressing Burrito Guac Pico',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_GREEN_LEAF_BLEND',
        prettyName: 'Bulk Green Leaf Blend',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: '3PD_BOX_141212',
        prettyName: '3PD_BOX_141212',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_1_BREAK',
        prettyName: 'LINE_1_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'MAPLE_SYRUP',
        prettyName: 'Maple Syrup',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_ROASTED_CORN',
        prettyName: 'Bulk Roasted Corn',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_FALAFEL_CABBAGE',
        prettyName: 'Bulk Falafel Cabbage',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_4_BREAK',
        prettyName: 'LINE_4_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_ALMOND_BUTTER',
        prettyName: 'Bulk Almond Butter',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'FENNEL_SALAD',
        prettyName: 'Fennel & Celery Salad by Missy Robbins',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 4,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 8,
        },
      },
      {
        id: 'BULK_COBB_TOPPINGS',
        prettyName: 'Bulk Cobb Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'AVOCADO_WHOLE',
        prettyName: 'Whole Avocado',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 4,
          bay: 'B',
          sort: 3,
        },
      },
      {
        id: 'BULK_CHEDDAR',
        prettyName: 'Bulk Cheddar',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_GREEK_TOPPINGS',
        prettyName: 'Bulk Greek Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: '3PL_2DAY',
        prettyName: '3PL_2DAY',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'WALLABY_GREEK_YOGURT',
        prettyName: 'Wallaby Organic Whole Milk Plain Greek Yogurt',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'Other',
          sort: 9,
        },
      },
      {
        id: 'BULK_DRIED_CHERRIES',
        prettyName: 'Bulk Dried Cherries',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_FALAFEL_CHICKPEA_GARLIC',
        prettyName: 'Dressing Falafel Chickpea Garlic',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_AVOCADO_RANCH',
        prettyName: 'Dressing Avocado Ranch',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_DIJON_SPREAD',
        prettyName: 'Bulk Dijon Spread',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_FALAFEL_COUSCOUS',
        prettyName: 'Bulk Falafel Couscous',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SOUTHWEST_TOPPINGS',
        prettyName: 'Bulk Southwest Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_FALAFEL',
        prettyName: 'Bulk Falafel',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'CUCUMBER_SOUP',
        prettyName:
          'Chilled Cucumber Soup with Yogurt & Dill by Andrew Zimmern',
        categories: ['Bowls', 'Snacks'],
        salesChannel: ['FRIDGE'],
        shelfLife: 5,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'B',
          sort: 9,
        },
      },
      {
        id: 'DRESSING_AVOCADO_LIME',
        prettyName: 'Dressing Avocado Lime',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_2_BREAK',
        prettyName: 'LINE_2_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_HARVEST_TOPPINGS',
        prettyName: 'Bulk Harvest Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SHREDDED_SMOKED_CHEDDAR',
        prettyName: 'Bulk Shredded Smoked Cheddar',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_ASIAN_CHOPPED_TOPPINGS',
        prettyName: 'Bulk Asian Chopped Topping',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_PISTACHIOS',
        prettyName: 'Bulk Pistachios',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_3_BREAK',
        prettyName: 'LINE_3_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SILK_ALMOND_HALF_GALLON',
        prettyName: 'Silk Unsweetened Vanilla Almondmilk',
        categories: ['Salads'],
        salesChannel: ['FRIDGE'],
        shelfLife: 7,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: 'Medium_Dish',
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: {
          zone: 3,
          bay: 'Other',
          sort: 9,
        },
      },
      {
        id: 'BULK_CAULIFLOWER_FRIED_RICE',
        prettyName: 'Bulk Cauliflower Fried Rice',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_SHREDDED_MONETERY_JACK',
        prettyName: 'Bulk Shredded Monetery Jack',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_KOMBUCHA',
        prettyName: 'Bulk Kombucha',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'LINE_0_BREAK',
        prettyName: 'LINE_0_BREAK',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_BURRITO_RICE',
        prettyName: 'Bulk Burrito Rice',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'DRESSING_HONEY_MUSTARD',
        prettyName: 'Dressing Honey Mustard',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_BURRITO_TOFRITAS',
        prettyName: 'Bulk Burrito Tofritas',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'BULK_BBQ_SUNFLOWER_SEEDS',
        prettyName: 'Bulk BBQ Sunflower Toppings',
        categories: [],
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: 'Fork',
        status: 'BULK',
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'SWEET_POTATO_WRAP',
        prettyName: 'SWEET_POTATO_WRAP',
        categories: null,
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: null,
        status: null,
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
      {
        id: 'HUMMUS_WRAP',
        prettyName: 'HUMMUS_WRAP',
        categories: null,
        salesChannel: ['FARMSTAND'],
        shelfLife: 0,
        sundryType: null,
        status: null,
        jarSize: null,
        tags: null,
        packagingType: null,
        unitsPerPack: null,
        lineOrder: null,
      },
    ],
  },
};

export const mockOpsLocations = {
  data: {
    opsLocations: {
      locations: [
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_HUSCO_ROUTE3__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_WIH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Where are we currently parking? If we're entering through the main entrance I can get updated info.\r\n\r\nIf through the loading dock, enter through loading dock, go right to elevator C on ground floor, take to first floor and Fridge is into the WIH on your right",
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_ABE8_BACK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk from front break room to back break. Follow the green lines all the way down to the back breakroom. It’s in the back right of the building.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson Newark',
          id: 'NJ_HUDSON_NEWARK__3_BREWSTER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_125_HIGH_STREET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once they are on site, please have them call Jill Gray (617-470-6429) and I will have security escort them to the location!\r\n\r\nSecurity # 617-342-7500',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8501_W_HIGGINS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_WSJFOE',
          id: 'NY_WSJFOE__6_ST_JOHNS_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'COORDINATING WITH ALEX + TEAM!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Sales_Canteen_St_Louis_Midwest_Division_Meeting',
          id: 'MO_CANTEEN_ST_LOUIS_2331_ MILLPARK_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Please drop off at the Canteen branch loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Memorial Hermann Southwest',
          id: 'TX_MEMORIAL_HERMANN_SOUTHWEST__7600_BEECHNUT_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Call Ely 832-276-3667',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CHELSEA_PIERS_BROOKLYN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_NATIONWIDE_ARENA_112',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon parking take the loading dock entrance towards security who will allow you entry. Take the main corridor hallway towards the 1st floor machine outside of gates 112/113',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_DARWINS_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_1303__14200_S_BELL_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_AFW1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security take a right and the breakroom will be straight ahead',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1150',
          id: 'NY_TARGET_T1150__13505_20th_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_UTAUSTIN EVENT',
          id: 'TX_UTAUSTINEVENT__201_E_21ST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'PLEASE CALL AIMEE',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_18TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security and walk to vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_KEELEY_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main entrance, take the elevators to the second floor, walk across the office to the breakroom on the other side.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_HEALTH_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "walk in through main entrance, turn to the left until you dead end into the reception desk, go right until you see the first set of elevators on the left side, go to the 3rd floor and once you're off the elevators the machine will be directly to your right",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_METHODIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Entrance by the front glass door then short walk strait down the hallway turn left in to the Cafeteria pass the cashiers and on your right side by the wall it’s fridge.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Ranch Triangle',
          id: 'IL_RANCH_TRIANGLE__413_W_NORTH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Sheehan Brothers Wholesale',
          id: 'OH_SHEEHAN_BROTHERS_WHOLESALE__1010_HOMETOWN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Park at the loading dock of the building facility- use man door',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Sheehan Brothers Vending',
        },
        {
          opportunityName: 'Hudson ONT - Terminal 2',
          id: 'CA_HUDSON_ONT_TERMINAL_2__2900_E_AIRPORT_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'T2 go up the escalator and turn left the store name is Evolve you can deliver their in the store.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MD_BAL_GRACE_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go past the parking garage and turn left into the loading dock area. Take the freight elevator up to 5.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_FRAMA_TECH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through those doors straight back about 200 feet, Fridge will be against that back wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_NORTHROP_GRUMMAN_CARMEL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter and check in with the desk. Go down the main hallway and down a flight of stairs to the micro market where the fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_SPEA__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1852',
          id: 'PA_HUDSON_PHL_1852__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_TARGET_SOUTH_BREA__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AMAZONGO_IOR1_WCHICAGO',
          id: 'IL_AMAZON_GO_IOR1__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'pickup at cicero',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Boston East Health Samples',
          id: 'MA_BOSTON_EAST_HEALTH_SAMPLES__20_MAVERICK_SQ',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Please call Carly',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Aramark Refreshments Route 103 – TE Office',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_103_TE_OFFICE__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Sheehan Brothers Vending',
          id: 'OH_SALES_SHEEHAN_BROTHERS_VENDING__1740_COMMERCE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'please call delivery contact',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Morris',
          id: 'MN_CANTEEN_MORRIS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Costco - Melrose Park Warehouse',
          id: 'IL_COSTCO_MELROSE_PARK_WAREHOUSE__8400_W_NORTH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TEAMS_DEMO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_UPENN_HOSPITAL_PAVILION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter loading dock, drive down to park. Go through double doors and make a left. Then make a\r\nright. Take elevators on left side to first floor. Make a left and go through double doors then a\r\nsingle door. Make a left and walk towards big windows and it’s on left side.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OAKBROOK_22',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_EL_CENTRO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, Fridge will be straight through to the main lobby against the far wall.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - ProMed',
          id: 'MN_CANTEEN_PROMED__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NJ_TURTLE_BACK_ZOO_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Drivers will be supplied with a key to the Savanna cafe (they can keep it in the truck if not the same driver each time). If they deliver after 8am there will be someone in the cafe to let them in',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BROOKLYN_FEDERAL_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Guards will be able to direct you to the first floor coffee shop where our Fridge is located. Call/text Alex Alvarez (646-851-9660) if you have any issues.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SOUTHERN_UNI_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to Library Learning Center located North West from Entrance.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1401',
          id: 'MN_HYVEE_1401__18755_70th_WAY_NORTH',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - DRC BOONE EAST',
          id: 'MN_CANTEEN_DRC_BOONE_EAST__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_CAFE_32__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3225',
          id: 'CA_TARGET_T3225__​5760_EAST_7TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_UNITED_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the main entrance, take the elevator down a floor, walk straight past the coffee shop and the Fridge will be located in the dining area just outside the cafeteria doors.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_1900 Gallows Rd',
          id: 'VA_SALES__1900_GALLOWS_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park out front and call Ivory (703) 214-0706',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Loyola-Chicago Wholesale',
          id: 'IL_LOYOLA_UNIVERSITY_WATER_TOWER_CAMPUS__N_SHERIDAN_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EDWARDS_HOSPITAL_MAIN_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take main entrance to the right and vending area is right there',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_MELVILLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Marketing_TUFTS',
          id: 'MA_TUFTS__150_HARRISON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'CALL NEAL',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'MI_MARTINS_21__2121_S_CLEVELAND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IIT_GALVIN_LIBRARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_A21_LEFT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Life Time',
          id: 'MN_SALES_LIFE_TIME__250_10_SOUTHDALE_CTR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Please call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BROOKLYN_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter via the glass doorway on Campus Road directly into Whitehead Hall. Once you enter the building, you can navigate to the Library Café by entering the door directly on the right. This leads directly into the Library Café.\r\n\r\nFor access prior to 7AM for either machine, please call the Public Safety Office at (718) 951-5511. Since the campus is closed overnight, an officer will have to be dispatched to give access.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Bloomfield Warehouse',
          id: 'MI_COSTCO_BLOOMFIELD_WAREHOUSE__2343_S_TELEGRAPH_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back and deliver via receiving.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NYC_HUGEINC_BROOKLYN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From entrance walk to baggage claim. Find fridgein central area to the right of the 7/11',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_NORTHEAST_BAPTIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors and immediately on your left the Fridge will be against the wall',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ICSC_VEGAS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYU_TISCH_ARTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Front entrance at 721 Broadway and then through the turnstiles and directly back to the Riese Lounge',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SMC_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building and you will see a few other vending machines where we will live\r\n\r\nIf arriving before building is open, call campus security and they can open the door remotely. Campus security phone # 310-434-4300.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_FBI_HQ_HOOVER_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'You will be escorted by a FBI employee',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_APPLE_STORE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_COLER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Texas Health Dallas Hospital',
          id: 'TX_TEXAS_HEALTH_DALLAS_HOSPITAL_WHOLESALE__8200_WALNUT_HILL_LANE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            "Drop the product at the cafeteria's cold storage which is right next to our current Fridge there",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'The Texas Health Resources Medical System',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW8',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in, pass security, turn left and follow all the way to the end, break room straight ahead',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_ONT2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Please take the main front entrance in to the security desk. Badge access to the 1st floor main break room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LESLEY_UNIVERSITY_1815_MASS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Phil Trainor (617-304-9967). He will guide you to walk through the back entrance towards the front entrance and take a right towards Shaking Crab.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_PRINCES_BAY_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Utilize the front entrance, check in with security who can guide you to the elevator which you will take to the ground floor. Follow the signs to the cafeteria and the Fridge unit will be there.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'MI_GOPUFF_126__1902_FEDERAL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Canteen February Event',
          id: 'TX_CANTEEN_FEBRUARY_EVENT__2747_W_GRAND_PKWY_N',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'STOCK FRIDGE AT CANTEEN OFFICE. Call Emily at 281-475-5786',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Kite Pharma',
          id: 'CA_KITE_PHARMA__2383_UTAH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Enter the front check in. Go through the main doors to the break room / kitchen where the fridge will live',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_929_APARTMENTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter throught the double glass doors and the fridge location will be on the right.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_University of Pittsburgh',
          id: 'PA_UNIVERSITY_OF_PITTSBURGH__123_UNIVERSITY_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Call Steve',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_MARVIN_ENG_260_BEACH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in main driveway to main building (dark grey) with hazards on. Check-in at main desk and security will grant access. ID is required for each delivery. Walk across street where cafeteria is. See additional picture for which door to enter.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_CROWN_POINT_FRANCISCAN_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'take main hallway to your right near the other fridge.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Depaul Hospital',
          id: 'MO_DEPAUL_HOSPITAL__12303_DEPAUL_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'RECIEVERS WILL BE WAITING- BRING FOOD INTO WALK IN COOLER IN KITCHEN',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_FOX_CHASE_CANCER_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Go to security desk take elevator up to 3',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_EBAY_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with facilities. Go to the elevator bank in the middle of the building, take it to floor 2.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_COLLEGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mercy Hospital - Clayton Clarkson',
          id: 'MO_MERCY_HOSPITAL_CLAYTON_CLARKSON__ 15945_CLAYTON_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'When you walk go directly to the coffee bar',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_STUDENT_UNION_GROUNDFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Park Place West',
          id: 'MN_CANTEEN_PARK_PLACE_WEST__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'CA_LA_SPOTIFY_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in at front desk. Security is onsite 24/7 -- main desk phone number is (213)219-1826. Security goes on patrol at 10pm for 30 minutes.\r\n\r\nFridge is located in the cafe.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DHL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Turn right, turn right again at the wall, walk straight back and their will be a hallway on the left in the dining area, go down the hallway and on the left will can the canteen market and we will be across the from the canteen market',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_WACKER_NUESON_OFFICE_ROUTE5_12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_ALBANESE_CANDY__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_HARVARD_MAXWELL_DWORKIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the front entrance of building from street',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ORD1',
          id: 'IL_ORD1__1639_NMILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'please call javiar and deliver through the back door',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RIVERVIEW_MEDICAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Huntsman',
          id: 'TX_HUNTSMAN__10003_WOODLOCH_FOREST_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Call Tomica Archie at 281-719-7705, or Dennis at 281-719-7916',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'MI_GOPUFF_195__2036-2040_W_11_MILE_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Tufts Medical Wholesale - Woburn',
          id: 'MA_TUFTS_MEDICAL_WOBURN__600_UNICORN_PARK_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Can park out front, enter through main entrance, elevator in lobby is against the back wall slightly to the right. Take elevator to the 3rd floor. Exit elevator to the right, end of hall take a left, office is on the right. Walk straight back and kitchen',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c4d07e2c-2718-4e1c-b4d2-5fe8ea363d8d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_MA_Catering (comped) - Sales_Mike Samples Bos',
          id: 'MA_MIKE_SAMPLES_BOS__103_ARCH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Call Mike at 847-909-5434',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'CANTEEN - Stratasys Edenvale',
          id: 'MN_CANTEEN_STRATASYS_EDENVALE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'USE BADGE AND DROP OFF IN FRIDGE',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1854',
          id: 'PA_HUDSON_PHL_1854__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_DOUGLAS_ELLIMAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_TUFTS_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter via the Emergency Department Entrance at 830 Washington Street, Boston MA 02111. Enter the emergency department doors. Check in with the security desk located in the waiting area and inform you are making a delivery to the 3rd floor Atrium. Security will unlock the hallway for you to enter. Navigate to the atrium elevators (down the hall from the ER on the left side). Take the elevators to the 3rd floor. Upon exiting the elevators, the driver will walk straight ahead to the seating area. The Farmer’s Fridge machine is located on the left hand side wall by the windows with additional vending options. Once complete, the driver should take the atrium elevators back to the 1st floor and make their way back to the emergency department exit.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_LANDMARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside to the right, into the cafeteria, and we will be on the back wall next to the Avenue C',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_WEILCORNELL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevator from main entrance to basement level and follow signs for garden cafe. Fridge is right near the entrance',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_MED_900_WESTMORELAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the 800 main entrance and head to the dining area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'dfd626a8-4089-441d-b32f-12077d47c57b',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__8034_ST_LOUIS_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please call contact',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Park Place East',
          id: 'MN_CANTEEN_PARK_PLACE_EAST__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_OAKLAND_COFFEE_SHOP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Location: located in the Coffee Shop seating area. Delivery Entrance: Main Entrance, park in the parking spots near the main door. Park to fridge instructions: Enter via the main door, then turn right. Follow signs for the coffee shop. Fridge is at the end of the coffee seating area, opposing the gift shop.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYIT_LONG_ISLAND_FERRENTINO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the front door of the building and take a right upon entering. The fridge will be placed in the lounge area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_CHS_CORPORATE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_SC_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Enter through the Polk St entrance and it'll be on your right hand side.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'Testtest123',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_UNION STREET_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_2200_CAFETERIA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk from the A entrance to the cafeteria.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_OLD_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Pull in on the back lot off of W Hancock St. Park near dumpsters (“P” below). Use ramp to go down into basement (door is circled below). Walk through hallway and then take a right. Elevators will be on the right. Take to the 2nd floor. Walk out of elevators and turn left. Walk through doors and into student lounge and you will see snack and beverage machine. Please install machine exactly next to snack machine. We are adding an additional snack and beverage to this location so need to keep them close so they fit.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_INGALLS_TINLEY_PARK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'We can expand your access to 24/7 as long as they enter thru Entrance C which is where the Fridge is located.\r\n\r\nTake a left when you enter the building. The spot is at the end of that hallway before it turns into the waiting room / lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'KTLA',
          id: 'CA_KTLA__5800_SUNSET_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_CIB_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Cicero',
          id: 'IL_CICERO__5370_S_CICERO_ST',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Kira will pick on Monday 10/30',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jane Street - 250 Vesey Street',
          id: 'NY_JANE_STREET__250_VESEY_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading Dock – Freight Elevator 29 to 3rd floor – exit right from freight area on 3, go through double white doors and kitchen is straight ahead',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_INGALLS_HARVEY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '***If arriving/stocking after dock hours, please call the dispatcher at 708-915-5555 to be let in***\n\nTake the dock hallway all the way to the dead end, take a left, first right, lobby is right there and fridge is in the seating area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_UNIVERSITY_AVERY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter in through the side entrance, this location is actually street level. The double doors in the photo lead to street',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HARRIS_COUNTY_PUBLIC_HEALTH_DEP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the lobby and take the elevator to the bottom floor, go to the electric stairs and you will see the spot on your left side.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_11TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with front desk, take elevator or stairs down to 2nd floor vending room',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BEVERLY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front entrance and head left past the front desk. Head down the hallway past the elevator bank and take a right. The vending room will be on your left.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen One',
          id: 'MN_SALES_CANTEEN_ONE_SAMPLES__700_N_5th_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'call Katie',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BLUECROSS_BLUESHIELD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_16',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Greater Baltimore Medical Center',
          id: 'MD_GREATER_BALTIMORE_MEDICAL_CENTER__6701_N_CHARLES_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Loading Dock in Back',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_LA_SDR_SAMPLES',
          id: 'CA_SALES_LA_SDR_SAMPLES__3417_EDGEHILL_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Please call the delivery contact',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Endeavor MSP',
          id: 'MN_ENDEAVOR_MSP__4300_GLUMACK_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Deliver to Endeavor Airlines breakroom located in Concourse C near gate C10. Please call delivery contact upon arrival to be let in.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Endeavour Air',
        },
        {
          opportunityName: null,
          id: 'IND_STUTZ_BUILDING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_17',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CDA Event',
          id: 'IL_CDA_EVENT__856_W_FULLERTON_AVE_UNIT_2',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Jayne upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson JFK T7',
          id: 'NY_HUDSON_JFK_T7__QUEENS_NY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'The delivery can be made to our warehouse in Terminal 7 . They would need to go to Post G behind the Jet blue Hanger corner of 148th street and south cargo road  ( they can goggle it ). The post does not open until 6am , there will be a security guard a',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_B2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter Terminal B and Find fridge across from gate B2',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Allentown Aramark',
          id: 'NJ_ALLENTOWN_ARAMARK__29_COMMERCE_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'ATTN Alex Cacciatore: this is for the aramark order going to Aramark @ Air Products\r\n\r\nAttn: Dave Fruhwirth. 1940 Air Products Boulevard Allentown, PA 18106. Once arrive, call Dave at 717-982-7317. Deliver at the loading docks.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '485_HALF_DAY_ROAD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3182__1202_STATE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_BAYLOR_ST_LUKES_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator down to basement, follow signs for cafe. Fridge is located in recessed nook around the corner from cafe.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Romeoville, IL',
          id: 'IL_LIFETIME_ROMEOVILLE_IL__1220_LAKEVIEW_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee 1391',
          id: 'WI_HYVEE1391__3801_EAST_WORTHINGTON_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Entry: back door in loading area w/ Recieving Sign. Walk straight until the first cooler on your right. Place box on riser/shelf on the inside right hand corner upon entry. Check in with a member of the produce team to confirm delivery before leaving.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the lobby. Take elevator up to 2nd floor. Follow signs to Cafe. Fridge outside of cafe by ATM',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_UH_CMC_BOLWELL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Same as OH_CLE_UH_CMC_ATRIUM, just follow signs to Bolwell Bldg/Lobby, fridge is hgihly visible, located near main doors of this area',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LAUNDRY_CAPITAL_BRONX',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'the fridge will be in the glass enclosed blue room, in the same back room as where the bathroom is',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Torrance Memorial Medical Center',
          id: 'CA_TORRANCE_MEMORIAL_MEDICAL_CENTER__3330_LOMITA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'When they arrive there is a buzzer that they can ring and staff will let them in. If they do not open they can always go to the front desk and let them know they are here to deliver to Yang café and they will let them in',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Cedars-Sinai Health System',
        },
        {
          opportunityName: null,
          id: 'MO_STL_WASHU_STEINBERG_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk right in front doors and Fridge is right in front',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'go through front entrance',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson CLE',
          id: 'OH_HUDSON_CLE__5300_RIVERSIDE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Deliver to Bradford logistics with instructions to deliver food to Hudson Commisary',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Target - T0942',
          id: 'IL_TARGET_T0942__2656_N_ELSTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Stock product in the Deli endcap on all 4 shelves.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_132__616_RIDGE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NEIU_STUDENT_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Parking Instructions: For both locations, please park in Lot F which is located off of Bryn Mawr Ave. Prior to getting to campus, please call the campus police at (773) 442-4100 and let them know you are going to be there shortly.  After you park, please go to their office which is located in the parking garage in the F Lot.  The Security guard will unlock both building doors for you. The building is adjacent to the Campus Police office and directly north of the library. When you enter through the west door, walk straight until you see the Descartes cafe and then the fridge will pop up on your right before the cafe.\r\n\r\nFrom contact 7/25/21: "We had worked out a daily delivery schedule for Farmer\'s Fridge.  They would come to campus anytime between 4:00am-6:00am.  Their first stop would be to go directly to the FA door where they would be admitted remotely by the on-duty TC.  After finishing the machine adjacent to Cafe Descartes, they would call x4100 and an officer would meet them for an escort to the Library for admittance.  The whole process took 45min -1 hour."',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_SALES SAMPLES - AUSTIN',
          id: 'TX_SALES_SAMPLES_AUSTIN__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Sales will be picking up - Call Eddie Sak - 630-777-1885',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_Lululemon',
          id: 'MN_LULULEMON__116_W_MARKET_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Prince Rountree 7869105768',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e5547f57-25a3-4ec4-8524-12658bcfe598',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '916377f7-a91a-4127-aecc-b9142cf8f555',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Marketing_LULUDC',
          id: 'DC_LULUDC__219_M_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call Matt',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_AMAZON_STL9',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Part to fridge - Park in the front and turn on hazards , enter thru door and hit buzzer to contact security. Have your DL or ID ready and they will enter you into the system for a guest badge . You will also need to get a vest before you step on the floor. Follow the wall of the right side of the building all the way to the back of the warehouse and you will see the fridge outside the break area .',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_229_W_43RD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FF_MARKETING_EVENT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'DRIVER WILL HAVE TO ENTER AT E 18th Dr & S Dusable Lake Shore Dr AND CALL ALAYA WHO CAN MEET THE DRIVER',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'OH_GOPUFF_90__1972_CENTAL_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST IGNORE',
          id: '12345',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_5_PARAGON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AUDIE_MURPHY_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take an immediate right, cafeteria entrance will be there, once through the doors we will be on the left wall',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ST_DAVIDS_ROUND_ROCK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'We’re right in the lobby through the front doors',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_FF Corporate Office',
          id: 'IL_CATERING_(COMPED)_SALES_FF_CORPORATE_OFFICE__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call cell upon arrival 617-460-2755',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_100_OVERLOOK_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator up to 1 and walk towards lobby',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_VENDING__1_N_DINING_HALL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock is located on the north end of the building right next to the fire station',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_TOMBALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the library parking (basically the primary parking lot when you pull in) walk through the library, out the doors and into the commons building which is a big atrium in the middle of the complex.  The fridge is in the middle of the atrium next to the cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Scripps Mercy Wholesale',
          id: 'CA_SCRIPPS_MERCY_WHOLESALE__4077_FIFTH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Go to loading dock. It is on the corner of fourth and Lewis St. There is a phone number by the loading dock and someone will come open the door',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Scripps Health',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_3039_NOWITZKI_WAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'take freight elevator to 9th floor',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GROUPON_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1415_W_DIEHL_ROAD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FEDEX_FREIGHT_MDW',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '22e52cbf-371c-430a-8ba6-d827194a3b7f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - C4',
          id: 'VA_HUDSON_IAD_C4__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'THIS IS A TEST',
          id: 'IL_TEST__TEST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CDW_LINCOLNSHIRE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_UMMA_CAFE__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_297__5111_W_ROOSEVELT_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OHM Wholesale',
          id: 'VA_RONALD_REAGAN_WASHINGTON_NATIONAL_AIRPORT_DCA__2401_SMITH_BLVD_DOCK_B',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "As you're approaching, there are signs everywhere for Dock B. All vendors call Saskya 20-30 mins before delivery so that they?re ready to meet them. Strongly Preferred after 8:00am delivery",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_KEARNY_MESA_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once you enter, you will take the first left up the stairs. Take a left again at the top and you will see the Fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_8425_WOODFIELD_CROSSING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Northern Westchester',
          id: 'NY_NORTHERN_WESTCHESTER__400_EAST_MAIN_STREET_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Kolja: Pronounced (Kol)',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_BOMA',
          id: 'IL_BOMA_115_S_LASALLE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call (267) 474-9042 upon arrival!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_ST_JOHNS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock off of the back side of the building head towards the cafeteria along the elevators',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_SUGAR_LAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge located in front of the cafe. \r\nOn site contacts: \r\nJames (Retail Manager) 281-543-8528\r\nAM Supervisor 281-276-8273 (starts shift at 5a)\r\nPM Supervisor 281-276-8958 (ends shift at 9:30p)',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 9 Illumina Bldg 6',
          id: 'CA_CANTEEN_VISTA_RT_9_ILLUMINA_BLDG_6__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_NKU_HEALTH_INNOVATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Head north on the path to building 9 - See picture in Salesforce for help',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_MIDDLEBURG_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 7:30AM to 7:30PM\nDriver Entrance - Front Entrance\nDriver Parking - Park at the front entrance\nPark To Fridge Instructions - Walk through the front entrance. fridge will be located along the wall on the left-hand side of vending area in lobby',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_PNC_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Head East on Maryland St. the dock will be on the left hand side of road just before Illinois Street.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_WILKIE_FARR_GALLAGHER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCC_NORTHWEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the elevator to the 3rd floor and the fridge will be to the right in this space within the picture. \r\n\r\nIf they are ever struggling to get in the Campus Police (817-515-8911) are always available to help get them in',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOME_DEPOT_REDLANDS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the front door and check in with desk. Down the hall on the left you will see the break room where the fridge will be',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHERN_TRUST_50_S_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_ARROW_SPRINGS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the front entrance to the vending area on same floor',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNI_OF_HOUSTON_ARCHITECTURE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'the fridge is on the 2nd floor, on the west side of the building, in a vending room',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson SNA',
          id: 'CA_LA_HUDSON_SNA__18601_AIRPORT_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Curbside delivery, call the delivery contact upon arrival for pick up',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_GATE_40_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter terminal 8 through the loading dock and proceed to gate 40. find location just beyond the gate after coming up from tunnnel.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Vanderbilt - COMMONS',
          id: 'TN_VANDERBILT_COMMONS__103_21ST_AVE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Liberty Township',
          id: 'OH_COSTCO_LIBERTY_TOWNSHIP__7135_VETERANS_BOULEVARD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'ARLINGTON_HEIGHTS_MEMORIAL_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Drive under the building in the parking garage and access the exterior staff entrance door (13) by unlocking the door with the key and using the badge to open the door. Then access the internal door to the locker room. Enter those two doors and then head up the elevator one floor. Fridge will be in the main cafe area in the middle of the floor.\r\n\r\nAfter the Driver is finished, use the badge to exit in the interior door and deadbolt the exterior door (13) on the way out. The entire building will be alarmed at 330, we will need to be finished before then or the alarm will sound.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_C12',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Will update when finalized',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1442',
          id: 'MA_TARGET_T1442__550_ARSENAL_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in rear and enter through loading area. Stock after 7am.  Stock location PENDING. Receiver signs for drops',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_1855_PLACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address - 550 S Harrison Rd East Lansing MI 48824\nCustomer Friendly Location - MSU - 1855 Building. Located on the first floor near elevators.\nDelivery Hours - Fall/Winter 7AM to 5PM (summer hours are 7:30 but stop 8/21)\nDriver Entrance - Front Entrance\nDriver Parking - Park outside main entrance\nPark to Fridge Instructions - through main entrance and go straight down hallway past elevators until it dead ends. Turn right. Machine is on your right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CHOC_MAIN_VENDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'loading dock',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_HENRY_FORD_BLOOMFIELD_HILLS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://goo.gl/maps/VatJ9Mf1spbCgP2w8\n Go to main entrance, park in the loop after the door.  Go in doors, veer to the left and go down main street as pictured.  The fridge is on the left by the mini Mart.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_SINAI_MEDICAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Through the dock double doors, left down the hallway till just past the elevator bank on the right, fright elevator through wide door.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at back door/dock by trash compactors/parking garage.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_CHASE_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_WACKER',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_UCI_LAW_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Buzz security to enter. Fridge located in second floor lounge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ESSENDANT_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Get visitor badge at loading dock, fridge located in first floor pantry',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_12',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_839__1356_LEXINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1856',
          id: 'TX_HUDSON_PHL_1856__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_MANOOGIAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The vending area is on the north east side of the main floor',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_PILSEN',
          id: 'IL_PILSEN__1852_W_19TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Raul: 312-536-7728',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CHELSEA_PIERS_CHELSEA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - SoFi',
          id: 'VA_HUDSON_IAD_SOFI__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_eacubo event',
          id: 'NJ_EACUBO_EVENT__1000_MORRIS_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Miron Student Center loading dock- call mike',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD C22',
          id: 'IL_HUDSON_ORD_C22__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName:
            'OH_Catering (comped) - Marketing_OSU WORLD FOOD DAY',
          id: 'OH_OSU_WORLD_FOOD_DAY__1739_N_HIGH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver to 1739 N High St Columbus OH 43210 on Friday Oct 14th between 9 am and 2pm',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MEET THE CREATORS - MARKETING EVENT',
          id: 'IL_MEET_THE_CREATORS__19_E_21ST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver on Thursday Nov. 17th between 12 and 2pm \nMae District, 19 E 21st St, Chicago, IL 60616',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_367__900_W_JEFFERSON_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'C&S Vending Company, Inc.',
          id: 'MN_CS_VENDING_COMPANY__1919_2ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Rick Amundson\nVice President\nC&S Vending Company, Inc.\nOffice: (507) 334-8414\nMobile: (507) 491-0187',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Progressive Field',
          id: 'OH_SALES_PROGRESSIVE_FIELD__2401_ONTARIO_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Please call Tyler',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '38b53453-0057-4f83-a6f4-2fcf561ec824',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_FLOYD_CURL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, past the coffee shop, and take the elevators on the left down 1 floor, cafeteria will be right there and we are going to be on the back wall opposite the checkout',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BRAINTREE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1852',
          id: 'TX_HUDSON_PHL_1852__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2006',
          id: 'NY_TARGET_T2006__2900_VETERANS_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'MI_DET_DTW_COKE_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "MI_DET_DTW_COKE_LEFT and MI_DET_DTW_COKE_RIGHT\nDRAFT dry run\nWhen approaching airport, follow signs for McNamara terminal -International- Arrivals.\nThe Bradford dock is just past the passenger doors, and we use dock #1 which is a lift gate.\nDrop product, cart, and paperwork at Bradford McNamara dock.\nMap pin: https://goo.gl/maps/bL6uJdQumVhJ7Ytv6\nWhen leaving Bradford, keep right, following signs for i94/airport traffic.\nFollow signs for McNamara terminal, baggage claim/arrivals (not international!)\nWhen approaching, keep left and enter the Ground Transportation area.\nPark to the immediate left.\nDo NOT back under the overhang - your van is too tall! Back to the overhang, ensuring you're not blocking the entrance in front of you.\nProceed to walk straight and to the right to enter the terminal.\nFollow signs for Elevator and take the elevator up to the ticketing/security level.\nEnter the security checkpoint via the far left designated Employee lane.\nAfter clearing security, turn left.\nFridges are in the Coke vending area across from Plum Market near gate a36.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_GATEWAY_PLAZA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_AFW5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in the front door, turn right before security to the break room, once in the break room turn right and the fridge will go on straight ahead on the white wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Colleyville, TX',
          id: 'TX_LIFETIME_COLLEYVILLE_TX__1221_CHURCH_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - W Plano Warehouse',
          id: 'TX_COSTCO_W_PLANO_WAREHOUSE__1701_DALLAS_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_SATELLITE_UNIT_CROSSINGS__DUNCAN_STUDENT_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call cody upon arrival (574)631-1537',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6841c9b2-d95f-42d0-bc9a-2a5a1b2caab7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DashMart New York',
          id: 'NY_DASHMART__152_W_25_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Totowa Sample',
          id: 'NY_TOTOWA_SAMPLES__29_COMMERCE_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Caitlin and Kurt will pick up at Totowa',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Cushman and Wakefield',
          id: 'OH_CUSHMAN_AND_WAKEFIELD__4680_PARKWAY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Call Elise',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_BUSCH_STADIUM_RED_BIRD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Take the loading dock for entry where there will be a bomb sniff dog upon entry. Once entered into the facility, head right and continue down past 1st warehouse. Take a right thru roll up gate leads to the ramps/switchbacks. Take their ramp onto the concourse and after you've reached the 1st level you'll need to take the elevators up the the 3rd floor- to the Red Bird Club seating. The fridge is at section 250",
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ULTA_BEAUTY_HQ_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_20TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, fridge located in Vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MAIZIES__911_NORTH_UNIVERSITY_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'side loading dock on fletcher St.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BROOKEDALE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through main entrance check in at security desk make right, make another right through double door walk to elevators on left fridge is the right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_AVI Foodsystems - Cincinnati Branch',
          id: 'OH_AVI__9930_PRINCETON_GLENDALE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Ask for Tim Prues or Barb Parsons',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'LA_Catering (comped) - Sales_660 Newport',
          id: 'CA_660_NEWPORT__660_NEWPORT_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Once parked at the docks park in any open bay or space. Use the dock to enter into the lower level double doors with the fob. After entering into the double doors the hallway will enter you into the lower level lounge the fridge is along the wall of the lounge',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_LULUGLEN',
          id: 'CA_LULUGLEN__529_AMERICANA_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE MAKE IT THE LAST STOP ON THE ROUTE!',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '645_N_KINGSBURY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - New Berlin Warehouse',
          id: 'WI_COSTCO_NEW_BERLIN_WAREHOUSE__15300_WEST_GRANGE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Pull to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_BBN',
          id: "MA_BBN__56_GERRY'S_LANDING_ROAD",
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Pull into the circular driveway and I will meet you. Call 617-335-1159, and text with an eta earlier if possible',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_Fenway Park -- Gate D',
          id: 'MA_SALES_FENWAY_PARK_GATE_D__4_JERSEY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Head towards Gate D and call Walker Mackenzie (203-739-5930). You can also enter through the security entrance and tell them you are dropping off samples for Walker Mackenzie.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T5_G23',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock.  Enter and walk straight down to security check point.  Must have everything screened by TSA and also wait for an escort to bring us into the terminal.  Either one of our contacts at the airport or a Third Party security service.  Walk through the area with the escort.  You will exit a door in front of Gate 7. Turn left, and stay to your left.  Follow signs for Gate 23.  Continue down terminal, fridge will be on the left side in front of Gate 23.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Evanston Hospital Earth Day Samples',
          id: 'IL_EVANSTON_HOSPITAL_EARTH_DAY__2650_RIDGE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'ATTN: John Ciletti, HR Office Rm B947',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_RECREATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Main doors, fridge in main cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: '444 W Lake Wholesale',
          id: 'IL_444_W_LAKE_WHOLESALE__444_W_LAKE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please go to the loading dock off of canal on the right. (Gibsons is right there). Check in with security and go to the 23rd floor to the tenant lounge. (2350). CLIENT REQUESTS WE LEAVE ALL EXPIRED PRODUCT PLEASE!',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Flik',
        },
        {
          opportunityName: null,
          id: 'STROGER_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in the front and enter through ER3 and walk down to entrance 2.Take the elevator down to "B" and turn left then make a quick right and look for a sign that says vending. Walk through the double doors and the fridge will be on your left.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - D24',
          id: 'VA_HUDSON_IAD_D24__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'HMS Host BOS',
          id: 'MA_HMS_HOST_BOS__580_CHELSEA_STREET_EAST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Dropping to HMS Distribution warehouse',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_BAJUNELA',
          id: 'CA_BAJUNELA__851_SANTEE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE CALL BREANNA',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'IN_Catering (comped) - Sales_Rolls Royce',
          id: 'IN_SALES_ROLLS_ROYCE__450_S_MERIDIAN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'PLEASE DELIVER WITH FRIDGE ORDER',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_ROBBINSVILLE_PNE5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security and walk towards main breakroom',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_COLLECTORS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_MERCHANDISEMART',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_TSG_FACILITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security and walk to to vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T3_H1',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions: 'Locate in terminal 3 near gates H1.H2',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SIMMONS_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'West entrance right off Avenue Louis Pasteur',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2451',
          id: 'NY_TARGET_T2451__4024_COLLEGE_POINT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - POST EAST',
          id: 'MN_CANTEEN_POST_EAST__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MA_JAMAICA_PLAIN_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter in through the main entrance, Fridge is located by the elevator bank',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_EMIRATES_PRE_SEC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the Pre-Security side of Terminal D and walk to Emirates Desk. Fridge is in Vending bank on your right.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_SIUH_NORTH_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the doors on the left side of the loading dock. After entering the hallway, they will make a right when they get to the end of the hallway. After turning right, the cafeteria is on the right side at the end of the hallway (a Starbucks sign outside of cafeteria can be seen. Fridge is past the cashiers on the opposite side of the seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'American University - Eagles Nest',
          id: 'DC_AMERICAN_UNIVERSITY_EAGLES_NEST__4400_MASSACHUSETTS_AVE_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Kurt to Follow Up',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_GATE_12_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk from JFK T8 loading dock to Gate 12 and find the fridge located around the corner',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_A3_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ff1a3567-1cd6-46a5-bc6e-775575c363fc',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_TECH_TRUAX_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the doors, take a left, vending area will be on the left',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Sales_Eddie Cicero',
          id: 'IL_EDDIE_CICERO__5370_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'LEAVE AT CICERO FOR SALES TO PICKUP',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IN_Catering (comped) - Marketing_INDYGOLFTOURNY',
          id: 'IN_INDYGOLFTOURNY__499_SOUTHPOINT_CIR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'CALL SAVANNAH',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_NIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '79b8f892-ea26-46ae-9bad-0111fdfda008',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Lincoln Park Warehouse',
          id: 'IL_COSTCO_LINCOLN_PARK_WAREHOUSE__2746_N_CLYBOURN_AVE2746_N_CLYBOURN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_810__323-325_W_37TH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'DC_Catering (comped) - Sales_J Edgar Hoover Building',
          id: 'DC_J_EDGAR_HOOVER_BUILDING__935_PENNSYLVANIA_AVE_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park on 10th Street Side by the FBI Police Booth',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_PG Dayton',
          id: 'OH_PG_Dayton_Center__1800_Union_Airpark_Dr',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Once driver has their badge they should be able to go through the inbound gatehouse and can drive to the area where they are stocking.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_LINCOLN_PROPERTY__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WILLKIE_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host MSP - Bottle Rocket',
          id: 'MN_HMS_HOST_MSP_BOTTLE_ROCKET__4300_GLUMACK_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Deliver to MSP Bradford with instructions to be delivered to HMS Host Bottle Rocket location',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'IND_RIVER_CROSSING_II',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the front entrance into the lobby of the building',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Marketing_GLOSSLAB NYC EVENT',
          id: 'NY_GLOSSLAB__121_READE_STREET_APT_10L_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver on Tuesday May 18th to 121 reade street apt 10L new york ny 10013 between 12 - 2pm Contact: Hilary Sheinbaum 954.592.0478',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'U of St Thomas Houston Wholesale',
          id: 'TX_U_OF_ST_THOMAS_HOUSTON_WHOLESALE__3909_GRAUSTARK_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'GO TO THE BACK GATE AND CALL SEAN. SOMEONE WILL MEET YOU OUTSIDE AND ASSIST',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'University of St. Thomas',
        },
        {
          opportunityName: null,
          id: '166e1c5a-4b64-4acc-b7d3-c41e2fbf8e28',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_CARFAX_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Cristina at 571-398-4426. Take freight elevator up to 5. Turn right. Fridge in left corner.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - LYMAN LUMBAR',
          id: 'MN_CANTEEN_LYMAN_LUMBAR__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'USE BADGE AND DROP OFF IN FRIDGE',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_FROEDTERT_CFAC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CIELO_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door of building 1 (left most building) and the fridge is right in the lobby',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_TICKETING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the yellow lot: follow the signs to terminal, take the first elevators you see to 3 (sky bridge) take the bridge to the end and take those elevators to the ticketing level (TL)',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CSU_LONG_BEACH_MUSIC_DANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once you get to the building, go to the atrium where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_Canteen Laura Wheeldon 2',
          id: 'NJ_CANTEEN_LAURA_WHEELDON_2__190_HOMESTEAD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'front entrance to canteen offices',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_UNIQUE_ID_123',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_HOSPITAL_ARMOUR_ACADEMIC_4TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in main entrance, take elevators to 4th floor - find the Cash Stations sign  just past the escalator down to the cafeteria.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Boston Fire Department',
          id: 'MA_BOSTON_FIRE_DEPARTMENT__293_CONCORD_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Park on street in front of address',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_CONEY_ISLAND',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter front entrance. Check in with security and walk straight down hallway. Go through double doors and turn right. Walk to end of hallway and turn left. Take Elevators C to second floor. Machine will be right in front of elevators on the right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Feinstein Institutes for Medical Research',
          id: 'NY_FEINSTEIN_INSTITUTES_FOR_MEDICAL_RESEARCH__350_COMMUNITY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Call Delince Joint (347-546-9449) or Jorge Gomez (516-244-6781) when arriving on site.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_UPENN_SEAS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '**DAYTIME HOURS CALL Kilian Feeney C. 267-784-0053 ***\r\n\r\nWalk through double doors to the right. Take freight elevator up to 3. Fridge is located where the Levine building and Towne building intersect',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_PUBLICIS_GROUPE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'they will escort you from the dock to the floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, past the coffee shop, take the hallway to the ER, Fridge will be against the wall in that hallway',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b0952e3e-0300-4398-831b-573fd708cf26',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EVANSTON_HOSPITAL_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Same as previously',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_OVERLOOK_MEDICAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter front entrance. Check in with security. Go to the right and follow sign for main hospital hallway next to Cafe. Walk down hallway and take the elevators on your right to the 4th floor. Exit out and walk straight toward the cafeteria, there will be a room on the left with vending machines, our machine will be in there.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '3ba32057-9767-4f55-9e45-a16e2d2ac2f3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_GATE_40_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk from JFK T8 Loading dock through the tunnel to the other end of the terminal to gate 40',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Anaheim Medical Center',
          id: 'CA_ANAHEIM_MEDICAL_CENTER__3440_E_LA_PALMA_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Call Elaine Helmick and she will coordinate (949) 379-0588',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - MECCS South',
          id: 'MN_CANTEEN_MECCS_SOUTH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'PHI_CHOP_ED',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through double doors on left side of loading dock. Turn left at first hallway and take elevator to first floor. Exit and turn right. Walk straight and turn slight right down hallway. Keep walking straight passed security at main entrance and follow signs for emergency department. Go through doors on left toward elevators. Machine will be on the left side.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NJ_TARGET_T1084__2690_US_HWY_22_E',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Lake Zurich Warehouse',
          id: 'IL_COSTCO_LAKE_ZURICH_WAREHOUSE__680_S_RAND_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Cannot deliver before 6am due to neighborhood\r\nPlease be off the sales floor by 9:30am - enter through back door receiving',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'IND_MED_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Southlake Warehouse',
          id: 'TX_COSTCO_SOUTHLAKE_WAREHOUSE__2601_E_STATE_HIGHWAY_114',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'ANIXTER_HQ_NEW',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go around the back of the building to the loading dock and use badge to access through the door. Follow the hall until you reach double doors on the left and use badge again. Once your badge through the doors turn left and fridge is about 20ft straight ahead. Phone number for security onsite is 224-300-9001.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FEINBERG',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_CITY_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front entrance and vending bank will be down the hallway around the corner- ask security guard where vending bank is',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_IAH3 TASTING',
          id: 'TX_IAH3_TASTING__12510_W_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'LEAVE AT COLD STORAGE',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NEW_SCHOOL_39_W_13TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'come up through the loading dock freight elevator directly to the right of the entrance, will include additional picture. Will provide building contact',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson JFKT1',
          id: 'NY_HUDSON_JFKT1__JFK_AIRPORT_TERMINAL_ONE_BLD_55',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Drop to loading dock at JFK T1',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_UMASS_CAMPUS_CENTER_LOWER_EAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt to Follow up',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DCA',
          id: 'VA_HUDSON_DCA__2401_S_SMITH_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Drop to the loading dock and alert the receivers',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_CENTRAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'once you walk in the entrance, the fridge is to the right in the cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b59ff1bc-fe85-4c0b-ae96-9dcbcf060a61',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CONTINENTAL_TOWERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Castleton Warehouse',
          id: 'IN_COSTCO_CASTLETON_WAREOUSE__6110_E_86TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Park near receiving - off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MKE_FROEDTERT_CLINICAL_CANCER_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Techs and drivers park in loading dock B sign in security for access',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_PROCTER_AND_GAMBLE_CENTER_HILL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'come in the main door at the 6105 lobby, bade in and turn right into the cafe space, fridge will be on the left side of the cafe near the seating area.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Cincinnati Children's Liberty",
          id: 'OH_CINCINNATI_CHILDRENS_LIBERTY__7777_YANKEE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Go to loading dock (3 bays) kitchen is right behind the dock. Loading dock is on the north east side of the building',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Cincinnati Children's Hospital",
        },
        {
          opportunityName: null,
          id: '1c41da7b-22f7-41c5-b935-ddcd8905a2a2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk straight through the doors of the dock office, walk straight down the hallway, turn right once you see the elevators, take the elevators to level"P" , take left out of elevators and fridge is right there.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'afc4d34e-174c-4220-b45c-2f54ba04f1ec',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_GENESYS_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'fridge is right inside the cafeteria which is right inside front door',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_BEST_BUY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the visitor entrance and walk to the Caribou coffee',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_RONALD_MCDONALD_MINNEAPOLIS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Pull through the circle drive (look for the red heart on the door). The desk will have someone from 9am-5pm, if arriving outside of these hours, call into the phone and dial '000' and say that you're at oak st. w farmers fridge and you'll be buzzed in. Walk through the doors and fridge will be in the kitchen on the left",
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_PROTECTIVE_SERVICES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, straight down the hallway, when you get to the elevators take a left into the break room',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_340_MADISON_AVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3632',
          id: 'IL_JEWEL_OSCO_3632__3630_N_SOUTHPORT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'Costco - Strongsville Warehouse',
          id: 'OH_COSTCO_STRONGSVILLE_WAREHOUSE__16690_ROYALTON_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'IND_PURDUE_WALC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Drive straight through Centennial mall road, turn left onto loading dock. Walk into double doors and fridge is to left of room 1132 and will be placed on the far right next to other vending',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_NUECO__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "LA Children's Hospital",
          id: 'CA_LA_CHILDRENS_HOSPITAL__4650_SUNSET_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'deliver to the Anderson loading dock- located on sunset and lyman plaza. Ring the security intercom',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Children's Hospital of California",
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 275',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_275__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'CANTEEN - Graco Rogers',
          id: 'MN_CANTEEN_GRACO_ROGERS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'PA_PHI_MERCY_FITZGERALD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevator to the first floor. Walk down the hall towards the vending room on the left hand side.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Greyson Samples',
          id: 'NY_GREYSON_SAMPLES__149_N_6TH_APT_3',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Buzz the bottom button for Apt 3. Call Greyson 9083070006',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_VA_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go up the ramp on the right and put the code in the on the double doors on the right. Push ( Start 58486#). Walk through door following signs to the elevator. Take a left then a right. Don't take first set of elevators on the left. Take the second set and go to floor 1R. Fridge is right on the other side of elevators.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAH A',
          id: 'TX_HUDSON_IAH_A__2800_N_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drop to the address listed, upon arrival please call the manager line listed to be met by manager and deliver product',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'BRIDGEWATER_STUDIOS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the warehouse, entry the office door and walk 10feet then turn left and on your right you will see the break room area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AIR_LIQUIDE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, take freight elevator up to floor 9. Fridge is right in front of the freight elevator door. Can speak to the receptionist at the front desk upon entry to the building or call Mike at 713-256-2012 if you run into any issues',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University',
          id: 'IN_NOTRE_DAME__LAFORTUNE_STUDENT_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Stock Coolers',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'The University of Notre Dame',
        },
        {
          opportunityName: null,
          id: 'MN_US_BANCORP_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once they have unloaded into the loading dock. They can take the freight elevators up to level 2. Once on level 2 they will take a left out of the freight lobby and follow it down until they see US Bank branch and they are located directly across from the US Bank ATM’s. \n\nThey have 24/7 security. When the driver arrives at the loading dock they will buzz in and let security know what they are onsite for and they will provide access.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host SAT Ice House',
          id: 'TX_SAN_HMS_HOST_SAT_ICE_HOUSE__9800_AIRPORT_BLVD_SUITE_2090',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Deliver to warehouse and call delivery contact for instruction upon arrival',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: '3c552fa9-597e-49dd-bd0a-c5faf50f379d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host SAT Rosarios',
          id: 'TX_SAN_HMS_HOST_SAT_ROSARIOS__9800_AIRPORT_BLVD_SUITE_2090',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Deliver to warehouse and call delivery contact for instruction upon arrival',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'MDW_TICKETING_EMPLOYEES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD B21',
          id: 'IL_HUDSON_ORD_B21__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_RICHMOND_MED_CENTER_ER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Through the loading dock entrance and head toward the ER. Call Antonio Marti he will show you where to go.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_IRVING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'After walking through the main doors, take a left down the hallway, Fridge will be located in the micro-market just past the cafeteria',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Mishawaka Warehouse',
          id: 'IN_COSTCO_MISHAWAKA_WAREHOUSE__625_E_UNIVERSITY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drive to the back near receiving to deliver the product',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Guardian Refresh',
          id: 'CA_GUARDIAN_REFRESH__1051_LAW_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Call Tucker',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Morristown Medical Center 1',
          id: 'NJ_MORRISTOWN_MEDICAL_CENTER__100_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOSTON_MEDICAL_CENTER_ED',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The refrigerator is located in the Menino Pavilion. Park in the same area when stocking the other 2 Refrigerators in the cafe. The fridge is in Area B in the Emergency Department.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'WI_Catering (comped) - Sales_Appleton market',
          id: 'WI_SALES_APPLETON_MARKET__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'PLEASE CALL DC',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AIRPORT_GATE_B34',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through security walk to Concourse B and find fridge across from gate B34',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CONCORDIA_UNI_WI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WEST_ANN_ARBOR_HEALTH_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of the main entrance to the building, just pull all the way up, leave enough room for vehicles to get by and leave our hazards on.\nFridge is right by the main entrance on the right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Marijean Bechtold - Hudson',
          id: 'CO_MARIJEAN_BECHTOLD_HUDSON__4255_KITTREDGE_ST_1528',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please deliver to door',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Armstrong Student center',
          id: 'OH_ARMSTRONG_STUDENT_CENTER__550_E_SPRING_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call robin',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - KHN FORT HAMILTON',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_KHN_FORT_HAMILTON__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_BROOKLYN_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through kitchen, make a right. Second hallway make another right once you come out of the kitchen. You will see Starbucks after that. Manager will guide for first time.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_HARKNESS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through main entrance, go to second floor and walk through the connector of CHONY and Milstein hospital, fridge is located just passed the end of the walkway.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MAIMONIDES_DOCTORS_PAVILLION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building through the double doors main lobby. When entering the main lobby they will go to the right side around the lounge chairs and to the cafe. The fridge will be on the right side when facing the cafe where there is an outlet.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_MAIN_CONCOURSE_NORTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park and enter through East Entrance. Go straight down hallway, take first hallway on the left towards South Tower. Machines are in the open area by the Starbucks (South) and Gift Shop (North).',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MAYER_BROWN_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_VANGUARD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in, turn right, follow path until the end, turn left and the break room will be on the right half way up the building',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_VA_MED_CENTER_ICU',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take main entrance elevators to the third floor. Walk out elevators, take 2 lefts to the ICU waiting area',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_2444__944_S_YORK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson MSP 2297',
          id: 'MN_HUDSON_MSP_2297__MINNEAPOLIS_SAINT_PAUL_INTERNATIONAL_AIRPORT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Retrieve order from Bradford with fridge orders. Deliver to Hudson News next to Wild Bar in T2',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'CANTEEN - Super Radiator Coils',
          id: 'MN_CANTEEN_SUPER_RADIATORS_COILS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'RUSH_HOSPITAL_1750_HARRISON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in front door, take your first left into the break room.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Tell team you are there for Cobblestone Café in the CMSC Building\r\n\r\nIf during DAYTIME HOURS you can call Tim at 443-538-9766.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'FireFly Cafe',
          id: 'MA_FIREFLY_CAFE__101_SOUTH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Please deliver directly to manager from loading dock',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'WI_GOPUFF_136__2037_N_FARWELL_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call contact on arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALTA_K_STATION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_HOWARD_UNIVERSITY_UGL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the front door and Fridge is on your left near another vending machine',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_CUSHMAN_WAKEFIELD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors, security will escort you to the machine.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_1_C5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Signal Products',
          id: 'CA_SIGNAL_PRODUCTS__5600_W_ADAMS_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Call Jessica Flores when onsite to coordinate where to put it. O 213-748-0990 x134',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SOUTHERN_UNI_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to Student Center located in the middle of the campus.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_GWC_STUDENT_SERVICES_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Make your way to the student services building and the Fridge will be in the back right. Campus map will be provided',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '08b006d5-f42f-4735-b35a-19cd5d4db188',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_BENTLEY Samples',
          id: 'MA_BENTLEY_SAMPLES__175_FOREST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'na',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SUNNOVA_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in roundabout, head inside and go up to floor 5, the fridge is in the southwesat corner of the building in the lounge for Sunnova.  The Sunnova main entrance is on floor 5',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_GRANT_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CON_ED_LEARNING_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Romeo will walk you from the loading dock to the cafeteria.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M5_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_BRO_HEIGHTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the door of building D (you will need a badge). Walk straight, then take a left and walk through the telehealth triage door. Walk straight through the emergency exit only door and fridge will be on the left near the emergency exit',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_UMASS_HEALEY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to follow up',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_EXXON_TECH_ENG_COMPLEX',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go to the building through the security desk. Walk to the right of the desk into the corridor and you'll see the Avenue C on the right side. The kiosk will be set up on the left side of the Av. C if you are looking at it from the front.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_ARE_GRADLABS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Walk inside to elevators up to 3rd floor. KC to provide more detail\r\nIf on the weekends and you do not have a badge, dial the callbox operator using the callbox and verify you are with Farmer's Fridge",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_CCD_WEST_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3437',
          id: 'OH_TARGET_T3437__27249_CHAGRIN_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Target - T3305',
          id: 'MA_TARGET_T3305__34_CAMBRIDGE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in rear and entrer through the loading dock. Location opens at 6am. Produce end cap',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T3_BAGCLAIM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park gateway 7 between Doorway 3E and elevator shaft (glass elevator shaft)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEVRY_CHICAGO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FREDDIE_MAC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "come in with security, they’ll ask for drivers licenses, take elevator to 4th floor, go down the hall, then take another elevator to 31st floor, once they’re in there it will notify Joe Chera who will let them in. If for some reason it doesn't, his number is listed inside the elevator.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WILLKIE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_HEALTH_MASONIC_CAMPUS_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front doors, Fridge will be to the right at the start of the hallway',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAL_POLY_BUILDING_20',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main lobby and go to the study room on the first floor\r\n\r\nIf stocking on weekend, call RA on duty (909) 636-4867',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_510 Township Line Rd',
          id: 'PA_SAMPLES__510_TOWNSHIP_LINE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Come to the side of the building that says CCP on top.   The opposite side has Rothman and Verinext on the building. Call Kim, if no answer, please text',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: '320_SOUTH_CANAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KUEHNE_AND_NAGEL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take front entrance to first floor cafeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DLF 402',
          id: 'TX_HUDSON_DLF_402__1004_N._UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Group drop to Dallas Hudson Distribution center',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Diego Samples 2',
          id: 'TX_DIEGO_SAMPLES_2__5928_WOODWAY_PLACE_COURT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Call Diego at 346-269-8473',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_OCEAN_MC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through main entrance and take a left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '525_W_MONROE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Vernon CA Warehouse',
          id: 'CA_VERNON_CA_WAREHOUSE__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Vernon warehouse delivery, please refridgerate.  I will pickup at the warehouse Thursday morning 10',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ONE_OHARE_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HOPE_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HHSC Building Wholesale',
          id: 'TX_HHSC_BUILDING_WHOLESALE__4601_W_GUADALUPE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Pull up to the circle drive in front of the garage and walk straight into the cafeteria window and knock on the window',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUGGABLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_UH_CMC_ATRIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 24/7, Driver Entrance - Enter Building through loading dock, same as UH Wholesale, Driver Parking - Park in loading dock located off Cornell Rd, Park to Fridge Instructions - Go past freight elevators to personal elevator in receving area, take down to basement level, follow out of receiving area, take ramp up and head left, use elevators at the end of the long hallway and use to take to 1st floor, follow signs to Atrium Cafeteria, fridge is located against back wall',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WALGREENS_108_WILMOT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MOUNT_SINAI_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through front door, follow the hallway straight, take a right and walk down the hall until you hit another desk and then take a left and walk down the hall and the cafeteria will come up on your right side. (Also there are signs posted there that will lead you to the cafeteria)\r\n\r\nLOCK CODE Is: 911',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LAX9_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once parked, take the main front entrance in to the facility lobby. Use badge access for entry in towards security. Once through security please take a right into the 1st floor main cafeteria. The fridge is along the side wall of the larger cafeteria with the micro markets',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LEO_BURNETT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ANTHEM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b982c0da-d802-4b7f-9435-d4ce4d701af3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ESSENDANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_WBC_Event',
          id: 'IL_WBC_EVENT__875_N_MICHIGAN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'PLEASE LEAVE TRAIL MIX WITH THE STAFF AT THE STORE!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_HEINZ_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the main entrance, and the Fridge is straight back down the hall and on your right once you get to the cafeteria area.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3277',
          id: 'NY_TARGET_T3277__112_W_34TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Florham Park, NJ',
          id: 'NJ_LIFETIME_FLORHAM_PARK_NJ__14_FERNWOOD_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HORIZON_MEDIA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SAN_DIEGO_VA_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through lobby door, take a left down the first hallway, fridge located in hallway',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_MAIN_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "MI_MSU_MAIN_LIBRARY\nAddress - 366 W Circle Dr East Lansing MI 48824\nCustomer Friendly Location - MSU - Main Library. Located on the Main Floor near the Starbucks.\nDelivery Hours - 24 hours during Fall and Winter semesters, 8am-5pm otherwise\nDriver Entrance - Front Entrance\nDriver Parking - Park at front entrance of Library\nPark to Fridge Instructions - Take main doors and go straight past information desk. Turn left and it's all the way down the hallway on the right past Starbucks",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_USPS_INTERNATIONAL_EXCHANGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'you can turn right into the cafeteria, they will escort you to area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson GRR',
          id: 'MI_HUDSON_GRR__5500_44TH_ST_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'In our warehouse located in the basement.  The driver can call ahead and we can meet with him /her to show them where it is.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_530_FIFTH_AVE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'take the loading dock elevator down, then take the other elevator up to the main floor, area is right outside the elevator\r\n\r\nThe building is staffed with security 24/7 so someone is always at the desk. Building is only accessible by card access after 7pm M-F and all day on weekends. As long as FF team members are identifiable as FF employees security can let them in.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_TORRESDALE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk down the hallway towards the cafeteria',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kirkland & Ellis Houston',
          id: 'TX_KIRKLAND_ELLIS_HOUSTON__609_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'LOADING DOCK IS OFF OF TEXAS AVE. SECURITY WILL BADGE THEM UP TO THE 34TH FLOOR',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_EAST_QUAD_BLUE_CAFE__200_OBSERVATORY_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FLUSHING_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'The loading dock leads right into the kitchen area. Walk straight through the doors and it will open into the cafeteria. The vending wall is on your right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - MAN2',
          id: 'NY_DASHMART_MAN_2__125_MAIDEN_LANE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Johns Hopkins University',
          id: 'MD_JOHNS_HOPKINS_UNIVERSITY__3400_N_CHARLES_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'As you enter, take an immediate left down the ramp.  knock on door.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_HOLY_CROSS_SILVER_SPRING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk 40 feet, take left, walk straight. Take first bank of elevators to 2nd Floor. Fridge Straight ahead.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '493068b8-71fe-4661-ab06-a4bccbf219c4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '225_W_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WALGREENS_30_N_MICHIGAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_STEM_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address - 642 Red Cedar Rd East Lansing MI 48824\nCustomer Friendly Location - MSU - STEM Building. located on the main floor near the cafe.\nDelivery Hours - 7AM to 6PM\nDriver Entrance - Front Entrance\nDriver Parking - Park in the loading dock area, do not block loading dock.\nPark to Fridge Instructions - walk into main entrance, go straight until reaching the big machine display. Machine is on the left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MD_ANDERSON_DONOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park and go through the main entrance to the glass door and receptionist will escort you to the breakroom.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_IT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Chicago, IL',
          id: 'IL_LIFETIME_CHICAGO_IL__15_W_CHICAGO_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MW_Catering (comped) - Sales_Hudson ORD',
          id: 'MW_CATERING_(COMPED)_-_SALES_HUDSON_ORD__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop with Syed to Terminal 3 Post 9 at Hudson offices',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1800_HOLLISTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors, fridge will be on the wall on the right side before the hallway',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PAYLOCITY_LUNCH_ROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Buzz in, security will let the driver in. Sign in with security and they will give you a temp badge. Take freight elevator up to the 11th floor. Get off the elevator, turn right and the fridge will be at the end of the center counter.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon Culver City',
          id: 'CA_AMAZON_CULVER_CITY__9336_W_WASHINGTON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Navigate to 4162 Ince Blvd. Go to Gate 3 of the property, where they will enter. Check in with security. They will head down to the loading dock, security can will instruct them, and we will meet the driver down in the loading dock. Call DC if needed',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LGB6_REAR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the front entrance where to security for access. Take the front entrance past security towards the rear of the facility. The 2nd fridge is along the blue wall of the rear break room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JFK_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Kurt to follow up',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_TIFFANY_CO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter service entrance. Take freight elevator to 10th floor. Exit elevator turn right then left. Scan badge and go through door. Turn right then left and walk straight till you get to the vending room, machine is on the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Coon Rapids Warehouse',
          id: 'MN_COSTCO_COON_RAPIDS_WAREHOUSE__12547_RIVERDALE_BLVD_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Hudson PHL 2053',
          id: 'TX_HUDSON_PHL_2053__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -   Rt 4 SD Credit Union',
          id: 'CA_CANTEEN_VISTA_RT_4_SD_CREDIT_UNION__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (marketing) - new event Ruso test',
          id: 'IL_MARKETING_EVENT',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Test',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TEXAS_STATE_EVANS_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door, fridge is directly in front of you in lobby',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Honeywell Plymouth',
          id: 'MN_CANTEEN_HONEY_PLYMOUTH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b1c65c9a-8633-46bc-8068-5cfc1adc72de',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '518ac965-6ef6-4e77-b1b6-d81ea31334a5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ef38cd90-c283-441d-a7f8-990289044971',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'kekekekea',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host DTW - Jolly Pumpkin',
          id: 'MI_HMS_HOST_DTW_JOLLY_PUMPKIN__ROMULUS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Dropping to bradford',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'DC_VETERANS_ADMIN_CENTRAL_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside underneath the blue awning.\r\n\r\nUpon arrival, need to show Driver License, and team onsite will escort them to obtain a temporary pass.  \r\n\r\nSecurity will call the the Canteen to send someone to escort our driver. 202-461-5234 mobil # Escort',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lindsay Event',
          id: 'KY_LINDSAY_EVENT__4_TESSENEER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Berkeley Heights, NJ',
          id: 'NJ_LIFETIME_BERKELEY_HEIGHTS_NJ__25_CONNELL_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Scripps Research La Jolla',
          id: 'CA_CANTEEN_SCRIPPS_RESEARCH_LA_JOLLA__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            "Please note for deliveries made on Sunday's - a delivery cannot be made earlier than 9am. Approach gate and honk. There is a receiving gate to the left of gate",
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Scripps Research Institute',
        },
        {
          opportunityName: 'Costco - Mayfield Heights Warehouse',
          id: 'OH_COSTCO_MAYFIELD_HEIGHTS_WAREHOUSE__1409_GOLDEN_GATE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CA_PARAMOUNT_PICTURES_STUDIOS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'as of 7/1: fridge is temporarily located in the MAIN cafeteria.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WRIGHT C STORE_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_USPS_AIRPORT_MAIL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once parked there is a side entry door take that door which leads you directly into the employee cafeteria lunch break room',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS MKE - GAB',
          id: 'WI_HMS_MKE_GAB__5300_S_HOWELL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Delivery to dock and call the commissary number listed at the dock to let HMS Host know, number to call: 414-349-3245',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: '200_W_JACKSON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_CANTEEN_ONE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front door, turn right down main hallway. Fridge located in the employee lounge at the end of the hallway',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HEALTH_FORT_WORTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, there is a hallway to the cafeteria to the left, take that which will lead into the cafeteria, Fridge will be on your left against the wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__413_W_NORTH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LOVE_AIRPORT_GATE_16',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock and enter terminal where the fridge will be located post security across from Starbucks near gate 16.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BCH_LOBBY_DOUBLE_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver can contact Bill Toth when they arrive (617-308-7984).',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALLSTATE_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MERITER_HOSPITAL_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Upon entering the front entrance, take a right past security and the front lobby towards Guest Service. The fridge is located in the alcove along the main lobby hallway',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_RENTAL_CAR_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the front entrance and find fridge located within the check in facility to the right',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_QUEENS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'S_C_ELECTRIC_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Verona Warehouse',
          id: 'WI_COSTCO_VERONA_WAREHOUSE__1000_BATKER_COURT',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'Drive to the back near receiving to deliver',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NYC_JAVITS_MEZZANINE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN SOUTH VIEW',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_SOUTH_VIEW__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'HARPER_COLLEGE_BUILDING_L',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on the curb at the front of the parking lot near building Z, follow the ramp up, take a left once inside the doors, take the hallway down all the way and then go right, Fridge will be on left near windows. Security #847-925-6330',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c36edd2b-445b-4959-bc55-9b54f6f7e642',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CRYPTO_ARENA_MAIN_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge located on the upper concourse level across from the All In food stand outside of section 301-302. Security phone # is 213-742-7444, Jessica Pena 213-742-7885',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1889',
          id: 'IL_TARGET_T1889__1154_S_CLARK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'The loading dock is the first light after Roosevelt coming down Clark. Make a left and drive around parking garage to the back you’ll see loading dock\n\nLook for John',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: '46211adf-9602-4c08-b0b8-92113046d739',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_EMERALD_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the through the loading dock to the lobby. Go to security desk and take freight elevator down to G1 (garage floor). Fridge is right near the elevator.\r\n\r\nCall Paris Cox with issues 619-398-8953 or Teresa Souris  619-993-1316',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Skokie Hospital Earth Day Samples',
          id: 'IL_SKOKIE_HOSPITAL_EARTH_DAY__9700_GROSS_POINT_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'ATTN: SK Patient Food Service 1309',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BLUE_MARKET_AT_UNION__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ABT_ELECTRONICS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park around the back side of the building, security will open the gate, follow the yellow taped off walkway to get to the elevator, then the lunch room is on the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'a472c7dd-504f-4fe7-831a-15970b5519cf',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HACKENSACK_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From the loading dock it is a short distance of 300 feet to the fridge on the floor.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5bd3b551-963f-46a8-a47d-e99632087a7f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3475',
          id: 'IL_JEWEL_OSCO_3475__16_S_WAUKEGAN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "LOCATION: In deli cooler located to the left of meat and cheese section, with Boar's Head sign above it. DELIVERY: Judy is receiver. Marilyn is deli manager. Product must be scanned in. Receiving is closed on Wednesday and Sunday.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'NYC_LEHMAN_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter doors and turn right. Fridge will be there near stairwell. Bring vax card.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b007d424-ab13-4c42-a042-cd81ec6c78b1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - LIFESOURCE',
          id: 'MN_CANTEEN_LIFESOURCE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JLL_AON_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIEND_HEALTH_COTTAGE_GROVE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors and the cafe will be on the right. Fridge will be up against the back wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DOVER_EMPLOYEE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the loading dock, ring the bell, which alerts the security guard whether they are at their post on the 1st floor or walking around with a phone. They will allow the driver in that way. Go up to 3rd floor, the employee cafe is to the left out of the elevator',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_EXXON_BTEC_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park outside the building and go to the second floor. We are outside the nook in the hallway when you leave the elevator keep going left 10-15 steps.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Northwest Community Hospital Earth Day Samples',
          id: 'IL_NORTHWEST_COMMUNITY_HOSPITAL_EARTH_DAY__800_W_CENTRAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'ATTN: NCH Patient Food Service',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Medical Center at Timonium',
          id: 'MD_MEDICAL_CENTER_TIMONIUM__2118_GREENSPRING_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park on North side of the building Call Steph',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MN_SAINT_PAUL_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'After arriving at the dock, there will be a set of double doors on the right hand side- go through the double doors and take a left. There is a long hallway- take that to the intersection and take a right. Continue on until you see the vending area on the right hand side.\r\n\r\n**Mary is the vending POC and can show the driver where to go**',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_CCD_7TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in the loading zone across from UCMED Comer. Enter through the front entrance and you will see a set of elevators across from you. Use your badge and take the elevator to the 7th floor. Once out of the elevator take an immediate right, and to your right is a set of double doors. Use your badge to get through the double doors and go down to the end of the hall. Take a right and the fridge will be in the corner on your left.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - ABBOTT MAPLE GROVE',
          id: 'MN_CANTEEN_ABBOTT_MAPLE_GROVE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Costco - W Katy Warehouse',
          id: 'TX_COSTCO_W_KATY_WAREHOUSE__23645_KATY_FREEWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Hudson SNA Sample Pack',
          id: 'CA_CATERING_(COMPED)_SALES_HUDSON_SNA_SAMPLE_PACK__18601_AIRPORT_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Curbside delivery, call the delivery contact upon arrival for pick up',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Chris Samples',
          id: 'IL_CHRIS_SAMPLES__1448_N_ORLEANS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call me and Ill come out to grab the package',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_UNIVERSITY_OF_ST_AUGUSTINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Check in with Security in BLDG A.  Then walk through door to smaller building (on the right hand side) with chairs/tables outside - this is the building with the kitchen/cafe.  We're in there with the Canteen micromarket",
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6a8b2ffd-bf43-46da-ac92-a9aeb6688215',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ST_PETERS_FAMILY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_B10',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Park in loading dock for B. Enter Terminal B, Walk to Fridge',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Johnson and Johnson',
          id: 'NJ_JOHNSON_AND_JOHNSON__1000_US202_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'mailroom receivers are stationed at the loading dock and can direct the driver-but, they would go through the loading dock/warehouse',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAP_MUSIC_GROUP_959',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk through courtyard, walk through lobby, enter front door, check-in at lobby, use elevator on right that's freight elevator. Suite 200.\r\n\r\nNo pallet jacks are allowed in the lobby; if using a dolly, it must have rubber wheels so as to not damage the lobby flooring.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FEDEX_INDUSTRY_CITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at side loading dock and buzz or ring into security. Security will escort you to the main breakroom.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CRYPTO_ARENA_SUITE_LEVEL_A1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through vendor entrance\r\n\r\nLocated on premium level A, across from premium seats 5-6\r\n\r\nSecurity phone # is 213-742-7444\r\nJessica Pena 213-742-7885',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SMS_ASSIST_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_14',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Canteen MN Mystic Lakes Farmers 2',
          id: 'MN_CANTEEN_MN_MYSTIC_LAKES_FARMERS_2__700_24TH_AVE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Call Bryan Eckes at 7152079322',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1854',
          id: 'TX_HUDSON_PHL_1854__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_A4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'IN_MARTINS_15__120_SANFORD_SCHOOL_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HINES_VARICK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If through front entrance, go past the turnstiles on the left side of lobby and the lobby room is to the left. \r\nLoading dock is only open 8am-6pm. Loading Dock Location and Access: (646) 392-8828\r\n\r\nThe Loading Dock for 75 Varick Street is located on Watts Street. Loading dock hours are Monday through Friday 8 a.m. to 6 p.m, and closed on weekends and holidays. 75 Varick Street’s loading dock contains an internal loading platform capable of efficiently serving 4 trucks at one time.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Yankee Executive Office',
          id: 'NY_YANKEE_EXECUTIVE_OFFICE__164TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'the office entrance is adjacent to gate 2, they can go through security doors and drop them with the desk',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_ARRIVALS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk from JFK T8 Loading dock to arrivals area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3479__79_MCHENRY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Princeton, NJ',
          id: 'NJ_LIFETIME_PRINCETON_NJ__7_FORRESTAL_RD_S',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_PUBLIC_STORAGE_CORPORATE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Once you're parked, security will direct you towards the loading dock. Upon entering the rear doors, take the elevator on your right up to the 2nd floor. Go thru the double doors and take that straight ahead. The 1st door on your left takes you to the break room.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Hudson Employee Samples',
          id: 'IL_HUDSON_EMPLOYEE_SAMPLES__2447_EAST_HIGGINS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "Deliver to the front door of Hudson's office",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MKE_BREWERS_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_CAFE_32__911_NORTH_UNIVERSITY_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'side loading dock on fletcher St.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MOJO_BLUE_MARKET_AND_CAFE__200_OBSERVATORY_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHSHORE_CARDIOVASCULAR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From ED: Walk down the hall, take first left, walk down the hall, take right at elevators, walk through door, take left towards main lobby, take first right past bathrooms, fridge will be on the left\n\nIf using main entrance: walk past front desk, take first left, fridge will be immediately on the left',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_BERGSTROM_TECH_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: "we're right in the lobby of building 310",
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f7fff273-200d-4ab0-a9c6-58c3d53cd1bc',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TRINITY_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the back door, down the hallway, take a left, Fridge will be on your left in 10 feet',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEALERS_INSPIRED',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UC_CLERMONT_PETER_JONES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take main entrance to main elevators, take that to LL, take that to cafeteria',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hy-Vee 1035',
          id: 'IL_HYVEE1035_1403_N_VETERANS_PKWY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_USPS_ANNEX',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Take the loading dock entrance and walk straight ahead into the facility. You'll take the first left through the facility towards the pink double doors.Badge into the main entrance of the building which leads you to the lobby. Once in the lobby take the elevator to the 2nd floor and go left off of elevators. Follow the cubicles towards women's rest room and go left. You'll head towards the open lunch room seating area where you'l find the vending bank. Machine is along the wall of vending machines\r\n\r\nthe annex is not 24 hour access due to the sensitive information stored there. The hours you have listed are when they will have other folks on site to maintain security",
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Brooklyn',
          id: 'NY_PURE_GREEN_BROOKLYN__95_BOERUM_PL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop off to store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'eb8b7c91-ce5c-4f55-b631-e35951c0d29c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_RUSH',
          id: 'IL_RUSH__1750_W_HARRISON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'CALL MOHAMMAD WHEN YOU ARRIVE AT THE RAMP AND THEY WILL COME GET IT! 708-415-8533',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_FENWAY_ORDER',
          id: 'MA_CATERING_(COMPED)_SALES_FENWAY_ORDER__175_LPSWICH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call DC and go to loading dock!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3456',
          id: 'IL_JEWEL_OSCO_3456__411_NORTH_GREEN_BAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: Deli cooler in basement. OTHER DELIVERY INSTRUCTIONS: Product will be stocked in cooler in marked FF Space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'Raytheon El Segundo',
          id: 'CA_RAYTHEON_EL_SEGUNDO__2000_E_EL_SEGUNDO_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Enter main gate and sign in',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CVS_137_STATE_CHI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COLLIN_COLLEGE_PLANO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, take a left, Fridge will be straight down the hallway against the wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CHI_Catering (comped) - Marketing_SARAH M CHICAGO EVENT',
          id: 'IL_SARAH_M__5859_N_MERRIMAC_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Delivery anytime on Wednesday works great! \n5859 N Merrimac Ave - Chicago IL 60646\n60 meals"',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe AZ',
          id: 'CA_QUALCOMM_CAFE_AZ__10155_PACIFIC_HEIGHTS_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Mount Laurel, NJ',
          id: 'NJ_LIFETIME_MOUNT_LAUREL_NJ__3939_CHURCH_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Memorial Hermann Southeast Samples',
          id: 'TX_SAMPLES_MEMORIAL_HERMANN_SOUTHEAST__11800_ASTORIA_BOULEVARD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Please drop off with their normal wholesale order - if you see anyone on-site while dropping off please call out that these are the samples',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_WOODLANDS_ER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "go through the loading dock into the main hospital and follow signs for the ER.  We're in the vending room on the 1st floor.  We already deliver wholesale here, we're just on the other side of the hospital from their cafeteria.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_SPRING_MILL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_CONCOURSE_C',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn left and walk to C gates, find fridge at the beginning of C concourse',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_TMC_REDS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "go through laoding dock, head up to floow two, follow signs for Red's Cafe, it is on the East side of the hospital near the coffee shop.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_WACevent',
          id: 'IL_WACEVENT__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to coolers in the back off the office by the test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Takeda',
          id: 'MN_CANTEEN_TAKEDA__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'IND_COMM_NORTH_HAV',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - WHOLESALE TOMATO PLANT',
          id: 'MN_CANTEEN_WHOLESALE_TOMATO_PLANT__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ARRIVE_LOGISTICS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go through loading dock in the back, the fridge is on the WEST side of the building',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2b10abc4-68f9-4eb0-9267-c4e8eb57053a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_CHOP_ROBERTS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1545',
          id: 'MN_HYVEE_1545__3505_BOTTINEAU_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MARQUETTE_ALUMNI_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk or take elevator upstairs, go into the dining area and fridge will be near the vending machines',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_HEALTH_MAIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'enter through main entrance, turn left until you dead end into the reception desk, turn right and keep going straight until you see the mcdonalds and we will be across from McDonalds',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TESTING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_AIRPORT_CD_LINK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Bill will arrange for someone to escort driver on first day of stocking\r\n\r\nFrom fridge in terminal A/B link to second fridge - Turn around and head back toward terminal B to get to the second fridge. Walk passed security and walk towards terminal C/D. Walked passed next security point and stay left. Continue toward terminal D. Machine is on the right by united club.\r\n\r\nFrom loading dock - Have to leave product in the dock and go through security check point and go back to retrieve it. Exit dock and enter through terminal B main entrance. Go to second floor and go through B security check point. Go around security and turn right and walk down hallway. Go through black door with yellow tape on the left and take elevator down to dock level. Hold button to open and close elevator. Walk straight out of elevator. Walk down to a walk and turn right and go through door BT1018. Go back the same way you came with your product and go to the same elevator and take it to floor 2.exit black doors and turn right. Walk passed security and walk towards terminal C/D. Walked passed next security point and stay left. Continue toward terminal D. Machine is on the right by united club. \r\n\r\nRaven (security) - 215 669 8388\r\nSupervisor number - 215 852 1193 \r\nIf anyone gets confused or lost we can contact them or bill for assistant finding machines. \r\n\r\nLoading dock is open from 4am-2pm Monday - Friday  \r\n\r\nDon’t use moving walkways or escalators.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_University of Maryland Medical Center Midtown',
          id: 'MD_UNIVERSITY_OF_MARYLAND_MEDICAL_CENTER_MIDTOWN__827_LINDEN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Meet Matt Lynch (Sales) to drop off samples',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'IN_MARTINS_16__12850_STATE_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_LIVONIA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map pin: https://maps.app.goo.gl/SRJB6h39WtZhRUNPA\nEnter through the South Entrance if 6a-8pm.\nIf before 6am, enter via ER (right next to South Entrance)\nFrom South Entrance, walk straight and the fridge is to the right near the coffee shop.\nFrom ER, follow the hallway to the right along the windows. Once you hit the South lobby, the fridge is across the lobby from you.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_KINGS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through main entrance of D building, take the left past the security desk and the fridge will be in the hallway',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CO_Catering (comped) - Sales_Hudson News - Denver',
          id: 'CO_HUDSON_NEWS__9100_PENA_BLVD_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Contact delivery contact upon delivery. This will be a air shipment',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_EASTFIELD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk through the front doors, take the elevator down one level, Fridge will be straight ahead against the wall. When you get to the Fridge you'll see what use to be a Subway to your left",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_SW_INFLIGHT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security at this location.\r\n\r\nBy Main Entrance',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_US_COURTS_ADMIN_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk down the path to ring the bell, security will let you in. Walk down hallway passed the elevators. Fridge in cafeteria',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_WASHINGTON_TOWNSHIP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through clear double doors, not loading dock. Take this hallway down to the cafeteria (on the right, in the same hallway)',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_REPAIR_BIT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Hours: 7a-4pm\nMap pin: https://maps.app.goo.gl/fZtipYUnj6sWN1Bx8\nInstructions:\nGoogle maps/map pin is pretty close on this one.\nBuilding is located in an old industrial park and does not have signage other than the address.\nFollow the cones into the gated lot, and park opposite the two entry doors.\nEnter via the "customer entrance"\nCall Bob for admission to the building\n440-409-1363\nFridge is located in the lobby just past the vestibule.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Franciscan Children's Hospital -- Kitchen",
          id: 'MA_FRANCISCAN_CHILDRENS_HOSPITAL_KITCHEN__30_WARREN_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park at the loading dock around the back of the hospital and call 781-354-1075 to help guide you to the kitchen.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ST_ELIZABETH_COVINGTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: "it's right when you walk in",
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe Q',
          id: 'CA_QUALCOMM_CAFE_Q__6455_LUSK_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_ASCENSION_FRANKLIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go in the lobby, make right and head down the hallway past the Cafe -- through double doors -- after the two tables and computer by the window it is right',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_HUMAN_ECOLOGY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front entrance, take a left down the hall, elevator will be on the right. Get off elevator on 2, turn right, and the fridge will be across from Ember Hall on the left **located in the robins nest coffee area**',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_2allcustomerorders',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_WHOLE_FOODS_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go in loading dock - use badge to get in - take freight elevator up to 2nd floor - the kitchen is on the east end of the building and the fridge is all the way at the end of the kitchen',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WEST_SUBURBAN_MEDICAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TN_Catering (comped) - Sales_Hudson BNA Samples',
          id: 'TN_CATERING_(COMPED)_SALES_HUDSON_BNA_SAMPLES__1_TERMINAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Deliver through Third party to Bradford at Nashville International Airport',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Pure Green - Greenpoint',
          id: 'NY_PURE_GREEN_GREENPOINT__5_BLUE_SLIP',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop off to store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DISCOVER_FINANCIAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_SAMSUNG_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '701_E_22ND',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '321_NORTH_CLARK_TENANT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter in through the front door, check in with security and head towards the elevators on the left of security desk. Go to the third floor and follow sign to Tenant Lounge Suite 375. \r\n\r\nFridge is located on the right side of suite.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LGB6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security to your right where you'll find the fridge in the main break room cafeteria.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_JUNE25-EDDIE-SALES',
          id: 'TX_EDDIE_SAMPLES__120_BFG_9000_WY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'EDDIE TO PICK UP AT AIRPORT',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_NORTH_HARRIS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in visitor parking, walk into Student Services building (red roof, the "main building") check in with security, and the fridge is directly on your left in the old coffee shopt.\r\n\r\nFriday access is different: 7a-6p\r\nSaturday access is 9a-2p',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MAINSTAY_SUITES_WHEELING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_PEDIATRIC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_1700_MARKET_ST_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk towards main lobby. Fridge on right side of lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW A9',
          id: 'TX_HUDSON_DFW_A9__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 4',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_4__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_1_FEDERAL_STREET_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The Federal Street entrance is open 24/7.  All other lobby entrances are open from M-F from 8am-7pm with the loading dock open M-F from 6am-6pm. \r\n\r\nIf entering through the Devonshire Street entrance, take a right and the fridge will be located on your left next to Josie Greens & the shoe shine station.  \r\n\r\nIf accessing the Federal or Franklin Street entrance, look for the curved glass in the middle of the lobby and follow it towards Devonshire Street.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_HOUSING_AUTHORITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge can be picked up from security desk and then returned upon leaving. This will get you access to the 7th floor.\r\n\r\nIf any issues, call Andre Coleman first - \r\n312.802.2113\r\n\r\nIf Andre doesnt answer call one of the following contacts:               \r\nMike Ringo – 312.786.3217\r\nDave Pulley-  312.913.7158  \r\nJohn Young -312.913.7556\r\nMiguel O’Campo- 312.786.3248',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MERCH_MART_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front go through the main doors, Front door on the riverside,W Merchandise Mart Plaza Lane where the cab line is, make a left on to the main hall. The machines will be located on your right. Go through handicap doors.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_HUNTINGTON_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the front entrance up the escalators or elevator to the lobby. The fridge is located in the main hallway of the lobby,',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_FRIDGE_NJ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_PAVILION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator down to ground level to cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_GBMC_EMERGENCY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk towards the ED from the current fridge in the hospital.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_SOCIAL_WORK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go to 2nd floor, cafe room is to the right off the elevator. call roger when onsite 212-851-2208',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWHEALTH_EAST_MADISON_HOSP',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the first floor vending area, fridge is located in vending area',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_HOSPITALS_LINCOLN_MED_CENTER_BREAKROOM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through loading dock, NOT front entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Syosset, NY',
          id: 'NY_LIFETIME_SYOSSET_NY__350_ROBBINS_LANE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'US_CELLULAR_PLAZA_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'You can access the property through the 8420 building (middle building) there is security there 24/7. Pull through the dock, follow hallway to main lobby, turn right at main lobby, fridge will be by the ATM near the walkway.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Broadway',
          id: 'NY_PURE_GREEN_BROADWAY__4026_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop off to store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MAYER_BROWN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENSBOROUGH_CC_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Officer will instruct you on the best place to park (it will likely be the main parking lot) and then you can walk to the Student Union building and head to the basement vending area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_PSP1_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security down the hall to the secondary break room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RICHMOND_MED_CENTER_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Through the loading dock entrance and head toward the main lobby. Call Antonio Marti he will show you where to go.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_180__3801_CHESTNUT_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_REECE_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, take the hallway straight down to the lunch room',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St David Round Rock',
          id: 'TX_ST_DAVID_ROUND_ROCK__2400_ROUND_ROCK_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Please call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "St. David's HealthCare",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_EXXON_HQ_SHADE_NATURE_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Badge into the large roll up door and walk across the tunnel to the double kitchen doors. You will also need to badge there for access. Once you are inside the kitchen, take a LEFT and you will see the elevator that will take you up.\r\n\r\n In the elevator, push the button for the first floor.\r\n\r\nWhen you get to the first floor, you will exit the elevator and turn RIGHT. You will immediately see a door that exits into the Cafe' . Go out that door and take a LEFT and walk around the grill station. This is where you will find the Farmer's Fridge location.\r\n\r\nIt is the same route to go back to the dock.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Kaiser West LA Medical Canter',
          id: 'CA_KAISER_WEST_LA__6041_CADILLAC_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Jennifer when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW7_FTW9',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security, take a left to the breakroom, once in the breakroom turn left and the space is half way down the wall on the left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3314',
          id: 'IL_TARGET_T3314__4631_W_FOSTER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park in the loading dock around the building.\n\nCharles is the dock supervisor in case Charles isn’t available Tenishia is the market team lead.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'NORTHWEST_COMMUNITY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at main entrance, walk inside and keep walking straight down the hallway. End of hallway turn RIGHT and keep going following signs for "Oasis Cafeteria". Walk down the white hallway until you see "Oasis Cafeteria" sign and our fridge will be to the RIGHT by the seating area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CITY_HALL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "walk straight and right before you reach the main hall, there is a stairwell on the left hand side. the fridge is underneath the stairs\r\n\r\n#'s to call for access  312-744-3650 or 312-744-9111",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_OLYMPIA_GYMNASTICS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map pin: https://maps.app.goo.gl/69i7hD95tL8Fttux7\n Walk in the front doors, walk past the receptionist desk and take a left.  Fridge is dead ahead against the back wall.\nHours:\nMonday: doors open at 3:30 pm\nTuesday: 9:00 am\nWednesday: 9:00 am\nThursday: 1:30 pm\nFriday: 3:30 pm',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_LOLLA',
          id: 'IL_LOLLA__337_E_RANDOLPH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'DRIVER WILL HAVE TO ENTER AT E 18th Dr & S Dusable Lake Shore Dr AND CALL ALAYA WHO CAN MEET THE DRIVER',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Costco - Easton OH Warehouse',
          id: 'OH_COSTCO_EASTON_OH_WAREHOUSE__3888_STELZER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Hudson IAD - C28',
          id: 'VA_HUDSON_IAD_C28__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_INDICOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, Fridge will be in the breakroom to the left.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - NW Columbus Warehouse',
          id: 'OH_COSTCO_NW_COLUMBUS_WAREHOUSE__7300_STATE_ROUTE_161',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to back by receiving and delivery through door',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_Rivers Casino Philadelphia',
          id: 'PA_RIVERS_CASINO__1001_N_DELAWARE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'If the gate is down, there is a button to call Security to open the gate. The Receiving area has a dock and Receivers.   Text 267-570-6226 when you are here if you need anything.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_IAH3',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door, turn left after checking in and the fridge will be in plain view in the main breakroom',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Bell Flight Headquarters',
          id: 'TX_BELL_FLIGHT_HEADQUARTERS__600_EAST_HURST_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_UPS_BEDFORD_PARK_ROOM_143__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_330_E_KILBOURN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OAK_BROOK_POINTE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_AMAZON_MTN9',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front and badge in. Walk to the right to the main breakroom',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Northbrook, IL',
          id: 'IL_LIFETIME_NORTHBROOK_IL__1100-SKOKIE_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_553__1860_FLATBUSH_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_NAVYPIER',
          id: 'IL_NAVYPIER__600_E_GRAND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop off at the front of the Pier and call Colleen: 708-205-5336',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNITED_INFLIGHT_TRAINING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park on the back of the building. Get badged and Go to the breakroom',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_AIRPORT_GATE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver will have to check in food through dock master. Driver will go through security and get food post-security.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AA_LOUNGE_ORD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'End of terminal T3 K19, elevators on your right hand side, using badge and 4 digit pin code, go to lower floor.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Trinity Crusin [RT 22]',
          id: 'IL_CANTEEN_TRINITY_CRUISIN_RT_22__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'DROP OFF',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Hermel Vending Wholesale',
          id: 'MN_HERMERL_VENDING_WHOLESALE__5401_OPPORTUNITY_COURT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'GO TO LOADING DOCK (AFTER DRY RUN YOU SHOULD RECEIVE A BADGE AND AN ALARM CODE)',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Hermel Vending',
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_UNIVERSITY_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the dock doors, take the elevator up one floor, take a right out of the elevator, Fridge will be around the corner',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '155_E_SUPERIOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Siemens',
          id: 'NJ_SIEMENS__200_WOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Behind the building there is vendor parking and freight elevator access.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MO_STL_MERCY_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Tower B just inside the main entrance',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Horizon Therapeutics',
          id: 'IL_HORIZON__1_HORIZON_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CSU_LONG_BEACH_CSPACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the building and the Fridge is located on the second floor',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SHEDD_AQUARIUM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park by dock, walk in ask security for badge. Walk past security, turn left, walk straight, take another left, keep going until you can turn right. Keep going straight, you will reach a gray door located next to "Temp Fresh Recovery" room. Use badge to open door, after you go through turn left. Walk until you see a freight elevator, in elevator press "1M Education". Use other side of elevator to exit, follow hallway down to door, walk through, and you will see "Lunch Room/Vending" on your left. Go inside fridge is there.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_343_THORNHALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter main entrance. Turn right, Fridge is right in lobby',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_ARNETT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_CAPITAL_HEALTH_EAST_TRENTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Security will let you in will direct you to the fridge',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_TWO_22_TENANT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'They will buzz at the entry to the dock to security. Freight elevator is right on the dock and they do not need special access to the 2nd Floor. Security will open doors to the tenant lounge',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Palantir 3',
          id: 'NY_PALANTIR_3__416_W_13TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Please deliver to the 7th floor AOA',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3243',
          id: 'NY_TARGET_T3243__1715_E_13th_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AREA_23',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Hudson CLE Sample Pack',
          id: 'OH_CATERING_(COMPED)_SALES_HUDSON_CLE_SAMPLE_PACK__5300_RIVERSIDE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Drop to Cleveland Bradford with instructions to be delivered to Cleveland Hudson',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_3061__1150_W_MAPLE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'BACKSTOCK: In deli cooler, behind deli to the right of where we stock product - 2 identical doors, store our product inside door on the right. DELIVERY: Steve (receiver) Marley (deli manager). Call store and speak with customer service if needed',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Costco SE samples',
          id: 'IL_CATERING_(COMPED)_SALES_COSTCO_SE_SAMPLES__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Drop to fulton office in kitchen coolers',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'WALSH_GROUP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevators up to 2 and fridge will go in the 2nd floor lunchroom in the main office area. Grab a Vendor badge from the receptionist and return upon leaving. Any issues, call Jim Kerr (773-490-8443).',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_TikTok NYC',
          id: 'NY_TIKTOK_NYC__151_42ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'call 570-371-8196 to Peter Albano',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_NORTH_CENTRAL_BAPTIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk just past the Starbucks and our Fridge will be on the last pillar of their dining area',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_WUXI_NAUB__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Commerce Township Warehouse',
          id: 'MI_COSTCO_COMMERCE_TOWNSHIP_WAREHOUSE__3000_COMMERCE_XING',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to the back and deliver through receiving.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NJ_MORRISTOWN_MEDICAL_CARDIAC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'It is a big and complicated hospital. We will get a map of the hospital and get directions from them and will update at a later date.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_4308_1151_S_ROSELLE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AMAZON_LDJ5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen- Personalization Mall [Route 17]',
          id: 'IL_CANTEEN_PERSONALIZATION_MALL__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_CHILDRENS_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take a left, go straight until you see the cafe through the sliding glass doors\r\n24 hour access - Need to use main entrance',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_C4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update when process is finalized.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_ROBBINSVILLE_EAST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Follow signs for the break room EAST',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_EDELMAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '150_N_MARTINGALE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_FREDDIE_MAC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the elevator to the second level, walk through the doors, take a left, walk to the dining area which is in the back',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - ABBOTT ROSEVILLE',
          id: 'MN_CANTEEN_ABBOTT_ROSEVILLE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MKE_MCW',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'fb4ac0cc-0d45-44e5-85d8-0a313c13ec1d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FOUR_PARKWAY_NORTH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_264__6437_N_AVONDALE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T5 M5',
          id: 'IL_HUDSON_ORD_T5_M5__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 5 Loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'B&B Bodega',
          id: 'TX_MOGO_MARKET__5610_VILLAGE_GLEN_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Walk from the loading area to the market, load the cooler shelves which will be just inside the door to the left',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'The Village Dallas',
        },
        {
          opportunityName: null,
          id: '4901_SEARLE_PARKWAY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the dock, check in with Security and they will provide access to the building. Go through the double doors and turn left, head straight to the freight elevator, go to floor 1, turn right out of the elevator and go down the hallway , turn right then immediately left to continue down the hallway, turn right about half way down (should see water fountains straight ahead), turn right and Fridge will be there.\r\n\r\nSecurity phone # 847-568-8420',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Arcadia University',
          id: 'PA_ARCADIA_UNIVERSITY__125_ROYAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Mimi upon arrival 215-572-2941',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'IND_ESKENAZI',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'st.Enter through main lobby or loading dock (loading dock on fairbanks street. fairbanks st. runs parallel  to eskenazi ave and st. margarets st.) enter through main doors, walk up staircase and continue straight until you hit breezeway. fridge is located directly inside',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f0c0489a-d73c-4d66-a8f1-4bd7cc308cbf',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BIDMC_ROSENBERG_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Kurt to follow up with detailed instructions',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ronald Reagan Washington National Airport (DCA)',
          id: 'DC_RONALD_REAGAN_WASHINGTON_NATIONAL_AIRPORT_DCA__2401_SMITH_BLVD_DOCK_B',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            "As you're approaching, there are signs everywhere for Dock B. All vendors call Saskya 20-30 mins before delivery so that they’re ready to meet them. Strongly Preferred after 8:00am delivery",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Orange Theory',
          id: 'IL_ORANGETHEORY__3010_N_ASHLAND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL Jennifer Lutenski',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CAROLINE',
          id: 'IL_CAROLINE__5370_S_CICERO_ST',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please hold at Cicero for pickup!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Duolingo Pittsburgh',
          id: 'PA_DUOLINGO_PITTSBURGH__141_S_CLAIR_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Call Rick',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3293',
          id: 'CA_TARGET_T3293__965_TOWN_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'OHARE_3H_LEFT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Fort Washington, PA',
          id: 'PA_LIFETIME_FORT_WASHINGTON_PA__375_COMMERCE_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_MERCY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If overnight, I am going to ask that they have badge access to the employee entrance. Only a hand truck will fit through there and they can park right in front of the main entrance.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_1801_CONGRESS_MICROMARKET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go in the front door, get courier badge, go to floor 6, take a right out of elevator, there is a cafe on the lefthand side of the building.  The micromarket is behind a closed door in the seating area of the cafe.  The room is 6.90',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Kaiser Woodland Hills',
          id: 'CA_KAISER_WOODLAND__5601_DE_SOTO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Cori when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MD_ANDERSON_FACULTY_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk into the building to the front desk. Ask for Faculty Lounge.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HONDA_DOWNTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front and enter via sales entry side',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_SUENOS',
          id: 'IL_SUENOS__337_E_RANDOLPH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL ALAYA  281-975-9867',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Austin Route Samples',
          id: 'TX_AUSTIN_ROUTE_SAMPLES__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Call Eddie Sak to coordinate a pickup 630-777-1885',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host DTW',
          id: 'MI_HMS_HOST_DTW__ROMULUS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Dropping to bradford',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Sheehan Brothers Vending Samples',
          id: 'OH_SALES_SHEEHAN_BROTHERES_VENDING__1740_COMMERCE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Call Patrick',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T2_H11',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update once process is finalized.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '10_S_WACKER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Machine is on 1st floor main lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FIT_SC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Street parking, walk into the main entrance, take the elevator to 6th floor, walk through double doors fridge on left.....SECURITY ESCORTS DRIVER',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '111_E_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1168',
          id: 'IL_TARGET_T1168__2099_SKOKIE_VALLEY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Check in at the receiving dock get a name tag and once you are done stocking put left over food in the produce cooler.\n\nLook for Gus the fresh market lead',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'a440dd2d-1a36-4c40-88b2-06132f07b050',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Marketing_TODAY SHOW PRODUCERS',
          id: 'NY_TODAYSHOWPRODUCERS__49_W_49TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'PLEASE DELIVER AS CLOSE TO 10:30AM AS POSSIBLE!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host SNA - Ciao',
          id: 'CA_LA_HMS_HOST_SNA_CIAO__18601_AIRPORT_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Curbside delivery, call the delivery contact upon arrival for pick up',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'OH_CIN_VA_539',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Deliveries to the VA Hospital should be made at the loading dock located at the back of the building. During normal hours 5:30AM to 3:00pm M-F. The location of the loading dock is on E Shields St just off Vine St.\r\n\r\nWe have three ways to enter, at the morgue, near the trash compactor or at the loading dock. The first two options are the best. If entering from the Morgue or trash compactor simply go straight down the hallway to the freight elevators located on the left. Take elevator to the first floor and exit to the left and your at the farmers fridge machine.\r\n\r\nIf necessary to deliver on off hours, please park in the flat lot away from the ED entrance, check in with Police services deliver and check out with police services',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_CICERO_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_600_CONGRESS_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go through loading dock headed toward lobby.  The fridge is right next to the main elevator bank\r\n\r\nsecurity phone # 512-583-5614',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_ZAK',
          id: 'IL_ZAK__2100_N_MAJOR_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call Rachel 434-249-6499',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_ALLEN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, Fridge will be against the wall directly behind the information desk',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_Hudson DCA Samples',
          id: 'VA_CATERING_(COMPED)_SALES_HUDSON_DCA_SAMPLES__2401_S_SMITH_BLVD_',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Drop to the loading dock and alert the receivers',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_HOSPITALS_COLER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "After checking in with security, walk down the hallway to the end and turn left. Enter the elevator and take it to the 3rd floor. Take a left out of the elevator and the Farmer's Fridge will be on your left side.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_UPENN_RYAN_VETERINARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Take up freight elevator up to 3.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_LAWNDALE',
          id: 'IL_RACHEL__1319_W_WELLI_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please call Leslie',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'ALLSTATE_NORTHBROOK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock to the freight elevators on your right. Take that to the 2nd floor and proceed to suite 201 directly next to security officer. Securities & facilities will check you in',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_BAYSHORE_MC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through doors and fridge will be on your right hand side',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_RUSH_HOSPITAL_TOB__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_HQ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through loading dock and take elevator to 2nd floor vendateria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Philadelphia 2',
          id: 'PA_CANTEEN_PHILLY__150_GORDON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_East health center gove Wellness Fair Day 2',
          id: 'MA_BOS_SALES_EAST_CENTER_GOVE_WELLNESS_FAIR_DAY_2__10_GOVE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'The areas of delivery are busy so please enter the building and see public safety and ask for Courtney Senechal and we will meet the person.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_FERRERO_FRANKLIN_PARK_CAFE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_SCHLITZ_PARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWHEALTH_UNIV_HOSP_SURGERY_CTR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevators up to level 3, walk toward the surgery center desks, fridge is located in the waiting room',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Morgan Stanley Wholesale',
          id: 'NY_MORGAN_STANLEY__750_7_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock on 49th Street between 7th and Broadway (loading dock opens at 7am)\nCafé located on the 4th floor, delivery should be made to 4th Floor Kitchen\nAnyone entering the building for deliveries MUST wear a mask when in the building',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AMAZON_MKE1_NORTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, and walk to north entrance break room',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Stony Brook University Hospital',
          id: 'NY_STONY_BROOK_UNIVERSITY_HOSPITAL__101_NICOLLS_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            "Contact Natasha Barley first (631-637-4824) and if she doesn't pick up you can contact receiving lead, Denise Titione, (631-972-8526) and if she doesn't respond you can contact Terance Lee, director of retail (631-572-1449) to let them know you're onsite",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_WILSON_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'MI_MSU_WILSON_HALL ***East Entrance ***\nAddress - 219 Wilson Rd East Lansing MI 48825\nCustomer Friendly Location - MSU - East Wilson Hall. Located on the main floor next to vending\nDelivery Hours - 7AM to 6PM\nDriver Entrance - Front East Hall Entrance\nDriver Parking - Park by East Wilson Hall Entrance\nPark to Fridge Instructions - enter through East Entrance door, take\nhallway down past vending area and stay right. Take first hallway on the right, follow signs for Auditorium. Take long hallway down and machine is on the left across from the Auditorium.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_BLOOMBERG',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight up to 2. Walk out of double doors.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Avon IN Warehouse',
          id: 'IN_COSTCO_AVON_IN_WAREHOUSE__8816_DR._CHARLES_NELSON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_HEALTH_MASONIC_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front doors, Fridge will be to the right at the start of the hallway.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CENTINELA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Make your way into the hospital and to the cafe. You will see the common area where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLEX_700_SOUTH_LUNCHROOM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter at door 2 to the left of the main entrance- walk through the hall to the cafeteria. Fridge will be near the other vending machines on the left',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 41 – Medtox',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_41_MEDTOX__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OAKTON_COMMUNITY_COLLEGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3287',
          id: 'MA_TARGET_T3287__471_SALEM_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in rear and entrer through the loading dock. Location opens at 7am. Stock in endcap near milk section',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_B20',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Walkthrough Terminal 1 - Gate B and look for B20 at the end of the terminal to the right, turn into a continued hallway, and the Fridge is located at the end of the hall.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Canteen Houston',
          id: 'TX_CANTEEN_HOUSTON__2747_W_GRAND_PKWY_N',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Please fill the fridge that is in the',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AJ_CAPITAL_PARTNERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BROOKLYN_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in main entrance. Take left hand hallway. The fridge will be on your left along with the other vending machines, right in front of the Starbucks',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_TEMPLE_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Walk through kitchen to seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Mike Samples',
          id: 'NY_MIKE_SAMPLES__450_W_44TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Mike at 847-909-5434',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_ST_LUKES_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the elevator to level 3. Walk down the skywalk (traveling west) to the end past the Market Express. Turn left and the machine is immediately on the left',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge will be located within the lobby coffee shop.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_CONROE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "follow signs for the cafeteria - we're in the sitting area",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ULTA_BEAUTY_HQ_3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWHEALTH_UNIV_HOSP_CAFE_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the main cafe, fridge is located near the north entrance',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '4973b939-2d83-41ed-961c-8a48ca6faca0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IN_Catering (comped) - Sales_Kittle Properties',
          id: 'IN_KITTLE_PROPERTIES__310_E_96TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Take main elevator to 4th floor',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '303_E_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UBS Nashville',
          id: 'TN_UBS_NASHVILLE__315_DEADERICK_ST',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'loading dock 4th avenue side- come straight down the ramp- dock manager will check in with you and someone will escort you upstairs',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'UBS Wealth Management',
        },
        {
          opportunityName: null,
          id: 'DUPAGE_TEC_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take door 7 to location of the fridge near tv on wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OLYMPIA_FIELDS_HOSPITAL',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_MARYMOUNT_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in man entrance. Fridge will be on the left',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_DARWINS_CAFE__911_NORTH_UNIVERSITY_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'side loading dock on fletcher St.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2781',
          id: 'IL_TARGET_T2781__1101_W_JACKSON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Check in at the loading dock and place product in the produce cooler\n\nLook for standard or latonya',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'NJ_186_WOOD_AVENUE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the front entrance turn right at security, machine will be on your right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Loyola University Connections Cafe',
          id: 'IL_LOYOLA_UNIVERSITY_CONNECTIONS_CAFE__N_SHERIDAN_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BP_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6de4a801-621e-47c2-a709-e92344a1995b',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SUFFOLK_SARGENT_LAW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main doors at 120 Tremont and head up to the 4th floor dining area. No access card needed, but proof of company affiliation may be asked for.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_NE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through glass doors. Walk straight through the lobby and go straight for 2 min and you will see the vending on the left side of the building through a glass door.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_125_BROAD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - Blueridge',
          id: 'VA_HUDSON_IAD_BLUERIDGE__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Deliver to Hudson Dulles',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Pure Green - Gates Ave',
          id: 'NY_PURE_GREEN_GATES_AVE__325_GATES_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop off to store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_LAWRENCE_GEN_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'They enter via the loading dock. The will take the Russell elevator to the 3rd floor.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson 1052',
          id: 'IL_HUDSON_1052__500_WEST_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "loading dock is on the corner of Washington and Canal. The Truck Clearance is 12'-3' Bays 2-5, 12'-7' Bays 6-7, and 13'-4' Bay 8. Drop off location is on the 1st. level, Hudson Bookseller.",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_GATE_A8_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn left and walk to A gates, circle the wall until you find the alcove outside of gate A8',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_CHILDRENS_ADVOCACY_NEW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the lot on Washburn and Damen, and can come through the main entrance.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_NEWARK_BERGEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go straight through, big space to the right of the security desk',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'C&S Vending',
          id: 'MN_CS_VENDING_COMPANY__1919_2ND_ST_NW',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please have the driver come to the EAST dock.\r\nWe are the side without the parking lot.\r\nWe have a loading dock.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'C&S Vending Company, Inc.',
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Alexander Court',
          id: 'DC_ALEXANDER_COURT__2001_K_STREET_NORTHWEST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call Kelly @ 301-974-5041',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Quick Pick Vending',
          id: 'MD_QUICK_PICK_VENDING__753_ST_MICHAELS_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Front Door Delivery, call Brandon',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Franciscan Health Michigan City',
          id: 'IN_FRANCISCAN_HEALTH_MICHIGAN_CITY__3500_FRANCISCAN_WAY',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Go to Lodaing dock - number is posted and ask for inventory control clerk or dietary',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Franciscan Health System',
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_DREW',
          id: 'NY_DREW__530_W_57TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL Sara Shae ((609) 760-4157)',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Vernon Warehouse Samples',
          id: 'CA_VERNON_WAREHOUSE_SAMPLES__4569_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Put in warehouse cold storage for Eddy pickup',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MKE_COLUMBIA_ST_MARYS_OZAUKEE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Loading Dock instructions:\r\nWalk up the Dock Ramp, buzz to get in, enter through the dock doors, go left, then right through doors and turn left and walk straight down long hall way, you will run into a door that leads to the kitchen, at that spot turn left and take a right past the bathroom, walk down the hall and turn left by the elevators, and walk down a block and vending area will be on your right, you will see it once you get to the end of the hallway\r\n\r\nER Instructions:\r\nEnter into the Emergency room entrance, walk past the registration area, enter the elevators on the left side, go to the ground floor. Once on the ground floor, when exiting the elevator turn to the left past the waiting area and take a right, go all the way down the hallway until get to the cafe sitting area, turn left, go past the cashier station and vending area will be on the right.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_UH Sugarland - Brazo Hall Free Fill',
          id: 'TX_UH_SUGARLAND_BRAZO_HALL_FREE_FILL__14004_UNIVERSITY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Enter through the main entrance',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_WILLOWBROOK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to cafe. Fridge located in cafe MOVED DEC 2022: Now located in the North Pavilion across from Common Grounds',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESENCE_SAINT_JOSEPH_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main entrance, go left towards vending, and then take a right into the vending area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_MKEVA_event',
          id: 'IL_ICNC_FULTON__2000_W_FULTON_SM',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'please put in the Fridge in the back test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_SLB Health Event',
          id: 'TX_SLB_HEALTH_EVENT__12510_W_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Leave in the cooler!',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'US_FOODS_ROSEMONT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: '7th floor community space',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Marche- Carta',
          id: 'NY_MARCHE_CARTA__1_WORLD_TRADE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please handoff to Marche at 1 World Trade',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Endeavor Air CVG',
          id: 'OH_ENDEAVOR_AIR_CVG__3087_TERMINAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Go to security office (around B22-B26) and ask to be taken to Endeavor Air. Security will escort you there. Go thru the 1st door that is located outside by the planes. Walk through the short hallway and there will be another door with a green button on the right side by the handle. Ask security to stay there because they will have to open the door to let you back out by the van. After going thru the 2nd door, the stairs that we usually come down are going to be located on your right. Follow the hallway to the endeavor lobby room. After stocking the fridge, ask the escort to open the door for you to let you out. Escort can now take you to B13 or the A concourses to stock the next fridge.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Endeavour Air',
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TROY_1ST_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Located near the Starbucks near the Main Entrance.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MONTEFIORE_NYACK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'there is a short hallway to get to main lobby from the kitchen dock',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WALGREENS_200_WILMOT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevators up to the 2nd floor fridge located in the Green main lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b6a2f5b8-97ea-40f8-9796-67f7e29871a2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_STATE_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_American Food and Vending Office',
          id: 'TX_AMERICAN_FOOD_AND_VENDING__6110_THEALL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'call Tom duffy cell',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_HTC_BROOKLYN_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main entrance doors, take the hallway to the right, Cafe will be on your left.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Morrison Foerster',
          id: 'DC_MORRISON_FOERSTER__2100_L_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'As you pass over 22nd street, take the first alley entry on your right. From the alley take the first left turn and proceed toward the red brick building. Our garage will be the last one on your left, with a sign above the door noting 2100 L Street.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_HAHN_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through entrance and fridge is located in the lounge area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IIT_KENT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host MSP - MKT',
          id: 'MN_HMS_HOST_MSP_MKT__4300_GLUMACK_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Deliver to MSP Bradford with instructions to be delivered to HMS Host. Tell Bradford this is for HMS Central Market.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'NYC_YANKEES_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'I will attach their delivery and dock info to the salesforce opp page',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CSU- LA Campus Book Store',
          id: 'CA_LA_CSU_LA_CAMPUS_BOOKS_STORE__5155_STATE_UNIVERSITY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Driver should park behind the loading deck to access book store directly',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_MAIN_CAMPUS_ATRIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'turn left off southernand take third entry to loading dock, take service elevator to ground floor, take walking or loading dock hallway take first right at the exit sign, towards west elevators, turn left at west elevator cafeteria then vier left at west cafeteria elevator sign, pass west elevators towards cafeteria, pass staff elevators into lower level open atrium off cafeteria entrance and fridge will be under the “parking sign”.\r\n24 hour access - this building is 24/7',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kettering Main Campus',
          id: 'OH_KETTERING_MAIN_CAMPUS__3535_SOUTHERN_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Use delivery dock of hospital.  Located around the back of the facility. Deliver to kitchen or ground floor cafe',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_KELLER_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'access the loading dock off 60th and Kimbark. Ring the buzzer and security will let you in. Walk straight and turn left and fridge is on the right.\r\n\r\nWeekend access hours are the same as weekday.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_LBA Realty -10000 Washington Blvd',
          id: 'CA_LBA_REALTY__10000_WASHINGTON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Enter through main door and enter through Atrium.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NYC_ST_JOSEPHS_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Cafeteria is located on the second floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_D18',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through dock, go toward D15z go toward coke display. Take a left. Machine is across from Timeless travel vacant space.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_SOUTHERN_OCEAN_MC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through main entrance, walk to cafeteria, fridge is located in seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3546__424_W_DIVISION',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco Stores',
          id: 'IL_COSTCO_TEST_LOCATION',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_186_WOOD_AVENUE_SOUTH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 3',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_3__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NYC_CON_ED_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "come in through front entrance, go to security and ask for brian sarrantonio the first few times. then you'll be able to enter in normally with the badge.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_3M_236__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St David N Austin Medical Center',
          id: 'TX_ST_DAVID_N_AUSTIN_MEDICAL_CENTER__12221_MOPAC_EXPRESSWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'GO TO LOADING DOCK AN CALL CAFETERIA NUMBER: 512-901-2804',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: "St. David's HealthCare",
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_ONT9',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Parking is located on the left-hand side of the building when you enter the site. There are plenty of vendor parking. Enter through main door and security will escort you to the main breakroom.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_580_ANTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use keypad and enter code #0580 to enter the building and go to the second floor and you will find the vending room where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_ST_CHRISTOPHERS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through front entrance. Walk passed security and continue straight. Fridge will be on left next to Cafe.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T3280__40_82ND_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WALMART_AREA_200_CHINO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building and check in with security who will direct you to Area 200',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir- Brookline',
          id: 'MA_GETIR_BROOKLINE__9_HARVARD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1439',
          id: 'MN_HYVEE_1439__8200_42ND_AVENUE_NORTH',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Hudson PHL Samples',
          id: 'PA_CATERING_(COMPED)_SALES_HUDSON_PHL_SAMPLES__8000_ESSNGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Deliver with Hudson PHL',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_ARLINGTON_COUNTY_DEPT_HUMAN_SERVICES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park outside the dock and call 703 228 1012. Walk through double doors and fridge is in the lobby',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_OFFLINE_FRIDGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_UBS_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Manhattan, NY 1',
          id: 'NY_LIFETIME_MANHATTAN_NY_1__60_W_23RD_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MAIMONIDES_8TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Service elevator to the eighth floor security in the main lobby. Stephen Castano (718-283-7621) can assist with directing if needed.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter thru loading door marked 1017. Turn left and walk down the hallway towards the elevator. Take the elevator to the second floor. Walk straight out the double glass doors. Fridge will be on your left.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALEXIAN_BEHAVIORAL_HEALTH_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Stratasys Nash',
          id: 'MN_CANTEEN_STRATASYS_NASH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OLYMPIA_FIELDS_FRANCISCAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take main hallway and follow signs to dining area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_CAREY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight up to floor 2, walk left towards the library',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAL_POLY_ENV_7',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Had to the building and you will see the Fridge as you walk up',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Johns Hopkins Howard County Medical Center',
          id: 'MD_JOHNS_HOPKINS_HOWARD_COUNTY_MEDICAL_CENTER__5755_CEDAR_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Pass the main entrance and park in the Loading Dock to the right. Walk through double doors and ask for helene or Jason',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through double doors. Take handicap elevator to first floor. Walk straight then turn left.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'VA_Catering (comped) - Sales_7900 Westpark Dr - Brookfield',
          id: 'VA_BROOKFIELD__7900_WESTPARK_DR_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call Sophie Barral - Client Experience Manager. Deliver to the office of the building.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_WPIX',
          id: 'NY_WPIX__220_E_42ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Please call Veronica',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_2346__750_ARMY_TRAIL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Marketing_Loyola University Medical Center',
          id: 'IL_LOYOLA_UNIVERSITY_MEDICAL_CENTER__2160_S_1ST_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "Call Tim (708-216-6382) and drop off the samples in the cafeteria's cooler behind cafe",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_RIVER_CROSSING_I',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the front entrance to the lobby of the building',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson MDW - 270',
          id: 'IL_HUDSON_MDW__5700_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop product to Terminal C loading dock. You can call the delivery contact upon arrival or alert dock staff to its pressence',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MKE_GE_ELECTRIC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Albank [Client Route- 10]',
          id: 'IL_CANTEEN_ALBANK__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Miramar College Pantry',
          id: 'CA_MIRAMAR_COLLEGE_PANTRY__10440_BLACK_MOUNTAIN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'put the address into GPS and it will put them directly on Miramar College Parkway. From there, they will follow the road around the bend, and make a left into our dock station (right behind the English/I building).Onsite contacts to call - Margot: (619) 212-3815 and Sonia: (619) 655-8750',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'San Diego Community College District',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNITED_AIRLINES_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the gated back entrance, check in with the guard ("i\'m with farmer\'s fridge here to stock"), park in front of the back entrance, check in with the guard, go to the cafe and the fridge is across from the micromarket',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_GREATER_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security will let you in. Fridge around the corner to the reight.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_LIZ LULULEMON',
          id: 'CA_LIZLULULEMON__11026_CRESTBROOK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE CALL LIZ',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Diego Samples',
          id: 'TX_DIEGO_SAMPLES__416_N_LIVE_OAK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Call Diego at 346-269-8473',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Akron - Infocision Stadium',
          id: 'OH_UNIVERSITY_OF_AKRON_INFOCISION_STADIUM__375_E_EXCHANGE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            "Loading dock is located on Vine St. Deliver to the Grab n' Go Kitchen",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CHANEL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Anheuser Busch',
          id: 'NY_ANHEUSER_BUSCH__125_W_24TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Walida at',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1108',
          id: 'NY_TARGET_T1108__98_VENTERANSMEMORIAL_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Fjallraven Chicago Event',
          id: 'IL_FJALLRAVEN__1708_N_DAMEN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Culinary Production Kitchen (CPK)',
          id: 'OH_OHIO_STATE_CULINARY_PRODUCTION_KITCHEN_CPK__2650_KENNY_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'See a sign for Central Production (last couple docks)\r\nNo badge needed they’re there\r\nIn case of emergency they can accept the earliest delivery at 5:30am and latest at 10pm',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_WEST_LOOP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "go through front entrance, walk down main hallway and follow the signs to the cafe which is on the lefthand side of the building.  We are in the hallway of the lounge and are pretty much front and center. hard to miss\r\n\r\nSecurity's phone # is (713) 718-7868",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_AMAZON_BWI5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Security towards main breakroom',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_AA_FA_LOUNGE_A35',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go to gate A35, find door to AA employee lounge, scan badge to enter, take escalator up one floor and then find the vending area around the corner.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '0810ccdc-78ba-4eda-948c-728516ac78b8',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_PITT_LANGLEY_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'CC to update',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_SANDBURG_RESIDENCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Drivers in front of location, service techs dock area 7 am to 3:30 after that front sign in with security.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HEART_HCA_CLEAR_LAKE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front, walk through main entrance, the fridge will be to your left in the corner near the little deli',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_INGALLS_CALUMET_CITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Right inside the door',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2613',
          id: 'IL_TARGET_T2613__1200_N_LARRABEE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drive into the parking lot and back in to the loading dock. Go in through stairs to the right of the dock and ask for Danielle',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'PA_UPENN_HOSPITAL_HUP',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in loading dock. Go through large door, make a left then quick right down hall. Go through double doors to end and make a right. Continue walking, make a left then right through light blue doors. Up elevator to 2nd fl. Make a right out of elevators then left down hall all the way to the stairs.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_HEART_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go through emergency room door in the back - if you cant get in, call (512) 407-7000.  The cafeteria is up on the left, we're in the seating area of the cafe",
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AUDIE_MURPHY_VA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Same as current Fridge',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales LA Airport Trip',
          id: 'IL_CATERING_(COMPED)_SALES_LA_AIRPORT_TRIP__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Fulton office monday morning, call Chris Macfadyen oin arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNESOTA_VIKINGS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'For re-stocking, Driver will use Gate 5, ring security, and go to the East Dock. A security officer will escort the driver at all times.\r\n\r\nSecurity phone # 952-918-8329',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'INSIGHT_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk down the hall at the loading dock, take a first left at the cages and take the elevator to the 2nd floor. Take a right out of the elevator, walk straight through the door, take first left, cafeteria will be at the end of the hall on the left. Fridge will be in the vending back on the right.\n\nTo leave, the loading dock is on floor B',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_MONROE',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Marche NYPA',
          id: 'NY_MARCHE_NYPA__1_WORLD_TRADE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please deliver with Carta',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_GM_INNOVATION_CENTER_MARKET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front doors, check in with security, walk to the back of the building, the fridge is on the 1st floor in the cafe all the way to the back\r\n\r\nSecurity phone # 512-571-1600',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_RIVERVIEW',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_GREENE_MEMORIAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the front entrance to the security desk. Use the main entrance hallway towards the elevator bank and X-Ray seating area where the fridge is along the wall across from the elevators',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_HMS Host John Wayne',
          id: 'CA_CATERING_(COMPED)_SALES_HMS_HOST_JOHN_WAYNE__18601_AIRPORT_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Curbside delivery, call the delivery contact upon arrival for pick up',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PRINCETON_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through ER Doors at check-in. Walk to the left, and go down the hallway to the medical arts pavilion, turn right down the hall towards the "restaurant". Fridge is located by the vending machines.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Christ Hospital',
          id: 'OH_CHRIST_HOSPITAL_PHYSICIANS_LOUNGE__2139_AUBURN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'look for a church (Mt. Auburn Baptist Church) by the main entrance of the hospital, across from Gilman Ave. Depending which way they are coming they will either turn left or right and go down a hill by the church. Call Kevin 513-319-6749.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTH_PARK_UNI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_OFFLINE_FRIDGEallcustomerorders',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_ARLINGTON_SPORTS_CLUB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main entrance and the fridge will be right there.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TRIANGLE_PLAZA_EAST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Call number at the dock each day. Enter through the double doors, head straight to the last elevator on the left, go to 1st floor, exit to the left off the elevator and go through the glass door. Fridge will be on the wall to the right.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8045_LAMON_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the rear loading dock into the facility and badge access your way into the double doors on the right. Upon entering into the facility follow the walk path around the hallway (you'll almost make a U shape) to the hallway where the vending bank is.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_METHODIST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From either Wolf St or ER driveway walk towards Jefferson Health sign and make a right towards a booth and another right towards the hospital entrance. From there driver may need to sign in as vendor and afterwards make a left into a corridor and another left towards the elevators. The fridge will be right in front.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CUYAHOGA_CC_WESTERN_CAMPUS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions:
            'Follow the hallway from the loading dock to the recreation area with the ping pong table and turn left, place machine on the wall near vending machines',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SWEDISH_COVENANT_HOSPITAL_EMERGENCY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through ED doors, turn right, fridge will be in the recessed area on the right hand side',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_PSP1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security to your left where you'll find the fridge in the main break room cafeteria.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1_S_DEARBORN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_SATELLITE_UNIT_HAGERTY__DUNCAN_STUDENT_CENTER',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call cody upon arrival (574)631-1537',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1855',
          id: 'TX_HUDSON_PHL_1855__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_NJCU_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter via loading dock to the Gilligan Student Union Building, at entrance on the right',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Grace Samples',
          id: 'IL_GRACE_SAMPLES__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please place in test kitchen when stocking the Fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (Comped) - Sales_Horizon BCBS',
          id: 'NY_HORIZON_BCBS__3_PENN_PLZ_E',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'you can leave the samples with front desk and call Jose to let him know 8627542481',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Minnesota Air',
          id: 'MN_MINNESOTA_AIR__6901_W_OLD_SHAKOPEE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Call Julie Carlson (952-918-8072)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NJ_SPECIALTY_RX_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "They can meet Shulem and he will show them. The cafeteria is downstairs but there's a side entrance they can likely go through",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WRIGHT FOOD COURT_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Barnard Dining',
          id: 'NY_BARNARD_DINING__607_W_116TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'loading dock entrance',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_3344__1220_S_ASHLAND',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_CBRE',
          id: 'DC_CBRE__3924_MINNESOTA_AVE_NE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'When you arrive, call the engineers',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_UTA_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Will have to walk from the parking lot to the main area of the campus. Fridge will be located on the second floor next to the Avenue C.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'a0a2406c-0001-47f1-9abd-61be6fd47ddb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Stony Brook University Hospital - Dental School',
          id: 'NY_STONY_BROOK_UNIVERSITY_HOSPITAL_DENTAL_SCHOOL__101_NICOLLS_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'please call dc and deliver through the main entrance on level 5 at Stony Brook Hospital and drop product in walk in fridge. ALL STONY BROOK WHOLESALE DELIVERIES are to be dropped off at Stony Brook Hospital',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_MERCY_MED_CENTER_16TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take Freight elevator down to LL, then ride elevator up to 16.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_PHYSICS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BMCC_4TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The machine will be loaded on the elevator to the 4th floor and rolled to the location.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '500_PARK_BLVD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_HENRY_FORD_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the East clinic entrance(same entrance as express/cafeteria). Head towards the elevators and make a right. From there follow the overhead signs leading to the cafeteria. Once you arrive at the hall with the art displayed on the wall make a right and the fridge is straight ahead. Map Pin:\nhttps://goo.gl/maps/KYS2zfX7WHAfRAfY6',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MUSEUM_SCIENCE_INDUSTRY_VENDING_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge it’s located on the lower level vending room - east side of the building by the escalator.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Wicker Park',
          id: 'IL_PURE_GREEN_WICKER_PARK__1533_N_MILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop off with the store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '536_S_CLARK_7TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Someone from Brian Butcher's staff will need to be present when the machine is first delivered.  Then Joe from the building vending will escort the person stocking the machine the first couple times.  Once everyone is comfortable, they can probably then stock without an escort.  Please note though that any people coming into the building to stock will need to pass through security screening on the dock and any boxes will need to be examined.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Deerfield Warehouse',
          id: 'OH_COSTCO_DEERFIELD_WAREHOUSE__9691_WATERSTONE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MKE_TRUSTAGE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the loading dock entrance, take a right down the hall and walk through the double doors using a badge. Take a left to the cubicle hallway, take first left, elevators will be on the left side. Take the elevator to G and the fridge will be off the elevators to the left in the Mutual Grounds Cafe.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OHM Wholesale',
          id: 'MD_BALTIMORE_WASHINGTON_INTERNALTIONAL_THURGOD_MARSHALL_AIRPORT_BWI__107_FUEL_FARM_ROAD_BOARS_HEAD_TERMINAL_A',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Will update after Bradford call',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_VIKING__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_UMASS_CAMPUS_CENTER_UPPER_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt to Follow up',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JAMAICA_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update with instructions.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Long Island University Brooklyn',
          id: 'NY_LONG_ISLAND_UNIVERSITY_BROOKLYN__1_UNIVERSITY_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Jennifer',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_NEWARK_BETH_ISRAEL_GROUNDFLR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CTA_GARAGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESENCE_RESURRECTION_MEDICAL_ER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_SOUTHWEST_WINGS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, past security, and the Fridge will be next the micro-market which is on the left side of the cafeteria',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_ABELCONN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_UNIVERSITY_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'you have to show ID at loading dock to check-in',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_3368__550_NORTH_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'BACKSTOCK LOCATION: In deli cooler in basement. Deli cooler will be one on the left.  OTHER DELIVERY INSTRUCTIONS: Stock product in deli section in FF marked area.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IIT_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_AMAZON_HMW1__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHARLES_SCHWAB_VENDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If using dock, take freight up to 14th floor. Fridge will be in the lunch room\n\nIf street parking, enter lobby and take low rise elevators to the 14th floor',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_D38_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Park at D loading dock and walk to fridge located across from gate D38.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_GRAD_CAFE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 12',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_12__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MD_BALTIMORE_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Get on the parking garage elevator, go to 1st floor, lets you out in the lobby, go around to next set of elevators and go to second floor Fridge is near top of escalator',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Widener Univ - Wholesale',
          id: 'TEST__Test',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MUNGER_BLUE_MARKET__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'The dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_MIT_BUILDING_4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main entrance and walk directly straight through the hallway until you reach building 4 room 115 which will be on your left side. Call Mark Hayes (781-392-5354) or Heather Ryall (617-253-5646) if you need assistance.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_100 Overlook Center',
          id: 'NJ_OVERLOOK_CENTER__100_OVERLOOK_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Park in front and ask for Jay',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Palantir',
          id: 'NY_PALANTIR_2__416_W_13TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_KTLA_MARKETING',
          id: 'CA_KTLA_MARKETING__5800_SUNSET_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE DELIVERY BETWEEN 6:30 AND 7AM',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_WESTPARK_CORPORATE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Follow the sign for the freight elevator and take up to the lobby. Fridge is in the cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HOFSTRA_UNIVERSITY_NETHERLANDS_HALL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_CHILDRENS_HOSPITAL_CABANA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Exit the loading dock double doors and turn right, straight through the doors, then take the elevators to the right up to 2, turn right off the elevators and continue straight until it dead ends, go right, Fridge will be ahead about 50 feet and to the right, next to their Coffee Cabana',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_PRAIRIE_VIEW_AM_NURSING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the "garage" next to building - go in the front door, check in with security, go up to floor 9, the lounge is on the east side of the building when you get off the elevator.  The fridge is right in the middle of the lounge.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Memorial Herman Pearland - EMS BREAK ROOM',
          id: 'TX_MEMORIAL_HERMAN_PEARLAND_EMS_BREAK_ROOM__16100_S_FREEWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'ENTRANCE FIRST FLOOR ON THE LEFT. THERE IS A # ON THE SIDE OF THE DOOR IF THERE ARE ANY ISSUES',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Memorial Hermann Healthcare System',
        },
        {
          opportunityName: null,
          id: 'OH_MOUNT_CARMEL_EAST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MITCHELL_DEPARTURES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_DREXEL_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator up to 1 and walk towards the student lounge',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_A28',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_RICHLAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the main entrance, take the elevator down a flight and the Fridge will be located in the hallway leading into the dining hall.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_Food Tank Event',
          id: 'TX_FOOD_TANK__900_CHICON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Huston Tillotson University  Davage Durden Student Union Dinning Hall',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_REAL SIMPLE',
          id: 'NY_REALSIMPLE__225_LIBERTY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'PLEASE CALL LISA AND BRING IT UP TO THE 6TH FLOOR',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: '3ce3f11e-af3b-48b4-bfe5-03c6082f9f4a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_UCLA HEALTH - AYS Catering RRMC',
          id: 'CA_UCLA_HEALTH_AYS_CATERING_RRMC__757_WESTWOOD_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Check in with receiving at the dock and bring it straight up to the cafeteria- DO NOT MERCHANDISE THIS ORDER IT IS FOR CATERING. Inform the store keeper this is for catering not for Dinning Common.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'SCHAUMBURG_CORP_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -   Rt 9 Illumina Bldg 2',
          id: 'CA_CANTEEN_VISTA_RT_9_ILLUMINA_BLDG_2__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Rolex NYC',
          id: 'NY_ROLEX__650_5TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Barak Alberro',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Hudson EWR Samples',
          id: 'NJ_CATERING_(COMPED)_SALES_HUDSON_EWR_SAMPLES__3_BREWSTER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Deliver with Hudson Newark order',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Indianapolis',
          id: 'IN_PURE_GREEN_INDIANAPOLIS__591_MONON_BLVD_STE_2',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver to store crew',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_AIRPORT_AB_LINK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Bill will arrange for someone to escort the driver to the fridges on the first day of stocking.\r\n\r\nPark at loading dock between terminals A and B. Enter through loading dock door on the left. Walk down the dock area toward the end. You have to leave product in the dock and go through security check point and go back to retrieve it. Exit loading dock and go in through terminal B main entrance. Go to second floor and go through employee B security check point. Go through security and turn right and walk down hallway. Go through black door with yellow tape on the left side and take elevator down to dock level. Hold button to open and close elevator. Walk straight out of elevator. Walk down till you hit a wall and turn right and go through door BT1018. Retrieve your product and Go back the same way you came with your product and go to the same elevator and take it to floor 2.  For first fridge you will exit left out of black doors and walk down hallway.  Fridge will be on the left side. \r\n\r\nRaven (security) - 215 669 8388\r\nSupervisor number - 215 852 1193 \r\nIf anyone gets confused or lost we can contact them or bill for assistant finding machines. \r\nLoading dock is open from 4am-2pm Monday - Friday  \r\nDon’t use moving walkways or escalators.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_FLEX_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park in loading dock, follow signs for cafeteria - we're right at the entrance",
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LINCOLN_CENTRE_ONE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "As soon as you get in the doors take a right, you'll see a micromarket, the Fridge will be in there against the back left wall",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Northeast Team Samples',
          id: 'NY_NORTHEAST_TEAM_SAMPLES__568_MORGAN_3R',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'the front door to the building is open, can be dropped in the vestibule. Please call Caitlin at 860-748-6799',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELMHURST_FITNESS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_EMERSON_PARAMOUNT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Coskun (AKA George) Altug at 954-330-2738  or Dawn Sajdyk (857-523-9816) upon arrival if you have issues. The retail manager will be able to meet you at the loading dock to direct them to the install location.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD B9',
          id: 'IL_HUDSON_ORD_B9__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Pure Green - Lincoln Park',
          id: 'IL_PURE_GREEN_LINCOLN_PARK__2344_N_LINCOLN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver product to store employees',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'IN_GOPUFF_167__1202_E_23RD_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Procter&Gamble',
          id: 'OH_CATERING_(COMPED)_SALES_PROCTER-GAMBLE__1_PROCTER_AND_GAMBLE_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Drop off with Eurest Team member',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CPD',
          id: 'IL_CPD__1718_S_STATE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call Amy (773) 354-5512',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT3_1404',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Coming out of the main break room, take a left and follow the green lines all the way around to the back break room',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3344',
          id: 'CA_TARGET_T3344__420_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'bdc3bf50-a432-4873-aaaf-00a466bc37d3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_EMTC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '62b57992-37c9-4adc-bc50-572c4b51584c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2050',
          id: 'PA_HUDSON_PHL_2050__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Syed Samples',
          id: 'IL_SALES_SYED_SAMPLES__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please drop off in the test kitchen fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_NASCAR',
          id: 'IL_NASCAR__350_E_MONROE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'PLEASE CALL JEFF UPON ARRIVAL AND HE WILL TELL YOU WHERE EXACTLY TO MEET HIM :) THIS ADRESS IS NOT EXACTLY WHERE HE WILL MEET YOU',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_LU_WINSLOW_ACADEMIC_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver will need to call campus police (617-243-2269) for entrance into the building. There is always someone there 24/7. Enter through white door with glass panels and the Fridge will be in that hallway.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EPIC_BURGER_ADAMS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_2_E10',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge lock code is 1000',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 4 DexCom 6340',
          id: 'CA_CANTEEN_VISTA_RT_4_DEXCOM_6340__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENS_COLLEGE_QUEENS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "For stocking: In order to OPEN these two buildings, please inform all your drivers to come to the Main Public Safety Office located at the front of the campus on Kissena Blvd entrance. \r\n\r\nWhen the drivers come to the front desk, inform the Public Safety Officer on duty  that they are from Farmer's Fridge and want to go to Student Union and Queens Hall Buildings to fill in the product.\r\n\r\nThe campus Public Safety Officer will go with your drivers to OPEN these two buildings and remain there until finish and lock the building after that. \r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\nThere may be times when your drivers have to wait for some time due to on-campus other emergency/routine situations.\r\n\r\nNumber for security: 718-997-5912",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1148',
          id: 'NY_TARGET_T1148__838_SUNRISE_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'KEMPER_LAKES_BUILDING_1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Same building as Center, turn left after going into the dock fridge is down the hall.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_CALHOUN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the side walk toward Calhoun Hall (East) then you will see two class doors - you can enter either and find 100 pointe express room',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '693e3ca8-52bb-470a-b2c2-e586a0210f66',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_JB_HUNT__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW6_1ST_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the security gates, take a left, breakroom will be straight ahead of you',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Capacity',
          id: 'MO_CAPACITY_6665_DELMAR_BLVD_SUITE_300',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_8TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, fridge is located in the Vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Loyola-Chicago Wholesale',
          id: 'IL_LOYOLA_UNIVERSITY_CENTER_STAGE__6511_N_SHERIDAN_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_UT',
          id: 'TX_UT__1616_GUADALUPE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: '817.501.2249 Nossé',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Food Rocket Samples',
          id: 'IL_CHI_FOOD_ROCKET_SAMPLES__639_N_MILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Mike when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_HOWARD_UNIVERSITY_MEDICINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk down the hall and turn left.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AMAZONGO_ICH4_MONROE',
          id: 'IL_AMAZON_GO_ICH4__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'pickup at cicero',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: null,
          id: 'CA_LA_ONT_TERMINAL_4_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge is located across from baggage claim',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_SALESFORCE_TOWER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the loading dock (Scioto and Market Street, Dock on lower level) and buzz security button, they will let you in. You will see a small dock and an elevator, park there, elevator will take you directly to the lobby (fl 1) & tenant lounge (fl 2)',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3514',
          id: 'IL_JEWEL_OSCO_3514__3531_NORTH_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'CANTEEN - Graco French Lake',
          id: 'MN_CANTEEN_GRACO_FRENCH_LAKE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Getir - River North',
          id: 'IL_GETIR__125_W_HARRISON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "please call dc if there are q's",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee 1184',
          id: 'WI_HYVEE_1184__2920_FTICHRONA_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Enter through loading door in rear of building. Walk straight in and turn left to find reciving office. They will page someone to receive the product.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ENOVA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ENERGY_TRANSFER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park on main street - check in with security in lobby - the fridge is to the right of the security desk in the snack room down the hall.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_EMS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Sciences Surface Parking Lot, off of Kenwood Blvd between Frederick Ave and Murray Ave. The entrance to EMS will be just Northeast of the lot.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Flushing Hospital',
          id: 'NY_FLUSHING_HOSPITAL__4500_PARSONS_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Park at the loading dock at Flushing Hospital Medical Center and call Kert Cameron (718-670-5402) upon arrival.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCU_RECREATION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver must have been added to the access form.\r\n\r\nWalk through the main doors and the Fridge will be next to the basketball court entrance to the right of the lockers along the wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '311_S_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'WI_Catering (comped) - Marketing_YELP',
          id: 'WI_YELP__7907_N_SANTA_MONICA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'CALL JESSICA',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_UPS_PALATINE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e770bda9-2cfa-437f-af55-ec24579a7eda',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3465',
          id: 'IL_JEWEL_OSCO_3465__9449_SKOKIE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName:
            'CA_Catering (comped) - Marketing_INFLUENCER RETREAT',
          id: 'CA_INFLUENCERRETREAT__123_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'PLEASE HOLD @ LA WAREHOUSE! COURIER WILL PICK ORDER UP AT 9AM ON APRIL 2',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Target - T3288',
          id: 'CA_TARGET_T3288__609_N_DILLON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WOODLANDS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If we are stocking between 6am-5pm Mon-Fri, then go through loading dock and call Kia 501-773-2760, if any other time PARK IN GARAGE and go through 2nd floor security desk',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f9fa62ad-41a9-4296-b2bb-f19fe6412f70',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEMO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 84 – Kraus',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_84_KRAUS__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: 'CANTEEN - Canteen Breakroom',
          id: 'MN_CANTEEN_CANTEEN_BREAKROOM__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Sharp Coronado Wholesale',
          id: 'CA_SHARP_CORNADO_WHOLESALE__250_PROSPECT_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'come in through the main entrance',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_UPS_EAGAN_C103__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_EMBLEM_HEALTH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b444e57f-bd11-463a-a4d1-a348962b19f2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Forbes St Market',
          id: 'PA_FORBES_ST_MARKET__3955_FORBES_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'bring them into the market/store',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD C26',
          id: 'IL_HUDSON_ORD_C26__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'CA_LA_TRIBECA_WEST_150',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Security phone # 424.442.2406\r\n\r\nOnce parked at the ramp take the security gate entrance where you'll sign in at the check in box and take that gate into the security entrance. Take that to your right and follow the path to suite 150",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Canteen 1',
          id: 'MN_CANTEEN1__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Deliver into cold storage in Canteen warehouse',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Hudson ORD Lunch',
          id: 'IL_CATERING_(COMPED)_SALES_HUDSON_ORD_LUNCH__10000_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver with the rest of Hudson on Thursday',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BNSF_RAILWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'WILL NEED TO BE ESCORTED UNLESS BADGED. CANNOT GO OVERNIGHT UNLESS BADGED.\r\n\r\nWalk about a 100 yards to the building on the right (2600 Lou Menk), take a left down the hallway until it turns right go through those doors and the Fridge will be in the breakroom to the right',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MACNEAL_HOSPITAL2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk down the hallway to the lobby.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Lennar',
          id: 'CA_SALES_LENNAR__15131_ALTON_PARKWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Peggy will meet you',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_HEALTH_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Vending area is just to the right as you go in',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - WHOLESALE FRESH CUT',
          id: 'MN_CANTEEN_WHOLESALE_FRESH_CUT__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_FFW',
          id: 'NY_FFW__33_35TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'call jasmine',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_2_E29',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_COOLIDGE_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through main entrance, machine is on the left down the main hallway.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_CCNY_NORTH_AC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through convent Ave entrance. Walk past security and follow signs on the right hand side\r\nfor the elevator. Turn right down hallway and take elevator to the second floor. Exit right and\r\nwalk out of doors. Fridge will be to your right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_COLLEGE_OF_DENTISTRY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the back Ortho door to swipe your badge to enter the building. Walk straight through the east entrance doors, take elevators up to the 5th floor, fridge is located on the Northeast end of the 5th floor.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 9',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_9__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'DC_DHS_ST_ELIZABETH_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator up to Floor 1, walk towards the lobby/Trae Cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MU_PUBLIC_SQUARE_HOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the front entrance and the location will be in the center of the floor to your left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD3',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security through the cafeteria to the middle section vending bank. There is a secondary set of machines in the middle of the facility along the production line.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ST_PETERS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TOSHIBA_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go through the loading dock where you'll be escorted to the fridge locations. This will be going on the executive floor",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '120_S_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter and walk through the double doors past security. Take a left down the hallway to the lobby, the firdge will be against the wall on the left next to the security desk',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_UNION_STREET__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1340_S_DAMEN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_MAIN_CONCOURSE_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park and enter through East Entrance. Go straight down hallway, take first hallway on the left towards South Tower. Machines are in the open area by the Starbucks (South) and Gift Shop (North).',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_JLL_13100_SPACE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the lobby with security get escorted to the break room',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_COMER_CHILDRENS_HOSP',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevators up to Comer level 1 lobby. Fridge located in hallway leading to Comer II entrance',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_CHARLTON_METHODIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take a left towards the cafeteria, Fridge will be just to the right of the cafe doors',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_SAIEH_HALL_ECONOMICS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'address is 5757 S University Ave. Make your way to the alley off 57th street between University st. and Woodlawn st. Go down to the dead-end of alley and fridge can be seen through the glass window. To get access call 773-702-8181.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_SPECTRUM_MOBILE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, go to freight elevator, take up to 4th floor, the fridge is in the breakroom on the north side of the building',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_15TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, fridge located in Vendateria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_OU_OAKLAND_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the pioneer food court, fridge located in the food court',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CTA_FULLERTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the front entrance on Fullerton. Security will buzz you in upon arrival. After you enter the terminal the fridge will be 15 feet down on the left wall. The fridge lock combo is 1321.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T0843',
          id: 'IL_TARGET_T0843__9250_JOLIET_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park in dock and walk up front to stock display. Put all overstock in produce cooler in the back stock room\n\nErick is the lead of the fresh market area',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'PA_PIT_DUOLINGO_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use badge to get into the office at any time. Go up to the ramp to office space. Leads you to back lobby of freight elevators. Press 3 and will be taken to the front desk.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MACNEAL_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MUSEUM_SCIENCE_INDUSTRY_EMPLOYEE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b6dd0bab-906c-4477-b4a9-f57adbbce4c0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Austin Canteen Branch',
          id: 'TX_AUSTIN_CANTEEN_BRANCH__8801_WALL_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            "CALL 832-525-6887 - if he doesn't answer then just drop the samples off in the office.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GGP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LENNAR_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building and go to the elevator and the third floor. You will be on the Lennar floor and the secretary will check you in. Fridge will be in the common area by the kitchen\r\n\r\nTo gain entry before 8AM, call the onsite contact, Alicia Gil M: 714-866-7675 / W: 949-349-8025; contact is onsite by 6:40AM',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_3M_HQ_BUILDING_235',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'See attached map. Michelle will also be onsite to show the placement location.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Canteen MD',
          id: 'MD_CANTEEN_MD__7458_CANDLEWOOD_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park out front and call Dave Harris',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BAPTIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver will then fill the fridge on floor Lahey 3 in the vending alcove right before the cafeteria.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_TOLL_BROTHERS_HQ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Buzz intercom. Walk down main hallway, elevator on the left hand side. Take elevator to 2nd floor. Once on the 2nd floor, walk towards the kitchenette.\r\n\r\nIf no one answers, call 215-852-7948 for security.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_CHILDRENS_KOALA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take service elevator to 3rd floor - from there take signs to cafeteria',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Pittsfield Township Warehouse',
          id: 'MI_COSTCO_PITTSFIELD_TOWNSHIP_WAREHOUSE__771_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back by receiving to deliver.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_182__1801_W_WARNER_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_E7',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Glenbrook Hospital Earth Day Samples',
          id: 'IL_GLENBROOK_HOSPITAL_EARTH_DAY__2100_PFINGSTEN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'ATTN: GB Patient Food Service Rm B47',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: '75a66560-5460-4fac-a3db-b09ed78b8e69',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AIT_WORLDWIDE_LOGISTICS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come in through front doors, walk to back of office employee breakroom',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_ONE_CLEVELAND_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 5:30AM to 6:00PM for main doors (loading dock entrance is 24/7), Driver Entrance - Use main doors off E. 9th, Driver Parking - Park on street on E 9th, directly in front of doors, Park To Fridge Instructions - Fridge is located to the right, visible from entrance, check-in with security prior to stocking',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Warrenville, IL',
          id: 'IL_LIFETIME_WARRENVILLE_IL__28141_DIEHL_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3082',
          id: 'IL_JEWEL_3082__6014_S_COTTAGE_GROVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_CONCOURSE_A',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at loading dock at side of terminal. Enter through elevator on left side of dock. Code to operate elevator is 123* or 123 (and then turn a knob). Take elevator to gates level. Exit elevator and turn left. Walk passed the Dunkin Donuts and continue down walkway. Fridge will be on left side across from the food court.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_U_ST_THOMAS_CSHP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through front entrance, we are in the lounge area in the SW corner, cant miss us',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '3eab29ed-52c8-4843-a6dd-e9156c4b652d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EAST_ORANGE_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of hospital, check in at main entrance, take elevators up to floor 3.  The fridge is in the elevator bank on floor 3.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '842b949d-a3ce-4232-8095-d1fc0532ea90',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LAGUARDIA_CC_BUILDING_E',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call public safety at 718-482-5555 for access.\r\n\r\nFor loading access (if the gate is closed)  718-482-5565\r\n\r\nLOADING DOCK ONLY OPEN 8:30 am to 4 pm, your team needs to look for street parking if they are coming in the off hours.\r\n\r\nFridge is located on the 2nd floor atrium at the top of the staircase on the right side',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_JOHN_CRERAR_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the code for the arm gate to get in (6509#) and call Nita to show the fastest way to get to the fridge location. \n\n(773) 702-6019\n(219) 689-0892 - Cell #',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Monmouth Medical Center',
          id: 'NJ_MONMOUTH_MEDICAL_CENTER__300_2ND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Pull up to main lobby and call Laura.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_6TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, walk back to Vendetaria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_HENRY_FORD_ONE_FORD_PL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park near front door and enter. Take elevators to floor three - turn right off elevator and take a right to cafeteria- machine in cafeteria',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SOCAL_HOSPITAL_CULVER_CITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Make your way into the main doors and go to the cafe. You will see the vending room on the way and thats where we will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Canteen Event',
          id: 'TX_CANTEEN_EVENT__2747_W_GRAND_PKWY_N',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Please stock the fridge that is on-site under MARKETING in CMS',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BOSKRB02',
          id: 'MA_BOSKRB02__290_WILLIAM_F_MCCLELLAN_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Dropping to bradford for delivery to Kelly Roast Beef (BOSKRB01)',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'Costco - Maple Grove Warehouse',
          id: 'MN_COSTCO_MAPLE_GROVE_WAREHOUSE__11330_FOUNTAINS_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pull to the back of receiving and please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BABSON_HELV',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go from the main entrance to the staircase and head downstairs to the right where the vending inlet is located.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon DC -- WAS 17',
          id: 'DC_AMAZON_DC_WAS17__510_14TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Pull to Loading Dock. Loading dock will be on the corner of 15th and Elm. Meet security guard at dock',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Austin Samples',
          id: 'TX_SALES_AUSTIN_SAMPLES__120_BFG_9000_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Please drop off at the cold storage facility in Austin. Matt G will pickup',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Natalie',
          id: 'IL_NATALIE_EVENT__1140_N_WELLS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL NATALIE UPON ARRIVAL',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'IND_FRANCISCAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the dock Use the elevator #20 go up to the second floor then Walk down the hallway and fallow the signs with arrow to The “Terrace Cafe” fridge will be located near cafeteria main entrance.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_SPECIALTY_TRANSPLANT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Straight down through the doors, you'll see it right across the wall from the elevators",
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '110_N_WACKER_23F',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The delivery driver will access 110’s loading dock via the southbound service lane on Lower Wacker.  There they will check in with security and check out a keycard to access the 23rd floor via the freight elevator. Once on the 23rd floor, walk out of the elevator, take a right and then take another right into the gym. Fridge will be in the main area against the wall.\r\n\r\n**I have attached a floor plan with how they will access the fitness center once on the 23rd floor\r\n\r\nShould FF arrive after normal dock operating hours (6A-6P, M-F), there is an intercom located next to the service door on Lower Wacker that can be pressed to speak with security. Our security team can either issue an access badge upon arrival and/or provide an escort to the 23rd floor for the machine to be serviced/stocked. We have a strong preference for the machine not to be serviced during prime lunch traffic, between 10AM and 1PM, to avoid interfering with tenants attempting to make purchases.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_FEDERAL_COURT_HOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the mail room which goes directly into the cafe on the first floor.  WILL NEED TO BE ESCORTED UNTIL CLEARANCE IS THROUGH.\r\n\r\nCall when onsite:\r\nShelbi Hindel: (614) 558-8059\r\nJake Lord: 614) 359-1901\r\nIvan Rogers: (614) 981-8615',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Houston Event- Canteen',
          id: 'TX_HOUSTON_EVENT_CANTEEN__2724_W_GRAND_PARKWAY_N_SUITE_D_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please call delivery contact when you arrive and stock the fridge that is there for an event',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '0cff0860-5c1b-4cca-8e73-2b5f5f84a75f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_1245_MAIN_ST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through two sets of double doors. Make a left and walk to the mailroom towards the back. It will be the wall in the middle of the room located on the mailroom side.\r\nNeed a code to get in the front door #6787\r\nThe code to enter the building is 4823',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_DEPT_OF_ENERGY_GOV_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Need to be on access list (driver names sent in advance)\n\nTake Elevator to Ground Floor.\n\nHelp desk phone # (202) 586-6100\nKelvin Jamison (202) 664-4986\nFred Williams (240) 449-7461',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_OUTPATIENT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the main lobby across from the Starbucks\r\n\r\nIf during DAYTIME HOURS you can call Tim at 443-538-9766.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Orland Park, IL',
          id: 'IL_LIFETIME_ORLAND_PARK_IL__16333_SOUTH_LA_GRANGE_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MD_ANDERSON_ROC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the glass door and you will be able to see the spot on your left side of the wall in the waiting area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Fenway Park - Wholesale',
          id: 'MA_FENWAY_PARK_WHOLESALE__175_LPSWICH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Please go to the loading dock',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security to your left where you'll find the fridge in the main break room cafeteria.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BC_ONEILL_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Helen (617-460-6181) will show the driver where the fridge is. The loading dock is opened from Mon-Friday 7:30am-4:30pm and the phone number 617-552-4470. Driver will not need a badge. Driver can park in the dock for deliveries and enter the building through the dock.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Villanova - Dougherty Hall',
          id: 'PA_VILLANOVA_DOUGHERTY_HALL__800_LANCASTER_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver to Dougherty Hall loading dock',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_MEDICAL_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "If you go in from the Wolcott entrance, head straight down the hall way, turn left twice, and it'll be in an eating area with other vending machines\r\n\r\nPM Driver Entrance: 840 S. Wood St. Chicago IL 60605. Park in front of the Clinical Sciences Building. Use badge reader on the right, go up ramp and go down first hallway on the right. Make a left at the end of the hallway and fridge is in the vending area.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_VA_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors in main entrance, cafe will be around the corner on the right hand side',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Christ Hospital',
          id: 'OH_CHRIST_HOSPITAL__2139_AUBURN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'look for a church (Mt. Auburn Baptist Church) by the main entrance of the hospital, across from Gilman Ave. Depending which way they are coming they will either turn left or right and go down a hill by the church. Call Kevin 513-319-6749.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3338',
          id: 'CA_TARGET_T3338__1610_WILSHIRE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Hudson IAH Sample Pack',
          id: 'TX_CATERING_(COMPED)_HUDSON_IAH_SAMPLE_PACK__2800_N_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drop to the address listed, upon arrival please call the manager line listed to be met by manager and deliver product',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_Trim Dining Hall',
          id: 'MA_TRIM_DINING_HALL__25_BABSON_COLLEGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Pull into the loading dock on the backside of the building which is down a slight hill.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_GWC_LEARNING_RESOUCE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to Fine Arts building and it will be in the lobby',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LOYOLA_MARYMOUNT_LAW_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security. Fridge is located in main level cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_VA_JEFFERSON_BARRACKS_657',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Sliding Doors. Turn left towards exit sign down the hall. Keep walking down that hall. Turn left at Dead End. Fridge will be in atrium space',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_GRANITE_757_ELDRIDGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walking distance from parking dock.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_FEE_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'MI_MSU_FEE_HALL ***East Entrance ***\nAddress - 939 Fee Rd East Lansing MI 48825\nCustomer Friendly Location - MSU - Fee Hall. Located on the Main Floor near Dean’s Office.\nDelivery Hours - 7AM to 6PM\nDriver Entrance - Front Entrance\nDriver Parking - Park on the east side of fee hall\nPark to Fridge Instructions - Park in a loading spot by the East Entrance and walk in, machine is on the right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MSK_WESTCHESTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If front entrance, just sign in with security and go down the first flight of stairs, fridge will be visible in the back right corner',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UI_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Go through front door off of Taylor street - head past the front desk, and go to the left side of the building - the cafeteria is on the West wing and the Fridge is located on the north end of the cafeteria.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3296',
          id: 'NJ_TAGRET_T3296__650_CUTHBERT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 2 SD Zoo Front',
          id: 'CA_CANTEEN_VISTA_RT_2_SD_ZOO_FRONT__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_620__780_STANNS_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_SEDA_ROUTE6__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SUFFOLK_UNI_SAWYER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the loading dock and the food area is on the same floor to the left of the common area.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_5_DUP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Plainfield Warehouse',
          id: 'IL_COSTCO_PLAINFIELD_WAREHOUSE__16261_S._BOULEVARD_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_KARE11',
          id: 'MN_KARE11__8811_OLSON_MEMORIAL_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'PLEASE CALL JUSTINE',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Bridgewater, NJ',
          id: 'NJ_LIFETIME_BRIDGEWATER_NJ__145_COMMONS_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_DENTSU_42ND',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MAINSTAY_SUITES_SCHAUMBURG',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 283',
          id: 'IL_JEWEL_OSCO_283__2031_N_MAIN_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: '540_W_MADISON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 202',
          id: 'IL_JEWEL_OSCO_202__2550_N_CLYBOURN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: Behind deli counter. Go through double doors on right, then through plastic door curtain.  OTHER DELIVERY INSTRUCTIONS: Product will be stocked in cooler in marked FF marked Space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DWIGHT_LOFTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through Lobby, security will let you in. Fridge is by seating area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_AACC_HLSB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call overnight contact to let you in. Once in, see diagram in "Files" Public Safety: 410-777-1818',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_U_ST_THOMAS_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take side road on clevland ave and across from Portland ave. , take the ramp up to the front doors , enter main lobby and stay on same floor, go to the back of the library and find the stacks coffee shop \nfridge in coffee shop seating area against the wall on the right',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_MASSBAY_CC_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the dock -- fridge location is just down the hallway and to the left.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cal Lutheran Wholesale',
          id: 'CA_CAL_LUTHERAN_WHOLESALE__60_W_OLSEN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'GO TO LOADING ZONE 1 IN THE BACK. DROP OFF IN THE WALK IN COOLER AT ULLMAN CAFE',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'CALIFORNIA LUTHERAN UNIVERSITY',
        },
        {
          opportunityName: null,
          id: 'OH_CIN_DINSMORE_SHOHL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver will need to check in with security on the first floor – they will give security their driver’s license to receive a freight elevator badge. They can then take the freight elevator up to 19 (our reception area) and then reception can give the driver a badge for access to 20. Once the driver has completed stocking, they will need to return the Dinsmore badge to 19 and then check back in again with security to give back the freight elevator badge/receive their license.\r\n\r\nCan always call Sarah (513-639-9277) or Don Trusty (513-490-3226) if having issues',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_12TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in through front doors, check in through security, fridge located in Vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NY_JFK_T5_G7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Marketing_BethIsraelEastCampus',
          id: 'MA_BETHISRAELEASTCAMPUS__330_BROOKLINE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Please be sure the items are clearly marked as perishables, so our receivers bring it right up for refrigeration!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 3 Safari Park',
          id: 'CA_CANTEEN_VISTA_RT_3_SAFARI_PARK__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD 185',
          id: 'IL_HUDSON_ORD_185__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3486__1485_PALATINE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ULTA_BEAUTY_HQ_4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTH_BRIDGE_FOOD_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the dock they would take the elevator to the 4th floor and go out the corridor to the floor. The location is directly in front when they exit the corridor.   They would then go back to the elevator and down to the R level which is the level the dock is located and be back at their vehicle',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T2_F1',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            "O'hare parking, the fridge is located in Terminal 2 near gate F1",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD G19',
          id: 'IL_HUDSON_ORD_G19__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: '512e71f9-aae5-4597-a8de-fac94dc89be7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '600_W_FULTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with security. Park in loading dock, walk through dock to double doors, turn right and go through door on immediate right to tenant lounge',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELMHURST_LIBRARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3346__153_SCHILLER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_BAYLOR_ST_LUKES_MAIN_BRIDGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of the building. Go through the lobby to the second floor sky bridge.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) _HMS Cleveland Samples',
          id: 'OH_CCATERING_(COMPED)_HMS_CLEVELAND__4000_ORANGE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Chris will pick up order at Cleveland Cold storage.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_BENCHMARK_INDUSTRIAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Secretary will escort every time',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_8686_NEW_TRAILS_DR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in parking lot, walk in front door and boom its right there',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Cincinnati - Tangeman',
          id: 'OH_UNIVERSITY_OF_CINCINNATI_TANGEMAN__2766_UC_MAIN_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dock is located on the side of the building closest to the football field. Doorbell is on the right side of the dock. Bring food up to the locatio',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CHUBB_LIMITED',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Make your way to the main lobby and you will need to check in. Go to the elevator and up to the 3rd floor. Enter the Chubb office and take a left. Down the hall will be a break room with a ping pong table. Fridge will live here.\r\n\r\n213-612-5700 main lobby number\r\n\r\n310-796-6099 alternative if they don’t pick up, that’s andrews number aka the boss',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_AXN EVENT',
          id: 'IL_AXN_EVENT__1140_N_WELLS_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'PLEASE CALL NATALIE UPON ARRIVAL',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MAIMONIDES_ASB_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main entrance and the fridge will be visible when walking into the main lobby of ambulatory.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Scripps Memorial Hospital La Jolla',
          id: 'CA_SCRIPPS_MEMORIAL_HOSPITAL_LA_JOLLA__9888_GENESEE_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'LOADING DOCK 2. Please note that MASKS are required on site and they will temp the food when it arrives',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Scripps Health',
        },
        {
          opportunityName: null,
          id: 'IND_ROLLS_ROYCE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'you will be escorted to the 2nd floor by employee',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHILLY_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter hospital through front entrance - the fridge is directly in front of the front entrance in the starbucks area.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LAX_INTL_TERMINAL_GATE_130',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'n/a',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_U_MARYLAND_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the lobby doors and turn right towards the cafeteria',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_GWC_MATH_SCIENCE_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge will be located in the main lobby',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_CAPITOL_POLICE_STATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Client to confirm',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Loyola University Engrained',
          id: 'IL_LOYOLA_UNIVERSITY_ENGRAINED__N_SHERIDAN_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MAIMONIDES_MAIN_DOUBLE_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the main lobby. The fridge will be just past security in the main lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JAMAICA_MED_CENTER_ER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to follow up with instructions',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'follow the signage to the cafeteria, we are in the far back next to the other vending machines',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_ST_MARYS_UNIVERSITY_LASALLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors to the reception desk, take a right, take a left at the end of the tile flooring, elevator to the 2nd floor (there is sinage for the bookstore)',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALLSTATE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CUYAHOGA_CC_WEST_SHORE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the wooden doors at the dock, go into the short hallway and at the end make a left to the Cafeteria',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Kaiser Irvine Medical Center',
          id: 'CA_KAISER_IRVINE__6640_ALTON_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Elaine when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BERTS_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson 831',
          id: 'IL_HUDSON_831__500_WEST_MADISON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "loading dock is on the corner of Washington and Canal. The Truck Clearance is 12'-3' Bays 2-5, 12'-7' Bays 6-7, and 13'-4' Bay 8. Drop off location is on the 1st. level, Hudson Bookseller.",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Stony Brook University Hospital - Shop Red East',
          id: 'NY_STONY_BROOK_UNIVERSITY_HOSPITAL_SHOP_RED_EAST__101_NICOLLS_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'please call dc and deliver through the main entrance on level 5 at Stony Brook Hospital and drop product in walk in fridge. ALL STONY BROOK WHOLESALE DELIVERIES are to be dropped off at Stony Brook Hospital',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Stony Brook Medicine',
        },
        {
          opportunityName: null,
          id: '706c2110-0f72-449e-bf41-1837174885c8',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_Marymount University',
          id: 'VA_MARYMOUNT_UNIVERSITY__4737_4763_YORKTOWN_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Park in Loading Dock and call Stephen at (703) 201-3466',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Ted Weiss Federal Building',
          id: 'NY_TED_WEISS__290_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call and contact Minhaj Kaled',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'COMPASS_HEALTH_CENTER_NORTHBROOK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Drive around building to get to suite 300. Use the key to open the front doors.  Walk straight through the lobby and use the key card to enter the next door. Fridge is located directly to the right as soon as you enter\r\nKey card and physical key will be needed to service fridge\r\nContact info:\r\nChristina (630)940-6708 building manager \r\nEd Ludwig (773)410-6266 maintenance manager',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'they can enter in the back basement door to access the basement floor. regular deliveries can come in the front entrance or the back basement door. to the left of the current vending machines there are doors that lead to a hallway that leads to a back door.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_JACOBI_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cummins',
          id: 'IN_CUMMINS_CUMMINS_ENGINE_PLANT__500_W_JACKSON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dock Instructions for COB delivery: 799 Brown Street, Columbus 47201 is the dock address Upon Arrival drivers must buzz security to open the door for them. They can then drive into the dock to unload. There is a normal truck dock to back up to and unload, or there is a lift system that loads can be moved to and then get up to the main floor.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Boston Heights Warehouse',
          id: 'OH_COSTCO_BOSTON_HEIGHTS_WAREHOUSE__6720_BASS_PRO_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'PRENTICE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on the street in front of main lobby. go through main lobby machine is located on the northside of the building by the gift shop',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Union Market/Espress-Oh Coffee Shop',
          id: 'OH_OHIO_STATE_UNION_MARKET_EXPRESS_OH_COFFEE_SHOP__1739_N_HIGH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'full loading dock, someone will be there, enter from N High St entrance, loading dock is off of high st.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '69_W_WASHINGTON_PEDWAY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Five Star Food Services',
          id: 'OH_FIVE_STAR_FOOD_SERVICES__1195_CLOUGH_PK_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please park at the loading dock and reference Dave Sievers',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Please park at the loading dock and take the loading dock entrance to the Dr.'s lounge located on the 2nd floor",
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_C2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'DFW Loading Dock',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_900 2nd St NE',
          id: 'DC_CATERING_(COMPED)_SALES_900_2ND_ST_NE__900_2ND_ST_NE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Call/text Rosa upon arrival. Leave items with Securrity',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Pennsylvania Hospital',
          id: 'PA_PENNSYLVANIA_HOSPITAL__800_SPRUCE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            "Walk through the lobby and tell security you're delivering samples to Mark in the cafeteria. Call Mark if any issues (267) 546-6312",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Hudson Chi Syed Samples',
          id: 'IL_CATERING_COMPED_SALES_HUDSON_CHI_SYED_SAMPLES__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'DROP AT FULTON AND PUT IN FRIDGE IN H ETEST KITCHEN. CALL SYED',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Fort Worth Warehouse',
          id: 'TX_COSTCO_FORT_WORTH_WAREHOUSE__5300_OVERTON_RIDGE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_TARGET_DISTRIBUTION_CENTER__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_NJ Hudson Corporate',
          id: 'NJ_HUDSON_CORPORATE__1_MEADOWLANDS_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'deliver to lobby at Hudson Corporate office through contact Lissette, she will meet you in the lobby upon call',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_GLICK_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 5:30 AM to 10PM\nDriver Entrance - Front Entrance\nDriver Parking - Park near the front entrance, but not directly in front of valet area\nPark To Fridge Instructions - Walk through the front entrance, fridge is located past the information desk, against the left wall of the lobby',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_309_N_WATER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_GLEACHER_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_1455_FRAZEE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main doors and walk straight to the dead end and take a right. Fridge will be visible -- Security phone #619-250-6335',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Florence Warehouse',
          id: 'KY_COSTCO_FLORENCE_WAREHOUSE__800_HEIGHTS_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Park near receiving - off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MD_BAL_MEDSTAR_TIMONIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Take elevators up to 2.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DUPAGE_PEC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Marketing_LOYOLA_MARYMOUNT_ADMISSIONS',
          id: 'CA_LOYOLA__1_LMU_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Jayne Patterson (608) 512-7731',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 10 Amazon SCA4',
          id: 'CA_CANTEEN_VISTA_RT_10_AMAZON_SCA4__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_WOODLAND_HILLS_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Fridge located in cafe in the basement',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_VA_580',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front entrance, and check in with security, Continue down main hallway until you reach cafe. Turn right. Walk straight until you see vending area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAL_STATE_MAIN_FOOD_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '***Use address: 1994 Paseo Rancho Castilla, Los Angeles, CA 90032 for loading dock.\n\nOnce at loading dock, proceed to main door. Go straight and take your first right and then another right. This will lead you to main food court. Fridge will be located to the left in the middle of the food court.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Fox Vending MM44',
          id: 'IL_FOX_VENDING_MM44__9717_S_76TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please drop in the walk in cooler across from the desks Contact Stu Spector for delivery 708-430-4600, or Cell 708-287-4155.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Lakeview',
          id: 'IL_GETIR_LAKEVIEW__3052_N_LINCOLN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "call DC if there are q's",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_TABLER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the front entrance where they will escort you to the located spot. Call the POC when arriving.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - POST WEST',
          id: 'MN_CANTEEN_POST_WEST__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_IMU_GIFT_SHOP__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T5_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock.  Enter and walk straight down to security check point.  Must have everything screened by TSA and also wait for an escort to bring us into the terminal.  Either one of our contacts at the airport or a Third Party security service.  Walk through the area with the escort.  You will exit a door in front of Gate 7.  Turn left, then left again toward the exit.  Take elevator down and exit secure area, fridge will be on the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CHILDRENS_HOSPITAL_COFFEE_SHOP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in loading dock, go to 2nd floor, the fridge is in the coffeechop which is near the front of the building',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Phillips',
          id: 'MN_CANTEEN_PHILLIPS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_METROPOLITAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From that entrance the fridge unit will be on the left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "St. Peter's University Wholesale",
          id: 'NJ_ST_PETERS_UNIVERSITY_WHOLESALE_1__2641_JOHN_F_KENNEDY_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HORIZON_BCBS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use badge and take freight up to the 1st floor. Located near the Avenue C/micromarket. Security desk # is 973-466-4240',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_3M_280__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_JORDAN__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 41 – RS39',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_103_RS39__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'please call Delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_ART_MUSEUM__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '788944c6-a7b4-46d8-bef1-d530ce29bc9d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ICNC WORLD FAIR - MARKETING',
          id: 'IL_ICNC_WORLD_FAIR__135_N_KEDZIE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            '135 N Kedzie Ave, Chicago IL 60612 \nDani Zuchovicki: (312) 283-5723 \nPlease deliver Dec 7th between 1 and 4 pm',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CHOC_EMERGENCY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'loading dock',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'North Shore Country Club',
          id: 'WI_NORTH_SHORE_COUNTRY_CLUB__3100_W_COUNTRY_CLUB_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Go to the loading dock and use the building key. Put product in cooler',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Northshore Country Club',
        },
        {
          opportunityName: null,
          id: 'NJ_RARITAN_BAY_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'from dock they will be escorted to cafe area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEPAUL_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Public Parking in front of the college at the front door, enter through the front door, follow signs for the cafeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Salk North Facility',
          id: 'CA_SALK_NORTH_FACILITY__10240_SCIENCE_CENTER_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAPGROUP_400_HOPE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Need to stock 333 Hope first, as that is where drivers will get a temporary day badge. Drivers will show ID to get a temporary day-badge that can used at 333 Hope and 400 Hope. After stocking, driver will need to return to 333 Hope to return badge and retrieve ID.\r\n\r\nFrom loading dock, check in and then take the Freight Elevator entrance to the 20th floor at the 400 S Hope building.  The location is the room next to the freight elevator when you exit.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St. Elizabeth Hospitals',
          id: 'KY_ST_ELIZABETH_EDGEWOOD__1_MEDICAL_VILLAGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_PEARLAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "If you're parking near the ER, then the fridge is going to be right in front of the entrance.  If you're coming from the loading dock, etc, then I would just follow the signage for the ER.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_YAMAHA_MOTORS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building and check in. Go through the doors and you will see the Fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_SOUTH_SUBURBAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through main entrance, keep going straight through main hallway, fridge is located in hallway',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'METRA_LASALLE_STATION_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park along side wall where employees park. Do not park and block the security cars or they will tow the van. Then go out the parking lot take the front elevator to the 3rd floor . Fridge is in the lobby against the wall. Twist the lock on the doors and they will open.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Hudson MDW Samples',
          id: 'IL_CATERING_(COMPED)_-_SALES_HUDSON_MDW_SAMPLES__5700_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Dropping to Hudson MDW',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DMC_CHOM_VENDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map pin:\nhttps://maps.app.goo.gl/YRa51yKwAW5TgQFBA\nMain entrance hours: 6am-8pm\nPark at the end of the main entrance loop.\nKeep right and follow signs for the cafeteria.\nMachine is in the vending area.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '60e9395b-9a5a-443c-b14e-c206c0ae65bb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PAYLOCITY_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3371',
          id: 'CA_TARGET_T3371__8900_SOUTH_SEPULVEDA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Costco - Perrysburg Warehouse',
          id: 'OH_COSTCO_PERRYSBURG_WAREHOUSE__26400_DIXIE_HIGHWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive near the back by receiving to deliver',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__1911_W_CHICAGO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Washington Univ - St. Louis',
          id: 'MO_WASHINGTON_UNIVERSITY_ST_LOUIS__6616_SHEPLEY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_CARTHAGE_COLLEGE_CLAUSEN_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Meyers Printing',
          id: 'MN_CANTEEN_MEYERS_PRINTING__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NJ_CAPITAL_HEALTH_REGIONAL_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Ring the bell. Walk through thee doors and turn left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '10_S_RIVERSIDE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the hallway and take a right. Walk straight to the lobby and the fridge will be against the back wall facing the revolving door',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Freddie Mac',
          id: 'NY_FREDDIE_MAC__8250_JONES_BRANCH_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'deliver at loading dock',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ORTHOPEDIC_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Sign in and out at the security desk. Walk into the cafeteria',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'OH_GOPUFF_273__5229_MONTGOMERY_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_BUCKS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk down the hallway  towards cafeteria. Fridge at entrance',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Hudson LAX Sample Pack',
          id: 'CA_CATERING_(COMPED)_SALES_HUDSON_LAX_SAMPLE_PACK__1_WORLD_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Bring food to Hudson Store "Wilshire and Fairfax" in the West gate area of the Terminal. Hudson will distribute from there.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_PENN_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through double doors.  Walk through kitchen. Walk out of kitchen and walk towards electors. Take elevator  up to 1. Turn right out of elevator towards 1751 cafe. Fridge on the left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_UNIVERSITY_AKRON_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors, take right or left depending on which door you enter through to get to the double doors straight ahead- fridge will be in the room with the picnic tables on the left right when you walk in.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Foodworks',
          id: 'IL_CANTEEN_FOODWORKS_171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ST_FRANCIS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NV_Catering (comped) - Sales_Marshall Retail Samples',
          id: 'NV_CATERING_(COMPED)_SALES_MARSHALL_RETAIL_SAMPLES__3755_W_SUNSET_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Air shipment to Las Vegas',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_BUTLER_BETHESDA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'The vending area is in the main lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_EMU_ALEXANDER_MUSIC_ATRIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin: https://maps.app.goo.gl/jE14QVryqgDgJQLx8 Park in front with hazards on and enter through the main doors. Once inside you’ll see another building straight ahead. Enter the doors of the second building and the fridge is on the right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD AM',
          id: 'IL_HUDSON_ORD_AM__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'ZEBRA_TECHNOLOGIES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DFW7',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the security, take a right, and the breakroom will be straight ahead',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_ALLEN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the loading dock, buzz security buzzer, walk through the door and take your first left, cafeteria will come up on your right',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_USPS_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once in through the dock (badges will need to be used) take the hallway left towards the middle of the USPS hallways. Take main floor and follow yellow tape towards the Cafeteria. Please note access is closed daily for cleaning from 1:45-2:45 am',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_CENTRAL_MARKET_LEFT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Walk into terminal and head to the central market next to the Hudson vending kiosk',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2054',
          id: 'PA_HUDSON_PHL_2054__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Jewel Osco - 3097',
          id: 'IL_JEWEL_3097__1148_OGDEN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'MKE_MAD_ARBOR_GATE_2601',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'After parking take the main entrance of building 2601',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JET_COM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GEORGE_LEIGHTON_JURY_ROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Pull up to loading dock, sign in, turn right and follow corridor around to get to elevator bank. Follow hallway to the end of the hall, elevator will be on the left. Use key up to the 3rd floor, make a right, go straight down and around to main jury room. Fridge will be in the back near the vending bank.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2053',
          id: 'PA_HUDSON_PHL_2053__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_225_BROADWAY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'COMMERCE_PLAZA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_LIFE_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors and check in at desk. Continue down main hallway. Take a left down the main hallway. fridge located in atrium across from entrance to cafe.\r\n\r\nIf front entrance is closed. Walk in through emergency entrance - check in with security - fridge is straight forward once you get pass security.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St. Elizabeth Florence Hospital',
          id: 'KY_ST_ELIZABETH_FLORENCE_HOSPITAL__4900_HOUSTON_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Richmond Warehouse',
          id: 'TX_COSTCO_RICHMOND_WAREHOUSE__11125_W_GRAND_PKWY_S',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back by receiving. Unload product, ring bell to get let in, bring product to the shelf',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Getir- Somerville',
          id: 'MA_GETIR_SOMERVILLE__42_MERRIAM_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_302 W Third Street',
          id: 'OH_SALES_302_W_THIRD_STREET__302_W_THIRD_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'please call delivery contact',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_SCHOOL_OF_ED__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_ICNC_121',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1007_W_HARRISON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Entrance is located at the east side  of building near the loading docks. Use badge to swipe in and walk towards the right past the classrooms and look for Vernon park and food court. Fridge is inside food court.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_YORK_HPE_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Can call John Baxter (718-262-5107) and enter the building HPE building through the back doors and take the elevator to go upstairs.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e841b5c5-eb79-46ea-8140-ca9470ee0af0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JENNER_BLOCK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'New England Baptist Hospital - Wholesale',
          id: 'MA_NEW_ENGLAND_BAPTIST_HOSPITAL__125_PARKER_HILL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'deliver to the front and call jeremiah. \r\nIf he doesnt answer still go to the front, put hazards on, get ticket from kiosk and if this is done under 30 min it is free',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Burlington, MA',
          id: 'MA_LIFETIME_BURLINGTON_MA__186_MIDDLESEX_TURNPIKE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Oakbrook, IL',
          id: 'IL_LIFETIME_OAKBROOK__2000_OAKBROOK_CENTER',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_JET_PROPULSION_LABORATORY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'See map of campus',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_THE_MET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver will park in loading dock and be escorted by security to space',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_LULUPAS',
          id: 'CA_LULUPAS__142_BERKELEY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'PLEASE DELIVER AS CLOSE TO 5:30PM AS POSSIBLE! THANK YOU',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales Atkore Sample',
          id: 'MW_CATERING_(COMPED)_SALES_ATKORE_SAMPLE__',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call the delivery contact upon arrival',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Avon OH Warehouse',
          id: 'OH_COSTCO_AVON_WAREHOUSE_35804_DETROIT_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_364__1242_W_DEVON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_EDITH_NOURSE_VA_518',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock, no access card needed, or knock on red door near big yellow container. Once inside, make a left, and a left down hallway 78',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_JAVITS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_District Center 2',
          id: 'DC_DISTRICT_CENTER_2__555_12TH_STREET_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park on 11th Street and call Allison 202 394 1700',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_2_E34_WALKWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_W_LA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk from the loading dock to the cafe. Fridge located in vending room next to cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'The Aerospace Corporation',
          id: 'CA_AEROSPACE_CORP__2310_E_El_Segundo_Blvd',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Pull into main parking lot. Before secruity hut, go left and park in visitor parking. Check-in at main secuity check-point. Tim Dow will meet you and escort you on premises. 8184801097',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'The Aerospace Corporation',
        },
        {
          opportunityName: null,
          id: 'RUSH_OAK_PARK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Main entrance of the lobby, head to the elevator bank and head to the 2nd floor. Turn right and the fridge is outside the cafeteria.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_SOUTH_SHORE_UNI_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building by the employee entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CNA_WABASH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Mt. Washington Pediatric Hospital',
          id: 'MD_MT_WASHINGTON__1708_W_ROGERS_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in roundabout and call Michelle 443-841-9452',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Kirkland & Ellis NYC',
          id: 'NY_KIRKLAND_ELLIS_NYC__601_LEXINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading dock is located 54th st between 3rd and Lexington.  Check in with security and go up the freight elevator to the 42nd floor. If security will not assist you please reach out to the delivery contact (call or text) - no matter the time!',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_Robert Wood Johnson University Hospital',
          id: 'NJ_ROBERT_WOOD_JOHNSON_UNIVERSITY_HOSPITAL__89_FRENCH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'they can deliver the samples to the back of the hospital at the shipping and receiving dock (89 French st).  The Food & Nutrition Department has a door which they can bring the samples to',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AMAZON_MKE1_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, take left and walk to employee breakroom',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_2_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD H5',
          id: 'IL_HUDSON_ORD_H5__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MORNINGSTAR_TENANT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon arrival, drivers should check in with the lobby security team to get access to the fridge on 7. Take the elevators to the 7th floor. Walk North out of the elevators. Take a right after the elevator bank. Walk straight until you reach the fridge.\r\n\r\ncan call security at 312-516-1510',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f9ca34d7-57b8-4980-b4d6-ab9854567fc1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_MADISON_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the back door into the lobby and the Fridge will live there',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_WAGNER_COLLEGE_SPIRO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the Spiro Hall building and turn right when facing the stairs to the planetarium.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HAMILTON_COUNTY_POLICE_DEPT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in to front entrance, will be escorted back. The Fridge is about a 1 min walk to your left upon entry.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd37dd498-d475-4ee4-99ce-8cf9a679bdc5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESENCE_RESURRECTION_MEDICAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Park at the loading dock located off of Talcott Ave and on the South East side of the building.  There is a phone on the loading dock that connects to security.  They will then open the dock for you. If they give you any trouble mention that Michelle has given you permission. Walk through the loading dock and take a left and then a right down the hall and then a left down the hall. Go one door past the Cafe Res and enter where the seating area is.  Take a right and you'll run into the fridge.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STARCOM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Palantir - DC',
          id: 'DC_PALANTIR_DC__1025_THOMAS_JEFFERSON_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Go to loading dock on K Street, around the corner from front entrance. Text Luis when driver is 5/10 minutes away. He will meet you at loading dock and escort driver to elevator to 6th floor. There is no entry without an escort.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Grafton Warehouse',
          id: 'WI_COSTCO_GRAFTON_WAREHOUSE__950_PORT_WASHINGTON_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'Drive to the rear near receiving to deliver',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_SOUTH_QUAD_BLUE_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_IMU_RETAIL__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JEFFERSON_HEALTH_STRATFORD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Ring the bell and head to the cafeteria area is around the corner\r\n\r\nSecurity: 856 346 7660',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Tiffany & Co - Midtown',
          id: 'NY_TIFFANY__6_EAST_57TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Delivery instructions: Come in through the 6 E 57th street entrance. Tell security they are going to cafe in 5th floor. Ask for Rodney or Jennifer, who can place food in the fridge.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_NATALIE',
          id: 'CHI_NATALIE__1140_N_WELLS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'PLEASE CALL NATALIE 8473700725',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOLLYWOOD_PRESBYTERIAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in the main hospital patient drop off round about (follow signs and also where uber and lyft pick up and drop off), When looking at end of roundabout, go left and follow blue line on sidewalk to main hospital entrance NOT doctors tower. Enter through sliding double doors, fridge will be located in main lobby prior to check-in.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_DEARBORN_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin:\nhttps://goo.gl/maps/17k4Np2poCpfXyH8A\nEnter through Fitzgerald Pavilion doors, continue straight.\nTake the last left before you walk out the opposing set of outside doors.\nCafeteria entrance will be to your right after the bathroom.\nWalk through the cafeteria. Fridge is to the right in the seating area after the vending area',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - SWITCHBACK',
          id: 'MN_CANTEEN_SWITCHBACK__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Jared Winchester',
          id: 'MO_JARED_WINCHESTER__317_CHEVAL_SQUARE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please deliver to Address',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1700_W_HIGGINS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_OU_RECREATION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in the main atrium',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_IDDBA',
          id: 'CA_IDDBA__1850_S_WEST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Please call Chris M upon arrival',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Gopuff',
          id: 'PA_GOPUFF__537_N_3RD_St',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Walk into main entrance and drop with front desk person. They receive packages and will contact the appropriate person. Can call 203-561-2484 if needed.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host BOS',
          id: 'MA_HMS_HOST_BOS__290_WILLIAM_F_MCCLELLAN_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Dropping to HMS Distribution warehouse',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_NORTHVILLE_HEALTH_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park by main entrance. Immediately to the right of the main entrance is the Vending area where our machine is located.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_MTA Building',
          id: 'MD_MTA_BUILDING__1515_WASHINGTON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Double Park out front and call Joe',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AMAZON_LDJ5_LINEAR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Dock Ramp 101 has access to main break room and then you will proceed to the small open air break room near Linear.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'College of the Canyons',
          id: 'CA_COLLEGE_OF_THE_CANYONS__26455_ROCKWELL_CANYON_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'PARK IN ROUND ABOUT IS IN THE MIDDLE OF CAMPUS - GO TO STUDENT CENTER. ROOM 123 AND ENTER THROUGH THE FRONT. Office is staffed starting at 8AM. To gain access before the office opens at 9AM, either knock or call the office at (661) 362-3375',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'College of the Canyons',
        },
        {
          opportunityName: null,
          id: '200_N_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Grossmont College Building 60-206',
          id: 'CA_GROSSMONT_COLLEGE_FOOD_PANTRY__8800_GROSSMONT_COLLEGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'The driver can either drive up the loading dock by building 10 and park there and walk over to building 60. Or they can park in the lot behind building 60 which is by #56 and walk toward the building that way. We are located on the 2nd floor. There is an',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Grossmont College',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Rachel Rischall',
          id: 'IL_RACHELRISCHALL__1319_W_WELLINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL RACHEL',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_PARMA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 7:30AM to 7:30PM\nDriver Entrance - Back corner entrance of building, use door that leads to pharmacy\nDriver Parking - Park near the back pharmacy entrance doors\nPark To Fridge Instructions - Walk through doors leading to pharmacy, walk past the pharmacy, fridge is located alongside the left wall of the vending bay area',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RWJ_RAHWAY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If going thru receiving dock just go through the doors and toward cafeteria. If main entrance just show ID and walk toward cafeteria',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_MULTI_MODAL_FACILITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Walk into Facility',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HOFSTRA_UNIVERSITY_BRESLIN_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Courtyard between classrooms. Breslin Hall will be on the right. Fridge will be in lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_RICHARDSON_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the elevator to the second floor, fridge will be directly ahead of you getting off the elevator.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Houston Methodist Woodlands',
          id: 'TX_HOUSTON_METHODIST_WOODLANDS__17201_I_45',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOYOLA_MEDICAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the general north entrance door, walk straight all the way down the hallway then turn left and there is Jazzman Cafe and Fridge',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Lewisville Warehouse',
          id: 'TX_COSTCO_LEWISVILLE__851_STATE_HIGHWAY_121_BYP',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Vanderbilt - BRANSCOMB',
          id: 'TN_VANDERBILT_BRANSCOMB__103_21ST_AVE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BOMA',
          id: 'IL_BOMA__115 S Lasalle St. Chicago, IL 60603',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call (267) 474-9042 upon arrival!',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3441',
          id: 'IL_JEWEL_OSCO_3441__2940_NORTH_ASHLAND_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: In deli cooler behind deli counter. OTHER DELIVERY INSTRUCTIONS:food to be stocked in deli section in FF marked area',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_UD_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in, take the elevator to the second floor, take a left in the main lobby and Fridge will be directly across at the start of the other hallway',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside and the Fridge is located in the back right of the main lobby area by the elevators. Loading docks is closed on all federal holidays but the hospital is always open. Drivers can enter through the Emergency room. We will be closed 6/20 and 7/4. But again they can also enter through the ER.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_MCDONEL_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address - 817 E Shaw Ln East Lansing MI 48825\nCustomer Friendly Location - MSU - McDonel Hall East Entrance. Located on Main floor near Main Lecture Hall\nDelivery Hours - 7AM to 6PM\nDriver Entrance - East Entrance\nDriver Parking - Park at East entrance of McDonel Hall\nPark to Fridge Instructions - East Entrance of building, turn right before Spartan Cafe and walk around to use ramp. Continue down hallway, make right at Lecture hall and machine is on the left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, take the elevator to the manufacturing floor which is labeled that',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_OVERLOOK_MEDICAL_MATERNITY_WARD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'USPS_OHARE_NORTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "There are going to be several ways in which you enter the facility. \n\nThe 1st is if you're going AFTER HOURS (outside of 7am-4pm)\nOnce in the facility take double doors to elevator on your left and take to 2nd floor\nTake that to the main security entrance to the turnabout doors with badge access in through the turnstile for North building entrance. Once through security, take elevators back down to 1 to access the main doors take a left into the mail room. Go to your right and follow all the way down to sign that says break room 160 at pillar M9.\n\nIf coming in during business hours, take the loading docks and enter into the main facility. Take a right and follow the hallway until you see the sign that says break room 160 at pillar M9.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'SD CITY COLLEGE PANTRY',
          id: 'CA_SD_CITY_COLLEGE_PANTRY__1313_PARK_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Go to receiving area- parking lot at the very end there is a sign that says "receiving" head there to the loading dock',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'California Community Colleges System',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SUFFOLK_COUNTY_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_DCAS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park by security and dogs will sniff all food. TBD driver may or may not be able to park behind security where the freight elevator is on the left side. Go through the elevator and come out by security. Cross over to the double doors where is says Mezzanine. Go through both set of doors and the Mezzanine elevator is on right side.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JOHNSON_AND_JOHNSON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "mailroom receivers are stationed at the loading dock and can direct the driver-but, they would go through the loading dock/warehouse, make a right into the corridor, and proceed to the visitor's entrance/lobby, starbucks is to the right",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen- Target Chicago South [Route 10]',
          id: 'IL_CANTEEN_TARGET_CHICAGO_SOUTH__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMERICAN_AIRLINES_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk through the main entrance doors, take a left, and it's located on the main level within the cafe.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_WNBPA',
          id: 'NY_WNBPA__1133_AVENUE_OF_THE_AMERICAS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL MICHAEL 805-637-7605',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_AMAZON_DIL3__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_MCCULLOUGH_HYDE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the main hallway to your left toward dining room',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_VA_LONG_BEACH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the Emergency Room! 5 minute walk from there, look for the vending atrium',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_COMMUNITY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Enter through the Emergency Department entrance and go to your right and you will see the Farmer's Fridge unit on your right side as you follow the tile past the waiting area.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_NORTH_CYPRESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door - the fridge is right in the lobby in the coffeeshop area',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3407',
          id: 'IL_JEWEL_OSCO_3407__5616_N_CLARK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Receiving opens at 7:30am and Receiver lunch starts at 12pm',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'OH_COL_DSCC_BUILDING_20',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock (basement floor) to the first floor store',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_SCHOOL OF ED_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ST_BERNARD_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_NORTH_LAKE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'In building K. The machine is right across from subway.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '3d6b6c88-3173-401c-94ee-5b142a4bffe7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_UMB_PHARMACY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk through Main Doors',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford - Main Detroit Campus Henry Express',
          id: 'MI_HENRY_FORD_MAIN_DETROIT_CAMPUS_HENRY_EXPRESS__2799_W_GRAND_BVLD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            '"Map Pin:\r\nhttps://goo.gl/maps/KYS2zfX7WHAfRAfY6\r\nEnter through the Clinic entrance doors.\r\nTurn right, and then take the elevators on your right down to the basement.\r\nFollow the hallway around, and turn right at the end of the hall.\r\nFollow that hallway down to the end, and follow it to the left.\r\nProceed through the double doors.\r\nTake the third door on the right.\r\nThe ""Express"" order goes in the 2nd walk-in straight ahead.\r\nThe ""Main Cafeteria"" order goes in the 1st walk-in to the left.\r\nAsk for assistance from staff as needed."',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'The Henry Ford Health System',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_PVOLVE EVENT',
          id: 'IL_PVOLVE_EVENT__730_W_RANDOLPH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Kelsey Evans (872) 250-8713',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_DEPARTMENT_OF_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the main elevator bank to the basement',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ELMHURST_HOSPITAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through lobby. Fridge is on the right hand side of the lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '094730fd-794e-4360-a93a-205bb3bcb33a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESIDENTS_PLAZA_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Centerville Warehouse',
          id: 'OH_COSTCO_CENTERVILLE_WAREHOUSE__5300_CORNERSTONE_NORTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CA_LAX_T1_GATE_14',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'N/a',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_CARGILL__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Houston Main Campus',
          id: 'TX_UNIVERSITY_OF_HOUSTON_MAIN_CAMPUS__4384_COUGAR_VILLAGE0_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'We can enter through the front entrance or the back',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_VA_CLINIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'we are located in the cafe that is directly to the right after you enter the building',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Rodgers Park',
          id: 'IL_LOGAN_SQUARE__2882_N_MILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Chicago South Loop Warehouse',
          id: 'IL_COSTCO_CHICAGO_SOUTH_LOOP_WAREHOUSE__1430_S_ASHLAND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Syed Samples 2',
          id: 'IL_SYED_SAMPLES__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Syed and Mike will pickup at Cicero for Aldi meeting',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'AMAZON_ORD4',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in through security, fridge located in employee breakroom',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_GOOGLE_ALEXANDRIA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call the number (703) 609-1987 to be let in. Take freight up to 5. Machine is in the kitchen',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DUPAGE_SRC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b78c1bd6-94c5-4b68-8135-e7bd24164543',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FCB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Old Orchard, IL',
          id: 'IL_LIFETIME_OLD_ORCHARD_IL__5330_OLD_ORCHARD_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_RIVER_CITY_CASINO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery of this machine we will have you use our loading dock, driver will walk through the hallway which leads straight to the break room',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 144 And 452',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_144_AND_452__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Delivery Instructions\r\nPlease call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: 'NYC_APPLE_CORPORATE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight elevator to 10th floor. Exit double doors, take first right and then another right. Vending room will be on the left down the hall.\r\n\r\nOnsite # to call: Reception Desk at 646-335-1410. Call the number at least twice before turning away. \r\n\r\nAs a backup, call the secondary reception floor at 646-335-1963.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RWJBH_NEW_BRUNSWICK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'They can park at the loading dock, and then call Mike Kasperek (Executive Chef).  They provided his phone # on the client intake form.  He will show the driver where to take the refrigerator.  The delivery window for installation is from 8 am – 2:30 pm.  Stocking the refrigerator can be any time.  However, we will not have a point of contact person overnight.  The last person to leave is at 8:30 pm.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAL_POLY_AMM_45',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the AMM Building and you will see the Fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_LA Maywood Facility',
          id: 'CA_LA_MAYWOOD_FACILITY__4569_MAYWOOD_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Patrick to pickup at Maywood facility',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '55_E_MONROE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If stocking between 7am-5pm- the dock officer will give a card for the freight elevator. Go up to the lobby. Take a right out of the elevator, another right, and the fridge will be behind the security desk\r\n\r\nIf stocking overnight, just walk through the doors and the fridge will be behind the security desk (312-855-0055)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'LGA Stellar',
          id: 'NY_LGA_STELLAR__2-15_26TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver tobradford with specific instructions to be send to Terminal C stellar locations',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'KRAFT_HEINZ_TENANT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take loading dock elevator to floor 76 - walk out of elevator and take a left, head down the hall and take a right through the elevator bank and walk through the double glass doors into the space (you'll need to badge in).",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Service - Beavercreek - KHN NOCC',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_NOCC__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Chris Samples 3',
          id: 'IL_CHRIS_SAMPLES_3__1448_N_ORLEANS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call me and Ill come out to grab the package',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 2363',
          id: 'IL_JEWEL_2363__5616_W_159TH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'Hudson IAD - A23',
          id: 'VA_HUDSON_IAD_A23__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_EDDIE_SAK',
          id: 'TX_15500_VOSS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Eddie will pick up at cold storage',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN MAIN CAMPUS',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_MAIN_CAMPUS__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Lewis Center Samples',
          id: 'OH_LEWIS_CENTER__7100_Graphics_Way',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Contact David Nelson for dropping off 740.953.4324',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Hudson JFK T7 Sample pack',
          id: 'NY_CATERING_(COMPED)_SALES_HUDSON_JFK_T7_SAMPLE_PACK__QUEENS_NY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'The delivery can be made to our warehouse in Terminal 7 . They would need to go to Post G behind the Jet blue Hanger corner of 148th street and south cargo road ( they can goggle it ). The post does not open until 6am , there will be a security guard a',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_SAKS_FIFTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the freight elevator to the upper floor',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_SHUTTERFLY__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Texas A&M',
          id: 'TX_TEXAS_AM__400_BIZZELL_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'This is to be delivered to Texas A&M in College Station, which is out of route.  Please call Eddie Sak when you arrive @ 630-777-1885.  Meeting with Texas A&M starts at 10am.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_CBRE_ISELIN',
          id: 'NJ_CBRE_ISELIN__200_WOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'There are designated visitor parking spaces at the front. Upon arrival, please sign in at the lobby security desk and mention that you are delivering to Siemens, providing my name (Megan Marzigliano)',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '4d25871e-c39c-4029-8366-07b998cacf6a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - King of Prussia, PA',
          id: 'PA_LIFETIME_KING_OF_PRUSSIA_PA__700_EAST_SWEDESFORD_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESENCE_SAINT_FRANCIS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Turn into the ER entrance off of Austin St., pull all the way up to the door. Check in with security just inside the door, follow the signs to the cafeteria, the vending room is connected to the cafeteria. Don't park along the building or anything because of ambulance/ER needs.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DashMart Chicago',
          id: 'IL_DASHMART_CHICAGO__1010_N_HOOKER_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'calll contact upon arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2056',
          id: 'PA_HUDSON_PHL_2056__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_HOMAN_SQUARE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with security then walk to fridge located near elevators\r\nNO WEEKEND ACCESS',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_BRECKSVILLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 6:30AM to 7:30PM\nDriver Entrance - Front main entrance\nDriver Parking - Park directly in front of entrance doors\nPark To Fridge Instructions - Walk through front door, head straight back past information and pharmacy, fridge is located against the back wall',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_BETH_ISRAEL_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Main entrance (before 10pm)  check in with security to provide a pass to access the seating area of the lobby. Once checked in the Fridge is on your left in the lobby seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Graco Riverside',
          id: 'MN_CANTEEN_GRACO_RIVERSIDE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MI_MSU_SHAW_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'take hallway to ice machine',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_1ST_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, fridge located in the vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_WINCHESTER_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Utilize the emergency entrance and just walk straight down that hallway and the Farmer's Fridge will be on your left as part of the vending wall.",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCC_SOUTHEAST_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the main entrance, take a left down the hallway, Fridge will be on the left side.\r\nIf they are ever struggling to get in the Campus Police (817-515-8911) are always available to help get them in',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_MOUNT_CARMEL_ST_ANNS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ALWAYS_IN_MOTION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building to the main area. Take a right and the kitchen / break room will be visible.\r\n\r\nAs of 8/7/24 please leave expired product with the client at the reception desk.\r\n\r\nAccess hours for Saturday and Sunday are 7AM-2PM',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_SOUTHWEST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, take a right down the hallway which will lead you to the cafeteria. Fridge will be located just outside the cafeteria next to the micro-market. Will have to get a temp badge if they go through Engineering. Can go through ER or Main entrance if they need to enter overnight.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_RPG_HAMILTON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_CityWestPlace',
          id: 'TX_CITYWESTPLACE__2103_CITYWEST_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT4_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge will be located in the back breakroom',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_VA_VBA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go to the front desk in the Hospital to request a Police escort into the VBA> VA police are on a rotating shift but have a main number to call in case no one is availible at the dest (414-384-2000  extension 42222) \r\n\r\nThis can be used regularly to request an escort as well. Fridge is immedeatly to your left upon entry into the VBA.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_ANAHEIM_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Fridge located in first floor cade',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_DEUTSCHE_BANK_SANTA_ANA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'TBD\r\n\r\nForm needs to be filled out to access \r\nhttps://app.smartsheet.com/b/form/7f6d16f7f60e47d0bd980b3f50955338',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FAEGRE_DRINKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Sales_United Willis Tower',
          id: 'IL_UNITED_WILLIS__233_S_WACKER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call Tim at 872.825.8656 or 224.258.5749 upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '190_S_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_ARRIVALS_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Athleta',
          id: 'IL_ATHLETA__901_W_WEED_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: '312-640-1040',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_HP',
          id: 'TX_HP__10300_ENERGY_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Annette Kelly - cell: 619-206-8560',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_MED_INGALLS_FLOSSMOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take main entrance to the right through door, vending area on left',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_165__3080_W_SOFFEL_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Eddy Samples',
          id: 'CA_EDDY_SAMPLES__11262_OTSEGO_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Leave for apartment 105. Call Eddy at 513-652-7043',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CEDARS_SINAI_8700_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in main entrance near the check-in counter to ER. Walk up to main check-in counter and fridge will be just past on the right.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_SAT_CONCOURSE_A_TICKETING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Tbd',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mercy Hospital - St. Louis',
          id: 'MO_MERCY_HOSPITAL_ST_LOUIS__615_S_NEW_BALLAS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Pull up to the main entrance and put on hazards and enter via the patient entrance. Cannot use this entrance past 1pm. For any reason we cannot make this window- enter via the loading dock by 3:30pm at the absolute latest. DO NOT DROP ON FLOOR',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Mercy Health System',
        },
        {
          opportunityName: null,
          id: 'CA_LA_SOFI_STADIUM_EMPLOYEE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once onsite, driver can follow the directions below to get down to Level 1 and park directly next to the fridge (see map attached).\r\nDriver will need to check in at the guard booth at the top of the ramp. Note that there will be a K-9 unit to screen the vehicle. \r\nOnce cleared past the guard booth, please continue straight down the ramp until they reach the loading dock on Level 1.\r\nAt the loading dock, make a right turn (one way traffic only) and continue around the service loop until they reach the parking lot in the Southwest corner of the stadium (see map attached).\r\nDriver can park and unload directly in front of the fridge. Located on the lower concourse off of the operations/employee hallway',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_LIBRARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_CDW5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'sign in with security to get a day badge. go left from where security is, walk straight into the breakroom',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_MACHUGA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'can enter in through emergency exit if during day, if not then enter in through the main entrance and go to 2nd floor where living area room is',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_MT_WASHINGTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Ring Buzzer. Security phone # is 410-735-7690. Walk down hallway. Turn right through double doors. Go straight through double doors again and turn left down the hall. Fridge is on the left in the cafe.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WRIGHT_C_STORE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Spectrum Health Blodgett',
          id: 'MI_SPECTRUM_HEALTH_BLODGETT__1840_WEALTHY_ST_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            '"Map: https://maps.app.goo.gl/x98yE7uzHRhZb5oFA\r\nHours: 6-2:30\r\nEnter through the door up the stairs at the dock and let the people in the receiving office you\'re here with the Farmers Fridge delivery. They\'ll take everything from you via the yellow liftgate dock"',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Spectrum Health',
        },
        {
          opportunityName: null,
          id: 'MN_AMAZON_MSP1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with security, take left down main hallway and breakroom is on the left',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOODYS_7WTC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_ANN_ARBOR_VA_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin: (same as VA 1)\nhttps://goo.gl/maps/nuLg6BES174ZcjbW9\nLocation: Located in the 5th Floor surgery waiting area.\nFrom the other VA fridge, turn left, and then take the Central elevators on your left up to the 5th floor.\nFrom the elevators, turn left, then follow the hallway around to the surgery center lobby.\nTo Exit:\nGo back the way you came, but pass the elevators you came in on. Follow the hallway around to the next set of elevators, and take them down to "B".\nFrom these elevators, turn left, and left again, and you\'ll head straight out the door.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BUNKER_HILL_CC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'An elevator will be taken to an elevator around the corner to the 2nd floor. John Chirichiello (617-228-3352) will be able to direct you.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Sarah',
          id: 'IL_SALES_SARAH__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Call Sarah - drop off in fridge in test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MITCHELL_CONCOURSE_D',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'The fridge is located in concourse D next to gate 41. It is in the Leinie Lodge seating area. After filling the Departures fridge turn around and walk towards the Concourse D entrance. You will have to ask for an escort and you may be asked to be searched from time to time.\r\nThis will take an extra 10 minutes when this happens. Continue walking thru the security checkpoint and the fridge will be located about 100 yards down on the right wall.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHERN_TRUST_333_WABASH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'When driver arrives to loading dock, they should either buzz the door or call the security number to let them in. Take elevator to 37th floor. A northern trust badge and pin number will be needed to access the space.\r\n\r\nCall +1 (312) 630-6217 to have security let you in',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Tiffany Samples',
          id: 'NY_SALES_TIFFANY_SAMPLES__200_5TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'MUST BE DELIVERED BEFORE 10:30AM. THANK YOU!',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Vernon CA Warehouse 2',
          id: 'CA_VERNON_CA_WAREHOUSE_2__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Vernon warehouse delivery, please refridgerate.  I will pickup at the warehouse Thursday morning 10',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BRISCOE C STORE_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_UTD_UNION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Drive down Waterview parkway, take Franklin Jennifer west through the signs that say "Do Not Enter" take that up the hill and it will lead directly to the back of the Student Union',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COOK_CHILDRENS_VENDING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front door, go straight until the first right, turn right and vending room will be on the right next to the smoothie shop',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_BAPTIST_MED_CENTER_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through either entrance, follow the hallway to the dead middle, there will be a vending room just off the hallway, the Fridge will be there',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_LAW_NEWARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ASURION_4TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security wants us to start parking at the loading dock. It’s on the Stewart Title side (big sign on corner of building). You will go to the left to enter the building upon parking and walk straight down the hall.  The freight elevator will be to the right at the end of the hall, just before the normal elevators.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3233',
          id: 'CA_TARGET_T3233__4245_CAMPUS_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'IND_COMM_HOSP_EAST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_100_WASHINGTON_SQUARE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'When you enter the building through the loading dock you will walk through double doors and straight ahead is the freight elevator. Take the freight elevator to the 4th floor, fridge will be in the cafeteria on the left',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kirkland & Ellis',
          id: 'TX_KIRKLAND_AND_ELLIS__4550_TRAVIS_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'ALLEY IN THE BACK OF THE BUILDING. ENTER THROUGH THE BACK DOOR. Call Shelby',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName: 'The Beacon at Garvies Point',
          id: 'NY_THE_BEACON_AT_GARVIES_POINT__100_GARVIES_POINT_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'call DC',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'The Beacon at Garvies Point',
        },
        {
          opportunityName: null,
          id: '230_W_MONROE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '680_N_LAKESHORE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the north entrance doors on Huron, fridge is on the right hand side.\r\n\r\nFridge has a lock, code is 911',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_BOOTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'access the loading dock off 58th and Kimbark. You will see a blue dumpster on the right side of the street. Hit the call buzzer and security will let you in. Walk up the ramp and follow the hallway. Fridge is located in a vending room to your left.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BERKLEE_MUSIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver can use the front entrance on 160 Massachusetts Ave OR if a receiver is at the loading dock, they will bring you up to where the fridge is. If you go in the entrance of 160 Massachusetts Ave, the fridge will be placed to your left as you walk in.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ST_BARNABAS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at loading dock. Enter through door on left. Turn right through door and walk straight down the hallway till the end, you will pass through a set of double doors. Turn right at end of hallway and walk around security desk, fridge will be on the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_D38_LEFT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Loading dock at Terminal D. Walk to fridge',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_E16',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update once process is finalized.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Woodbury Warehouse',
          id: 'MN_COSTCO_WOODBURY_WAREHOUSE__7070_TAMARACK_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NJ_JEFFERSON_SURGERY_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through main doors and fridge is right their',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Head through the main entrance and then to the elevators straight ahead, head down to B level, once down to B follow signs to cafeteria, fridge will be straight ahead once you enter, next to the atm.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_JET_ZERO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Call security if they're not present at the booth - 562.581.5803\r\n\r\nCheck-in with security in the front lobby and then go up the stairs. Take a left and go to the break room where the Fridge will live",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JET_BLUE_CALL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'In loading dock, through double doors. Make a right and walk down hall. Left at Freight Elevator sign then a Quick Right to the SE2 elevator. (Go to 6th flr first to the office outside the elevator to get an escort) Go to 7th fl, turn Left at double doors make Right down hall to double glass doors. Go through and room is on the right.\r\n\r\nFOR OVERNIGHT:\r\nLoading dock is closed - enter through the front entrance with a small hand truck to avoid any issues with security during that time. Call Dexter if any issues - 347 229 7194',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "St David's Surgical Hospital",
          id: 'TX_ST_DAVIDS_SURGICAL_HOSPITAL__1201_W_LOUIS_HENNA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: 'Scripps Health',
        },
        {
          opportunityName: null,
          id: 'CA_ARE_TORREYANA_ROAD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk to entrance of EXOS Fitness and it's across from the entrance",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_DELTA_AIRLINES__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ADVENTIST_GLENDALE_WEST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Upon entering the building, take an immediate right into the double doors towards the elevators. The fridge is in the vending bank',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_SOUTHWEST_EMPLOYEES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SCHAUMBURG_TOWERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UCLA HEALTH - Ronald Reagan Medical Center',
          id: 'CA_UCLA_HEALTH_RONALD_REAGAN_MEDICAL_CENTER__757_WESTWOOD_PLAZA_ROOM_B147E',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Check in with receiving at the dock and bring it straight up to the cafeteria',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'UCLA Health',
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_A3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '71dc0437-cbe5-46a3-a405-20b835656d89',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_SKY_LOUNGE_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Park in front sign in at security desk.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Moreno Valley',
          id: 'CA_MORENO_VALLEY__16130_LASSELLE_ST_17B',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Call Kenneth Morgan upon arrival: \r\nKenneth Morgan #951-203-7397\r\nAssistant Custodial Manager',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'California Community Colleges System',
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AIRPORT_GATE_A8',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From entrance pass through security, walk to A concourse and fid fridge partway up the concourse across from Gate A8',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_REGIS_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the double doors which are a service entry. Call Alex Fernandez (781-768-8121) who will help guide you to where the Fridge is located.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Canteen Austin',
          id: 'TX_CANTEEN_AUSTIN__8801_WALL_ST_840',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call 832-525-6887',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_BAIRD_19TH_FLOOR_ROUTE4__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_HOSPITAL_OR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FIT_KAUFMAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_621__3000_JERMONE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_18',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_WEST_ROXBURY_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through building 3, take elevator to floor 1',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CMH_CONCOURSE_C',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Will get map from contact.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_HOSTOS_CC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "take freight elevator up to third floor. if they don't want to deal with loading dock each time, they can find parking but still come in through the freight entrance",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cummins',
          id: 'IN_CUMMINS_CUMMINS_TECH_CENTER__500_W_JACKSON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dock Instructions for COB delivery: 799 Brown Street, Columbus 47201 is the dock address Upon Arrival drivers must buzz security to open the door for them. They can then drive into the dock to unload. There is a normal truck dock to back up to and unload, or there is a lift system that loads can be moved to and then get up to the main floor.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_SPECTRUM_12012_MOPAC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through main entrance, go up main staircase behind security, turn left out of staircase and the cafe/lounge area will be on your left. The Fridge is in the middle of the room.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_RTX McKinney Samples',
          id: 'TX_SALES_RTX_MCKINNEY_SAMPLES__2501_WEST_UNIVERSITY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'DELIVER WITH RTX MCKINNEY',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_MASONIC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DMC_SINAI_GRACE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Main door hours: 4am-8pm\nEnter through the main door, keep right. Walk past the stairs to the elevator. Take up to level 1 (you're on ground), turn right, right again, and follow the long hallway down to the second entrance for the cafeteria.\nFridge is in the vending/seating area.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson News JFK - T4',
          id: 'NY_HUDSON_NEWS_JFK_T4__6_CENTRAL_TERMINAL_AREA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading dock Terminal 4 tell the guard we are here for Hudson.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors and get escorted to the left side of the building into de breakroom.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_SUBARU_CAFETERIA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYU_LANGONE_TISCH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_PREMIUM_OUTLETS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mass Gen',
          id: 'MA_MASS_GEN__100_BLOSSOM_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Go to the loading dock and security with escort you',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Massachusetts General Hospital',
        },
        {
          opportunityName: null,
          id: '3040_SALT_CREEK_LANE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 1',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_1__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'LOYOLA_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the front door, fridge is located there.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_NORTHWEST_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take the elevators to the second floor and the Fridge will be in the main area next to the stairs',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe WT',
          id: 'CA_QUALCOMM_CAFE_WT__5745_PACIFIC_CENTER_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_3/26 - SALES SAMPLES',
          id: 'TX)SALES_3_26__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Eddie will meet driver at Airport',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Jewel Osco - 3345',
          id: 'IL_JEWEL_OSCO_3345__1224_SOUTH_WABASH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: Deli cooler located through the bakery back doors, go up stairs on the right then make a left then a right. Cooler is 1st gray double doors on the right. DELIVERY INSTRUCTIONS:Product will be stocked in Deli cooler in marked FF Space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_MICROSOFT_CH1__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_APPLEFORD__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_SKYWATER__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'DC_Catering (comped) - Sales_Johns Hopkins Hospital',
          id: 'DC_JOHNS_HOPKINS_HOSPITAL__1800_ORLEANS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Loading Dock. Food and Culinary – Scott Poe',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_CHILDRENS_ADVOCACY_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_EddieSakSamplesAUS8.1',
          id: 'TX_EDDIE__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'CALL EDDIE 630-777-1885',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HEALTH_HUGULEY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in the building, go to the elevators, down to lower level and it will be directly off the elevators to the right',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '627_W_JACKSON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_302_W_THIRD_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the front entrance at security up to the 2nd floor tenant lounge',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '166c1768-f046-4522-8fc0-4def23092467',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M16',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            "The vendor entrance from the dock closes from 8pm till 5 am and have to go in thru passengers screening  entrance. There's a lane for employees and vendors to cut the line. Once on the concourse m5 is to the right and m16 is to the left. They go in numerical order so it won't be hard to miss. To exit there's a big sign next to were you come in through that says exit and that will leave you back to ticketing.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LAGUARDIA_CC_4TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call public safety at 718-482-5555 for access.\r\n\r\nFor loading access (if the gate is closed)  718-482-5565\r\n\r\nFridge is on the 4th floor between Room C-434 and C-436\r\n\r\nLOADING DOCK ONLY OPEN 8:30 am to 4 pm, your team needs to look for street parking if they are coming in the off hours.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_LANKENAU_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_HELEN_WHITE_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the front doors of the library, turn right down hallway and through doors, turn right again and walk into vending area. Fridge is located in vending area. Campus PD # (608) 262-2957',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1886',
          id: 'NJ_TARGET_T1886__100_14TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Naperville Warehouse',
          id: 'IL_COSTCO_NAPERVILLE_WAREHOUSE__1320_S_ROUTE_59',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please be off the sales floor by 9:30am - enter through receiving',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'WALGREENS_1435_LAKE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'After security take a left straight towards the breakroom, Fridge will be along the left wall towards the micro-market',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BNY Mellon - Jersey City Pershing',
          id: 'NY_BNY_MELLON_JERSEY_CITY_PERSHING__95_CHRISTOPHER_COLUMBUS_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'loading dock',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3270',
          id: 'IL_TARGET_T3270__1129_LAKE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Kyle McCoy 3124590053',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'GOOD_SHEPHERD_HOSPITAL_OB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to main elevator bank (from ED or main entrance) and go to the 3rd floor. Fridge will be in the waiting area on the left side against the wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Ebay Austin',
          id: 'TX_EBAY_AUSTIN__7700_W_PALMER_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Lorraine at 512-520-7743',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - NW 1201 DAVIS [Client Route - 3]',
          id: 'IL_CANTEEN_NW_1201_DAVIS__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_1245 Main St Samples',
          id: 'NJ_1245_MAIN_ST_SAMPLES__1245_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'CALL DELIVERY CONTACT',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OAKTON_CC_LEE_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main entrance veer right towards admin building and nook will be right by the door.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6e8ba5b5-c359-4931-8036-5a5ca25da420',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales Costco WA',
          id: 'IL_COSTCO_WA__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'This will e picked up by Chris',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WAYMO_GOOGLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge located in seating area of cafe, near entrance',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MDW2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front doors, check in with security, fridge located on the right hand side in employee breakroom\r\n\r\nWhen you arrive, tell security you are stocking the Farmer’s Fridge and our contact is Prashanth Reddy Nomula (650.745.5640)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MUSEUM_SCIENCE_INDUSTRY_GROUP_LUNCH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Fridge is located on the lower level down the hall from Stan's donuts",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PSEG',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_PG_FULFILLMENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once driver has their badge they should be able to go through the inbound gatehouse and can drive to the area where they are stocking. Drivers should have a strobe and a marking on their vehicle that states the company they are working for.\n\nThe guard gate will direct the driver where to go when they pull in and gain access to the site. The driver will be on a small access road in front of the building within the gate not the parking lot for delivers and initial delivery of the machine. With the initial delivery Nicole will be onsite and will walk them through the process when they call her.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_FRANCISCAN_CHILDRENS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Enter through the main entrance and check in with security letting them know you're a vendor. Head towards the vending room which is across the hall from the cafeteria.",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 103 – TE Plant',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_103_TE_PLANT__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Delivery Instructions\r\nPlease call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: 'MN_3M_HQ_BUILDING_225',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'See map attached. Michelle will also be onsite to show driver the location the day of install.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_ONETWOPRU',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_ART MUSEUM_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Citi Bank NYC',
          id: 'NY_CITI_BANK_NYC__388_GREENWICH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Driver must check in with security on the 388 loading dock side ( North Moore ) before coming to the 390 side ( Hubert Street ) & deliver to second floor',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GOOGLE_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_DENTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main entrance and turn right,  then take another right . Located in front of the cafeteria.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_UPS_FRANKLIN_PARK_MARKET__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS_KILBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevator to the fourth floor, skyway is located in the back left corner of the floor, fridge will be done the skyway towards the east',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SKOKIE_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the emergency room parking lot, take elevators down to the Vending bank next to cafeteria in basement\r\n\r\nFridge lock code is: 1321',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_AT&T Samples',
          id: 'TX_SALES_ATT_SAMPLES__1212_DALTON_DR_302',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: "please deliver with AT&T's",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_ANOKA_REGIONAL_TREATMENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Check in with security - will ask the driver for a driver's license to be left at the desk. The front desk will then provide them with a temporary badge. Front desk is staffed 24/7.\r\n\r\nWalk through front door, walk through double doors to main staircase, fridge will be located down the stairs against the wall.",
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b1c5e485-74d3-45c3-b1d4-469abefba842',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_DALLAS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Enter through the Children's hospital entrance Fridge is located in the lobby.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Mike Samples',
          id: 'DC_MIKE_SAMPLES__1503_9TH_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call Mike McFadden 847-909-5434',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_TESLA_SANTA_MONICA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Lounge Entrance Code: 5025 Then press the green checkmark',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Mount Vernon Sample',
          id: 'NY_MOUNT_VERNON_SAMPLE__70_MACQUESTEN_PARKWAY_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_AUP_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '1. If the combined Net Sales at all Fridges does not exceed $16,000 per month, UWM shall pay to Farmer’s Fridge an amount equal to the difference between $16,000 and the actual amount of Net Sales at all Fridges in the portfolio during such month (the “Combined Support Payment”).\n2. Should the combined Net Sales exceed $16,000 at all 7 Fridges, Farmer’s Fridge shall determine the amount equal to 5% (the “Commission Rate”) of Net Sales that exceeded $12,000 during such month (the “Commission”).\n3. Farmer’s Fridge will not stock the Fridge and the Support Payment, Combined Support Payment, and Commission calculations will not include official school holidays and breaks.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SUNNOVA_7TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go into the building up the elevator to the 7th floor. Call 713.894.4226',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_WASHU_SUMERS_REC_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the main entrance using swipe, go right at the fork and vending room is 20 feet ahead on your right',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEVRY_ADDISON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_CAPITAL_REGION_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the lobby walk left down the hall and take the elevator up to 2. When you exit the elevator, walk around the corner to the left.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '770_N_HALSTED',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_MEMORIALCARE_ORANGE_COAST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go to the basement floor and to the cafe. The Fridge will be there as you walk in',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_MASON_GROSS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "It's at the top of the staircase across from the lounge seating area on 3rd floor. If they need assistance Thomas Calhoun 848-799-8408 can assist",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_VERDUGO_HILLS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter at Verdugo road, not main entrance. Park at loading dock. Dial in on door on the right, or right doorbell on the door to the left. Follow hallway all the way down. Cafe is on the left. Fridge is immediately to the right of the cafe. \n\nAdditional photo added with circled road to be taken to loading dock.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3363',
          id: 'MA_TARGET_T3363__500_GENEVA_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'location opens at 7am. Park in front and enter throiugh main entrance. Stock in endcap near produce section.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_NIU Event',
          id: 'IL_NIU_EVENT__5370_S_CICERO_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please hold at Cicero! This order will be picked up by Bryan Flower on Friday 11/4.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_STATEN_ISLAND_ARTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk from the parking lot to the main entrance and walk all the way down to the opposite side entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_SOUTH_LOOP_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through revolving door, fridge will be right against the wall in the lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_SOCIAL_BEHAVIORAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Bill will arrange for someone to escort the driver on the first day of stocking\r\n\r\nFrom terminal C/D to third fridge - Keep walking down to the end turn left. Walk straight and exit security through door DT2103. Exit secure area and walk towards baggage claim D/E.  Go to elevator on left at end of walkway/bridge toward baggage claims. Turn right out of the elevators. Turn right again and head toward E baggage claim. Fridge will be by baggage carousel 7 on the left.  \r\n\r\nFrom loading dock - Have to leave product in the dock and go through security check point and go back to retrieve it. Exit dock and enter through terminal B main entrance. Go to second floor and go through B security check point. Go around security and turn right and walk down hallway. Go through black door with yellow tape on the left and take elevator down to dock level. Hold button to open and close elevator. Walk straight out of elevator. Walk down to a walk and turn right and go through door BT1018. Go back the same way you came with your product and go to the same elevator and take it to floor 2.exit black doors and turn right. Walk passed security and walk towards terminal C/D. Walked passed next security point and stay left. Continue toward terminal D. Walk passed machine at C/D link\r\n.  Keep walking down to the end turn left. Walk straight and exit security through door DT2103. Exit secure area and walk towards baggage claim D/E.  Go to elevator on left at end of walkway/bridge toward baggage claims. Turn right out of the elevators. Turn right again and head toward E baggage claim. Fridge will be by baggage carousel 7 on the left.\r\n\r\nRaven (security) - 215 669 8388\r\nSupervisor number - 215 852 1193 \r\nIf anyone gets confused or lost we can contact them or Bill for assistant finding machines. \r\nLoading dock is open from 4am-2pm\r\nDon’t use moving walkways or escalators.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CO_Catering (comped) - Sales_Hudson Colorado Springs',
          id: 'CO_HUDSON_COLORADO_SPRINGS__2420_PEAK_INNOVATION_PARKWAY_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call delivery contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 41 – Allina',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_41_ALLINA__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: 'CVS_344_HUBBARD_CHI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_5000_BIRCH_MAILROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Make your way to the west tower and to the mail room where the fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Bloomingdale Warehouse',
          id: 'IL_COSTCO_BLOOMINGDALE_WAREHOUSE__505_W_ARMY_TRAIL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park by receiving - off the sales floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CTA_95TH_STREET_RED',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in through door and see fridge on your right across from the Amazon Lockers',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONMOUTH_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge is located on Floor 1 in Seabreeze Cafe.\r\n\r\nIf arriving before 12:00 am do entrance thru main lobby (truck would pull up to not block immediate main entrance)\r\nIf arriving between 12:00am and 5:00am it would be thru the physicians entrance – driver will be buzzed in thru the gate, and security would meet the driver at the entrance\r\n\r\nMap uploaded to "Files"\r\n\r\nDirect line to security is 732-923-7777 if anyone ever needs to call directly. They\'re there 24/7',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_CSU',
          id: 'CA_CSU_LONG_BEACH_OUT_POST_GRILL_COMPED__6049_E_7TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Go to the loading dock and head to the back door.  Renee's office is past the door- call Kelly if she doesn't answer leave a voicemail and send a text",
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_WASHU_STEINBERG_HALL_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge is in the main area next to the original fridge',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Canteen MN Mystic Lakes Farmers',
          id: 'MN_CANTEEN_MN_MYSTIC_LAKES_FARMERS__700_24TH_AVE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Call Bryan Eckes at 7152079322',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SUFFOLK_UNI_MILLER_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Entering main door and then going to third floor. You'll see security (24/7) who can escort you",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MSKCC_CAFE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Monumental Markets',
          id: 'MD_MONUMENTAL_MARKETS__11800_TROLLEY_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call David upon arrival 301 477 3802',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_100_E_WISCONSIN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_LULULACARLA',
          id: 'CA_LULULACARLA__4065_Duquesne_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'CALL CARLA',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HUNTSMAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go to lobby directed in front of the building where you'll get escorted to the fridge.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_TX_DAL_TWU_MARKET',
          id: 'TX_DAL_TWU_MARKET__304_ADMINISTRATION_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Dallas VA Event',
          id: 'TX_SALES_DALLAS_VA_EVENT__4500_S_LANCASTER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Call Mark',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_AMAZON_DIL7__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ST_JOHNS_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Enter the D'Angelo Building at the loading dock door. There is a service elevator down the corridor that can be taken to the first floor.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_SPRING_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through the front doors - head past the "security desk" and take your first left, then first right, we are in the "lounge" next to the vending machines',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SLB_16740_HARDY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of the door of the building.\r\nWeekend Access: come in through the Main gate. The truck gate is closed on the weekends',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'USPS_OHARE_CAFETERIA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If coming during DAYTIME, can call John (‪312) 925-3621‬) for assistance.\r\n\r\nWalk through the doors, go straight, make a left and then a right to the end of the hall. Cafeteria/lunchroom will be on the left and the fridge will be right when you walk in on the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MI_Catering (comped) - sales HMS Host DTW Samples',
          id: 'MI_CATERING_(COMPED)_SALES_HMS_HOST_SAMPLES__ROMULUS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Deliver with HMS Host DTW',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Madison Heights Warehouse',
          id: 'MI_COSTCO_MADISON_HEIGHTS_WAREHOUSE__30550_STEPHENSON_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to the back by receiving to deliver. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Target - T3221',
          id: 'IL_TARGET_T3221__2650_N_CLARK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'You can bring in food through front door depending on how much it is. Look for Angela',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'IN_MARTINS_25__7355_HERITAGE_SQUARE_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_PHYSICS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PASSAIC_COUNTY_COMMUNITY_COLLEGE_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '24 hour access - Driver(s) needs to see Michael Grossi and have a photo ID. His office is in the Paterson Food Court',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jump Trading',
          id: 'NY_JUMP_TRADING__15_E_26TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Take the freight to 3rd floor. Ring doorbell to the left of the door or call 646-843-7402. Enter, go up the ramp, take a left at end of the corridor and take a right in the kitchen. In the glass refrigerator place items by type there.',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TEXAS_STATE_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park on talbot street or somewhere adjacent to building, walk in bottom floor (1st floor) and we are in the vending room in the middle of the building',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Swedish Hospital Earth Day Samples',
          id: 'IL_SWEDISH_HOSPITAL_EARTH_DAY__2740_W_FOSTER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Walk through front and ask for Amanda Kritt',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Stonebriar Country Club',
          id: 'TX_DAL_STONEBRIAR_COUNTRY_CLUB',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Deliveries made to delivery dock on west side of building. Purchasing manager's office is through dock doors, end of hallway to the left.",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3272',
          id: 'IL_TARGET_T3272__6418_N_SHERIDAN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Just ask for the fresh market person.\n\nJust ask for the fresh market person.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'NYC_APPLE_SOHO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BENTLEY_UNIVERSITY_COPLEY_NORTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into main entrance of Copley North, and fridge is straight ahead in green colored lounge area',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_FOXAUSTIN',
          id: 'TX_FOXAUSTIN__119_E_10TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'PLEASE DELIVER AS CLOSE TO 9:30AM AS POSSIBLE!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Dallas Cold Storage',
          id: 'TX_COLD_STORAGE__1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Please keep at Dallas cold storage',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Cantigny Golf Club',
          id: 'IL_CANTIGNY_GOLF_CLUB__27W270_MACK_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Call Don (with Anythings Possible Events) upon arrival to Cantigny Golf Course. Let him know you are here and he will meet you to accept the order. This is for the Jewel Osco annual Golf Outing.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WEST_VA_CLINIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'fridge will be located on second floor in cafe\r\nIf we are having issues accessing the location we can stop by the security desk to check in.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - Minneapolis-2',
          id: 'MN_DASHMART_MINNEAPOLIS_2__644_OLIVE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'The site can handle a truck size up to a 53" semi-truck and instructions are dock doors are located in the back parking lot. Please knock for the team to open the dock doors',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORWEGIAN_AMERICAN_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Butler University',
          id: 'IN_BUTLER__630_W_HAMPTON_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'At the rear of the building is a loading dock. The product will be brought in and checked by a Chef. There is no stocking to be done at this time.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HUGEINC_BROOKLYN_DOCK_72',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The security team will verify your freight booking and usher you to the 14th floor. From there, we can show you where the fridge should be located.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Pearland Warehouse',
          id: 'TX_COSTCO_PEARLAND_WAREHOUSE__3500_BUSINESS_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Sales Team Keep in Van',
          id: 'TX_SALES_TEAM_KEEP_IN_VAN__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Matthew',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_FRISCO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk through main door and pass the security desk towards the stairs, elevators will be to the left. Go to level 2 and walk to the Cafe's area. Vending Machine on your right across Starbucks.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FRIED_FRANK_30S_PANTRY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If delivering during day, call ext 5389 on the service elevator phone bank, or call 917.733.6234 - Melvin Whitaker to be let up.\r\nIf delivering at night, call 212.859.4731 and security will let them up',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6afa59a2-1cee-476b-91fd-42c15dd4fc2e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_NJ Hudson Corporate 3',
          id: 'NJ_HUDSON_CORPORATE_3__1_MEADOWLANDS_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'deliver to lobby at Hudson Corporate office through contact Maria, she will meet you in the lobby upon call',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_ARRIVALS_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Caerus',
          id: 'MN_CANTEEN_CAERUS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Canteen - Chamberlain College of Nursing',
          id: 'IL_CANTEEN_CHAMBERLAIN_COLLEGE_OF_NURSING__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SUNNOVA_FIREBRICK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door - the breakroom is to your right as you walk in - we are going in the room in the back with the micromarket',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c2a11e64-d8f5-4588-a2e2-d79d5d593d5e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Fulton',
          id: 'IL_FULTON__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Droping to the office',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_ONEILL_HOUSE_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge in and take freight up to 3 and walk towards the market.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD G1',
          id: 'IL_HUDSON_ORD_G1__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_F8',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update once we finalize driver parking.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_AACC_CAREER_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Call Overnight Contact (410-777-1818) for access to building. Follow the photo in 'Files' to get to the elevator, take up one floor and exit to the right, and you'll see the Fridge in seating/vending area.",
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BLACKHAWK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KEMPER_LAKES_BUILDING_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Queensborough Community College Samples',
          id: 'NY_CATERING_SALES_QUEENSBOROUGH_COMMUNITY_COLLEGE_SAMPLES__22_05_56TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Please call with estimated delivery time\r\nDriver will just have to park in front of the Administration Building and call Angela for directions.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: '14e1234d-d305-49d5-a8bb-31c7c828e6aa',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_CAPITAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_ASCENSION_ST_FRANCIS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Front entrance: walk through main entrance and fridge in in sitting area\n\nED: walk to main lobby, fridge will be in sitting area',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Sales Team Keep in Van Wednesday',
          id: 'TX_SALES_TEAM_KEEP_IN_VAN_WEDNESDAY__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Matthew',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELITE_STAFFING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOSTON_MEDICAL_CENTER_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk in entrance of 850 Harrison, and turn right, Fridge will be on left against the wall opposite of Dunkin' Donuts",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_1111__1227_NAPER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ONT_TERMINAL_2_GATE_11',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once through security, take elevators up to gate level. Fridge located across from gate 11.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_FOFEVENT',
          id: 'IL_FOFEVENT__421_W_LOCUST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call Natalie!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Procter & Gamble - Mason-Montgomery',
          id: 'OH_PROCTER_GAMBLE_MASON_MONTGOMERY__8700_MASON_MONTGOMERY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Please bring the order to the rear loading dock at the facility - make sure to drop off at the food service loading dock!',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Procter & Gamble',
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_DIGITAL_FUTURES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upong entering the main entrance take the lobby to the back wall of the lobby. The vending bank is on your righthand side behind the wall.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AT&T Cafeteria',
          id: 'TX_ATT_CAFETERIA__211_S_AKARD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Loading dock underground check in with security booth',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PEGGY_NOTEBAERT_NATURE_MUSEUM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_LAW_CAMDEN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HARRIS_COUNTY_ANIMAL_SHELTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the lobby and get escorted to the employee breakroom',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_FROEDTERT_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_UNIVERSITY_SIPA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the entrance on Amsterdam. Walk through double doors and turn left. Fridge will be on the right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2049',
          id: 'PA_HUDSON_PHL_2049__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_ROLEX',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the elevator up to the 3rd floor, get off and turn left and then the fridge will go on left wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DALLAS_VIZIENT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Through the main doors, take a right into the Vizient office, Fridge will be in the employee break room',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_SUNY_OLD_WESTBURY_CAMPUS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the Campus Center building and head to the ground floor. You will see the Fridge against one of the green pillars.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS MKE - Chilis',
          id: 'WI_HMS_MKE_CHILIS__5300_S_HOWELL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Delivery to dock and call the commissary number listed at the dock to let HMS Host know, number to call: 414-349-3245',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Latham and Watkins',
          id: 'DC_LATHAM_AND_WATKINS__555_ELEVENTH_STREET_NW_SUITE_1000',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Pull into Parking garage to the right of the building (right of starbucks). Veer left towards loading dock. Call 202 585 6000',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ONT_TERMINAL_4_GATE_402',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once through security, take elevators up to gate level. Fridge located between gates 402 and 403',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'The Aerospace Corporation',
          id: 'CA_AEROSPACE_CORP__2310_E_El_Segundo',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Pull into main parking lot. Before secruity hut, go left and park in visitor parking. Check-in at main secuity check-point. Tim Dow will meet you and escort you on premises. 8184801097',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'The Aerospace Corporation',
        },
        {
          opportunityName: null,
          id: 'PRESIDENTS_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Phone at the dock where drivers will call security to let us in',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'HENRY_FORD_MAIN_DETROIT_CAMPUS_HENRY_EXPRESS__2799_W_GRAND_BVLD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'M-Building and park in ambulance spot along the building. Call upon arrival. Enter building, complete health screening, masks must be worn. Contact will show driver how to find the Main kitchen in the basement to deliver product to walk-in cooler.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Flower Mound, TX',
          id: 'TX_FLOWER_MOUND_TX__3100_CHURCHHILL_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IKEA_DISTRIBUTION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in and check in with security. Walk through the doors and take first right to cafeteria/break room. Walk to the end of the room and the vending machine area will be in the room to the left. Fridge will be on the left wall at the end',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '250_S_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_GRVC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_HEALTH_ED_ENTRANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'To be explained in more depth later.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_GRAD CAFÉ_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GEORGE_LEIGHTON_COURTHOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Pull up to the loading dock, sign in, turn right and follow corridor around to get to the elevator bank. Hold elevator button down to get to 2. Walk out of the elevators, turn right, take first right and go through the door, fridge will be around the corner in the sitting area on the left. To leave, take passenger elevator in main elevator bank down to B, get off the elevator and turn left, turn right at the end of the hall, you will be back in the loading dock area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SPACEX_HT17',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Space X to provide instructions',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GOOD_SAMARITAN_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the front door the hospital. The Fridge is located in the lobby, outside the cafe.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LOVE_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at dock and walk to the pre-security location',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_Impact Day',
          id: 'IL_IMPACT_DAY__20_N_UPPER_WACKER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'PLEASE CALL BEATRIZ UPON ARRIVAL',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_1_DUP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2054',
          id: 'TX_HUDSON_PHL_2054__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Amazon MDW5 [Client Route - 7]',
          id: 'IL_CANTEEN_AMAZON_MDW5__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UMASS - CAMPUS CENTER',
          id: 'MA_UMASS_CAMPUS_CETNER__100_WILLIAM_T_MORRISSEY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_EXXON_MOBIL__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_CLUB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the arch. Walk through the door and take elevator up to 1, turn right and right again. Room is behind the bar',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_HERRON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into front entrance, head to the right and follow hallway around , fridge will come up on your right hand side.Fridge lock combo is 1321.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_JOHNSON_SPRING_HOUSE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ST_PETERS_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'check in with the officer at the desk. go straight down the hallway to the 1st floor cafeteria, come out of the corridor and straight through then go left to the cafeteria signs, vending bank is on the left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Owens Corning',
          id: 'MN_CANTEEN_OWENS_CORNING__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'OH_CMH_FOOD_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Will need to get map from contact.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_SATELLITE_UNIT_DEGRASTA__940_GRACE_HALL',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_UCI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the lobby and take a left. Fridge will be in the large break room\r\nOnly open Monday-Friday',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MDW4',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of building, walk through front doors, go through security, turn right down main hallway, fridge located in main breakroom\r\n\r\nWhen you arrive, tell security you are stocking the Farmer’s Fridge and our contact is Prashanth Reddy Nomula (650.745.5640)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through security to get escorted to the cafeteria area',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ARE_3115_MERRYFIELD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the main front entrance and go down the hall and the Fridge will be in the main lobby at the end of the hall',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_ARCADE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Tim at 443-538-9766 and he will escort',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_4605_DUKE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Main Lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LOVE_AIRPORT_GATE_6',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock and enter terminal. The fridge is located post-security near gate 6.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NOKIA_HQ_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Arc Boats HQ',
          id: 'CA_ARC_BOATS_HQ__2100_195TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Park out front (use hazards if needed) and buzz at main entry door. Missy will meet you at front desk. Phone # 877-272-2443',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Arc Boats',
        },
        {
          opportunityName: null,
          id: 'UNITED_AIRLINES_OFFICE_WILLIS_TOWER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'We will need to create a vendor ID# and a badge request for each of you, and will need the following information:\r\nPlease fill out the attached spreadsheet with the required information for the vendor ID#.\r\nPlease note the date format on the spreadsheet, and avoid using any special characters like “-  / ()” in date and telephone number formatting.\r\nOur UAL Work Location is WHQOU.\r\nAny fields you aren’t sure of just leave blank and I will review.\r\nWe will also need a headshot for each badge. This should be a color photo against a plain white or blue background.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_IAD_PILOT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk up / elevator up to the concourse and walk towards D3. Picture attached.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Follett',
          id: 'IL_FOLLETT__3_WESTBROOK_CORPORATE_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park in the main office parking lot and take the main entrance of building 3 up to suite 200',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_637__1872_E_TREMONT_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NEWARK_BETH_ISRAEL_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "HMS Host BWI - Farmer's Market",
          id: 'MD_HMS_HOST_BWI_FARMERS_MARKET__107_AIR_CARGO_SERVICE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            "Deliver to Bradford with instructions to be delivered to Farmer's Market HMS Host BWI",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'MN_CANTEEN_OFFICE_DO_NOT_STOCK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOAG_HOSPITAL_NEWPORT_BEACH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Park at south entrance',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_CHILDRENS_NATIONAL_HOSPITAL_7TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in at the loading dock, will need to get a temporary sticker badge from the ER department or the main entrance. The driver will need a valid driver’s license.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LOYOLA_MARYMOUNT_ADMISSIONS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge located on first level next to admissions office',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen- Amazon ORD5 [Route 18]',
          id: 'IL_CANTEEN_AMAZON_ORD5__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_WALSH_GROUP_WEXNER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'TECHS & DRIVERS MUST WEAR hard had, reflective vest, safety glasses and hand protection.  \r\n\r\nExit vehicle, unload, and bring material in to building. Must check in at front desk to read + sign waiver.\r\n\r\nFrom there, will use one of 11 elevators to bring the material to the 4th floor and then walk to breakroom.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_FEDEX_CAROL_STREAM__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_MERCY_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take Freight elevator down to LL, then ride elevator up to lobby. walk in cafeteria seating area',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MORAINE_VALLEY_POLICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir- Brighton',
          id: 'MA_GETIR_BRIGHTON__462_WASHINGTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_FROEDTERT_EMERGENCY_DEPT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the ER entrance and check in with security. The fridge is located just past the triage desk, down the hall to the left where it will be easily visible near the vending machines.\r\n\r\nSecurity phone # \t\r\n(414) 805-7070',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Northwestern Mutual MKE HQ',
          id: 'WI_NORTHWESTERN_MUTUAL__805_E_MASON_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'a) Loading dock underground\nb) pull up to the gate Mason street (825 e mason)\nc) they?ll raise the gate and let them in\nd) Sprinter Van\ne) check in with security and they?ll call Maureen',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Ingredion HQ [Client Route- 10]',
          id: 'IL_CANTEEN_INGREDION_HQ__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ace7c307-50aa-4835-b03a-a0edc054950e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3321',
          id: 'NY_TARGET_T3321__512_2ND_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_LAS_COLINAS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main entrance the fridge will be just inside the cafeteria doors.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Marketing_VOLO',
          id: 'MA_VOLO_SPORTS__142_BERKELEY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please Call Eric',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TRINITY_TOWERS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the doors, take a left, Fridge will be on the left wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Fulton Market',
          id: 'IL_PURE_GREEN_FULTON_MARKET__936_W_FULTON_MARKET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to store crew',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_BARNES_JEWISH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through main lobby entrance. Walk to the left towards the cafe. Take a left after the restrooms and walk through the "exit"',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_DELTA_INFLIGHT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Same loading dock process as other JFK fridges. Once you pick up the food take elevator from loading dock to the third floor. Exit and turn left and exit the door on the left. Turn left and walk down the B concourse. Near gate 36 there is an elevator on the right side, swipe your badge and go to the second floor. Exit and turn right, walk passed the computers and walk through door way. Fridge will be on your left side.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOSTON_HARVARD_MEDSCHOOL_ATRIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into 240 Longwood building and Atrium Cafe is right there',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_Barstool',
          id: 'NY_BARSTOOL__333_SEVENTH_AVE_2ND_FLOOR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Alex upon arrival!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Canteen Office - Austin',
          id: 'TX_CANTEEN_OFFICE_AUSTIN__8801_WALL_ST_UNIT_840',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Austin Piske - 832-525-6887',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS MKE - Lienies',
          id: 'WI_HMS_MKE_LIENIES__5300_S_HOWELL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Delivery to dock and call the commissary number listed at the dock to let HMS Host know, number to call: 414-349-3245',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_ALLIANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors to the elevator, take elevator to the second floor, Fridge will be straight down the hallway outside of the cafeteria doors',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PAUL_ROBESON_CAMPUS_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AMAZONGO_ICH2_MERCHANDISEMART',
          id: 'IL_AMAZON_GO_ICH2__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'pickup at cicero',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: 'St. Louis Lambert International Airport',
          id: 'MO_ST_LOUIS_LAMBERT_INTERNATIONAL_AIRPORT__10701_LAMBERT_INTERNATIONAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Park car in the dock area, roll items down through security. Security guard will inspect. Driver needs to call Kajal (314-280-5225)or Johnny (314-562-2111) 30 min before arrival to get access to dock. Use badge to deliver to actual area in warehouse',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_K2',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Enter through delivery/package lane, Post 7',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_APPLE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front entrance, the cafe is to the right',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kettering Health Dayton',
          id: 'OH_KETTERING_HEALTH_DAYTON__405_W_GRAND',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Use delivery dock of hospital.  Located around the back of the facility. Deliver to kitchen or cafeteria',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon Irvine',
          id: 'CA_AMAZON_IRVINE__40_PACIFICA_STE_100',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Manager will meet at the loading dock',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_9TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, fridge located in vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CRIMINAL_JUSTICE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "walk through the MAIN entrance, there is a TSA style security check in you have to go through. It's quick. The fridge is directly to the right after you get through.",
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_DENTISTRY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver will need to park at the west side loading dock walk up the ramp go through the door walk pass the temp Che k point fridge will be on the right',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ARE_CAMPUS_POINT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside of the building, and turn right down the hallway. Fridge is located on the right near Amazon fulfillment center and UCSD lab. Common doors are unlocked Monday - Friday 6am - 7pm.  Call Izear Williams (619-952-8732) if issues arise.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_GM_COLE_ENGINEERING_7TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the fire lane next to the other vans/box trucks. Adam will escort you inside via the steel door to the right of the main door. You will then also be escorted back out after stocking.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'MI_MARTINS_23__5637_CLEVELAND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GOOD_SHEPHERD_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Emergency room entrance, walk to elevator and take it down to lower level. The Fridge is in vending machine area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Hudson MDW Sample Pack',
          id: 'IL_CATERING_(COMPED)_-_SALES_HUDSON_MDW_SAMPLE_PACK__5700_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Dropping to Hudson MDW',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LAX_AIRPORT_MSC_GATE_205',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'n/a Delaware North is stocking the fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ROBERTET_PISCATAWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'MUST SIGN IN FIRST ON IPAD. CHOOSE FRANK, LOUIS, OR PAUL. Then a security escort will bring you to the room. \r\n\r\nCall/text Frank Czerwinski (201-714-1142)\r\n\r\nBackups for Frank are:\r\nLouis Zwaan (908) 229-3101\r\nPaul Edworthy (610) 350-9710',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FLIXBUS_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once emergency parked in front of the building, fridge is immediately in the lobby to the right.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ROUDEBUSH_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park up front of the building the walk down the hallway, pass the elevators/pharmacy Fallowing the Wall sign with arrows to the “Patriot Cafe”',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_BUSINESS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_WAUKESHA_20935_SWENSON_DRIVE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter in through the front entrance lobby and bring to the ridge in the lobby lounge',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security, take a left, breakroom will be straight ahead of you',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3317',
          id: 'IL_TARGET_T3317__2434_N_SACRAMENTO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Entrance to the dock is around the back of the store\n\nAndrea 3126222573',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'IND_AIRPORT_A14',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Food will be checked by the dock master and delivered to the fridge. Driver will need to go through security and pick up food post-security.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3340__599_E_ROOSEVELT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ICW_15030_INNOVATION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use a badge, along with entering a PIN number into their keypad "13345" to gain entry.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_479__3118_N_HARLEM_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Polaris RX',
          id: 'MN_CANTEEN_POLARIS_RX__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_EWR9_MAIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, walk short distance to main break room open area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MCLAREN_MACOMB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Location: Located in the Surgery Center lobby.\nDriver Entrance & Parking: Surgery Center door.\nDelivery Hours: 5am to 8pm - if before/after hours, enter through ER, it is a short walk.\nPark to fridge instructions - Enter through Surgery Center door. Fridge will be straight ahead to your right. Map Pin:\nhttps://goo.gl/maps/FoiHHT5Jo2r1SoZF7',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '123_N_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_HESS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in front door, check in with security fridge is located in lobby to the left up ramp next to starbucks/coffee shop',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ab47bdfd-9fcc-46ca-af7e-26f85b48a1e3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LURIE_CHILDRENS_OUTPATIENT_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Ames New York Sample Order',
          id: 'NY_Ames_New_York_Sample_Order__138_BOWERY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            "I'm staying at Airbnb - Please call Ames when you arrive",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STIFEL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3454',
          id: 'IL_JEWEL_OSCO_3454__4250_N_LINCOLN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: In deli cooler located in receiving. Cooler is on the right when coming through dock doors. OTHER DELIVERY INSTRUCTIONS: Product to be stocked in Deli section in FF marked space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'MKE_UW_SEWELL_SOCIAL_SCIENCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once in the building there is a door to the right side that will exit into a hallway and the machines are located right there in front of you as you enter the hallway',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_NATATORIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the entrance and find the main staircase in the main lobby, the vending area is right at the bottom of the staircase',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_OLD_BROOKLYN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 6:30AM to 7:30PM\nDriver Entrance - Front Entrance is located off Devonshire Road, directly by shuttle pick-up/drop-off semi-circle\nDriver Parking - Either use parking spot on sidewalk in front of security or, if unavailable, use street parking on Pearl Road\nPark To Fridge Instructions - Once inside the front entrance, go past the information desk and right at first hallway to the elevator bay, use double set of elevators to go the second floor, left off the elevator, fridge is located against the right wall of lobby, past the information desk',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1_S_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BRONX_CC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'for stocking come in through the front entrance and it will be right there at the vending bank',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'USPS_CAROL_STREAM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the main front entrance ramp into the lobby and get a temporary badge from security (need to provide your drivers license). Enter in through the main security gates and take the front hallway to your left where you'll find the main cafeteria.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_SPH_JAVA_BLUE__200_OBSERVATORY_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIVERSITY_OF_CHICAGO_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the loading dock to the lower level library seating area. The fridge is along the bank of widows near the other vending',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_4013__1200_W_BOUGHTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNITED_AIRLINES_ARLINGTON_HEIGHTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter in the main doors and see security as the desk, announce your’e with FF. Once accessed through by security, take a left up the ramp to the kitchen area breakroom. The fridge is along the vending wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_PACIFIC_COLLEGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_UC Riverside Student Recreation Center',
          id: 'CA_UC_RIVERSIDE_REC_CENTER__900_UNIVERSITY_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver to Lindy Fenix at the Student Recreation Center \nPhone: (951) 827-4461',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_VA_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front doors, take main hallway until you see signs for cafe, turn right for cafe, vending area with fridge is next to the cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LAKE_COUNTY_GOV_WAUKEGAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front doors security will walk you to the cafeteria which is down the hallway to the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_Influencer Event',
          id: 'CA_INFLUENCER_EVENT__2135_HERCULES_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Kerry-Anne 604 902 7733',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emerson College - Paramount Cafe',
          id: 'MA_EMERSON_COLLEGE_PARAMOUNT_CAFE__555_WASHINGTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'call george',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMERICAN_METRO_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Open double doors, make a left into building. Open 2nd set of double doors and your in cafe.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'COYOTE_LOGISTICS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kirkland Ellis Austin',
          id: 'TX_KIRKLAND_ELLIS_AUSTIN__1501_RED_RIVER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Park on Red River and knock on door. If delivered after 7:30am can go through main entrance. Must be delivered before 10:30am as the client is doing to drop off at dif location',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName: null,
          id: 'CA_LA_AIRFORCE_BASE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt to follow up',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WALGREENS_4010_COMMERCIAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ASTELLAS_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the dock doors and straight to Waterview Market in the South Building',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_HEIGHTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go thru loading dock, follow signs for the cafeteria, the fridge is outside the cafe next to a snack vending machine',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'White Plains Hospital',
          id: 'NY_WHITE_PLAINS_HOSPITAL__41_EAST_POST_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Go through loading dock in between E Post Rd and Maple Ave. Get screened in by security officers. Ask to be escorted to the kitchen',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JOHNSON_AND_JOHNSON_930',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'sign in with security, go down short hallway to break room',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 58',
          id: 'IL_JEWEL_OSCO_58__2855_W_95_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'WI_Catering (comped) - Sales_Northshore Country Club',
          id: 'WI_NORTHSHORE_COUNTRY_CLUB__3100_WEST_COUNTRY_CLUB_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Pick up key and they will show you to the cooler',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon IAD-28',
          id: 'VA_AMAZON_IAD_28__13200_WOODLAND_PARK_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Pull up to the loading dock area- call chef Yossef so he can escort them and will give a temporary badge. Must calll Yossef every time!',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: 'Hyvee #1462',
          id: 'MN_HYVEE_1462__7180_10TH_STREET_NORTH',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DUBLIN_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_MEDLINE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_ST_ANNS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Front Entance - Take signs to cafeteria',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Mount Carmel East Sampling Event',
          id: 'OH_MOUNT_CARMEL_EAST_HOSPITAL__6001_E_BROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Enter the front entrance; Brandyn Bernard 740-963-2803',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ONE_LINCOLN_CENTRE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '09a273a8-fc60-4031-9eb9-006dd52437ac',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NEW_SCHOOL_FRIDGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_VETERINARY_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go in through hospital to freight elevator and up to the 2nd floor. From second floor you go to lobby which is accessible from same elevator. \r\n\r\nPass the lockers. The hallway will lead to a large opening seating area. There is a window wall next to the seating area. There is a vending bank alcove where the machine is placed',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SLB_7030_ARDMORE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park at visitor parking. Go through glass doors take a left on the hallway and a quick right in the breakroom. It's 10-15 steps from the lobby floor.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EVANSTON_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through emergency room, take elevators down to the basement, follow signs for the vending bank.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_EMU_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'See map attached',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMITA_SAINT_MARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_BETHESDA_DINING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take service elevator to G floor, dining room is near there.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'The Bridge Cafe - AU',
          id: 'DC_THE_BRIDGE_CAFE_AU__4400_MASSACHUSETTS_AVE_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Walk in the door and onto the elevator, push "2" (not M2). Cafe is right off the elevator',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Circle K Corporate',
          id: 'IL_CATERING_(COMPED)_SALES_CIRCLE_K_CORPORATE__550_WARRENVILLE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Bring Food to 4th floor to find Circle K offices and drop to Tony Lemasters',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB3_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter at terminal B, Walk to gate B3 and find machine across from Best Buy machine',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_VA_607',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter in loading dock basement area and take the elevator up the the main floor vending alcove. It is directly off the cafeteria',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'MI_MOJO_BLUE_MARKET_AND_CAFE__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Peloton Studios',
          id: 'NY_PELOTON_STUDIOS__450_W_33RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'please call Ivan upon arrival',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MAIMONIDES_MAIN_DOUBLE_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the main lobby. The fridge will be just past security in the main lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '33d67b87-1370-4752-b098-fa1935497d68',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'MI_MARTINS_19__720_S_11TH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OHM Wholesale',
          id: 'MA_BOSTON_LOGAN_INTERNATIONAL_AIRPORT__290_WILLIAM_F_MCCLELLAN_HIGHWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Will update after conversation with Bradford',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYIT_MANHATTAN_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Head straight to the back fridge will be on the right of the back room by the windows, there is a shallow set of stairs. There is a loading dock but they store garbage on the platform. Could install via that if needed. Canteen just used the front entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'RUSH Catering',
          id: 'IL_RUSH_CATERING_2__1700_W_VAN_BUREN_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW6_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the elevator to the third floor, take a left out of the elevator, breakroom will be right there',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_IAD_FLIGHT_ATTENDANTS_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge in and take elevator down to the lounge. Badge in and fridge is in breakroom',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_LUDDY_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_CORPORATE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'park at loading dock and take the freight elevator to the 11th floor. Walk to the vending area on north east side of the building near the lunch seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "DOM'S Kitchen and Market",
          id: 'IL_DOMS__2730_N_HALSTED_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'go on the Diversy side of the building and there is a gate. A receiver will be there',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2001_BUTTERFIELD_ROAD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Loading dock -> to freight elevator (signs provided), take that to level 1* -> take a left out of the double doors, then a right, then the room will be directly on your right. \r\n\r\nCard access  provides entry to both dock man doors, service elevator to first floor and the lounge itself only.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Fort Worth-Alliance, TX',
          id: 'TX_LIFETIME_FORTWORTH_ALLLIANCE_TX__10761_FOUNDERS_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1853',
          id: 'PA_HUDSON_PHL_1853__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Hudson PHL 1851',
          id: 'TX_HUDSON_PHL_1851__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Not available',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_GEICO_RICHARDSON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, straight down the hallway, take a right into the cafeteria, Fridge will be just at the start of the InReach micro-market; for 24 hour access, check in with security at the desk at the South entrance (closest to the loading dock)',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_BAPTIST_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, Fridge will be a feet in door next to the starbucks',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2_N_LASALLE_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with the guard, they will give an access card to get up the freight elevator. Take elevator up to 2nd floor. Fridge will be in the tenant lounge to the left when getting off elevator',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Sales_Getir Samples',
          id: 'IL_GETIR__625_W_ADAMS_ST_19TH_FLOOR_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Kristin when you arrive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_PELOTON_PLANO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Drivers will have to "buzz in" and wait for receptionist. If no one is at the desk, they can call Andrea Reyna 512-297-4854\r\n\r\nCheck in at security desk.\r\n\r\nFridge will be straight through the main doors, against the back left wall of the kitchen',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson 832',
          id: 'IL_HUDSON_832__500_WEST_MADISON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "loading dock is on the corner of Washington and Canal. The Truck Clearance is 12'-3' Bays 2-5, 12'-7' Bays 6-7, and 13'-4' Bay 8. Drop off location is on the 1st. level, Hudson Bookseller.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_REHAB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the main entrance, first door on the right is the cafe, Fridge will be straight across next to the other vending unit',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '320_W_OHIO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_VA_JOHN_COCHRAN_587',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come through ER and had towards the Lobby. Fridge is placed by the Starbucks.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_UNION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address - 49 Abbot Rd East Lansing MI 48824\nCustomer Friendly Location - MSU - Union. right across from the New Coffee Location- next to the ATM\nDelivery Hours - 7:00AM to 5:00PM\nDriver Entrance - Front Right Entrance\nDriver Parking - Front Entrance\nPark to Fridge Instructions - Take ramp to right of main entrance. Once through the doors there is an elevator on your right. Take to 1st floor. Machine is immediately on the left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Bolingbrook Warehouse',
          id: 'IL_COSTCO_BOLINGBROOK_WAREHOUSE__830_E_BOUGHTON_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park by receiving - off the sales floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCU_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver must have been added to the access form.\r\n\r\nWalk across the street, the library will be on the left, use the main doors, take a left, and the Fridge will be against the wall. It will be the opposite side of the cafe\r\n\r\nTo gain access, go to the central control center, located at 3589 Bellaire Dr N, Fort Worth, TX 76109, and check out a card each time you are on campus.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c475cb54-e2c1-449e-bb05-f2c2fdfbf7d0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'American University - East Campus',
          id: 'DC_AMERICAN_UNIVERSITY_EAST_CAMPUS__3501_NEBRASKA_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Kurt to Follow Up',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Willowbrook Warehouse',
          id: 'TX_COSTCO_WILLOWBROOK_WAREHOUSE__12405_N_GESSNER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CA_LA_LOMA_LINDA_VA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'park in front',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_APPLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk left past the front desk, take a right and walk past the elevator, take another right, fridge located in kitchenette area on the left',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_SHADYSIDE_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'CC to update',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '200_W_ADAMS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '640_N_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T3326__5200_Kings_HWY_STE_A',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SHARP_TECH_WAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main doors and go up the stairs. Take a right at the top and enter that door. Walk straight and to the back of the room where the break room will be',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_METHODIST_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'One in the main entrance - take the lobby towards your right.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_AMAZON_ORD4__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_METROPOLITAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Location is in Women’s center walk through doors take immediate right then immediately left fridge on your right in tight area.',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Cold Storage Diego Samples 3',
          id: 'TX_DIEGO_SAMPLES_3__12510_W_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Leave at the cold storage for Diego.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen - Delnor Smart Market',
          id: 'IL_CANTEEN_DELNOR_SMART_MARKET__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL DC',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_Philadelphia Police academy',
          id: 'PA_PHILADELPHIA_POLICE_ACADEMY__2838_WOODHAVEN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Walk in main entrance and front desk know that the order is for Nick Tees',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_IKEA__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MCW_HUB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDX6',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'turn right when you get in, go striaght back, turn left and the Fridge will go on the wall to the right',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Bunker Hill Warehouse',
          id: 'TX_COSTCO_BUNKER_HILL_WAREHOUSE__1150_BUNKER_HILL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'HENRY_FORD_WYNADOTTE_HOSPITAL__2333_BIDDLE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'push doorbell and call delivery contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_GRANITE_2925_BRIARPARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'In the lobby',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson Pittsburgh',
          id: 'PA_HUDSON_PITTSBURGH__1000_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Deliver to Bradford with instructions to be sent to Hudson Group',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_MACOMB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon entering into the facility take the main entrance Located in the 1st floor medical center hallway directly off the main entrance. The fridge is located along the front facing wall of the hallway slightly to the right after entering into the main front doors.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST shipping location 3',
          id: 'IL_TEST__TEST_AVENUE',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please call delivery contact when you arrive',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f9b4c87f-d034-4e93-b0ff-f6e286309b5e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cummins',
          id: 'IN_CUMMINS_SEYMOUR_ENGINE_PLANT__500_W_JACKSON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dock Instructions for COB delivery: 799 Brown Street, Columbus 47201 is the dock address Upon Arrival drivers must buzz security to open the door for them. They can then drive into the dock to unload. There is a normal truck dock to back up to and unload, or there is a lift system that loads can be moved to and then get up to the main floor.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - DELTA CVG',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_DELTA_CVG__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'DOMINICAN_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'LUX Cafe',
          id: 'MI_LUX_CAFE__4065_W_MAPLE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Please take the entrance to the Lux cafe. The Cafe is the left when you enter the building, drop off in the cafe BACK fridge.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_10000_INNOVATION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_PAVILLION_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through glass doors and the location will be 15 steps in.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '600_W_MADISON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Grace Medical Center Baltimore',
          id: 'MD_GRACE_MEDICAL_CENTER_BALTIMORE__2000_W_BALTIMORE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park in Loading Dock in Back',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEST_CARROT_CLOSET',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_SAMS_CLUB__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FILM_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'park in lot b',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JSX_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the building, turn left and the spot will be on the left next to the counter',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_UNIVERSITY_STUDEBAKER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through 131st street entrance. Check in with security, take elevator to 6th floor. Our beautiful machine will be right in front of you when you exit the elevator.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Whoop HQ - Wholesale',
          id: 'MA_WHOOP_HQ_WHOLESALE__545_COMMONWEALTH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Call Steph Walsh at 617-710-1021 upon arrival',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Whoop',
        },
        {
          opportunityName:
            'IL_Catering (comped) - Marketing_Food Justice Summit',
          id: 'IL_FOOD_JUSTICE_SUMMIT__750_S_HALSTED_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Stef',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Niles Warehouse',
          id: 'IL_COSTCO_NILES_WAREHOUSE__7311_N_MELVINA_AVE7311_N_MELVINA_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please be off the sales floor by 9:30am - enter through receiving',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_HMS AUS Samples',
          id: 'TX_AUS_CATERING_(COMPED)_SALES_HMS_AUS_SAMPLES__3600_PRESEDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Deliver to Bradford, instructions to be delivered to HMS as a sample pack',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'DashMart - MAN4',
          id: 'NY_DASHMART__21_ASTOR_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_ACI',
          id: 'CA_ACI__300_E_OCEAN_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Our Fridge is at booth # 731 at the ACI conference - please stock the fridge!',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMTRAK_UNION_STATION_GREAT_HALL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter right hand elevator and ride to level "C". Walk from concourse area where the elevator arrives out towards the great hall. Fridge will be directly in front of you upon exiting the Concourse.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ROOSEVELT_UNI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Brooklyn-7',
          id: 'NY_DASHMART__74_N_4TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - VSC: Veterinary Specialty Center',
          id: 'IL_CANTEEN_VSC_VETERINARY_SPECIALTY_CENTER__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_SEAGATE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELMHURST_FRICK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IND',
          id: 'IN_INDY_HUDSON_IND__7800_COL_H_WEIR_COOK_MEMORIAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Dropping entire order to Hudson News store at gate A20. Product will be distributed by Hudson from there.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Costco - Columbus Warehouse',
          id: 'OH_COSTCO_COLUMBUS_WAREHOUSE__1500_GEMINI',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to the back by receiving, be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'MI_GOPUFF_164__835_HOLDEN_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Canton Warehouse',
          id: 'OH_COSTCO_CANTON_WAREHOUSE__4736_DRESSLER ROAD_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Drive to the back near receiving to deliver',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Soin Medical Center',
          id: 'OH_SOIN_MEDICAL_CENTER__3535_PENTAGON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Use delivery dock of hospital.  Located around the back of the facility. Deliver to kitchen or ground floor cafe',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HOFSTRA_UNIVERSITY_HAGEDORN_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge is visible from main entrance through glass double doors. Just walk straight down.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHEVRON_HQ_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park outside of back entrance, check in with security, head upstairs to the cafeteria,',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - MetroWest-Boston, MA',
          id: 'MA_LIFETIME_METROWEST_BOSTON_MA__490_OLD_CONNETICUT_PATH',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_CCD_8TH_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DEPAUL_CENTER_LOOP_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver can enter the DePaul Center and gain access to the 11th floor by using one of the below options:\n\n1. Access the DePaul Center using the State or Van Buren street entrance. Access the elevators located across from the Public Safety desk and take to the 11th floor. Once there, go to the information desk for further assistance.\n\n2. Access through the Lewis building by taking the elevators to the 14th floor and using the sky bridge. Once on the 14th floor they should exit to the right and follow the hallway leading to the sky bridge.\n\n3. Driver can also call Mike Pecora’s cell phone, 312-481-5332, between 7am-3pm for assistance on their first time here on campus and Mike will show them the route best to take.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__3052_N_LINCOLN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Fulton 2',
          id: 'IL_FULTON_2__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Bring to Test Kitchen fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - GE North Pointe',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_GE_NORTH_POINTE__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Babson College Olin Hall',
          id: 'MA_BABSON_COLLEGE_OLIN_HALL__4_BABSON_COLLEGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Call John Pagano 517-304-5016 when onsite',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_StLukesevent',
          id: 'IL_SARAH_STLUKES__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please put in back Fridge by test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_VALERO_ENERGY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the loading entrance, take a right down the hallway, Fridge will be in the breakroom which is the first room on the right',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_GATE_C17_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn left and walk to C Gates, walk to the end of concourse and find fridges on the right.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Brookfield, WI',
          id: 'WI_LIFETIME_BROOKFIELD_WI__17585_GOLD_PARKWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Canteen Event Dallas',
          id: 'IL_CANTEEN_EVENT_DALLAS__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'LEAVE AT CICERO',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Stony Brook University Hospital',
          id: 'NY_STONY_BROOK_UNIVERSITY_HOSPITAL__101_NICOLLS_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'please call dc and deliver through the main entrance on level 5 at Stony Brook Hospital and drop product in walk in fridge. ALL STONY BROOK WHOLESALE DELIVERIES are to be dropped off at Stony Brook Hospital',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Stony Brook Medicine',
        },
        {
          opportunityName: null,
          id: 'NYC_ZOCDOC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the freight entrance - badging only works at the entrance. Will have to be escorted.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BCH_LOBBY_SINGLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver can contact Bill Toth when they arrive (617-308-7984).',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_CYFAIR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of Conference Center in the roundabout area, walk into the doors and into the main student lounge that contains the cafeteria on the right.  The fridge is in the vending bank.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - BKN5',
          id: 'NY_DASHMART_BKN5__82_SUYDAM_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UT_MD_ANDERSON_DUNCAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'walk into',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emerson College - Max Cafe',
          id: 'MA_EMERSON_COLLEGE_MAX_CAFE__150_BOLYSTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'CALL GEORGE',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Emerson College',
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN PRESTIGE PLACE',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_PRESTIGE_PLACE__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SWIFT_TRANSPO_JURUPA_VALLEY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main door and you will see the fridge in the lobby right away',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales Atkore Sample',
          id: 'IL_ATKORE_SAMPLES__16100_SOUTH_LATHROP_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call the delivery contact upon arrival',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T3230__7000_AUSTIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T5_BAGGAGE_LEFT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_SOUTH_OAKS_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'They can go right down the stairs or around the corner to access the elevator, can be a little confusing for different drivers.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Carfax HQ',
          id: 'DC_CATERING_(COMPED)_SALES_CARFAX_HQ__5860_TRINITY_PARKWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in any unmarked space. Call 571-307-5556 when arriving.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '0ef9434c-6e9a-4939-8293-d25d0707b81c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENS_MUSEUM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_LAW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Pull onto Gilmour Mall off of Cass Ave and drive up to Law Library Building. Walk through doors on the left and turn right. Walk straight past library and past seating area on the right. Turn left and you will be in vending area.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LAKEFOREST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_Hamilton Police Division',
          id: 'NJ_HAMILTON_POLICE_DIVISION__1270_WHITEHORSEMERCERVILLE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Steven (609) 581-4017',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 84 – Winthrop',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_84_WINTHROP__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_38__2408_W_JARVIS_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Hudson JFK T8 Sample pack',
          id: 'NY_CATERING_(COMPED)_SALES_HUDSON_JFK_T8_SAMPLE_PACK__BUILDING_56_LOADING_DOCK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Deliver to the loading dock at T8',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3368',
          id: 'MA_TARGET_T3368__100_CAMBRIDGE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Product in the front of store near the produce section.  9am receiving start time enter through front of store.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RADIO_FLYER_HQ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Pull in through the gate go right pass the wagon pull into the park door will be on the left. Security # 773-965-7041',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_CONCOURSE_C',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'n/a',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_TRINITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'You can pull directly in the parking lot in front of the emergency entrance. You will get a wristband from security and walk through the double doors . Take an immediate left and go to the end of the hall. Take another left then immediate right . The “ light on the hill” cafe is about 15 feet down on the right hand side. Once you enter the fridge is directly in front of you.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Graco Dayton',
          id: 'MN_CANTEEN_GRACO_DAYTON__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Costco - S Indianapolis Warehouse',
          id: 'IN_COSTCO_S_INDIANAPOLIS_WAREHOUSE__4628_E_COUNTY_LINE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MA_BIDMC_EAST_CAMPUS_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock, walk through the kitchen to elevators and take to 2nd floor',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_EMERGENCY_DEPARTMENT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Check in with security in the adult emergency room. They will guide you to the door directly north of the ER (it's locked so they will have to let you in). Once in the door, take the elevator up to 2, walk straight through the door and the fridge will be directly ahead against the wall.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_KEAN_UNIVERSITY_EAST_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in front entrance, cafe is right in front',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ARE_ENCLAVE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk up the steps and into the lounge/cafeteria area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_CACI',
          id: 'VA_CACI__12021_SUNSET_HILLS_ROAD_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Sylvie upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_EAST_QUAD_BLUE_CAFE__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the loading dock to the third floor, the Fridge will be in the breakroom/cafe area.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_MCNUTT C STORE_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_PAOLI_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KIRKLAND_ELLIS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park down below at the dock, go through the doors to the building to the elevator doors, go up to 22nd floor, and find the micromarket.\r\n\r\nOnce your driver arrives, security will open the dock gate to let the truck in. At that time Security will proceed downstairs to sign the driver in and issue a temporary key card. If the gate isn’t lifting upon his or her arrival, please have your driver ring the intercom button located at the single entrance door. As always, feel free to let us know if there are any issues accessing the dock.\r\n\r\nLoading dock instructions attached under files',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_QUEENS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through main door and fridge is within sight',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RIVER_NORTH_POINTE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'bc607a4a-150e-407d-9c41-b99a41d1b2a3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Fulton Office for Bill',
          id: 'IL_FULTON_OFFICE_FOR_BILL__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please leave in the chef kitchen cooler!',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_US_DEPT_OF_COMMERCE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator up to 5th floor, exit to right and market is right there',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_BOULDERING_PROJECT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into building via the ramp. Location will be in the front retail shop right when you walk in.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAL_POLY_CC_MARKETPLACE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter door code # -- (Will update this prior to start of semester Fall 2024).\r\n\r\nMake your way to the Campus Center Marketplace and the Fridge ill be in the common area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_32 BJ Funds',
          id: 'NY_31_BJ_FUNDS__25_W_18TH_STREET_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please let lobby security call Michaela (ext. 2811) or Elizabeth (extension. 3011).',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Vendrite Samples',
          id: 'NY_VENDRITE_SAMPLES__70_S_MACQUESTERN_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Zach - 631-371-7584',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_B25_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'N/A',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CUYAHOGA_CC_METRO_CAMPUS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions:
            'After unloading at the dock use the doors into the "Alex B. Johnson Campus Center", location will be to the right next to the Soda and Coffee station',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_MCKINNEY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take a right to the gift shop, Fridge will be against the back glass wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 5 Amazon DSD5',
          id: 'CA_CANTEEN_VISTA_RT_5_AMAZON_DSD5__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - N Fort Worth Warehouse',
          id: 'TX_COSTCO_N_FORT_WORTH_WAREHOUSE__8900_TEHAMA_RIDGE_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CROWN_CASTLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'get in freight elevator, go to floor 6 we are',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TN_Catering (comped) - Sales_Hudson BNA Sample Pack',
          id: 'TN_CATERING_(COMPED)_SALES_HUDSON_BNA_SAMPLE_PACK__1_TERMINAL_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Deliver through Third party to Bradford at Nashville International Airport',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee Wholesale - Rt 7',
          id: 'WI_CANTEEN_MILWAUKEE_WHOLESALE_ROUTE_7__5400_S_WESTRIDGE_DR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'CA_LA_GENERAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once parked take the loading dock entrance into the basement hallway and go through the lower level kitchen. Take that towards the elevator in the main hallway. Take elevator to level 2. Once on 2 (main floor level) make your way directly around the corner to the cafeteria where the fridge is in the alcove along the wall of the seating area near the cash registers',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_PEARLAND_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "We will already be stocking another fridge in the ER, so maybe park there and walk to the doctor's lounge which is on the other side of the hospital",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_130_E_WASHINGTON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CNA_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped)-Sales_LA Costco Samples',
          id: 'CA_LA_COSTCO_SAMPLES__11000_GARDEN_GROVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Deliver top front desk for Deli Buyer Jonathan Galvan Deli Dept-19',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '05a27662-2fc5-470a-b8ce-d27b1de93870',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AVI_TRIAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in employee cafe',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_STATE_UNIVERSITY_COOKADM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '108_N_STATE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the loading dock at Washington and Dearborn, the loading dock is located on Dearborn. The entrance is next to the public parking garage. Park at the farthest wall, walk down the hallway, open the door once it becomes a dead end. Enter through the doors on the right. The fridge will be located right when you walk in. Security- 312-261-4730',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_FORT_WORTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From loading dock; Walk through double doors and Make a right then walk to the end of the hallway through the double doors and make a left. Follow signs to Cafe. Fridge will be right before entering Cafe.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BFA WHOLESALE',
          id: 'OH_BFA_WHOLESALE__6033_PERIMETER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'call delivery contact',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_KARE',
          id: 'IL_KARE11__8811_OLSON_MEMORIAL_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'PLEASE CALL JUSTINE',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Brooklyn-1',
          id: 'NY_DASHMART__500_DEKALB_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lindenwood University',
          id: 'MO_LINDENWOOD_UNIVERSITY__209_SOUTH_KINGSHIGHWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Spellman Center- loading dock- door is unlocked- bring it up to the second floor elevator by dock. opens up to the kitchen- put in fridge)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_RMSC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 1',
          id: 'IL_JEWEL_OSCO_1__1340_PATRIOT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: Deli cooler in stockroom. Speak with Janice, before storing products   OTHER DELIVERY INSTRUCTIONS:  Janice is receiver, Liz is deli manager.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCU_BASS_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the building, take a left down the lobby, and the Fridge will be located on the wall going down the hallway',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_ARE_MOREHOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside and Fridge is located behind main check in desk',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St. Elizabeth Hospitals',
          id: 'KY_ST_ELIZABETH_FT_THOMAS__85_N_GRAND_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_AIRPORT_B16',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Loading dock to the main level follow signs to b16',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BMCC_FITERMAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in through main entrance there, drivers just need to show their ID to security, contact cheryl if needed 917-838-1073',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - MAN10',
          id: 'NY_DASHMART_MAN10__2101_FREDERICK_DOUGLAS_BOULEVARD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '911_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of building. Fridge is located on 1st floor in cafeteria.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRESENIUS_KABI_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '**Will need to check in with security to get a badge each time** Walk through glass doors, then walk through double doors on the left into the cafeteria. Fridge will be on the right side in the main room. ***Please note the doors are airlocked when entering the building, so the doors from the entry need to be fully closed before opening the doors to the building**',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Tucker Samples',
          id: 'CA_TUCKER_SAMPLES__1051_LAW_ST_UNIT_2',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Please leave box at front door. No need to contact Tucker!',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7eda6c00-7c27-4329-bf91-0bd51f5169b4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_CANTEENMN Samples',
          id: 'MN_CANTEENMN_SAMPLES__700_24th_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'PLEASE CALL DEREK',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LOVE_AIRPORT_GATE_12',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park at loading dock and enter terminal.  The fridge is located post-security near the maggiano's in the main concourse area close to security checkpoint. Airfield Access - walk to Gate 12",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ILLINOIS_COLLEGE_OF_OPTOMETRY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front doors and check in with security (**if arriving before 7am, you will need to use the call box to have someone let you in**). Walk past security towards the right and through the hall- take the first left. Go to the cafeteria on B one floor below the fridge and the fridge will be in the cafeteria on the left',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Procter & Gamble -Center Hill',
          id: 'OH_PROCTER_GAMBLE_CENTER_HILL__6090_CENTER_HILL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Please bring the order to the loading dock at the opposite of Center Hill Avenue',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Procter & Gamble',
        },
        {
          opportunityName: 'Target - T3285',
          id: 'PA_TARGET_T3285__456_N_5TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Fortune Park Warehouse',
          id: 'IN_COSTCO_FORTUNE_PARK_WAREHOUSE__9010_MICHIGAN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_LGA5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "sign in the front entrance, show drivers license, they will escort to break room, or if they want to use loading dock, sign in at main entrance and they'll tell you where to go.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MORTON_COLLEGE_BUILDING_C_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Entrance to building C, take a right and fridge will be at exit of cafeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JCREW_MADEWELL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CROWN_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'parking in the lot in the front near from entrance\r\n\r\nfrom dry run: address is 1801 Main st, Evanston IL. Park at the main entrance in the parking lot, use key to enter first set of double doors, look for the alarm system on the right side of the wall labeled “Partition 2 Vending” enter the alarm code (44551 to disarm, and 44552 to arm). Walk through second set of double doors then towards the right side of the building and fridge is located in the vending area across from Rink 1&2.\r\n\r\nIf stocking after 5:20a, do not reset the alarm but do lock the front door back .However please lock the front door back after delivery.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1266',
          id: 'MA_TARGET_T1266__101_COMMERCE_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in rear and enter through the loading dock, if not possible enter through front, Location opens at 7am, Stock in endcap near produce',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Menomonee Falls Warehouse',
          id: 'WI_COSTCO_MENOMONEE_FALLS_WAREHOUSE__W162N9235_PERSHING',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DFW7_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in through security, turn left, breakroom will be down the hall on the left, fridge will go across from microwaves on the left side',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'USC Arcadia',
          id: 'CA_USC_ARCADIA__300_W_HUNTINGTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'LOADING DOCK NEXT TO ERON HUNTINGTON  GO DOWN HILL AND CALL DELIVERY CONTACT',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Keck Medicine / USC Med',
        },
        {
          opportunityName: 'TX_HOU_LONE_STAR_COLLEGE_NORTH_HARRIS',
          id: 'TX_LONE_STAR_COLLEGE__2700_WW_THORNE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call onsite contact when arriving',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_REID_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter into the main location front entrance and take a right at security The fridge is located along the wall of the dining area next to the Starbucks',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'fc72acce-e2f2-4ec3-9982-79a957324a82',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1040',
          id: 'MN_HYVEE_1040__9409_ZANE_AVE_N',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_HMS Host ORD',
          id: 'IL_CATERING_(COMPED)_-_SALES_HMS_HOST_ORD__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop with HMS Ohare Order with specific instructions to be samples',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Debevoise & Plimpton',
          id: 'NY_DEBEVOISE_PLIMPTON__66_HUDSON_BOULEVARD_EAST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_8080_PARK_LANE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the main 8080 Park Lane entrance, Fridge will be directly ahead in the lobby.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '48bc9dd7-8225-4acf-a293-b1aedd6abe3e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Lake In The Hills Warehouse',
          id: 'IL_COSTCO_LAKE_IN_THE_HILLS_WAREHOUSE__250_N_RANDALL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drive to the back of receiving and deliver product',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NJ_HARBORSIDE_5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the main entrance side doors (do not use revolving ) make left fridge straight ahead on left in lobby',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS IAH Mockingbird Brewery',
          id: 'TX_HOU_HMS_IAH_MOCKINGBIRD_BREWERY__2800_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Before 9 AM Park outside Terminal A Baggage claim, call delivery contacts upon arrival. After 9 AM Park in the Limo lot next to Terminal A on North Terminal RD and call delivery contacts upon arrival.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_CREE_LIGHTING_MAIN_ROUTE7__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MILWAUKEE_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_PECHANGA_ARENA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the loading area and take a right. The fridge will live in the break room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DLF 191',
          id: 'TX_HUDSON_DLF_191__1001_N._UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Group drop to Dallas Hudson Distribution center',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_1_A4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '55ef65b2-2fd7-4304-8878-6fd72dbcfd10',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KEMPER_LAKES_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SOLDIER_FIELD_SECTION_102',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1801_S_MEYERS_OAKBROOK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the loading dock and head to main lobby to stock the machine.\r\n\r\nSaturday delivery window is from 5AM - 1PM',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIV_OF_MIAMI_TEMP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_TODAYSHOW',
          id: 'NY_TODAYSHOW__155_EAST_82ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'This is for Al Roker from the Today show :)',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_University of Houston Main Campus Free Fill',
          id: 'TX_UNIVERSITY_OF_HOUSTON_MAIN_CAMPUS_FREE_FILL__4384_COUGAR_VILLAGE0_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'We can enter through the front entrance or the back',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Dallas Warehouse',
          id: 'TX_COSTCO_DALLAS_WAREHOUSE__8055_CHURCHILL_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back near receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MN_CENTURY_COLLEGE_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From dock, head through double doors towards the exercise gym.  Turn right and head up the ramp until it turns left. Go left about 10ft then turn right. You are now in the cafeteria. Fallow the wall in your left to the bank of vending machines. \n\n**If the driver needs help they can call security at 651-747-4000.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PRINCETON_LEWIS_THOMAS_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Turn right onto street by the bridge. Keep going down the road until you can't anymore. Walk through main doors, fridge on right. (Pics attached)",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_IFK',
          id: 'IL_IFK__205_E_RANDOLPH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please include a box of forks with the order! Thank you!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TWU_MCL_COFFEE_STAND',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'There is a door that leads directly to the Fridge which is 10 feet inside that door.\r\n\r\n24/7 Access: Driver would need to get a TWU ID from ID services which is open Mon-Friday 8-5pm.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RABOBANK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ea1d1875-9de1-4a22-9158-e9a05f0a47c8',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Manchester MO Warehouse',
          id: 'MO_COSTCO_MANCHESTER_MO_WAREHOUSE__301_HIGHLANDS_BLVD_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Park near receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'WALGREENS_DOWNTOWN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through Van Buren entrance into main lobby (closest to the light and go up the ramp through accessible door). \r\n\r\nCheck in with security at the front desk, exchange DL for guest card to have access to turnstiles. Walk to elevators and take up to 4th floor. Exit elevator, badge in, and walk down hallway (follow signs for forum if you get lost). Fridge is inside a small room next to the microwaves.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_FOXLA',
          id: 'LA_FOXLA__123_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE CALL SANDRA',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Garden City, NY',
          id: 'NY_LIFETIME_GARDEN_CITY_NY__750_ZECKENDORF_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TEXAS_CHILDRENS_HOSPITAL_WOMENS_PAVILLION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '475_HALF_DAY_ROAD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_NY BNY Samples',
          id: 'NY_BNY_SAMPLES__240_GREENWICH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'SAMPLES FOR CAITLIN. DROP OFF WITH NORMAL BNY MELON NYC ORDER',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson BWI',
          id: 'MD_BAL_HUDSON_BWI__107_AIR_CARGO_SERVICE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Dropping to bradford',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_FORT_WORTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the doors, take a right down the hall, Fridge will be all the way down just before the elevators on the left side.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_GWC_LANGUAGE_ARTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Make your way to the Language Arts Building and enter the main doors. Go to room 121 (it is a common area) and you will see the Fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DMC_HURON_VALLEY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to shipping and receiving Sign in  and go down the hallway and make a left and a quick right and then a left and follow signs for cafeteria Map Pin: https://goo.gl/maps/pRjoNvoMrVtCGBrz9',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3345',
          id: 'CA_TARGET_T3345__17401_VENTURA_BLVD_UNIT_A1',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'CA_LA_MEMORIALCARE_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in visitor spot, go through front doors, walk back to the cafeteria to the left side.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_SHOENECKER_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver will enter building through the loading dock and take elevator to third floor',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Garland, TX',
          id: 'TX_LIFETIME_GARLAND_TX__5602_NAAMAN_FOREST_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            "Texas Children's Hospital - 21st Floor Lounge PM Drop",
          id: 'TX_TEXAS_CHILDRENS_HOSPITAL_21ST_FLOOR_PHYSICIANS_LOUNGE_PM_DROP__6651_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            "DROP IN THE AFTERNOON - Go to loading dock, take freight elevator to 21st floor physician's lounge. Use ID to get in the door - remove old food, take any excess food that cannot fit to Bistro",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "Texas Children's Hospital",
        },
        {
          opportunityName: null,
          id: '4f2fd799-ee12-40c3-9985-1de5f3d9f15e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS IAH Hugo',
          id: 'TX_HOU_HMS_IAH_HOU__2800_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Before 9 AM Park outside Terminal A Baggage claim, call delivery contacts upon arrival. After 9 AM Park in the Limo lot next to Terminal A on North Terminal RD and call delivery contacts upon arrival.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_EXPEDIA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go in loading dock, walk through security door and go straight to the "lounge", we are in the micromarket',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'eba7dccb-cfdf-41c9-b4de-63443c9ee003',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_ALIEF_HAYES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door, go to the right toward the atrium, the fridge is in the cafe area',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_SOCIAL_WORK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take farthest elevator to the 2nd floor. Take a left out of the elevator bank, take left at end of hallway. walk through the 2 doors and fridge will be in the vending hall outside of room 201b',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '043814ec-e267-4f9e-912f-21a061c65571',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ST_BERNARD_HIGH_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter on 92nd street - see map. Park in guest parking. Check-in at front counter located through main doors. You will be escorted to fridge.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_KRONOS__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BOSTON_CONSULTING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Logan Square',
          id: 'IL_LINCOLNWOOD__3374_W_DEVON_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_AMERICAN_UNIVERSITY_LAW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Turn right out of the loading dock, another right through the double doors. Take service elevator to Terrace. Walk out of elevator, turn right then left. Fridge by staircase.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Miami of Ohio Samples',
          id: 'OH_SALES_MIAMI_OF_OHIO_SAMPLES__',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Come up the loading dock on Patterson and Bishop Circle. Door will be unlocked',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_PUBLIC_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight elevator up to 9. Fridge by elevators.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_MEDICAL_EMERGENCY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Front door, enter emergency dept and walk straight back to the vending bank',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Burnsville Warehouse',
          id: 'MN_COSTCO_BURNSVILLE_WAREHOUSE__14050_BURNHAVEN_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pull to the back to receiving. Please be off the sales floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_BOND',
          id: 'IL_BOND__20_N_UPPER_WACKER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL BRETT - FLOOR 10',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_HENRY_FORD_MACOMB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Location: Located in the cafeteria seating area.\nDriver Entrance: Main Entrance, park near the end of the entrance driveway.\nDelivery Hours:  5am-8pm.\nPark to fridge instructions: Enter through the main door. Proceed through the vestibule (keeping left), and once through, turn right. Take the elevator immediately opposite the entry door up to 2.\nFrom the elevator, turn left. Then turn left again. Proceed through the waiting room (past the fish tank), and turn right. Turn right again, and proceed through the canteen store to the fridge at the far end of the dining seating area. Map Pin: https://maps.app.goo.gl/HbRP93tPhVTGGHQA9',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_ST_LUKES_HOSPITAL_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter North Building Entrance doors, continue straight down the hallway towards the Central Hub, Fridge will be next to the ATM in the Central Hub (near glass elevator)',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_PIT Airport',
          id: 'OH_PIT_AIRPORT__LANDSIDE_TERMINAL_4TH_FLOOR_TERMINAL',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'Does Not Exist',
          deliveryInstructions: 'Airship',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ST_ALBANS_VA_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter into the main entrance and go to the left in the lobby where the vending area is.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Palm Springs Event',
          id: 'IL_PALM_SPRINGS_EVENT__1140_N_WELLS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'If possible, please pack in 1 large cardboard box!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_RNDC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_LAW',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'See attached video. Walk through the loading dock.\r\n\r\nOvernight contact, University Police 312-503-3456',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'XS_TENNIS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales Walmart',
          id: 'IL_CATERING_(COMPED)_SALES_WALMART__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop to Fulton Office and alert Mike Saletta upon arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Costco TX Region Office',
          id: 'TX_COSTCO_TX_REGION_OFFICE__1701_DALLAS_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Enter at side of the W Plano Costco where the region office door is. Ask for Danielle Oellrich or Lauren Knight (Department 19 buyers)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Canteen Office',
          id: 'MD_CANTEEN_OFFICE__7458_CANDLEWOOD_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park out front and call Dave Harris 240 904 1880',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS MKE - Market',
          id: 'WI_HMS_MKE_MARKET__5300_S_HOWELL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Delivery to dock and call the commissary number listed at the dock to let HMS Host know, number to call: 414-349-3245',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'Getir- Medford',
          id: 'MA_GETIR_MEDFORD__294_HARVARD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KENT_COLLEGE_OF_LAW_CAFETERIA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Take freight elevator to the 3rd floor and fridge is located in the cafeteria\r\nThe access point is the rear service door, behind the building on Quincy Street. There's a card reader next to the door where you have to tap to get in and out without activating an alarm.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1853',
          id: 'TX_HUDSON_PHL_1853__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_GESSNER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "walk in front door, turn right, we're in the new vending room",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ANIXTER_HW_NEW',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_HONEYWELL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors, take first left passed the security desk, and then walk down to cafe. Fridge is located in cafe.\r\n\r\nCheck in with security every time we stock a fridge to confirm we are on their list. They will allow entry after confirmation.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_ANN_ARBOR_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Door hours: 5a-8p\nMap pin: https://maps.app.goo.gl/YerotswnAv6MfnJu6\nFrom the main door, keep left. Follow signs for cafeteria/micro mart. Fridge is in the vending area near the micro mart.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_IKEA_BUILDING_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through loading dock into the building and get escorted to the location.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_WHOOP HQ',
          id: 'MA_WHOOP_HQ__545_COMMONWEALTH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Call Steph Walsh at 617-710-1021 upon arrival',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COMEDYCENTRAL_NICKELODEON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_NYC Health Hospitals',
          id: 'NY_NYC_HEALTH_HOSPITALS__900_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Call Nelson Cabrera (917-623-5972) upon arrival at 900 Main Street.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_1330_FOUR_OAK_PLACE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Dinning Manager to get escorted to the service elevator into the cafeteria.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LEGACY_PLACE_MAIL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in go straight until you see the lounge, turn right and the fridge will be on the right past the first set of doors',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Allina Health Mercy Wholesale',
          id: 'MN_ALLINA_HEALTH_MERCY_WHOLESALE__4050_COON_RAPID_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Arrive at the east entrance, and call (763) 236-8163',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BNY Mellon NYC',
          id: 'NY_BNY_MELLON_NYC__240_GREENWICH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'loading dock',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Flexco Main',
          id: 'IL_CANTEEN_FLEXCO_MAIN__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kean University Wholesale',
          id: 'NJ_KEAN_UNIVERSITY__1000_MORRIS_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'we would require deliveries directly to the Outtakes Store in the Miron Student Center.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: 'Kean University',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CITY HALL',
          id: 'IL_CITYHALL__121_LASALLE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL ENRIQUE',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'IIT_HERMAN_HALL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_DEPT_OF_ENERGY_GOV',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Need to be on access list (driver names sent in advance)\r\n\r\nTake Elevator to Ground Floor. \r\n\r\nHelp desk phone # (202) 586-6100\r\nKelvin Jamison (202) 664-4986\r\nFred Williams (240) 449-7461',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_PROCTOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the freight elevator to floor 3 take a right then a left then the fridge location is in the vending alcove on your right',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '075b3eef-cda8-45a6-aedf-5c2f14887ed1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_MUNSTER_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take main hallway towards main desk - fridge is on your left by floor to ceiling windows',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MERITER_HOSPITAL_ATRIUM_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Upon entering the building, take a left in the main lobby. Make your way towards the atrium and cafe past the gift shop. The machine is along the vending machine wall of the seating area',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_PROCTER_AND_GAMBLE_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the front entrance into the main lobby where the fridge is located off the main lobby.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VE_TEST_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VE_TEST_6',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_Hudson BWI Sample Pack',
          id: 'MD_CATERING_(COMPED)_SALES_HUDSON_BWI_SAMPLEPACK__107_AIR_CARGO_SERVICE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Drop to bradford',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_UNION_DEPOT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once parked where the green star indicates, the driver should cross the bus loop via the pedestrian crossing and enter through Door 14. From there they should take the elevator up to the first floor. As they exit the elevator, the machine will be to the left. For the first delivery, I am happy to arrange for one of my team members to meet the driver to walk through this with them.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_CLARK',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8736b914-d60f-41e9-96af-2a6a3c3b0444',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Memorial Hermann Cypress',
          id: 'TX_MEMORIAL_HERMANN_CYPRESS__15500_VOSS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Eddie will pick up at cold storage',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_PENN_PRESBYTERIAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through Medical Office Building. Walk straight passed temperature check point and continue straight. Once you get to the information desk turn right. Walk down hallway and turn left toward elevators. Machine will be on the left side.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_iCapital NYC',
          id: 'NY_ICAPITAL_NYC__60_EAST_42ND_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Sean',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MD_BAL_LIFE_BRIDGE_HEALTH_SINAI',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge in and walk through the doors. Turn right, walk past 1st bank of elevators to the 2nd bank of elevators. Take to 1st floor. Walk towards the cafeteria, fridge is on the left hand side.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CUYAHOGA_CC_ADMINISTRATIVE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'FOR FRIDGE DELIVERY - WHEN DELIVERING THE FRIDGE USE THE SMALL LOADING DOCK AREA IN THE BACK OF THE\r\nBUILDING, THIS DOOR CANNOT BE OPENED FROM THE OUTSIDE. DELIVERY PERSON SHOULD\r\nREQUEST ACCESS FROM THE FRONT DESK\r\n\r\nFor Daily Stocking - When parking at the main front entrance take those entrance doors into the lobby of the admin building. The fridge is located in the 1st floor kitchenette (making a left at room 146).\r\nDrivers will need to call Cuyahoga Community College Campus Police Dispatch is 216-987-4325.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KETTERING COLLEGE',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KETTERING_COLLEGE__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_AXN',
          id: 'TX_AXN__1501_GAYLORD_TRAIL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'CALL CHRIS M! WE WILL HAVE A MARKETING FRIDGE ON SITE - PLANOGRAM FF_Marketing_Event',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Lifetime- Chappaqua, NY',
          id: 'NY_LIFETIME_CHAPPAQUA_NY__1_WESTCHESTER_PARK_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_University of St Thomas',
          id: 'TX_UNIVERSITY_OF_ST_THOMAS__3909_GRAUSTARK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'Call Sean at 832-244-5635',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_FAIRVIEW_PHARMACY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Ring buzzer and go straight, break room will be the first door on the left',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Toledo Warehouse',
          id: 'OH_COSTCO_TOLEDO_WAREHOUSE_3405_W_CENTRAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to back by receiving. Please be off floor by 9:30AM',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 2',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_2__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'df4ba17e-c2a4-43a1-8217-bf0d8cff3388',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TEXAS_STATE_LBJ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go to main lobby, the fridge is in the seating area of the cafeteria',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MORAINE_VALLEY_NURSING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_TANGEMAN_CENTER_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once into the main entrance at the food court the fridge is located along the wall outside the bookstore on the 3rd floor',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ST_VINCENT_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_8',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_NASCARDALLAS',
          id: 'TX_NASCARDALLAS__3545_LONE_STAR_CIR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'PLEASE CALL JEFF',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b7c8fc74-6de8-4820-830b-8236883efc10',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Sarah',
          id: 'IL_ICNC_FULTON__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_JACOBI_4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Marketing_TECHWEEK',
          id: 'OH_TECHWEEK__1205_ELM_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'PLEASE DELIVER AS CLOSE TO 11:30AM AS POSSIBLE!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'CITADEL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through main entrance, security will let you in, fridge is located in the lobby next to the ATM.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_SAMARITAN_8TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take service elevators to 5th floor, vending area will be in main hallway',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_OH_Catering (comped) - Sales Pittsburgh Hudson',
          id: 'PA_CATERING_COMPED_SALES_PITTSBURGH_HUDSON__1000_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Deliver to bradford with instructions to be delivered to Pittsburgh Hudson NEws',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HARRIS_COUNTY_ADMIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The fridge will be located on the first floor besides the elevators and bathroom, attached is a picture of the spot for reference.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Eden Prairie Warehouse',
          id: 'MN_COSTCO_EDEN_PRAIRIE_WAREHOUSE__12011_TECHNOLOGY_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pull to the back in receiving and please be off the floor at 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'AON_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BELLEVUE_9TH_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health System',
          id: 'MI_HENRY_FORD__6777_W_MAPLE_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AIRPORT_GATE_23_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park at loading dock and walk to gate 23. The fridge is located on the windows.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_GOODBODY_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'WA_Catering (comped) - Sales Starbucks Corp',
          id: 'WA_CATERING_(COMPED)_SALES_STARBUCKS_CORP__47527_288TH_AVE_SE',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'Does Not Exist',
          deliveryInstructions: 'Air Shipment',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_SOHO_LOFTS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - PD Instore Tempo',
          id: 'MN_CANTEEN_PD_INSTORE_TEMPO__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Lifetime - Schaumburg, IL',
          id: 'IL_LIFETIME_SCHAMBURG_IL__900_EAST_HIGGINS_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_ORD2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security\r\nBreak room/lunchroom will be to your left as you enter break room make a right at the first entry you see on the right make a quick left the fridge will be next to multiple vending machines.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_OU_HUMAN_HEALTH_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in main atrium',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_EINSTEIN_MED_CENTER_MONTGOMERY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the receiving door, walk straight, turn left and walk through "exit" door to the cafeteria. Walk out of cafeteria to lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Fox Vending MM44',
          id: 'IL_FOX_VENDING__9717_S_76TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please drop in the double door coolers staged outside (Pepsi coolers). Contact Stu Spector for delivery 708-430-4600, or Cell 708-287-4155.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2ddbcd64-3b60-43b4-9906-ddb91d3a1b07',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_SWIFT_TRANSPO_LANCASTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main office, go through back door and the space will be on the left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6e11160a-de75-4717-b246-f15b34ac073d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB1_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter EWR Terminal B and walk to the Jersey on the Shore food hall. Find fridge just before the food hall',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_AMAZON_BURLINGTON_ACY2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security, turn right towards the "Main Breakroom"',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_LEWIS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter main door and location is right by elevator bank',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SCRIPPS_RANCHO_BERNARDO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk straight down main hallway, take a right at the end of the hallway. Fridge located in the vending room.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_WESTERN_RIDGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the main entrance towards the medical office building, once in the connecting hallway the vending area will be on the right near the seating area.\r\n\r\nCan also be accessed through the Emergency Department Entrance which is open 24/7/365',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '226_W_JACKSON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '28e06dbd-1575-4061-87fd-d93a8e40a6f5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SCRIPPS_ENCINITAS_CLINIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Located in the 2nd floor breakroom',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MOJO_BLUE_MARKET_AND_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WESTERN_UNIVERSITY_HPC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the main part of campus and you will see the HPC building. Enter and go to the main lobby and you will see the Fridge location\r\n\r\nIf stocking overnight, call Security # (909) 706-3000. Even with badge access, need to call and notify their security team that you are on campus.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Bloomingdale, IL',
          id: 'IL_LIFETIME_BLOOMINGDALE_IL__455_SCOTT_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW B43',
          id: 'TX_HUDSON_DFW_B43__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'CA_ARE_SORRENTO_VALLEY_BLVD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emporium Market - Armstrong Student Center',
          id: 'OH_EMPORIUM_MARKET_ARMSTRONG_STUDENT_CENTER__550_E_SPRING_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Come up the loading dock on Patterson and Bishop Circle. Door will be unlocked',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_MORTENSON_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'They can go through main entrance, left door swings fully open, 35" clearance and back to the lobby, next set of doors is double doors no center post, then it\'s straight back to the main room where the fridge will go',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b3fd42b8-8c00-4d5e-a7e6-a0a74bfe927a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BERTS_CAFE__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN FORT HAMILTON',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_FORT_HAMILTON__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_REHABILITATION_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Come in and follow the hallway down to the left. When you reach the nurses station, take a right. The cafeteria is on the right hand side ¾ of the way down the hall. The cafeteria doors may be shut but they do not lock.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_TABETHA',
          id: 'IL_TABETHA__2545_S_DEARBORN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL TABETHA!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_PITCHSLAM',
          id: 'IL_PITCHSLAM__1551_W_THOMAS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL CARLOS',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_HMS SAT Samples',
          id: 'TX_SAT_CATERING_(COMPED)_SALES_HMS_SAT_SAMPLES__9800_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Deliver to warehouse and call delivery contact for instruction upon arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_SAN_USPS_SAN_ANTONIO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk up the loading dock ramp, use to door to the right, Fridge will be in that room to the back right\r\n\r\nContacts if there's issues: \r\n(281)728-5914 James (469)888-0060 Elizabeth",
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_INTERFAITH_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Check in with security and head to the vending area of the cafeteria. Farmer's Fridge will be located there. \r\n\r\nSecurity phone # 718-613-4280",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Interfaith Medical',
          id: 'NY_INTERFAITH_MEDICAL__255_PINELAWN_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Go to the front entrance and proceed to the loading dock.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Canteen Melville HQ',
          id: 'NY_CANTEEN_MELVILLE_HQ__255_PINELAWN_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Go to the front entrance and proceed to the loading dock.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GLENBROOK_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through emergency room and follow the signs for the break room/vending bank',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Ziegler ZRC',
          id: 'MN_CANTEEN_ZIEGLER_ZRC__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: '4_OVERLOOK_PT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_EAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take front entrance lobby to the doors of the east cafe',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LEWIS_UNI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_UNITED_CAFE',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Enter through Delivery/Package lane at Post 7\r\nDoor Code = 5423',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_ARE_MIRATI_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Kurt will follow up with instructions',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WALLACE_10',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to the first floor of Mark Wallace Tower. Grab a key card from our security desk to access the 10th floor. Return after use.\r\n\r\nTake the freight elevator to the Fridge.\r\n\r\n# to call 832-824-5400',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CVG_PRE_SECURITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Depend of the Designated parking by the police',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DLF 195',
          id: 'TX_HUDSON_DLF_195__1002_N._UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Group drop to Dallas Hudson Distribution center',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AIRPORT_OFFICES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter badging office entrance on the second floor and take elevator down to the first floor. Turn right, walk towards the conference rooms, turn right again towards the break room and find fridge to your left.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOAG_HOSPITAL_IRVINE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'must wear mask Dec 2023-Mar 2024\r\n\r\nEnter main door and follow hall then take a right at the end. Fridge will be in the lunch area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_754__5545_S_BRAINARD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3348__125_E_STEARNS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Turck Main',
          id: 'MN_CANTEEN_TURCK_MAIN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_MAIN_CAMPUS_MATERNITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the 1st floor elevator up to the 2nd floor. The fridge is along the wall off the elevator',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_IAH1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Security will escort you to the Fridge',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_GLOBAL_POWER_COMPONENTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through main entrance - straight shot to the fridge.\r\n\r\nIf coming DURING THE DAY - NOT OVERNIGHT, call Bruce (414-554-8197) or Kori (262) 305-4033) if you have trouble accessing.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_MILSTEIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through main entrance, take escalator to the second floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_PLANO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main entrance, Fridge will be located directly to your right.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2650_WARRENVILLE_CORRIDOR_3',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take main entrance to the hallway to the right, then location is in the hallway to the left across from vacant "deli"',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SLB_155_INDUSTRIAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the main entrance and ask to be escorted. The kiosk is going straight down the same corridor from the main entrance.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_U_ST_THOMAS_CENTRAL_HUB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the loading dock go to the elevator. Get off on floor 1. It will open up to the TMH Atrium. The machine will be on the backside of the staircase',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CTA_DAMEN_PINK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park right in front of the front door. Ask security to enter and go through the turnstile. The fridge will be located about 10 feet down on your right side. The fridge lock combo is 1321.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_WRIGHT_FOOD_COURT__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Oak Brook Warehouse',
          id: 'IL_COSTCO_OAK_BROOK_WAREHOUSE__ 1901_W_22ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Hyvee #1165',
          id: 'MN_HYVEE_1165__1500_CENTRAL_PARK_COMMONS_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_1_A17',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_EXXON_HQ_ENERGY_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'You will badge at the large roll up door and go into the tunnel. Go across the tunnel to the office and check in to get the reflective vest that you will need to wear to walk through the tunnel.  You will then proceed to the next set of large roll up doors. You will badge at the first door and walk through to the next badge reader on the other side. Once you are to the next badge reader and clear the first door, you can badge at the next reader, and the 2nd door will open. This is a security check point, and you can get stuck in the middle if you do not badge properly.  You will go to the first stop sign, and then proceed to the 2nd. YOU MUST TREAT THE TUNNEL SYSTEM LIKE ANY ROADS AND MAKE COMPLETE STOPS AND LOOK BOTH WAYS. There are vehicles that are constantly moving. \r\n\r\nAt the 2nd stop sign, take a LEFT. You will go through another stop sign and then look for the sign for the Energy 3 building. Turn RIGHT into the hallway to that building and badge at the elevator badge and push the button for the Lower Level "LL" . When you come out of the elevator you will exit the foyer room and be in a hallway with other elevators. You will also see a set of glass revolving doors and an "ADA" door. You will badge for this door on the wall, do not push it open, an alarm will sound. The door will automatically open and close. For security purposes, once you walk through the door, you cannot walk away until the door has completely closed behind you.  You will then take a LEFT and walk towards the doors that lead outside, before you get to those doors, there will be a hallway on your Left. Turn there and walk towards the Spark location which is at the end of that hallway.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Mark Vend 2',
          id: 'IL_MARK_VEND_2__3000_MACARTHUR_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please bring the order to the front door and buzz for access with the onsite team',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_TX HOU Catering (comped) Sales - HMS Host IAH',
          id: 'TX_HOU_CATERING_(COMPED)_SALES_HMS_HOST_IAH__2800_TERMIANL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Deliver to IAH just outside Terminal A Baggage claim, call delivery contacts upon arrival.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_ALLIANCE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through main entrance and turn left. Go through the wooden doors. Machine is on the left.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Food Rocket - ORD8',
          id: 'IL_FOOD_ROCKET_ORD8__901_W_JACKSON_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_MICHIGAN_CITY_FRANCISCAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Take front door to vending area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_SUNY_OLD_WESTBURY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Driver can call Brian Watala (516-628-5088) when onsite and he will meet you at the loading dock. If Brian doesn't pick up, call Carol Kaunitz (516-628-5071)",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - 2020 Ridge',
          id: 'IL_CANTEEN_2020_RIDGE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Vernon Hills, IL',
          id: 'IL_LIFETIME_VERNON_HILLS_IL__680_WOODLANDS_PARKWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Carfax Catering',
          id: 'DC_CARFAX_CATERING__5860_TRINITY_PARKWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Please drop with their fridge order',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIVERSITY_OF_CHICAGO_KNAPP_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the rear loading dock to the main (1st) floor front entrance lobby the fridge will be located along the main wall of the seating area of the lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Morgan Stanley Wholesale',
          id: 'NY_MORGAN_STANLEY_WESTCHESTER__2000_WESTCHESTER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Guards will direct to loading dock\n\n \n\n*** Driver must have valid ID to enter the premise*****',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on Hartford to enter and head straight to vending machine area',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_UPENN_MEDICAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ELI_LILLY_TECH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_SAKS_FIFTH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_UBS_TRADING_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If any issues accessing the fridges, TEXT GUSTAVO 646-286-9733 \r\n\r\nFridge has to be stocked between 700am-230pm Monday-Friday. \r\nCall 212-713-4876 when you are at the loading dock because we need to be escorted to the fridge. Enter freight elevator and take it to the 9th floor. Exit elevator room and turn right. Walk down and turn right into the pantry. Fridge will be right there.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '73cba37f-0be1-4b93-8f48-835b5ad65418',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - WHOLESALE SPLITS',
          id: 'MN_CANTEEN_WHOLESALE_SPLITS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_RICE_REC_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park outside and walk to building and you will go through glass doors. The location will be just are your right when you walk in the building.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3459__1600_DEERFIELD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN VIKINGS EVENT',
          id: 'MN_VIKINGS_EVENT__2510_S_7TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Delivery Date: Monday, December 19th\nTime: 9:30 AM\nDrop off location:  Masonic Children’s Hospital | 2510 S 7th Street Minneapolis, MN 55454\nContact: Quinn Adams | 507.720.7606',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_IMSMP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Street parking. Enter through loading dock and take freight elevator to 3rd floor. Walk straight down hallway and turn right toward employee lounge. Fridge will be on the right in the lounge.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_THOMAS_JEFFERSON_UNI_HOSPITAL_CENTER_CITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'SEE "FILES" FOR DETAIL - Take Freight Elevator to Level 2. Take a left off elevator and right at the second opening down the hallway, go through double doors to seating area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Madison',
          id: 'WI_CANTEEN_MADISON__2250_MUSTANG_WAY',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Go to loading dock and either ring doorbell or use fob!',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'HMS IAH Cadillac Bar',
          id: 'TX_HOU_HMS_IAH_CADILLAC_BAR__2800_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Before 9 AM Park outside Terminal A Baggage claim, call delivery contacts upon arrival. After 9 AM Park in the Limo lot next to Terminal A on North Terminal RD and call delivery contacts upon arrival.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'PA_PHI_EINSTEIN_MED_YORK_ROAD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Ring the doorbell. Walk through the kitchen to elevator and take up to 1st floor cafeteria. Fridge is in seating area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_TEACHERS_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in through gate and turn right down stairs, i included pics',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson LGA',
          id: 'NY_HUDSON_LGA__2-15_26TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Drop to Bradford Logistics at LGA',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Aramark DFW',
          id: 'TX_ARAMARK_DFW__750_N_ROYAL_LANE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'please call delivery contact',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: 'Hudson ORD T1 B5',
          id: 'IL_HUDSON_ORD_T1_B5__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'GOULD_AND_RATNER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (Comped) - Sales_Matt Arlington Sample',
          id: 'VA_MATT__1770_CRYSTAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'There is a loading dock, located at the corner of 18th Street and Crystal Drive, entrance is off 18th street. Enter there and call Richard at 571-299-9662',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_MED_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the clinical academic building, through the bridge to the medical academic building.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Minnesota Vikings',
          id: 'MN_MINNESOTA_VIKINGS_668_ONEIL_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            "Please park at the 668 O'neill facility East Loading Dock. (This is where you're going now for the fridge) Announce to security that you are with FF and they'll call to the food service team",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Minnesota Vikings',
        },
        {
          opportunityName: null,
          id: 'CLEVELAND_AVE2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Service go through the back take elevator to 3rd floor turn left and walk to the end of the hall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_NOCC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Main lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CSU_DOMINGUEZ_LOKER_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk up the stairs at the loading dock towards the Loker Student Union. You will see the Fridge outside next to the boba machine',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_FENWAY_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter off 4 Jersey Street to deliver at Gate D. Take the product through the security checkpoint, then into the ballpark and to the fridge. Fridge is located in the main concourse near the vending area and Grandstand 1-3',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LAHEY_MEDICAL_OFFICES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BAXTER_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '700_COMMERCE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2799',
          id: 'IL_TARGET_T2799__1_S_STATE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Enter through the loading dock and take freight elevator to 2 one display will be in the fresh market section of the store. There is a 2nd display next to the self check out that we would also stock\n\nChris',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: '6a5a079e-d39a-4f75-bb9b-e2d47864316f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_DREXEL_HEALTH_SCIENCES_7TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock, need badge to get to elevators, take to 7th Floor. Take a right off the elevator and a left and quick left into vending area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_DREW_UNIVERSITY_FRIDGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the double doors and walk to the front by the lobby. Fridge is in lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park next to the 2nd entrance to a parking garage, do not enter parking garage because van is to tall, walk through the parking garage to a main door, at door there will be a buzzer, use it to call security they will open the door for you, walk in, pass the security desk to the left and turn left. Short walk through a big hallway and the fridge will be in a open room to your left in "Tenant Amenities Center".\r\n\r\nNotes from dry run: Park in front of 8755 W. Higgins Rd. Security is here 24/7 and will buzz you in. Walk past security desk towards elevators. Turn left and follow the hallway past the second security desk and Fridge is located in Tenant Amenities Center on the left. Vending sticker is updated.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_UCBA_MUNTZ_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock entrance towards the main dining court where the fridge is located directly outside of along the wall',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SEPULVEDA_VA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'FRIDGE WILL BE LOCATED BEHIND STARBUCKS MAIN LOBBY',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_BP_HELIOS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through main entrance, fridge located next to starbucks',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Duncanville Warehouse',
          id: 'TX_COSTCO_DUNCANVILLE_WAREHOUSE__250_W_HIGHWAY_67',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back for receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Target - T2079',
          id: 'IL_TARGET_T2079__2112_W_PETERSON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'When arriving at the loading dock there will be a ramp to the left and a double door. Ring the bell and they will let you in.\n\nWhen we come in ask for Jasmine or Demir they’re in charge of the fresh market section.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Target - T3312',
          id: 'NY_TARGET_T3312__1201_3RD_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Costco - Merrillville Warehouse',
          id: 'IN_COSTCO_MERRILLVILLE_WAREHOUSE__1310_E_79TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Drive to the back near receiving and deliver',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'FEDEX_EXPRESS_ORD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at front door entrance walk inside fridge is on the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_CANTEEN EVENT',
          id: 'TX_CANTEEN_EVENT__12510_W_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'KEEP AT COLD STORAGE',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'ROOKERY_BLDG',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_UNIVERSITY_ALUMNI',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the freight elevator up to 1 and turn right down the hall.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GOTTLIEB_MEMORIAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main entrance doors. Walk straight and take a left down the main hallway. Keep walking until you reach the reception. Fridge is located next to the main elevator bank behind reception',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '01157eeb-1159-4bfb-a244-9079ea7220be',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M25',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            "The vendor entrance from the dock closes from 8pm till 5 am and have to go in thru passengers screening entrance. There's a lane for employees and vendors to cut the line. Once on the concourse m5 is to the right and m16 is to the left. They go in numerical order so it won't be hard to miss. To exit there's a big sign next to were you come in through that says exit and that will leave you back to ticketing.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_East health center gove Wellness Fair',
          id: 'MA_BOS_SALES_EAST_CENTER_GOVE_WELLNESS_FAIR__10_GOVE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'The areas of delivery are busy so please enter the building and see public safety and ask for Courtney Senechal and we will meet the person.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Charlie Samples',
          id: 'TX_CHARLIE_SAMPLES__1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Charlie will pick up at cold storage',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'aabf6ee4-627a-445e-bf62-03dab4853345',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS_DMOS_5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the dock doors, take elevator up to the second floor, Fridge will be located next to the personal cubbies which is around the corner from the micro-market',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_MERCHMART',
          id: 'IL_MERCHMART__222_MERCHANDISE_MART_PL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL MALORIE',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_DRAMATIC_ARTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the front door, take elevator down to basement, fridge is located in the study room on the far back wall.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Marche',
          id: 'NY_Marche_Carta__1_World_Trade',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please handoff to Marche at 1 World Trade',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_WOODLAND_MOTHERCARE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in loading dock. Take ramp up to main double doors and enter building. Walk down all of hallway until you reach elevators. Go up to floor 3. Exit elevator to the right. Go right again. Fridge is in purple waiting room.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ruso test',
          id: 'test',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Test',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD1_BACK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the front entrance past security. Behind the desk take the main hallways to the rear break room on Southwest side of the building near inbound. The fridge is along the wall with the windows.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_NORTHROP_GRUMMAN_MANHATTAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main door and check in. You will be directed to the breakroom',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3304',
          id: 'MA_TARGET_T3304__822_SOMERVILLE_',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Park in lot at the rear. Ring door bell for reciever and enter through recieving. Stock near produce section NO DELIVERY BETWEEN 9 - 10AM. 7am receiving',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_NAZARETH_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter though ER. and walk towards main lobby. Walk through the vending double doors on left hand side.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JESSE_BROWN_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in roundabout, enter through revolving door. Fridge is directly next to front desk.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LAKE_POINT_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_CURBIVORE',
          id: 'CA_CURBIVORE__826_MATEO_ST',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'COORDINATING WITH JAY TO SEND THIS OUT WITH A LEAD!',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_NORTH_HILLS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge location in main lobby. Walk through front door and turn right.\r\n\r\nFridge is in the Main lobby to right.\r\n\r\nStraight past the front desk on the left side of the lobby.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FOOTHILL_MED_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with public safety if going overnight.\r\n\r\nFollow the hallway and check in with the security desk. Go straight and take your first right where you will then see the fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_NEWTON_WELLESLEY_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Emi or Paul and they will walk with you to the location.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_CHRIST_CHILDRENS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Park on Kilborn side parking lot then take to main lobby towards the children hospital then take the elevator to ground level childrens cafe fridge right outside of elevators. Or park on small dock next to children's hospital and go in through main entrance to then take elevators towards children cafe ground side.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_DINALLO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go straight through doors under the Dinallo Heights sign, community room is to the right past guard. The fridge will go outside of the vending bank',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_CIB__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_RICE_BIORESEARCH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock on the side of the building, walk to the entrance of the building.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_MARYLAND_SCIENCE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Science Center team will direct our driver through',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_RUSH_CAFE_7_MARKET__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_MARS_INC_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight elevator up to Floor 10. Call Gary if any issues 571 596 0895',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - S St Louis Warehouse',
          id: 'MO_COSTCO_S_ST_LOUIS_WAREHOUSE__4200_RUSTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Park near receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: '1000_W_FULTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the loading dock and follow the hallway till it leads to a dead end. Security will buzz you up to the second floor. Take a left out of the elevators, and the fridge will be there.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BMCC_MURRAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'loading dock elevator to 3rd floor then take the other elevator to main floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_TX Costco Samples',
          id: 'TX_CATERING_(COMPED)_SALES_TX_COSTCO_SAMPLES__1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Chris to pick up order at facility',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOVELL_VA_BUILDING_4',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take parking lot to building 4 entrance, take that to vending area.\r\n\r\nFor overnight access, stop at the police office in building IEF and an officer will accompany you to the machine',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kaiser Permanente Ontario Medical Center',
          id: 'CA_KAISER_ONTARIO_MEDICAL_CENTER__2295_S_VINEYARD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver to Todd Engel. His cell is 909-303-0137.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_NJ Assortment',
          id: 'NJ_HUDSON_ASSORTMENT__1_MEADOWLANDS_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'deliver to lobby at Hudson Corporate office through contact Lisette, she will meet you in the lobby upon call',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Markforged',
          id: 'MA_MARKFORGED__85_SCHOOL_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Pull in the lot and park on the right in Handicap. Driver call Jen at 781 856 6876',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_UMMA_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_MALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update when plan is finalized',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_GREAT_WOLF_LODGE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5d5e1ce5-6cc2-474f-ab4b-1598cb16b4f3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ZURICH_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Children's Hospital of Minneapolis",
          id: 'MN_CHILDRENS_HOSOITAL_OF_MINNEAPOLIS__2530_CHICAGO_AVENUE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Park out front in half circle on left side close to the curb. Walk into lobby and take a left, go to elevators. Access from elevator to kitchen code: 5632. Walk in and cooler door is on the left. Find empty space on shelves to leave the boxes.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Allina Health',
        },
        {
          opportunityName: null,
          id: 'MKE_MILLIPORE_SIGMA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'See photo attached for map of parking spot to fridge instructions. Walk through the doors, go straight to the break room, fridge will be in the corner.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CEDARS_SINAI_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk to the Ray Charles Cafeteria',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_SONY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_WEILCORNELL_3RDFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_ECO_LABS_MAIN__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_VIRGINIA_HOSPITAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the lobby and the Fridge is to your right near the Starbucks Cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MAIZIES__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_DENTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take main entrance, walk right around the bend to the cafeteria. Fridge will be in the dining area of the cafeteria.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_UT_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, take freight elevator up to 5th floor, to the left is the vending bank',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Viacom Wholesale',
          id: 'NY_VIACOM_WHOLESALE__1515_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock- 44th St between 7th and 8th ave. Left hand side ¾ down the block',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health System - Main Detroit Campus',
          id: 'MI_HENRY_FORD_MAIN_DETROIT_CAMPUS_CAFETERIA__2799_W_GRAND_BVLD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            '"Map Pin:\r\nhttps://goo.gl/maps/KYS2zfX7WHAfRAfY6\r\nEnter through the Clinic entrance doors.\r\nTurn right, and then take the elevators on your right down to the basement.\r\nFollow the hallway around, and turn right at the end of the hall.\r\nFollow that hallway down to the end, and follow it to the left.\r\nProceed through the double doors.\r\nTake the third door on the right.\r\nThe ""Express"" order goes in the 2nd walk-in straight ahead.\r\nThe ""Main Cafeteria"" order goes in the 1st walk-in to the left.\r\nAsk for assistance from staff as needed."',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'The Henry Ford Health System',
        },
        {
          opportunityName: null,
          id: 'ICNC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Park at loading dock on Carrol Ave, using your keycard, open the freight elevator and take to floor 3. Turn left out of the elevator and it'll be right there.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_STATEN_ISLAND',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Call security if going overnight: 718-982-2111\r\n\r\nDriver would enter building form the loading dock doors or the glass doors depending on where he parked',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_BMS_SUMMIT_S4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come in through the entrance at 181 Passaic Ave and go to building S4. Please contact Christian (973 951-2658) or Shira',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Food Rocket - ORD2',
          id: 'IL_FOOD_ROCKET_ORD2__160_E_GRAND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'please call Jasmine',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_LGA9_EDISON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to the main cafeteria, fridge will be to the left of the projector',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_BALDWIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at rear loading dock off of the side of the main entrance. There will be some security gates to drive through but will be open during hours of operations. Enter through main entrance. Pass through lobby, and head back towards seating area past security check-in. The fridge is along divider wall.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_15__1922_N_MENDELL_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BNY_MELLON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'use freight elevators to 16FL, pass through first shaft of elevators onto the dining seating area, machine will be immediately passed the entrance. COI Doc has delivery instructions too. Associate delivering must bring photo ID for delivery for each visit and make it known he comes from Farm Fridge upon arrival for access to the 16FL food hall.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_BLOCK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/9BvNDL5nnZQCWZzd7?g_st=ic\nPark on curb in front of the building, from there take the ramp down. You will need to be buzzed in. Once inside you’ll head straight until you see the elevators. Take it to the 1st floor, exit the elevator and take a right down the hall. At the end of that hall make a right and the lunch room is the first door on the left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_VILLANOVA_BARTLEY_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front door. Vending room is on the left side of the room',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_SNYDER_PHILLIPS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address - 362 Bogue St East Lansing MI 48825\nCustomer Friendly Location - MSU - Sny Phi Building. Located on the main floor in the west entrance of Sny Phi.\nDelivery Hours - 7AM to 6PM\nDriver Entrance - West Entrance\nDriver Parking - Park in front lot of sny phi building\nPark to Fridge Instructions - walk around to the West side entrance. Enter the building, head straight up the ramp, turn left and go through first set of doors and the fridge will be on your left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_HOME_DEPOT_NORTHLAKE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '05d6edff-1dfe-432b-8d91-ca1acdd7054c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Event Dallas',
          id: 'TX_CATERING_(COMPED)_CANTEEN_EVENT_DALLAS__1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Leave at Dallas Cold storage for Eddie (idiot) to pick up',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_LOUIS_STOKES_VA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 24/7\nDriver Entrance - Front Entrance, Main Entrance\nDriver Parking - Park in semi-circle near main entrance\nPark To Fridge Instructions - Walk through main entrance doors, fridge is located to the left, just past the information desk',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BMC_CROSSTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the front entrance towards the elevator bank and the fridge will be straight ahead.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Tucker Samples 2',
          id: 'CA_TUCKER_SAMPLES__5162_DAWES_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Please leave box at front door. No need to contact Tucker!',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_PANORAMA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From loading dock head to the cafeteria on Floor 1.  Fridge will be located right in the front.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_STUDENT_UNION_2NDFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_LEAR__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'U_CHICAGO_BIOLOGICAL_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If stocking same day os 900 E. 57th st, walk from that location to Biological Sciences Learning Center via the bridge\n\nIf stocking on a different day/time,] park on 57th street and use East entrance to BSLC since it has a ramp at that entrance. Fridge will be on the lower level against the back wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MBF DOWNTOWN [ROUTE 4]',
          id: 'WI_MBF_DOWNTOWN_ROUTE4__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_DENTSU_AOA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe AY',
          id: 'CA_QUALCOMM_CAFE_AY__10155_PACIFIC_HEIGHTS_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Vernon Warehouse Spotify',
          id: 'CA_VERNON_WAREHOUSE_SPOTIFY__4569_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Put in warehouse cold storage for Eddy pickup',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3490',
          id: 'IL_JEWEL_OSCO_3490__890_N_WESTERN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK: Go through brown doors on right of sign Produce Market. Take elevator to basement level. Deli Cooler is through tan double doors. DELIVERY: Rob (deli mgr). Patrick (receiver). Call ahead of you are going to arrive outside receiving hours',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'OHARE_3K_LEFT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Terminal 3 next to K6, past foodcourt and Frontera Grill',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_RSNYC',
          id: 'NY_RSNYC__125_E_50TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL RACHEL',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen San Diego',
          id: 'CA_CANTEEN_SAN_DIEGO_VISTA__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_OVERLOOK_MEDICAL_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'They need to go into the entrance by the red awning and go left to the elevators',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_POPPI',
          id: 'TX_POPPI__31_NAVASOTA_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'PLEASE CALL MEGAN',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_2_E20',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LUTHERAN_GENERAL_CANCER_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "If we're coming overnight, our drivers need to park in the CAC Dock - located off of Vernon St by Vernon Lot. They would then get buzzed into the CAC dock by the ASCC. If we're coming between 8am-6pm, we can just park in front and walk through the front doors.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_ASCENT_LOGISTICS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/BeEqE5hXQxZpDVcH9?g_st=ic\n24 hour access\nPark in the loop near the front door or the empty space next to the loop. Walk inside, someone will buzz you in. Once inside there’s a hall to your right, take it and the vending area will be ahead.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JULLIARD_SCHOOL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CENTRAL_DUPAGE_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JUILLIARD_SCHOOL_FIFTH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Loading dock, check in with security. Use the same elevator that you use to access the 3rd floor fridge. Take elevator to the 5th floor instead. Exit, turn right down hallway, turn right at next hallway, then turn right at the next hallway after that. Fridge will be in alcove on the right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ruso New Test',
          id: 'IL_TEST_FOR_RUSO__123_TEST_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_CA_Catering (comped) - Sales_Norco College',
          id: 'CA_NORCO_COLLEGE__2001_THIRD_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Drop them at the food bank in the Engagement Center',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WLS_TELEVISION_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Driver can park right infront of building. There will always be security to let you in. Once inside you just walk pass security and machine visible right infront of you.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MSU_IM_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'MI_MSU_IM_WEST\nConsumer Friendly Name/Location: MSU - IM West Located on the main floor by the Sports Arena/Volleyball courts\nDelivery Hours - 6AM to 8PM\nFridge Address: 393 Chestnut Rd East Lansing, MI 48824\nDriver Entrance: Front Entrance\nDriver Parking: Park infront of the IM West front entrance (near north end of football stadium)\nPark to Fridge Instructions: ramp entrance through the left side of the main entrance. Take hallway all the way down past the Fitness Center and the machine is outside the Sports Arena/Volleyball Courts',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in entrance to Cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD E4',
          id: 'IL_HUDSON_ORD_E4__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TECHNIP_ENERGIES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to the loading dock on the side of the building or go though the front entrance to the elevator.\r\n\r\nCheck in at reception to receive a temporary badge. No escort needed.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in loading dock, walk to elevators and go up to the 5th floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Memorial Hermann Southeast',
          id: 'TX_MEMORIAL_HERMANN_SOUTHEAST__11800_ASTORIA_BOULEVARD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'PLEASE CALL DC UPON ARRIVAL',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Memorial Hermann Healthcare System',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_IAH_UNITED_PILOTS_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'AIRPORT FRIDGE - NEED TO FIGURE OUT',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW B21',
          id: 'TX_HUDSON_DFW_B21__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_OAKLAND_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Location: located in the main cafeteria vending area. Delivery Entrance: Main Entrance, park in the parking spots near the main door. Park to fridge instructions: Enter via the main door, then turn left. Follow signs for the cafeteria. Fridge is in the vending nook on the right of the cafeteria seating area.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_MIAMISBURG',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go right through the cafeteria to the on the go market area next to dining room 1,',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_GR_WYOMING_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the canteen on the first floor. NO weekend access.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen San Diego',
          id: 'CA_CANTEEN_SAN_DIEGO__5515_MARKET_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNI_OF_HOUSTON_STUDENT_CENTER_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park outside of Student center, walk through main entrance doors, the Fridge is in the middle of the main atrium on the West side, next to the Mondo's subs.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UKG Lowell',
          id: 'MA_UKG_LOWELL_WHOLESALE__900_CHELMSFORD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Contact Sherry at 978-427-5621 and/or Jirair at 617-838-7986 when arriving onsite',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_WATTAGE',
          id: 'IL_WATTAGE__1044_W_KINZIE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please call Christina 708 280 9747',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Pondview Plaza',
          id: 'MN_CATERING_(COMPED)_SALES_PONDVIEW_PLAZA__',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Must be delivered before 11:30am- DC will direct driver where to go. Suite is on 3rd floor',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_SOUTH_LAKE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through the front entrance, turn right and it will be down teh hallway on the right\r\nUse the call button for entry.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_PRESBYTERIAN_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Fridge will be on the 11th floor cafeteria.  it's going to the left from where that digital screen is in the photo",
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Oakland University',
          id: 'MI_OAKLAND_UNIVERSITY__CHARTWELLS_312_MEADOW_BROOK_RD_SUITE146',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'To get to the dock of the Oakland Center (OC) by way of Wilson Blvd. The dock is signified by the Red Arrow.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AB_INBEV',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '4e7a1c11-ab04-4cdd-bc59-4b93e867678c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS IAH Ciao',
          id: 'TX_HOU_HMS_IAH_CIAO__2800_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Deliver to IAH just outside Terminal A Baggage claim, call delivery contacts upon arrival.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'UMASS - UNIVERSITY HALL/BEACON CAFETERIA',
          id: 'MA_UMASS_UNIVERSITY_HALL_BEACON_CAFETERIA__100_WILLIAM_T_MORRISSEY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_UNITED_COMMUNICATIONS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Loading dock will have security ID required all product will go thru scanning.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Heywood',
          id: 'MN_CANTEEN_HEYWOOD__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Mettawa Warehouse',
          id: 'IL_COSTCO_METTAWA_WAREHOUSE__25901_RIVERWOODS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please be off the sales floor by 9:30am - enter through back door receiving',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Costco - St Charles Warehouse',
          id: 'IL_COSTCO_ST_CHARLES__215_S_RANDALL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drive to the back by receiving, deliver product',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MI_DET_EAST_ANN_ARBOR_HEALTH_GERIATRICS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin:\nhttps://goo.gl/maps/so6TpeoL1xYL8G666\nFridge is at the "East Ann Arbor Health and Geriatrics Center" building.\nPark at the very beginning or very end of the dropoff loop.\nEnter through the main door, and the fridge is to the right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_2000_MARKET',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_WILLIAM_ECKHARDT_RESEARCH_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SOUTHERN_UNI_LAW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the School of Law located North West of the campus map.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_NYC Kimmel Center',
          id: 'NY_KIMMEL_CENTER__60_WASHINGTON_SQUARE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'call sandy',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SOUTHWESTERN_CAESAR_CHAVEZ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'You will be able to park in the back of the building in a large lot and enter through the back door. Follow the short hallway and you will be in the large lobby where the Fridge will live.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CC Vending',
          id: 'NY_CC_VENDING__70_S_MACQUESTEN',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'DELIVERY CONTACT & CELL_Mike Jr_914-774-3611',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'C C Vending Inc',
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Hudson ONT Sample Pack',
          id: 'CA_CATERING_(COMPED)_SALES_HUDSON_ONT_SAMPLE__2900_E_AIRPORT_DRIVE_PACK__',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Drop order to the Hudson INK Store in Terminal 4 across from AA gate 208',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Israel Discount Bank - Wholesale',
          id: 'NY_ISRAEL_DISCOUNT_BANK__1114_AVENUE_OF_AMERICAS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading Dock Entrance: 43rd Street midway between 5th and 6th. *Deliveries must be made through the loading dock and brought to the kitchen on the 2nd Floor. Signature is required for order acceptance. Order may be delivered Monday ? Friday 6:30am to 2:30pm',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_NORTHEAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take a left into the cafeteria, Fridge will be on the back left wall',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_HOLLYWOOD_CASINO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From our dock we will escort you and your team to get vendor badges and then escort you to our breakroom using our service elevators.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNI_OF_HOUSTON_COUGAR_PL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door, the fridge is right in front of the main entrance in the vending room',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kiosk/WF',
          id: 'MI_KIOSK_WF__100_MICHIGAN_ST_NE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Woodlands Warehouse',
          id: 'TX_COSTCO_WOODLANDS_WAREHOUSE__8185_HIGHWAY_242',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Canteen- Nvenia [Route 1]',
          id: 'IL_CANTEEN_NVENIA__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_SSM_HEALTH_STL_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the Bordley Main Entrance, Fridge will be on the left hand side',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_TRIANGLE_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at loading walk straight down the hall pass the Receptionist desk to the elevator up to the Second floor turn left Entry door will be on your right Fridge is located inside\r\n\r\nDriver can also enter through the front door depending on how much food they have. The Second location picture are here as well If the fridge door opens too wide Client says they are trying to get the lights removed so there won’t be a issue with the door opening. This will be a Tight squeeze for as height wise This location it 9’7',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8bf2abb6-3088-403d-b3b5-d5f485739be4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KOREATOWN_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to area A where the fridge is located.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_MEDICINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'elevator down to the ground floor',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_MATHER_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the loading dock area. Alert receiving personnel that you are making a delivery for Food & Dining Services and someone from FDS will greet you and assist with delivery.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Scripps Encinitas Doctor Lounge',
          id: 'CA_SCRIPPS_ENCINITAS_DOCTOR_LOUNGE__354_SANTA_FE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Please deliver with Scripps Encinitas normal order',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Scripps Health',
        },
        {
          opportunityName: null,
          id: 'NJ_ROBERTET_BUDD_LAKE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'MUST SIGN IN FIRST ON IPAD in the vestibule and await approval from your host before entering the building”. DON OR BRENDA FOR HOST. \r\n\r\nOnce they call or text Don DiBello (973) 868-2509, he will have someone from facilities greet the driver at that door for entrance (which is right next to the cafeteria). \r\n\r\nReception, Sabine Elsasser or Brenda Breheny, 201-337-7100 then press 0 to reach the receptionist to let us know they’ve arrived.\r\n\r\nBack up contact: Brenda Breheny Office:  201-337-7100 x2245,\r\nCell:  973-670-1577\r\n\r\nAt this location, there is a receptionist onsite from 8:30am-4pm that can be contacted for entrance if Don or Brenda do not immediately respond.  They can be reached at our main number which is (201) 337-7100.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3204',
          id: 'MN_TARGET_T3204__2080_FORD_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BLUE_MARKET_AT_UNION__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'de08c7a7-6e2d-4937-8845-76bdd221d0f4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MBF DOWNTOWN [ROUTE 4]',
          id: 'WI_MBF_DOWNTOWN_ROUTE4__12100 W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'a101bcd4-a5f9-4954-8c50-387e3d654870',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EQUINOX_HIGHLAND_PARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MCLAREN_OAKLAND',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COOK_CHILDRENS_SKYBRIDGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: '*will need escort to fridge',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - Metro',
          id: 'VA_HUDSON_IAD_METRO__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Deliver to Hudson Dulles',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'IND_LOWES_SUPPORTCENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_WHITE_PLAINS_HOSPITAL_CAFETERIA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "2 ways of entering.\n\nStaff entrance (shortly past main entrance) is unlocked from 5:30am to 4pm. If it's locked, then driver is required to contact security or the food service manager for entry. Go up 6 stairs, enter door, walk straight. Get to the hallway, turn right and the cafeteria will be on the left.\n\nEntry #2 is located by the loading dock at the intersection of Winchester st & E Post rd (opposite of dead end). Enter through black door. If it's locked, then driver is required to contact security or the food service manager for entry. Walk straight down to elevator on the left. Go up to the Main floor. Exit left out of the elevator, quick right into the hallway and the cafeteria will be on the left.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '54556be1-c6b4-4dea-adab-2410c9c0ef4d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 4500',
          id: 'IL_JEWEL_OSCO_45000__1340_S_CANAL_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DIVCOWEST Coffee Bar',
          id: 'MA_BMS_CAMBRIDGE__250_WATER_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'LOCATED AT THE MAIN ENTRANCE IN THE FRONT OF THE BUILDING',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_ABBOTT_PLYMOUTH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RESURRECTION_PREP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LAX_AIRPORT_MSC_GREAT_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'n/a Delaware North stocking fridges',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_RAB',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "For regular filling delivery you will just take elevator to the floor and head the area marked vending.  Near the bridge to the main hospital tower building. It has been suggested by the Rush staff to ensure we won't need an added badge component here, the team should use the current method of entry for the Tower fridge as this new location is directly across from the bridge.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHERVON_NORTH_AMERICA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Entrance Door along west side of building (next to cafeteria).  If same driver each week we’ll consider offering a temporary ID badge for driver to gain access.  See attached overhead picture with parking and access instructions.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 144 And 100S',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_144_AND_100S__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Delivery Instructions\r\nPlease call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: 'Costco - Sugar Land Warehouse',
          id: 'TX_COSTCO_SUGAR_LAND_WAREHOUSE__17520_SOUTHWEST_FWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_DEA',
          id: 'IL_DEA__2721_W_31ST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call the delivery phone number!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c31d2df6-3490-4fb0-88ab-93101b5cbc24',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_CARTHAGE_COLLEGE_UNION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_MONTGOMERY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in at the Business Office (in the Maverick Student Center MSC 217), which is in the building next to Building B.\r\n\r\nAccess hours listed are for Mon-Thurs. For Friday, access is 8a-5p; Saturday access is 9a-2p',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 84 Fred',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_84_FRED__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Delivery Instructions\r\nPlease call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_DEPAUL_ATHLETICS',
          id: 'IL_DEPAUL__2323_N_SHEFFIELD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Hailey Lacy (812)-987-3779',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'OPPLOANS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_869__61_6TH_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AMAZON_MKE2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the front door, check in with security, fridge located in main breakroom',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '2500_W_BRADLEY_PL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk into west lobby of 2500 W Bradley. Fridge will be in inside the doors of "Compass Health Center"',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ST_AUGUSTINE_HEALTH_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Will need to check in at the main lobby in building A then be let into building C where the Fridge will be visible',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UNI_OF_HOUSTON_SUGAR_LAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front of Brazos Hall, walk in front door, walk to back of building, fridge is under the only staircase',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '300_S_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'fridge will be on north side of lobby, near the elevators on the right.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_SOUTHDALE_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take the elevator down a flight, walk left towards the cafeteria, Fridge will be just outside the cafe.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PROVIDENT_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_10TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the first floor community room. Fridge located in the vending bank',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW B28',
          id: 'TX_HUDSON_DFW_B28__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: '222_S_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Edinburough',
          id: 'MN_CANTEEN_EDINBUROUGH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: '118_N_CLARK_PEDWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Pass the city side, first elevator on left and right are freight elevators. Take down, go down the hall and there's a door that leads into the pedway. Fridge will be located across from the vending machines",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_NORRIS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '(See attached video) Walk through loading dock doors, keep walking straight passed the freight elevators. Walk a little further until you see the single brown door on the right hand side. Walk straight through that door and then straight through the double doors. Continue walking straight passed the vending machines and the fridge is on your right.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AB_INBEV2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_ST_LUKES_BAPTIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the doors, take a left past the coffee stand, cafeteria will be the next door on the right. Fridge will be just inside the door on the left side next to a Canteen drink machine and a microwave',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Chestnut Hill, MA',
          id: 'MA_LIFETIME_CHESTNUT_HILL_MA__300_BOYLSTON_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_WALTER_RICE_COURTHOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Need to be escorted onsite. \r\nPrimary contact: Will Manning (614) 208-1613)\r\nSecondary: Anthony Ezerski 614-214-4164\r\n\r\nDriver will be able to go from the dock to the freight elevator on the first floor that will take them to the 3rd floor Rm 300 where the fridge will be placed',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_TAUBMAN_JAVA_BLUE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_OCEANSIDE_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the front door and go to the main hallway where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_2265__507_E_WOODLAWN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAH C',
          id: 'TX_HUDSON_IAH_C__3800_TERMINAL_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Deliver to loading dock and then call manager on duty line to recieve order',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'ELMHURST_HOSPITAL_MAIN_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'take a right on main hallway then left towards cafe, vending will be on the left side of the hallway next to cafe\r\n\r\nFor overnight access, drivers need to call the security office for an escort from their entry point at Edwards emergency room. The number is 331-221-5500',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: '475 SS Cafeteria 2',
          id: 'NJ_475_SS_CAFETERIA__100_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver to 475 South street. Do NOT deliver with their regular order',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_LEWISVILLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main entrance, head left to the cafeteria, and the fridge will be to your right.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WTTW',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through front door, security will let you in. Cafeteria is on the East end of the building. Do not use a cart (it will damage limestone floors)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_UBS_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Sinai Hospital',
          id: 'DC_SINAI_HOSPITAL__2401_W_BELVEDERE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Loading Dock is located around the side of the building. Take first left off of Belvedere and veer right past the parking garage towards the dumpsters. Loading Dock on your left',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_3H_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MATTESON_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'front entrance - go through security - take a left through balloons into the cafeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 6',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_6__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BLUE_MARKET_PIERPOINT__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'drop off in circle drive or dock, first left of Bonisteel past Murfin',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson MSP 2295',
          id: 'MN_HUDSON_MSP_2295__MINNEAPOLIS_SAINT_PAUL_INTERNATIONAL_AIRPORT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pick up orders from Bradford with Fridge orders. Deliver to hudson news in the Main concourse next to sunglass Hut',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_LBJ_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go in loading dock - go to 1st floor - the cafe is right past the front deck on the righthand side.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 144 Andersen CR4',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_144_ANDERSEN_CR4__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: 'MD_BAL_SINAI_HOSPITAL_ED',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From the cafeteria, walk towards the ED. Fridge in Lobby.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - GE EVENDALE',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_GE_EVENDALE__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Warehouse Vernon',
          id: 'CA_WAREHOUSE_VERNON__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            "Farmer's Vernon Warehouse drop.  I will drive to warehouse and pickup samples for client distribution",
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_BMS Sample',
          id: 'NJ_BMS_SAMPLE__181_PASSAIC_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Call delivery contacts - Christian 973 951-2658 or Matthew 973-255-0791',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'CANTEEN - Boston 2 Foodcourt',
          id: 'MN_CANTEEN_BOSTON_2_FOODCOURT__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Cleveland State University 2',
          id: 'OH_CLEVELAND_STATE_UNIVERSITY_2__2121_EUCLID_AVE',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Delivering to Joe Ettinger',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '4e3f1a8f-2e4d-4874-b909-693fec4b37c2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3376',
          id: 'IL_JEWEL_OSCO_3376__370_N_DESPLAINES_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: Deli cooler OTHER DELIVERY INSTRUCTIONS: Deli section in FF marked space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'CTA_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MACYS_STORY_STATE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_420_LEXINGTON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Waubonsis Sugar Grove',
          id: 'IL_CANTEEN_WAUBONSIS_SIGAR_GROVE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PUBLICIS_TENANT_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of building, use handicap doors to enter building, (always accessible) security will be to your left, walk through turnstiles on left using your badge for access. Take elevator to 21st floor, when exiting elevator use badge again to enter through glass double door, make a left, walk until end of hallway, you will pass what will look like a kitchen with a coffee dispenser. Fridge will be on your right.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_THE_DEVAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front doors, there will be a concierge who can show them the first few times, but they just walk straight back to the kitchen area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - North Riverside IL Warehouse',
          id: 'IL_COSTCO_NORTH_RIVERSIDE_IL_WAREHOUSE__2500_SOUTH_HARLEM_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by Receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CA_LA_ADVENTIST_GLENDALE_PAVILION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Once inside the facility, announce to security you're there with FF. Then take the front lobby to the hallway on your right (Maternity & Outpatient Surgery Center) to the seating reception. There is an elevator bank on your right hand side. The machine is in the alcove on the right",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_OVATION_HOLLYWOOD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_A_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock. 1 driver will enter the front of the airport and go through the passenger/employee security check point. The other driver with the vehicle will take the products out and bring them to the door in the loading with security and wait for the other driver to make it through security. Pick up product from the loading dock and follow the hallway to the right till you reach the elevator.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_UT Lunch',
          id: 'TX_UT_LUNCH__100_W_21ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'CRUCIAL that it is delivered prior to 11:00am.  This is our largest account in Texas, and will be unacceptable for it to be late. please include x120 SUNDRIES + x120 BOATS',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_PURDUE_KRACH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Rivian- Normal',
          id: 'IL_RIVIAN_NORMAL__100_RIVIAN_MOTORWAY',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Loading dock at building B. Take the main entrance and take a right.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIVERSITY_OF_CHICAGO_ECKHART',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevator up to the 3rd floor- fridge will be located against the wall near vending machines',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_COLUMBIA_STMARYS_WOMENS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '0da4bdd1-9b7d-47b4-a81c-95733f513a5d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MITCHELL_CONCOURSE_C',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in the Contractors parking lot and take the rental car elevator to the 3rd level. Make a right out of the elevator and walk down the concourse  until you see \'C". Make a left and the fridge is right past the TSA checkpoint on the left.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_UNITED_HEALTH_GROUP_OPTUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once in the building they are to take a right and badge through another set of doors. Please take the main hallway through the site and follow the hallway East until you arrive at the machine',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_VAL',
          id: 'IL_VAL__190_N_STATE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL VAL 708-921-8295‬',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ST_DAVIDS_GEORGETOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park next to loading dock, go in the door that leads to the cafeteria and the fridge is right in the cafe',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FEDEX_590',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through security entrance,  take a right past security, keep walking straight until you see fridge located in employee breakroom',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_SIG_LEFT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e5a9ac47-559a-46dc-8271-b58561473d48',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Loyola-Chicago Wholesale',
          id: 'IL_LOYOLA_UNIVERSITY_DAMEN_FOOD_COURT__N_SHERIDAN_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_4TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security and walk to Vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SONY_PICTURES_ATRIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter loading dock off Duquesne St behind building main entrance. Park and enter double main doors straight across from parking. Follow hallway all the way down. Fridge will be located at the end of the hallway.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in main lobby',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU6',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go thru front doors, the breakroom with the fridge is to the left of the security desk, before you pass the desk.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ORD Nonstop K3',
          id: 'IL_ORD_NONSTOP_K3__10000_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to the same location as T1-T3',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'GA_Catering (comped) - Sales_Abraham Villaran-Faiz',
          id: 'GA_ABRAHAM_VILLARAN-FAIZ__1576_JOHNSON_RD_NW',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'Does Not Exist',
          deliveryInstructions: 'Air Shipment',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_FLEX_N_GATE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TRUMAN_COLLEGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_Spring House Building Catering',
          id: 'PA_SPRING_HOUSE_BUILDING_CATERING__727_NORRISTOWN_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Elizabeth Kunkle 215-347-9171',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MI_Catering (comped) - Sales_Jack Samples for NSF',
          id: 'MI_JACK_NSF_SAMPLES__22795_HESLIP_DR',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Jack will pick up on Wednesday - 5/31',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_ARLINGTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in the main lobby, Fridge will be directly to the left of the coffee stand',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Fresenius Kabi Ruby St.BR2',
          id: 'IL_CANTEEN_FRENSENIUS_KABI_ST_BR2__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ANIXTER_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1224',
          id: 'NJ_TARGET_T1224__200_PROMENADE_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW D8',
          id: 'TX_HUDSON_DFW_D8__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M8',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Terminal five loading dock take the elevator up to TSA vendor checkpoint badge in turn right walk down to M8 fridge is on the left side when facing both fridge’s',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Food Rocket - ORD1',
          id: 'IL_FOOD_ROCKET_ORD1_1639_NMILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'please call javiar and deliver through the back door',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'The Franklin Institute Wholesale',
          id: 'PA_FRANKLIN_INSTITUTE__271_21ST_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Driver Parking: Alcove parking off of 21st Street\n- Driver Instructions: Walk to security, make a right, take the first gold elevators to the second floor\n- 24 hour access - We'll likely be looking to make deliveries during off hours, please confirm this is okay, or if we need badging of any kind.\n- The Eatery located on the second floor",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOSTON_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk in entrance of 850 Harrison, and turn right, Fridge will be on left against the wall opposite of Dunkin' Donuts",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_THE_ION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Access fridge through the loading dock located on Wheeler St. \r\nThe fridge is in the lower level on the right side of the big staircase in the middle of the building',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_AMAZON_MTN6',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk through Security towards breakrook',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_231__1300_CHRISTOPHER_COLUMBUS_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - John Marshall Law School',
          id: 'IL_CANTEEN_JOHN_MARSHALL_LAW_SCHOOL__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Arlington Warehouse',
          id: 'TX_COSTCO_ARLINGTON_WAREHOUSE__600_W_ARBROOK_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back to receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SAN_JACINTO_CC_CENTRAL_45',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through the front entrance , the fridge is in a vending alcove that is visible from the front door, on the right side of the building',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_DENTONS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Unload & access the freight elevators to bring the delivery to 25th Dentons' floors.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1eedb0a8-fb59-46c8-99ed-4f503ece3d50',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_NORTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front entrance, walk straight and the cafeteria come up on your left side, fridge is located right outside cafeteria.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_WILLOW_CREEK_FITNESS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use badge to get access into the space. Come through the front door and take a left. Follow the hallway until you reach the fitness center.\r\n\r\nPedro (619-951-8050) can assist with access if needed. He is on-site 8am – 2pm M-F.\r\n\r\nLockbox/ Remote unlocking of the doors can be provided at critical times.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PVH',
          id: 'CA_PVH__2400_EAST_4TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'CALL DC',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Prime Healthcare',
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_INT_MARKET_SQUARE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "DOM'S Kitchen and Market Old Town",
          id: 'IL_DOMS_KITCHEN_AND_MARKET_OLD_TOWN__1225_N_WELLS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "Receiving door - Goethe St alley: one-way. Max truck - 32'. Door - 10' W x 14' H. Need to assist in delivery by unloading truck and bringing SKUs into receiving. May need to have hand jack or pallet jack to prepare the product for delivery and unload.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ASPEN_MEDICAL_PRODUCTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the front door and go left in the lobby. At the end of the hallway, take another left and the Fridge will be in the cafe there. Access hours are 8-4 Mon - Fri',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CHI_Catering (comped) - Marketing_Fulton Office - Invest for Kids Event',
          id: 'IL_INVEST_FOR_KIDS_EVENT__2000_W_FULTON_ST_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver to the Fulton office on Monday 10/31 anytime!',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WILLIS_TOWER_LEFT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'George Mason Univ - Wholesale',
          id: 'VA_JOHNSON_CENTER_EXPRESS_STORE__JOHNSON_CENTER_4400_UNIVERSITY_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock- ground level, downstairs \r\nStore first floor\r\nNavigation from Parking to Drop location: On the first floor of the Johnson center\r\nJohnson Center,',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_330_HOPE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the Building and the Fridge will be on the fist floor in the main common area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_HOUSTONMETHODIST',
          id: 'TX_HOUSTON_METHODIST__18500_KATY_FWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'ANN 832-522-1556',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_3443__5343_N_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson MDW - 372',
          id: 'IL_HUDSON_MDW_372__5700_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop product to Terminal C loading dock. You can call the delivery contact upon arrival or alert dock staff to its pressence',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_CLEARY_GOTTLIEB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Bentley University - Natural!',
          id: 'MA_BENTLEY_UNIVERSITY_NATURAL__175_FOREST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'from loading dock bring up 1 floor on freight elevator to kitchen.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: '435 South Street 3',
          id: 'NJ_435_SOUTH_STREET_3__435_SOUTH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Deliver to 475 South street',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd2aa93f7-f926-45fe-9c77-f1e453a33a66',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Burr Ridge, IL',
          id: 'IL_LIFETIME_BURR_RIDGE_IL__601_BURR_RIDGE_PARKWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Hearth and Home',
          id: 'MN_CANTEEN_HEARTH_AND_HOME__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_KENWOOD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: "It's in the lobby",
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ONT_TERMINAL_2_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge located in front of baggage claim in terminal 2',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_CLEAR_LAKE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'once you go thru the side door of the cafeteria, the fridge will be in the main seating area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Nestle USA',
          id: 'MD_NESTLE_USA__1812_N_MOORE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in loading dock and call Hilary 703.309.7863',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pure Green - Madison',
          id: 'WI_PURE_GREEN_MADISON__634_STATE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'retrieve key from lockbox on door. The lockbox code is 2300 and walk straight back. There is an empty white pick up freezer on the right for online orders before the washroom.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Texas Children's Hospital - Bistro",
          id: 'TX_TEXAS_CHILDRENS_HOSPITAL_BISTRO__6651_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'GO TO LOADING DOCK- TAKE FREIGH ELEVATOR TO 3RD FLOOR. SOMEONE WILL BE AT BISTRO AND GUIDE YOU TO FRIDGE TO DROP ORDER',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "Texas Children's Hospital",
        },
        {
          opportunityName: 'TEST for RETAIL 3',
          id: 'IL_TEST_FOR_RETAIL_3__2130_W_THOMAS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_KATY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through glass doors and take a right into the hallway where the flower shop and cafeteria are located. You will see the vending area on your left side.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_VA_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors and check in at desk. Continue down main hallway. Take a left down the main hallway. fridge located in atrium across from entrance to cafe.\r\n\r\nIf front entrance is closed. Walk in through emergency entrance - check in with security - fridge is straight forward once you get pass security.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Marketing_FORBES',
          id: 'OH_FORBES__500_LAKESIDE_AVE_E',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Please call Rachel upon arrival!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NYC Health + Hospital Harlem',
          id: 'NY_NYC_HEALTH_HOSPITAL__506_LENOX_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Tuesday, December 20. Please CALL David Vega c: 917-656-3597 when you're on way to coordinate exact place to meet, he will be expecting you. (Backup phone number Ross Perone c: 631-807-8158).",
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_HSC_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'PARK IN LOADING DOCK, FRIDGE WILL BE LOCATED ON FIRST FLOOR NEXT TO STARBUCKS',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_ABERCROMBIE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the glass doors and you will see the just baked machine, we will go right besides it.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_GLICK_ROTUNDA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 5:30 AM to 10PM\nDriver Entrance - Front entrance of emergency department\nDriver Parking - Parking will be the same as MetroHealth Glick Lobby, as we cannot park in front of the emergency department doors\nPark To Fridge Instructions - Walk through the front entrance of MetroHealth Glick Lobby, follow hallway alongside the front of building towards the emergency department, walk past security, fridge is located near the vending wall, just left of information desk',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_2ND_FLOOR_PAVILION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the elevators to the second floor, Fridge will be to the left in the waiting area',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_MCKEESPORT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'right inside courtyard entrance',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_KIRKLAND_ELLIS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HINES_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in the pharmacy parking lot not the roundabout - enter through the emergency entrance and the Fridge is off to the right in front of the Starbucks.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB3_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter at terminal B, Walk to gate B3 and find machine across from Best Buy machine',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Grove City Sampling Event',
          id: 'OH_GROVE_CITY_HOSPITAL__5300_N_MEADOWS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Loading dock; Brian P. Snedeker 614-499-5031',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen MN Samples 3',
          id: 'MN_CANTEEN_MN_SAMPLES_3__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NJ_JEFFERSON_CHERRY_HILL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the hallway all the way down until you reach the elevator (photo attached). Take the elevator to the ground floor. Walk through the double door on your right. Fridge straight ahead.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_HOWARD_UNIVERSITY_NURSING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Park in the parking lot off of H St NE',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYU_LANGONE_KIMMEL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD B11',
          id: 'IL_HUDSON_ORD_B11__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CHASE_LOUNGE_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From loading dock head to far end of East Concourse near Gate 1 and circle around back of the Bar with neon sign. Take the elevator up to second floor and see fridges to the left of the  elevator.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CVG_CONCOURSE_B_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at CVG and find fridge next to existing unit in B concourse',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SHARP_MARY_BIRCH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building and check in. Go to the 3rd floor and take a left. Fridge will live in the corner vending room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WALGREENS_HQ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Front door of the building, using badge, you'll enter through the building and head to towards the Cafeteria. Head to your right after security",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5100_RIVER_ROAD_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Loading Dock door. Walk straight to kitchen on left and punch in key code Code is 6970. Walk through kitchen straight to cafe.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ST_PETERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SAN_JACINTO_S1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front, go in through front, fridge is in the lounge on the first floor which should be to your left when you walk in.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HACKENSACK_MEDIPLEX',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'go to the main lobby with the palm trees, fridge will go next to the coffee cafe/vending machines',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LGB3_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building, check in, and go to the breakroom on the left',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_WESTALLIS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front door turn left the fridge is in front of the coffee shop.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MORRISTOWN_MEDICAL_ER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'It is a big and complicated hospital. We will get a map of the hospital and get directions from them and will update at a later date.\r\n*** fridge moved***\r\nCardiac Dept. walk past current fridge to end turn left down hall fridge on left (Surgical family waiting)',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TN HMS Nashville',
          id: 'TN_HMS_NASHVILLE__1_TERMINAL_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Deliver to Bradford at BNA with instructions to be delivered to BNA HMS Host',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName:
            'IL_Catering (comped) - Marketing_IL_Catering (comped) - DEI_Event',
          id: 'IL_DEI_EVENT_2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Fill Fridges by the test kitchen',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen - Best Buy [Client Route - 14]',
          id: 'IL_CANTEEN_BEST_BUY__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_IAH_UNITED_BREAKROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Please see badging instructions',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Fulton SDR Sample',
          id: 'IL_FULTON_SDR_SAMPLE__2000_W_FULTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please leave in the chef kitchen cooler!',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_ARE_SCRANTON_ROAD_EXOS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CVS_130_CANAL_CHI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_1303__14200_S_BELL_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_MELROSE_WAKEFIELD_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Follow directions to the cafeteria and the Fridge is against the wall near the bathrooms.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TROY_PIZZA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park by the Atrium Entrance, machine is right inside the doors.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_25TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Check in with security',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security to your left where you'll find the fridge in the main break room cafeteria.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Google SD Samples',
          id: 'CA_SALES_GOOGLE_SD_SAMPLES__6420_SEQUENCE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'please deliver with the Google San Diego order and give to Allen',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT3_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through security, take a left into the main breakroom, the Fridge will be on the left wall across from the Canteen Avenue C',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_ST_LUKES_ENTRANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the North Entrance, walk past security and take a left towards the ER. The fridge will be directly on the left wall',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cummins',
          id: 'IN_CUMMINS__500_JACKSON_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver to 799 Brown Street, Columbus 47201 is the dock address\n \n\n Upon Arrival drivers must buzz security to open the door for them. They can then drive into the dock to unload. There is a normal truck dock to back up to and unload, or there is a lift system that loads can be moved to and then get up to the main floor.\n \n\n Once here please call delivery contact\n \n\n CODE FOR THE DOCK: Farmers Fridge - 53578',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_BHCC',
          id: 'MA_BHCC__250_NEW_RUTHERFORD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Call Jay at 617-642-2987',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FALCHI_BUILDING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_EIGENMANN__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Howard University Retail',
          id: 'DC_HOWARD_UNIVERSITY__2397_6TH_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Back loading dock - bring into the kitchen loading hallway',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: 'Howard University Inc.',
        },
        {
          opportunityName: null,
          id: 'IND_DYER_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take left past the front desk, take a right to down hallway toward  main lobby - right of the wheelchair storage alcove.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_NURSING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take front entrance into lounge on first floor',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_CHILDRENS_HOSPITAL_RB4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main entrance and turn left towards vending area',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Arrowhead Hospital',
          id: 'CA_ARROWHEAD_HOSPITAL__400_N_PEPPER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Please call delivery contact',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen- Amazon KRB6 [Route 5]',
          id: 'IL_CANTEEN_AMAZON_KRB6__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_FALK_MED',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Right insdie main entrance, straight ahead',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_ACSEVENT',
          id: 'IL_ACSEVENT__1409_W_CUYLER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL CARLY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_GOODBODY__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen MN Samples',
          id: 'MN_CANTEEN_MN__700_24TH_AVENUE_SE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: "Please deliver with Canteen MN's normal order",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'daea6a1e-aed6-4753-96b8-0a5e7f353634',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Overlook Medical Wholesale',
          id: 'NJ_OVERLOOK_MEDICAL_CENTER__99_BEAUVOIR_AVER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_METROHEALTH_BROADWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours: 7:30 AM to 5:00PM, Driver Entrance - Front/Main Entrance, Driver Parking - Park in shopping plaza lot, Park to Fridge Instructions - Fridge is located around the corner on the right side wall after entering main doors',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - MECCS North',
          id: 'MN_CANTEEN_MECCS_NORTH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'CANTEEN - Ziegler 901',
          id: 'MN_CANTEEN_ZIEGLER_901__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MI_DET_DMC_RECEIVING_VENDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Entrance hours: 7am-6pm\nMap pin:  https://goo.gl/maps/YFct1jgvrSfzrY579\nEnter at door near the end of the main entrance loop\nTurn right, and follow hallway down past the cafeteria (Red Brick Cafe) to vending area on left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_MARVIN_ENG_RECEIVING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in loading dock behind building. Park and walk up ramp and ring bell for service / security. Security will escort you to fridge. \r\n\r\nMust check in at the Receptionist Desk and show valid ID/ proof of citizenship and will be given a Temporary Badge. \r\n\r\nFollow hallway down straight ahead. Fridge will be on left hand side near "purchasing reception sign".',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_GRR_MAIN_ENTRANCE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/GKnR8uHuaZmhgWoSA\nParking is posted as \'Media/Vendor parking 30 minutes only" but we can park as long as needed. Van needs to have Farmer\'s Fridge branding or the Parking permit kept in the gray cabinet in the warehouse.\nWalk in Door 1, go left and then right to take the ramp walkway towards All Gates. When at the top of the ramp walkway go left. Elevator to the basement is on the left past the restrooms. Take the elevator to the basement. You will need to badge before pressing the button for the basement.\nExit the elevator and go left. Walk all the way down the hallway and take double door 4-9. Walk straight through to the opening an go slightly right and through the overhead door. Once through the overhead door go left and then right at the dead end. On your left go through door 43-9.\nOnce through door 43-9, go straight towards the double door - badge and enter your code then walk through to the "Sterile Area". On your right is a small desk with a black binder labeled "Farmer’s Fridge". Sign in on the sheet (if this is your first time you must sign the sheet in the left side pocket of the binder) and return the binder to it\'s place on the table.\nTurn and head the other way to the elevator. Take the elevator to level "M". Once out of the elevator, exit the hallway to the right and you will see MI_GRR_MARKETPLACE on the right. After servicing this fridge go back towards Terminal B and all the way down on the right is MI_GRR_CONCOURSE_B. Once done servicing this fridge walk back the way you came but at the end of the walkway take the right towards passenger exit. Go through the automated doors on the right and then straight until you can take the first right and you will be at MI_GRR_MAIN_ENTERANCE. Once done servicing this fridge you can leave through any exit and find your way back to the van.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd88c59d7-630c-4a9c-ae25-b1659132565c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOBBY_AIRPORT_SW_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Southwest Airlines will connect with installers to ensure things go where they need to go.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M37',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Fridge is located at the far end of T5 as it opens up to the larger area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mark Vend - Wholesale',
          id: 'IL_MARK_VEND__3000_MACARTHUR_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Canteen MN Branch Samples',
          id: 'MN_CANTEEN_BRANCH_SALES__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HACKENSACK_UMC_ATRIUM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_DALLAS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors and we will be against the left wall next to the micro-market',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ST_ELIZABETH_DEARBORN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Take the hallway to the right of the main desk until it T's, then take a right and the area is right there.",
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Prentice Catering',
          id: 'IL_NORTHWESTERN_PRENTICE__250_E_SUPERIOR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'stock coolers - In cases where the badge does not work, they have to go back to Security, and let them know to be let in or get another badge.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Northwestern Medicine',
        },
        {
          opportunityName: null,
          id: 'CA_LA_CHOC_DOCTOR_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front and check in lobby and call Kelie to get direction for lounge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_FROEDTERT_SKYWALK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the revolving doors, head straight down the pedway leading to the Skywalk Deli. The fridge is across from the entrance The fridge lock combo is 1321.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 103 – Design Ready',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_103_DESIGN_READY__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD6_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Upon entering the main front doors, please make your way to security for check-in. After going through security make your way to the main 1st floor break room. The fridge is along the main back wall of the',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_BARNES_JEWISH_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk from the current Fridge down the hall to the connected building, Fridge will be in the Plaza cafeteria',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_WEST_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NJ_TARGET_T1055__100_PARSONAGE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_GRR_CONCOURSE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/GKnR8uHuaZmhgWoSA\nParking is posted as \'Media/Vendor parking 30 minutes only" but we can park as long as needed. Van needs to have Farmer\'s Fridge branding or the Parking permit kept in the gray cabinet in the warehouse.\nWalk in Door 1, go left and then right to take the ramp walkway towards All Gates. When at the top of the ramp walkway go left. Elevator to the basement is on the left past the restrooms. Take the elevator to the basement. You will need to badge before pressing the button for the basement.\nExit the elevator and go left. Walk all the way down the hallway and take double door 4-9. Walk straight through to the opening an go slightly right and through the overhead door. Once through the overhead door go left and then right at the dead end. On your left go through door 43-9.\nOnce through door 43-9, go straight towards the double door - badge and enter your code then walk through to the "Sterile Area". On your right is a small desk with a black binder labeled "Farmer’s Fridge". Sign in on the sheet (if this is your first time you must sign the sheet in the left side pocket of the binder) and return the binder to it\'s place on the table.\nTurn and head the other way to the elevator. Take the elevator to level "M". Once out of the elevator, exit the hallway to the right and you will see MI_GRR_MARKETPLACE on the right. After servicing this fridge go back towards Terminal B and all the way down on the right is MI_GRR_CONCOURSE_B. Once done servicing this fridge walk back the way you came but at the end of the walkway take the right towards passenger exit. Go through the automated doors on the right and then straight until you can take the first right and you will be at MI_GRR_MAIN_ENTERANCE. Once done servicing this fridge you can leave through any exit and find your way back to the van.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '222_N_LASALLE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of main entrance on LaSalle, walk inside through the main rotating door, walk past security and the big staircase, the fridge is right after you pass the stairs to your left. Kind of under the stairs.\r\n\r\nDriver will pull up to main entrance and use call button. No badge needed. Fridge is located in the middle of the lobby. Vending sticker added. No deep clean needed.\r\nSecurity #312-236-1240',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HP WHOLESALE',
          id: 'TX_HP_WHOLESALE__10300_ENERGY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'GO TO THE KITCHEN BACK DOOR',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ICW_15025_INNOVATION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take a left and make your way to the break room / kitchen where we will be\r\n\r\nin addition to the badge at this location, you will need to enter a PIN number into their keypad "13345"',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_STRATFORD_HEIGHT_BLDNG_16',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the front entrance of the building to the lobby where the fridge is located next to the elevator bank',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee 1392',
          id: 'WI_HYVEE1392__675_S_WHITNEY_WAY',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            "Entry: back door in loading area w/ Recieving Sign. Walk straight in, take first left at the end of the shelves, then another immediate left go through wooden door into the recieving office. Alert them you are making a drop for Farmer's Fridge.",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_290__3625_TULIP_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TCC_NORTHEAST_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk down the pathway past the first two buildings, NSTU will be on your right, the Fridge will be just left of the cafeteria. If building isn't open please call 817-515-8911",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_NORMANDALE_CC_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Chris to guide you to fridge location',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Boston University -- Agganis Arena',
          id: 'MA_BOSTON_UNIVERSITY__925_COMMONWEALTH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in front of Agganis Arena and call Edward Brennan (617.314.5397) who will direct you on where to go.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Christ Hospital',
          id: 'OH_CHRIST_HOSPITAL_STARBUCKS__2139_AUBURN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'look for a church (Mt. Auburn Baptist Church) by the main entrance of the hospital, across from Gilman Ave. Depending which way they are coming they will either turn left or right and go down a hill by the church. Call Kevin 513-319-6749.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7e2f4eab-a59f-4ac8-b8bd-9cd29c44daef',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_THOMPSON_COBURN_LLP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'For first delivery, please call Tom DeBourge 618/803-0069.\r\n\r\nTake the main dock entrance to the elevator and notify security what floor (you will be escorted to 30)\r\nUpon exiting the elevator take the wooden door to your left out into the hallway directly back into the lunch room (the gray large freight door is not accessible from the elevator. See photos for reference.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Lake Zurich, IL',
          id: 'IL_LIFETIME_LAKE_ZURICH_IL__400_N_RAND_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T5_DEPARTURES_LEFT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_SUGAR_LAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, go through kitchen and deliver wholesale drop, then walk through to the cafeteria - the C-Store is behind a locked door next to the entrance to the cafeteria.  YOU MUST GET A CAFE EMPLOYEE TO UNLOCK THE DOOR',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kirkland & Ellis -- Boston (Wholesale)',
          id: 'MA_KIRKLAND_ELLIS_BOSTON_WHOLESALE__200_CLARENDON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Go to the loading dock behind the building (trinity place) alley in the back. Check in with security',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName:
            'CHI_Catering (comped) - Marketing_OROS CHIAGO EVENT',
          id: 'IL_OROS__2146_W_DICKENS_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver on Friday 10/21 between 10am and 4pm',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Varispace Coppell',
          id: 'TX_VARISPACE_COPPELL__450_FREEPORT_PKWY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock is 24/7. Client has requested that deliveries be made before 8am',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1851',
          id: 'PA_HUDSON_PHL_1851__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_LAW',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through main entrance make right, make left to take elevator to third floor, fridge is locate on right before cafeteria',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_10',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Canteen - Minneapolis Office',
          id: 'MN_CANTEEN_700_24TH_AVENUE_SE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please deliver the samples to the Canteen Minneapolis office branch with attention to Sam Steffen and Bryan Eckes',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CAA',
          id: 'IL_CAA__12_S_MICHIGAN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Last stop on the route please! Please call Jill upon arrival and she will direct you to refrigeration for the food',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ST_DAVIDS_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in north entrance - the fridge is directly in front of the entrance next to the coffee shop,',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NJ_TARGET_T2392__209_STAFFORD_PARK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Market Place',
          id: 'OH_OHIO_STATE_MARKET_PLACE__1578_NEIL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Loading zone on Neil Avenue that our delivery trucks use. There is a concrete ramp leading from the street to sidewalk for a dolly to use. The blue marking is where the loading zone is, the red marking is delivery door',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'The Ohio State University',
        },
        {
          opportunityName: null,
          id: 'CA_LA_GENERAL_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the front door and you will see the Fridge as you walk in',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevators up to 2nd floor starbucks area, fridge located next to starbucks',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_BAIRD_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_GRAINGER_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through loading dock door to main lobby, walk down hallway until you see the vending area, fridge in vending area',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_COLUMBIA_STMARYS_WATERTOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Memorial Hermann Sugar Land',
          id: 'TX_MEMORIAL_HERMAN_SUGAR_LAND__17500_W_GRAND_PKWY_S',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Loading dock located in the back of the hospital. Check in with security and call Brandon',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Memorial Hermann Healthcare System',
        },
        {
          opportunityName: 'American Food & Vending - San Antonio',
          id: 'TX_AMERICAN_FOOD_AND_VENDING_SAN_ANTONIO__7514_REINDEER_TRAIL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Drop off in their loading area, AFV will take it from there to their cold storage',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'American Food & Vending Corp',
        },
        {
          opportunityName: null,
          id: 'CA_SD_SOUTHWESTERN_JAGUAR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to the main door and the Fridge will live between the two entry doors',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ABBOTT_LABS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'walk in building go to floor 3',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ABILITY_LABS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on Huron Street by entrance to a garage dock, use buzzer to contact security to let you in, park at any open dock, go inside, you will walk through an elevator bank, use automatic door to walk through. Then go left - it will say "Medical Transportation Waiting" on top. Walk through another automatic door, walk up to a small security window and ask the woman for a badge to get to 12th floor. Use badge to call for an elevator, "12 Floor Cafe". Walk out of elevator and go left then right. Fridge will be on your left by cafe. Go back same way to return the badge.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_EMU_MARSHALL_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Located in the lobby near the old cafe window',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_NORTH_CENTRAL_BRONX',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Walk up Ramp. Fridge on right hand side.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_GRADUATE_SCHOOL_OF_JOURNALISM_PULITZER_HALL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk up the walkway towards the Quad area. Turn left towards Pulitzer Hall. Once in hall, walk to the center on the left. Fridge in left hand corner.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HARPER_COLLEGE_BUILDING_Z',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park outside of Building Z and walk up ramp.  Southeast corner near the Massage Therapy Clinic.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_BLANTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go in through front entrance, it's in the room to the right of the mini restaurants",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_CANTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Door hours: 6:30a-8pm\nEdit: Park at dock on south side of building and use entry/pedestrian door.\nMap pin: https://maps.app.goo.gl/Ac8bcjoXfXbXKpQq7\nFridge is inside the door immediately to the right',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Olympia Fields Hospital',
          id: 'IL_OLYMPIA__20201_S_CRAWFORD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'call contact upon arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_MEDICAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in the doors, past security desk Fridge will be straight ahead and to the left. Next to the ATM near the stairs.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Hudson PHL Samplaes',
          id: 'PA_HUDSON_PHL_SAMPLES__8000_ESSNGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver with Hudson PHL',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_DREXEL_HEALTH_SCIENCES_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock, need badge to get to elevators, take to 3rd Floor. Take a right off the elevator and a left and quick left into vending area',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_525__122_WESTMORELAND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'erins_test',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "St. Mary's Hospital",
          id: 'MO_ST_MARYS_HOSPITAL__6420_CLAYTON_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Deliveries are received at the dock on the back (south) side of the hospital off of Bellevue Ave. Driver can enter receiving door and open garage door',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_READ EL BISTRO_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1344',
          id: 'NY_TARGET_T1344__8801_QUEENS_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'CA_SD_SOUTHWESTERN_OTAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'You will go to the front door of building 2400 and enter there and see the Fridge.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ELI_LILLY_CAFE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '00ef48eb-be33-4d99-a3c4-5a8b74debb08',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WEWORK_20_W_KINZIE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_CUMMINS_MAIN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Espress-Oh Coffee Shop',
          id: 'OH_OHIO_STATE_EXPRESS_OH_COFFEE_SHOP__1739_N_HIGH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'full loading dock, someone will be there, enter from N High St entrance, loading dock is off of high st.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Drexel',
          id: 'PA_DREXEL__3210_CHESTNUT_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Emily Geschke 215.895.6228',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_TECH_TRUAX_MAIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the loading dock, walk through the door, turn right and the vending area will be on the right',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_WAUKESHA_20975_SWENSON_DRIVE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter in through the front entrance lobby and bring to the ridge in the lobby lounge',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3464',
          id: 'IL_JEWEL_3464__885_E_BELVIDERE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_WOODWINDS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the dock doors straight through to the cafeteria. Door will be on the back wall out just outside of the cafeteria.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Sun Prairie Warehouse',
          id: 'WI_COSTCO_SUN_PRAIRIE_WAREHOUSE__2850_HOEPKER',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Drive to receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'goPuff - 651',
          id: 'NY_GOPUFF_651__2505_MIDDLECOUNTRY_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CARL_STOKES_COURTHOUSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "MAP PIN: https://maps.app.goo.gl/GSCuawDGQ6udZc8a6\nHOURS - 6:30 AM to 4PM\nLocated on 7th Floor, outside of cafeteria seating\nDriver Entrance - Loading Dock/Gated Entrance - Located Off Canal Road\nDriver Parking - Security will direct you to either park at loading dock bay or on Canal Road\nPark To Fridge Instructions - Use Canal Road loading dock entrance (using the address 601 Canal Rd will take you to general area of gated entrance), present ID to security at booth and inform them that you are with Farmer's Fridge, they will direct you where to park at that point, at the top of the left loading bay is the door to another security checkpoint, pockets will need to be emptied, food/boxes/RPC's will need to be inspected, you will walk through a scanner and sign-in, once finished with security check, walk down hallway to large freight elevator on your left and take to the 7th Floor, from elevator take a right towards cafeteria, fridge is located immediately before seating area in cafeteria, when leaving, take elevator back down to L3 for loading dock entrance, no need to go back through security when leaving, just use doors that exit to the dock.",
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ONE_PIERCE_PLACE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD F8',
          id: 'IL_HUDSON_ORD_F8__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_LIVINGSTON_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge will be to the right when entering in the main entrance',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_VILLANOVA_FALVEY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through back door. Take elevator up to Lobby. Fridge is on your right in the cafe.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOAG_HOSPITAL_NEWPORT_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'must wear mask Dec 2023-Mar 2024\r\n\r\nEnter the main doors and check in as vendor. Go to the closest elevators and take to the basement. Follow the signs to the cafeteria and you will see the Fridge as you enter.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Vending area is on the north side of building',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Union Market',
          id: 'OH_OHIO_STATE_UNION_MARKET__1739_N_HIGH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'full loading dock, someone will be there, enter from N High St entrance, loading dock is off of high st.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'The Ohio State University',
        },
        {
          opportunityName: null,
          id: 'OFFICE_OF_THE_MAYOR_BREAK_ROOM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'enter through front door and checkin with security by the elevator, they will escort you to the fridge.\r\n\r\nOnsite engineer phone # 312-744-9111.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - Prysmian',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_PRYSMIAN__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEDICAL_CENTER_COMMONS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors into cafe, fridge located in cafe across from Starbucks\r\nConfirmed with Client on 6/12/24 that a badge was not needed to gain access',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the security gate, take a left and the Fridge will be straight back against the far wall',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5c6010c2-f210-4eda-be4d-6641e9cae5f4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - E Plano Warehouse',
          id: 'TX_COSTCO_E_PLANO_WAREHOUSE__3800_N_CENTRAL_EXPY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back of receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: '63576aaf-389e-424c-8e1e-b3c9113e3776',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_606__1300_S_MILWAUKEE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENSBOROUGH_CC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_30TH_STREET_STATION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Here is all the information in one email, in addition to the 2x maps.\r\n\r\nOnce truck is parked, notify Amtrak Police Department that driver is at the loading dock and will be making a delivery to the Farmers Fridge unit. I have also already told them that the delivery will be taking place after 10pm on Wednesday 5/3.\r\nOnce they clear you to enter, then go through the doors/opening on the left, taking an immediate right and the freight elevators will be straight ahead.\r\nTake the elevators to the street level.\r\nOnce there, follow the map (attached) to get to the fridge in the concourse.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_EWR7',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'They will need to show their IDs to security and walk to the dock break room.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_BMO_PLAZA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3207',
          id: 'IL_TARGET_T3207__401_E_ILLINOIS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park on the side of the store there is only one entrance. Head to the fresh market section and they will assign us a spot to stock items\n\nKeeana',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_KLOTSCHE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Entrance to the building is through the far right main entrance door on Hartford Ave. The entrance is directly across the street from the NWQ parking lot. Take elevators to School of Nursing Student Lounge, this is the 1st floor. room 167 (we think). Fridge will be on westernmost wall.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_SAMARITAN_4TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the service elevators to the 4th floor, take a right, the dixymth elevator bank will be right there.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T3280__40_82nd_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_PUBLIC_SERVICE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '-When the fridge is delivered, Chris will coordinate the opening of the double gray metal doors off the loading area for you to get the fridge into the receiving area and the freight elevator.\r\n-Take the freight up to the 3rd floor and the location of the fridge will be to the right when exiting the elevator, location marked on the attached plan.\r\n\r\nSecurity phone # 612-673-6116',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_373__1300_WASHINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_MISSION_TRAIL_BAPTIST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors straight to the waiting area which is where we will be.',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St. Louis University Hospital',
          id: 'MO_ST_LOUIS_UNIVERSITY_HOSPITAL__1212_SPRING',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Our dock is available any time after 6:00 AM. We have normal height dock bays to back up to or a ramp to the dock for hand truck deliveries. The driver will have to use the call button next to the door to gain access to the building.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENSBOROUGH_CC_SCIENCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Officer will instruct you on the best place to park (it will likely be the main parking lot) and then you can walk in through the open air seating area, take a left into the building and the Fridge will be in that vending area.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'REDWOOD_LOGISTICS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_CHOP_4TH_FLOOR_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through double doors on left side of loading dock. Turn left at first hallway and take elevator to 4th floor. Exit and turn right\r\n\r\nUntil badged- Contact will be Liz Cintron 267-721-5130 and the meeting point will be the receiving dock.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '20_S_CLARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Marketing_MHEVENT',
          id: 'DC_MHEVENT__5402_EISENHOWER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'PLEASE CALL LABARRON',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b80d5f94-28b5-4c10-84ca-4bc4672ae126',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_BRANDASSEMBLYLA',
          id: 'CA_BRANDASSEMBLYLA__860_S_LOS_ANGELES_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE BRING IT UP TO THE 11TH FLOOR',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emerson College - Lions Den',
          id: 'MA_EMERSON_COLLEGE_LIONS_DEN__122_124_BOLYSTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'PLEASE CALL GEORGE',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_HCA Medical Center - Fort Worth',
          id: 'TX_HCA_MEDICAL_CENTER__900_8TH_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Please contact the food service director to put this food into cold storage. This can be done during the regular fridge stocking hours. If the director isn't available, please give it to someone on the food service team to hold in their cooler. Hamid Zandevakili\t817-266-3724",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_USD_BOSLEY_CAFE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_EIGEN_CAFE__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'drop off in circle drive or dock, first left of Bonisteel past Murfin',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_KESWICK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter from the dock and security will escort to the elevator - take to 2nd floor',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_SLU_MEDICAL_LRC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the entrance doors, Fridge will be in the main lobby.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_LOVE_AIRPORT_MAIN_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park at loading dock and enter terminal.  The fridge is located post-security near the maggiano's in the main concourse area close to security checkpoint",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BNC U Chicago Bookstore',
          id: 'IL_BNC_U_CHICAGO_BOOKSTORE__970_E_58TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to store attendent for stocking',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_PRINCETON_UNI_PERETSMAN_SCULLY_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through loading dock. Take elevator down to B-Level. Fridge in vending Bank',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_LA Maywood Facility',
          id: 'CA_LA_MAYWOOD_FACILITY__4569_S_MAYWOOD_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Patrick to pickup at facility',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MI_Catering (comped) - Sales Mike Bazzi',
          id: 'MI_CATERING_(COMPED)_SALES_MIKE_BAZZI__740_N_MELBORN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Call contact upon delivery',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_11',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Pear Chef',
          id: 'IL_PEAR_CHEF__170_W_WASHINGTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please walk through the front of the building and call the delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Nissan HQ',
          id: 'TN_NISSAN_HQ__1_NISSAN_WAY',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'BACK ENTRY DELIVERY DOOR -RING THE DOOR BELL AND SECURITY WILL ESCORT. PLEASE NO LATER THAN 1PM',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Nissan',
        },
        {
          opportunityName: null,
          id: 'MA_AMAZON_BOS3',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the front entrance and check in. The fridge will be in the break room directly to your left. Please use Chris Jablonski as your onsite contact if you have any questions when onsite.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '744b8e90-68c0-4d00-b09a-a96347c8802f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_KEAN_UNIVERSITY_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the path by the trees to front of Student Center and walk towards the starbucks. Scan the badge to enter the the lounge. Pics Attached',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BRIGHAM_WOMENS_HOSPITAL_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter Loading Dock and take freight elevator up to floor 2. Fridge is near the bridge. If you do not have a badge, you must go through the front door at 89 Fenwood, get a vendor sticker then go over to to skyway at 75 Francis Street.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_GROVE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'the loading dock elevator goes right into the kitchen and the location of the fridge is right outside the cafeteria exit.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_TEMP_INSTALL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8cf7bf7b-2b23-4337-ad3c-ce4ac92166d0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_STAFFORD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front of building, go through front entrance, the fridge is off to the right in the vending room by the library, cant miss it',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_SE_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "go thru front entrance - turn left at first hallway and the physician's lounge is down the hallway on the left.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CHI_Catering (comped) - Marketing_ASL CHICAGO EVENT',
          id: 'IL_ASL_EVENT__1065_N_ORLEANS_ST_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver weds the 19th\nArchitectural Artifacts, Inc.\nATTN: Helen Pole\n1065 N. Orleans St.  \nChicago, IL 60610\nThursday, October 20th at 6:30pm',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson MSP 2294',
          id: 'MN_HUDSON_MSP_2294__MINNEAPOLIS_SAINT_PAUL_INTERNATIONAL_AIRPORT',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pick up orders from Bradford with Fridge orders. Deliver to hudson news in the Main concourse next to sunglass Hut',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Dallas Methodist Samples',
          id: 'TX_SALES_DALLAS_METHODIST_SAMPLES__1441N_BECKLEY_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Please deliver with the fridges food',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_WALMART__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f935d664-3f9f-4899-afd5-e7f21f145389',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Dallas Cold Storage',
          id: 'TX_COLD_STORAGE_1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Please keep at Dallas cold storage',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_Kaiser Lindsay LA Maywood',
          id: 'CA_KAISER_LA_MAYWOOD__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'LINDSAY WILL PICKUP AT MAYWOOD - SHE WILL PICKUP BEFORE 11:30AM',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CC_INDUSTRIES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Back Entrance\r\nEnter from loading dock freight elevator, walk down the hall, two glass doors on your right labeled CC industries',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_VA_BLDG_111_SOUTH_ENTRANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'enter through south entrance and see fridge down the hall on your left.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HACKENSACK_UMC_CAFE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_UBS_TRADING_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If any issues accessing the fridges, TEXT GUSTAVO 646-286-9733 \r\n\r\nFridge has to be stocked between 700am-230pm Monday-Friday. \r\nCall 212-713-4876 when you are at the loading dock because we need to be escorted to the fridge. Enter freight elevator and take it to the 9th floor. Exit elevator room and turn right. Walk down and turn right into the pantry. Fridge will be right there.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3223',
          id: 'IL_JEWEL_OSCO_3223__7523_LAKE_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'UCMED_WEST_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Park in front',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Vanderbilt - ROTHSCHILD MARKET',
          id: 'TN_VANDERBILT_ROTHSCHILD_MARKET__103_21ST_AVE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - East Village',
          id: 'IL_EAST_VILLAGE__1911_W_CHICAGO_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Webster Warehouse',
          id: 'TX_COSTCO_WEBSTER_WAREHOUSE__1310_JASMINE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TEXAS_CHILDRENS_HOSPITAL_MEYER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through front door, go past the elevators, the micromarket is in on the right after the elevator bank\r\n\r\nCall security at 832-824-2850 if there are problems',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_UNIVERSITY_OF_SAN_DIEGO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CHI_Catering (comped) - Sales_Canteen Chicago Samples',
          id: 'IL_CANTEEN_CHICAGO_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BALLANTINE_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_VA_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'call Bobby Hapchuck 412-360-6166, this fridge will be on the ground floor. same way to enter as the fridge in main lobby',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IN_Catering (comped) - Marketing_ALLSTAR',
          id: 'IN_ALLSTAR__3208_E_MICHIGAN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'PLEASE DELIVER AS CLOSE TO 10AM AS POSSIBLE!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WEWORK_1_W_MONROE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__4740_N_PULASKI',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_CARE_CRAWLEY_BLDNG_MED_CAMPUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock to the main floor lobby. The fridge is located along the wall near the Subway',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '3a2636e8-8767-4172-8384-70d1d827da5a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_RBC_MINNEAPOLIS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevators up to 21st floor. Fridge located in cafe',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7269c1ea-d495-43be-8f7a-2496a38da8fb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_7TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security. Fridge located in the Vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_BAYTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevators up to 2nd floor cafe. Fridge located at the entrance of the cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_FEDEX_CAJON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take your first left to the break room and the Fridge will be there',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_LANSDALE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'contact security before arriving for escort #215-361-4444\r\nTake elevator to ground level and walk around the wall towards the  ATM / Vending',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Princeton U Store',
          id: 'NJ_PRINCETON_U_STORE__36_UNIVERSITY_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Kurt to follow up',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: 'Princeton University',
        },
        {
          opportunityName: null,
          id: '10_S_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BU_MED_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - 12th Avenue Bread Company',
          id: 'OH_OHIO_STATE_12TH_AVENUE_BREAD_COMPANY__251_W_12TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'loading dock on 12th ave. 251 W 12th. Loading dock is about 50 yds east of Neil Ave. Ring doorbell, after 1-2 mins someone will answer the door',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'The Ohio State University',
        },
        {
          opportunityName: 'CANTEEN  - Minnetronix',
          id: 'MN_CANTEEN_MINNETRONIX__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'OH_COLUMBIA_PLAZA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_VILLANOVA_DRISCOLL_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through glass doors. Take the elevator up. Walk around the from the cafe.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_QUEENS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Sign in with security. Fridge can be seen in the same hallway.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BROOKLYN_BOULDERS_QUEENSBRIDGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Use front entrance to get inside.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CONDE_NAST_PUBLICATIONS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_BUTLER_SQUARE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building through the loading dock man door, walk through the double doors from the loading dock into the office building, Farmers Fridge will be located directly in front of you.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5a78b96f-3720-4f95-b729-ea071ecde722',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - sales_Hudson DFW Samples',
          id: 'TX_CATERING_(COMPED)_SALES_HUDSON_DFW_SAMPLES__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Drop samples to Store across from A12 and mention that these are samples',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_NORTH_DINING_HALL__1_N_DINING_HALL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock is located on the north end of the building right next to the fire station',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_10 Light Street',
          id: 'MD_10_LIGHT_STREET__10_LIGHT_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Bring to front desk and ask for Brett',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'STERICYCLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Mt Prospect Warehouse',
          id: 'IL_COSTCO_MT_PROSPECT_WAREHOUSE__999_N_ELMHURST_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'PEAK6',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge is located on the 5th floor (Suite 500). Driver will scan badge immediately to the left of freight elevator. They will call security at 312-435-7180 for loading dock access. \r\n\r\nFrom dock to floor 5:\r\nMain Office # 312-444-8000\r\n2nd contact: Ilma Gjini (office manager) 630-625-3256',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f2da9e61-2474-4ace-ba2e-8bfb1e01b200',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_RECENT_FRIDGEallcustomerorders',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3249',
          id: 'NY_TARGET_T3249__500_14TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'b2a0fa90-6a57-49d9-863f-6ac7cef58de9',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TRAVEL_WIFI_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door go to elevator bank straight ahead, go to floor two, take a left then another left and breakroom is straight ahead',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_DISTRICT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Show Driver's License upon arrival. \r\n\r\nTake freight elevator to the lobby. Walk towards Nordstrom Rack",
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HARBORSIDE_3',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Main entrance side doors or revolving door should be open. If not there is an access panel to the far right of all the doors. Ring bell and someone will open door. Fridge is straight to the back of the building  towards the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_SOUTHEAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, follow the signs to the cafeteria, the C-store is outside of the cafeteria',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_LIBERTY_TOWERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host - Farmers Market',
          id: 'IL_HMS_HOST_OHARE_PILOT__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            "Drop to store attendant at Farmer's Market store located in the Rotunda at the entrance to Concourse G in terminal 3 Ohare.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UH Sugarland - Brazo Hall',
          id: 'TX_UH_SUGARLAND_BRAZO_HALL__14004_UNIVERSITY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Enter through the main entrance. If possible, please leave a few forks and the paper boats.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WILLIS_TOWER_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDX7',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Hit the green button to be let in, Fridge will be in the break room on the working floor',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ICNC_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Park at loading dock on Carrol Ave, using your keycard, open the freight elevator and take to floor 3. Turn left out of the elevator and it'll be right there.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3347',
          id: 'CA_TARGET_T3347__1833A_LA_CIENEGA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Hudson PHL 1855',
          id: 'PA_HUDSON_PHL_1855__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_MANDALAY_TOWER_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'ATTN: FRIDAY Stocking - please leave any food removed from the fridge on Friday stocking next to the Fridge. The client would like to keep the food.\r\n\r\nLoad into elevator, down to ground floor, turn left, break room on right at the end of the hall\r\n\r\nSecurity phone # 214-502-5800',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_INTERNATIONAL_PLACE_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The drivers should first check in with the One International Plaza security desk (main entrance on Oliver Street) then proceed with delivering through the side entrance (also on Oliver Street) that is located next to the Starbucks. The drivers do not need to be badged but will need to check in with security- Security has been informed that Farmers Fridge deliveries have access to the fridges 24/7.\r\n\r\nEnter side entrance on Oliver Street (next to Starbucks) and immediately turn right through the glass doors. Fridge will be directly on your left.  (map attached in Salesforce)\r\n\r\nSecurity phone # 6173305227',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_A15_BAGGAGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_NEWTON_WELLESLEY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the East elevators (NOT the south elevators which are the first set of 3 you will see). The East elevators are down the hall in the basement. Take the second right and go past the center elevators and they are next to CSR. Take the EAST elevator to the 1st floor and take a right.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '123TEST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCHI_ATHLETIC_CEN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_2ND_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security. Fridge located in the lobby.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'BOSKRB01',
          id: 'MA_BOSKRB01__290_WILLIAM_F_MCCLELLAN_HWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Dropping to bradford for delivery to Kelly Roast Beef (BOSKRB01)',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'Costco - McKinney Warehouse',
          id: 'TX_COSTCO_MCKINNEY_WAREHOUSE__3650_W_UNIVERSITY_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Onsite News - BWI Charm City Market',
          id: 'MD_ONSITE_NEWS_CHARM__107_AIR_CARGO_SERVICE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Deliver to Bradford loading dock with instructions that product is to be sent to Charm City Market D side.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_DCA_MAIN_TERMINAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through DCA Loading dock, walk to main terminal find fridge on the left hand sif=de',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - CAP [Client Route- 10]',
          id: 'IL_CANTEEN_CAP__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_SCHOOL_OF_INTERIOR_DESIGN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in through main entrance, take elevator to basement',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_SUFFOLK_UNI_1_COURT_STREET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once parked the Fridge is right inside the main entrance on the right, right next to security',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDF5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the associate entrance, Fridge will be across the breakroom from the door',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_SPECTRUM_RUTLAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go through front doors, the cafe Is directly in front of the front desk, the fridge is in that room',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Compass Group',
          id: 'NJ_COMPASS_GROUP__190_HOMESTEAD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Alex 732.570.2036',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_HOXTON',
          id: 'IL_HOXTON__200_N_GREEN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL MARISSA',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Google Corporate',
          id: 'NY_GOOGLE_CORPORATE__111_8TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Park in loading dock on 16th street and 9th ave. Call Nick at 917-670-2376',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_FIRESIDE_CAFE__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'drop off in circle drive or dock, first left of Bonisteel past Murfin',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_QUEENS2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter front entrance walk to the left past security then turn right. Follow signs for Blue elevators. Take them to the basement. Exit elevators turn towards the only hallway, turn right, then left. Enter door on the left for the cafeteria. Follow to the right towards the seating area. Fridge will be on the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Allen, TX',
          id: 'TX_LIFETIME_ALLEN_TX__971_STATE_HWY_121',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Plano, TX',
          id: 'TX_LIFETIME_PLANO_TX__7100_PRESTON_ROPAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '74e44e82-40d5-4c13-aabe-69fe602a7450',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_NFI_INDUSTRIES__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MUSEUM_SCIENCE_INDUSTRY_VENDING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge it’s located on the lower level vending room - east side of the building by the escalator.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Test Wholesale Locations',
          id: 'IL_WHOLESALE_TEST_T0001__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Arrive and call point of contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LUTHERAN_UNIVERSITY_SWENSON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the main lot and the building will be visible from the lot. Walk to the building, go in the front door, and you will see the Fridge. Can be seen from outside as well though glass windows.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DashMart NYC',
          id: 'NY_DASHMART__441_4TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_RachelMN',
          id: 'MN_RACHELMN__1758_W_LANSFORD_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'please call Rachel',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_312__1725_HUBBARD_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - UC WCH',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Amazon DLN2 [RT 22]',
          id: 'IL_CANTEEN_DLN2_RT_22__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'DROP OFF',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_SPRING_HOUSE_BUILDING_7_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park, go into building and it’s on the left side',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DUPAGE_SSC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in door to service the other Fridge in the HSC, walk South down the hallway until you get to an open area near the starbucks - we are in the lower level',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Haggerty Warehouse',
          id: 'MI_COSTCO_HAGGERTY_WAREHOUSE__20000_HAGGERTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to back near receiving. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'OH_MEDPACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building from the garage parking double glass door, step in and on your left you will see glass door and vending room where the fridge will be located - you will need call security to enter the vending room or badge from the client.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_BAYLOR_ST_LUKES_MCNAIR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the dock and into the cafeteria kitchen, take a left when exiting the cafeteria and you will see the vending.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales Notre Dame of Maryland University',
          id: 'MD_CATERING_(COMPED)_SALES_NOTRE_DAME_MARYLAND__4701_NORTH_CHARLES_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Call Allie Rockstroh 410-532-5782 upon arrival',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Wholesale - Boston',
          id: 'MA_ARAMARK_WHOLESALE__300_FENWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "2 dumpsters- door right of that- freight elevator- receiver will be there \n\nSIMMONS UNI- CLIENT WILL BE DISTRIBUTING THE REMAINDER OF THE PRODUCT TO 5 OTHER UNI'S",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CVS_101_CLINTON_CHI',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Huntington Hospital Wholesale',
          id: 'CA_HUNTINGTON_HOSPITAL_WHOLESALE__100_W_CALIFORNIA_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'West tower loading dock off of Pasadena avenue.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELMHURST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson LAX 1249 TBIT T-63',
          id: 'CA_LA_HUDSON_LAX_1249_TBIT_T_63__1_WORLD_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Bring food to Hudson stock room near Hollywood Reporter store across from gate T63. Hudson Employee/manager at Hollywood reporter store will assist in finding stock room!',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_TABLER_ARTS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_SMU_HUGHES_TRIGG_STUDENT_CENTER__3140_DYER_STREET',
          id: 'TX_SMU_HUGHES_TRIGG_STUDENT_CENTER__3140_DYER_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Loading spot on the side of the building',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_NEWTON_WELLESLEY_AMBULATORY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the back entrance and head through the center towards the front of the building. The alcove will be on your left across from the waiting area.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Z's Good Vibez Juice Bar Allen",
          id: 'TX_Z_GOOD_VIBEZ_JUICE_BAR_ALLEN__950_W_STACY_ROAD_SUITE_140',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'call delivery contact if you have questions',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: 'Z’s Good Vibez Juice Bar',
        },
        {
          opportunityName: null,
          id: 'OHARE_T3_UPPER_L2A_K1',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Located in terminal 3 across from Gate K15',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CAMP_MABRY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through the front doors and we are directly to your right in the cafe sitting area',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DTW_EVANS_BAGGAGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/4tyZTGxdApTjUjd66?g_st=ig\nPark on the right side of the road among the cars queued in the loading lanes. Optimize proximity by parking as close as possible to "DOOR 4." Choosing this entrance streamlines access to the fridge without navigating through a crowd. Upon entering, identify Spirit Baggage Claim in the distance through its distinctive yellow signage. Proceed towards it, and you will find the fridge situated on a pillar to the right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BOULDERING_PROJECT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Schaumburg Warehouse',
          id: 'IL_COSTCO_SCHAUMBURG_WAREHOUSE__1375_N_MEACHAM_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by receiving - off he floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: '7a415949-deb3-40d0-abb1-8fdf6f08910d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RELATIVITY_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_LIBRARY_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address is 801 S. Morgan. Park in front walk through the main entrance past the desk and turn right. Fridge is about 20ft in seating area against the window.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Pondview Plaza',
          id: 'MN_CATERING_(COMPED)_SALES_PONDVIEW_PLAZA__5850_OPUS_PARKWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Must be delivered before 11:30am- DC will direct driver where to go. Suite is on 3rd floor',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BELLEVUE_15TH_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_UPENN_WHARTON_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Hit the buzzer to be let in. Turn left out of double doors. Fridge is down the hall to the right.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_HOSPITAL_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Come through main entrance past security desk, straight past Cafe in the guggeinheim pavilion, make right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MORRISTOWN_MEDICAL_MATERNITY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'It is a big and complicated hospital. We will get a map of the hospital and get directions from them and will update at a later date.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_SUENOS2',
          id: 'IL_SUENOS2__299_E_BALBO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL ALAYA',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TTI_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the lobby, obtain visitor badge. Walk down the hallway into the cafe, turn right into the market area. Map uploaded in files.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_IVY ROOM',
          id: 'IL_IVYROOM__12_E_OHIO_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL ABBY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 5',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_5__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'de2b376b-13c9-4377-9d71-94379fa6a692',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_ARLINGTON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Arrive and park via loading dock on back of the building. Ring doorbell and wait for escort. \r\n\r\n"Main contact \r\nKaleb Newman 318 680 9135\r\n\r\nSecondary Contact\r\nJohn 682 509 3660 \r\nSarah 817 271 6427"\r\n\r\nFridge is located in the Cafeteria',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_DEPAUL',
          id: 'IL_DEPAUL__1_EAST_JACKSON_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'PLEASE CALL ERIK UPON ARRIVAL! AND PROVIDE FORKS IF POSSIBLE! 443-987-4773',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_807__2680_BROADWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_NURSING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in front entrance, location is to the right, where the other vending machines are located',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ST_LUKES_SUGAR_LAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the main hospital doors, take a left, and walk to the end of the building where the cafeteria is at. The fridge will be visible',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Procter & Gamble - Procter & Gamble HQ',
          id: 'OH_PROCTER_AND_GAMBLE_HQ__1_PROCTER_GAMBLE_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Please bring the order to the loading dock at the rear of the facility of Corporate HQ',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Procter & Gamble',
        },
        {
          opportunityName: 'CANTEEN - Graco Tech',
          id: 'MN_CANTEEN_GRACO_TECH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_CARTAMUNDI',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Will have an escort each time\r\n\r\nCome through the loading dock, go straight, and the break room entrance is on the right, turn left in the break room and it will go on the wall straight back',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIEND_HEALTH_COTTAGE_GROVE_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver may ask for Hurschell or Michael, or security can show them to the 2nd floor employee lounge.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DAL2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through the security gates, take a left, breakroom will be straight ahead of you',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Diego Samples ( Austin )',
          id: 'TX_DIEGO__16400_BRATTON_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Diego 346-269-8473',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'PRESIDENTIAL_TOWERS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LGB3_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building, check in, and go to the breakroom on the left',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WALMART_FULFILLMENT_CHINO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main door by the loading dock and check in with security who will then take you to the Fridge in the break room',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_222LASALLE',
          id: 'IL_222LASALLE__222_N_LASALLE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please call Victoria',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JAVITS_DOD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_PANORAMA_MED_CENTER_MOB2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park across street from MOB2. Use ramp to go up to double doors. Enter through double doors down hallway. Take first right and then left towards atrium. Vending bank is on right hand side and fridge is located there.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_USD_PALOMAR_LOUNGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHROP_GRUMMAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '" Your driver will check in with the Security officer on duty who will issue a temporary visitor badge for your driver’s use while they’re in the facility. This allows them to walk around our site without being escorted by someone. In the event that your driver and back up driver are both out of commission, we can always arrange to escort a third individual if need be.\r\n\r\nDriver will enter through Security operation center door behind building. Exchange ID for badge, walk through double doors, take first left and continue to walk all the way down hallway, take another left and continue down hallway. Fridge will be on left hand side. Return badge prior to security prior to departing.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '35757916-c03e-4359-938c-e22ad98579c4',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_56__1156_MAPLE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TAYLOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If after 7:30am: https://goo.gl/maps/8iNekkq7FcHsbygo8\n\nEnter through the "Hospital \'Main\' Entrance"\nFridge is inside to the left.                                                    If before 7:30am: Enter through West Entrance. Take the hallway to the left of the security desk. Almost immediately on the left is an elevator. Take the elevator down to "LL". Exit the elevator and go left. Follow the hallway all the way down towards "Main Entrance" and follow the hallway to the right where there will be an elevator bank. Take the elevator back up to Level 1. Exit the elevator and go straight through the glass tube hallway towards the Main Entrance. Machine will be on the right.\nWest Entrance map pin:\nhttps://maps.app.goo.gl/HfySZZ1bL2vCzFLF9',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Kirkland and Ellis',
          id: 'NY_SALES_KIRKLAND_ELLIS__601_LEXINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading dock is located 54th st between 3rd and Lexington. Check in with security and go up the freight elevator to the 42nd floor.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Whole Foods',
          id: 'TX_WHOLE_FOODS__550_BOWIE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1191',
          id: 'NY_TARGET_T1191__255_PONDPATH_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'IND_8711_RIVER_CROSSING_BLVD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_MADISON',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host - Austin Central Market',
          id: 'TX_HMS_HOST_AUSTIN_CENTRAL_MARKET__3600_PRESEDENTIAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Deliver to Bradford with instructions to be sent to HMS Host Central Market',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FRIED_FRANK_23N',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If delivery during day, call ext 5389 on the service elevator phone bank, or call 917.733.6234 - Melvin Whitaker to be let up.\r\nIf stocking at night, call 212.859.4731 and security will let them up',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JEFFERSON_WASHINGTON_TOWNSHIP',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Dial 001 OR 002 to be let in by the kitchen. Walk through the doors and turn left. Walk all the way down the hall towards the elevators. Fridge is on the right.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_DCA Donations',
          id: 'VA_CATERING_(COMPED)_SALES_DCA_DONATIONS__2401_RONALD_REAGAN_WASHINGTON_NATIONAL_AIRPORT_ACCESS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Deliver to arrivals area "Survivors pickup" call carol upon delivery',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Dulles International Airport (IAD)',
          id: 'VA_DULLES_INTERNATIONAL_AIRPORT_IAD__44844_AUTOPILOT_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'ALM Services, Llc\r\nDC3 B terminal',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COLLIN_COLLEGE_FRISCO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors take a left, Fridge will be at the end of the hallway in a welcome room',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Maspeth Federal Savings',
          id: 'NY_MASPETH_FEDERAL__56_18_69th_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call Teresa Pikner at 718 335-1300. Ask for Teresa or April',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WOMENS_PAVILLION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Greg Andrews or 713.876.8585 or Cesar Falconi – 281-512-0146 for access. We are not badged for this location so we need an escort.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_FCIF',
          id: 'NY_FCIF__225_LIBERTY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'please call Kathryn upon arrival!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1b595ebd-7a35-4c0f-b1f7-416e9edede93',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JOHN_THEURER_CANCER_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go through the lobby floor, go down towards the cafe section, fridge will go across from it.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson News - Dulles',
          id: 'VA_HUDSON_NEWS_DULLES__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_ESCONDIDO_POLICE_DEPT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Working on badges as of 7/1: In the interim, if we can have the driver contact our dispatch at the non-emergency line of 760-839-4722 and have an on-duty supervisor meet with them to facilitate the restock process during the next two weeks.\r\n\r\nEnter the front door and make your way to the Northwest side of the building. The Fridge will live in the main hallway',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_ARE_CAMPUS_POINT_EXOS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_DEARBORN_2ND_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Located on the 2nd floor of the Fitzgerald Pavilion elevator bank, elevator access in basement left of the loading dock',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CSU Long Beach - Catering',
          id: 'CA_CSU_LONG_BEACH_CATERING__6049_E_7TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'please call DC',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: 'California State University System',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_WORLEY_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in rear entrance to building, go through main doors and check in with security, the fridge is in the cafe room on the west side of tower 1',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1871',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_PRATT_HIGGINS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_HICKMAN_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in main entrance, turn left, vending bank is to your left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HOLY_CROSS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_304__810_31ST_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LAHEY_HOSPITAL_EAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in the parking lot and walk in the 2 East entrance. Fridge is right in the entrance near surgical wing.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BALLANTINE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_22ND_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security, then walk to vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LONG_BEACH_MEMORIAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front lot / roundabout. Enter through automatic doors, and go right. Check in at main desk. Go right of security and go to elevators. Go down to main level. Cafeteria is to the right and will be located in the left.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_UNW__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FULTON_WEST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front and enter through main entrance with badge. Walk down hallway towards the front desk and turn left.  Swipe badge to go through security glass turnstile and fridge is straight ahead about 30 feet against the wall on the right side. Fridge can be seen from Ada street but can’t be accessed from Ada entrance.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_RICE_MCMURTRY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the side of the building into the common area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_WORKHUMAN_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Enter through the badging area to the left of the loading dock. Head straight from that entrance until you hit a wall. Turn left and enter that room -- the Farmer's Fridge will be on the left side. Use drawn map if needed.",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - RMS Upstairs',
          id: 'MN_CANTEEN_RMS_UPSTAIRS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AMAZON_PIT9',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the front entrance to the main breakroom on the main floor',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen- Target DC Joliet [Route 14]',
          id: 'IL_CANTEEN_TARGET_DC_JOLIET__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_SOUTHEAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk through front door, we are right in the entrance next to the coffee shop',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Amazon MDW5 [Client Route - 7]',
          id: 'IL_CANTEEN_AMAZON_MDW7__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__3374_W_DEVON_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COLLIN_COLLEGE_MCKINNEY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk down the side walk to the student center, Fridge will be past the information desk in the main hangout area',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_HENRY_FORD_WYANDOTTE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin: https://goo.gl/maps/uUW88xSQVpodejdM9\nHours: 5am\nPark in beginning of loop at main entrance.\nEnter through main entrance, keep right. Walk past the Starbucks, follow signs for "Henry\'s On The River"/Cafeteria.\nAt the end of the hall, turn right. Then take a left into the cafeteria seating area. Fridge is on the right hand wall.\nIf lost, just as for directions to the cafeteria.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_WAKEFERN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'go to the right then the left, go to main/IT break room. They will show you the first few times.\r\n\r\novernight contact: Cristian Navas, (973)902-7242',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_IAH5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the loading dock and go to the main breakroom located in the middle of the facilities to the vending area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3334',
          id: 'NY_TARGET_T3334__1863_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'CA_LA_LUTHERAN_UNIVERSITY_GILBERT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'You will walk into the back of the building and down the hallway to the main lobby. The Fridge will be visible as you exit the hallway.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AMAZONGO_ICH6_ONETWOPRU',
          id: 'IL_AMAZON_GO_ICH6__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'pickup at cicero',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: 'LIU Brooklyn  - Wholesale',
          id: 'NY_LIU_BROOKLYN_WHOLESALE__1_UNIVERSITY_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            "Go to loading dock - contact security and say the delivery is for Aramark. If you don't get security, call 718-246-6468 and say we are delivering for Jennifer Benjamin",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Long Island University',
        },
        {
          opportunityName: 'Ritual NYC Event',
          id: 'NY_RITUAL__177_MOTT_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_PROGRESSIVE_FIELD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 24/7, Unless there is a home game, then we need to be done with delivery at least three hours prior to start of game\nDriver Entrance - 758 Bolivar Road will take you to security entrance, check-in with security\nDriver Parking - Security will direct you where to park, it may vary each time, but will be in the same general area down the ramp\nPark To Fridge Instructions - After checking in with security and parking, check-in with security office loated up ramp, they will provide a visitor badge, walk to red freight elevator, take  up to the main concourse level, fridge is located in section 134 near seating area',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_NJ Hudson Corporate 2',
          id: 'NJ_HUDSON_CORPORATE_2__1_MEADOWLANDS_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'deliver to lobby at Hudson Corporate office through contact Lissette, she will meet you in the lobby upon call',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_9',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_SBS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'POC doesn‚Äôt have access to loading dock .. so I couldn‚Äôt get directions on how to get to the fridge. Fridge will be next to currency exchange and across from New York Sports Bar.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_SCIENCE_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the entrance on the west side of the building and take the elevator to the second floor. The room is located right when you get out of the elevator to your left.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_SOUTHWEST_TOPS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through the main doors, walk straight to the cafeteria and micro-market, we will be next to the coffee bar',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TRENTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Hours: After 6am\nMap pin:\nhttps://goo.gl/maps/deYBSHKHeiAEnUzX7\nEnter through the main entrance.\nProceed all the way down the hallway, past the first set of elevators.\nTurn right at the end of the hall and take the elevators ahead on the left up to "2".\nTurn right off the elevator, and the fridge is just ahead on the right near the Cardiac Cath Lab.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOYOLA_MEDICAL_OUTPATIENT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use general outpatient entrance, walk straight down till you reach the cafeteria and the Fridge is on your right. If the door is locked you will need to call security at 708-216-9077',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_LAKEHOUSE_HEALTHCARE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call intercom on the left to get buzzed in, walk straight through doors, turn right at hall, 2nd door on right, fridge will be in the sitting area',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8287bd16-0d06-46e8-82b6-3ea548cc647a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_GALLAGHERWAY',
          id: 'IL_GALLAGHERWAY__3635_N_CLARK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please deliver to the Fulton office',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_3100_MAIN_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the loading dock into the building, someone will escort you to the cafeteria in the first floor.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3372',
          id: 'NY_TARGET_T3372__160-08_JAMAICA_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OAKBROOK_TWENTY_TWO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter in that back door and fridge is to the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SUNSTAR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_541__133-40_79TH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Marketing_BrighamWomens',
          id: 'MA_BRIGHAMWOMENS__89_FENWOOD_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Attn: BWH Food Services',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_LongBeachVA',
          id: 'CA_VA_LONGBEACH__5901_E_7TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Call Jayne 6085127731',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SYMMETRY_MANAGEMENT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the building and go to the main elevators where the Fridge will be visible',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_HARVARD_SCIENCE_ENG_COMPLEX',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside 150 Western building (SEC), up the couple stairs, elevator bank is on the left. Take to 3rd floor, and walk along the right wall back 50 steps and on your left, enter in Room 3.313 If they ever are unable to access the dock, the driver should call security (in advance of arrival if a holiday or academic break) and Security will let them in to stock 617-495-2078 or 617 999 6841',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Sarah Samples',
          id: 'IL_SALES_SARAH_SAMPLES__5859_N_MERRIMAC_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please deliver and call Sarah',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_POLY_PREP_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk from Parking Lot to Novogratz Center Entrance. Set up a time period with Security. The rear gate at 9216 7th ave has a guard posted in the booth 24/7. Call security at 718-663-6083 with any issues.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BCH_LOBBY_DOUBLE_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver can contact Bill Toth when they arrive (617-308-7984).',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_1allcustomerorders',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_GM_COLE_ENGINEERING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Call Adam with ETA.\nAdam Chandler  (248) 996-0681\nEnter the GM technical center from Entrance #11 on Van Dyke.\nStop at the guard shack, and say you're with Farmer's Fridge to stock the vending machine. If you are questioned, say we were told to enter this way by Joe with Continental vending.\nPark in the fire lane next to the other vans/box trucks. Adam will escort you inside via the steel door to the right of the main door. You will then also be escorted back out after stocking.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_ASL Event',
          id: 'IL_ASL_EVENT__1065_N_ORLEANS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Maura Holladay, 734-476-1607',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_ABINGTON_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through Double Doors towards the kitchen and take the freight elevator up to the ground level. Go right out of doors take another right and walk all the day down the hall towards the Widener lobby. Fridge is by the Gift Shop.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WBEZ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'See video attached. Building is under renovation so it can be tricky to get up to office space.  Photos of entrance attached as well!',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '205_W_RANDOLPH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_NKU_CALLAHAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the main entrance main hall passed the pool table -> tv area.\r\n\r\nFor Callahan hall, we do have a key box on the back loading dock (where deliveries are brought in).  The key box code is 19696',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIVERSITY_ST_FRANCIS_TOWER_HALL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GLANBIA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The cafeteria is directly inside the badging area which is where the Fridge will',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BABSON_REYNOLDS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through either the main or side entrance and walk towards the green pillars across from the grab n go market.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3307',
          id: 'IL_TARGET_T3307__1664_W_DIVISION_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Sign in at the front entrance and put the items in the produce refrigerator\n\nAsk for Lupe',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'WI_Catering (comped) - Sales_Canteen Milwaukee',
          id: 'WI_CANTEEN_MKE__5400_S_Westridge_Dr',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Use given fob that was previously picked up to get into the office. Upon arrival the driver can proceed to the East side of the buildings there will be multiple truck docks, but can back into dock six if they are using a dock height truck.  If truck is no',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_LUDDY__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T5_G7',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock.  Enter and walk straight down to security check point.  Must have everything screened by TSA and also wait for an escort to bring us into the terminal.  Either one of our contacts at the airport or a Third Party security service.  Walk through the area with the escort.  You will exit a door in front of Gate 7.  Turn left, and follow the wall on your right.  Fridge will be on your right side across from Gate 21.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HOPKINS_OFFICE_0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_4500__1340_S_CANAL_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '337f18e5-0a1a-4faf-8773-4ea25e37c3c5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_194_WOOD_AVENUE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock, follow the long hallway towards the main entrance of the building. Exit past security desk, fridge is in lobby of the building.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'dd7e3d7d-b039-4815-b352-e664a1287633',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOVELL_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in main entrance, immediately turn left and follow hallway to elevators at end of atrium hallway, Fridge is behind stairs in front of elevators',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1176',
          id: 'IL_TARGET_T1176__1700_E_RAND_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Check in to the loading dock and put all the food in the produce cooler\n\nJesse 847-4521156',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: '3e3a00b7-2db0-41ab-821a-3148ed65bac3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNIVERSITY_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_GCFD',
          id: 'IL_GCFD__4100_W_ANN_LURIE_PL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'DONATION TO GREATER CHICAGO FOOD DEPOSITORY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WRIGLEY_BLDG',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1139',
          id: 'NY_TARGET_T1139__999_CORPORATE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'UnitedHealth Group - Optum',
          id: 'MN_UNITEDHEALTH_GROUP_OPTUM__11000_OPTUM_CIRCLE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please park at loading dock north of the entrance. Large sign highlights Loading Dock.s\nPlease call Kelly Lawler at 5072020316 upon arrival.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_IKEA_BUILDING_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "You'll be escorted into the breakroom. Please call POC.\n\nGo through the dock into the breakroom.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_TEST',
          id: 'IL_TEST__1234_N_FRIDGE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please call george',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_DREXEL_HAGERTY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through doors and walk towards the front desk, lounge on the left.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HACKENSACK_MATERNITY_WARD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: '4th floor maternity atrium',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LIBERTY_BROOKLYN_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the main entrance and the fridge will be on the right side of the lobby when facing the Saks Off 5th Ave entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7bfbbde5-ae25-4170-a247-8498a4b7963f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_NU_INTERNATIONAL_VILLAGE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'USPS_CHICAGO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the ramp near bay 1 at east dock. Walk through the doors, take a right, take first left through the doors, take a left. Take pedestrian elevator on the left to floor 3. Walk straight and turn right. Walk all the way down the hall, take last right and walk through double doors. Turn left and walk past elevators and then turn left through the doors. Cafeteria room will be on the right down the hall. Fridge will be first machine on the left when you walk in',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ADM_KENTUCKY_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Take main doors to cafe area',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_HOLY_NAME',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FEDERAL_RESERVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NEW_SCHOOL_55_W_13TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'CC to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ELMHURST_UNIVERSITY_FRICK_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use elevator and take it to level K, Quick right, quick left then seating area is on your right through double glass doors, Northwest corner of seating area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_SPEA_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOYOLA_UNIVERSITY_SCHREIBER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_ONE_MONUMENT_PLACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Take elevator down to basement',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_JUNE5-EDDIE-SALES',
          id: 'TX_EDDIE__120_BFG_9000_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'eddie to pick up',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Target Stores',
          id: 'PA_TARGET_T3263__1020_BROAD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HYATT_MCCORMICK_PLACE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Northwestern Mutual HQ Wholesale',
          id: 'WI_NORTHWESTERN_MUTUAL_HQ_WHOLESALE__805_E_MASON',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Loading dock delivery address is 825 E. Mason St. Loading dock is located on the corner of Mason and Marshall. Pull up to the door- security will open. Drive straight down the ramp. Upon arrival check in with security and they will contact Joseph meet you',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ST_VINCENT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IN_Catering (comped) - Sales_Federal Plaza',
          id: 'IN_FEDERAL_PLAZA__5400_FEDERAL_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please call Tom',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e9075657-9dcc-4fa7-afa6-191772ab7116',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST FOR TECH',
          id: '313221',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_OSCO_68__4_E_OGDEN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 4 DexCom Canyon',
          id: 'CA_CANTEEN_VISTA_RT_4_DEXCOM_CANYON__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_PUBLIC_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge is good for the front entrance over night otherwise the dock should be available after 6am. For front entrance access, swipe badge for front door, walk in past the desk, turn right and fridge is about 10 feet away.\r\n\r\ndry run 8/18: 1601 W. Taylor. Badge is good for the front entrance over night otherwise the dock should be available after 6am. For front entrance access, swipe badge for front door, walk in past the desk, turn right and fridge is about 10 feet away.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_CA_Catering (comped) - Sales_Avolta SAN Sample Pack',
          id: 'CA_CATERING_(COMPED)_SALES_AVOLTA_SAN_SAMPLE_PACK__7056_CONVOY_CT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Bring to rear delivery area with door marked for Hudson',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ACADEMY_SPORTS_BUILDING_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at guest parking. Go to front desk to get badged, procced to the dugout deli.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_UPENN_BRB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '909_DAVIS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge is located in the main lobby - enter through front entrance',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SCHWAB_REHABILITATION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'You can access Schwab at the California entrance on 1400 S California. There is a security guard posted at all times. Walk through the front doors, turn right and the fridge will be there against the wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Northeast Team Samples',
          id: 'NY_NORTHEAST_TEAM_SAMPLES__122_WEST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'building pin is #198922, take elevator up to 4th floor, drop in front of #4P. If needed contact number is 917-444-2722',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3284__33_E_ST_CHARLES',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_GOOSE',
          id: 'IL_GOOSE__1800_N_CLYBOURN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL HENRY UPON ARRIVAL',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ST_LUKES_VINTAGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the kitchen and take a right at the fridge.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AIRPORT_EAST_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in loading dock and walk to east concourse. The fridge is located in the vending bank .',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AMAZON_AUS2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front, badge in through front desk, the main breakroom is on the 1st floor on the LEFT when you walk in.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Nashville International Airport - Urban Juicer',
          id: 'TN_NASHVILLE_INTERNATIONAL_AIRPORT__1_TERMINAL_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_229__2839_SOUTHAMPTON_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Adventist Cancer Institute',
          id: 'IL_ADVENTIST_CANCER_INSTITUTE__1_SALT_CREEK_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver to COFFEE SHOP',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Marketing_Paramount Pictures Cafe',
          id: 'CA_PARAMOUNT_PICTURES__5555_MELROSE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Call Jayne 6085127731; Closest to 1030am as possible',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB_SATELLITE_2_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through security in satellite B2 and the fridge is right there',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_SHIELD_AI',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the front, check in at security, go through the doors, take the first right and the breakroom will be to your right',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CCNY_MARSHAK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MITCHELL_HAMLINE_LAW',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'After parking in the Portland Street parking lot, the team can enter the buildings through entrance E9.  They will head straight east into the 1931 building and see the food service area on their right.\n\n**also see maps attached for reference**',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JAVITS_INTERNAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_TEXAS_STATE_ROUND_ROCK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door - turn left, fridge is right there',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWHEALTH_UNIV_HOSP_CAFE_1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the main cafe, fridge is located in the cafe',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ROBINSON_HELICOPTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Once parked please enter into the main front entrance of the building take the main door towards security. Announce you're with FF and upon entry take your first right. The machine is located along the right hand wall across from the bathrooms where the vending bank and other machines are located.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_CROWN_POINT_FRANCISCAN_GRECCIO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Take signs to "cafe"',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b56cdc86-1f9a-4dc3-8aa4-d97c9e5d1227',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CVG_ADMIN_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the loading dock side door walk walk through the double doors and turn left down the hall continue walking to the cafeteria and find the fridge on the left',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Fox Vending Greenberg Samples',
          id: 'IL_SALES_FOX_VENDING_GREENBERG__9717_S_76TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'DELIVER WITH FOX VENDING',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JERSEY_SHORE_UMC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk trough turnstiles, head towards cafeteria, fridge is located near micro market outside of cafeteria.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ba4df913-6b65-4cb8-827c-e0c66e817cd9',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_AUS Samples - CL',
          id: 'TX_CATERING_(COMPED)_SALES_AUS_SAMPLES_CL__120_BFG_9000_WAY',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Charlie to pick up at Austin cold storage on Tuesday morning',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Aramark Refreshments Route 91 – Polar',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_91_POLAR__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: '6caa25f9-297b-46f4-bc1c-999889d9275e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IIT_MEETING_DEMO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Memorial Herman Pearland - DR Dining Room',
          id: 'TX_MEMORIAL_HERMAN_PEARLAN_DR_DINING_ROOM__16100_S_FREEWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'ENTRANCE FIRST FLOOR ON THE LEFT. THERE IS A # ON THE SIDE OF THE DOOR IF THERE ARE ANY ISSUES',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_UPENN_NURSING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors, fridge is in the main cafe',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1643',
          id: 'MN_HYVEE_1643__8155_HIGHWAY_65_NE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_CHILDRENS_HOSPITAL_OF_NEW_YORK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter front entrance. Walk up ramp and to the security desk. Turn left. You will see the machine on the other wall next to the cafe.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_WAYNE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin:\nhttps://goo.gl/maps/sx4S8YtrYsT3Cu3R6\nHours: After 8am\nPark near the cones in the parking lot along the sidewalk/curb east of the main door.\nEnter through the main door. Walk straight past the welcome desk and turn left.\nFollow the hallway and take the last left into the cafeteria seating area.\nFridge is immediately on your left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Medtronic Plymouth',
          id: 'MN_CANTEEN_MEDTRONIC_PLYMOUTH__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MALCOM_X',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of the college on Jackson, get badged by security, and walk through the main hallway. The fridge is across from the cafeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DAL3',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the security desk, take an immediate right, Fridge will be straight down the hall in the breakroom. Once in the breakroom it will be right to the right.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_EWR9_DOCK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'If main entrance, show ID/Badge to security. If dock, go to dock 206, the break room is right next to it.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St David Medical Center',
          id: 'TX_ST_DAVID_MEDICAL_CENTER__900_E_30TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'The loading dock for the hospital is located on 30th street about ½ block east of Red River.  You’ll see an elevated walkway from an office building (attached) to a parking garage.  You’ll drive underneath the walkway to a gate (it may be open or closed)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "St. David's HealthCare",
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_RANNY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_FRAMINGHAM_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Head to the main entrance of the Whittemore Library and the fridge will be going into the coffee shop area immediately on your right.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_RILEY_CHILDRENS_ROC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CDW_120_S_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HAROLD_WASHINGTON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CITY_TECH_NAMM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Checking in with security and go up to the cafeteria. The fridge will be located on the far wall of the cafeteria.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1402',
          id: 'MN_HYVEE_1402__2501_WHITE_BEAR_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LENOX_HEALTH_GREENWICH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Enter in and turn left into waiting room',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_OCC_THE_HARBOUR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the front door and take your first right after the check in desk. You will be outside and go about 10 steps and take a right into the first building there. This is the community room where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_THE_GARAGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_DCAM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter building, we are on the left of the entry doors',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ESLER_COMPANY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'once you walk in the front entrance the fridge will be visible - its an open concept.  IF DELIVERING OVERNIGHT use the door code that will be provided.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'af49045d-5988-4773-b6ac-b5c095940e98',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_GOOGLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through front doors, take elevators to floor 2, fridge located in kitchen area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_TODAYSHOW',
          id: 'NY_TODAYSHOW__30_ROCKEFELLER_PL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Please call Emma 919-454-8607',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - WHOLESALE OFFICE',
          id: 'MN_CANTEEN_WHOLESALE_OFFICE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_HODGE_HALL__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_HARVARD_WILLIAM_JAMES_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Head to the basement of the building and you will see the Farmer's Fridge near the bathroom entrance.",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_CHRISTIAN_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, straight down the hallway, take a left, Fridge will be just outside the cafeteria doors',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_UCLA RONALD REGAN SAMPLES',
          id: 'CA_SALES_UCLA_RONALD_REGAN_SAMPLES__4569_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'TUCKER WILL PICKUP AND BRING TO UCLA',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_REPUBLIC_SQUARE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the entrance into the back of the building, take a right into the left wing, and keep going till you stop and take a right. The spot will be at the glass window near the micro-market.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_WASHU_OLIN_LIBRARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDX2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge will be right next to the Canteen Avenue C',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_COLEMAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk into 1919 building, go up stairs, fridge is next to the entrance to the skybridge',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TN_Catering (comped) - Sales_Nissan North America',
          id: 'TN_NISSAN_NORTH_AMERICA__1_NISSAN_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            "Contractor entrance just past the main entrance with the traffic light. Head there and go all the way to the end at the right. Truck parking near the delivery door is obvious. Ring the bell at the door and tell security it's a delivery for the kitchen.",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Katie Samples',
          id: 'TX_KATIE_SAMPLES__1212_DOLTON_DR_302',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Katie will pick up from cold storage weekly',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'PA_PHI_BRYN_MAWR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through double doors. and take elevator up to 1. Turn left out of elevator towards student seating area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Target CNBC',
          id: 'MN_CANTEEN_TARGET_CNBC__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'RUSH_HOSPITAL_ARMOUR_ACADEMIC_7TH_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_JOHNSON_AND_JOHNSON',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Enter campus and follow signs for lobby/parking area B\r\nPark and follow path to entrance\r\nCheck in with security/ fill out health pass.\r\nWalk down the hall and it's on the right hand side",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e231bd97-ff1e-40b6-a7a5-fd826914af8d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_EAST_QUAD_BLUE_CAFE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CLEVELANDAVE',
          id: 'IL_CLEVELANDAVE__222_N_CANAL_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL IVANO',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: '400_SKOKIE_BLVD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LITTLE_COMPANY_MARY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security at this location.\r\n\r\nGo through loading dock door, immediately take a left and head through double doors. Take a right at the ATM to reach the cafeteria. We are directly ahead to the left against the West wall.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1888',
          id: 'IL_TARGET_1888__4050_N_HARLEM_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'f289569a-6856-473e-9aaf-e333eed1fa1f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_GRR_MARKETPLACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/GKnR8uHuaZmhgWoSA\nParking is posted as \'Media/Vendor parking 30 minutes only" but we can park as long as needed. Van needs to have Farmer\'s Fridge branding or the Parking permit kept in the gray cabinet in the warehouse.\nWalk in Door 1, go left and then right to take the ramp walkway towards All Gates. When at the top of the ramp walkway go left. Elevator to the basement is on the left past the restrooms. Take the elevator to the basement. You will need to badge before pressing the button for the basement.\nExit the elevator and go left. Walk all the way down the hallway and take double door 4-9. Walk straight through to the opening an go slightly right and through the overhead door. Once through the overhead door go left and then right at the dead end. On your left go through door 43-9.\nOnce through door 43-9, go straight towards the double door - badge and enter your code then walk through to the "Sterile Area". On your right is a small desk with a black binder labeled "Farmer’s Fridge". Sign in on the sheet (if this is your first time you must sign the sheet in the left side pocket of the binder) and return the binder to it\'s place on the table.\nTurn and head the other way to the elevator. Take the elevator to level "M". Once out of the elevator, exit the hallway to the right and you will see MI_GRR_MARKETPLACE on the right. After servicing this fridge go back towards Terminal B and all the way down on the right is MI_GRR_CONCOURSE_B. Once done servicing this fridge walk back the way you came but at the end of the walkway take the right towards passenger exit. Go through the automated doors on the right and then straight until you can take the first right and you will be at MI_GRR_MAIN_ENTERANCE. Once done servicing this fridge you can leave through any exit and find your way back to the van.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SHARP_CHULA_VISTA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the doors and walk to the left of the desk and take your first right. Fridge will live there',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Toll Brothers HQ',
          id: 'PA_TOLL_BROTHERS_HQ__1140_VIRGINIA_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Kurt',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emerson College - Backstage Deli',
          id: 'MA_EMERSON_COLLEGE_BACKSTAGE_DELI__120_BOLYSTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Please call the delivery contact',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Gristedes',
          id: 'GRISTEDES_51__144_S_CLARK',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Point of contact',
          timeZone: null,
          containerType: null,
          status: null,
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EVANSTON_HS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_TMC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Dunn loading dock, Located off Bertner/Wilkins. Fridge is on the 2nd floor next to the coffee shop/cafeteria',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_44_WHIP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYIT_MEDICAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Graco ROC',
          id: 'MN_CANTEEN_GRACO_ROC__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Varispace Coppell',
          id: 'TX_VARISPACE_COPPELL__901_WEST_WALNUT_HILL_LANE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Loading dock is 24/7. Client has requested that deliveries be made before 8am',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TRUNK_CLUB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen 3M Samples',
          id: 'MN_CANTEEN_3M_SAMPLES__700_24TH_AVE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please use standard canteen wholesale delivery instructions',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_A21_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_APNASCARCHI2024',
          id: 'IL___3050_E_MOE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'PLEASE CALL JIMMY - THIS ORDER IS FOR NASCAR :)',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_AUS_GM_INNOVATION_CENTER_WHEEL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front doors, check in with security, walk to the back of the building, the fridge is on the 2nd floor in the cafe all the way to the back',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_EINSTEIN_ELKINS_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Ring Bell. Walk through kitchen',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Sugar Land Cold Storage',
          id: 'TX_SUGAR_LAND_COLD_STORAGE__15500_VOSS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'LEAVE AT COLD STORAGE FACILITY',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_LAX9',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once parked, take the main front entrance in to the facility lobby. Use badge access for entry in towards security. Once through security please take a right into the 1st floor main cafeteria. The fridge is along the side wall of the larger cafeteria with the micro markets',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PACTIV_EVERGREEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the front entrance to the main floor break room',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_TEB6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Sign in through front desk like other Amazons, Matthew completed the badging forms so once they have those they can just enter with the badges. Turn right and walk into main breakroom',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_DEPTFORD_EAST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Stock west side FIRST. Check in through west side security. After stocking west side, walk left out of the breakroom and follow the green lines past security booth for about 5-10 minutes until you see the break room sign.',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_ST_ELIZABETHS_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_KINDER_MORGAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in loading dock, take elevator down to tunnel, we are located basically on the southeast end of the tunnel.  Follow the signs for 919 Milam!',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_19',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_GROSSE_POINTE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Take hallway from loading dock to east elevator - take to LL / Ground floor and you will see cafeteria. If after 3:30 - Enter through front/main door, turn right, then left, then right. Take the elevators down to G (you're currently on 1) and turn left. Fridge will be straight ahead. Map Pin: https://maps.app.goo.gl/QioRfpucsuwn2wwr8",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Drew University Wholesale',
          id: 'NJ_DREW_UNIVERSITY_WHOLESALE__36_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'BACK ENTRANCE- CALL NICOLE WHEN YOU ARRIVE\r\nEhinger Center,',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_SCRIPPS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the 3rd street entrance door and the fridge is located directly in front the entrance of the lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW E8',
          id: 'TX_HUDSON_DFW_E8__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Lifetime - North Dallas, TX',
          id: 'TX_LIFETIME_NORTH_DALLAS_TX__3419_TRINITY_MILLS_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_AdvocateChrist',
          id: 'IL_ADVOCATECHRIST__4440_W_95TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Advocate Christ Medical Center Employee Entrance',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd383ba3e-369d-438e-be70-fe19a565206e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Dallas Sheppard Mullin',
          id: 'TX_DALLAS_SHEPPARD_MULLIN__2200_ROSS_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dropping to Deanna Ouderkirk with Sheppard Mullin on the 20th floor of the building',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LAHEY_HOSPITAL_EAST_4TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the main entrance and go up to the 4th floor cafeteria. Fridge will be located in the vending area.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LAKE_COUNTY_GOV_LIBERTYVILLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge will be located just inside the main doors to the left. It will be the hallway leading to the bathrooms',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCC_KATY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front door - the fridge is to the right hallyway in the coffee room on the left side of the hallway',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Capitol Vending',
          id: 'TX_CAPITOL_VENDING__4711_E_5TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'drop off at front desk',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BARUCH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Main entrance fridge is located through silver doors behind the security desk in lobby....after doors make a right',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Force America',
          id: 'MN_CANTEEN_FORCE_AMERICA__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'WE_ENERGIES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe R',
          id: 'CA_QUALCOMM_CAFE_R__6455_LUSK_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_HPB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'located at the end of the hallway on the EAST side of the building\r\n\r\n"Closed every Friday from June to August 16th.\r\n\r\n We open Fridays on August  23rd. Then we are open Fridays for fall and spring unless it is a holiday."',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Highland Pointe',
          id: 'MN_CANTEEN_HIGHLAND_POINTE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_WAYFAIR_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take elevator 13 (Tower 4) to the 3rd floor. \r\n\r\nOnce you exit the elevators, take a left and badge into the double doors. \r\n\r\nGo straight and the big kitchen will be on your right closest to Tower 1 doors.\r\n\r\nAfter delivery, return to the dock and exchange the Wayfair badge for your ID.\r\n\r\n*If you are not granted badge access, please contact Helen Stanley-Serrano at 617-637-1986',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_MIT_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If you use the main entrance, you walk down the steps and the Fridge will be in the room directly to your right. If you use the loading dock, you will take an immediate right when you enter and then a left through the common space and then take a left and proceed straight into the room with the fridge.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_FEDEX_GRAYSLAKE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BOEING_BUILDING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales Hudson Receiving Office',
          id: 'IL_CATERING_(COMPED)_SALES_HUDSON_RECEIVING_OFFICE__2447_EAST_HIGGINS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Enter through main door and deliver food to Rina Hernandez with Hudson News',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host - Ciao',
          id: 'IL_HMS_HOST_OHARE_CIAO__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Drop to store attendant at Ciao store in Terminal C gate C15',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_CHICAGO_TRIBUNE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_VA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'cc to update',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SPACEX_HT01',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Space X to provide instructions',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3220',
          id: 'IL_JEWEL_OSCO_3220__20_BIESTERFIELD_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'WEWORK_332_S_MICHIGAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8ce20dc4-e1e0-45f6-aae9-30c5ecee288e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_CHRIST_OUTPATIENT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'After parking Walk to entrance, fridge will be to the right side next to the service information counter',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_INFORMATION_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main doors and go to the elevator. Take it to the 5th floor and go to Suite 525 and we will be in the lounge there',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Essen Healthcare',
          id: 'NY_ESSEN_HEALTHCARE__2626_HALPERIN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'call Laura Hernandez 347-202-6253',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Middleton Warehouse',
          id: 'WI_COSTCO_MIDDLETON_WAREHOUSE__2150_DEMING_WAY',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName:
            'IL_Catering (comped) - Marketing_BRAND BASIS SUMMIT',
          id: 'IL_BRANDBASIS__11_E_MADISON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL TAYLOR - 6TH FLOOR DELIVERY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'IN_Catering (comped) - Sales_Hudson IND Sample Pack',
          id: 'IN_(CATERING)_COMPED_SALES_HUDSON_IND_SAMPLE_PACK__7800_COL_H_WEIR_COOK_MEMORIAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Dropping entire order to Hudson News store at gate A20. Product will be distributed by Hudson from there.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Sales Seattle Costco',
          id: 'IL_SALES_SEATTLE_COSTCO__1448_N_ORLEANS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Drop to Chris at apartment call upon arrival',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KITE_PHARMA_EL_SEGUNDO',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the front check in. Go through the main doors to the break room / kitchen where the fridge will live\r\n\r\nCan call Jen Brown if issues 424-521-4287\r\n\r\nIf you arrive after 8am, check in at the Visitor Lobby first. Then, proceed to the Strand area near the Northshore patio and call me at 424-373-0953.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through front entrance, continue down main hallway until you hit the VA logo on the ground, take a right, and you will eventually hit a dead end.  The cafe is on the right.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2850',
          id: 'NY_TARGET_T2850__445_ALBEE_SQUARE_WEST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALEXIAN_EBERLE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the doors to the ER. Security is in the ER waiting room and will escort the driver to the Fridge.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_AVE_OF_THE_AMERICAS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_INTERNATIONAL_PLACE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The drivers should first check in with the One International Plaza security desk (main entrance on Oliver Street) then proceed with delivering through the side entrance (also on Oliver Street) that is located next to the Starbucks. The drivers do not need to be badged but will need to check in with security- Security has been informed that Farmers Fridge deliveries have access to the fridges 24/7.\r\n\r\nDirections to 2IP Fridge: Enter side entrance and go straight through doors leading to the IP Commons. Take a left to go around the perimeter of the waterfall. Fridge will be on your left.\r\n\r\nSecurity phone # 6173305227',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T5 Evolve',
          id: 'IL_HUDSON_ORD_T5_EVOLVE__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 5 Loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NJ_BERGEN_NB_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'if loading dock, it will bring you right to the cafeteria area, walk past to the seating area. if front entrance, sign in with security and take elevator down one level and follow signs to cafeteria',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HARPER_COLLEGE_BUILDING_M',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2049',
          id: 'TX_HUDSON_PHL_2049__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'FF Fulton Office',
          id: 'IL_FF_FULTON_OFFICE__2000_W_FULTON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_CHULA_VISTA_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main doors and take a left. Go to the end of the hallway and take a right where you will see the other vending machine where we will be next to',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_15500 Voss',
          id: 'TX_15500_VOSS__15500_VOSS_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'SALES ORDER - Leave it at the facility',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_17220_KATY_FREEWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at visitors parking, walk to the building security will show you the break room.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_30TH_STREET_STATION_2',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Same as PA_PHI_30TH_STREET_STATION',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UCLA HEALTH - SANTA MONICA',
          id: 'CA_UCLA_HEALTH_SANTA_MONICA__757_WESTWOOD_PLAZA_ROOM_B147E',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'PLEASE DROP OFF WITH THE RONLAD REGAN ORDER. THE UCLA TEAM WILL BE DISTRIBUTING THIS W/ THEIR PRODUCE TRUCK. THE EARLIER THE DELIVERY THE BETTER. THEIR WILL BE A SPOT IN THEIR WALK IN COOLER FOR THIS ORDER.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'UCLA Health',
        },
        {
          opportunityName: null,
          id: 'NYC_LAGUARDIA_CC_BUILDING_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call public safety at 718-482-5555 for access.\r\n\r\nFor loading access (if the gate is closed)  718-482-5565\r\n\r\nFridge is located in the main lobby on the right side when entering past security',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EVANSTON_HIGH_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "For overnight deliveries, call safety number, 847-424-7328, within one hour of arrival. Park in back of the building and go to door #2 and security is supposed to be there. The number to dial if security isn't sitting there is: 847-424-7328 from there security will escort driver to the fridge",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Marketing_TODAYSHOW Rockefeller Center',
          id: 'NY_TODAYSHOW__32_W_49TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL Brianne Demmler (917-287-2534)',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_603_MUNGER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the elevators, take it to the top floor, Fridge will be past the kitchen area in the back left wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Amercian University',
          id: 'DC_AMERICAN_UNIVERSITY__4300_NEBRASKA_AVE_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call Rolanda. (202) 531-6068',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NEIU_LIBRARY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3405',
          id: 'IL_JEWEL_OSCO_3405__6509_W_GRAND',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK: In deli cooler next to meat market. Must speak with Sue or member from deli before storing. DELIVERY: Jeff is receiver Sue is deli manager Will not accept deliveries after receiving hours. Receiving is closed Wednesday and Sunday.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'WALGREENS_191_N_CLARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_ADRIANC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5750_OLD_ORCHARD_ROAD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BELLEVUE_12TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Security will from time to time escort to the 12th floor Employee Lounge\r\n\r\n                                          -if not-\r\n\r\nWalk through plastic curtain straight to end make left (sign for mail room) turn right go to elevators. Call elevator operator on intercom 3006 tell them....basement to 12th floor. Once on the 12th floor you'll see the employee lounge on the right/left... fridge is on the left  inside lounge",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_161__8121_CENTRAL_PARK_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_770_N_JEFFERSON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1437',
          id: 'IL_TARGET_T1437__2939_W_ADDISON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park in the loading dock and walk in ask for Sheryl she’s the team lead and will receive the food.\n\nSheryl is the market team lead',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'UMASS - ISC/Jazzman',
          id: 'MA_UMASS_ISC_JAZZMAN__100_WILIAM_T_MORISSEY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Enter the rear of the building which has 4 Bays. Enter the door to the right, walk up the ramp to the left. See the double door to the right. Walk through hallway to kitchen area and someone will assist you.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_GATE_A8_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn left and walk to A gates, circle the wall until you find the alcove outside of gate A8',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_ED_VEND',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Walk into the ED, go down the hallway that's straight back and it will be on the left.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Baxter Healthcare',
          id: 'BAXTER_HEALTHCARE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Driver will 1st check in with security in building C to retrieve badge (bring ID). After retrieval, they will continue ahead, following signs to loading dock. Use telecom next to dock door to buzz security for entry. Once in, use badge to open blue doors on right then take the elevator to the 2nd floor. Make a left and you will see a wall ahead with ?all in? written on it. Once reached, make another left then an immediate right to find the small convenience store for drop off (look for 2 small PepsiCola machines, located directly in front of store.)',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_375_PEARL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_DEARBORN_HALLWAY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin: https://goo.gl/maps/a1Mr2BNUMkFXwcpk6\nEnter through the West Entrance/Cancer center doors.\nTurn right, then straight. Proceed straight into the main atrium and fridge is ahead slightly to the left',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_500_N_MERIDIAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1200_W_HARRISON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Graco Anoka',
          id: 'MN_CANTEEN_GRACO_ANOKA__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NJ_99_WOOD_AVENUE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Turn right and go through the doors and walk straight to the service elevator. Exit on 1st floor and turn right, then right again and follow sign for vending. Machine will be on the right.\r\nLoading dock open 7am-5pm',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hy-Vee 1035',
          id: 'IL_HYVEE_1035__1403_N_VETERANS_PKWY',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Marketing_BARR MANSION FOOD TANK',
          id: 'TX_BARRMANSIONFOODTANK__123_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'PLEASE CALL MELANIE OR BRANDY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Eagan Warehouse',
          id: 'MN_COSTCO_EAGAN_WAREHOUSE__995_BLUE_GENTIAN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Drive to receiving in the back. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 20',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_20__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Costco - Wyoming Warehouse',
          id: 'MI_COSTCO_WYOMING_WAREHOUSE__4901_WILSON_AVE_SW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back near receiving to deliver',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_CONTINUING_CARE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with security, walk to cafe, fridge located across from cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_11601_WILSHIRE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front main entrance into the lobby and security desk will be right as you enter. Announce you're with FF and take that to the lobby lounge. The fridge is in the upper lounge seating area.\r\n\r\nFor night deliveries there is always a security guard on the property. Park on San Vicente blvd. There is a intercom button on the right side of the door. Security number is  (310) 479-2607. If they don't answer right away security may be patrolling.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_NJ_1245_MAIN_ST',
          id: 'NJ_SALES__1245_MAIN_STREET_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call RJ when onsite (908-910-2916)',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'erin_test',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Motion AI',
          id: 'MN_CANTEEN_MOTION_AI__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3375',
          id: 'TX_TARGET_T3375__2075_WESTHEIMER_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Costco - Auburn Hills Warehouse',
          id: 'MI_COSTCO_AUBURN_HILLS_WAREHOUSE__400_BROWN_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to the back by receiving to deliver. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Costco - Galleria Warehouse',
          id: 'TX_COSTCO_GALLERIA_WAREHOUSE__3836_RICHMOND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_CJ Samples',
          id: 'CA_CJ_SAMPLES__12610_BRADDOCK_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Use the gate marked 12610 with a white bench in front of the gate. Code to enter is 85883. My unit is on the ground floor on the right hand side. He or she can leave it at the front door.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'RUSH_OAK_BROOK_CBRE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the first loading dock door and hit the #1 button and then press the green call button and security will buzz you through. Walk in about 10 feet and you will reach the second door and there will be a second screen and you will hit the #1 and then the green call button, and security will buzz you through. Take a right as soon and you walk through the doors, and take that down to the last door on the left. You will pass the security booth and you will sign in, the fridge is to your right between the other vending machines.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BRIGHAM_WOMENS_HOSPITAL_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter Loading Dock and take freight elevator up to floor 2. Fridge is near the bridge.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cummins',
          id: 'IN_CUMMINS_MID_SIZE_ENGINE_PLANT__500_W_JACKSON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Dock Instructions for COB delivery: 799 Brown Street, Columbus 47201 is the dock address Upon Arrival drivers must buzz security to open the door for them. They can then drive into the dock to unload. There is a normal truck dock to back up to and unload, or there is a lift system that loads can be moved to and then get up to the main floor.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_HOSPITAL_1750_HARRSION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UW Madison Capital Cafe',
          id: 'WI_UW_MADISON_CAPITAL_CAFE__975_UNIVERSITY_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Please deliver to the loading dock of Grainger Hall - unit is located right off the loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'University of Wisconsin System',
        },
        {
          opportunityName: null,
          id: 'NYC_QUEENS_COLLEGE_STUDENT_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Number for security: 718-997-5912\r\n\r\nGo around building to front door\r\n\r\nFor stocking: In order to OPEN these two buildings, please inform all your drivers to come to the Main Public Safety Office located at the front of the campus on Kissena Blvd entrance. \r\n\r\nWhen the drivers come to the front desk, inform the Public Safety Officer on duty that they are from Farmer's Fridge and want to go to Student Union and Queens Hall Buildings to fill in the product.\r\n\r\nThe campus Public Safety Officer will go with your drivers to OPEN these two buildings and remain there until finish and lock the building after that. \r\n\r\nThere may be times when your drivers have to wait for some time due to on-campus other emergency/routine situations",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T3 K11',
          id: 'IL_HUDSON_ORD_T3_K11__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_DILLO',
          id: 'IL_DILLO__1999_CAMPUS_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "Meredith Ciesemier's (847) 491-2390",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LAKEWOOD_CHURCH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Maria will escort you to the floor, there is a service elevator that will take you there.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T2_UPPER_F3',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Fridge is located on upper level of terminal T2 near Gate F3 and near the Illinois Lottery Booth',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Genral Dyn Hampshire',
          id: 'MN_CANTEEN_GENERAL_DYN_HAMPSHIRE__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'MI_HENRY_FORD_MACOMB__15855_19_MILE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'go to 2nd floor cafeteria ask for chef richard or a supervisor',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_SURGERY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'POC doesn‚Äôt have access to loading dock .. so I couldn‚Äôt get directions on how to get to the fridge. Fridge will be next to currency exchange and across from New York Sports Bar.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '555_W_HARRISON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the call button to notify security. Go down to the lower dock area- there are 2 bay docks to offload. Take the freight elevator up to the 2nd floor. Fridge will be in the vending room on the east side of the building',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Cincinnati Children's Base",
          id: 'OH_CINCINNATI_CHILDRENS_HOSPITAL_WHOLESALE__3333_BURNET_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Drop at the B cafeteria and call Tom',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Cincinnati Children's Hospital",
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_15',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_EIGENMANN_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_3M_HQ_BUILDING_236',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please see image of map attached. Michelle will also be onsite to guide to the location',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Bowmanwille',
          id: 'IL_GETIR__5430_N_CLARK_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please call DC if there are questions',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_MISSOURI_BAPTIST_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Badge in. NEW: Fridge is on the first floor next to coffee shop \r\nOLD: Walk straight down hall way, go right, first hallway left. Fridge in back of cafeteria.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T2_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'UPdate soon',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_TIAA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_CONCOURSE_B1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn right and walk to Gate B1 to find fridge.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_UNIVERSITY_OF_CHICAGO_MEDICINE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WILLIS_TOWERS_WATSON_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '35_E_WACKER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LAX_TRMNL_1_GATE_14',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_APPLE_CULVER_CITY_22',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Pull up to secuirty gate, check-in with security with badge, stay right of parking garage and follow road briefly. Loading zone / side door on right (NOT main door near security gate). Enter door that has "no tailgating" placard above where to scan badge. Enter through door, then second door, take first left, mail room will be on right hand side down the hall.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_IMU GIFT SHOP_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'IN_GOPUFF_80__1597_N_HARDING_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HORIZON_PHARMACEUTICALS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 45 – Toro',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_45_TORO__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_SDR SAMPLE',
          id: 'DC_SDR_SAMPLE__2512_I_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Call David',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_HEALTH_SCIENCES_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Walk through loading dock doors, walk passed the coffee, you'll see the vending room, fridge located in vending room.",
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the entrance, head towards the coffee stand, Fridge will be just to the right when you walk in',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Frisco, TX',
          id: 'TX_LIFETIME_FRISCO_TX__4900_THORNE_HALL_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_CONCOURSE_A4A',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "A4A concourse is off of the main A concourse. You'll need to take the long walkway to the gate holding area where the fridge is located.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TRITON_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ST_ELIZABETH_EDGEWOOD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take main entance to main elevators to cafeteria - take elevators to Ground floor and vending area will be right when you walk out',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIKERS_ISLAND_OBCC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '33395ebe-d234-4e51-9a06-5c2969da6edc',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_T5_DEPARTURES_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Nicole Villasin',
          id: 'CA_NICOLE_VILLASIN__5945_LOOMIS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Air Shipment',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Hudson MSP Sample Pack',
          id: 'MN_CATERING_(COMPED)_SALES_HUDSON_MSP_SAMPLE_PACK__MINNEAPOLIS_SAINT_PAUL_INTERNATIONAL_AIRPORT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Retrieve order from Bradford with fridge orders. Deliver to Hudson News next to Wild Bar in T2',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Getir Chi Samples',
          id: 'IL_CHI_GETIR_CHI_SAMPLES__625_W_ADAMS_ ST_19TH_FLOOR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Kristen',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Target DC',
          id: 'MN_CANTEEN_TARGET_DC__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_717_TEXAS_CALPINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front and use the intercom on Prairie Street to buzz security\r\n\r\nwalk in lobby, the fridge is basically in the lobby in the coffee lounge against the windows',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_112__1347_S_ASHLAND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon JFK14',
          id: 'NY_AMAZON_JFK14__6_W_35TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading dock is between 5th and 6th Ave and Amazon is located on the 11th floor',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Delta 6',
          id: 'MN_CANTEEN_DELTA_6__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MD_BAL_AACC_CALT_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front, fridge in main lobby across from coffee shop',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MO_Catering (comped) - Sales_EddieSakSamplesSTL7.30',
          id: 'MO_EDDIE__10701_LAMBERT_INTERNATIONAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'CALL EDDIE 630-777-1885',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_DEPTFORD_WEST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Stock west side FIRST. Check in through west side security. Walk past security and turn right and look for breakroom sign. Fridge is on left side of the breakroom',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_US_DEPT_HOMELAND_SECURITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Will need escort to get onto the base. Client is following up with more detail. Will update asap.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Kurt Collins House',
          id: 'IL_KURT_COLLINS_HOUSE__3129_N_SEMINARY_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "Call Kurt's Cell upon arrival - 814-880-9831",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Aramark Austin Office',
          id: 'TX_ARAMARK_AUSTIN_OFFICE__8606_WALL_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Omont Wash at 512-458-0165',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_WAVERLEY_OAKS_ATHLETIC_CLUB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk past the front desk into the lobby and the fridge will go against the left wall.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_LizSampling',
          id: 'IL_LizSampling__5700_S_CICERO',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to the Southwest Airlines Pilots Lounge',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_SIGNAL_PRODUCTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'check in with security at black gate',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_UM_MEDCENTER_DOWNTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Client will send over map',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MARKETING_MICHIGAN_AVE_POP_UP',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'From dock to the front of the building',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_BRONX_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in main entrance, go to the right after security, and the cafeteria is on your left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_ST_CLARES_DENVILLE_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter in through the front entrance, walk past security, turn right, machine will go to the right of the ATM',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2050',
          id: 'TX_HUDSON_PHL_2050__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indianapolis International Airport',
          id: 'IN_INDIANAPOLIS_INTERNATIONAL_AIRPORT__7800_COL_H_WEIR_COOK_MEMORIAL_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'First right is sign terminal loading dock, go to south access, make a right to perimeter road. Once over bridge, is north access road, go right and around fire station to guard shack. Pull up right side. Go straight to terminal loading dock.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_333_THORNHALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through loading dock. Walk straight till you get to a door that says Cafeteria. Enter, walk straight, you’ll see the Fridge to your left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Procter & Gamble - Spring Grove',
          id: 'OH_PROCTER_GAMBLE_SPRING_GROVE__5229_SPRING_GROVE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Please bring the order to the loading dock at the facility located on the corner of June and Spring street',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Procter & Gamble',
        },
        {
          opportunityName: null,
          id: 'MN_USPS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Come in through the east end of the loading dock. Hit the button for the gate to open, drive in and they'll tell you which dock to park at. Walk all the way to the end towards dock 33. Walk past the freight elevator to the passenger elevator near the exit sign (elevator 2). Go to the 3rd floor, take a slight left towards the exit sign, walk straight through the door to the end of the hall, walk to the end of the hall, turn right and the vending room in the old cafeteria will be on the right",
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GROUPON_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_NASCAR2024',
          id: 'IL_NASCAR2024__1362_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL TERYL',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Brooklyn Boulders West Loop',
          id: 'IL_SALES_BROOKLYN_BOULDERS_WEST__100_S_MORGAN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call IYANA',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_CITI',
          id: 'NY_CITI__388_GREENWICH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'CALL TARYN',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go through loading dock and head to the front of the hospital , we are across from the starbucks',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1898',
          id: 'MA_TARGET_T1898__7_ALLSTATE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park by and enter through side loading dock. Stocking location is the endcap near the produce section.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_AIRPORT_GATE_A',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Food will be checked by the dock master and delivered to the fridge. Driver will need to go through security and pick up food post-security.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MO_Catering (comped) - Sales_Schnucks Samples',
          id: 'MO_CATERING_(COMPED)_SALES_SCHNUCKS_SAMPLES__11420_LACKLAND_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'Deliver to front desk and ask for Scott Shapiro',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Mckinsey & C0',
          id: 'NY_MCKINSEY__195_BROADWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Freight Entrance on Fulton Street. Call Lisa upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BU_FITREC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call our front desk at 617-358-3740 when you arrive and a manager on duty will come assist with the locked doors and directions',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NJ_TARGET_T1865__7101_TONNELLE_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GOOGLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The driver will need to get escorted upstairs to the 3rd floor',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'de3e26dd-d439-4bb4-9376-c877b5a1c7bb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_SPH_JAVA_BLUE__1503_WASHINGTON_HEIGHTS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'the dock is on East Medical Center Drive which is where we will want the delivery to be dropped off at.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Reach Media 2',
          id: 'DC_REACH_MEDIA__1101_NEW_YORK_AVE_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Please call when you are 10 mins away, pull into the loading dock, I will meet you there.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_B25',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock.  1 driver will enter the front of the airport and go through the passenger/employee security check point.  The other driver with the vehicle will take the products out and bring them to the door in the loading with security and wait for the other driver to make it through security.  Pick up product from the loading dock and follow the hallway to the right till you reach the elevator.  Take the elevator to the 3rd floor.  Exit and turn left.  There will be a door with a sign that says "Gate 20 Lounge Area" exit that door and turn left.  Walk straight down towards the B gates.  Fridge will be on the right side opposite of Gate B25.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_SCIENCE_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '085ac831-9e3a-47d5-b69e-fd0f82f3bfd2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_CHULA_VISTA_POLICE_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the front door and check in with security. Go through the doors on the left of the room on to the elevators. Go to floor 2 and down the hallway and on the right will be the break room with the Fridge\r\n\r\nWE DO NOT HAVE WEEKEND ACCESS',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Juice @ the RPAC',
          id: 'OH_OHIO_STATE_JUICE_AT_THE_RPAC__337_ANNIE_AND_JOHN_GLENN_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Be on east side of college road. Go to Annie + John Glenn Ave, take a left on Millikin at the powerplant. Follow to small parking lot/loading dock. Take sidewalk to left of dock around the building to glass building. Juice is on other side of lobby.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'The Ohio State University',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_CHASE_LOUNGE_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From loading dock head to far end of East Concourse near Gate 1 and circle around back of the Bar with neon sign. Take the elevator up to second floor and see fridges to the left of the  elevator.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_JPS Health Catering',
          id: 'TX_JPS_HEALTH_CATERING__1500_SOUTH_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Cafeteria - From main entrance, turn left until dead end to reception desk, turn right until orange elevators, go down to lower level, turn left once off, turn right at the second turn, follow the hall until dead end, turn left, turn right. Caf is on left',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_3030_THOMSON_AVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'IN_GOPUFF_395__6107_GUION_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_5TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Check in with security,',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5b3ab6d1-c01a-4d4e-b8da-9cb7fcbefba3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_UTA_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the side doors, take the hallway straight down and the Fridge will be located next to the Wells Fargo ATM machine',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DETROIT_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Hours: 24/7\nMap pin: https://goo.gl/maps/BNmBM23DR2mahiHbA\nPark in the emergency room dropoff loop. Enter the main door, keep slight left.\nFridge is ahead on the left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Enterprise Refreshments - Wholesale',
          id: 'OH_ENTERPRISE_REFRESHMENTS_WHOLESALE__895_GLENDALE_MILFORD_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Pull right into the building and park near the coolers',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Enterprise Refreshments',
        },
        {
          opportunityName: 'Aramark Refreshments SALES',
          id: 'MN_ARAMARK_SALES__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Delivery Instructions\r\nPlease call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: null,
          id: 'CBRE_OFFICE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN TROY HOSPITAL',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_TROY_HOSPITAL__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_WWCCO Media',
          id: 'MN_WWCCO_MEDIA__90_S_11TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Tracy upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_LAKEHOUSE_HEALTHCARE_CENTER_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge located in vending area 15ft away from front desk',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: '475 SS Cafeteria 2',
          id: 'NJ_475_SS_CAFETERIA_2__475_SOUTH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver to 475 South street. Do NOT deliver with their regular order',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: 'Atlantic Health System',
        },
        {
          opportunityName: 'Raytheon McKinney',
          id: 'TX_RAYTHEON_MCKINNEY__2501_WEST_UNIVERSITY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Walk through the back door of the loading dock',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DELNOR_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_SPS_TOWER_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security phone # 6126736754.  Walk through doors, fridge would be on the right on the white wall that backs into the escalator\r\n\r\nIf stocking after business hours, one of the officers will show driver to the lobby',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_GRANITE_3355_ALABAMA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock, fridge will the walking straight through the service door.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_VALLEY_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go to basement toward cafeteria, across from the cafeteria is a windowed room with vending machines and tables',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1_N_FRANKLIN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_TECH_COMMERCIAL_AVE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the front door, walk into the Octagon Lounge on the right',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f2440772-1110-45c5-a71f-37b0537ad3e0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MUJO__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'drop off in circle drive or dock, first left of Bonisteel past Murfin',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - NE Naperville Warehouse',
          id: 'IL_COSTCO_NE_NAPERVILLE_WAREHOUSE__1255_EAST_OGDEN_AVE1255_EAST_OGDEN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BRISCOE_C_STORE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e3c334cd-3422-40b0-bf0d-8474ab3edd7f',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_GRAPEVINE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Through the front doors, turn right until you hir the Starbucks, turn left and follow that hall all the way until the cafeteria.\r\n\r\nLobby doors lock at 9pm, so if you're coming past that time you'll need to go through the emergency department",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Manhattan, NY 2',
          id: 'NY_LIFETIME_MANHATTAN_NY_2__605_W_42ND',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LESLEY_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter at the front of Sherrill Library through the court yard. Go through the front doors and turn right and the Fridge will be going on the pillar in front of the cafe.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'MA_TARGET_T2822__1341_BOYLSTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Product in grab & go section on second floor.  7am start time for receiving. Enter through Loading Dock.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NY_GOPUFF_554__835_BROADWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 4 DexCom 6350',
          id: 'CA_CANTEEN_VISTA_RT_4_DEXCOM_6350__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_BROWN_AND_CROUPPEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk through the main doors, take a right, and the Fridge will be on the backwall of the of main break room\r\n\r\nCan call Andy Crouppen if there's issues 314-397-0600",
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MOSS_INC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through door 21 with issued key fob. Take a right down the hall, turn left and the fridge will be in the break room. Once inside turn right then left. Fridge is located in cafeteria straight ahead.\r\n\r\nNO WEEKEND ACCESS',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Virtua Mount Holly',
          id: 'PA_VIRTUA_MOUNT_HOLLY__175_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Pull into loading dock and ask for the Jeff in the kitchen',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_CORE_SPACES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once parked, our drivers will have a code for the gate and walk through the back gate then go in the first door on the left. This will take you right to the common area and the fridge will be visible.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DashMart Chicago-',
          id: 'DASHMART_CHICAGO__1010_N_HOOKER_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'calll contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson IAD - A24',
          id: 'VA_HUDSON_IAD_A24__1_SAARINEN_CIR',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'These orders are to be left outside the door A-L1-391 in A Concourse, ground level, Gate A22. Go through those doors, straight until you reach the main hallway and make a left.  The room is on the right side a couple of doors down.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_DUNN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in loading dock of dunn tower (off of fannin) and head toward the coffeeshop/atrium/main entrance to Dunn (theyre all the same area). We are in the hallway NEXT to the coffeeshop behind a pair of double doors',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at the loading dock and take the elevator one floor up. The fridge is located in the cafeteria seating area.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_PLANO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the main doors, use the elevators to go down a flight, take a right out of the elevators, the Fridge will be down the hall.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_ELI_LILLY_NORTH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_LAWRENCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through employee entrance, tell them you’re delivering, if both bays are full then park to the side.\r\n\r\nStraight back through stocking room, take a left through double doors, take a right and then a left before elevators through another double doors and another left to “Courtyard Cafe”',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_A1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LAHEY_HOSPITAL_BUILDING_31',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call Richard Delacy upon arrival (339-927-3916). He will help to coordinate getting you inside the building and showing you where to go.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T4_PRESECURITY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_Jessica LA',
          id: 'CA_JESSICALA__14140_Moorpark_St',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Call Jessica',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KEHOE_DESIGNS_INC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Walk to fridge, located in dock breakroom',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host DTW - Common Grace',
          id: 'MI_HMS_HOST_DTW_COMMON_GRACE__ROMULUS',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            '"DTW Airport Wholesale Drop (Common Grace, Jolly Pumpkin):\r\nCALL  734-652-3125 WHEN YOU ARE ON YOUR WAY THERE WITH AN ETA\r\nDIRECTIONS:\r\ncome off of 94 head south on airport drive.\r\nAt the first light, get in the far right lane.\r\nHead to “west service drive” indicated by the Blue Sign.\r\n Follow that road till you get to a stop sign and turn left at the stop sign, then follow that road to the very end.\r\nOn arrival, back to a dock and they\'ll open up, ask for your drivers license and paperwork, have you sign a sheet, and then take the boxes from you.\r\nThat\'s it.\r\nMAP PIN THAT WILL TAKE YOU TO THE RIGHT LOCATION:\r\nhttps://maps.app.goo.gl/UPfikwFbanSkZypA8"',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'OH_CIN_LIBERTY_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take main lobby towards ED - fridge location will be on right in hallway between main lobby and ED',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_AMAZON_STL8',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in through security, fridge located in FOH1 Breakroom',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_MEMORIAL_HOSPITAL_BELLEVILLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main entry doors, Fridge will be located to the left within the Starbucks area.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_CONCORIDA_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UH Cleveland Medical Center',
          id: 'OH_UH_CLEVELAND_MEDICAL_CENTER__11100_EUCLID_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Delivery Hours - 5:00AM to 1PM (Deliver hours are longer, however, our contacts want delivery no later than 1PM)\r\nDriver Entrance - 2084 Cornell Road will take you to loading docks, use receiving entrance door\r\nDriver Parking - Park in front of large garage door just past loading bays\r\nPark To Fridge Instructions - Walk through receiving doors and head straight until you reach the freight elevator doors on the left, elevators will be marked ""clean only"", take elevator down one floor to basement level, take a left from elevator, follow until you reach the first intersection and head right up the ramp, take a left at next intersection (begins to look like an actual hospital at this point) and follow until you reach the set of elevators next to the vending machines, take that elevator up to the first floor and take a right, follow until you reach the food service area, ring the bell and wait for an employee to escort you to the walk-in cooler where product will be placed',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Sodexo',
        },
        {
          opportunityName: null,
          id: '32470bc6-20a5-4220-83d6-04be4c50822c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7fac3ff7-3750-474e-bb40-cf55f518dc74',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_UCLA SANTA MONICA SAMPLES',
          id: 'CA_SALES_UCLA_SANTA_MONICA__4569_VERNON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Patrick and Tucker will pickup at cold storage',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FCLOUD1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Greyson',
          id: 'NY_GREYSON__149_N_6TH_APT_3',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Buzz the bottom button for Apt 3. Call Greyson 9083070006',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_SAINT_MARGARET',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "go in through loading dock, fridge will be in basement cafeteria. if they come after hours, there's a phone listed and they can call security to let them in.",
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_SOUTH_SUBURBAN_EMERGENCY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take a direct left when you enter and it will be against the south wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_MMU9',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Come in via front entrance, security will check IDs and escort to back break area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SDSU_THE_ESSENTIAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main door and it will be the first right',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_1819_INNOVATION_HUB',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at the spaces up the ramp off the rear loading entrance of the building and take those (already on the 2nd floor from the ramp) to the 2nd level vending kitchen and seating area',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'cb07c81d-a470-49b8-b797-ded49b76640c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TROY_GARDEN_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter via the West Entrance, turn right. Follow hallway all the way down, turn left, and continue, following signs for the cafeteria. Fridge is on the right near the cafeteria entrance.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CMH_CONCOURSE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Will have to get more info/map from client.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Stratasys Smacs',
          id: 'MN_CANTEEN_STRATASYS_SMACS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'USE BADGE AND DROP OFF IN FRIDGE',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'USC Norris Cafe',
          id: 'CA_USC_NORRIS_CAFE__1441_E_LAKE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Go to the loading dock and enter through the back entrance. Kevian Cross (323-865-3040) or Francisco Venegas (323) 559-0202) are onsite contacts.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Keck Medicine / USC Med',
        },
        {
          opportunityName: null,
          id: 'INTEGRATED_MEDICAL_SYSTEMS_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use main entrance and take to employee lounge',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_COLLINS_AEROSPACE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "go through courtyard door, turn right, turn left and go through the doorway and it'll be straight ahead",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_LAHEY_HEALTH_MOUNT_AUBURN_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter via Emergency Dept. and check in with security. Walk toward cafeteria area, and Fridge is in seating area',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'HENRY_FORD_MAIN_DETROIT_CAMPUS_CAFETERIA__2799_W_GRAND_BVLD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'M-Building and park in ambulance spot along the building. Call upon arrival. Enter building, complete health screening, masks must be worn. Contact will show driver how to find the Main kitchen in the basement to deliver product to walk-in cooler.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MN_Catering (comped) - Sales_Anoka Metro Regional Treatment Center',
          id: 'MN_ANOKA_METRO_REGIONAL_TREATMENT_CENTER__3301_N_7TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            "Check in with security - will ask the driver for a driver's license to be left at the desk. The front desk will then provide them with a temporary badge. Front desk is staffed 24/7.",
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MARQUETTE_COMMUNICATION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through double doors ont he east side, take elevator 1 floor up and walk to student lounge',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HCA_KINGWOOD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in south tower entrance, walk in front doors, take a right and the fridge will be on your right in a little alcove next to an ATM.  Very visible from the main hallway',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LI_STONY_BROOK_CHAPIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2104',
          id: 'NJ_TAGRET_T2104__4004_RTE130',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BABSON_OLIN_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through either the main entrance and use the elevator and go to the cafe floor or the back entrance and go to the left of the stairs when facing them.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Feb Board Meeting',
          id: 'IL_FEB_BOARD_MEETING__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please put the food in the double doored fridge inside the Cicero office',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_HTC_EDEN_PRAIRIE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the main doors, take the first right, Fridge will be located on the left side in the cafeteria.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HSBC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From loading dock, down hall, make a right then a quick left to elevator. 5th fl, come out make a left, walk down hall and make a left into the Pantry.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'USC Keck Medical Center',
          id: 'CA_USC_KECK_MEDICAL_CENTER__1500_SAN_PABLO_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Loading dock in the back of the hospital- the street is Alcazar go to the sign that says delivery. Check in with security at the door and take elevator to kitchen and merchandise!',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Keck Medicine / USC Med',
        },
        {
          opportunityName: null,
          id: 'NYC_WSJ_FUTURE_OF_EVERYTHING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd39742d4-1f3b-4d06-8578-3571adfd5944',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7b871a6f-4ca6-486e-a1f7-5d488656ad4d',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '150_N_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the parking garage off of Lake St, the fridge is located in the Lobby, in Gateway News alcove. Use the key for 150 N Riverside gateway store',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Roseville MI Warehouse',
          id: 'MI_COSTCO_ROSEVILLE_MI_WAREHOUSE__27118_GRATIOT_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back by receiving to deliver.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'UIC_PHARMACY_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front entrance doors, fridge will be in the main lobby area against a post. Please call Mike with any questions.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CHOC_NORTH_TOWER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the building and check in. Make your way to the second floor cafe and the Fridge will be there',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_7700 Parmer',
          id: 'TX_7700_PARMER__7700_PARMER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Call Michael @ 512-203-9750',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'IL_TARGET_3402__630_N_LASALLE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_CALL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Main entrance, take the elevator to the second floor, walk down the hallway take a right and the Fridge will be in their breakroom',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_MILWAUKEE_SHERIFFS_OFFICE_ROUTE4__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_SOUTH_QUAD_BLUE_CAFE__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Northshore Mall (Peabody), MA',
          id: 'MA_LIFETIME_NORTHSHORE_MALL_PEABODY_MA__210_ANDOVER_STREET_W171',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '77_W_WACKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PWC_CHICAGO_1_WACKER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Main loading dock entrance then take freight elevator to 9. Take a right, come through the door. See map',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOYOLA_UNIVERSITY_SULLIVAN_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the walkway directly into the front entrance. The fridge is directly across from the entrance doors next to the stairs',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Market Street At MacCracken',
          id: 'OH_MARKET_STREET_AT_MACCRACKEN__500_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Loading dock is off to the left on Maple Street- It is a one way Street. Ring doorbell and someone will be able to assist you',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UCLA HEALTH - AYS Catering RRMC',
          id: 'CA_UCLA_HEALTH_AYS_CATERING_RRMC__757_WESTWOOD_PLAZA_ROOM_B147E',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Check in with receiving at the dock and bring it straight up to the cafeteria- DO NOT MERCHANDISE THIS ORDER IT IS FOR CATERING. Inform the store keeper this is for catering not for Dinning Common.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'UCLA Health',
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_7',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '325d0936-6e03-4b4b-8bf7-735b763f227a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3208',
          id: 'IL_TARGET_T3208__3204_N_CLARK_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Arrive at loading dock check in and walk out to the main sales floor and stock display\n\nLook for Michael or Maya for the fresh market team leass',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'NJ_BMS_SUMMIT_S12',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come in through the entrance at 181 Passaic Ave and go to building S12.  Please contact Christian (973 951-2658) or Shira',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'MI_HENRY_FORD_WYNADOTTE_HOSPITAL__2333_BIDDLE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'push doorbell and call delivery contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Humble Warehouse',
          id: 'TX_COSTCO_HUMBLE_WAREHOUSE__21802_TOWNSEN_BLVD_W',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Monumental Markets #2',
          id: 'MD_SALES_MONUMENTAL_MAKETS_2__11800_TROLLEY_LN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Pull up main door and ring buzzer.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Lifetime - Ardmore, PA',
          id: 'PA_LIFETIME_ARDMORE_PA__40_EAST_MONTGOMERY_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_GLOBE_LIFE_FIELD_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security phone # 817-533-1803 Please call ~15min before arrival and let them know you are on your way\r\n\r\nPark in Lot T, and then proceed to the entrance on the East facing side of the building (Right off T-Lot).  There will probably be a sign that says “Please use Southeast Entrance”.  You can stay at that entrance and not go to the SE entrance. Call Security, and they will be able to pop the door for entry.\r\n\r\nOnce finished with stocking, please call Central Security, and let them know you are done and leaving the building.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ALEXIUS_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3230__30_DANADA_SQUARE_W',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_HENNEPIN_COUNTY_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the 8th street emergency entrance, check in with security, take elevators to second floor skyway. Fridge will be across from the elevator bank',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VIRGIN_HOTELS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_SIEMENS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'come up through the elevator and through the doors, leading out to the employee cafe area\r\n\r\nNO WEEKEND ACCESS',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Houston Methodist Hospital Main',
          id: 'TX_HOUSTON_METHODIST_HOSPITAL_MAIN__6565_FANNIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Dunn loading dock, Located off Bertner/Wilkins. Deliver directly to FNS Food Control located in Dunn B1, vendor can use designated FNS freight elevator. They will need to back down the ramp',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Houston Methodist',
        },
        {
          opportunityName: null,
          id: 'NJ_TOWER_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Lifetouch EP',
          id: 'MN_CANTEEN_LIFETOUCH_EP__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_LUKES_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on the street outside the main entrance, walk through main entrance and take a left after the security desk. Fridge will be right before the cafeteria entrance.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Wisconsin Department Distribution Center',
          id: 'WI_WISONCSIN_DEPARTMENT_DISTRIBUTION_CENTER__312_N_3RD_ST',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'enter the parking lot, the last door on the right in the rear of the building. Enter door to the right of the large overhead door. (KEYBOX ON THE BACKDOOR) Once inside the building, refrigerators are on your left labeled Farmers. LOCK BOX CODE: 7544',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales Liberty Mutual',
          id: 'MA_SALES_LIBERTY_MUTUAL__30_ST_JAMES_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Enter at 30 St. James Ave',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - BKN4',
          id: 'NY_DASHMART_BKN4__157_RAMSEN_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_DEPARTMENT_OF_EDUCATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go in the front doors, go through security and walk to the left. It’s right in front of you.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_JOHNS_HOPKINS_EASTERN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the Double Doors down the hallway to "vending area".',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_WHOOP_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'I (Thuy Nguyen) will be the main POC for this account but usually when vendors arrive at our building for deliveries, security will notify our Front Desk Associate, Wedner, to help with opening the door if needed. Our 8th floor doors are locked so Wedner will meet the driver upstairs to help open the door. We will not be able to issue badges.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JOHN_HANCOCK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Call security at (312)751-3695. Park in the back of building in the dock. Must consult with security in booth and provide insurance to gain access to dock.machine located in 13th floor in front o the gym',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3349',
          id: 'IL_TARGET_T3349__555_W_WEBSTER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Loading dock or front entrance if there’s a truck in the loading dock you will have to park in the street or in front of the store.\n\nAutumn',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Hudson ORD T5 M14',
          id: 'IL_HUDSON_ORD_T5_M14__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 5 Loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: '8de7e115-94da-4586-b1b3-ff4d7e99c3c3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AIRPORT_GATE_23_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at loading dock and walk to gate 23 and fridge is located across from the gate along the windows',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_SHADYSIDE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Main floor west wing cafe',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            "OH_Catering (comped) - Sales_St. Ann's Sampling Event",
          id: 'OH_ST_ANNS_HOSPITAL__500_SOUTH_CLEVELAND',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Front Entrance - call 380-898-4105',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_CTDI_BREAKROOM__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Joes Coffee',
          id: 'NY_CATERING_(COMPED)_SALES_JOES_COFFEE__443_PARK_AVENUE_S',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Samples can be dropped with doorman. Say they are for Mark Kushneir. If there are any complications please call delivery contact.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_WESLEYAN_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'As soon as you walk in the doors Fridge will be just to the left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_TEB9_MAIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'sign in with security like other amazons, the main breakroom is just to the right of security.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_TERMINAL_1_C25',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Kurt scheduling call for KB',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_COLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the side of the building next to statues, take the elevator to the 3rd floor',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3234',
          id: 'IL_JEWEL_OSCO_3234__102_W_DIVISION',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: In deli cooler located behind deli, on left. 1st white door to the right.  OTHER DELIVERY INSTRUCTIONS:  Product will be stocked in Deli cooler in marked FF Space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'NYC_JAVITS_4THFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_Suffolk University',
          id: 'MA_SUFFOLK_UNIVERSITY__73_TREMONT_ST_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please call Bob Cirame when onsite 978-604-9576',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_PENN_STATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through Front Door and walk towards Main Information Desk.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DOMINICAN_UNIVERSITY_PARMER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'SALES HUDSON CONFERENCE',
          id: 'IL_HUDSON_CONFERENCE__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Syed/Mike will pick this order up at Cicero on Tuesday Dec. 13th! Please pack in cardboard boxes.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_MEDICINE_GLENVIEW_OUTPATIENT_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please take the loading & receiving hallway to the 1st floor main entrance lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Westchester, NY',
          id: 'NY_LIFETIME_WESTCHESTER_NY__1_WESTCHESTER_PARK_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_EWR5',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'sign in with security like other amazons, turn left and walk short distance to the main break room. will be with the other vending machines',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CO_Catering (comped) - Sales_Hudson Colorado Springs',
          id: 'CO_HUDSON_COLORADO_SPRINGS__2420_PEAK_INNOVATION_PARKWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please contact delivery contact upon arrive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CITY_TECH_VORHEES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Check in with security at the main entrance and proceed to the Farmer's Fridge which is upstairs in the cafeteria area on the pillar closest to the vending wall.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FEINSTEIN_MEDICAL_RESEARCH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'On weekends and holidays, if the security officer is away from the front desk, there is a telephone on the wall in the building vestibule that connects to the hospital’s main security desk.  Please have your driver use that phone and explain the situation.  Hospital security will radio Feinstein security so they can return to the desk to let the driver inside.\r\n\r\nDuring weekdays, please have the deliveries made before 3pm when Materials Operations staff are still present.  There is also a phone on the outside of the loading dock door that says to call security at 1028 or 8444 for assistance.  When nobody is present, the driver can call this number and security will get someone to respond.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HUDSON_ORD_DISTRIBUTION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the front door by having one of the employees buzz you in. There is both a doorbell and you can be seen by the folks working inside. Walk straight back through the door immediatly in at the back of the room and find the fridge on your right. Door picture attached.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_UNIVERSITY_AKRON_UNION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to main hallway by turning left, walk all the way down until it dead ends, take a right and use elevator to get to 2, fridge will next to docuzip location on the left-hand side.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_TECH_GOODMAN_SOUTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Push button to call. Walk straight through doors take a left down main hallway, take first left, fridge will be against the back stone wall in the right corner.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NAVISTAR_BUILDING3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_EYE_EAR_THROAT_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'if loading dock, security will escort them from basement up to main floor to stock. if front entrance, security can also escort them to the fridge which will be near the main entrance\r\n\r\nsecurity phone #  212-702-7777',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB1_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter EWR Terminal B and walk to the Jersey on the Shore food hall. Find fridge just before the food hall',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_EXXON_BAYTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security will escort and send to the right location.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1167',
          id: 'IL_TARGET_T1167__2241_WILLOW_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Come in check in at the dock and ask for one of the leads they will have you put the food into the produce cooler and they will stock it\n\nAsk for Diego or manny',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AA_EMPLOYEE_LOUNGE_C2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_SAMSUNG_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_HOU6 TASTING',
          id: 'TX_HOU6_TASTING__12510_W_AIRPORT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'LEAVE AT COLD STORAGE',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WEST_LOOP_BROOKLYN_BOULDERS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If parking out front, walk through doors and fridge will be on the left\n\nIf parking in the parking lot, turn right on monroe, turn right on morgan, front doors will be on the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_ED',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen MN Samples 2',
          id: 'MN_CANTEEN_MN_SAMPLES__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Call Derek',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Vanderbilt University',
          id: 'TN_VANDERBILT_UNIIVERSITY__103_21ST_AVENUE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Progressive Field 2',
          id: 'OH_PROGRESSIVE_FIELD_2__758_BOLVIAR_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Please call Tylor Mettler at 7176861061',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_EAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take main elevators to lower level, turn left, then turn right and take that hallway past the cafeteria and the vending will be on your left after all the dining seating area.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_PRESTIGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter either front entrance, fridge is in main lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_BEVERLY_CENTER_MALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park, check in, take elevator up to 6th floor',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_RIVERSIDE_METHODIST_ASSOCIATE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Turn left in front of main entrance and follow road around to signs for loading dock\n\nGo through double dock doors on the far side of the building.  Make a right  as soon as you go through.  Continue and in a few feet you’ll see a set of elevators (the blue elevators). Take elevators to 1 and you’ll see a sign for cafeteria\n\nGo through double dock doors on the far side of the building. Make a right as soon as you go through. Continue and in a few feet you’ll see a set of elevators (the blue elevators). Take elevators to 1 and you’ll see a sign for cafeteria',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Grand Rapids Warehouse',
          id: 'MI_COSTCO_GRAND_RAPIDS_WAREHOUSE__5100_28TH_ST_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back near receiving to deliver',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_13',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_KNIGHT_SWIFT__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'WI_GOPUFF_244__6120_N_BAKER_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_SIGNATURE_AVIATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'go in front entrance, fridge is to your right in the breakroom on the right hand side of the hallway',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CIEVENT',
          id: 'IL_CIEVENT__800_E_GRAND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Makeda Cohran 312.405.1180',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NC_Catering (comped) - Sales Starbucks Henry Klein',
          id: 'NC_CATERING_(COMPED)_SALES_STARBUCKS_HENRY_KLEIN__7921_REUNION_ROW_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'Does Not Exist',
          deliveryInstructions: 'Air Shipment',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_FEDEX_NILES__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_WEST_12TH_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Please refer to same instructions drivers had been using for MOUNT_SINAI_WEST_CAFE',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_2020_RIDGE_AVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'IN_GOPUFF_88__850_MARIETTA_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_VA_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_VIBEZSTUDIO',
          id: 'IL_VIBEZSTUDIO__644_N_WELLS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL GLORIA',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_MUDD_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk into main entrance, turn right and you will see a set of elevators. Take elevators to floor 2 and exit right. Follow hallway all the way down, turn left and continue to the end of the hallway. Fridge is located on the right, near vending machines.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_RIVERSIDE_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Turn left in front of main entrance and follow road around to signs for loading dock\r\n\r\nGo through double dock doors on the far side of the building.  Make a right  as soon as you go through.  Continue and in a few feet you’ll see a set of elevators (the blue elevators). Take elevators to 1 and you’ll see a sign for cafeteria \r\n\r\nGo through double dock doors on the far side of the building.  Make a right as soon as you go through.  Continue and in a few feet you’ll see a set of elevators (the blue elevators). Take elevators to 1 and you’ll see a sign for cafeteria',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '97e5fc42-a735-4658-a20e-d1bda4a9e2b6',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_310_WISCO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3391',
          id: 'CA_TARGET_T3391__289_E_17TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_CANTEEN_MILWAUKEE__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Hudson DFW Sample pack',
          id: 'TX_CATERING_(COMPED)_SALES_HUDSON_DFW_SAMPLE_PACK__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drop samples to Store across from A12 and mention that these are samples',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN GREEN MEMORIAL',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_GREEN_MEMORIAL__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'MKE_UW_NURSING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the door, take a right and Revive Market will be on the right',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lululemon-Fuel Bar Wholesale',
          id: 'IL_LULULEMON__944_W_NORTH_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Please have the delivery driver come to the loading dock garage door, NOT the back door gate that is across from it\nThe dock should be open, but if it isn't please call Fuel at 312.202.6840 \nIf no one answers you can reach out to me at 480.209.8312\nOne of our team members will accept the delivery and bring it up to Fuel",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '66e4c824-cac2-4fc3-82bd-e9f781401bf5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_MICHIGAN_SCIENCE_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Hours 8:20a-4p, Tues-Sat.\nMap Pin:\nhttps://goo.gl/maps/YXXbkaYc224mT8u36\nPark at the dock. Actual dock or parking spaces is fine.\nUse the call phone box and say you're Farmers Fridge out at the dock and they'll buzz you in the entry door.\nFrom the entry door, take the first door on the right.\nIn the dock area, sign in to the vendor log on the table.\nThen, proceed through the door straight ahead from the table, and turn left.\nFollow along the left-hand wall past the bathrooms, and fridge is on the left past the science experiment workstation.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_PUBLIC_SCHOOLS_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon entering the building, ring the buzzer at the door to be let in. Driver must go and check in with security on the Dearborn side to obtain a temporary pass and or be escorted to the area. Freight elevator must be taken from dock to floor 1B which is the Garden Level to refill and or service the machine',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MDW7',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'After walking through doors at main entrance, take a left at security to cafeteria, fridge is located along the rear wall',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_HCA Medical - Arlington',
          id: 'TX_HCA_MEDICAL_ARLINGTON__3301_MATLOCK_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please drop off to food service director during regular fridge stocking schedule. Please call\nKaleb Newman at 682-509-3660 to have him put the food into cold storage. If he is not available, please ask a food service member to receive.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DFW6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "walk in the main entrance, go past security to the left, break room on the left, and straight back to the back wall under 'Frugality' words",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T1 C18',
          id: 'IL_HUDSON_ORD_T1_C18__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NYC_BROOKLYN_FEIRSTEIN_CINEMA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "When the driver arrives they will always be allowed to park at the loading dock to stock the fridge. They will also be able to enter at the loading dock, and there will be no restrictions on how they navigate to the fridge. \r\nJust please make sure to send me the Driver or Driver's contact info and I will add them to the guest list with freight access. Make sure to give them my contact information as well and I'll make sure they get in.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_CENTER_AT_600_VINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take loading dock entrance into main floor which is located directly off the vending tenant room, called Nexus Room',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 2056',
          id: 'TX_HUDSON_PHL_2056__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_UPS_EAGAN_H102__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Onsite News - BWI B Side Store',
          id: 'MD_ONSITE_NEWS_B__107_AIR_CARGO_SERVICE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Deliver to Bradford loading dock with instructions that product is to be sent to Onsite News B side location.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_APPLE_SND05',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk past the front desk and go right. Go through the first set of doors into the hallway where the Fridge will live.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_LUKE_SAMPLES',
          id: 'IL_SALES_LUKE_SAMPLES__834_WINCHESTER_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Please call Luke',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MONTEFIORE_EINSTEIN_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front door, fridge on left in waiting area.\r\n\r\nSecurity phone number is 718-405-8339 - call this number to have the gate opened for us',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson Group Nashville International Airport',
          id: 'TN_HUDSON_NASHVILLE_INTERNATIONAL_AIRPORT__1_TERMINAL_DR',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Same as OHM Nashville. This is to be delivered by Bradford to the A/B dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Conde Nast',
          id: 'NY_CONDE_NAST__1_WORLD_TRADE_CENTER',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MIDWESTERN_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "enter library - while you're still in the entryway, there is a door on the right that leads to a study room.  The Fridge is in the room adjacent to that, on the East exterior wall",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_NEWARK_BETH_ISRAEL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park on Lyons ave, head towards the hospital. Enter through main entrance. Security Desk make right to the elevator bank which will be on right. Take elevator up to the 2nd floor. Exit to your right and walk straight until you see Glass windows on right .....looking right you will be able to see fridge in the cafeteria. Make right into the cafeteria fridge straight ahead.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3455',
          id: 'IL_JEWEL_OSCO_3455__4355_NORTH_SHERIDAN_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'MO_STL_BARNES_JEWISH_CHILDRENS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Call 315-215-1952 to be escorted down to the fridge. Just identify as Farmer’s Fridge wanting to restock the fridge.\r\n\r\nWalk from the current Fridge to the Children's wing, take the elevator two floors down to the cafeteria\r\n\r\nCan call Tom Atland (314) 454-2643",
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_Virtua Mount Holly Hospital',
          id: 'PA_VIRTUA_MOUNT_HOLLY_HOSPITAL__175_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Drop off to Jeff in the Kitchen',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CDW_VERNON_HILLS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b0795aa4-6da1-45e1-bb73-3ead0e0f31cf',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_RECREATION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading and receiving location towards the rec center',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_D38',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'John Hancock',
          id: 'MA_JOHN_HANCOCK_90_ST_JAMES_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'LOADING DOCK GOT TO THE GATE ENTRANCE AND RING DOORBELL/ SECURITY WILL BUZZ YOU IN. SIGN IN AND GO TO 16TH FLOOR',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'John Hancock Investment Management Distributors LLC',
        },
        {
          opportunityName: 'Rivian - Normal Illinois Facility',
          id: 'RIVIAN_NORMAL__100_RIVIAN_MOTORWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading dock at building B. Take the main entrance and take a right.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Palantir',
          id: 'NY_PALANTIR_1__620_6TH_AVE_FLOOR_6',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Park in dock, freight elevator up to location',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'DC_Catering (comped) - Sales_McDermott Will and Emery',
          id: 'DC_MCDERMOTT_WILL_AND_EMERY__500_NORTH_CAPITOL_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            "Tell security you're meeting with Ja'Don McDermott",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_District Center',
          id: 'DC_DISTRICT_CENTER__555_12TH_STREET_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Loading Dock and call allison 202 394 1700',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_APPLE_PARMER_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in either side entrance to lobby - the coffeeshop is in the southwest corner',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ORD Rental Car',
          id: 'IL_ORD_RENTAL_CAR__10000_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to the same location as T1-T3',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Hudson MSP 2293',
          id: 'MN_HUDSON_MSP_2293__MINNEAPOLIS_SAINT_PAUL_INTERNATIONAL_AIRPORT',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pick up orders from Bradford with Fridge orders. Deliver to hudson news in the Main concourse next to sunglass Hut',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HAVEN_ELGIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the glass door and take a right at the lobby. The vending area will be in the same common space.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_MBF_WAUKESHA_ROUTE3__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_TEST_TEST_RECENT_FRIDGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mercy Health South',
          id: 'MO_MERCY_HEALTH_SOUTH__10010_KENNERLY_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DFW HQ',
          id: 'TX_DFW_HQ__2400_AVIATION_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Go through the front door and check in with the front desk. Micro Market is on the 1st floor. Please merchandise the product upon delivery (remove anything that is bad or going to be bad)',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Vuori GC',
          id: 'IL_VUORIGC__1003_N_RUSH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL AMY',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Canteen Samples',
          id: 'TX_CANTEEN_TEXAS__2747_W_GRAND_PARKWAY_N',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Chris will pick up at the Houston cold storage facility.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'United Health Wholesale',
          id: 'MN_UNITED_HEALTH_WHOLESALE__333_SMITH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'To get to the dock turn west on Chestnut between the Nasseff Heart center and the employee parking lot, go to loading dock. Enter through door 24, enter elevator, go down to the 1st floor, cooler is past a door to the right. Call contact if stuck',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Allina Health',
        },
        {
          opportunityName: 'Kettering Health Miamisburg',
          id: 'OH_KETTERING_HEALTH_MIAMISBURG__4000_MIAMISBURG_CENTERVILLE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Use delivery dock of hospital.  Located around the back of the facility. Deliver to kitchen or ground floor cafe',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CALAMOS_INVESTMENTS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park on the side of the building, enter code 1321 to get into into lockbox,  then enter cafeteria. Service technician front entrance place opens at 7am closes at 7pm walk to the back towards the restrooms fridge will be on the Right.\r\n\r\nMove-it can come in through dock located in the back. Client is trying to figure out a quicker way to get in the building for Driver's at night. In which they will park next to the fridge location .",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VA_DCA_TERMINAL_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through DCA Loading dock walk to fridge located in B terminal and find fridge at entrance to B side of the terminal',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JFK_T8_GATE_12_LEFT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through JFK T8 Loading dock and walk to the tunnel between both sides of Terminal 8. Find machine located at the tnrance to the tunnell',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LEWIS_UNI_LRC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_SE_EMS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'turn right at the cafe, right in to the ER hallway, left in to the CDU hallway, badge in there, the EMS lounge is on the lefthand side - the CODE TO GET IN 0911',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_SCHOOL_OF_MEDICINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park on Amsterdam ave between 138th & 139th street. Walk down the slight hill.\r\n\r\nWalk through main entrance. Sign in to security, turn right, walk down whole hallway. Fridge in Hallway Leading to the Student Center.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_MUNGER_BLUE_MARKET__530_S_STATE_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Come through the side loading dock, come in on Thompson Street to the circle drive on Richard Kennedy drive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Vanderbilt - HIGHLAND',
          id: 'TN_VANDERBILT_HIGHLAND__103_21ST_AVE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_4680_PARKWAY_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Main lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health Detroit',
          id: 'HENRY_FORD_MACOMB__15855_19_MILE_RD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'go to 2nd floor cafeteria ask for chef richard or a supervisor',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Apple Corporate Office',
          id: 'NY_APPLE_CORPORATE_SAMPLES__11_PENNSYLVANIA_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call Rommy at (646) 251-3599. Park in Loading Dock between on 31st street between 6th & 7th',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_BLVD_425',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_NCH_BEHAVIORAL_PAVILION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once in the building the driver will need to do the following:\n\n1.       Take the elevator down to the basement\n\n2.       Make a right turn and follow the tunnel into the BHP Building\n\n3.       Following the tunnel the driver will make a left  and then a right inside of the BHP\n\n4.       After making the Right turn, the kitchen is the first door in the left.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T1866__100_WILLOWPARK_CTR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FF_PRODFACILITY_CHI1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_176__69_W_CHELTEN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_SC_EAST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Address is 750 S. Halsted. Park in front , use badge to access front door. Walk in towards the right and there is an elevator in the corner, take it to floor 2. Exit the elevator and fridge is located past the escalators in the vending area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_PONDVIEW_PLAZA_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The delivery driver can park in the front lot and utilize the main entrance to access the building. The service elevator can be used to get to the 2nd floor where the fridge will be located.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_ABT',
          id: 'IL_ABT__1200_N_MILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL MARIA 847-913-5212',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the main entrance -> head towards the back right where the signs say "towards main stage and C-Store" Vending bank is in the back.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DTW_MCNAMARA_BAGGAGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'https://maps.app.goo.gl/f4WwkUHskKWaxwgi7?g_st=ig\nUpon your arrival, align your vehicle with the designated parking area located to the right, within the loading lanes. Park in close proximity to the entrance marked "Domestic Passenger Pick Up 3" by the overhead sign. This strategic parking will facilitate ease of access to the refrigerator. Upon entering the airport, proceed towards the rear, passing the elevators on your right. Beyond the elevators, execute a sharp right turn, leading you to a vending area where the refrigerator is prominently positioned.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLEX_700_NORTH_LUNCHROOM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter using badge through door 13. Fridge will be straight ahead right when you walk in',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'd0c52794-6b44-43d6-b751-1bf112b6d147',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_BRONX',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Covington and Burling',
          id: 'DC_COVINGTON_AND_BURLING__850_10TH_ST_NW',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in Front and tell the security desk in the lobby that the samples are for David Frischkorn with Sedexo.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Harvard Medical School - Wholesale',
          id: 'MA_HARVARD_MEDICAL_SCHOOL__200_LONGWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Depends on truck, time, etc.   Deliver to loading dock at below ground garage, use elevator to 1st floor.  If truck is small cargo van can plan to park behind the building and enter rear door on same level',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_WOODHULL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 91 – GN Resound',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_91_GN_RESOUND__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_HEART_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through East Entrance, go straight. Left at the end of the hallway then quick right.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MANHATTAN_VA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through security desk, take right when you enter the atrium lobby, we are in the first hallway outside the lobby and should be visible.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_GOODDAYNY',
          id: 'NY_GOODDAYNY__205_E_67TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'christal: (917) 202-5418‬',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JUMP_TRADING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Jump Trading is located on the 6th floor - fridge will be inside bedrock cafe.\r\nFor techs- from the elevators entry by the double glass door and turn left walk around 100feet then on the left you will see entrance in to the bedrock cafe.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '121_W_WACKER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front of building, straight through the main entrance, pass main desk by the stairs. The fridge is located in the hallway all the way to the back on your left hand side',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Dashmart - MAN8',
          id: 'NY_DASHMART_MAN_8___9_E_40TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AEROSPACE_CORPORATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'UNTIL WE HAVE BADGES - CALL TIM DOW (‪818) 480-1097‬) FOR ESCORT (6A-2P)\r\n\r\nDrive up to hut and show badge. Drive straight and then take a left. Enter through south east lobby. Scan badge. Go straight and left down blue hallway. Hang left to continue down hallway (don’t go up ramp). Go right, vending bank will be straight ahead with blue walls and large booth chairs.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hollywood Gaming Dayton Raceway',
          id: 'OH_HOLLYWOOD_GAMING_DAYTON_RACEWAY__777_HOLLYWOOD_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Loading dock; go around the back and ring the doorbell, warehouse ppl will be there. If no one responds go through the team member entrance and let security know',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST FOR RETAIL',
          id: 'IL_TEST_FOR_RETAIL',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_FTW3',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once past security the front breakroom will be right there, our Fridge will be straight back against the wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Chicago',
          id: 'IL_GOPUFF_173__5324_EAST_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RIVERVIEW_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "security at loading dock will escort drivers to the cafeteria, it's about a 5-7 min walk",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'PA_Catering (comped) - Sales_University of Pittsburgh SAMPLES',
          id: 'PA_SALES_UNIVERSITY_OF_PITTSBURGH_SAMPLES__3955_FORBES_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Call Mallory',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            "MKE_Catering (comped) - Sales_Roundy's Corporate Office",
          id: 'WI_ROUNDYS_CORPORATE__875_W_WISCONSIN_AVE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please alert delivery contact upon drop off',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ICNC_OFFICE_FRIDGE',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDA8',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Breakroom will be the first thing you see, Fridge will be against the back wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Amazon DC -- WAS 19',
          id: 'DC_AMAZON_DC_WAS19__525_14TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Pull to Loading Dock. Loading dock is on the corner of 13th and Edas. Meet security guard at dock',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Sales_Ohio State Samples',
          id: 'OH_OHIO_STATE__251_W_12TH_AVENUE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call Lesa when you arrive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_THE_E_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "**IF you don't have a badge, must come between 6a-6p.\r\n\r\nUse badge to get into the loading dock, use badge to get into second door on left, walk straight through door, turn left at lobby, make first left past the tv, fridge will be against the wall on the left\r\n\r\nAfter hours number: (952) 831-1001",
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UW_PHARMACY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevators up to 2nd floor. Walk until you see fridge in the cafe of 2nd floor lobby cafe area.',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3051',
          id: 'IL_JEWEL_OSCO_3051__9350_W_159_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_WEILCORNELL_7THFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HINES_VA_578_BUILDING_13',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through front door - go to main hallway on lefthand side and follow signs that lead to the "canteen".  The fridge is in a vending area on the way there, you can\'t miss it.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD H13',
          id: 'IL_HUDSON_ORD_H13__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'OHARE_T5_BAGGAGE_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Marketing_Harbor UCLA Medical Center',
          id: 'CA_HARBOR_UCLA_MEDICAL_CENTER__1000_W_CARSON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Call Jayne (608) 512-7731; as close to 1030am as possible please',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZONGO_WCHICAGO',
          type: 'DELIVERY',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Lindsay_Sales_LA Maywood Facility',
          id: 'CA_LA_MAYWOOD__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Lindsay / CJ to pickup at facility',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: '435 South Street 3',
          id: 'NJ_435_SOUTH_STREET_3__475_SOUTH_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Deliver to 475 South street',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - St. Louis',
          id: 'MO_CANTEEN_ST_LOUIS__5433_EAGLE_INDUSTRIAL_COURT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions:
            'go to loading dock. walk-in cooler is on the left',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MKE_MADISON_525_JUNCTION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Walk through the interior overhead door or man door, through the parking garage, and through the double-doors near the handicap parking. Turn left  down the short hallway, past the café on your righthand side and you'll see the dining/seating area with vending also on the righthand side.\n\n**map of directions is also attached**",
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SDAA_BREAKROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main entrance of the building and you will be at the secretary desk. They will point you in the right direction, but it is the first left',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Harvard Graduate School of Design Gund Hall',
          id: 'MA_HARVARD_GRADUATE_SCHOOL_OF_DESIGN__HARVARD_UNIVERSITY_GUND_HALL_48_QUINCY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Loading Dock, enter at the sign: Deliveries CGIS & Gund Hall 1737&1727.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMTRAK_UNION_STATION_CONCOURSE_LEFT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter right hand elevator and ride to level "C". Walk from concourse area where the elevator arrives out towards the great hall. Fridge will be directly in front of you upon exiting the Concourse.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MERCH_MART_1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in front go through the main doors, Front door on the riverside,W Merchandise Mart Plaza Lane where the cab line is, make a left on to the main hall. The machines will be located on your right. Go through handicap doors.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AMAZON_PIT5',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take front entrance to main floor break room',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_BROOKLYN_METHODIST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through entrance at 7th and 6th street and the fridge is located next to the coffee kiosk.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_United Way',
          id: 'VA_UNITED_WAY__701_NORTH_FAIRFAX_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park out Front and ask for Greg',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Evans Coffee & Vending',
          id: 'NJ_EVANS_COFFEE_VENDING__152_REDNECK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'DRIVER WILL BE GIVEN A KEY TO THE OUTDOOR COOLER- DROP OFF INTO COOLER',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IU_HEALTH_PATHOLOGY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through main door, take elevator up to 4th floor and fridge is located in cove of waiting room straight back.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'dd67a65e-b7f6-42ca-bbd6-6f367b24a947',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Spectrum Health Buttersworth',
          id: 'MI_SPECTRUM_HEALTH_BUTTERSWORTH__100_MICHIGAN_ST_NE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            '"Updated Instructions:\r\n\t1.\tReceiving is immediately after emergency\r\n\t2.\tPark in van only parking\r\n\t3.\tWalk up the ramp into the building and go to the right , make a quick left you’ll see brown double doors. Go through those and walk straight\r\n\t4.\tLook for gray door on your right\r\nhttps://maps.app.goo.gl/qmgieRW9xrwDaThB7?g_st=i"',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Spectrum Health',
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_Jenks Center',
          id: 'MA_JENKS_CENTER__109_SKILLINGS_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Call Vicki Drougas/Phillip Beltz, Administrative Secretary, at 781-721-7136',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Costco - Orland Park Warehouse',
          id: 'IL_COSTCO_ORLAND_PARK_WAREHOUSE__9915_W_159TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Park by receiving - off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName:
            'IL_Catering (comped) - Sales_Highland Park Hospital Earth Day Samples',
          id: 'IL_HIGHLAND_PARK_HOSPITAL_EARTH_DAY__777_PARK_AVE_W',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'ATTN: HP Patient Food Service Rm B110',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'TX_HOU_RICE_BROCKMAN_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at the Mudd Loop and go straight to the building.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ST_DAVIDS_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'park in the',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Pewaukee Warehouse',
          id: 'WI_COSTCO_PEWAUKEE_WAREHOUSE__443_PEWAUKEE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'Drive to the back near receiving to deliver',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MICHAEL_LEWIS_BREAKROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use badge to take door to employee lounge / Lunch room',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_SANNEH_FOUNDATION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in front of the main entrance, open the double doors if necessary, then cart the supplies into the lobby. A front desk staff person would be available to greet them.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St David Medical Center 1 Time Catering',
          id: 'TX_ST_DAVID_MEDICAL_CENTER_1_TIME_CATERING__900_E_30TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'The loading dock for the hospital is located on 30th street about ½ block east of Red River. You’ll see an elevated walkway from an office building (attached) to a parking garage. You’ll drive underneath the walkway to a gate (it may be open or closed)',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NJ_GOPUFF_612__700_CRESCENT_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T3326__5200_KINGS_HWY_STE_A',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Ohio State - Curl Market',
          id: 'OH_OHIO_STATE_CURL_MARKET__80_W_WOODRUFF',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Loading Dock off of High St - across from dunkin donuts, looks like a sidewalk',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'The Ohio State University',
        },
        {
          opportunityName: null,
          id: 'NOKIA_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_BURNSVILLE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the door take a right towards the cafeteria, Fridge will be located on the wall just right of the cafeteria doors',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Mitchel Hamlin Law',
          id: 'MN_CANTEEN_BOSTON_14__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Kirkland and Ellis - Chicago',
          id: 'IL_KIRKLAND_AND_ELLIS_CHICAGO__333_WEST_WOLF_POINT_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Take freight elevators passed security, use call phone, tell them you are going to the 37th floor, from there, Turn left out of the door, turn first right, past the elevators and open the glass doors to the cafe. Go in the cafe & deliver to storage closet',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Kirkland & Ellis',
        },
        {
          opportunityName: null,
          id: 'MO_STL_500_MARYVILLE_CENTRE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take hallway to use card reader for access, take a left off the dock entry doors to lower level cafe along cafe wall.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_DIGNITY_HEALTH_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge is in the main lobby once you walk through the doors\r\nOvernight, call security 20 mins before arriving and go to gate I.\r\nSecurity # is 310-630-2044.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '123',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_DEPARTMENT_OF_HUD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Point of Contact will escort drivers for the first few times to show the path to the machine. If POC does not respond call (202) 708-1955 or (202) 402-4321 and they will assist.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Greenberg Traurig Office',
          id: 'IL_GREENBERG_TRAURIG_OFFICE__77_W_WACKER_DRIVE_SUITE_3100',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Walk into the front of the building. Cindy will meet you at the main level',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 103 –Medivators 1',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_103_MEDIVATORS_1__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_COMER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the loading zone across from the front entrance. Park and go through front entrance past the security and you will see a set of elevators. Use your badge to take the elevator to the 3rd floor. Once you exit the elevators take a right and go down the hall, and the fridge will be right on the wall next to the other vending machines.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1090',
          id: 'MN_HYVEE_1090__7280_E_POINT_DOUGLAS_RD_S',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Kent College',
          id: 'IL_CANTEEN_KENT_COLLEGE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KAISER_PERMANENTE_ONTARIO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to cafe, fridge located in entrance across from coffee shop',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_U_ST_THOMAS_LAW_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through loading dock to the lower level lounge',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Allina Health Mercy',
          id: 'MN_ALLINA_HEALTH_MERCY__4050_COON_RAPID_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Arrive at the east entrance, and call (763) 236-8163',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Allina Health',
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_LANGSAM_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the ramp up towards the library and enter into the main doors where the fridge is located along the wall of the main seating area next to the Starbucks',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHERN_TRUST_801_S_CANAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_UNION_BEER_DISTRIBUTORS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "go in the shipping receiving door to the left of loading dock 1.\r\n\r\nCan't edit the weekend access field- this location closes on Saturdays at 1pm and open again on Sunday at 7pm.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_DHL_ELWOOD_BREAKROOM__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Bernard 1',
          id: 'MN_CANTEEN_BERNARD_1__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Target - T3422',
          id: 'CA_TARGET_T3422__19041_BEACH_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_6',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'One Stop C-Store',
          id: 'VA_ONE_JEWEL_CSTORE__4599_CHEsaPEAKE_ RIVER_LN',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CARS_COM_HQ',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_CAMPUSCENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_Wakefern',
          id: 'NJ_WAKEFERN__230_RARITAN_CENTER_PARKWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'come in via main entrance',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'IN_MARTINS_11__926_ERSKINE_PLAZA',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'fa29a92b-31bd-4bd7-9e2e-9ac566f43a88',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_BROOKLYN_WEST_QUAD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter via the West Quad entrance and check in with security. You can then go directly straight into the West Quad entrance and the fridge will be on the right. \r\n\r\nFor access prior to 7AM for either machine, please call the Public Safety Office at (718)951-5511. Since the campus is closed overnight, an officer will have to be dispatched to give access.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3292',
          id: 'TX_TARGET_T3292__8335_WESTCHESTER_DR_STE_200',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_WOODLANDS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The entrance of 45 south. third entrance to the right which has signage of the dock.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1d7eb6dc-611d-4c4b-8bca-f25eefd6fe24',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UW Health - Wholesale',
          id: 'WI_UW_HEALTH__600_HIGHLAND_AVENUE_MADISON',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Bring products to Mendota Market cooler if delivery is before 5:00am, after 5:00am receiving team will accept delivery.',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'UW Health',
        },
        {
          opportunityName: null,
          id: '261ca7ba-01c3-488c-b971-7d3184194720',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SLB_5950_COURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the front entrance and and press call button for security.\r\n\r\nSecurity is onsite 24/7 and will give a temporary badge upon signing in. Once stocked, sign out and return badge.\r\n\r\nIf security is away from desk, they will leave a note with their cell number. Fridge will be in the first floor near the canteen/seating area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB_SATELLITE_2_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter satellite B2 and fridge is right after security',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_VA_COLUMBUS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'north entrance, guard will be at the door, guard will guide them to fridge',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'COMMUNITY_FIRST_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DLF 70',
          id: 'TX_HUDSON_DLF_70__1003_N._UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Group drop to Dallas Hudson Distribution center',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'CHI_Catering (comped) - Sales_Jewel Samples',
          id: 'IL_JEWEL_SAMPLES__150_E_PIERCE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Enter building and take elevator to 4th floor - ask receptionist to deliver to Dave Ryan',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6522862e-8dbb-4889-b17a-37e304d01b28',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_UTA_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_PDHP',
          id: 'IL_PDHP__636_RIDGE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL BOB!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_USD_KNAUSS_SCHOOL_OF_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Please enter through double doors, pass the computers and Dash will be right there.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '75b92b66-fc4e-4e8d-8641-ddf44f61af2c',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Lakeside Warehouse',
          id: 'MI_COSTCO_LAKESIDE_WAREHOUSE__45460_MARKET_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back by receiving to deliver.',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS_DMOS_6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to the elevators, take that to the 3rd floor and the Fridge will be around the corner in the breakroom',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Algonquin, IL',
          id: 'IL_LIFETIME_ALGONQUIN_IL__455_SCOTT_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_COMMUNITY_TECHNICAL_COLLEGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '*Loading dock* instructions: drive up to the loading dock located on Spruce. Hit call button on the left, park in the dock and walk tot the end of the garage and open garage on the left. Take ramp up on the right and open garage, take first left through the double doors, elevators are at first door on the left. Take elevator on left to the first floor. Walk into the cafeteria and fridge will be at the vending bank. Once done, come through the dock and close garages on the way out (some of the buttons to close the door are on the outside and inside)\r\n\r\n*Front Entrance* instructions- Walk through the doors to the cafeteria, fridge will be next to the other vending machines in the vending bank',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LGB_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk into the baggage claim area to deliver to fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3426',
          id: 'IL_JEWEL_OSCO_3426__2502_NORTH_WAUKEGAN_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'LOCATION: In deli cooler, located on the deli side of store through double doors. Do not store in cooler where dock entry is located. DELIVERY : Steve (receiver), Mike and Ren (managers).  We are only allowed inside store when receiving opens.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: '8a2df67b-09dc-4a20-bdb2-d7d0e1752fcb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LENOX_HILL_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through Employee entrance located on 76th Street (Gold Glass Double Doors) Ring bell for security go through another glass door walk to end  take elevator on left to 2nd floor walk straight into cafeteria , fridge on left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AIRPORT_CONCOURSE_D',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter and go through security then walk to concourse D and find fridge to the left',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_NJ Hudson Corporate Event',
          id: 'NJ_HUDSON_CORPORATE_EVENT__1_MEADOWLANDS_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'deliver to lobby at Hudson Corporate office through contact Maria, she will meet you in the lobby upon call',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main doors and the Fridge will e in the lobby area',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Host SNA - Farmers',
          id: 'CA_LA_HMS_HOST_SNA_FARMERS__18601_AIRPORT_WAY',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Curbside delivery, call the delivery contact upon arrival for pick up',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'HMS Host',
        },
        {
          opportunityName: 'Onsite News - BWI A Side Store',
          id: 'MD_ONSITE_NEWS_BWI_A__107_AIR_CARGO_SERVICE_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Deliver to Bradford loading dock with instructions that product is to be sent to Onsite News A side location.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Anoka',
          id: 'MN_CANTEEN_ANOKA__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Morristown Medical Center 1',
          id: 'NJ_MORRISTOWN_MEDICAL_CENTER_1__100_MADISON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: 'Atlantic Health System',
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_VOGUE',
          id: 'NY_VOGUE__123_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL ROSE 678 231 3365',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Jewel Osco - 3470',
          id: 'IL_JEWEL_OSCO_3470__3570_N_ELSTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TEST_CANAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Cleveland State University',
          id: 'OH_CLEVELAND_STATE_UNIVERSITY__2121_EUCLID_AVE',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: 'Delivering to Joe Ettinger',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MCCORMICK_PLACE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Bowmanwille',
          id: 'IL_BOWMANWILLE__5430_N_CLARK_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_SD_SCRIPPS_ANDERSON_MEDICAL_PAVILLION',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go around the building and come in through front doors, fridge located in first floor alcove',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '225_W_WASHINGTON_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Pull up to the bay, badge in, go to the freight elevator at first left. Take it to LL, turn right and go through the door- fridge will be on the left',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '318_W_ADAMS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Trinity Cafe',
          id: 'IL_CANTEEN_TRINITY_CAFE__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - St Peters Warehouse',
          id: 'MO_COSTCO_ST_PETERS_WAREHOUSE__200_COSTCO-WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Pull to back of store by receiving.',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Located pre-security in the baggage claim next to the escalator',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_STJUDE',
          id: 'IL_STJUDE__624_W_LAKE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL ANDREW',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_CHRIST_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Driver can pull up to main entrance and knock on window (security said they will be there and open the door for our drivers). Fridge is located to the right of the gift shop.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_SIG_RIGHT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_POWER_PACKAGING__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T5 M12',
          id: 'IL_HUDSON_ORD_T5_M12__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 5 Loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'OH_Catering (Comped) - Sales_302 W Third',
          id: 'OH_302_W_THIRD__302_W_THIRD_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Call Sarah Blank Upon Arrival',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2693',
          id: 'MA_TARGET_T2693__250_GRANITE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in rear and entrer through the loading dock. Location opens at 6am.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'Five Star Food Service - Beavercreek - KHN MIAMISBURG',
          id: 'OH_FIVE_STAR_FOOD_SERVICE_BEAVERCREEK_KHN_MIAMISBURG__2152_HELLER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            "Go to loading dock - call dc if you have any q's",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_Vikings',
          id: 'MN_VIKINGS__401_CHICAGO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Sindy or Rob can bring to the event as they will be attending!',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST location',
          id: 'ID_TEST_TEST',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call delivery contact when you arrive',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            "Texas Children's Hospital - 1st Floor Physician's Lounge",
          id: 'TX_TEXAS_CHILDRENS_HOSPITAL_1ST_FLOOR_PHYSICIANS_LOUNGE__6651_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            "Go to loading dock, take freight elevator to 1st floor physician's lounge. Use ID to get in the door - remove old food, take any excess food to the 4th floor. Call Carol once in the hallway and she will guide you to the cooler (713) 503-6987",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "Texas Children's Hospital",
        },
        {
          opportunityName:
            'NY_Catering (comped) - Sales_Hudson JFK T4 Sample pack',
          id: 'NY_CATERING_(COMPED)_SALES_HUDSON_JFK_T4_SAMPLE_PACK__6_CENTRAL_TERMINAL_AREA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Loading dock Terminal 4 tell the guard we are here for Hudson.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WESTSIDE_RESEARCH_UI_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at the south entrance and walk through glass doors. Fridge will be across from the elevators on the left.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Southern Indiana University',
          id: 'IN_SOUTHERN_INDIANA_UNIVERSITY__1800_LINCOLN_AVENUE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            "Look for the UC West Dock. There is a round about as soon as you get off the expressway you will go right and follow that all the way around you'll see a lake and then there is a guard shack on the left and you will go to that area and up to the loading dock (UC West)",
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CLEVELAND_AVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8133de01-4f0b-4375-991b-938a1f1924b0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NJ_GOPUFF_74__401_JERSEY_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PRESENCE_SAINT_JOSEPH_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Bloomingdales LIC -- Wholesale',
          id: 'NY_BLOOMINGDALES_LIC_WHOLESALE__28_07_JACKSON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Enter building and tell security you are dropping off an order for Bloomingdales and Nate Horwitz',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Bloomingdales Corporate',
        },
        {
          opportunityName: null,
          id: 'NYC_HAVAS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'BLACKHAWKS_UNITED_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1948',
          id: 'NY_TARGET_T1948__2675_HORSEBLOCK_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'MKE_GLOBAL_POWER_COMPONENTS_184',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use same fob at MKE_GLOBAL_POWER_COMPONENTS\r\n\r\nAt the gate code, scan the fob. \r\n\r\nPull into parking spot or right up front and enter through the door to the left of the visitor entrance. Walk in, take a left, take a right straight down main hall, lunchroom will be on the left',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDA9',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the associate entrance, Fridge will be on the right wall in the breakroom',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Refreshments Route 45 – Abbot St Jude',
          id: 'MN_ARAMARK_REFRESHMENTS_ROUTE_45_ABBOT_ST_JUDE__2220_COUNTY_RD',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Please call delivery contact. go to loading dock and walk into the walk in cooler and drop the food off according to route (which is in the name of the shipping location)',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName:
            'VA_Catering (comped) - Sales_Washington Commanders Training Center',
          id: 'VA_WASHINTON_COMMANDERS__21300_COACH_GIBBS_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Pull into parking space and call Connor',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Target - T3304',
          id: 'MA_TARGET_T3304__822_SOMERVILLE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Park in lot at the rear. Ring door bell for reciever and enter through recieving. Stock near produce section NO DELIVERY BETWEEN 9 - 10AM. 7am receiving',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '8152050f-1b27-4aba-9290-2cdf7cabc120',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_BUSCH_STADIUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the loading dock for entry where there will be a bomb sniff dog upon entry. Once entered into the facility, head right and continue down past 1st warehouse. Take a right thru roll up gate leads to the ramps/switchbacks. Take their ramp onto the concourse. At the 1st exit off the ramp it will open up to he main concourse. Take a left. When you enter the concourse you’ll be at 132 you need to find 153 (left two section 153) on the lest had side you’ll see the 8th st market (roll down security gate)\r\n\r\nFor access:  call Phil (head of security) on your way to the site to be put on the approved list to deliver that day. We need to mind the cutoff times for arriving at this location on given days: \r\n\r\nWeekend - no cutoff time\r\nDay Game days - 9am\r\nNight Game days - 3pm\r\n\r\nPhil #: 314 345-9170',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e57d6517-ce59-4b4d-8a18-d2d8cea73420',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson JFK T5 - Bryant Park',
          id: 'NY_HUDSON_JFK_T5__TERMINAL_5_JETBLUE_BLDG_60G',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Drop to Hudson at JFK T5 loading dock',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Lone Star College CyFair',
          id: 'TX_LONE_STAR_COLLEGE_CYFAIR__18132_WEST_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Ted when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_SIG_2NDFLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CSU_LONG_BEACH_BREEZEWAY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'fridge located in outpost cafe c-store. Client to show during dry run.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '5TH_AVENUE_STATION',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - IIT',
          id: 'IL_CANTEEN_IIT__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_KINGWOOD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'TBD',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_TROY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the hallway diagonal to the right past the bathroom to the cafe area.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_OHIO_HEALTH_GRANT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Main lobby - the fridge is located right underneath the stairs next to the Just baked machine',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_Hudson DLF Sample Pack',
          id: 'TX_CATERING_(COMPED)_SALES_HUDSON_DLF_SAMPLE_PACK__1001_N_UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Drop with the DLF Hudson orders',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_RACHELEVENT',
          id: 'IL_RACHEL_EVENT__600_W_CHICAGO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'MAYA TOM 847.532.5084',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_USC KECK MEDICAL CENTER',
          id: 'CA_USC_KECK_MEDICAL_CENTER__1500_SAN_PABLO_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Please call',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MDW_SOUTHWEST_PILOTS_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the loading dock walk to the elevator go up to TSA once through TSA walk to A8 take\r\nthe elevator down walk to the pilot door entrance code walk around to the second door\r\nentrance the door code once in walk straight into the lobby fridge is on the right',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T3 L8',
          id: 'IL_HUDSON_ORD_T3_L8__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DFW_IOC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk in front entrance and head to employee break area',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Silver Pharmacy',
          id: 'IL_SILVER_PHARMACY__15426_S_70TH_CT',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Maisoun Farhoud, her office phone number is 708-789-1200 and her cell is 708-299-6543',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Silver Pharmacy',
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_TAUBMAN_JAVA_BLUE__200_OBSERVATORY_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target Stores',
          id: 'NJ_TARGET_T1153__570_ROUTE_70',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ADVENTIST_GLENDALE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Once you are parked there, come in from the parking garage come into the building through the main hallway corridor towards the elevator banks. You'll take that to level G where the cafeteria is located. The machine is along the vending bank of the cafeteria seating area\r\n\r\nFor overnight access, enter through Emergency Department",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_100_BAYVIEW_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Enter the building and go to the back right of the lobby towards the elevator and gym. You will see the Fridge shortly after entering. Security's contact number is 949.355.6620\r\n\r\n24 hour access - check in with security on site",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_CAPITAL_HEALTH_HOPEWELL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take elevator up to Floor 2. Walk to the right around the corner.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Feinberg Catering',
          id: 'IL_NORTHWESTERN_FEINBERG__251_E_HURON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'stock coolers -  In cases where the badge does not work, they have to go back to Security, and let them know to be let in or get another badge.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Northwestern Medicine',
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_SBD6_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Upon entering the main front doors, please make your way to security for check-in. After going through security make your way to the main 1st floor break room. The fridge is along the main back wall of the',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_MEMORIAL_HERMANN_CYPRESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in front doors, the fridge is right next to the cafeteria which is visible from the main entrance',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_TX Today',
          id: 'TX_TXTODAY__4805_AMON_CARTER_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'PLEASE CALL JESSICA',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T2_H1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter T2 and meet Bradford to recieve food. Heat to Gate H11 to drop food to fridge, find fridge on the left hand side.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ECHO_LOGISTICS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Scripps Encinitas Wholesale',
          id: 'CA_SCRIPPS_ENCINITAS_WHOLESALE__354_SANTA_FE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Loading dock- go to the farthest left door there is a key pad and ring the doorbell- there are people there 24/7',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Scripps Health',
        },
        {
          opportunityName: 'Qualcomm - Cafe N',
          id: 'CA_QUALCOMM_CAFE_N__5535_MOREHOUSE_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Parts Town [RT 4]',
          id: 'IL_CANTEEN_PARTS_TOWN_RT_4__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'DROP OFF',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_SHOPS_GRAND_AVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen-Main Break Room',
          id: 'IL_CANTEEN_MAIN_BREAK_ROOM__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_SMU_MAX_PLACE__6000_OWNBY_DRIVE',
          id: 'TX_SMU_MAX_PLACE__6000_OWNBY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_UMB_CAMPUS_CENTER_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Call David  once in Loading Dock.  240-904-1880',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'SL TEST',
          id: '3132132131213',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_KPMG_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Check in with Vanessa at the loading dock take elevator to 11th floor. CALL/TEXT Alex if there's any issues 323-972-7832",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            "Texas Children's Hospital - 1st Floor Physician's Lounge PM DROP",
          id: 'TX_TEXAS_CHILDRENS_HOSPITAL_1ST_FLOOR_PHYSICIANS_LOUNGE_PM_DROP__6651_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            "DROP IN THE MORNING - Go to loading dock, take freight elevator to 1st floor physician's lounge. Use ID to get in the door - remove old food, take any excess food that cannot fit to Bistro",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "Texas Children's Hospital",
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TWU_DALLAS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the elevator to the third floor, Fridge will be in the main area which leads to the stairs',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BSWH_PLANO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go straight to the elevators, take it a floor down, once you are off the elevator the Fridge will be to your left next to the TV.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '451_N_STATE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Philadelphia',
          id: 'PA_GOPUFF_30__3501_COTTMAN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hyvee #1531',
          id: 'MN_HYVEE_1531__16705_COUNTRY_ROAD_24',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UC IRVINE - Douglas Hospital Main Cafe',
          id: 'CA_UC_IRVINE_DOUGLAS_HOSPITAL_MAIN_CAFE__101_THE_CITY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            "Drop off to main cafe kitchen. Can call Bill if there's issues 949 701 3666",
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'UC Health',
        },
        {
          opportunityName: 'Hudson ORD T1 B14',
          id: 'IL_HUDSON_ORD_T1_B14__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BOOKMARKET_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UnitedHealth Group - Optum',
          id: 'MN_UNITEDHEALTH_GROUP_OPTUM__11000_OPTUM_CIRCLE_COMPED',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please park at loading dock north of the entrance. Large sign highlights Loading Dock.s\nPlease call Chad Dewald at 6514858309 upon arrival.',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_3RD_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Fridge located in the vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - Greater Midwest',
          id: 'IN_GOPUFF_32__2361_RAPPEL_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SOLDIER_FIELD_UNITED_CLUB',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WRIGHT_COLLEGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Black Rock',
          id: 'NY_BLACK_ROCK__50_HUDSON_YARDS',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Entrance is on 33rd st between 10th and Hudson blvd',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_BENTLEY_UNIVERSITY_DANA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through the ramp entrance and proceed to your left down the hallway. You will see the Fridge on your right.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_FOXTROT',
          id: 'IL_FOXTROT__2017_N_DAYTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'please knock and leave at front door',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_CAL_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Fridge located in lobby',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '3040_SALT_CREEK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If necessary, buzz in using the phone to get let in (if door is not open). Walk straight through doors through lobby, lunchroom will be on the right in the main atrium area',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Pittsburgh Forbes Street Market',
          id: 'PA_FORBES_STREET_MARKET__3955_FORBES_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions:
            'Go through the set of double doors (front door) and ask for Mallory or Missy',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'University of Pittsburgh',
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_Fields Grade Development',
          id: 'NJ_FIELDS_GRADE_DEVELOPMENT__1_HENDERSON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Please call Rob Giannone 201.656.6160',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Fox Vending Rt86',
          id: 'IL_FOX_VENDING_RT86__9717_S_76TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please drop in the double door coolers staged outside (Pepsi coolers). Contact Stu Spector for delivery 708-430-4600, or Cell 708-287-4155.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_CEWIT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_NEUCO__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Noblesville Warehouse',
          id: 'IN_COSTCO_NOBLESVILLE_WAREHOUSE__E_148TH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Drive to back by receiving to deliver. Off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Canteen Exton Branch',
          id: 'PA_CANTEEN_EXTON_BRANCH__150_GORDON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Pull around to Dock and ask for Mike or Eric',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - St Louis Park Warehouse',
          id: 'MN_COSTCO_ST_LOUIS_PARK_WAREHOUSE__5801_W_16TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Pull to the back near receiving and please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'MD_BAL_NOTRE_DAME_MARYLAND',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Take elevator up to 1',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_KETTERING_HEALTH_HAMILTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'After entering via loading dock, take basement hallway all the way down until you hit a dead end, take a left until you hit another dead end, take another left into the open dining area and you will see the fridge on the left with two other vending machines',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_UM Laurel Medical Center',
          id: 'MD_UM_LAUREL__7150_CONTEE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: 'Park out front',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'G_W_ELECTRIC',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ORD Nonstop B7',
          id: 'IL_ORD_NONSTOP_B7__10000_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Deliver to the same location as T1-T3',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'KLUCZYNSKI_FEDERAL_BUILDING',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Mansfield, TX',
          id: 'TX_LIFETIME_MANSFIELD_TX__1551_E_DEBBIE_LANE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_AMAZON_ONT6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Take the front entrance where you must use the vendor badge at security for access. Take the front entrance past security to your left where you'll find the fridge in the main break room cafeteria.",
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_1500_POST_OAK_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Get escorted to the service elevators, and walk to the the cafeteria.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_CLIFTON_COURT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: "It's on the main floor near vending",
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HIGHLAND_LANDMARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '77557922-c672-4f14-8bae-292acf26213e',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_AIRPORT_AA_BREAKROOM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Once you go through the same process of getting the food inside like the other fridges.  Walk toward the elevator you use to go back upstairs, walk past the elevator and turn right at the corner.  You will walk straight and follow the hallway all the way till the end of the terminal.  You will have to walk outside 2 different times to follow that hallway, just exit and enter the doors directly in front of you.  The hallway turns, but just keep walking down it and follow it.  At the end of the hallway, turn right and walk to the end of that hallway.  There will be a door BC1154, scan the badge and go through the doors and turn left. There will be a break room on your left, turn there and the fridge will be on your left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_COLUMBIA_STMARYS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through the ED and then have security badge you into the cafeteria. Security has a desk in the lobby of the Emergency Department, 414-585-1031\r\n\r\n(Head to the right around the corner to the elevators, head down to the lower level, off the elevators head towards the cafeteria, Fridge will be in the round room before you get to the cafeteria)',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MD_Catering (comped) - Sales_University of Maryland',
          id: 'MD_UNIVERSITY_OF_MARYLAND__7093_PREINKERT_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Park in Loading Dock. Contact: South Campus Market Phone Number:   (301)314-2016 If there is no answer, call 301.314.8516',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3342',
          id: 'TX_TARGET_T3342__901_E_5TH_ST_STE_40',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_TEXSAN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Through the main doors, take a right towards the cafeteria, Fridge will be against the right wall in the dining area',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PUBLIC_SAFETY_TRAINING_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Walk into the main entrance, upon entry you'll need to sign in by showing your drivers license they'll buzz you into the main 1st floor seating area. The fridge is located directly off the front entrance along the main cafe wall across from the microwaves",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_G_CONCOURSE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come into main mall at T2 in MSP. find Fridge at the entrance to G concourse on the far side of the mall.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KEHOE_DESIGNS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_USC ARCADIA HOSPITAL',
          id: 'CA_USC_ARCADIA_HOSPITAL__300_W_HUNTINGTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Please call DC',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Sales_Dover Corporation',
          id: 'IL_DOVER_CORPORATION__3005_HIGHLAND_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call Jayne Greybar - cell: 630 743 5114 or 708 732 0230',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'VA_Catering (comped) - Sales_Nestle USA',
          id: 'VA_NESTLE_USA__1812_N_MOORE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in loading dock and call Hilary 703.309.7863',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HUDSON_COMMONS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 3487',
          id: 'IL_JEWEL_OSCO_3487__2485_HOWARD_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_4047__6215_MAIN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '125_S_CLARK',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_HOWARD_CC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take freight to 2nd Floor, turn right towards the vending room',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7fff0e8f-9f9a-4971-9c39-5d1051b20fda',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MDW2_ENTRANCE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'When you arrive, tell security you are stocking the Farmer’s Fridge and our contact is Prashanth Reddy Nomula (650.745.5640)',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MO_Catering (comped) - Sales_WASHU Samples',
          id: 'MO_WASHU_SAMPLES__1_BROOKINGS_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please call claire when you arrive\r\nloading dock on the South40',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__7008_N_CLARK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: "call DC if there are q's",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JUILLIARD_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at loading dock, check in with security and they will escort you to the fridge.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CLEAR NYC',
          id: 'NY_CLEAR_NYC__85_10TH_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Take freight entrance between 10th and 11th and go to the 9th floor. Call if there are issues.',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Clear Secure',
        },
        {
          opportunityName: 'AMAZONGO_ICH3_MADISON',
          id: 'IL_AMAZON_GO_ICH3__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'pickup at cicero',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Amazon',
        },
        {
          opportunityName: 'Kaiser Permanente - Ontario Medical Center 2',
          id: 'CA_KAISER_PERMANENTE2__2295_S_VINEYARD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_DAYTON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park out front and go left into the cafeteria',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Sales_Canteen MN',
          id: 'MN_SALES_CANTEEN_MN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3268',
          id: 'NY_TARGET_T3268__615_10TH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'MKE_PROHEALTH_WAUKESHA_MEMORIAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at the emergency room entrance. Walk through the front doors, check in with security, take an immediate left, walk down hallway until next opening on the left (west lobby), take elevator up 1 floor, exit off elevator and go into the hallway, walk down the hallway passed the coffee bar, fridge will be in the alcove by the piano.\r\n\r\n*in the west lobby, vendor badge is right there on the left as go through the door',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD L4',
          id: 'IL_HUDSON_ORD_L4__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Hudson ONT',
          id: 'CA_HUDSON_ONT__2900_E_AIRPORT_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Drop order to the Hudson INK Store in Terminal 4 across from AA gate 208',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'OH_OHIOHEALTH_DOCTORS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NEUBERGER_BERMAN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Baxter Healthcare',
          id: 'IL_BAXTER_HEALTHCARE__ONE_BAXTER_PARKWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Driver will 1st check in with security in building C to retrieve badge (bring ID). After retrieval, they will continue ahead, following signs to loading dock. Use telecom next to dock door to buzz security for entry. Once in, use badge to open blue doors',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Sales_Bryn Mawr College',
          id: 'PA_BRYN_MAWR_COLLEGE__816_NEW_GULPH_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Lisa upon arrival',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - UCMC',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_UCMC__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Five Star Food Service Inc',
        },
        {
          opportunityName: null,
          id: 'CA_SD_FOUNDATION_MEDICINE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use intercom system at the main entrance by the door. \r\n\r\nIf the driver presses the button, it will dial our desk or command center for assistance. Security phone # (617)245-3433.\r\n\r\nEnter the building and check in. Make your way to the second floor break room where the Fridge will live',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_CREE_LIGHTING_NORTH_ROUTE7__12100_W_WIRT_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_GR_TRINITY_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "https://maps.app.goo.gl/YCRCQEuHhyMrV57D6\nHours:  6:30am to 7pm\nWe can park in their parking lot and the front door, as pictured, is right there.  Swipe badge to gain access.  Once inside take an immediate right turn and another right turn and you will see the machine.  In the picture of the machine that glass door on the right side is the entrance you come in, so it's very close by.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1d55e1da-ef6a-4dc0-a7c0-eb3126864310',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'JESSE_BROWN_VA_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in roundabout, enter through revolving door. Fridge is directly next to front desk. This fridge will be on the right of the current fridge',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Cincinnati - Market on Main',
          id: 'OH_UNIVERSITY_OF_CINCINNATI_MARKET_ON_MAIN__2701_BEAR_PATHWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Contact Tiffany when you arrive',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '41f764d3-e4d3-4e05-a52f-3c6245f12f41',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_AMAZON_SAT2_3RD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front entrance, there is an elevator that takes you to the 3rd floor - the breakroom is against the South wall, same side as the 1st floor breakroom where there is another Fridge.',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB_SATELLITE_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park out front in usual location, take product through TSA. To access the fridge call the Fraport Duty Manager Phone at  412.598.2072.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_RARITAN_BAY_OLD_BRIDGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'drivers will be escorted to main area via the loading dock',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '525_VAN_BUREN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_ST_DAVIDS_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in north entrance - the fridge is in the physicians lounge directly in front of the entrance.  Driver will need to ask a member of the cafeteria staff to open the door to the lounge.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Lifetime - Westwood, MA',
          id: 'MA_LIFETIME_WESTWOOD_MA__44_HARVARD_STREET',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_READ_EL_BISTRO__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_John Samples',
          id: 'CA_JOHN_SAMPLES__13603_MARINA_POINTE_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'Call John 773-993-5359',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_Reach Media',
          id: 'DC_REACH_MEDIA__1101_NEW_YORK_AVE_NW_SUITE_800',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions:
            'Park in Loading dock around the building on 12th street and tell security that the order is for Yocleyn at reach media, suite 800',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU3',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front - after you check in with security, the main breakroom is directly to your right. The Fridge is',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CVG_CONCOURSE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use badge to enter building entrance from the loading area. GO to elevator to 3rd floor and exit into the foodcourt area. Fridges are in the vending bank area.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WOODFIELD_PRESERVE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CITY_HARVEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '53rd street loading dock, if allowed. Park in dock. Go through blue door, make a left and go to freight elevator on left, 2nd floor. Walk straight out and make a left. Now if they don’t allow the loading dock, go to the door on left of it. Walk straight to the big freight elevator, to 2nd fl. Doors open, walk straight and make a left into the pantry.\r\n\r\nIf someone is unable to find parking they can call and enter through the truck gate. When your staff arrive we can let them into our parking lot. Once driver is in the parking lot they should leave their vehicle parked along the wall. The number they should call is 646-412-0604 where there would be a security guard that answers. Driver should mention that they are farmers fridge here to restock machine. Once driver parks they will have to walk out the lot and enter thru the 52nd street main entrance to sign in with our front desk reception/ security guard. If there any issues please ask your driver to ask for me.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WARREN_BARR_GOLD_COAST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Pull into the loading dock garage and press the call button to connect with the receptionist. Walk straight to the door, and then turn left past the elevator bank. Turn left again and walk to the seating area. The fridge will be against the back wall.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_101_WOOD_AVENUE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter loading dock, follow signs for service elevator. Take to the first floor, labeled LR. Hold the door open button once you arrive at the floor to open the door to the lobby. Exit right and machine will be on your left.\r\n MUST use Masonite to protect the floors When install happens. Loading dock open 7am-5pm.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MBX_SYSTEMS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_WESTCHESTER_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "They can contact Al if during biz hours, if not just sign in with loading dock and take freight elevator to the first floor, they'll go toward main entrance and the vending bank near the Burn hallway.",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_PARKER_HANNIFIN_IRVINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter and check in. Go through the main doors and take a left. Walk down the hall and you will see the Fridge in the cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_Vibez',
          id: 'IL_VIBEZ__8_W_MONROE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'CALL GLORIA 773-230-1263',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Hyvee 1640',
          id: 'IL_HYVEE1640__2115_S_MACARTHUR_BLVD',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Park in the rear by loading dock, enter through door marked w maroon sign explaining delivery hours. Check in with Kevin in the office immediately ahead on your right. If Kevin is unavailable ask for Ronnie the Produce manager or Jamie the store manager.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T3 K1',
          id: 'IL_HUDSON_ORD_T3_K1__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 3 Post 9.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'MI_Catering (comped) - Sales_Sachse Construction',
          id: 'MI_SALES_SACHSE_CONSTRUCTION__3663_WOODWARD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Call Monica',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADDISON_PARKS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_AMAZON_MKE5_ROUTE1__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3062__2317_75TH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_ST_JOSEPH_ASCENSION_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'For a few weeks, Security will escort the driver to Elevator J and guide the assigned driver to the designated area where the vending machine will be housed.\r\n\r\nIf issues, please call Security # 414-447-2154',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_ST_MARYS_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the ER past security to the main llobby. Walk down the hall towards "The Beestro"',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_VPEVENT',
          id: 'IL_VPEVENT__939_W_FULTON_MARKET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Call Kristin!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_AMAZON_HOU8',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in vendor parking out front, if the truck doesnt fit then park on the side of the building. Walk in the front door, go to the left and the fridge is in the breakroom',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'testlocal123',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson - JFK T8',
          id: 'NY_HUDSON_JFK_T8__BUILDING_56_LOADING_DOCK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Deliver to the loading dock at T8',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_FASTCO',
          id: 'TX_FAST_CO_FRI__201_BRAZOS_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions:
            'FAST CO EVENT - PLEASE DELIVER BEFORE 9:30AM AND STOCK FRIDGE AT EVENT! THANK YOU!',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_ HTeaO',
          id: 'TX_HTEAO__1322_RANCHERS_LEGACY_TRAIL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Call Heath Nielsen 312-813-8656',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mckesson Wholesale',
          id: 'TX_MCKESSON_WHOLESALE__6535_STATE_HIGHWAY_161',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Go to loading dock and call DC',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: 'Hudson LAX 2148 TBIT Wilshire & Fairfax',
          id: 'CA_LA_HUDSON_LAX_2148_TBIT_WILSHIRE_FAIRFAX__1_WORLD_WAY',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Bring food to Hudson Store "Wilshire and Fairfax" in the West gate area of the Terminal. Hudson will distribute from there.',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Canteen - Fresenius Kabi Ruby St.BR1',
          id: 'IL_CANTEEN_FRENSENIUS_KABI_ST_BR1__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_660_NEWPORT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once parked at the docks park in any open bay or space. Use the dock to enter into the lower level double doors with the fob. After entering into the double doors the hallway will enter you into the lower level lounge the fridge is along the wall of the lounge.\r\n\r\nAny issues - Please call Security 949-729-7272.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHSHORE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_SAMPLES SALES MAY22',
          id: 'TX_SAMPLES_SALES_MAY_22__120_BFG_9000_WY',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'EDDIE TO PICK UP',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'NJ_RUTGERS_NEWARK_ROBESON',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the door at the dock, there is a buzzer there to call the kitchen if needed, once inside, go in the door on the left, once in the kitchen turn left, then right and go to the door straight ahead, once your in the food area, fridge will be just outside the entrance on the right side.Delivery must be made before 10pm',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CAMPINGWORLD_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'f2bbc726-f5b4-4f27-b839-96a94b5daeca',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_CCD_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Boston 14',
          id: 'MN_CANTEEN_BOSTON_14_2__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'NYC_MSKCC_RESEARCH_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'enter in through front entrance, veer to the right side go down the hallway and there is a vending bank',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3229',
          id: 'NY_TARGET_T3229__255_GREENWICH_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_17TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in through security, fridge located in vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_TRIHEALTH_BETHESDA_CVICU',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take service elevator to 1st floor, CVICU is in main hallway',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MET_EMPLOYEE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_HARVARD_NORTHWEST_LABS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LURIE_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Go through the Loading Dock (Located 200 E. Superior). Drivers will need to bring a BOL or item list to present to security, along with a Drivers License.\r\n\r\nFrom Maurice during dry run  "You enter the loading dock off superior and turn into a parking spot by the dock . Give security your ID and they will give you a badge for the elevators. Enter the building and take the service elevators on your left up to the 11th floor. Make your way down the hall to the sky lounge and as soon as you walk through the door , fridge will be on your left directly in the middle of the room."',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOSTON_HARVARD_MEDSCHOOL_COURTYARD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk into the 200 building (Warren Alpert building) and go straight Courtyard Cafe. Any problems please call 24 hour security at (617) 432-1379. During business hours you an also call Foodservice contacts: Esther at 617.432.0460 or Chris at 617-432-6169',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Walser Toyota',
          id: 'MN_CANTEEN_WALSER_TOYOTA__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MKE_411_E_WISCONSIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Go through front door, before security turn left, into the Davians cafe\r\n\r\nI spoke with our Security team about food deliveries and they said that typically in the past, since your deliveries usually occur in the early morning hours (2am-5am), Farmer's Fridge will pull into the loading dock (located on Milwaukee Street, in between Wisconsin Ave & Michigan Street), check in at the Security desk, and Security will issue them a badge to use the freight elevator to get up to the lobby cafe where the fridge is located. No matter the proposed delivery timing, since our Security team is onsite 24/7, I would recommend that we stay this course going forward. Please pass this information along to your teams, and let me know if you have any questions.",
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ST_JOHNS_UNIVERSITY_HENLEY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Greyson to update.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'AMAZON 5 MANHATTAN WEST',
          id: 'NY_AMAZON_5_MANHATTAN_WEST__459_W_31ST_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'GO TO LOADING DOCK - AMAZON OFFICE IS 7TH FLOOR',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FRIDGE_IN_THE_WILD_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2373',
          id: 'IL_TARGET_T2373__4466_N_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Back in to loading dock then door to the receiving are is on the other side of the trash compactor inside a gated area. Ask for andrea (receiving supervisor) she will have you sign in and put the food in their receiving cooler.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'ULINE BACK [ROUTE 1]',
          id: 'WI_ULINE_BACK_ROUTE1__12100_W _WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Tufts Medical Center',
          id: 'MA_TUFTS_MEDICAL_CENTER__25_HARVARD_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'NEW: Directions to Chop’d Cafe: Upon entry to the hospital via the 800 Washington Street entrance, take the Atrium elevators to the 3rd floor. When you exit the elevators, walk down the hallway on the left hand side. The café will be located directly afte',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MACYS_STORY_HERALD',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_CHOP_CARDIAC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through double doors on left side of loading dock. Turn left at first hallway and take elevator to 6th floor. Exit and turn right',
          fulfillmentCenter: 'PA_Philadelphia',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '98d63905-2710-49a0-8d95-bdff8b824313',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Avenel NJ - Canteen',
          id: 'NJ_AVENEL_NJ_CANTEEN__190_HOMESTEAD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Enter parking lot straight on and ring bell at gate to enter through our dock. Place order in walk in cooler to the left once entering building from warehouse.',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_JLL_16001_PARK_TEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk to the lobby where you will see the food area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '550_W_ADAMS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_3/26 - SALES SAMPLES',
          id: 'TX_SALES_3_26__3600_PRESIDENTIAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: 'Eddie will PICK UP IN TAYLOR FACILITY',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'PHI_BRYN_MAWR_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AIRPORT_CONCOURSE_C',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'From entrance come through security, walk to Concourse C and Find Fridge located immediately at entrance to concourse on your right hand side.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_DIVISIONS_MAINTENANCE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the front entrance to the employee cafe -',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UBS - Wholesale NY',
          id: 'TBD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_AUSTIN EVENT',
          id: 'TX_AUSTIN_EVENT__1212_DOLTON_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'FOR NESTOR TO BRING TO AUSTIN!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_ORANGE_COUNTY_GOV',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the main entrance and check in with the front desk. Fridge will be just down that main hallway on the left by the elevators',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHBROOK_COURT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_WSP',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson ORD T5 Baggage Claim',
          id: 'IL_HUDSON_ORD_T5_BAGGAGE_CLAIM__10000_W_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliver to Loading dock at Ohare Terminal 5 Loading dock',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: '04ee5f1a-0b6b-4ac3-b676-cf563df7d899',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir - Rodgers Park',
          id: 'IL_RODGERS_PARK__7008_N_CLARK',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SCHAUMBURG_DISTRICT_LIBRARY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through west entrance and location will be directly on your left in the snack area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_SAT_CONCOURSE_B',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Tbd',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Frisco Warehouse',
          id: 'TX_COSTCO_FRISCO_WAREHOUSE__11220_DALLAS_PKWY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'CA_LA_HOME_DEPOT_ONTARIO',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the front door and check in with the desk. Make your way down the hall and on the left you will see the break room where the fridge will be',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Sales_Rue Gilt Groupe',
          id: 'MA_RUE_GILT_GROUPE__281_SUMMER_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Contact Alicia Liu (973-722-3649) who will help to coordinate delivery',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_North_Park_University',
          id: 'IL_NORTH_PARK_UNIVERSITY__3305_WEST_FOSTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Please call the delivery phone number!',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'TX_Catering (comped) - Sales_University of Texas - Arlington',
          id: 'TX_UNIVERSITY_OF_TEXAS__701_S_NEDDERMAN_DRIVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please contact the food service director when arriving on site for the regular fridge stocking for him put into cold storage. Sean Armstrong 484-354-0814. If he is not available, please contact some in food services to put into food storage and confirm name of that person.',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_LYONS_VA_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go in through the doors and straight to vending bank',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_JUILLIARD_SCHOOL_ROSE_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park at loading dock and check in with security. Use same elevator to access other fridge and get out on the main floor in the lobby. Walk straight out of elevator walk out of glass doors on right and walk on outside walkway. Rose building is straight ahead. Take elevator to the 11th floor. Follow hallway toward other set of elevator, fridge with be in front of them.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_HOUSTON_METHODIST_CLEAR_LAKE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'If coming outside of 5am-7pm, press security call button for access. Go through loading dock, walk to the cafe, fridge located in entrance to cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TEXAS_STATE_DEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'park in front go in through the main door, the vending room is upstairs from the food court',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1431_OPUS_PL_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The fridge location is right inside the doors from garage',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_VOICE_OF_AMERICA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Loading dock located on ground level of building.',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '321_NORTH_CLARK_ST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Using same badge as other tenant lounge fridge, check in with security, fridge is located in north end of the main lobby',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'ULINE BACK [ROUTE 1]',
          id: 'WI_ULINE_BACK_ROUTE1__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OHARE_3K_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Terminal 3 next to K6, past foodcourt and Frontera Grill',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_SATELLITE_UNIT_DECIO__DUNCAN_STUDENT_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call cody upon arrival (574)631-1537',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_BASEMENT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park and enter through East Entrance. Go straight down hallway. Make a right at the Heart Center. Go down hallway following signs for Emergency. Make a left after the elevators following signs for Emergency. Go all the way down the hall, machine is on the left by the other vending.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir Wholesale - Greater Chicago',
          id: 'IL_GETIR__2882_N_MILWAUKEE_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_FOUND_STUDY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '6308dfd6-d2a9-4c26-bd1c-cbbdaf6895fe',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_ALSTOM_TRANSPORT_INC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'For restocking of the machine should be at the front entrance to access the elevator to the second floor breakroom. Go down the back hallway and into the break room (NOT THROUGH THE OFFICE ENTRANCE)',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CVG_CONCOURSE_A',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'There is a loading area immediately outside the window where the fridge will be located. Archie at CVG said driver will check-in and be escorted to loading area. From there, driver will take an elevator directly to concourse walk out the door and fridge will be on the right.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_FULTON',
          id: 'IL_ICNC__2000_W_FULTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'PLEASE LEAVE IN FULTON OFFICE FRIDGE IN TEST KITCHEN',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Getir - River North',
          id: 'IL_RIVER_NORTH__125_W_HARRISON',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'SoFi Stadium Catering 6/14/2024',
          id: 'CA_SOFI__1001_STADIUM_DRIVE_INGLEWOOD_CA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Once onsite, driver can follow the directions below to get down to Level 1 and park directly next to the fridge (see map attached). Driver will need to check in at the guard booth at the top of the ramp. Note that there will be a K-9 unit to screen the ve',
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'SoFi - Wholesale',
        },
        {
          opportunityName: null,
          id: 'CA_SD_NORTHROP_GRUMMAN_SPECTRUM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main door, check in and you will go down the main hall and see the fridge',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_DAY_KETTERING_HEALTH_SOIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter door and you will take an immediate 180 degrees right and you will see fridge at bottom of staircase',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson PHL 1856',
          id: 'PA_HUDSON_PHL_1856__8000_ESSINGTON_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Emmanuel College - New Residence Hall',
          id: 'MA_EMMANUEL_COLLEGE_NEW_RESIDENCE_HALL__400_FENWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            "Service elevator entrance between Jean Yawkey Center and New Residence Hall(where c-store is located). Intercom system to buzz cashier who can unlock the door.\r\n\r\nIf there's any trouble, call Patrick 617-290-8869. he's on campus by 7am.",
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WOODFIELD_CORP_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Test',
          id: 'IL_TEST__123_Fulton',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'WGN_TV',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter at security gate and park in receiving dock area. Walk down receiving hallway turn left towards front entrance area. Fridge will be located in the break room on the lefthand side.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Rockwall Warehouse',
          id: 'TX_COSTCO_ROCKWALL__1225_STATE_HIGHWAY_276',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_WESTERN_PSYCHIATRIC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'The Loading dock is limited for drop offs and is rarely occupied now that our emergency room has moved to 5. On the rare occurrence they can’t get in, please call their Dispatch at (412) 586-9742 and they will dispatch a police officer to the location to assist. \r\n\r\nThey may even call our police desk directly at (412) 246-6045.\r\n\r\nTake the loading dock elevator to 4th floor - cafeteria is there',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_16TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Located in the Vendateria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_ULINE_MAIN_ROUTE1__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_JORDAN_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T1798',
          id: 'NY_TARGET_T1798__40_W_225_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_SC_JOHNSON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_HNE1',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'sign in with security, turn left, main breakroom is on left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GLENOAKS_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_BOOKMARKET__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_330_E_KILBOURN_NEW',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Same as before\r\nFront entrance - use the main entrance on the left site of the security desk you will see the room behind the glass wall, enter the room and fridge will be located in the left back corner visible to everyone',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_ST_LUKES_WOODLANDS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park at the loading dock, go through the kitchen and take a left in the cafeteria area.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_8',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Dristeem',
          id: 'MN_CANTEEN_DRISTEEM__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_GM_INNOVATION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through front doors, check in with security, walk to the back of the building, the fridge is on the 2nd floor in the cafe all the way to the back',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b5663daf-57a1-4d63-8f17-797c3a1254b0',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_HARRIS_HALL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'USC to send instructions',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3003__303_HOLMES',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MI_Catering (comped) - Sales_Eastern Michigan University',
          id: 'MI_EASTERN_MICHIGAN_UNIVERSITY__529_ST_JOHNS_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Please take the product in through the loading dock and call to meet the client directly through will instruction you to the drop off the products.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'METLIFE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_DTW_COKE_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "MI_DET_DTW_COKE_LEFT and MI_DET_DTW_COKE_RIGHT\nDRAFT dry run\nWhen approaching airport, follow signs for McNamara terminal -International- Arrivals.\nThe Bradford dock is just past the passenger doors, and we use dock #1 which is a lift gate.\nDrop product, cart, and paperwork at Bradford McNamara dock.\nMap pin: https://goo.gl/maps/bL6uJdQumVhJ7Ytv6\nWhen leaving Bradford, keep right, following signs for i94/airport traffic.\nFollow signs for McNamara terminal, baggage claim/arrivals (not international!)\nWhen approaching, keep left and enter the Ground Transportation area.\nPark to the immediate left.\nDo NOT back under the overhang - your van is too tall! Back to the overhang, ensuring you're not blocking the entrance in front of you.\nProceed to walk straight and to the right to enter the terminal.\nFollow signs for Elevator and take the elevator up to the ticketing/security level.\nEnter the security checkpoint via the far left designated Employee lane.\nAfter clearing security, turn left.\nFridges are in the Coke vending area across from Plum Market near gate a36.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'St. Elizabeth Hospitals',
          id: 'KY_ST_ELIZABETH_DEARBORN__600_WILSON_CREEK_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_ANN_ARBOR_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Map Pin:\nhttps://goo.gl/maps/nuLg6BES174ZcjbW9\nLocation: Located in the 3rd Floor cafeteria seating area across from the Starbucks\nDriver Entrance: Emergency Entrance, park in a parking spot.\nDelivery Hours: 24/7\nPark to fridge instructions:\nEnter through the ER doors, go straight and take a left at the T.\nContinue to the elevators on your right, take them up to the 3rd floor.\nOff the elevator, take a left, follow the hallway around to the right, and the seating area will be on your left.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_NWQ',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in turnaround with hazard lights on, enter the side door with key card, enter to left,  through double doors and Fridge will be immediately to the right',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_VA',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go up the ramp on the right and  put the code in the on the double doors on the right. Push ( Start 58486#). Walk through door following  signs to the elevator. Take a left then a right. Don't take first set of elevators on the left. Take the second set and go to floor 1R. Fridge is right on the other side of elevators.",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FLAB_TEST_6',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Park at loading dock on Carrol Ave, using your keycard, open the freight elevator and take to floor 3. Turn left out of the elevator and it'll be right there.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff - 629',
          id: 'NY_GOPUFF_629__535_4TH_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_WAYNE_STATE_400_MACK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Opens 7:00am\nhttps://maps.app.goo.gl/TYY8GzhK3kG9qP9C6?g_st=ic\nPark preferably in a parking spot instead of at the door. Walk straight in, pass the security desk and out into the corridor. From here continue straight into the next building ahead and the fridge will be right in front',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_RIVERSIDE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_MAPLE_GROVE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Straight back through all sets of doors to waiting area, turn left and walk to elevators at the end of the hall. Take elevator on your left to first floor, turn left, and right before the stairs.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_AURORA_ST_LUKE_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter North Building Entrance doors, continue straight down the hallway towards the Central Hub, Fridge will be next to the ATM in the Central Hub (near glass elevator)',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_735_N_WATER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_LONE_STAR_COLLEGE_SYSTEM_OFFICE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in parking lot, walk into Community Building - stay left, and the Fridge is outside of the Avenue C micromarket room in the corner of the building.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HEALTH_FORT_WORTH_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From the current Fridge walk straight down the hallway to the cafeteria, walk through the cafeteria to the back left wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - Adtalem [Client Route- 10]',
          id: 'IL_CANTEEN_ADTALEM__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CUNY_LAW',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Cypress TX Warehouse',
          id: 'TX_COSTCO_CYPRESS_TX_WAREHOUSE__26960_NW_FREEWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Drive to the back near receiving and park. Buzz the doorbell and bring product in on a cart. Bring to shelf to merchandise, be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Consignment',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'US_CELLULAR_PLAZA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'c039f0d5-7756-4aa2-85d2-d2938f3e8d35',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DENTON_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Can either walk from the current Fridge or go through the Woman's doors straight to the micro-market",
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'b280190a-4aa4-4806-a23c-31613b9864a5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Hudson DLF Samples',
          id: 'TX_CATERING_(COMPED)_SALES_HUDSON_DLF_SAMPLES__1001_N_UNION_BOWER_RD_SUITE_130',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop with the DLF Hudson orders',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_COREWELL_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'For initial delivery, the driver will enter off the dock and will pass through the double doors leading from the dock to the main atrium. The driver will take a right and will pass the security desk to head towards the C/D wings. The unit will be delivered to the atrium across from the Deli/Starbucks area on the wall opposite Starbucks. Please also note that we could locate the fridge in the B conference area as well if desired.',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DELL_MACHINE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'OH_Catering (comped) - Marketing_OSU EVENT',
          id: 'OH_OSU_EVENT__1739_NORTH_HIGH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'PLEASE CALL GINA UPON ARRIVAL 614-314-4882',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_NEW_SCHOOL_66_W_12TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'CC to update',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Marketing_NAMA2024',
          id: 'TX_NAMA2024__650_S_GRIFFIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions:
            'PLEASE STOCK THE FRIDGE AT OUR BOOTH (1147) FOR A MARKETING EVENT! FRIDGE NAME: FF_Marketing_Event',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'MD_BAL_KENNEDY_KREIGER_INSTITUTE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the front door. Vending room is on the right',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LOYOLA_MEDICAL_BURRRIDGE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Please have your drivers access the dock area using the following code of 1764. Driver must first enter "0", then the code, then the green button on the keypad for access to the building.\r\n\r\n24 hr. security. Ring the security box and they will let our drivers in. Walk straight and take the first right. Go through doors at the end of the hallway and turn left, walk straight until you reach the fridge.\r\n\r\n708-327-1232 Security\'s #. The dock door also has a keypad in which we can call security.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MUSEUM_CONTEMPORARY_ART',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Chicago - Wholesale',
          id: 'IL_CANTEEN_HOME_DEPOT_DC__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_WEST_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '69_W_WASHINGTON_TENANT_LOUNGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_EWR8',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Come in via front entrance, security will check IDs, turn left right to the main cafeteria.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_LAUNDRY_CAPITAL_QUEENS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the front entrance and the placement will be directly ahead.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_KLINGENSTEIN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter through main entrance of building. Get screened at security.\r\nTake elevator down to MC level. Exit and turn right, machine will be on your left.\r\nInstructions from Hospital Lobby fridge to new one. Enter Annenberg elevators around\r\nthe corner from fridge, take down to MC level. Exit and turn right down hallway. Turn right\r\nat next hallway and follow signs for Klingenstein lobby. There will be blue circles with\r\narrows on the floor, follow the arrows. Machine will be on the right in front of elevators near\r\nKlingenstein lobby.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Test',
          id: 'IL_XXX__2130_W_THOMAS_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_MOUNTAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Come through the side entrance and should be straight ahead when walking in',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - ANIMAL EMERGENCY',
          id: 'MN_CANTEEN_ANIMAL_EMERGENCY__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Sharp Grossmont Wholesale',
          id: 'CA_SHARP_GROSSMONT_HOSPITAL_WHOLESALE__5555_GROSSMONT_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Receiving area is off of Murray drive & health center circle Take that up a hill to the small area where the heart center is. The food vendors will park and take the back area 25 feet away from the rear entrance Hand truck into department Take the loading',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'Sharp Healthcare',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DDF1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Use the associate entrance Fridge will be in the break room',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ef99190b-0cd1-4f4b-a1bd-77b49015e5eb',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Puma',
          id: 'MA_PUMA__455_GRAND_UNION_BLVD_7TH_FLOOR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: 'Please call delivery contact when you arrive',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Puma',
        },
        {
          opportunityName: null,
          id: 'ULTA_BEAUTY_HQ',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Driver to pull into parking lot and park at loading dock (will be on your left hand side). Badge into door 9.\r\n\r\nAfter badging into door 9, walk straight at the end of the hallway and turn right. Badge into 1 North. Exit right and fridge be inside the vending area.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Beckman 326',
          id: 'MN_CANTEEN_BECKMAN326__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TEST for RETAIL 2',
          id: 'IL_TEST_FOR_RETAIL__2130_W_THOMAS',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KEMPER_LAKES_BUSINESS_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'See map in photo attached',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_ROGER_SAMPLES',
          id: 'IL_ROGER_SAMPLES__171_COVINGTON',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: "Please drop with Canteen Chi's normal order",
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DUPAGE_HSC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Park in the Fawell D parking lot and enter the HSC through it's main entrance.  The Fridge is on the second level which is basically a mezzanine",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aurora University',
          id: 'IL_AURORA_UNIVERSITY__1410_MASMEILLAISE_PLACE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call onsite contact when arriving and she will coordinate',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HCA_MCKINNEY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk in the main lobby, Fridge will be just off to the left.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'DC_DEP_GEN_SERVICES_2ND_FLOOR_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Escort access required --\r\n\r\nStephane Beziat (202-993-0539) and Robert Jones will be escorts\r\n\r\nTake elevator up to floor 2 and head towards the cafe',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Life Time - Athletic Dallas, TX',
          id: 'TX_LIFETIME_ATHLETIC_DALLAS_TX__5910_NORTH_CENTRAL_EXPRESSWAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CHICAGO_POLICE_14TH_DISTRICT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Check in with security; walk to vendeteria',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Alstom Transport Inc',
          id: 'NY_ALSTROM_TRANSPORT__402_PAN_AM_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Drop off in the second floor break room Fridge and always notify Audrey Lunan (347-551-0900)',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Getir - Lake View',
          id: 'IL_LAKE_VIEW__3052_N_LINCOLN_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TWU',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in loading dock area, take elevator up to 3rd floor, the vending/kitchen room is on the North end of the building.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ea8c6fbf-4441-4d54-b55b-37532cff0cb9',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson JFKT1',
          id: 'IL_HUDSON_JFKT1__JFK_AIRPORT_TERMINAL_ONE_BLD_55',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Drop to loading dock at JFK T1',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '1245_DIEHL_ROAD',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in north lot, use key card to get in and go toward lobby in center of building.  Fridge is on the left next to the vending room.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_PLACENTIA_LINDA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main doors and go down the hall and the first right will be the cafe. The Fridge will be here',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_LUTHERAN_GENERAL_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Straight from dock right through double doors, then continue straight down the hallway, just before the hallway hits another double door, turn left down the hall with marks all over the floor (like carts have gone through), follow the cart marks through the hallway, then turn left before the hall ends, elevators will be ahead to your right, go to 10th floor, take a right out of elevators, then left down the hall, then turn right, Fridge will be around the corner outside the cafe.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3214',
          id: 'IL_TARGET_T3214__3300_N_ASHLAND_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Ask for Alexis',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'CANTEEN - Goldern Hills',
          id: 'MN_CANTEEN_GOLDERN_HILLS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_JPS_BEH_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the loading dock entrance, turn right, walk straight until the doors, enter doors and the area will be on the right, fridge will go on the left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_JPL_CAFE_190',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'See campus map',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_SOUTHWEST_TECH_OPS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk in the main entrance, to the right will be an elevator and will be taken to floor 2, once off the elevator turn right, about half way down the hall will be the cafe on the right and the fridge will go against the half wall on the left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TWU_MARKET',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use the main entrance doors, will down the left hallway towards the market.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_UT_DENTISTRY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk the the dock take the elevator to the 4th floor.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_DALLAS_COLLEGE_BROOKHAVEN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Second floor against the wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - University City Warehouse',
          id: 'MO_COSTCO_UNIVERSITY_CITY_WAREHOUSE__8685_OLIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'Pull to receiving and deliver via back door.',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'CANTEEN - Tractorworks',
          id: 'MN_CANTEEN_TRACTORWORKS__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'MA_BOS_EXCHANGE_PLACE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Parking Garage -- take elevators up to the lobby and go up to the turnstiles to the 12th floor (can also access the freight bay for the freight elevator which goes directly up to the 12th floor) and call Jordan Thomas for assistance (914-572-5308)',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Thrivent',
          id: 'MN_CANTEEN_THRIVENT__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'Canteen - Target Chicago North',
          id: 'IL_CANTEEN_TARGET_CHICAGO_NORTH__171_COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Memorial Hermann - Medical City',
          id: 'TX_MEMORIAL_HERMANN_MEDICAL_CITY__920_FROSTWOOD_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            'Go to the loading dock- easiest to find on 921 Guessner',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MOUNT_SINAI_LUKES_OR_LOUNGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'PA_Catering (comped) - Marketing_NASPA PHILLY',
          id: 'PA_NASPAPHILLY__3910_IRVING_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'CALL BRIAN',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'SWEDISH_COVENANT_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in turn-around outside the main entrance, enter hospital and go to elevators to the left, go to second floor, left out of elevators, and then to the left under the restrooms signs is where our fridge will be. IF YOU ARRIVE BEFORE 5:30AM USE BUZZER NEXT TO MAIN ENTRANCE DOOR TO CALL SECURITY, THEY MIGHT LET YOU IN EARLY!',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_TYSON_FOODS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security will let you through after showing an FF badge, Fridge will be straight down the hallway to the left',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MDW_CENTRAL_MARKET_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Walk into the terminal and the units are located in the central Hall area next to the Hudson kiosk just post the security checkpoint',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_COL_NATIONWIDE_ARENA_205',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon parking take the loading dock entrance towards security who will allow you entry. Take the main corridor hallway towards the concourse elevators of the arena. Take those up to the 2nd floor where the machine is outside of gates 205/206',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SARGENT_LUNDY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Getir- Downtown',
          id: 'MA_GETIR_DOWNTOWN__44 _WINTER_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_SAN_JACINTO_CC_NORTH_N17',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through glass doors into the building and look for the Pharmacy Technology. The spot will be in front of that sign.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Wholesale',
          id: 'WI_ANDIS_ROUTE7__12100_W_WIRTH_ST',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_ROBBINSVILLE_WEST',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Follow signs for the break room WEST',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_MONTCLAIR_UNIVERSITY_FELICIANO_BUSINESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'We have Montclair badging. Go in via back entrance and toward venture cafe',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ADVOCATE_CONDELL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'For the refilling of the machine, they would also have to go to the Emergency Department Testing area for temperature checks. Once they get their temperature taken, they would need to contact once inside to be escorted to the area. This would just be for the first few times for comfort levels.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_CCLASEPT',
          id: 'CA_CCLASEPT__110_E_9TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'PLEASE CALL ALEXIS OR DANA!',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_JERSEY_SHORE_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'go past security and to the left, walk down short hallway to seating area/vending',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 6 Cubic C',
          id: 'CA_CANTEEN_VISTA_RT_6_CUBIC_C__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T2124',
          id: 'PA_TARGET_T2124__4000_MONUMENT_ROAD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PHI_JEFFERSON_FRANKFORD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk down the hall to the cafeteria. Fridge on the left.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Five Star Food Services Office - TQL Edison',
          id: 'OH_FIVE_STATE_FOOD_SERVICES_OFFICE_TQL_EDISON__1195_CLOUGH_PK',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'LOADING DOCK- 1 LOADING DOCK IS A BACK IN, 1 IS A MANUAL DOCK',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_COLGATE_PALMOLIVE_CAFE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "They'll escort you but you'll go through the kitchen to the main dining area, it's going to be on the other side of the Bevi machines. Will attach additional pics",
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Notre Dame University Wholesale',
          id: 'IN_NOTRE_DAME_SATELLITE_UNIT_COMMONGROUND__DUNCAN_STUDENT_CENTER',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call cody upon arrival (574)631-1537',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_BAYLOR_MEDICINE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Go through the loading dock, into the kitchen, and to the cafeteria space. You'll find the machine on the right side of the cafeteria when viewing from the front.",
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_MARQUETTE_RAYNOR_LIBRARIES',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through front entrance, go up one floor to the cafe, fridge will be to the right of the coffee bar',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW B14',
          id: 'TX_HUDSON_DFW_B14__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: 'Target Stores',
          id: 'NY_TARGET_T2212__1598_FLATBUSH_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Deliver through loading dock - If not accessible, product can be brought in through the front',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_RILEY_CHILDRENS_2ND_FLOOR',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CHI_Catering (comped) - Marketing_CSSC EVENT',
          id: 'IL_CSSC__1010_N_HOOKER_ST_SUITE_301_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            '400 meals (breakfast)\nChicago Sport and Social Club\nAttn: Chris Hastings\n1010 N. Hooker St, Suite 301\nChicago, IL 60642\n\nDelivery on friday\n312.543.4126\n12-2pm',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UIC_ART_ARCHITECTURE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_YELPMN',
          id: 'MN_YELPMN__8594_EXCELSIOR_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'please call Kelsa',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'OH_CMH_CONCOURSE_A',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Loading dock and then a variety of doors',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_STONY_BROOK_ADMIN',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_GLOBE_LIFE_FIELD',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Security phone # 817-533-1803 Please call ~15min before arrival and let them know you are on your way\r\n\r\nPark in Lot T, and then proceed to the entrance on the East facing side of the building (Right off T-Lot).  There will probably be a sign that says “Please use Southeast Entrance”.  You can stay at that entrance and not go to the SE entrance. Call Security, and they will be able to pop the door for entry.\r\n\r\nOnce finished with stocking, please call Central Security, and let them know you are done and leaving the building.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales Costco LA',
          id: 'IL_COSTCO_LA__5370_S_CICERO_AVE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'This will e picked up by Chris',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'University of Michigan - Wholesale',
          id: 'MI_BLUE_MARKET_AT_BURSLEY__2101_BONISTEEL_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'drop off in circle drive or dock, first left of Bonisteel past Murfin',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'VE_TEST_UPGRADE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_MARKET_TOWER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_AMAZON_DAL3_3RD_FLOOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'From the current machine, take the elevator to the third floor, Fridge will be in the break room to your left',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Southwestern College - Time Out Cafe',
          id: 'CA_SOUTHWESTERN_COLLEGE_TIME_OUT_CAFE__900_OTAY_LAKE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Go to loading dock area- go through the gate and enter via the sliding glass doors. There is a team behind there that can direct the driver',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'SOUTHWESTERN COLLEGE',
        },
        {
          opportunityName: null,
          id: 'AMA_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NORTHWESTERN_UNIVERSITY_1201_DAVIS_ST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Mercy Jefferson Hospital',
          id: 'MO_MERCY_JERFFERSON__1400_US_HIGHWAY_61',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Please use the loading dock (hours are 5am-5pm). If it is earlier/past these hours please use the front entrance',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MASPETH_FEDERAL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk towards entrance and take a right to the elevator. Select Lower Level and exit towards the left towards vending area',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_CROWN_POINT_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the loading dock to the back side of the building off of Franciscian Way Drive. Upon entering take the main loading dock doors take left down the first hallway. When that ends go right until you hit the 4 way hallway intersection turn right. When you get to the elevators turn left to the signs that say Main Lobby or towards the cafeteria. The fridge is located in the main entrance hallway right off of the cafeteria.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MORTON_SALT_FLOOR_29',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take the freight elevator to the 29th floor. Take a right out of the elevator and call Janice (312-270-6031)or Tom to get you. Someone will lead you to the fridge which is located in the main office in the open center area by the plants',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TN_Catering (comped) - Vanderbilt Health - Sodexo',
          id: 'TN_VANDERBILT_HEALTH_SODEXO__1301_MEDICAL_CENTER_DRIVE',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions: 'Call Calvin Spencer @ (910) 639-9630',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_U_CHICAGO_MED_CROWN_POINT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "If coming after hours, just let security know they are there to replenish Farmer's Fridge. See highlighted path attached.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TAKEDA_PHARMA',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'GROUPON_2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NJ_Catering (comped) - Sales_RWJBH New Brunswick',
          id: 'NJ_RWJBH_NEW_BRUNSWICK__1_ROBERT_WOOD_JOHNSON_PL',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Call Jill Wedeen 1-732-828-3000',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MO_Catering (comped) - Sales_EddieSakSamplesSTL8.7',
          id: 'MO_SALES_EDDIE_SAMPLES__10701_LAMBERT_INTERNATIONAL_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: 'CALL EDDIE 630-777-1885',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take loading dock hallway left past the vending machines to elevators, take elevator to first floor, fridge locaiton will be across.',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_CHOP_WANAMAKER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco',
          id: 'IL_JEWEL_3481__481_BUSSE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_AMAZON_STL4',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in with security and walk to employee breakroom',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MOSAIC_SALES_SOLUTIONS',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UCMED_SKYLOUNGE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_UNI_OF_CINCINNATI_MARIAN_SPENCER_HALL_VESTIBULE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter into the main lobby and find the fridge in the vestibule',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MO_STL_WASHU_ZETCHER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through kitchen to the grab & go market. Fridge will be in that hallway.',
          fulfillmentCenter: 'MO_St_Louis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MD_Catering (comped) - Sales_Keswick Building',
          id: 'MD_KESWICK_BUILDING__3910_KESWICK_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Brian upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Delta 7',
          id: 'MN_CANTEEN_DELTA_7__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'IN_Catering (comped) - Marketing_BETH',
          id: 'IN_BETH__5155_CENTRAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'CALL BETH!',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_EAST_HEALTH_CENTER_GOVE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the main entrance and proceed left into the emergency room waiting room. The location of the fridge will go on the right wall behind the security booth in the waiting room.',
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_IUPUI_LAW_SCHOOL',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Take front door into main area and the eating area is on the north side of the building through glass doors',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '541_N_FAIRBANKS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park right next to address on Fairbanks Street. Machine is located in basement in front of the only restaurant.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Aramark Cincy - Duff Drive',
          id: 'OH_ARAMARK_CINCY__4890_DUFF_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'drop off to the main point of contact, Ron (610-960-1202).',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Aramark',
        },
        {
          opportunityName: "Martin's Supermarkets",
          id: 'IN_MARTINS_10__2081_SOUTH_BEND_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CSU Long Beach - Out Post Grill',
          id: 'CA_CSU_LONG_BEACH_OUT_POST_GRILL__6049_E_7TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            "Go to the loading dock and head to the back door.  Renee's office is past the door- call Rene if she doesn't answer leave a voicemail and send a text",
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Wholesale',
          retailer: 'California State University System',
        },
        {
          opportunityName: null,
          id: 'TX_AUS_Q2_MAIN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'walk in to main lobby from either entrance.   The breakroom is on the east side of the building.  The fridge is to the right of the entrance to the breakroom.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TMC_INNOVATION_INCUBATOR',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through front doors. Go straight down main hallway until you reach cafe. Fridge located in the cafe',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'LA Maywood facility',
          id: 'CA_MAYWOOD__4569_S_MAYWOOD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Patrick to pickup at facility',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'LORETTO_HOSPITAL',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'S_C_ELECTRIC_1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'CA_Catering (comped) - Sales_RTX El Segundo Samples',
          id: 'CA_SALES_RTX_El_SEGUNDO_SAMPLES__2501_WEST_UNIVERSITY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'DELIVER WITH RTX EL SEGUNDO',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Milwaukee - Route 11',
          id: 'WI_CANTEEN_MILWAUKEE_ROUTE_11__5400_S_WESTRIDGE_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions:
            'Come to the west side of the building and go to cooler doors. Use key fob',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: 'SIG',
          id: 'PA_SIG__401_E_CITY_AVENUE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'park out front of building, security lets you up. stock coolers\r\nPlease deliver at night to the 6th floor.  Will have the refrigerator marked for it to be stored.',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Susquehanna International Group LLP',
        },
        {
          opportunityName: null,
          id: '70c4e4ec-e526-4f88-bc11-c588faf4662a',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_UM_MEDCENTER_DOWNTOWN_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Same as existing Fridge',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Marketing_CSSCJUNE',
          id: 'IL_CSSCJUNE__814_W_EASTMAN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Erin Woodhams (773) 907-5000',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Jewel Osco - 3451',
          id: 'IL_JEWEL_3451__150_W_MAIN',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Aramark Mt. Vernon',
          id: 'NY_ARAMARK_MT_VERNON__70_MACQUESTEN_PARKWAY_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Mike upon arrival',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_THRIVE',
          id: 'NY_THRIVE__599_BROADWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL Huma 712-560-6812',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MS_Catering (comped) - Sales_Aramark at Nissan',
          id: 'MS_ARAMARK_AT_NISSAN__300_NISSAN_DRIVE_DOCK_17',
          type: 'WHOLESALE',
          routingTag: '3PL',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'Does Not Exist',
          deliveryInstructions:
            'Enter Nissan through Gate 6 and follow signs to dock 17.',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Does Not Exist',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EE_TEST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen - BP',
          id: 'IL_CANTEEN_BP__171__COVINGTON_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MN_Catering (comped) - Marketing_YELP',
          id: 'MN_YELP__1015_VINCENT_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Please call Mikhala',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'KLUCZYNSKI_FEDERAL_BUILDING_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk inside building, the correct entrance is next to the big red art in front of the building, once inside you will go through a metal detector/security check point. Then go into any elevator and go to 2nd floor, get out the elevator and take a right, then right again, then left into a small vending room',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_ABE8_FRONT',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'walk through security and walk to the left',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '600_N_MCCLURG',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN VISTA -  Rt 6 Amazon UCA6',
          id: 'CA_CANTEEN_VISTA_RT_6_AMAZON_UCA6__511_OLIVE_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'HMS Ohare',
          id: 'IL_HMS_OHARE__1000_BALMORAL_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Delivery to HMS Loading dock at Post 8',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'HMS Host',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HUTCHINS_CONVENTION_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Take the elevator up to the second floor, Fridge will be located next to the cafe.\r\nDrivers must have ID and must check in with our dispatch if arriving after 5pm (214) 939-2940.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_WATT_PLAZA_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Use intercom to enter building, once in building, use intercom again and security will grant access. Please sign in with security before stocking.\r\n\r\nGo straight towards main lobby and bare right. Once in lobby lounge, go right towards conference center. Fridge will be located in the nook next the sweet green outpost.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Qualcomm - Cafe S',
          id: 'CA_QUALCOMM_CAFE_S__5535_MOREHOUSE_DR',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMAZON_MDW9',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in through security, fridge located in employee breakroom',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_AIRPORT_GATE_C17_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'enter through the keypad silver door to the left of carousel 6 in Baggage claim. Walk straight ahead to cross the small street and turn right. Find another Keypad protected silver door walk through and down the hall to the elevator on your left, take the elevator to level 2. Exit elevator walk forward and take your first left to find another hall and exit the door at the end into the terminal. turn left and walk to C Gates, walk to the end of concourse and find fridges on the right.',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MD_BAL_UM_MEDCENTER_MIDTOWN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Client to send details / map',
          fulfillmentCenter: 'DC_MD_VA_Washington',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HOFSTRA_UNIVERSITY_STUDENT_COMMUTER_LOUNGE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk up ramp, enter through both doors. Turn left, walk all the way down hallway past the bathrooms. Commuter lounge is the first room on the right.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Procter&Gamble',
          id: 'IL_CATERING_(COMPED)_SALES_PROCTER-GAMBLE__1_PROCTER_AND_GAMBLE_PLAZA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'Drop off with Eurest Team member',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_FARMINGTON_HILLS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Enter through loading dock, go straight and take first hallway to the left. Follow around to the cafeteria. Fridge is located in the cafeteria.\nAccess through loading dock is 0700 - 1700. If before or after hours, use front door with Farmer's Fridge Supervisor approval.",
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_COLUMBIA_UNIVERSITY_MED_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'the fridge will be placed right past the security desk and to the left, big empty corner spaceIf the gate/entrance at 50 Haven Ave is closed at night, just enter through 60 Haven Ave (tower 1) as the two buildings are connected.  The machine is located literally in the middle of the lobby at 50 Haven Ave and you will see it no matter which entrance you use.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_HOU_TOSHIBA_HEV',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go through the loading dock. POC will escort you to the HEV breakroom.',
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MAINSTAY_SUITES_HOFFMAN_ESTATES',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Sales_Chanel Samples',
          id: 'CA_CHANEL_SAMPLES__411_NORMANDIE_AVE_UNIT_711',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Please call Chanel at (312) 605-2023. PLEASE USE BOXES NOT TOTES',
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Hudson DFW A12',
          id: 'TX_HUDSON_DFW_A12__2400_AVIATION_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: 'Traditional',
          retailer: 'Hudson Group Inc',
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_A2',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M18',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            "The vendor entrance from the dock closes from 8pm till 5 am and have to go in thru passengers screening entrance. There's a lane for employees and vendors to cut the line. Once on the concourse m5 is to the right and m16 is to the left. They go in numerical order so it won't be hard to miss. To exit there's a big sign next to were you come in through that says exit and that will leave you back to ticketing.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FF_MW_MARKETING_SUBSIDIZED',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_BELL_FLIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main entrance, Fridge will be down the hall, Fridge will be located in the eating area against the wall.',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_SAN_METHODIST_STONE_OAK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, take a left towards the cafeteria, take a left once in the cafeteria, Fridge will be against the back left wall',
          fulfillmentCenter: 'TX_San_Antonio',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Glenview Warehouse',
          id: 'IL_COSTCO_GLENVIEW_WAREHOUSE__2900_PATRIOT_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Please be off the floor by 9:30am - enter through back receiving',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: 'Target - T1887',
          id: 'NY_TARGET_T1887__500_E_STANDFORD_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'Not available',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'EF_TEST',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'e7dda542-ac3b-4807-918d-e5011ec1f821',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CA_Catering (comped) - Marketing_CCLA',
          id: 'CA_CCLA__980_S_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: 'CALL ALEXIS UPON ARRIVAL. LOADING DOCK B',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_4705_DUKE_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Its right tin the lobby',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Liberty Mututal',
          id: 'MA_LIBERTY_MUTUAL__175_BERKELEY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'PLEASE CALL DELIVERY CONTACT. IF TEDS OUT OF TOWN CALL DANA  732-841-7368',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Liberty Mutual Insurance Group',
        },
        {
          opportunityName: 'Lifetime - Bergen County, NJ',
          id: 'NJ_LIFETIME_BERGEN_COUNTY_NJ__10_VAN_RIPER_ROAD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'MA_Catering (comped) - Marketing_BostonMedical',
          id: 'MA_BostonMedical__750_ALBANY_STREET',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'Delivery Attn: David Maffeo, Support Services — "he wants us to deliver to loading dock and hand off to Victor." David cell: (617) 414-7003',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: "Texas Children's Hospital - 21st Floor Lounge",
          id: 'TX_TEXAS_CHILDRENS_HOSPITAL_21ST_FLOOR_PHYSICIANS_LOUNGE__6651_MAIN_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions:
            "PLEASE DROP IN THE MORNING - Go to loading dock, take freight elevator to 21st floor physician's lounge. Use ID to get in the door - remove old food, take any excess food that cannot fit to Bistro",
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: "Texas Children's Hospital",
        },
        {
          opportunityName: 'IN_Catering (comped) - Marketing_NASCARINDY',
          id: 'IN_NASCARINDY__4790_W_16TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: 'CALL JIMMY',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: 'Target - T3283',
          id: 'IL_TARGET_T3283__1616_SHERMAN_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Loading dock is on Benson Ave right next to a byline bank. Manager said once you arrive at the loading dock there will be a set of double doors with a doorbell to ring to gain access. There is a person who stocks fresh market items.',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: null,
          id: 'CA_LA_MANULIFE_PLAZA',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Once parked at the loading dock take the 3rd floor hallway and make turns around the 3rd floor hallway path to the Suite 386. The machine is located in the vending amenity room.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'HERE_TENANT',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'NJ_Catering (comped) - Sales_Canteen Laura Wheeldon',
          id: 'NJ_CANTEEN_LAURA_WHEELDON__190_HOMESTEAD_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'front entrance to canteen offices',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '180_N_LASALLE',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - East Lansing Warehouse',
          id: 'MI_COSTCO_EAST_LANSING_WAREHOUSE__2540_E_SAGINAW_HIGHWAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: 'Drive to the back by receiving to deliver',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'ECHO_LOGISTICS_2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Upon checking in driver to inform loading dock staff needs to be sent to the Echo Facilities team on floor 2.\r\n\r\nCall Emily if having issues 508-369-4699',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MKE_UWM_STUDENT_UNION',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            '2nd Floor in the main food hallway, on the brickwall. Go through the dock door and through the door to the right of the dock office, take an immediate right down the hallway, at the end of the hallway will be a freight elevator on the left, take the to 1R. Directly in front of the elevator doors are double doors you go through, and continue straight until you enter the main union area, veer off towards the left towards all the tables and chairs. Fridge is placed on brick wall on the northern hallway of the second floor of the union',
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_CHILDRENS_SPECIALTY_CENTER',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'fridge will go in the main lobby',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_INSTRUMENTS_HQ_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk straight down the hallway about 80 yards to the cafe, Fridge will be on the back right wall',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'AMWINS',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Driver will park at the loading dock on Arcade Pl. There is a call button towards the middle of the building for security if they do not see driver. Driver will enter through loading dock. \r\n\r\nOnce in building, security will give driver badge to stock AM Wins fridge on 18th floor. This floor requires keycard access',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MSKCC_MEMORIAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Same as prior instructions- Enter through 1275 York ave. Walk passed security turn left and take elevator to M. Walk down long hallway to the other end of the building. Turn left and follow signs for cafeteria. Enter cafeteria, machine will be straight ahead in the seating area with outlet vending machines.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PHI_LANKENAU_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'SILVER_CROSS_HOSPITAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'take front entrance and go directly left toward emergency room, once you see chairs go right into the little cubby.\r\n\r\n24/7  access if entering the emergency department',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Delta 8',
          id: 'MN_CANTEEN_DELTA_8__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'IND_SIMON_MUSIC_SCHOOL_BLOOMINGTON',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'goPuff Wholesale - NYC/NJ',
          id: 'NJ_GOPUFF_76__1008_ASTORIA_BLVD',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ab84ef0a-0374-4da6-b854-8453dd08be77',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_9800_HEALTHCARE_LANE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'For the first delivery it’s best to contact Richard to show the driver how to get to the fridge-  952.270.2359',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_AUS_USPS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Park in the employee entrance using badge at the gate.  Go right after the gate and another right into the parking "nook."  There is a set of double doors for you to badge into, once you badge in, the cafeteria is on the right.',
          fulfillmentCenter: 'TX_Austin',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_EWR_TB_1',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_700_S_FLOWER_ST_RIGHT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Go in through the loading dock and check in with security so that they can provide access upon arrival (213-454-4953)',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'TX_DAL_TEXAS_HEALTH_DALLAS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Walk through the main doors, go through the hallway straight ahead to the cafeteria , Fridge will be just outside the cafeteria entrance doors',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NJ_AMAZON_TEB9_BACK',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Sign in with security like other amazons, the back break room is about a 10-15 min walk from the main entrance. There will be 2 fridges, one in the front breakroom and one in the back. Someone will escort them to the back room',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Canteen Minneapolis',
          id: 'MN_CANTEEN_CIRTEC_MAIN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'NY_Catering (comped) - Sales_Greyson Samples 2',
          id: 'NY_GREYSON_SAMPLES__200_N_11TH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'Enter through the main entrance and type #6151 on the key pad to unlock the door. Leave the box in the lobby with the elevator. Call/text Greyson (908-307-0006) to let him know it has been delivered or if you have any issues.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: "Farmer's Fridge",
        },
        {
          opportunityName: null,
          id: 'AURORA_UNIVERSITY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'To come from client',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HYUNDAI_MOTOR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Park in visitor parking near front door. Enter through main door. Check-in with security and they will escort you to 2nd floor cafe.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: null,
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'TX_Catering (comped) - Sales_Chevron',
          id: 'TX_HOU_CHEVRON_SAMPLE__1400_SMITH_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions:
            'Call  AJ Mathew - cell: 713 542 3232 or Scott Caffrey - cell: 281.799.7596. x60 SUNDRIES and x60 BOATS',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Marketing_Lululemon',
          id: 'DC_Lululemon__5709_Namakagan_Rd',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Maisie McCune 3014526052',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Amazon Downtown',
          id: 'MN_CANTEEN_AMAZON_DOWNTOWN__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName:
            'OH_Catering (comped) - Sales_Mount Carmel Lewis Center Sampling Event',
          id: 'OH_LEWIS_CENTER_HOSPITAL__7100_GRAPHICS_WAY',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions:
            'Enter the main door; David Nelson 614-205-5150.',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CLEVELAND_HEIGHTS_LOBBY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Delivery Hours - 7:30AM to 7:30PM {ER is 24/7, security will open doors) Driver Entrance - Front Entrance\r\nSaturday Access Hours: 8a-4p; Sunday access hours: 10a-2p\r\nDriver Parking - Park at the east medical office entrance\r\nPark To Fridge Instructions - Walk through the east medical office entrance, walk through sliding doors, fridge will be located directly straight back on the right-hand side',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_PASSAVANT_MCCANDLESS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Dock is open 6a-2p M-F, if coming outside of those hours, call security (# is listed onsite)\r\n\r\nhallway into hospital from the loading dock, it will be in the seating area across from the elevators\r\n\r\nFor 24 hour access: we are good to come to the main entrance (first option) at any time. You will then be able to take those elevators to the ground floor and end up right next to the cafeteria. Because the delivery van is small, there's no need to use the dock.",
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ST_ELIZABETH_FLORENCE',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take main entrance to central elevators, take that to 1st floor fridge location will be right when you exit the elevator',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HEALTH_HARLEM',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Security must escort driver. Walk straight down hallway to the freight elevator. Take elevator to 2nd floor, follow hallway and exit the hallway on the left side. Once you go through the doorway there will be doors on the right side with card access, the fridge should be through those sets of doors',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '111_N_CANAL',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: 'Not Available',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3402',
          id: 'IL_TARGET_T3402__630_N_LASALLE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'Not available',
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'NY_Catering (comped) - Marketing_BA',
          id: 'NY_BA__548_W_22ND_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: 'PLEASE CALL TRACEY',
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'OH_CIN_ST_ELIZABETH_FORT_THOMAS',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter the lobby, take a left towards the coffee shop',
          fulfillmentCenter: 'IN_OH_Indianapolis',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Maplewood Warehouse',
          id: 'MN_COSTCO_MAPLEWOOD_WAREHOUSE__1431_BEAM_AVE',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions:
            'Drive to the back by receiving. Please be off the floor by 9:30am',
          timeZone: 'America/Chicago',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: '3e911e21-bee0-4074-bd35-865ea3c1bca5',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_MCNUTT_C_STORE__3050_DISCOVERY_PARK_WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'call contact upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_TRINITY_ANN_ARBOR_REICHERT',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Door hours: 5a-8p\nFridge is straight ahead from Reichart North Entrance.\nMap pin: https://maps.app.goo.gl/Me6c4F8UcKY5Em4Y7',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Liberty Mutual Samples',
          id: 'MA_SALES_LIBERTY_MUTUAL_SAMPLES__175_BERKELEY_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            'PLEASE CALL DELIVERY CONTACT. IF TEDS OUT OF TOWN CALL DANA 732-841-7368',
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Indiana University',
          id: 'IN_INDIANA_UNIVERSITY_HODGE HALL_3050 DISCOVERY PARK WAY',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UNITED_AIRLINES_CHIRR',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the doors at the north entrance, make right to first floor lunchroom',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Jewel Osco - 1241',
          id: 'IL_JEWEL_OSCO_1241__1341_NORTH_PAULINA',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'BACKSTOCK LOCATION: In deli cooler located behind deli counter, towards the back. There will be 2 white doors, deli cooler is the door on the left. OTHER DELIVERY INSTRUCTIONS: Product will be stocked in Deli cooler in marked FF Space',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Traditional',
          retailer: 'Jewel Osco',
        },
        {
          opportunityName: null,
          id: 'bdc34c09-3b69-4ef0-a947-794154b7fea3',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'CANTEEN - Medtronic Perfusion',
          id: 'MN_CANTEEN_MEDTRONIC_PERFUSION__700_24TH_AVENUE_SE',
          type: 'WHOLESALE',
          routingTag: 'Group Drop',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: 'Use badge and drop off in fridge',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'Wholesale',
          retailer: 'Canteen - Midwest/Central',
        },
        {
          opportunityName: null,
          id: 'CA_LA_CAPGROUP_333_HOPE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'This Fridge will need to be stocked first. Drivers will show ID to get a temporary day-badge that can used at 333 Hope and 400 Hope. After stocking, driver will need to return to 333 Hope to return badge and retrieve ID.\r\n\r\nOnce parked in the loading dock area - check in and take the freight elevator to the 49th floor.',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: '7_ELEVEN_KINGSBURY',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            '7-Eleven front door entrance is on the corner of Kingsbury and Ontario. Take the front entrance to the machine located along the side wall next to the ice cream machine',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_UPMC_MONTEFIORE_4TH',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Fridge on the 4th floor cafeteria seating area near exit of the seating area',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_HUNTINGTON_LOBBY',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'RND Park',
          id: 'NY_RND_PARK__101_NICOLS_RD',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions:
            'please call dc and deliver through the main entrance on level 5 at Stony Brook Hospital and drop product in walk in fridge. ALL STONY BROOK WHOLESALE DELIVERIES are to be dropped off at Stony Brook Hospital',
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: 'Wholesale',
          retailer: 'Stony Brook Medicine',
        },
        {
          opportunityName: null,
          id: 'OH_CLE_CUYAHOGA_CC_EASTERN',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through main doors and go straight until you find the cafeteria seating area and place the machine next to the other vending\r\n\r\nCampus Dispatch #216-987-4325',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'RUSH_HOSPITAL_CAFE',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            "Enter through the front entrance of the hospital and go to the 7th floor, follow the signs to Cafe 7. Inside the cafe we're located in a separate room where the vending machines are.",
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_MACYS_W_34TH',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'NYC_CITY_TECH_ACADEMIC',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Utilize the loading dock entrance and head towards the security desk. Before you get to the desk you can take the elevators up to the third floor and turn left and the Fridge will be right there.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'UW Madison - Ingraham Hall',
          id: 'WI_UW_MADISON_INGRAHAM_HALL__1155_OBSERVATORY_DRIVE',
          type: 'WHOLESALE',
          routingTag: 'Overnight',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'WI_Milwaukee',
          deliveryInstructions: 'Please delivery to the dock of Ingraham Hall',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: 'University of Wisconsin System',
        },
        {
          opportunityName: null,
          id: 'ef43132f-bce6-4784-8988-c3dbcb4a46b1',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_CRYPTO_ARENA_SUITE_LEVEL_A2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Enter through vendor entrance\r\n\r\nLocated on premium level A across from premier seat 15-16.\r\n\r\nSecurity phone # is 213-742-7444\r\nJessica Pena 213-742-7885',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_CIRCLE_CENTER',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'FF_MARKETING_EVENT_TWO',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'UC_CHICAGO_ORLAND_PARK',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'take the main entrance to the back door, badge in, take a right to the service elevator, and take that to the 4th floor.',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'ORD_T5_M9',
          type: 'FRIDGE',
          routingTag: 'Multiple Stocking',
          parkToFridgeInstructions:
            'Terminal 5 loading dock take the elevator up to TSA vendor check point badge in turn right walk down to M9 fridge is on the right side the first one is M9',
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update once process is finalized.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'PA_PIT_AMAZON_PIT2',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'main floor break room is to the left after security',
          fulfillmentCenter: 'OH_Cleveland',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_USC_SOTO_BUILDING',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Check in at front desk, fridge located in the back lobby near the fitness center',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Cushman and Wakefield - Chicago',
          id: 'IL_CUSHMAN_AND_WAKEFIELD__225_W_WACKER_DR',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions:
            'Deliveries can go to the 31st floor and be received by our cafe barista',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Middlebelt Warehouse',
          id: 'MI_COSTCO_MIDDLEBELT_WAREHOUSE__13700_MIDDLEBELT_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Please pull the back to receiving to deliver. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'NYC_NYP_LOWERMANHATTAN',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Enter the main lobby on Gold street. After you check in turn right. Fridge located next to glass windows.',
          fulfillmentCenter: 'NJ_NY_PA_New_York',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Target - T3205',
          id: 'CA_TARGET_T3205__​3030_GRAPE_ST',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions:
            'Can enter through the front door with delivery, go to the deli department to stock the product. Ask for the ETL if you have any questions.',
          timeZone: 'America/Los_Angeles',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'West',
          operatingModel: 'Consignment',
          retailer: 'Target',
        },
        {
          opportunityName: 'Vanderbilt - Rand Dining',
          id: 'TN_VANDERBILT_RAND_DINING__103_21ST_AVE_SOUTH',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TN_Nashville',
          deliveryInstructions:
            'Please drop off at the Kissam Student Center (this is a one way street) and go to the loading dock and call delivery contact',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MN_MSP_T1_C18',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: 'Update once finalized.',
          fulfillmentCenter: 'MN_Minneapolis',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'DC_Catering (comped) - Sales_&Pizza',
          id: 'DC_PIZZA__3160_BLADENSBURG_RD_NE_COMPED',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: 'Call Joseph upon arrival',
          timeZone: null,
          containerType: null,
          status: 'LIVE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MI_DET_BEAUMONT_TROY_WEST',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            'Located on the main floor right inside to the right of the West Entrance',
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'CA_LA_HARBOR_UCLA_MEDICAL_CENTER',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk to back cafe door and knock. If there is no answer, call Nancy at (951) 643-9008. Once inside, fridge is located outside the cafe',
          fulfillmentCenter: 'CA_Los_Angeles',
          deliveryInstructions: null,
          timeZone: 'America/Los_Angeles',
          containerType: 'Box',
          status: 'LIVE',
          region: 'West',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'MA_BOS_MARKFORGED_INC',
          type: 'FRIDGE',
          routingTag: 'Daytime',
          parkToFridgeInstructions:
            "Walk down the ramp through the back entrance and go through the doors and you'll be able to see the kitchen area",
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions: null,
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Marathon Oil Wholesale',
          id: 'TX_MARATHON_OIL_WHOLESALE__990_TOWN_AND_COUNTRY_BLVD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'TX_Houston',
          deliveryInstructions: 'go to the loading dock',
          timeZone: 'America/Chicago',
          containerType: null,
          status: 'LIVE',
          region: 'South',
          operatingModel: 'Wholesale',
          retailer: 'Marathon Oil',
        },
        {
          opportunityName: 'IL_Catering (comped) - Sales_Luke DNS CAP',
          id: 'IL_LUKE_DNS_CAP__400_N_MICHIGAN_AVE_STE_1700',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'IL_Chicago',
          deliveryInstructions: 'PLEASE CALL DC',
          timeZone: 'America/Chicago',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Midwest',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName:
            'MA_Catering (comped) - Sales_Emerson College Loading Dock',
          id: 'MA_EMERSON_COLLEGE_LOADING_DOCK__122_BOYLSTON_ST',
          type: 'WHOLESALE',
          routingTag: 'Pickup',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MA_Boston',
          deliveryInstructions:
            "Contact George at (954-330-2738) -- delivery will go to Lion's Den Cafe on the 2nd floor",
          timeZone: 'America/New_York',
          containerType: 'Box',
          status: 'OFFLINE',
          region: 'Northeast',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Henry Ford Health System',
          id: 'MI_HENRY_FORD__205_N_EAST_JACKSON_AVE',
          type: 'WHOLESALE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: '',
          timeZone: null,
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: 'Costco - Green Oak Township Warehouse',
          id: 'MI_COSTCO_GREEN_OAK_TOWNSHIP_WAREHOUSE__6700_WHITMORE_LAKE_RD',
          type: 'WHOLESALE',
          routingTag: 'Daytime',
          parkToFridgeInstructions: null,
          fulfillmentCenter: 'MI_Detroit',
          deliveryInstructions:
            'Drive to the back by receiving to deliver. Please be off the floor by 9:30am',
          timeZone: 'America/New_York',
          containerType: 'Shipper',
          status: 'LIVE',
          region: 'Midwest',
          operatingModel: 'BBS',
          retailer: 'Costco',
        },
        {
          opportunityName: null,
          id: 'TX_DAL_HEALTH_SOUTHWEST_BUILDING_II',
          type: 'FRIDGE',
          routingTag: 'Overnight',
          parkToFridgeInstructions:
            'Walk through the doors, past the elevators, take a left and the Fridge will be down the hallway next to the other vending machine\r\n\r\nThis building is not open 24/7, you need to call 817-250-6527 when you are on you way and security will come open the door for them',
          fulfillmentCenter: 'TX_Dallas',
          deliveryInstructions: null,
          timeZone: 'America/Chicago',
          containerType: 'RPC',
          status: 'LIVE',
          region: 'South',
          operatingModel: null,
          retailer: null,
        },
        {
          opportunityName: null,
          id: 'IND_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          routingTag: null,
          parkToFridgeInstructions: null,
          fulfillmentCenter: null,
          deliveryInstructions: null,
          timeZone: 'America/Indiana/Indianapolis',
          containerType: null,
          status: 'OFFLINE',
          region: null,
          operatingModel: null,
          retailer: null,
        },
      ],
    },
  },
};

export const mockLocations = {
  data: {
    locations: {
      locations: [
        {
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_WIH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 N George Mason Dr, Arlington, VA 22205',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_ABE8_BACK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '309 Cedar Ln, Florence, NJ 08518',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_125_HIGH_STREET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '125 High St, Boston, MA 02110',
            guaranteed: null,
          },
        },
        {
          id: '8501_W_HIGGINS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8501 W Higgins Rd, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1725 W Harrison St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CHELSEA_PIERS_BROOKLYN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '265 Schermerhorn Street, Brooklyn, NY 11217',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_NATIONWIDE_ARENA_112',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 W Nationwide Blvd, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_AFW1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1851 NE Loop 820 Service Road, Fort Worth, TX 76131',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_18TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1116 N Larrabee St, Chicago, IL 60610',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_KEELEY_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 S Ewing Ave, St. Louis, MO 63103',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_HEALTH_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 S Main St, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_METHODIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 N Senate Ave, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_GRACE_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Baltimore St, Baltimore, MD 21223',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_FRAMA_TECH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 E Arbrook Blvd., Arlington, TX 76014',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_NORTHROP_GRUMMAN_CARMEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15073 Avenue of Science, San Diego , CA 92128',
            guaranteed: null,
          },
        },
        {
          id: 'TEAMS_DEMO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1812 W Hubbard Street, Chicago, IL 60622',
            guaranteed: null,
          },
        },
        {
          id: 'PA_UPENN_HOSPITAL_PAVILION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 Spruce St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'OAKBROOK_22',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 22nd St, Oak Brook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_EL_CENTRO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 Main St, Dallas, TX 75202',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_TURTLE_BACK_ZOO_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '560 Northfield Ave, West Orange, NJ 07052',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BROOKLYN_FEDERAL_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 Cadman Plaza E, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SOUTHERN_UNI_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 Cleburne St, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_UNITED_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 Smith Ave, St Paul, MN 55102',
            guaranteed: null,
          },
        },
        {
          id: 'EDWARDS_HOSPITAL_MAIN_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S Washington St, Naperville , IL 60540',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_MELVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'IIT_GALVIN_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '35 W 33rd St, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_A21_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BROOKLYN_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 Bedford Ave, Brooklyn , NY 11210',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HUGEINC_BROOKLYN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '45 Main St, 3F, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Airport Blvd, Pittsburgh, PA 15231',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_NORTHEAST_BAPTIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8811 Village Drive, San Antonio , TX 78217',
            guaranteed: null,
          },
        },
        {
          id: 'ICSC_VEGAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29 E Madison St, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYU_TISCH_ARTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '721 Broadway, New York, NY 10012',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SMC_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 Pico Blvd, Santa Monica, CA 90405',
            guaranteed: null,
          },
        },
        {
          id: 'DC_FBI_HQ_HOOVER_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '935 Pennsylvania Avenue NW, Washington, DC 20535',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_APPLE_STORE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '45 Grand Central Terminal, New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_COLER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Main Street, New York, NY 10044',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3351 Balmorhea Dr, Dallas, TX 75241',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_ONT2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1910 E Central Ave, San Bernardino, CA 92408',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LESLEY_UNIVERSITY_1815_MASS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1815 MAssachusetts Ave, Cambridge, MA 02140',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_PRINCES_BAY_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 Seguine Ave, Staten Island, NY 10309',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_929_APARTMENTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '929 N Wolfe St, Baltimore, MD 21205',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_MARVIN_ENG_260_BEACH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '260 w Beach Ave, Inglewood, CA 90302',
            guaranteed: null,
          },
        },
        {
          id: 'IND_CROWN_POINT_FRANCISCAN_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 South Main Street, Crown Point, IN 46307',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_FOX_CHASE_CANCER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 COTTMAN AVENUE, PHILADELPHIA, PA 19111',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_EBAY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7700 W Parmer Lane Building BLDG D, Austin , TX 78729',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7725 Harlem Ave, Bridgeview, IL 60455',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_STUDENT_UNION_GROUNDFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 E Kenwood Blvd, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SPOTIFY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 Mateo St, Los Angeles, CA 90013',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DHL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 E. Fulghum Road, Wilmer, TX 75172',
            guaranteed: null,
          },
        },
        {
          id: 'MA_HARVARD_MAXWELL_DWORKIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33 Oxford St, Cambridge, MA 02138',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RIVERVIEW_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Riverview Plz, Red Bank, NJ 07701',
            guaranteed: null,
          },
        },
        {
          id: 'c4d07e2c-2718-4e1c-b4d2-5fe8ea363d8d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DOUGLAS_ELLIMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '575 Madison Ave, New York, NY 10022',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_TUFTS_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Washington St, Boston, MA 02111',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_LANDMARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5510 Presidio Parkway, San Antonio , TX 78249',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_WEILCORNELL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 E 68th St, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_MED_900_WESTMORELAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 N Westmoreland Rd, Lake Forest, IL 60045',
            guaranteed: null,
          },
        },
        {
          id: 'dfd626a8-4089-441d-b32f-12077d47c57b',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_OAKLAND_COFFEE_SHOP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44405 Woodward Ave, Pontiac, MI 48341',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYIT_LONG_ISLAND_FERRENTINO',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'Northern Boulevard, Old Westbury, NY 11568',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_SC_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '828 S Wolcott Ave., Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'Testtest123',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'Testtest123, New York, CO 01001',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_2200_CAFETERIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 Nothern Blvd, Greenvale, NY 11548',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_OLD_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4841 Cass Ave, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_INGALLS_TINLEY_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6701 W 159th St, Tinley Park, IL 60477',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_INGALLS_HARVEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Ingalls Dr, Harvey, IL 60426',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_UNIVERSITY_AVERY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1172 Amsterdam Ave #300, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HARRIS_COUNTY_PUBLIC_HEALTH_DEP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 Fannin St, Houston , TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_11TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3151 W Harrison St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BEVERLY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 Herrick Street, Beverly, MA 01915',
            guaranteed: null,
          },
        },
        {
          id: 'BLUECROSS_BLUESHIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 E Randolph St., Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_16',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_STUTZ_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '212 W 10th St., Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_17',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_B2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: '485_HALF_DAY_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '485 Half Day Road, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_BAYLOR_ST_LUKES_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 Bates Ave, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 N George Mason Dr, Arlington, VA 22205',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_UH_CMC_BOLWELL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11100 Euclid Avenue, Cleveland, OH 44106',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LAUNDRY_CAPITAL_BRONX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1240 E Tremont Ave, Bronx, NY 10460',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_WASHU_STEINBERG_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6244 Forsyth Blvd, St Louis, MO 63112',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1401 E McCarty Ln, San Marcos, TX 78666',
            guaranteed: null,
          },
        },
        {
          id: 'NEIU_STUDENT_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 St Louis Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'e5547f57-25a3-4ec4-8524-12658bcfe598',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '916377f7-a91a-4127-aecc-b9142cf8f555',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_AMAZON_STL9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8850 Springdale Ave, St Louis, MO 63134',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_229_W_43RD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '229 W 43rd Street, New York, NY 10036',
            guaranteed: null,
          },
        },
        {
          id: 'FF_MARKETING_EVENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '337 E Randolph St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_5_PARAGON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5 Paragon Drive, Montvale, NJ 07645',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AUDIE_MURPHY_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7400 Merton Minter, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ST_DAVIDS_ROUND_ROCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Round Rock Ave, Round Rock, TX 78681',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_100_OVERLOOK_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Overlook Center, Princeton, NJ 08540',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_TOMBALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30555 Tomball Pkwy, Houston, TX 77375',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_3039_NOWITZKI_WAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3039 Nowitzki Way, Dallas, TX 75219',
            guaranteed: null,
          },
        },
        {
          id: 'GROUPON_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: '1415_W_DIEHL_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1415 W Diehl Road, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'FEDEX_FREIGHT_MDW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4800 S. Central Avenue, Forest View, IL 60402',
            guaranteed: null,
          },
        },
        {
          id: '22e52cbf-371c-430a-8ba6-d827194a3b7f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CDW_LINCOLNSHIRE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 Tri State International, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_KEARNY_MESA_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8875 Aero Dr, San Diego, CA 92123',
            guaranteed: null,
          },
        },
        {
          id: 'IND_8425_WOODFIELD_CROSSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8425 Woodfield Crossing Blvd, Indianapolis, IN 46240',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_ST_JOHNS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1575 Beam Ave, Maplewood, MN 55109',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_SUGAR_LAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16655 Southwest Fwy, Sugar Land, TX 77479',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_NKU_HEALTH_INNOVATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 Kenton Dr, Highland Heights, KY 41076',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_MIDDLEBURG_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7800 Pearl Rd, Middleburg Heights, OH 44130',
            guaranteed: null,
          },
        },
        {
          id: 'IND_PNC_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101-115 W Washington St., Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_WILKIE_FARR_GALLAGHER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '787 7th Ave., New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCC_NORTHWEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4801 Marine Creek Parkway, Fort Worth, TX 76179',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOME_DEPOT_REDLANDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '27352 River Bluff Ave, Redlands, CA 92374',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHERN_TRUST_50_S_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 S LaSalle St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_ARROW_SPRINGS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Arrow Springs Blvd, Lebanon, OH 45036',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNI_OF_HOUSTON_ARCHITECTURE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4200 Elgin St, Houston, TX 77204',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_GATE_40_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'ARLINGTON_HEIGHTS_MEMORIAL_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 N Dunton Ave, Arlington Heights, IL 60004',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_C12',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_1855_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 S Harrison Rd, East Lansing, MI 48824',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CHOC_MAIN_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4650 Sunset Blvd, Los Angeles, CA 90027',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_HENRY_FORD_BLOOMFIELD_HILLS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6777 W Maple Rd, West Bloomfield, MI 48322',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_SINAI_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '945 N 12th St, Milwaukee, WI 53233',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, Euless, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_CHASE_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 E Wisconsin Ave, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_WACKER',
          type: 'DELIVERY',
          operationConfigs: {
            address: '111 E Wacker Dr Suite LL005, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_UCI_LAW_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '401 E Peltason Dr, Suite 1000, Irvine, CA 92697',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'ESSENDANT_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Parkway N, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_12',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_MANOOGIAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '906 W Warren Ave, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CHELSEA_PIERS_CHELSEA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Chelsea Piers, New York, NY 10011',
            guaranteed: null,
          },
        },
        {
          id: '38b53453-0057-4f83-a6f4-2fcf561ec824',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_FLOYD_CURL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7700 Floyd Curl Dr, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'BRAINTREE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 W Merchandise Mart Plz, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DTW_COKE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11050 W G Rogell Dr #602, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_GATEWAY_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '950 N Meridian Street, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_AFW5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12728 Lake June Rd, Balch Springs, TX 75180',
            guaranteed: null,
          },
        },
        {
          id: '6841c9b2-d95f-42d0-bc9a-2a5a1b2caab7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BUSCH_STADIUM_RED_BIRD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Clark Avenue, St Louis, MO 63102',
            guaranteed: null,
          },
        },
        {
          id: 'ULTA_BEAUTY_HQ_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Remington Blvd., Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_20TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 N Lincoln Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BROOKEDALE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 E 25th St., New York, NY 10010',
            guaranteed: null,
          },
        },
        {
          id: '645_N_KINGSBURY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '645 N Kingsbury St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T5_G23',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_RECREATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 N Charles St, Blatimore, MD 21218',
            guaranteed: null,
          },
        },
        {
          id: 'TEST2',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'TEST, Chicago, IL 34242',
            guaranteed: null,
          },
        },
        {
          id: 'STROGER_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1969 Ogden Ave, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_ROBBINSVILLE_PNE5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18 Applegate Dr, Robbinsville, NJ 08691',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_COLLECTORS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1610 E. St Andrew Place suite 250, Santa Ana, CA 92705',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_MERCHANDISEMART',
          type: 'DELIVERY',
          operationConfigs: {
            address: '222 W Merchandise Mart Plaza, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_TSG_FACILITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 W Jackson Blvd, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T3_H1',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SIMMONS_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Fenway St, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'MA_JAMAICA_PLAIN_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 South Huntington Ave, Boston, MA 02130',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_EMIRATES_PRE_SEC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_SIUH_NORTH_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '475 Seaview Ave, Staten Island, NY 10305',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_GATE_12_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_A3_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Terminal A, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'ff1a3567-1cd6-46a5-bc6e-775575c363fc',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_TECH_TRUAX_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1705 Hoffman St, Madison, WI 53704',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_NIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18-18 Hazen Street, East Elmhurst, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: '79b8f892-ea26-46ae-9bad-0111fdfda008',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_RIVER_CROSSING_II',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3925 River Crossing Parkway, Indianapolis, IN 46240',
            guaranteed: null,
          },
        },
        {
          id: '166e1c5a-4b64-4acc-b7d3-c41e2fbf8e28',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'VA_CARFAX_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5860 Trinity Parkway, Centreville, VA 20120',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_FROEDTERT_CFAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9200 W Wisconsin Ave., Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CIELO_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1250 S Capital of Texas Hwy, West Lake Hills, TX 78746',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_TICKETING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CSU_LONG_BEACH_MUSIC_DANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1250 Bellflower Blvd, Long Beach, CA 90840',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_UNIQUE_ID_123',
          type: 'FRIDGE',
          operationConfigs: {
            address: '123 Main Street, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_HOSPITAL_ARMOUR_ACADEMIC_4TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 S Paulina St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_CONEY_ISLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 Ocean Pkwy, Brooklyn, NY 11235',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_UPENN_SEAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '220 S 33rd St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_PUBLICIS_GROUPE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 hudson st, New York , NY 10014',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7700 Floyd Curl Dr Entrance, San Antonio, TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'b0952e3e-0300-4398-831b-573fd708cf26',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'EVANSTON_HOSPITAL_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2650 Ridge Avenue, Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_OVERLOOK_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '99 Beauvoir Ave, Summit, NJ 07901',
            guaranteed: null,
          },
        },
        {
          id: '3ba32057-9767-4f55-9e45-a16e2d2ac2f3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_GATE_40_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_CHOP_ED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3401 Civic Center Blvd, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'IND_MED_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1633 N Capitol Avenue, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'ANIXTER_HQ_NEW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 Patriot Blvd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: 'FEINBERG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '251 E Huron St, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_CITY_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 S 5th St, Minneapolis, MN 55415',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NEW_SCHOOL_39_W_13TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '39 West 13th St, New York , NY 10011',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_UMASS_CAMPUS_CENTER_LOWER_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 William T Morrissey Blvd, Boston, MA 02125',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_CENTRAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 Holman St, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'b59ff1bc-fe85-4c0b-ae96-9dcbcf060a61',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CONTINENTAL_TOWERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 Golf Rd, Rolling Meadows, IL 60008',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_FROEDTERT_CLINICAL_CANCER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8800 W Doyne Ave, Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_PROCTER_AND_GAMBLE_CENTER_HILL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6105 Center Hill Ave, Cincinnati, OH 45224',
            guaranteed: null,
          },
        },
        {
          id: '1c41da7b-22f7-41c5-b935-ddcd8905a2a2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1970 Campus Drive, Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: 'afc4d34e-174c-4220-b45c-2f54ba04f1ec',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_GENESYS_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7601 Interactive Way, Indianapolis, IN 46278',
            guaranteed: null,
          },
        },
        {
          id: 'MN_BEST_BUY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7601 Penn Ave S, Minneapolis, MN 55423',
            guaranteed: null,
          },
        },
        {
          id: 'MN_RONALD_MCDONALD_MINNEAPOLIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '621 Oak St SE, Minneapolis, MN 55414',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_PROTECTIVE_SERVICES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3635 SE Military Dr., San Antonio , TX 78223',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_340_MADISON_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '340 Madison Ave, New York, NY 10173',
            guaranteed: null,
          },
        },
        {
          id: 'IND_PURDUE_WALC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '340 Centennial Mall Dr, West Lafayette, IN 47907',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_MERCY_FITZGERALD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 Landsdowne Ave, Darby , PA 19023',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_VA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 S Lancaster, Dallas, TX 75216',
            guaranteed: null,
          },
        },
        {
          id: 'BRIDGEWATER_STUDIOS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4834 S. Oakley, Chicago, IL 60609',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AIR_LIQUIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9811 Katy Fwy #100, Houston, TX 77024',
            guaranteed: null,
          },
        },
        {
          id: 'MN_US_BANCORP_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Nicollet Mall, Mineapolis, MN 55402',
            guaranteed: null,
          },
        },
        {
          id: '3c552fa9-597e-49dd-bd0a-c5faf50f379d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_TICKETING_EMPLOYEES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60629',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RICHMOND_MED_CENTER_ER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '355 Bard Ave, Staten Island, NY 10310',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_IRVING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1901 N MacArthur Blvd, Irving, TX 75061',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOSTON_MEDICAL_CENTER_ED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '850 Harrison Ave, Boston, MA 02118',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AIRPORT_GATE_B34',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Airport Blvd, Pittsburgh, PA 15231',
            guaranteed: null,
          },
        },
        {
          id: 'CONCORDIA_UNI_WI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12800 N Lake Shore Dr, Mequon, WI 53097',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WEST_ANN_ARBOR_HEALTH_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '380 Parkland Plaza, Ann Arbor, MI 48103',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BROOKLYN_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '121 DeKalb Ave, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_HARKNESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '622 W 168th St, New York, NY 10032',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MAIMONIDES_DOCTORS_PAVILLION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4813 9th Ave, Brooklyn , NY 11220',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_MAIN_CONCOURSE_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'MAYER_BROWN_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '311 W Monroe St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_VANGUARD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '31 Morehall Road, Malvern, PA 19355',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2701 W Bethel Rd, Coppell, TX 75099',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_VA_MED_CENTER_ICU',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4100 W Third St, Dayton, OH 45428',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_HOSPITAL_1750_HARRISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1750 W Harrison Street, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 Orleans St, Baltimore, MD 21287',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M1',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'ALTA_K_STATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 W Kinzie Street, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'DC_HOWARD_UNIVERSITY_UGL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 6th st NW, Washington, DC 20059',
            guaranteed: null,
          },
        },
        {
          id: 'DC_CUSHMAN_WAKEFIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2445 M Street NW, Washington, DC 20037',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_1_C5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SOUTHERN_UNI_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 Cleburne St, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GWC_STUDENT_SERVICES_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15744 Goldenwest St, Huntington Beach, CA 92647',
            guaranteed: null,
          },
        },
        {
          id: '08b006d5-f42f-4735-b35a-19cd5d4db188',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SUNNOVA_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 E Greenway Plaza, Houston , TX 77046',
            guaranteed: null,
          },
        },
        {
          id: 'OH_GRANT_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 S Grant Ave, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CON_ED_LEARNING_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '43-82 Vernon Blvd, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M5_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_BRO_HEIGHTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 Lancaster Dr., Brooklyn Heights, OH 44131',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_UMASS_HEALEY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 William T Morrissey Blvd, Boston, MA 02125',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_EXXON_TECH_ENG_COMPLEX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 bayway dr, Baytown , TX 77520',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_GRADLABS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9880 Campus Point Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_CCD_WEST_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Maryland Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T3_BAGCLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'DEVRY_CHICAGO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3300 N Campbell Ave, Chicago, IL 60618',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FREDDIE_MAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Park Ave 16th Floor, New York, NY 10166',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_HEALTH_MASONIC_CAMPUS_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2450 Riverside Ave, Minneapolis, MN 55454',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAL_POLY_BUILDING_20',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 W Temple Ave, Pomona, CA 91768',
            guaranteed: null,
          },
        },
        {
          id: '320_SOUTH_CANAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '320 S Canal St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'KUEHNE_AND_NAGEL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1501 Devon Ave, Bensenville, IL 60106',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_OCEAN_MC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Jack Martin Blvd, Brick Township, NJ 08724',
            guaranteed: null,
          },
        },
        {
          id: '525_W_MONROE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 W Monroe St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'ONE_OHARE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6250 N River Road, Rosemont, IL 60018',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HOPE_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '19 Davis Ave, Neptune City, NJ 07753',
            guaranteed: null,
          },
        },
        {
          id: 'RUGGABLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5025 W 73rd St, Bedford Park, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_UH_CMC_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11100 Euclid Avenue, Cleveland, OH 44106',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_108_WILMOT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '108 Wilmot Rd, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'MOUNT_SINAI_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 S Fairfield Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LAX9_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11263 Olleander Ave, Fontana, CA 92337',
            guaranteed: null,
          },
        },
        {
          id: 'LEO_BURNETT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '35 W Wacker Dr, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ANTHEM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '220 Virginia Ave, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'b982c0da-d802-4b7f-9435-d4ce4d701af3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ESSENDANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Parkway North Blvd, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_HEINZ_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1010 Delafield Rd, Pittsburgh, PA 15215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HORIZON_MEDIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 Varick St., New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SAN_DIEGO_VA_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3350 La Jolla Village Dr, San Diego, CA 92161',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_MAIN_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '366 W Circle Dr, East Lansing, MI 48824',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_USPS_INTERNATIONAL_EXCHANGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '80 County Rd, Jersey City, NJ 07307',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_530_FIFTH_AVE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '530 Fifth Avenue, NY, NY 10036',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_TORRESDALE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10800 Knights Rd, Philadelphia, PA 19114',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FLUSHING_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 Parsons Blvd, Flushing, NY 11355',
            guaranteed: null,
          },
        },
        {
          id: 'MD_HOLY_CROSS_SILVER_SPRING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 Forest Glen Rd, Silver Spring, MD 20910',
            guaranteed: null,
          },
        },
        {
          id: '493068b8-71fe-4661-ab06-a4bccbf219c4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '225_W_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 W Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_30_N_MICHIGAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 N Michigan Ave, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_STEM_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '642 Red Cedar Rd, East Lansing, MI 48824',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MD_ANDERSON_DONOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2555 Holly Hall St, Houston , TX 77054',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_IT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '875 W Michigan St., Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: '1800_HOLLISTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 Hollister Drive, Libertyville, IL 60048',
            guaranteed: null,
          },
        },
        {
          id: 'PAYLOCITY_LUNCH_ROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 American Ln, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LGB6_REAR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20901 Krameria Avenue, Riverside, CA 92518',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JFK_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '65 James St, Edison, NJ 08820',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_TIFFANY_CO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 5th Avenue, New York, NY 10010',
            guaranteed: true,
          },
        },
        {
          id: 'TX_SAN_TEXAS_STATE_EVANS_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 University Dr, San Marcos, TX 78666',
            guaranteed: null,
          },
        },
        {
          id: 'b1c65c9a-8633-46bc-8068-5cfc1adc72de',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '518ac965-6ef6-4e77-b1b6-d81ea31334a5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ef38cd90-c283-441d-a7f8-990289044971',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'kekekekea',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'kekekekea, kekekekea, WI 12345',
            guaranteed: null,
          },
        },
        {
          id: 'DC_VETERANS_ADMIN_CENTRAL_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '810 Vermont Ave, NW, Washington, DC 20420',
            guaranteed: null,
          },
        },
        {
          id: 'CA_PARAMOUNT_PICTURES_STUDIOS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5555 Melrose Ave, Los Angeles, CA 90038',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_USPS_AIRPORT_MAIL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5801 Postal Rd, Cleveland, OH 44181',
            guaranteed: null,
          },
        },
        {
          id: '200_W_JACKSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 W Jackson Blvd, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MN_CANTEEN_ONE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 N 5th St, Minneapolis, MN 55401',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HEALTH_FORT_WORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 Pennsylvania Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LOVE_AIRPORT_GATE_16',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8008 Herb Kelleher Way, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BCH_LOBBY_DOUBLE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Longwood Ave, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'ALLSTATE_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3075 Sanders Rd, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MERITER_HOSPITAL_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '202 S Park St, Madison, WI 53715',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_RENTAL_CAR_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2424 E 38th St, Dallas, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_QUEENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '56-45 Main Street, Flushing, NY 11355',
            guaranteed: null,
          },
        },
        {
          id: 'S_C_ELECTRIC_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6601 N Ridge Blvd, Chicago, IL 60626',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAVITS_MEZZANINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '429 11th Ave, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'HARPER_COLLEGE_BUILDING_L',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 W Algonquin Rd, Palatine, IL 60067',
            guaranteed: null,
          },
        },
        {
          id: 'c36edd2b-445b-4959-bc55-9b54f6f7e642',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CRYPTO_ARENA_MAIN_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 S Figueroa St, Los Angeles, CA 90015',
            guaranteed: null,
          },
        },
        {
          id: '46211adf-9602-4c08-b0b8-92113046d739',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_EMERALD_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '402 W Broadway St, San Diego , CA 92101',
            guaranteed: null,
          },
        },
        {
          id: 'ABT_ELECTRONICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 N Milwaukee Ave, Glenview, IL 60025',
            guaranteed: null,
          },
        },
        {
          id: 'a472c7dd-504f-4fe7-831a-15970b5519cf',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HACKENSACK_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 Prospect Ave, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: '5bd3b551-963f-46a8-a47d-e99632087a7f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LEHMAN_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Bedford Park Blvd, Bronx, NY 10468',
            guaranteed: null,
          },
        },
        {
          id: 'b007d424-ab13-4c42-a042-cd81ec6c78b1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'JLL_AON_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 E Randolph St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'FRIEND_HEALTH_COTTAGE_GROVE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6250 South Cottage Grove, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'DOVER_EMPLOYEE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3005 Highland Parkway, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_EXXON_BTEC_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 bayway, Baytown , TX 77520',
            guaranteed: null,
          },
        },
        {
          id: 'MN_SAINT_PAUL_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '235 Marshall Ave, Saint Paul, MN 55102',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_CCD_7TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_UNIVERSITY_OF_ST_AUGUSTINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5401 La Crosse Ave, Austin , TX 78739',
            guaranteed: null,
          },
        },
        {
          id: '6a8b2ffd-bf43-46da-ac92-a9aeb6688215',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ST_PETERS_FAMILY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '123 How Ln, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_B10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, Dallas, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAP_MUSIC_GROUP_959',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6555 Barton Ave Ste 200, Los Angeles, CA 90038',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FEDEX_INDUSTRY_CITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '21971 Industry Way, City of Industry, CA 91789',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CRYPTO_ARENA_SUITE_LEVEL_A1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 S Figueroa St, Los Angeles, CA 90015',
            guaranteed: null,
          },
        },
        {
          id: 'SMS_ASSIST_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '875 N Michigan Ave., Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_14',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_A4',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HINES_VARICK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 Varick St, New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_ARRIVALS',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_PUBLIC_STORAGE_CORPORATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 Western Blvd, Glendale, CA 91201',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_BREWERS_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Brewers Way, Milwaukee, WI 53214',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHSHORE_CARDIOVASCULAR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2100 Pfingsten Rd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_BERGSTROM_TECH_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6800 Burleson Road, Austin , TX 78744',
            guaranteed: null,
          },
        },
        {
          id: 'f7fff273-200d-4ab0-a9c6-58c3d53cd1bc',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_TRINITY_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'W campus Ln #248A, San Antonio, TX 78212',
            guaranteed: null,
          },
        },
        {
          id: 'DEALERS_INSPIRED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1864 High Grove Ln, Naperville, IL 60540',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UC_CLERMONT_PETER_JONES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4200 Clermont College Dr, Batavia, OH 45103',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_USPS_ANNEX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2202 Broadway Ave, Cleveland, OH 44101',
            guaranteed: null,
          },
        },
        {
          id: 'eb8b7c91-ce5c-4f55-b631-e35951c0d29c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CVS_137_STATE_CHI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '137 S State St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COLLIN_COLLEGE_PLANO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 E Spring Creek Parkway, Plano, TX 75074',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_WOODLANDS_ER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17201 I-45, Shenandoah, TX 77385',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_SPRING_MILL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1100 E Hector Street, Conshohocken, PA 19428',
            guaranteed: true,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_CONCOURSE_C',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_TMC_REDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6411 Fannin St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'IND_COMM_NORTH_HAV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8075 N Shadeland Ave, Indianapolis, IN 46250',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ARRIVE_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7701 Metropolis Dr, Austin, TX 78744',
            guaranteed: null,
          },
        },
        {
          id: '2b10abc4-68f9-4eb0-9267-c4e8eb57053a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_CHOP_ROBERTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '734 Schuylkill Ave, Philadelphia, PA 19146',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MARQUETTE_ALUMNI_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1442 W Wisconsin Ave, Milwaukee, WI 53233',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_HEALTH_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 S Main St, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'TESTING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 West Fulton, Chicago, IL 60202',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_AIRPORT_CD_LINK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Essington Ave, Philadelphia, PA 19153',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_LIVONIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '36475 5 Mile Rd, Livonia, MI 48154',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_KINGS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '451 Clarkson Ave, Brooklyn, NY 11203',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_EASTFIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3737 Motley Drive, Mesquite, TX 75150',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_SW_INFLIGHT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'DC_US_COURTS_ADMIN_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Columbus Circle NE, Washington, DC 20002',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_WASHINGTON_TOWNSHIP',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '1997 Miamisburg Centerville Rd, Washington Township, OH 45459',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_REPAIR_BIT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9955 Walford Ave, Cleveland, OH 44102',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ST_ELIZABETH_COVINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 James Simpson Jr Way, Covington, KY 41011',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_ASCENSION_FRANKLIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10101 S 27th, Franklin, WI 53132',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_HUMAN_ECOLOGY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 Linden Dr, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_2allcustomerorders',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_WHOLE_FOODS_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '501 N Lamar Blvd, Austin , TX 78703',
            guaranteed: null,
          },
        },
        {
          id: 'WEST_SUBURBAN_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Erie St, Oak Park, IL 60302',
            guaranteed: null,
          },
        },
        {
          id: 'DISCOVER_FINANCIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 Lake Cook Rd, Riverwoods, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SAMSUNG_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 Challenger Road, Ridgefield Park, NJ 07660',
            guaranteed: null,
          },
        },
        {
          id: '701_E_22ND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 E 22nd St, Lombard, IL 60148',
            guaranteed: null,
          },
        },
        {
          id: '321_NORTH_CLARK_TENANT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '321 N Clark, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LGB6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20901 Krameria Avenue, Riverside, CA 92508',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_NORTH_HARRIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2700 W W Thorne Dr, Houston, TX 77073',
            guaranteed: null,
          },
        },
        {
          id: 'MAINSTAY_SUITES_WHEELING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Capitol Dr, Wheeling, IL 60090',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_PEDIATRIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1002 Wishard Blvd, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_1700_MARKET_ST_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1700 Market St, Philadelphia, PA 19103',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_1_FEDERAL_STREET_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Federal St, Boston, MA 02110',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_HOUSING_AUTHORITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 E. Van Buren, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'MERCH_MART_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 W Merchandise Mart Plz, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_HUNTINGTON_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 Vine St, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_FRIDGE_NJ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '789 N Dixboro Rd, Ann Arbor, MI 48105',
            guaranteed: null,
          },
        },
        {
          id: 'VA_VIRGINIA_HOSPITAL_CENTER_PAVILION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 N George Mason Dr, Arlington, VA 22205',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_GBMC_EMERGENCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6701 Charles St, Baltimore, MD 21204',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_SOCIAL_WORK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1255 Amsterdam Ave, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWHEALTH_EAST_MADISON_HOSP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4602 East Park Blvd, Madison, WI 53718',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_HOSPITALS_LINCOLN_MED_CENTER_BREAKROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '234 e 149th St, Bronx, NY 10451',
            guaranteed: null,
          },
        },
        {
          id: 'US_CELLULAR_PLAZA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8420 W Bryn Mawr Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'MAYER_BROWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '71 S. Wacker Dr., CHICAGO, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENSBOROUGH_CC_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222-05 56th Ave, Queens, NY 11364',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_PSP1_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1010 W 4th St, Beaumont, CA 92223',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RICHMOND_MED_CENTER_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '355 Bard Ave, Staten Island, NY 10310',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_REECE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1001 E Wintergreen Rd, DeSoto, TX 75115',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_EXXON_HQ_SHADE_NATURE_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22777 Springwoods Village Parkway, Spring, TX 77389',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW7_FTW9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '944 W Sandy Lake Rd, Coppell, TX 75019',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWEST_COMMUNITY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 W Central Rd, Arlington Heights, IL 60005',
            guaranteed: null,
          },
        },
        {
          id: 'CITY_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '121 N Lasalle St., Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_OLYMPIA_GYMNASTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '51676 Danview Technology Ct, Shelby Twp, MI 48315',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_INDICOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '721 N Burleson Blvd., Burleson, TX 76028',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_IAH3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15525 Milner Rd, Houston , TX 77032',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_330_E_KILBOURN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 E Kilbourn Ave, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'OAK_BROOK_POINTE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Commerce Drive, Oak Brook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_AMAZON_MTN9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Nottingham Dr, Nottingham, MD 21236',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNITED_INFLIGHT_TRAINING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4375 Wright Rd, Houston , TX 77032',
            guaranteed: null,
          },
        },
        {
          id: 'IND_AIRPORT_GATE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '7800 Col. H. Weir Cook Memorial Dr, Indianapolis, IN 46241',
            guaranteed: null,
          },
        },
        {
          id: 'AA_LOUNGE_ORD',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_UNIVERSITY_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Harvard St SE, Minneapolis, MN 55454',
            guaranteed: null,
          },
        },
        {
          id: '155_E_SUPERIOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '155 E Superior St, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_MERCY_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '615 South New Ballas Road, St. Louis, MO 63141',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CSU_LONG_BEACH_CSPACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1250 Bellflower Blvd, Long Beach, CA 90840',
            guaranteed: null,
          },
        },
        {
          id: 'SHEDD_AQUARIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 S Lake Shore Dr, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_343_THORNHALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '343 Thornhall St, Edison, NJ 08837',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_ARNETT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5165 McCarty Ln, Lafayette, IN 47905',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_CAPITAL_HEALTH_EAST_TRENTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 Hamilton Ave, Trenton, NJ 08629',
            guaranteed: null,
          },
        },
        {
          id: 'MN_TWO_22_TENANT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 South 9th Street, Minneapolis, MN 55402',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AREA_23',
          type: 'FRIDGE',
          operationConfigs: {
            address: '622 Third Ave, New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: 'WALSH_GROUP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '307 S Sangamon, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_NORTH_CENTRAL_BAPTIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '520 Madison Oak Drive, San Antonio , TX 78258',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MORRISTOWN_MEDICAL_CARDIAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Madison Ave, Morristown, NJ 07960',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AMAZON_LDJ5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '526 Gulf Ave, Staten Island, NY 10314',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_CHILDRENS_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3401 Burnet Avenue, Cincinatti, OH 45229',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_C4',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_ROBBINSVILLE_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 New Canton Way, Robbinsville, NJ 08961',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_EDELMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Hudson Street, New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: '150_N_MARTINGALE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 N Martingale Rd, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_FREDDIE_MAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6555 Excellence Way, Plano, TX 75023',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MCW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8701 W Watertown Plank Rd, Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'fb4ac0cc-0d45-44e5-85d8-0a313c13ec1d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'FOUR_PARKWAY_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Parkway North, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: '4901_SEARLE_PARKWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4901 Searle Pkwy, Skokie, IL 60077',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ESKENAZI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '720 Eskenazi Avenue, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'f0c0489a-d73c-4d66-a8f1-4bd7cc308cbf',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BIDMC_ROSENBERG_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Deaconess Rd, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_3H_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_MERCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 Locust St., Pittsburgh, PA 15219',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_1801_CONGRESS_MICROMARKET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1801 Congress, Austin , TX 78701',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MD_ANDERSON_FACULTY_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 Pressler St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HONDA_DOWNTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '780 W Martin Luther King Jr Blvd, Los Angeles , CA 90037',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T2_H11',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: '10_S_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FIT_SC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '227 W 27th St, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: '111_E_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 E Upper Wacker Dr, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'a440dd2d-1a36-4c40-88b2-06132f07b050',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_VA_539',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3200 Vine St, Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_CICERO_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5370 S Cicero Ave Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_600_CONGRESS_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Congress, Austin, TX 78701',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_ALLEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1105 N Central Expy, Allen, TX 75013',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_HOSPITALS_COLER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Main St, New York, NY 10044',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_UPENN_RYAN_VETERINARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3900 Spruce St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'ALLSTATE_NORTHBROOK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 Sanders Rd, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_BAYSHORE_MC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '727 N Beers St, Holmdel, NJ 07733',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3501 S Michigan Ave, Chicago, IL 60653',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_SCHLITZ_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1555 N Rivercenter Dr, Milwaukee, WI 53212',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWHEALTH_UNIV_HOSP_SURGERY_CTR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Highland Ave, Madison, WI 53792',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AMAZON_MKE1_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3501 120th Ave, Kenosha, WI 53144',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_WILSON_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '219 Wilson Rd, East Lansing, MI 48825',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_BLOOMBERG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 N Charles St, Baltimore, MD 21218',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_HEALTH_MASONIC_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2450 Riverside Ave, Minneapolis, MN 55454',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CENTINELA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 E Hardy St, Inglewood, CA 90301',
            guaranteed: null,
          },
        },
        {
          id: 'FLEX_700_SOUTH_LUNCHROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Corporate Grove Dr, Buffalo Grove , IL 60089',
            guaranteed: null,
          },
        },
        {
          id: 'OAKTON_COMMUNITY_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 E Golf Rd, Des Plaines, IL 60016',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_B20',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'AJ_CAPITAL_PARTNERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '133 N Jefferson St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BROOKLYN_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Poly Pl, Brooklyn, NY 11209',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_TEMPLE_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1331 E Wyoming Ave, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_ST_LUKES_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 W Oklahoma Ave, Milwaukee, WI 53215',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1441 N Beckley Ave, Dallas, TX 75203',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_CONROE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '504 Medical Center Blvd, Conroe, TX 77304',
            guaranteed: null,
          },
        },
        {
          id: 'ULTA_BEAUTY_HQ_3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Remington Blvd., Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWHEALTH_UNIV_HOSP_CAFE_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Highland Ave, Madison, WI 53792',
            guaranteed: null,
          },
        },
        {
          id: '4973b939-2d83-41ed-961c-8a48ca6faca0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '303_E_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '303 E Wacker Dr, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'DUPAGE_TEC_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 22nd St, Glen Ellyn, IL 60137',
            guaranteed: null,
          },
        },
        {
          id: 'OLYMPIA_FIELDS_HOSPITAL',
          type: 'DELIVERY',
          operationConfigs: {
            address: '20201 South, Crawford Ave, Olympia Fields, IL 60461',
            guaranteed: null,
          },
        },
        {
          id: 'VA_MARYMOUNT_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2807 N Glebe Rd, Arlington , VA 22207',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_186_WOOD_AVENUE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '186 Wood Avenue South, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'BP_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 S. Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '6de4a801-621e-47c2-a709-e92344a1995b',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SUFFOLK_SARGENT_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '120 Tremont St, Boston, MA 02108',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1050 Wishard Blvd, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_NE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18951 N Memorial Dr, Humble, TX 77338',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_125_BROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '125 Broad St., New York, NY 10004',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_LAWRENCE_GEN_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 General St, Lawrence, MA 01842',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_GATE_A8_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_CHILDRENS_ADVOCACY_NEW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1240 S Damen Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_NEWARK_BERGEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '65 Bergen St, Newark, NJ 07107',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_COLUMBIA_ST_MARYS_OZAUKEE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13111 N Port Washington Rd, Mequon, WI 53097',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_WILLOWBROOK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18220 State Hwy 249, Houston, TX 77070',
            guaranteed: null,
          },
        },
        {
          id: 'PRESENCE_SAINT_JOSEPH_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 N Lake Shore Dr., Chicago, IL 60657',
            guaranteed: null,
          },
        },
        {
          id: 'US_FOODS_ROSEMONT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9399 W Higgins Road, Rosemont, IL 60018',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TROY_1ST_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44201 Dequindre Rd, Troy, MI 48085',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MONTEFIORE_NYACK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 N Midland Ave, Nyack, NY 10960',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_200_WILMOT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Wilmot Rd, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'b6a2f5b8-97ea-40f8-9796-67f7e29871a2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_STATE_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9501 S King Dr, Chicago, IL 60628',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_HTC_BROOKLYN_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9000 Brooklyn Blvd, Brooklyn Park, MN 55445',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_HAHN_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'Alcala Park Way, San Diego, CA 92110',
            guaranteed: null,
          },
        },
        {
          id: 'IIT_KENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '565 W Adams St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_YANKEES_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 E 161 St, Bronx, NY 10451',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_MAIN_CAMPUS_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 Souther Boulevard, Kettering, OH 45429',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_KELLER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1307 E 60th St., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_JOSEPHS_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '127 South Broadway, Yonkers, NY 10701',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_D18',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SOUTHERN_OCEAN_MC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1140 NJ-72, Manahawkin, NJ 08040',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_186_WOOD_AVENUE_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '186 Wood Avenue South, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CON_ED_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Irving Place, New York, NY 10003',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_ONT9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2125 San Bernardino Rd, Redlands, CA 92408',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_580_ANTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '580 Anton Blvd, Costa Mesa, CA 92626',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_ST_CHRISTOPHERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 E Erie Ave, Philadelphia, PA 19134',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WALMART_AREA_200_CHINO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6750 Kimball Ave, Chino, CA 91710',
            guaranteed: null,
          },
        },
        {
          id: 'VA_ARLINGTON_COUNTY_DEPT_HUMAN_SERVICES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2100 Washington Blvd, Arlington, VA 22204',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_OFFLINE_FRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_UBS_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Harbor Blvd, Weehawken, NJ 07086',
            guaranteed: true,
          },
        },
        {
          id: 'NYC_MAIMONIDES_8TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4802 10th Ave, Brooklyn , NY 11219',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '755 W Michigan St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'ALEXIAN_BEHAVIORAL_HEALTH_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1650 Moon Lake Blvd, Hoffman Estates, IL 60169',
            guaranteed: null,
          },
        },
        {
          id: 'OLYMPIA_FIELDS_FRANCISCAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20201 S Crawford Ave, Olympia Fields, IL 60461',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_CAREY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 International Dr, Baltimore, MD 21202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAL_POLY_ENV_7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 West Temple Avenue, Pomona, CA 91768',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 N Charles St, Baltimore, MD 21218',
            guaranteed: null,
          },
        },
        {
          id: 'IND_RIVER_CROSSING_I',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3815 River Crossing Parkway, Indianapolis, IN 46240',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_GE_ELECTRIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4855 Electric Ave, Milwaukee, WI 53219',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNITED_AIRLINES_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Grand Plaza Dr, Houston, TX 77056',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_GREATER_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6701 N Charles St, Towson, MD 21204',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CHANEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9 W 57th St, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_WEST_LOOP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5601 W Loop S, Houston , TX 77081',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_AMAZON_BWI5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5501 Holabird Ave, Baltimore, MD 21224',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_AA_FA_LOUNGE_A35',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: '0810ccdc-78ba-4eda-948c-728516ac78b8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_PITT_LANGLEY_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '171 Tennyson Ave, Pittsburgh, PA 15260',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_SANDBURG_RESIDENCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 N Maryland Ave, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HEART_HCA_CLEAR_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '495 W. Medical Center Blvd, Webster , TX 77598',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_INGALLS_CALUMET_CITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 Torrence Ave, Calumet City, IL 60409',
            guaranteed: null,
          },
        },
        {
          id: 'PA_UPENN_HOSPITAL_HUP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 Spruce St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_HEART_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 N Lamar Blvd, Austin , TX 78756',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AUDIE_MURPHY_VA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7400 Merton Minter, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNESOTA_VIKINGS',
          type: 'FRIDGE',
          operationConfigs: {
            address: "668 O'Neill Drive, Minneapolis, MN 55121",
            guaranteed: null,
          },
        },
        {
          id: 'INSIGHT_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2525 S. Michigan Ave, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_MONROE',
          type: 'DELIVERY',
          operationConfigs: {
            address: '113 S Franklin Street, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_GM_INNOVATION_CENTER_MARKET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13201 McCallen Pass, Austin , TX 78753',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_RIVERVIEW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2340 N Commerce St, Milwaukee, WI 53212',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_GREENE_MEMORIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1141 North Monroe Dr, Xenia, OH 45385',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PRINCETON_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Plainsboro Rd, Plainsboro Township, NJ 08536',
            guaranteed: null,
          },
        },
        {
          id: 'NORTH_PARK_UNI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3225 W Foster Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_OFFLINE_FRIDGEallcustomerorders',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_ARLINGTON_SPORTS_CLUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29 Massachusetts Ave, Arlington, MA 02474',
            guaranteed: null,
          },
        },
        {
          id: 'TRIANGLE_PLAZA_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8750 W Bryn Mawr Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: '8045_LAMON_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8045 Lamon Ave, Skokie, IL 60077',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_METHODIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 S Broad St, Philadelphia, PA 19148',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CUYAHOGA_CC_WESTERN_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Carnegie Ave, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: 'SWEDISH_COVENANT_HOSPITAL_EMERGENCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5140 N California Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_PSP1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1010 West 4th Street, Beaumont, CA 92223',
            guaranteed: null,
          },
        },
        {
          id: '1_S_DEARBORN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 South Dearborn Street, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_NJCU_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2039 John F. Kennedy Blvd, Jersey City , NJ 07305',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SPECIALTY_RX_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2 Bergen Turnpike, Ridgefield Park, NJ 07660',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_UTA_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '702 Planetarium Pl, Arlington, TX 76019',
            guaranteed: null,
          },
        },
        {
          id: 'a0a2406c-0001-47f1-9abd-61be6fd47ddb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_MERCY_MED_CENTER_16TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '345 St Paul Pl, Baltimore, MD 21202',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_PHYSICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Way, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BMCC_4TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '199 Chambers St, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: '500_PARK_BLVD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Park Blvd, Itasca, IL 60143',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_HENRY_FORD_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2799 W Grand Blvd, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'MUSEUM_SCIENCE_INDUSTRY_VENDING_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Lake Shore Dr, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: '536_S_CLARK_7TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '536 S. Clark St., Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_TRUSTAGE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5910 Mineral Point Rd., Madison, WI 53705',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_UMASS_CAMPUS_CENTER_UPPER_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 William T Morrissey Blvd, Boston, MA 02125',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAMAICA_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8900 Van Wyck Expy, Queens, NY 11418',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_NEWARK_BETH_ISRAEL_GROUNDFLR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 Lyons Ave, Newark, NJ 07112',
            guaranteed: null,
          },
        },
        {
          id: 'CTA_GARAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '642 North Pulaski Road, Chicago, IL 60624',
            guaranteed: null,
          },
        },
        {
          id: 'PRESENCE_RESURRECTION_MEDICAL_ER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7435 W Talcott Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_SOUTHWEST_WINGS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2195 Research Row, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_UNIVERSITY_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20515 TX-249 S, Houston, TX 77070',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6706 Conestoga Trail, Loves Park, IL 61111',
            guaranteed: null,
          },
        },
        {
          id: 'IIT_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 W 35th St, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'CHARLES_SCHWAB_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_D38_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BALTIMORE_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 N Greene St, Baltimore, MD 21201',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_MIT_BUILDING_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '182 Memorial Dr, Cambridge, MA 02139',
            guaranteed: null,
          },
        },
        {
          id: 'VA_WESTPARK_CORPORATE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8484 Westpark Dr, Tysons, VA 22102',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HOFSTRA_UNIVERSITY_NETHERLANDS_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'Oak St, Uniondale, NY 11553',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_CHILDRENS_HOSPITAL_CABANA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8915 W Connell Ct., Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_PRAIRIE_VIEW_AM_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6436 Fannin St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'OH_MOUNT_CARMEL_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6001 E Broad St, Columbus, OH 43213',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MITCHELL_DEPARTURES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 S Howell Ave., Milwaukee, WI 53207',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_DREXEL_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3210 Chesnut St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_A28',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_RICHLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12800 Abrams Road, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: '3ce3f11e-af3b-48b4-bfe5-03c6082f9f4a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'SCHAUMBURG_CORP_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1501 Woodfield Road, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_AIRPORT_AB_LINK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Essington Ave, Philadelphia, PA 19153',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_FLEX_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12455 Research Blvd, Austin , TX 78759',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LINCOLN_CENTRE_ONE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 Lyndon B Johnson Fwy, Dallas, TX 75240',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_FITNESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '221 Walter St, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_EMERSON_PARAMOUNT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 Washington St, Boston, MA 02111',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT3_1404',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6806 Cal Turner Dr, San Antonio , TX 78220',
            guaranteed: null,
          },
        },
        {
          id: 'bdc3bf50-a432-4873-aaaf-00a466bc37d3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_EMTC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10-10 Hazen St, Bronx, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: '62b57992-37c9-4adc-bc50-572c4b51584c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_LU_WINSLOW_ACADEMIC_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1844 Commonwealth Ave, Auburndale, MA 02466',
            guaranteed: null,
          },
        },
        {
          id: 'EPIC_BURGER_ADAMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 W Adams St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_2_E10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENS_COLLEGE_QUEENS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '65-21 Main Street, Flushing, NY 11367',
            guaranteed: null,
          },
        },
        {
          id: 'KEMPER_LAKES_BUILDING_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Corporate Drive, Lake Zurich, IL 60047',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_CALHOUN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2480 Corbett Dr, Cincinnati, OH 45219',
            guaranteed: null,
          },
        },
        {
          id: '693e3ca8-52bb-470a-b2c2-e586a0210f66',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW6_1ST_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 W Bethel Rd, Grapevine, TX 76051',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_8TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3420 w 63rd st, Chicago, IL 60629',
            guaranteed: null,
          },
        },
        {
          id: 'DC_HOWARD_UNIVERSITY_MEDICINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '520 W St NW, Washington, DC 20059',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ONT_TERMINAL_4_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 E Airport Dr, Ontario, CA 91761',
            guaranteed: null,
          },
        },
        {
          id: 'IND_SALESFORCE_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 E Ohio St, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'ENOVA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '175 W Jackson Blvd, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ENERGY_TRANSFER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 Main, Houston, TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_EMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3200 N Cramer St, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCU_RECREATION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3005 Stadium Dr, Fort Worth, TX 76109',
            guaranteed: null,
          },
        },
        {
          id: '311_S_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '311 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'e770bda9-2cfa-437f-af55-ec24579a7eda',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WOODLANDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17580 I-45, The Woodlands, TX 77384',
            guaranteed: null,
          },
        },
        {
          id: 'f9fa62ad-41a9-4296-b2bb-f19fe6412f70',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DEMO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_EMBLEM_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 Water St, New York, NY 10041',
            guaranteed: null,
          },
        },
        {
          id: 'b444e57f-bd11-463a-a4d1-a348962b19f2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_TRIBECA_WEST_150',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12233 W Olympic Blvd, Los Angeles, CA 90064',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BNSF_RAILWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2600 Lou Menk Dr, Fort Worth, TX 76131',
            guaranteed: null,
          },
        },
        {
          id: 'MACNEAL_HOSPITAL2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3249 S Oak Park Ave, Berwyn, IL 60402',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_HEALTH_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1050 Wishard Blvd, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_2_E29',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_COOLIDGE_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4949 Coolidge Highway, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_CCNY_NORTH_AC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 Convent Ave, New York, NY 10031',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_COLLEGE_OF_DENTISTRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S. Paulina St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DHS_ST_ELIZABETH_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2701 Martin Luther King Jr. Ave, Washington , DC 20032',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MU_PUBLIC_SQUARE_HOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Hempstead Ave, Rockville, NY 11570',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5990 Cajon Blvd, San Bernardino, CA 92408',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ST_PETERS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '254 Easton Ave, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TOSHIBA_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13131 W Little York Rd, Houston , TX 77041',
            guaranteed: null,
          },
        },
        {
          id: '120_S_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '120 S Riverside Plaza, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '1340_S_DAMEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1340 S. Damen Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_MAIN_CONCOURSE_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_JLL_13100_SPACE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13100 Space center, Houston , TX 77059',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_COMER_CHILDRENS_HOSP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5721 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_CHARLTON_METHODIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3500 W Wheatland Rd, Dallas, TX 75237',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_SAIEH_HALL_ECONOMICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5757 S University Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_SPECTRUM_MOBILE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11921 N Mopac Expy, Austin , TX 78759',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_15TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5701 W Madison St, Chicago, IL 60644',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_OU_OAKLAND_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '312 Meadow Brook Rd, Rochester, MI 48309',
            guaranteed: null,
          },
        },
        {
          id: 'CTA_FULLERTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '943 W. Fullerton Ave, Chicago, IL 60614',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_DUOLINGO_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '141 S St Clair St., Pittsburgh, PA 15206',
            guaranteed: null,
          },
        },
        {
          id: 'MACNEAL_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3249 S Oak Park Ave, Berwyn, IL 60402',
            guaranteed: null,
          },
        },
        {
          id: 'MUSEUM_SCIENCE_INDUSTRY_EMPLOYEE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Lake Shore Dr., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'b6dd0bab-906c-4477-b4a9-f57adbbce4c0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'GGP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '110 N Upper Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LENNAR_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 FivePoint, Suite 350, Irvine, CA 92618',
            guaranteed: null,
          },
        },
        {
          id: 'MN_3M_HQ_BUILDING_235',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2564 Conway Ave, Maplewood, MN 55144',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BAPTIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '125 Parker Hill Ave, Boston, MA 02120',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_TOLL_BROTHERS_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1140 Virginia Dr, Fort Washington, PA 19034',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_CHILDRENS_KOALA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4401 Penn Ave, Pittsburgh, PA 15224',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_E7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: '75a66560-5460-4fac-a3db-b09ed78b8e69',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'AIT_WORLDWIDE_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '975 Algonquin Rd, Palatine, IL 60067',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_ONE_CLEVELAND_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1375 E 9th St, Cleveland, OH 44114',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_CONCOURSE_A',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07144',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_U_ST_THOMAS_CSHP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4102 Yoakum Blvd, Houston , TX 77006',
            guaranteed: null,
          },
        },
        {
          id: '3eab29ed-52c8-4843-a6dd-e9156c4b652d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EAST_ORANGE_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '385 Tremont Ave, East Orange, NJ 07018',
            guaranteed: null,
          },
        },
        {
          id: '842b949d-a3ce-4232-8095-d1fc0532ea90',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LAGUARDIA_CC_BUILDING_E',
          type: 'FRIDGE',
          operationConfigs: {
            address: '31-10 Thomson Ave, Queens, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_JOHN_CRERAR_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5730 S Ellis Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_6TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7808 S Halsted St, Chicago, IL 60620',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_HENRY_FORD_ONE_FORD_PL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Ford Pl, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SOCAL_HOSPITAL_CULVER_CITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3828 Delmas Terrace, Culver City, CA 90232',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BABSON_HELV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '231 Forest St, Babson Park, MA 02457',
            guaranteed: null,
          },
        },
        {
          id: 'IND_FRANCISCAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8111 S Emerson Ave, Indianapolis, IN 46237',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_SPECIALTY_TRANSPLANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8026 Floyd Curl Drive, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: '110_N_WACKER_23F',
          type: 'FRIDGE',
          operationConfigs: {
            address: '110 N. Wacker, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_FEDERAL_COURT_HOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 Marconi Blvd, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: '0cff0860-5c1b-4cca-8e73-2b5f5f84a75f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_1245_MAIN_ST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1245 Main St, Rahway, NJ 07065',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DEPT_OF_ENERGY_GOV_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Independence Ave SW, Washington , DC 20024',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_OUTPATIENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 Orleans St, Baltimore, MD 21287',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MD_ANDERSON_ROC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1700 Holcombe Blvd, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1494 S Waterman Avenue, San Bernardino, CA 92408',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BC_ONEILL_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '140 Commonwealth Ave, Chestnut Hill, MA 02467',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_MEDICAL_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '835 S Wolcott Ave, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_VA_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4100 W Third St, Dayton, OH 45428',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GWC_LEARNING_RESOUCE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 15744 Goldenwest St, Huntington Beach, CA 92647',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LOYOLA_MARYMOUNT_LAW_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '919 Albany St, Los Angeles, CA 90015',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_VA_JEFFERSON_BARRACKS_657',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Jefferson Barracks Dr, St Louis, MO 63125',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_GRANITE_757_ELDRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '757 N Eldridge Pkwy, Houston , TX 77079',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_FEE_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '939 Fee Rd, East Lansing, MI 48825',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MSK_WESTCHESTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Westchester Ave, West Harrison, NY 10604',
            guaranteed: null,
          },
        },
        {
          id: 'UI_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1740 West Taylor Street, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SUFFOLK_UNI_SAWYER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8 Ashburton Pl, Boston, MA 02108',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_5_DUP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1623 Dalton Ave, Cincinnati, OH 45234',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DENTSU_42ND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 E 42nd St., New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: 'MAINSTAY_SUITES_SCHAUMBURG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 Bank Dr, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: '540_W_MADISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '540 W Madison St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'DWIGHT_LOFTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '642 S Clark St, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'MD_AACC_HLSB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 College Parkway, Arnold, MD 21012',
            guaranteed: null,
          },
        },
        {
          id: 'MN_U_ST_THOMAS_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2115 Summit Ave, St. Paul, MN 55105',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_MASSBAY_CC_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 Oakland Street, Wellesley, MA 02481',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_DINSMORE_SHOHL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '255 E 55th St, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_12TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1412 S Blue Island Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'NY_JFK_T5_G7',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'John F. Kennedy International Airport, Terminal 5, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'ULTA_BEAUTY_HQ_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Remington Blvd, Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'NORTH_BRIDGE_FOOD_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '520 N. Michigan, Chicago , IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T2_F1',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: '512e71f9-aae5-4597-a8de-fac94dc89be7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '600_W_FULTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Fulton St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '190 S Prospect Ave, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_BAYLOR_ST_LUKES_MAIN_BRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 Bates Avenue, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_BENCHMARK_INDUSTRIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '950 Claycraft Rd, Gahana, OH 43230',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_8686_NEW_TRAILS_DR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8686 New Trails Dr, The Woodlands, TX 77381',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CHUBB_LIMITED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 S Flower St, Los Angeles, CA 90012',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MAIMONIDES_ASB_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1025 48th , Brooklyn, NY 11219',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ROLLS_ROYCE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '450 S Meridian Street, Indianapolis, IN 46225',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHILLY_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3900 Woodland Ave, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LAX_INTL_TERMINAL_GATE_130',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 World Way, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_U_MARYLAND_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '901 Harry S Truman Dr, Largo, MD 20774',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GWC_MATH_SCIENCE_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15744 Goldenwest St, Huntington Beach, CA 92647',
            guaranteed: null,
          },
        },
        {
          id: 'DC_CAPITOL_POLICE_STATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '119 D St NE, Washington, DC 20510',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MAIMONIDES_MAIN_DOUBLE_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4802 10th Ave, Brooklyn , NY 11219',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAMAICA_MED_CENTER_ER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8900 Van Wyck Expy, Queens, NY 11418',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18200 Katy Fwy, Houston , TX 77094',
            guaranteed: null,
          },
        },
        {
          id: 'MN_ST_MARYS_UNIVERSITY_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 Park Ave, Minneapolis, MN 55404',
            guaranteed: null,
          },
        },
        {
          id: 'ALLSTATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3075 Sanders Rd, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CUYAHOGA_CC_WEST_SHORE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Carnegie Ave, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: '706c2110-0f72-449e-bf41-1837174885c8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'COMPASS_HEALTH_CENTER_NORTHBROOK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Revere Dr, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Normal Ave, Montclair, NJ 07043',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_JACOBI_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 Pelham Pkwy S, Bronx, NY 10461',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PRENTICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 E Superior St, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: '69_W_WASHINGTON_PEDWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '69 W Washington St., Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2525 Chicago Avenue South, Minneapolis, MN 55404',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_C2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_GLICK_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 MetroHealth Dr., Cleveland , OH 44109',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_309_N_WATER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '309 N Water St, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_GLEACHER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '450 Cityfront Plaza Dr, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_1455_FRAZEE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1455 Frazee Rd, San Diego , CA 92108',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_MEDSTAR_TIMONIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2118 Greenspring Dr, Timonium, MD 21093',
            guaranteed: null,
          },
        },
        {
          id: 'DUPAGE_PEC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Fawell Blvd, Glen Ellyn, IL 60137',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_WOODLAND_HILLS_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5601 De Soto Ave, Woodland Hills, CA 91367',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_VA_580',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2002 Holcombe Blvd, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAL_STATE_MAIN_FOOD_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5151 State University Dr, Los Angeles, CA 90032',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_TABLER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Road, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8120 Humble Westfield Rd, Humble, TX 77338',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T5_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CHILDRENS_HOSPITAL_COFFEE_SHOP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9835 N Lake Creek Pkwy, Austin , TX 78717',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_METROPOLITAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1901 1st Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HORIZON_BCBS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Penn Plaza E, Newark, NJ 07105',
            guaranteed: null,
          },
        },
        {
          id: '788944c6-a7b4-46d8-bef1-d530ce29bc9d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CHOC_EMERGENCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4650 Sunset Blvd, Los Angeles, CA 90027',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RARITAN_BAY_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '530 New Brunswick Avenue, Perth Amboy, NJ 08861',
            guaranteed: null,
          },
        },
        {
          id: 'DEPAUL_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2320 N Kenmore Ave, Chicago, IL 60614',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAPGROUP_400_HOPE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 S Hope St, Los Angeles, CA 90071',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_PEARLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16100 South Fwy, Pearland , TX 77584',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_YAMAHA_MOTORS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6555 Katella Ave, Cypress, CA 90630',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_SOUTH_SUBURBAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17800 Kedzie Ave, Hazel Crest, IL 60429',
            guaranteed: null,
          },
        },
        {
          id: 'METRA_LASALLE_STATION_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '414 S LaSalle St, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DMC_CHOM_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4442 Beaubien Rd, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: '60e9395b-9a5a-443c-b14e-c206c0ae65bb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PAYLOCITY_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 American Ln, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_CARTHAGE_COLLEGE_CLAUSEN_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2001 Alford Park Drive, Kenosha, WI 53140',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_CAPITAL_HEALTH_REGIONAL_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '750 Brunswick Ave, Trenton, NJ 08638',
            guaranteed: null,
          },
        },
        {
          id: '10_S_RIVERSIDE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 S Riverside Plaza, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ORTHOPEDIC_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7401 Main St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_BUCKS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '380 N Oxford Valley Rd, Langhorne, PA 19047',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_PENN_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 SPRUCE STREET, PHILADELPHIA, PA 19107',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_UNIVERSITY_AKRON_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '259 S Broadway St, Akron, OH 44325',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_FRANCIS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Port Washington Blvd, Roslyn, NY 11576',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_BUTLER_BETHESDA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3125 Hamilton Mason Rd, Hamilton, OH 45011',
            guaranteed: null,
          },
        },
        {
          id: 'MI_EMU_ALEXANDER_MUSIC_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '000 E Circle Dr, Ypsilanti, MI 48197',
            guaranteed: null,
          },
        },
        {
          id: 'ZEBRA_TECHNOLOGIES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Overlook Pt, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DFW7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Westport Pkwy, Fort Worth, TX 76177',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_ALLEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5141 Broadway, New York, NY 10034',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_USPS_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Orange Ave, Cleveland, OH 44101',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_CENTRAL_MARKET_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MAD_ARBOR_GATE_2601',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 w Beltline Hwy, Madison, WI 53713',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JET_COM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '221 River St, Hoboken, NJ 07030',
            guaranteed: null,
          },
        },
        {
          id: 'GEORGE_LEIGHTON_JURY_ROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2650 S California Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M3',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NYC_225_BROADWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 Broadway, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: 'COMMERCE_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2001 Spring Road, Oak Brook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_LIFE_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Veterans Dr, Minneapolis, MN 55417',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_ICNC_121',
          type: 'FRIDGE',
          operationConfigs: {
            address: '121111 S State St, Downers Grove, IL 60605-3413',
            guaranteed: null,
          },
        },
        {
          id: '1007_W_HARRISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1007 W Harrison Street, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_YORK_HPE_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '94-20 Guy R Brewer Blvd, Jamaica, NY 11451',
            guaranteed: null,
          },
        },
        {
          id: 'e841b5c5-eb79-46ea-8140-ca9470ee0af0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'JENNER_BLOCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '353 N Clark St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CA_JET_PROPULSION_LABORATORY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4800 Oak Grove Dr, Pasadena, CA 91109',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_THE_MET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 5th ave, New York, NY 10028',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_EDITH_NOURSE_VA_518',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Springs Rd, Bedford, MA 01730',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_JAVITS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_2_E34_WALKWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_W_LA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6041 Cadillac Avenue, Los Angeles, CA 90034',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_OAK_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '520 S Maple Ave, Oak Park, IL 60304',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_SOUTH_SHORE_UNI_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '301 E Main St, Bay Shore , NY 11706',
            guaranteed: null,
          },
        },
        {
          id: 'CNA_WABASH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 S Wabash Ave, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AMAZON_MKE1_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3501 120th Ave, Kenosha, WI 53144',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_2_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'MORNINGSTAR_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22 W Washington St, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'f9ca34d7-57b8-4980-b4d6-ab9854567fc1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_MADISON_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1955 Citracado Parkway, Escondido, CA 92029',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_WAGNER_COLLEGE_SPIRO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Campus Rd, Staten Island , NY 10301',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HAMILTON_COUNTY_POLICE_DEPT',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '1270 Whitehorse Mercerville Rd, Hamilton Township, NJ 08619',
            guaranteed: null,
          },
        },
        {
          id: 'd37dd498-d475-4ee4-99ce-8cf9a679bdc5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PRESENCE_RESURRECTION_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7435 W Talcott Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STARCOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 Hudson St, New York, NY 10014',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JEFFERSON_HEALTH_STRATFORD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18 E Laurel Rd, Stratford, NJ 08084',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOLLYWOOD_PRESBYTERIAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 North Vermont Ave, Los Angeles, CA 90027',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_DEARBORN_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18101 Oakwood Blvd, Dearborn, MI 48124',
            guaranteed: null,
          },
        },
        {
          id: '1700_W_HIGGINS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1700 W Higgins Road, Des Plaines, IL 60018',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_OU_RECREATION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '569 Pioneer Dr, Rochester, MI 48309',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_NORTHVILLE_HEALTH_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '39901 Traditions Dr, Northville, MI 48168',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AMAZON_LDJ5_LINEAR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '526 Gulf Ave, Staten Island, NY 10314',
            guaranteed: null,
          },
        },
        {
          id: '200_N_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 N Lasalle St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_PARMA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12301 Snow Rd, Parma, OH 44130',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RWJ_RAHWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '865 Stone St, Rahway, NJ 07065',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_MULTI_MODAL_FACILITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HOFSTRA_UNIVERSITY_BRESLIN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '105 Hofstra University, Hempstead, NY 11549',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_RICHARDSON_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2831 E President George Bush Hwy, Richardson, TX 75082',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2160 S 1st Ave, Maywood, IL 60153',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_UD_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1845 E Northgate Drive I, Irving , TX 75062',
            guaranteed: null,
          },
        },
        {
          id: 'DC_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 Irving Street NW, Washington, DC 20422',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_MCDONEL_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '817 E Shaw Ln, East Lansing, MI 48825',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13536 N Central Expy, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_OVERLOOK_MEDICAL_MATERNITY_WARD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '99 Beauvoir Ave, Summit, NJ 07901',
            guaranteed: null,
          },
        },
        {
          id: 'USPS_OHARE_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11600 W Irving Park Rd, Chicago, IL 60666',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SUFFOLK_COUNTY_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Pemberton Square, Boston, MA 02108',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DCAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Centre St, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JOHNSON_AND_JOHNSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 US-202 South, Raritan, NJ 08869',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMERICAN_AIRLINES_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13800 Airport Fwy, Fort Worth, TX 76155',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_MCCULLOUGH_HYDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '110 N Poplar St, Oxford, OH 45056',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_VA_LONG_BEACH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5901 E 7th Street, Long Beach, CA 90822',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_COMMUNITY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 Hospital Rd, Ptachogue, NY 11772',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_NORTH_CYPRESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '21214 Northwest Fwy, Cypress, TX 77429',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_DSCC_BUILDING_20',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3990 E Broad St, Columbus, OH 43213',
            guaranteed: null,
          },
        },
        {
          id: 'ST_BERNARD_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '326 W 64th St, Chicago, IL 60621',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_NORTH_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5001 N MacArthur Blvd, Irving, TX 75038',
            guaranteed: null,
          },
        },
        {
          id: '3d6b6c88-3173-401c-94ee-5b142a4bffe7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_UMB_PHARMACY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 N Pine St, Baltimore, MD 21201',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_DEPARTMENT_OF_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '35 E. Chestnut St, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ELMHURST_HOSPITAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '79-01 Broadway, Queens, NY 11373',
            guaranteed: null,
          },
        },
        {
          id: '094730fd-794e-4360-a93a-205bb3bcb33a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PRESIDENTS_PLAZA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8600 W Bryn Mawr Ave., Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LAX_T1_GATE_14',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 World Way, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_VA_CLINIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7901 Metropolis Dr, Austin , TX 78744',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_ORD4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25810 S Ridgeland Ave, Monee, IL 60449',
            guaranteed: null,
          },
        },
        {
          id: 'VA_GOOGLE_ALEXANDRIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 Duke St, Alexandria, VA 22314',
            guaranteed: null,
          },
        },
        {
          id: 'DUPAGE_SRC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Fawell Blvd, Glen Ellyn, IL 60137',
            guaranteed: null,
          },
        },
        {
          id: 'b78c1bd6-94c5-4b68-8135-e7bd24164543',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FCB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 W 33rd St, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_RIVER_CITY_CASINO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '777 River City Casino Blvd, St Louis, MO 63125',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_APPLE_CORPORATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11 Pennsylvania Plaza, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RWJBH_NEW_BRUNSWICK',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 1 Robert Wood Johnson Pl, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAL_POLY_AMM_45',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 W Temple Ave, Pomona, CA 91768',
            guaranteed: null,
          },
        },
        {
          id: '55_E_MONROE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 E Monroe St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'KRAFT_HEINZ_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 E Randolph St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_SAKS_FIFTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Plaza Frontenac St, St Louis, MO 63131',
            guaranteed: null,
          },
        },
        {
          id: '4d25871e-c39c-4029-8366-07b998cacf6a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PRESENCE_SAINT_FRANCIS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '355 Ridge Ave, Evanston, IL 60202',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_HOMAN_SQUARE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3440 W Fillmore St, Chicago, IL 60624',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_BRECKSVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9200 Treeworth Blvd, Brecksville, OH 44141',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_BETH_ISRAEL_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '281 1st Avenue, New York, NY 10003',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_SHAW_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '591 N Shaw Ln, East Lansing, MI 48825',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_1ST_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1718 S State St, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_WINCHESTER_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '41 Highland Ave, Winchester, MA 01890',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCC_SOUTHEAST_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2100 Southeast Pkwy, Arlington , TX 76018',
            guaranteed: null,
          },
        },
        {
          id: 'OH_MOUNT_CARMEL_ST_ANNS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 South Cleveland Ave, Westerville, OH 43081',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ALWAYS_IN_MOTION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6930 Warner Ave, Huntington Beach, CA 92647',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_SOUTHWEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6100 Harris Parkway, Fort Worth, TX 76132',
            guaranteed: null,
          },
        },
        {
          id: 'PA_RPG_HAMILTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 Hamilton St, Philadelphia, PA 19130',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT4_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10384 W US Hwy 90, San Antonio , TX 78245',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_VA_VBA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 W National Ave, Milwaukee, WI 53295',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_ANAHEIM_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3440 E. La Palma Ave., Anaheim, CA 92806',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_DEUTSCHE_BANK_SANTA_ANA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1761 E ST Andrew Pl, Santa Ana, CA 92705',
            guaranteed: null,
          },
        },
        {
          id: 'FAEGRE_DRINKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '320 S Canal st, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '190_S_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '190 S LaSalle St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_ARRIVALS_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave., Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_MED_INGALLS_FLOSSMOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '19550 Governors Hwy, Flossmoor, IL 60422',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CEDARS_SINAI_8700_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8700 Beverly Boulevard, Los Angeles, CA 90048',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_SAT_CONCOURSE_A_TICKETING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9800 Airport Blvd, San Antonio , TX 78216',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SOFI_STADIUM_EMPLOYEE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1001 Stadium Dr, Inglewood, CA 90301',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S Morgan Avenue, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_CDW5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '380 Middlesex Ave, Carteret, NJ 07008',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_MACHUGA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Webster Road, Little Falls, NJ 07424',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_MT_WASHINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5801 Smith Ave, Baltimore, MD 21209',
            guaranteed: null,
          },
        },
        {
          id: 'MN_AMAZON_MSP1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 4th Ave E, Shakopee, MN 55379',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOODYS_7WTC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Greenwich St., New York, NY 10006',
            guaranteed: null,
          },
        },
        {
          id: 'MI_ANN_ARBOR_VA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2215 Fuller Rd, Ann Arbor, MI 48105',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BUNKER_HILL_CC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Rutherford Ave, Boston, MA 02129',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MITCHELL_CONCOURSE_D',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 S Howell Ave., Milwaukee, WI 53207',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHERN_TRUST_333_WABASH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 S Wabash Ave, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_UTD_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 W Campbell Rd, Richardson, TX 75080',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COOK_CHILDRENS_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 7th Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_BAPTIST_MED_CENTER_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 Dallas Street, San Antonio , TX 78215',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_LAW_NEWARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '123 Washington St, Newark, NJ 07102',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ASURION_4TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10720 W Sam Houston Pkwy, Houston , TX 77064',
            guaranteed: null,
          },
        },
        {
          id: 'IND_COMM_HOSP_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 North Ritter Avenue, Indianapolis, IN 46219',
            guaranteed: null,
          },
        },
        {
          id: 'MN_100_WASHINGTON_SQUARE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Washington Ave S, Minneapolis, MN 55401',
            guaranteed: null,
          },
        },
        {
          id: '230_W_MONROE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 W Monroe St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '680_N_LAKESHORE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '680 North Lake Shore Drive, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_BOOTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5807 S Woodlawn Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BERKLEE_MUSIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 Mass Ave, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_BARNABAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4422 3rd Ave., Bronx, NY 10456',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_D38_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_E16',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JEFFERSON_SURGERY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '540 Egg Harbor Road, Sewell, NJ 08083',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 Bergen St., Newark, NJ 07103',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_JET_ZERO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4150 Donald Douglas Dr, Long Beach, CA 90808',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JET_BLUE_CALL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '27-01 Queens Plaza N, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_TORREYANA_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10996 Torreyana Rd, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ADVENTIST_GLENDALE_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1509 Wilson Terrace, Glendale , CA 91206',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_SOUTHWEST_EMPLOYEES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60629',
            guaranteed: null,
          },
        },
        {
          id: 'SCHAUMBURG_TOWERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 American Ln, Schaumburg, IL 60196',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_A3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: '71dc0437-cbe5-46a3-a405-20b835656d89',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_SKY_LOUNGE_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5841 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AIRPORT_GATE_A8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Airport Blvd, Pittsburgh, PA 15231',
            guaranteed: null,
          },
        },
        {
          id: 'MA_REGIS_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '235 Wellesley St, Weston, MA 02493',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_HOSPITAL_OR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1468 Madison Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FIT_KAUFMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '406 West 31st Street, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_18',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_WEST_ROXBURY_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 VFW Parkway, West Roxbury, MA 02132',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CMH_CONCOURSE_C',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4600 International Gateway, Columbus, OH 43219',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_HOSTOS_CC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Grand Concourse, Bronx, NY 10451',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_SPECTRUM_12012_MOPAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12012 N Mopac Expy, Austin, TX 78759',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_MASONIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '836 W Wellington Ave, Chicago, IL 60657',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DMC_SINAI_GRACE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6071 Outer Dr W, Detroit, MI 48235',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22525 Clay Rd, Katy , TX 77449',
            guaranteed: null,
          },
        },
        {
          id: 'IND_SUBARU_CAFETERIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 IN-38 E, Lafayette, IN 47905',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYU_LANGONE_TISCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 First Avenue, New York, NY 10016',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_PREMIUM_OUTLETS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1650 Premium Outlet Blvd, Aurora, IL 60502',
            guaranteed: null,
          },
        },
        {
          id: '3040_SALT_CREEK_LANE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3040 Salt Creek Lane, Arlington Heights, IL 60005',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2160 S 1st Ave, Maywood, IL 60153',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_NORTHWEST_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9939 State Highway 151, San Antonio , TX 78251',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_CHILDRENS_ADVOCACY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1240 S Damen Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HEALTH_HUGULEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11801 South Fwy, Burleson, TX 76028',
            guaranteed: null,
          },
        },
        {
          id: '627_W_JACKSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '627 W Jackson Boulevard, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_302_W_THIRD_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '302 W Third Avenue, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: '166c1768-f046-4522-8fc0-4def23092467',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M16',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave., Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LAGUARDIA_CC_4TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29-10 Thomson Ave, Queens, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_LANKENAU_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 E Lancaster Ave., Wynnewood, PA 19096',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_HELEN_WHITE_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 N Park st, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_1435_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1425 Lake Cook Road, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10384 W US Hwy 90, San Antonio , TX 78245',
            guaranteed: null,
          },
        },
        {
          id: 'GOOD_SHEPHERD_HOSPITAL_OB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '450 IL-22, Barrington, IL 60010',
            guaranteed: null,
          },
        },
        {
          id: 'OAKTON_CC_LEE_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 Golf Rd, Des Plains , IL 60016',
            guaranteed: null,
          },
        },
        {
          id: '6e8ba5b5-c359-4931-8036-5a5ca25da420',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WAYMO_GOOGLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2037 Granville Ave, Los Angeles, CA 90025',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MDW2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Emerald Dr, Joliet, IL 60433',
            guaranteed: null,
          },
        },
        {
          id: 'MUSEUM_SCIENCE_INDUSTRY_GROUP_LUNCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Lake Shore Dr., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PSEG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '80 Park Plz, Newark, NJ 07102',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_PG_FULFILLMENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 Union Airpark Blvd, Vandalia, OH 45377',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_FRANCISCAN_CHILDRENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 Warren St, Brighton, MA 02135',
            guaranteed: null,
          },
        },
        {
          id: 'MN_3M_HQ_BUILDING_225',
          type: 'FRIDGE',
          operationConfigs: {
            address: '209 3rd St, Maplewood, MN 55144',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_ONETWOPRU',
          type: 'DELIVERY',
          operationConfigs: {
            address: '130 E Randolph St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'GOOGLE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 West Fulton St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_DENTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 S Interstate 35, Denton, TX 76210',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS_KILBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13570 N Central Expy, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: 'SKOKIE_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9600 Gross Point Road, Skokie, IL 60076',
            guaranteed: null,
          },
        },
        {
          id: 'MN_ANOKA_REGIONAL_TREATMENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3301 N 7th Ave, Anoka, MN 55303',
            guaranteed: null,
          },
        },
        {
          id: 'b1c5e485-74d3-45c3-b1d4-469abefba842',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_DALLAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7777 Forest Lane, Dallas, TX 75230',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_TESLA_SANTA_MONICA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1401-1425 Santa Monica Blvd, Santa Monica, CA 90404',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_AUP_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2131 E Hartford Rd, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SUNNOVA_7TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 Greenway Plaza, Houston , TX 77046',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_WASHU_SUMERS_REC_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 N Big Bend Blvd, St Louis, MO 63130',
            guaranteed: null,
          },
        },
        {
          id: 'DEVRY_ADDISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1221 N Swift Rd, Addison, IL 60101',
            guaranteed: null,
          },
        },
        {
          id: 'MD_CAPITAL_REGION_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '901 Harry S Truman Dr, Largo, MD 20774',
            guaranteed: null,
          },
        },
        {
          id: '770_N_HALSTED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '770 N Halsted St., Chicago, IL 60642',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_MEMORIALCARE_ORANGE_COAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9920 Talbert Ave, Fountain Valley, CA 92708',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_MASON_GROSS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33 Livingston Ave, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_VERDUGO_HILLS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1812 Verdugo Blvd, Glendale, CA 91208',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_STATEN_ISLAND_ARTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 Victory Blvd, Staten Island, NY 10314',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_SOUTH_LOOP_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1411 S Michigan Ave, Chicago , IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_SOCIAL_BEHAVIORAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Essington Ave, Philadelphia, PA 19153',
            guaranteed: null,
          },
        },
        {
          id: 'CVS_344_HUBBARD_CHI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '344 W Hubbard St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_5000_BIRCH_MAILROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 Birch St, Newport Beach, CA 92660',
            guaranteed: null,
          },
        },
        {
          id: 'CTA_95TH_STREET_RED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15 W 95th St, Chicago, IL 60628',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONMOUTH_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 2nd Ave, Long Branch, NJ 07740',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_WASHU_STEINBERG_HALL_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6244 Forsyth Blvd, St Louis, MO 63122',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SUFFOLK_UNI_MILLER_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Somerset St, Boston, MA 02108',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MSKCC_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1275 York Ave, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_100_E_WISCONSIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 E Wisconsin Ave, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HUNTSMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8600 Gosling Rd, Houston , TX 77381',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_JOHNS_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Utopia Parkway, Queens , NY 11439',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_SPRING_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1010 W Sam Houston Pkwy N, Houston , TX 77043',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SLB_16740_HARDY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16740 E Hardy Rd, Houston, TX 77060',
            guaranteed: null,
          },
        },
        {
          id: 'USPS_OHARE_CAFETERIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11600 Irving Park Rd, Chicago, IL 60666',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_PHYSICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PASSAIC_COUNTY_COMMUNITY_COLLEGE_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 College Blvd, Paterson, NJ 07505',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_TEXAS_STATE_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 University Dr, San Marcos, TX 78666',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_APPLE_SOHO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '103 Prince Street, New York, NY 10012',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BENTLEY_UNIVERSITY_COPLEY_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '175 Forest St, Waltham, MA 02452',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WEST_VA_CLINIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '351 East Temple Street, Los Angeles, CA 90012',
            guaranteed: null,
          },
        },
        {
          id: 'NORWEGIAN_AMERICAN_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1044 N Francisco Ave, Chicago, IL 60622',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HUGEINC_BROOKLYN_DOCK_72',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Dock 72 Way, Brooklyn, NY 11205',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_FRISCO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 Frisco Square Blvd, Frisco, TX 75034',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FRIED_FRANK_30S_PANTRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 New York Plaza, New York City, NY 10004',
            guaranteed: null,
          },
        },
        {
          id: '6afa59a2-1cee-476b-91fd-42c15dd4fc2e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_ARRIVALS_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave., Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SUNNOVA_FIREBRICK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7310 N Eldridge Pkwy, Houston , TX 77041',
            guaranteed: null,
          },
        },
        {
          id: 'c2a11e64-d8f5-4588-a2e2-d79d5d593d5e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DC_ONEILL_HOUSE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 C St SW, Washington, DC 20024',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_F8',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'MD_AACC_CAREER_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 College Parkway, Arnold, MD 21012',
            guaranteed: null,
          },
        },
        {
          id: 'BLACKHAWK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1460 N Halsted Street, Chicago, IL 60642',
            guaranteed: null,
          },
        },
        {
          id: 'KEMPER_LAKES_BUILDING_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton St, Chicago, IL 60612-2363',
            guaranteed: null,
          },
        },
        {
          id: '14e1234d-d305-49d5-a8bb-31c7c828e6aa',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_CAPITAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 North Illinois Street, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_ASCENSION_ST_FRANCIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3237 s 16th St, Milwaukee, WI 53215',
            guaranteed: null,
          },
        },
        {
          id: 'ELITE_STAFFING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1315 N North Branch St, Chicago, IL 60642',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOSTON_MEDICAL_CENTER_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '850 Harrison Ave, Boston, MA 02118',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ONT_TERMINAL_2_GATE_11',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 E Airport Dr, Ontario, CA 91761',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_DIGITAL_FUTURES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3080 Exploration Ave, Cincinnati, OH 45206',
            guaranteed: null,
          },
        },
        {
          id: 'PEGGY_NOTEBAERT_NATURE_MUSEUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2430 N Cannon Dr, Chicago, IL 60614',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_LAW_CAMDEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '217 N 5th St, Camden, NJ 08102',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HARRIS_COUNTY_ANIMAL_SHELTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '612 Canino Rd, Houston , TX 77076',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_FROEDTERT_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9200 W Wisconsin Ave., Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_UNIVERSITY_SIPA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '420 W 118th St #1410, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_ROLEX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2612 Harry Hines Blvd., Dallas, TX 75201',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DALLAS_VIZIENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '290 E. John Carpenter Fwy, Irving , TX 75062',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_SUNY_OLD_WESTBURY_CAMPUS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '223 Store Hill Road, Old Westbury, NY 11568',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ONT_TERMINAL_4_GATE_402',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 E Airport Dr, Ontario, CA 91761',
            guaranteed: null,
          },
        },
        {
          id: 'PRESIDENTS_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8600 W Bryn Mawr Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'IKEA_DISTRIBUTION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '650 Emerald Drive, Joliet, IL 60433',
            guaranteed: null,
          },
        },
        {
          id: '250_S_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_GRVC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '09-09 Hazen St, Bronx, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_HEALTH_ED_ENTRANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 S Main St, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'GEORGE_LEIGHTON_COURTHOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2650 S California Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SPACEX_HT17',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Rocket Rd, Hawthorne, CA 90250',
            guaranteed: null,
          },
        },
        {
          id: 'GOOD_SAMARITAN_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3815 Highland Ave, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LOVE_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8008 Herb Kelleher Way, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_1_DUP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1623 Dalton Ave, Cincinnati, OH 45234',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_CLUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3400 N Charles St, Baltimore, MD 21218',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_HERRON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '735 W York St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'PA_JOHNSON_SPRING_HOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 McKean Road, Spring House, PA 19477',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ST_PETERS_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '240 Easton Ave., New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CMH_FOOD_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4600 International Gateway, Columbus, OH 43219',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_UCI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '856 Health Sciences Rd, Irvine, CA 92617',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MDW4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 Emerald Dr, Joliet, IL 60433',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10550 Ella Blvd, Houston , TX 77038',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ARE_3115_MERRYFIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3115 Merryfield Row, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_ARCADE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 Orleans St, Baltimore, MD 21287',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_4605_DUKE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4605 Duke Dr, Mason, OH 45040',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LOVE_AIRPORT_GATE_6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8008 Herb Kelleher Way, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'NOKIA_HQ_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 Lucent Ln, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W. Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'UNITED_AIRLINES_OFFICE_WILLIS_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 233 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'VA_IAD_PILOT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Saarinen Circle, Dulles, VA 20166',
            guaranteed: null,
          },
        },
        {
          id: 'NEWARK_BETH_ISRAEL_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 Lyons Ave, 201 Lyons Ave, NJ 07112',
            guaranteed: null,
          },
        },
        {
          id: 'MN_CANTEEN_OFFICE_DO_NOT_STOCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 24th Ave SE, Minneapolis, IL 55414',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOAG_HOSPITAL_NEWPORT_BEACH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Hoag Dr, Newport Beach, CA 92663',
            guaranteed: null,
          },
        },
        {
          id: 'DC_CHILDRENS_NATIONAL_HOSPITAL_7TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 Michigan Ave NW, Washington, DC 20010',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LOYOLA_MARYMOUNT_ADMISSIONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Loyola Marymount University Dr, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_WALSH_GROUP_WEXNER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1644 Cannon Dr, Caolumbus, OH 43201',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_MERCY_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '345 St Paul Pl, Baltimore, MD 21202',
            guaranteed: null,
          },
        },
        {
          id: 'MORAINE_VALLEY_POLICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9000 College Pkwy, Palos Hills, IL 60465',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_FROEDTERT_EMERGENCY_DEPT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8850 William Coffey Dr, Wauwatosa, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'ace7c307-50aa-4835-b03a-a0edc054950e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_LAS_COLINAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6800 N MacArthur Blvd, Irving, TX 75039',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TRINITY_TOWERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2777 N Stemmons Fwy, Dallas, TX 75207',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BARNES_JEWISH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Barnes Jewish Hospital, St Louis, MO 63110',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_DELTA_INFLIGHT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOSTON_HARVARD_MEDSCHOOL_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 Longwood Avenue, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_ALLIANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10864 Texas Health Trail, Fort Worth, TX 76244',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PAUL_ROBESON_CAMPUS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 Dr. MLK Jr. Blvd., Newark, NJ 07102',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_K2',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_APPLE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5501 W. Parmer Lane, Austin, TX 78727',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_9TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3120 S Halsted St, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CRIMINAL_JUSTICE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '509 W 11th, Austin , TX 78701',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_DENTISTRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1121 W Michigan st, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ARE_CAMPUS_POINT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10300 Campus Point Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_GM_COLE_ENGINEERING_7TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29755 Louis Chevrolet Rd, Detroit, MI 48093',
            guaranteed: null,
          },
        },
        {
          id: 'GOOD_SHEPHERD_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '450 IL-22, Barrington, IL 60010',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LAX_AIRPORT_MSC_GATE_205',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 World Way, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ROBERTET_PISCATAWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Colonial Dr, Piscataway, NJ 08854',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FLIXBUS_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '535 Clara St, Los Angeles, CA 90012',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ROUDEBUSH_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1481 W 10th St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3202 N Maryland Ave, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_WAUKESHA_20935_SWENSON_DRIVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20935 Swenson Dr, Waukesha, WI 53186',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1475 Akron Way, Forney, TX 75126',
            guaranteed: null,
          },
        },
        {
          id: 'IND_AIRPORT_A14',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '7800 Col. H. Weir Cook Memorial Dr, Indianapolis, IN 46241',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ICW_15030_INNOVATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15030 Avenue of Science, San Diego , CA 92128',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_EWR9_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8003 Industrial Hwy, Carteret, NJ 07008',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MCLAREN_MACOMB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Harrington Street, Mt Clemens, MI 48043',
            guaranteed: null,
          },
        },
        {
          id: '123_N_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '123 N Upper Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_HESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1470 Madison Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'ab47bdfd-9fcc-46ca-af7e-26f85b48a1e3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'LURIE_CHILDRENS_OUTPATIENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2515 N Clark St, Chicago, IL 60614',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STIFEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '787 7th Ave, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_SEWELL_SOCIAL_SCIENCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1180 Observatory Drive, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_NATATORIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '901 W New York St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_OLD_BROOKLYN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4229 Pearl Rd, Cleveland , OH 44109',
            guaranteed: null,
          },
        },
        {
          id: '1_S_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BRONX_CC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2155 University Ave, Bronx, NY 10453',
            guaranteed: null,
          },
        },
        {
          id: 'USPS_CAROL_STREAM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 E Fullerton Ave, Carol Stream, IL 60199',
            guaranteed: null,
          },
        },
        {
          id: 'UNIVERSITY_OF_CHICAGO_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1100 E 57th St, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'UNITED_AIRLINES_ARLINGTON_HEIGHTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1501 W Shure Dr, Arlington Heights, IL 60004',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_PACIFIC_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '110 William St, New York, NY 10038',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_VA_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11301 Wilshire blvd, Los Angeles, CA 90073',
            guaranteed: null,
          },
        },
        {
          id: 'LAKE_COUNTY_GOV_WAUKEGAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18 N County St, Waukegan, IL 60085',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMERICAN_METRO_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 American Metro Blvd, Hamilton, NJ 08619',
            guaranteed: null,
          },
        },
        {
          id: 'COYOTE_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2545 W Diversey Ave, Chicago, IL 60647',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AIRFORCE_BASE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '482 W 124th St., El Segundo, CA 90245',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_4010_COMMERCIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4010 Commercial Ave, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'ASTELLAS_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2375 Waterview Dr, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_HEIGHTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1635 N Loop W, Houston, TX 77008',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JOHNSON_AND_JOHNSON_930',
          type: 'FRIDGE',
          operationConfigs: {
            address: '930 US-202, Raritan, NJ 08869',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DUBLIN_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7500 Hospital Dr, Dublin, OH 43016',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_ST_ANNS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 South Cleveland Ave, Westerville, OH 43081',
            guaranteed: null,
          },
        },
        {
          id: 'ONE_LINCOLN_CENTRE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18W140 Butterfield Road, Oakbrook Terrace, IL 60181',
            guaranteed: null,
          },
        },
        {
          id: '09a273a8-fc60-4031-9eb9-006dd52437ac',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NEW_SCHOOL_FRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '65 W 11th St, New York, NY 10003',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_VETERINARY_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2015 Linden Dr, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SLB_7030_ARDMORE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7030 Ardmore St, Houston , TX 77054',
            guaranteed: null,
          },
        },
        {
          id: 'EVANSTON_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2650 Ridge Ave, Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'MI_EMU_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Oakwood St, Ypsilanti, MI 48197',
            guaranteed: null,
          },
        },
        {
          id: 'AMITA_SAINT_MARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2233 W Division St, Chicago, IL 60622',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_BETHESDA_DINING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10500 Montgomery Rd, Cincinnati, OH 45242',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB3_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_VA_607',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 Overlook Terrace, Madison, WI 53705',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MAIMONIDES_MAIN_DOUBLE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4802 10th Ave, Brooklyn , NY 11219',
            guaranteed: null,
          },
        },
        {
          id: '33d67b87-1370-4752-b098-fa1935497d68',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYIT_MANHATTAN_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '26 West 61st Street, NY, NY 10023',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW6_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 W Bethel Rd, Grapevine, TX 76051',
            guaranteed: null,
          },
        },
        {
          id: 'VA_IAD_FLIGHT_ATTENDANTS_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Saarinen Circle, Dulles, VA 20166',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_CORPORATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '466 Lexington Ave, New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: '2001_BUTTERFIELD_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2001 Butterfield Rd, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_GEICO_RICHARDSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2280 N Greenville Ave, Richardson, TX 75082',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_BAPTIST_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 Dallas St., San Antonio , TX 78215',
            guaranteed: null,
          },
        },
        {
          id: '2_N_LASALLE_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2 N. Lasalle, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_PELOTON_PLANO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6500 Chase Oaks Blvd, Plano, TX 75023',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_REHAB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '19126 Stonehue, San Antonio , TX 78258',
            guaranteed: null,
          },
        },
        {
          id: '320_W_OHIO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '320 W Ohio St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_VA_JOHN_COCHRAN_587',
          type: 'FRIDGE',
          operationConfigs: {
            address: '915 N Grand Blvd, St Louis, MO 63106',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '49 Abbot Rd, East Lansing, MI 48824',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCU_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2913 w Lowden St, Fort Worth, TX 76129',
            guaranteed: null,
          },
        },
        {
          id: 'c475cb54-e2c1-449e-bb05-f2c2fdfbf7d0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LOMA_LINDA_VA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11201 Benton St, Loma Linda, CA 92357',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_APPLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9455 Town Centre Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_SHADYSIDE_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5230 Centre Ave, Pittsburgh, PA 15232',
            guaranteed: null,
          },
        },
        {
          id: '200_W_ADAMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 W Adams St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '640_N_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '640 N LaSalle Dr, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SHARP_TECH_WAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8520 Tech Way #200, San Diego, CA 92123',
            guaranteed: null,
          },
        },
        {
          id: 'MN_METHODIST_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6500 Excelsior Blvd, St Louis Park, MN 55426',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_METROPOLITAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1310 McCullough Ave, San Antonio , TX 78212',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MCW_HUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8701 W Plank Rd, Wauwatosa, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDX6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16399 Gateway Path, Frisco, TX 75035',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_GRANITE_2925_BRIARPARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2925 Briarpark Dr, Houston , TX 77042',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_MACOMB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15959 Hall Rd, Macomb, MI 48044',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16-06 Hazen St, Queens , NY 11370',
            guaranteed: null,
          },
        },
        {
          id: 'f9b4c87f-d034-4e93-b0ff-f6e286309b5e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DOMINICAN_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7900 Division St, River Forest, IL 60305',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_10000_INNOVATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10000 W Innovation Dr, Wauwatosa, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_PAVILLION_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6621 Fannin Street, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: '600_W_MADISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Madison St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEST_CARROT_CLOSET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1212 Dolton Dr, Dallas, TX 75207',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FILM_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6363 Sunset Blvd, Los Angeles, CA 90028',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JSX_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7201 Lemmon Avenue, Dallas, TX 75209',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_UNIVERSITY_STUDEBAKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '615 West 131st St, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'AMTRAK_UNION_STATION_GREAT_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 S Canal St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'ROOSEVELT_UNI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 S Wabash Ave, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_FRICK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '190 S Prospect Ave, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HOFSTRA_UNIVERSITY_HAGEDORN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '119 Hofstra University, Hempstead, NY 11549',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHEVRON_HQ_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 Louisiana, Houston, TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_CCD_8TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5841 S Maryland Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'DEPAUL_CENTER_LOOP_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 E Jackson Blvd, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_VALERO_ENERGY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Valero Way, San Antonio , TX 78249',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_GATE_C17_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ZOCDOC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '568 Broadway, New York, NY 10012',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BCH_LOBBY_SINGLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Longwood Avenue, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_CYFAIR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9191 Barker Cypress Rd, Cypress, TX 77433',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UT_MD_ANDERSON_DUNCAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1155 Pressler St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SWIFT_TRANSPO_JURUPA_VALLEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3575 Wineville Rd, Jurupa Valley, CA 91752',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T5_BAGGAGE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_SOUTH_OAKS_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 Sunrise Hwy, Amityville, NY 11701',
            guaranteed: null,
          },
        },
        {
          id: '0ef9434c-6e9a-4939-8293-d25d0707b81c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENS_MUSEUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'New York City Building, Corona, NY 11368',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '471 W Palmer Ave, Detroi, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'LAKEFOREST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 N Westmoreland Road, Lake Forest, IL 60045',
            guaranteed: null,
          },
        },
        {
          id: 'RADIO_FLYER_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6515 West Grand Avenue, Chicago, IL 60707',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_CONCOURSE_C',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_TRINITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2320 E 93rd Street, Chicago, IL 60617',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BIDMC_EAST_CAMPUS_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 Brookline Ave, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_EMERGENCY_DEPARTMENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5656 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_KEAN_UNIVERSITY_EAST_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Morris Ave, Union , NJ 07083',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ARE_ENCLAVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9808 Scranton Rd, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 W Renner Rd, Richardson, TX 75080',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_PAOLI_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '255 Lancaster Ave, Paoli, PA 19301',
            guaranteed: null,
          },
        },
        {
          id: 'KIRKLAND_ELLIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 N LaSalle Drive, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_QUEENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '82-68 164th St, Jamaica, NY 11432',
            guaranteed: null,
          },
        },
        {
          id: 'RIVER_NORTH_POINTE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 N Orleans St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'bc607a4a-150e-407d-9c41-b99a41d1b2a3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DC_US_DEPT_OF_COMMERCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1401 Constitution Ave. NW, Washington , DC 20230',
            guaranteed: null,
          },
        },
        {
          id: 'MN_BOULDERING_PROJECT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1433 West River Rd N, Minneapolis, MN 55411',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAL_POLY_CC_MARKETPLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 W Temple Ave, Pomona, CA 91768',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_B25_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CUYAHOGA_CC_METRO_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Carnegie Ave, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_MCKINNEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5252 W University Dr, McKinney, TX 75071',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CROWN_CASTLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8020 Katy Fwy, Houston, TX 77024',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GENERAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 N State Street, Los Angeles, CA 90033',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_PEARLAND_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16100 South Fwy, Pearland, TX 77584',
            guaranteed: null,
          },
        },
        {
          id: 'IND_130_E_WASHINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 E Washington St., Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'CNA_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '151 N Franklin St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '05a27662-2fc5-470a-b8ce-d27b1de93870',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AVI_TRIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2590 Elm Rd NE, Warren, OH 44483',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_STATE_UNIVERSITY_COOKADM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9501 S King Dr, Chicago, IL 60628',
            guaranteed: null,
          },
        },
        {
          id: '108_N_STATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '108 N State Street, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_FORT_WORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 8th Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_RMSC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '19-19 Hazen Street, Elmhurst, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCU_BASS_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 W Bowie St, Fort Worth, TX 76109',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_MOREHOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5505 Morehouse Rd, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'IND_AIRPORT_B16',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '7800 Col. H. Weir Cook Memorial Drive, Indianapolis, IN 46241',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BMCC_FITERMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '245 Greenwich St, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: '911_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1411 W Madison St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'FRESENIUS_KABI_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2020 N Ruby St, Melrose Park, IL 60160',
            guaranteed: null,
          },
        },
        {
          id: '7eda6c00-7c27-4329-bf91-0bd51f5169b4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LOVE_AIRPORT_GATE_12',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8008 Herb Kelleher Way, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'ILLINOIS_COLLEGE_OF_OPTOMETRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3241 S Michigan Ave, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_LGA5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2205 NJ-27, Edison, NJ 08817',
            guaranteed: null,
          },
        },
        {
          id: 'MORTON_COLLEGE_BUILDING_C_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 South Central Avenue, Cicero, IL 60804',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JCREW_MADEWELL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 Liberty St, New York, NY 10281',
            guaranteed: null,
          },
        },
        {
          id: 'CROWN_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1801 Main St, Evanston, IL 60202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DFW7_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Westport Pkwy, Fort Worth, TX 76177',
            guaranteed: null,
          },
        },
        {
          id: 'IND_REID_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1100 Reid Pkwy, Richmond, IN 47374',
            guaranteed: null,
          },
        },
        {
          id: 'fc72acce-e2f2-4ec3-9982-79a957324a82',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_8080_PARK_LANE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8080 Park Lane, Dallas, TX 75231',
            guaranteed: null,
          },
        },
        {
          id: '48bc9dd7-8225-4acf-a293-b1aedd6abe3e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HARBORSIDE_5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '185 Hudson St, Jersey City, NJ 07302',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MILWAUKEE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 E Kilbourn Avenue, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_PECHANGA_ARENA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3500 Sports Arena Blvd, San Diego, CA 92110',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_1_A4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: '55ef65b2-2fd7-4304-8878-6fd72dbcfd10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'KEMPER_LAKES_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Corporate Dr, Lake Zurich, IL 60047',
            guaranteed: null,
          },
        },
        {
          id: 'SOLDIER_FIELD_SECTION_102',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1410 Museum Campus Dr, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: '1801_S_MEYERS_OAKBROOK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1801 S Meyers Rd, Oak Brook Terrace, IL 60181',
            guaranteed: null,
          },
        },
        {
          id: 'UNIV_OF_MIAMI_TEMP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1320 S Dixie Hwy, Coral Gables, FL 33146',
            guaranteed: null,
          },
        },
        {
          id: 'MN_CENTURY_COLLEGE_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3300 Century Ave N, White Bear Lake, MN 55110',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PRINCETON_LEWIS_THOMAS_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Washington Rd, Princeton, NJ 08544',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TWU_MCL_COFFEE_STAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1242 N Bell Ave, Denton, TX 76209',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RABOBANK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '245 Park Ave, New York, NY 10167',
            guaranteed: null,
          },
        },
        {
          id: 'ea1d1875-9de1-4a22-9158-e9a05f0a47c8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_DOWNTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '433 W Van Buren St., Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TEXAS_CHILDRENS_HOSPITAL_WOMENS_PAVILLION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6651 Main St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: '475_HALF_DAY_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '475 Half Day Road, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_FORT_WORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 8th Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GWC_LANGUAGE_ARTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15744 Goldenwest St, Huntington Beach, CA 92647',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DMC_HURON_VALLEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 William Carls Dr, Commerce Charter Twp, MI 48382',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_MEMORIALCARE_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17360 Brookhurst Street, Fountain Valley, CA 92708',
            guaranteed: null,
          },
        },
        {
          id: 'MN_SHOENECKER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2115 Summit Ave, St Paul, MN 55105',
            guaranteed: null,
          },
        },
        {
          id: '4f2fd799-ee12-40c3-9985-1de5f3d9f15e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_EXPEDIA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11920 Alterra Pkwy, Austin , TX 78758',
            guaranteed: null,
          },
        },
        {
          id: 'eba7dccb-cfdf-41c9-b4de-63443c9ee003',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_ALIEF_HAYES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2811 Hayes Rd, Houston, TX 77082',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_SOCIAL_WORK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1350 University Ave, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: '043814ec-e267-4f9e-912f-21a061c65571',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ST_BERNARD_HIGH_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9100 Falmouth Ave, Playa Del Rey, CA 90293',
            guaranteed: null,
          },
        },
        {
          id: 'BOSTON_CONSULTING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 N Lasalle St., Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'DC_AMERICAN_UNIVERSITY_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4300 Nebraska Avenue NW, Washington, DC 20016',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_PUBLIC_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2007 E Monument St, Baltimore, MD 21205',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_MEDICAL_EMERGENCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1653 W Congress Pkwy, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_HENRY_FORD_MACOMB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15855 19 Mile Rd, Clinton Township, MI 48038',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_ST_LUKES_HOSPITAL_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 W Oklahoma Ave, Milwaukee, WI 53215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_ALBANS_VA_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '179-00 Linden Blvd, Queens, NY 11424',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_RNDC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11-11 Hazen Street, Elmhurst, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '420 E Superior St, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'XS_TENNIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'KENT_COLLEGE_OF_LAW_CAFETERIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '565 W Adams St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_GESSNER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '929 Gessner Rd, Houston, TX 77024',
            guaranteed: null,
          },
        },
        {
          id: 'ANIXTER_HW_NEW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 Patriot Blvd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: 'MN_HONEYWELL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1433 Stinson NE Blvd, Minneapolis, MN 55413',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_ANN_ARBOR_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5301 McAuley Dr, Ypsilanti, MI 48197',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_IKEA_BUILDING_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4830 Borusan Rd, Baytown, TX 77523',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COMEDYCENTRAL_NICKELODEON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '345 Hudson St., New York, NY 10014',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_1330_FOUR_OAK_PLACE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1330 Post Oak Blvd, Houston , TX 77027',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LEGACY_PLACE_MAIL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 Tennyson Parkway, Plano, TX 75204',
            guaranteed: null,
          },
        },
        {
          id: 'IIT_HERMAN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3241 S Federal St, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DEPT_OF_ENERGY_GOV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Independence Ave SW, Washington, DC 20024',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_PROCTOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3110 Vine St, Cincinnati, OH 45219',
            guaranteed: null,
          },
        },
        {
          id: '075b3eef-cda8-45a6-aedf-5c2f14887ed1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_MUNSTER_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 Superior Ave, Munster , IN 46321',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MERITER_HOSPITAL_ATRIUM_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '202 S Park St, Madison, WI 53715',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_PROCTER_AND_GAMBLE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Procter and Gamble Plaza, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'VE_TEST_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '622 West Cornelia Avenue, Chicago, IL 60657',
            guaranteed: null,
          },
        },
        {
          id: 'VE_TEST_6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3414 N seeley Ave, Chicago, IL 60618',
            guaranteed: null,
          },
        },
        {
          id: 'MN_UNION_DEPOT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '214 4th St. E, Saint Paul, MN 55101',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_CLARK',
          type: 'DELIVERY',
          operationConfigs: {
            address: '144 S Clark St Ste 100, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: '8736b914-d60f-41e9-96af-2a6a3c3b0444',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_PENN_PRESBYTERIAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '51 N 39th Street, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_LIFE_BRIDGE_HEALTH_SINAI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2401 W. Belvedere Ave, Baltimore, MD 21215',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CUYAHOGA_CC_ADMINISTRATIVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Carnegie Ave, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_FAIRVIEW_PHARMACY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '711 Kasota Ave SE, Minneapolis, MN 55414',
            guaranteed: null,
          },
        },
        {
          id: 'df4ba17e-c2a4-43a1-8217-bf0d8cff3388',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_TEXAS_STATE_LBJ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '301 Student Center Dr, San Marcos , TX 78666',
            guaranteed: null,
          },
        },
        {
          id: 'MORAINE_VALLEY_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9000 College Pkwy, Palos Hills, IL 60465',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_TANGEMAN_CENTER_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2600 Clifton Ave, Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ST_VINCENT_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2001 West 86th Street, Indianapolis, IN 46260',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'b7c8fc74-6de8-4820-830b-8236883efc10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_JACOBI_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 Pelham Pkwy S, Bronx, NY 10461',
            guaranteed: null,
          },
        },
        {
          id: 'CITADEL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '131 S Dearborn St., Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_SAMARITAN_8TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 Dixmyth Ave,  Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HARRIS_COUNTY_ADMIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1001 Preston St, Houston , TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'AON_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 E Randolph St., Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BELLEVUE_9TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '462 1st Avenue, New York, NY 10016',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AIRPORT_GATE_23_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin, TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SOHO_LOFTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '273 16th St, Jersey City, NJ 07310',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_ORD2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '23714 Amoco Rd, Channahon, IL 60410',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_OU_HUMAN_HEALTH_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '433 Meadow Brook Rd, Rochester, MI 48309',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_EINSTEIN_MED_CENTER_MONTGOMERY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '559 W Germantown Pike Rd, East Norriton, PA 19403',
            guaranteed: null,
          },
        },
        {
          id: '2ddbcd64-3b60-43b4-9906-ddb91d3a1b07',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_SWIFT_TRANSPO_LANCASTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3250 N Longhorn Dr, Lancaster, TX 75134',
            guaranteed: null,
          },
        },
        {
          id: '6e11160a-de75-4717-b246-f15b34ac073d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB1_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_AMAZON_BURLINGTON_ACY2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 East Pearl St, Burlington, NJ 08016',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_LEWIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7100 Graphics Way, Lewis Center, OH 43035',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SCRIPPS_RANCHO_BERNARDO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15004 Innovation Dr, San Diego, CA 92128',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_WESTERN_RIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6949 Good Samaritan Drive, Cincinnati, OH 45247',
            guaranteed: null,
          },
        },
        {
          id: '226_W_JACKSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '226 W Jackson Boulevard, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '28e06dbd-1575-4061-87fd-d93a8e40a6f5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SCRIPPS_ENCINITAS_CLINIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '326 Santa Fe Dr, Encinitas, CA 92024',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WESTERN_UNIVERSITY_HPC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '309 E 2nd St, Pomona, CA 91766',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_SORRENTO_VALLEY_BLVD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3985 Sorrento Valley blvd, Suite C, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_MORTENSON_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 George Street, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'b3fd42b8-8c00-4d5e-a7e6-a0a74bfe927a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_REHABILITATION_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5115 N Biltmore Ln, Madison, WI 53718',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_USPS_SAN_ANTONIO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10410 Perrin Beitel Road, San Antonio , IL 78284',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_INTERFAITH_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1545 Atlantic Ave, Brooklyn, NY 11213',
            guaranteed: null,
          },
        },
        {
          id: 'GLENBROOK_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2100 Pfingsten Rd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: '4_OVERLOOK_PT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Overlook Point, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2775 Mosside Blvd, Monroeville, PA 15146',
            guaranteed: null,
          },
        },
        {
          id: 'LEWIS_UNI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 Airport Road, Romeoville, IL 60446',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_UNITED_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_MIRATI_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3545 Cray Ct, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WALLACE_10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6621 Fannin St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CVG_PRE_SECURITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3087 Terminal Drive, Hebron, KY 41048',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AIRPORT_OFFICES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin , TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOAG_HOSPITAL_IRVINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16200 Sand Canyon , Irvine, CA 92618',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_MAIN_CAMPUS_MATERNITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 Southern Boulevard, Kettering, OH 45429',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_IAH1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9155 Southlink Dr, Dallas, TX 75241',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_GLOBAL_POWER_COMPONENTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2300 S 51st St, Milwaukee, WI 53219',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_MILSTEIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '177 Fort Washington Ave, New York, NY 10032',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_PLANO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3901 W 15th Street, Plano, TX 75075',
            guaranteed: null,
          },
        },
        {
          id: '2650_WARRENVILLE_CORRIDOR_3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2650 Warrenville Rd, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SLB_155_INDUSTRIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '155 Industrial Blvd., Sugar Land, TX 77478',
            guaranteed: null,
          },
        },
        {
          id: 'MN_U_ST_THOMAS_CENTRAL_HUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 La Salle Ave, Minneapolis, MN 55403',
            guaranteed: null,
          },
        },
        {
          id: 'CTA_DAMEN_PINK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2010 S Damen Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_1_A17',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_EXXON_HQ_ENERGY_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22777 Springwoods Village Parkway, Spring, TX 77389',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_ALLIANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3101 N Tarrant Pkwy, Fort Worth, TX 76177',
            guaranteed: null,
          },
        },
        {
          id: 'IND_MICHIGAN_CITY_FRANCISCAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3500 Franciscan Way, Michigan City, IN 46360',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_SUNY_OLD_WESTBURY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '223 Store Hill Rd, Old Westbury, NY 11568',
            guaranteed: null,
          },
        },
        {
          id: 'UNIVERSITY_OF_CHICAGO_KNAPP_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 E 57th St, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2311 E Hartford Ave, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_UPENN_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3610 Hamilton Walk, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ELI_LILLY_TECH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1555 S Harding St, Indianapolis, IN 46221',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_SAKS_FIFTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '611 5th Ave., New York, NY 10022',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_UBS_TRADING_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1285 Avenue of the Americas, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: '73cba37f-0be1-4b93-8f48-835b5ad65418',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_RICE_REC_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6100 S MAIN ST, Houston , TX 77005',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_IMSMP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '521 W 57th Street, New York, NY 10019',
            guaranteed: true,
          },
        },
        {
          id: 'PA_PHI_THOMAS_JEFFERSON_UNI_HOSPITAL_CENTER_CITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 S. 11th Street, Philadelphia, PA 19107',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_EINSTEIN_MED_YORK_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5501 Old York Road, Philadelphia, PA 19141',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_TEACHERS_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 W 120th St #91, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'GOULD_AND_RATNER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 N LaSalle, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_MED_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '125 Paterson St, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'CLEVELAND_AVE2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 N Canal Street, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_NOCC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1050 Forrer Blvd, Dayton, OH 45420',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CSU_DOMINGUEZ_LOKER_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 E Victoria St, Cason , CA 90747',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_FENWAY_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Jersey St, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LAHEY_MEDICAL_OFFICES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29 Burlington Mall Road, Burlington, MA 01803',
            guaranteed: null,
          },
        },
        {
          id: 'BAXTER_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Baxter Way, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: '700_COMMERCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Commerce Dr, Oak Brook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: '6a5a079e-d39a-4f75-bb9b-e2d47864316f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_DREXEL_HEALTH_SCIENCES_7TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Filbert St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_DREW_UNIVERSITY_FRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '36 Madison Ave, Madison, NJ 07940',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8755 W Higgins Rd, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_UCBA_MUNTZ_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9555 Plainfield Rd, Cincinnati, OH 45236',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SEPULVEDA_VA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16111 Plummer St, North Hills , CA 91343',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_BP_HELIOS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 Helios Way, Houston, TX 77079',
            guaranteed: null,
          },
        },
        {
          id: 'FEDEX_EXPRESS_ORD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '632 W Cargo Road, Chicago, IL 60666',
            guaranteed: null,
          },
        },
        {
          id: 'ROOKERY_BLDG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '209 S LaSalle St, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_UNIVERSITY_ALUMNI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1020 Locust St, Phildelphia, PA 19107',
            guaranteed: null,
          },
        },
        {
          id: 'GOTTLIEB_MEMORIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 W North Ave, Melrose Park, IL 60160',
            guaranteed: null,
          },
        },
        {
          id: '01157eeb-1159-4bfb-a244-9079ea7220be',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M25',
          type: 'FRIDGE',
          operationConfigs: {
            address: "1000 W O'HAre Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'aabf6ee4-627a-445e-bf62-03dab4853345',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS_DMOS_5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13353 T I Blvd, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_DRAMATIC_ARTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '817 West 34th Street, Los Angeles, CA 90089',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_WOODLAND_MOTHERCARE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5601 De Soto Ave, Woodland Hills, CA 91367',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD1_BACK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3388 S Cactus Ave, Jurupa Valley, CA 92509',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_NORTHROP_GRUMMAN_MANHATTAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3001 N Aviation Blvd, Manhattan Beach, CA 90266',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_NAZARETH_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 Holme Ave, Philadelphia, PA 19152',
            guaranteed: null,
          },
        },
        {
          id: 'JESSE_BROWN_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '820 S Damen Ave., Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'LAKE_POINT_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '505 N Lake Shore Dr, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_NORTH_HILLS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4401 Booth Calloway Road, Fort Worth, TX 76180',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FOOTHILL_MED_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '14662 Newport Ave, Tustin, CA 92780',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_NEWTON_WELLESLEY_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2014 Washington St, Newton, MA 02462',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_CHRIST_CHILDRENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4440 W 95th St., Oak Lawn, IL 60453',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_DINALLO',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'Yogi Berra Drive, Little Falls, NJ 07424',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_RICE_BIORESEARCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6100 S MAIN ST, Houston , TX 77005',
            guaranteed: null,
          },
        },
        {
          id: 'MD_MARYLAND_SCIENCE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 Light St, Baltimore, MD 21230',
            guaranteed: null,
          },
        },
        {
          id: 'VA_MARS_INC_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1676 International Dr, Mclean, VA 22102',
            guaranteed: null,
          },
        },
        {
          id: '1000_W_FULTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 W Fulton Market, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BMCC_MURRAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '70 Murray St, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: 'LOVELL_VA_BUILDING_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3001 N Green Bay Rd, North Chicago, IL 60064',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_MALL',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: '5d5e1ce5-6cc2-474f-ab4b-1598cb16b4f3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ZURICH_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1299 Zurich Way, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MILLIPORE_SIGMA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6000 N Teutonia Ave, Milwaukee, WI 53209',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CEDARS_SINAI_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8700 Beverly Boulevard, Los Angeles, CA 90048',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_SONY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 Madison Ave, New York, NY 10010',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_WEILCORNELL_3RDFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 E 68th Street, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'VA_VIRGINIA_HOSPITAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 N George Mason Dr, Arlington, VA 22205',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_DENTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3000 1-35, Denton, TX 76201',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_UT_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 E 21st St, Austin, TX 78712',
            guaranteed: null,
          },
        },
        {
          id: 'ICNC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_STATEN_ISLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 Victory Blvd, Staten Island , NY 10314',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_BMS_SUMMIT_S4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '556 Morris Ave, Summit, NJ 07901',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_LGA9_EDISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2170 NJ-27, Edison, NJ 08817',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_BALDWIN',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '3782 W Martin Luther King Jr Blvd, Los Angeles , CA 90008',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BNY_MELLON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '240 Greenwich St, New York, NY 10286',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_BLOCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2727 2nd Ave, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_VILLANOVA_BARTLEY_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Lancaster Ave, Villanova, PA 19085',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_SNYDER_PHILLIPS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '362 Bogue St, East Lansing, MI 48825',
            guaranteed: null,
          },
        },
        {
          id: '05d6edff-1dfe-432b-8d91-ca1acdd7054c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_LOUIS_STOKES_VA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 E. Blvd, Cleveland, OH 44106',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BMC_CROSSTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 Massachusetts Ave, Boston, MA 02119',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_PANORAMA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13651 Willard St, Panorama City, CA 91402',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_STUDENT_UNION_2NDFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 E Kenwood Blvd, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'U_CHICAGO_BIOLOGICAL_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '924 E 57th St, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DENTSU_AOA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '32 Avenue of Americas, New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_3K_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NJ_OVERLOOK_MEDICAL_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Galloping Hill Rd, Union, NJ 07083',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_2_E20',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'LUTHERAN_GENERAL_CANCER_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1700 Luther Lane, Park Ridge, IL 60068',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_ASCENT_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2068 E St, Belleville, MI 48111',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JULLIARD_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Lincoln Center Plaza, New York, NY 10023',
            guaranteed: null,
          },
        },
        {
          id: 'CENTRAL_DUPAGE_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 N Winfield Road, Winfield, IL 60190',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JUILLIARD_SCHOOL_FIFTH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Lincoln Center Plaza, New York, NY 10023',
            guaranteed: null,
          },
        },
        {
          id: 'WLS_TELEVISION_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '190 N State St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MSU_IM_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '393 Chestnut Rd, East Lansing, MI 48824',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1305 E Pacific Coast Hwy, Long Beach, CA 90806',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TECHNIP_ENERGIES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11720 Katy Fwy #100, Houston , TX 77079',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '645 West 130th Street, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_IAH_UNITED_PILOTS_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 N Terminal Rd, Houston , TX 77032',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_OAKLAND_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44405 Woodward Ave, Pontiac, MI 48341',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_MIAMISBURG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4000 Miamisburg Centerville Rd, Miamisburg, OH 45342',
            guaranteed: null,
          },
        },
        {
          id: 'MI_GR_WYOMING_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5838 Metro Way, Wyoming, MI 49519',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNI_OF_HOUSTON_STUDENT_CENTER_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4800 Calhoun Rd, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_SOUTH_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1545 E Southlake Blvd, Southlake, TX 76092',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_PRESBYTERIAN_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Lothrop St., Pittsburgh, PA 15213',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AB_INBEV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Park Avenue, New York, NY 10177',
            guaranteed: null,
          },
        },
        {
          id: '4e7a1c11-ab04-4cdd-bc59-4b93e867678c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'DC_UNITED_COMMUNICATIONS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2720 Martin Luther King Jr Ave S, Washington, DC 20032',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_EAST_ANN_ARBOR_HEALTH_GERIATRICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4260 Plymouth Rd, Ann Arbor, MI 48109',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_2000_MARKET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 Market St., Philadelphia, PA 19103',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_WILLIAM_ECKHARDT_RESEARCH_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5640 S Ellis Avenue, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SOUTHERN_UNI_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 Cleburne St, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SOUTHWESTERN_CAESAR_CHAVEZ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Otay Lakes Road, Chula Vista, CA 91910',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_NORTHEAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12412 Judson Road, San Antonio , TX 78233',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_HOLLYWOOD_CASINO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '777 Casino Center Dr, Maryland Heights, MO 63043',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNI_OF_HOUSTON_COUGAR_PL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4016 Cullen Blvd, Houston , TX 77204',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_SSM_HEALTH_STL_UNIVERSITY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 S Grand Blvd, St Louis, MO 63104',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_TRIANGLE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1700 W Van Buren St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '8bf2abb6-3088-403d-b3b5-d5f485739be4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KOREATOWN_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '928 S Western Ave, Los Angeles, CA 90006',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_MEDICINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '259 Mack Ave, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_MATHER_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 N Country Rd, Port Jefferson, NY 11777',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ROBERTET_BUDD_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 International Dr, Budd Lake, NJ 07828',
            guaranteed: null,
          },
        },
        {
          id: 'de08c7a7-6e2d-4937-8845-76bdd221d0f4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'a101bcd4-a5f9-4954-8c50-387e3d654870',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'EQUINOX_HIGHLAND_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '799 Central Ave, Highland Park, IL 60035',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MCLAREN_OAKLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 N Perry Street, Pontiac , MI 48342',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COOK_CHILDRENS_SKYBRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 7th Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'IND_LOWES_SUPPORTCENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6620 Network Way, Indianapolis, IN 46278',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_WHITE_PLAINS_HOSPITAL_CAFETERIA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '41 E Post Rd, White Plains, NY 10601',
            guaranteed: null,
          },
        },
        {
          id: '54556be1-c6b4-4dea-adab-2410c9c0ef4d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'RESURRECTION_PREP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7500 W Talcott Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LAX_AIRPORT_MSC_GREAT_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '380 World Way, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_RAB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1724 W Harrison St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CHERVON_NORTH_AMERICA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1203 E Warrenville Rd, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'c31d2df6-3490-4fb0-88ab-93101b5cbc24',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_CARTHAGE_COLLEGE_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2001 Alford Park Drive, Kenosha, WI 53140',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_MONTGOMERY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3200 College Park Dr, Conroe, TX 77384',
            guaranteed: null,
          },
        },
        {
          id: 'OPPLOANS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 E Randolph St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AMAZON_MKE2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9700 S 13th St, Oak Creek, WI 53154',
            guaranteed: null,
          },
        },
        {
          id: '2500_W_BRADLEY_PL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 W Bradley Pl, Chicago, IL 60618',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ST_AUGUSTINE_HEALTH_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Windy Point Dr, San Marcos, CA 92069',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UNI_OF_HOUSTON_SUGAR_LAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '14000 University Blvd, Sugar Land, TX 77479',
            guaranteed: null,
          },
        },
        {
          id: '300_S_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 S. Riverside, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_SOUTHDALE_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6401 France Ave S, Edina, MN 55435',
            guaranteed: null,
          },
        },
        {
          id: 'PROVIDENT_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 E 51st St., Chicago, IL 60615',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_10TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3315 W Ogden Ave, Chicago, IL 60623',
            guaranteed: null,
          },
        },
        {
          id: '222_S_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 S Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '118_N_CLARK_PEDWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '118 N Clark, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_NORRIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1999 Campus Dr., Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AB_INBEV2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '114 W 41st Street, New York, NY 10036',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_ST_LUKES_BAPTIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7930 Floyd Curl Drive, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_WALTER_RICE_COURTHOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 W Second St, Dayton, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_OCEANSIDE_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1300 Rancho Del Oro Dr, Oceanside, CA 92065',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_HOSPITAL_MAIN_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '155 E Brush Hill Rd, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_LEWISVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 W Main Street, Lewisville, TX 75057',
            guaranteed: null,
          },
        },
        {
          id: 'WTTW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 N St. Louis Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_UBS_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Harbor Blvd, Weehawken, NJ 07086',
            guaranteed: true,
          },
        },
        {
          id: 'OHARE_3H_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MATTESON_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7001 Vollmer Rd, Matteson, IL 60443',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_LBJ_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 E 17th St, Austin , TX 78701',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_SINAI_HOSPITAL_ED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2401 W Belvedere Ave, Baltimore, MD 21215',
            guaranteed: null,
          },
        },
        {
          id: '4e3f1a8f-2e4d-4874-b909-693fec4b37c2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CTA_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '567 W Lake St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'MACYS_STORY_STATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 N State St, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_420_LEXINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '420 Lexington Ave, New York, NY 10170',
            guaranteed: null,
          },
        },
        {
          id: 'PUBLICIS_TENANT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '35 W. Wacker - 21st Floor, Chicago , IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_THE_DEVAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 New York Ave, Jersey city, NJ 07307',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ADVENTIST_GLENDALE_PAVILION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1509 Wilson Terrace, Glendale, CA 91206',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_OVATION_HOLLYWOOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6801 Hollywood Blvd, Hollywood, CA 90028',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_A_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'IND_PURDUE_KRACH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1198 3rd Street, West Lafayette, IN 47907',
            guaranteed: null,
          },
        },
        {
          id: 'UNIVERSITY_OF_CHICAGO_ECKHART',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5734 S University Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_COLUMBIA_STMARYS_WOMENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2323 N Lake Dr, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: '0da4bdd1-9b7d-47b4-a81c-95733f513a5d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MITCHELL_CONCOURSE_C',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 S Howell Ave., Milwaukee, WI 53207',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_UNITED_HEALTH_GROUP_OPTUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11000 Optum Circle, Eden Prairie, MN 55344',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ST_DAVIDS_GEORGETOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 Scenic Dr, Georgetown, TX 78626',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FEDEX_590',
          type: 'FRIDGE',
          operationConfigs: {
            address: '590 E Orangethorpe Ave, Anaheim, CA 92801',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_SIG_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '401 E. City Ave, Bala Cynwyd, PA 19004',
            guaranteed: null,
          },
        },
        {
          id: 'e5a9ac47-559a-46dc-8271-b58561473d48',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_4TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2255 E 103rd St, Chicago, IL 60617',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SONY_PICTURES_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10000 Washington Blvd, Culver City, CA 90232',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4250 Faculty Ave, Long Beach, CA 90808',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10507 Harlem Rd, Richmond, TX 77407',
            guaranteed: null,
          },
        },
        {
          id: 'TRUMAN_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1145 W Wilson Ave, Chicago, IL 60640',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_ARLINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 W Randol Mill Rd, Arlington, TX 76012',
            guaranteed: null,
          },
        },
        {
          id: 'ANIXTER_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 Patriot Blvd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W. Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M8',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Dr, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOSTON_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '850 Harrison Ave, Boston, MA 02118',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_THE_ION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4201 Main St, Houston , TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_AMAZON_MTN6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 Finishing Mill Rd, Sparrows Point, MD 21219',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SAN_JACINTO_CC_CENTRAL_45',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8060 Spencer Hwy, Pasadena, TX 77505',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DENTONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1221 Ave of the Americas, New York, NY 10020',
            guaranteed: null,
          },
        },
        {
          id: '1eedb0a8-fb59-46c8-99ed-4f503ece3d50',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11700 N Meridian St, Carmel, IN 46032',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_WILLOW_CREEK_FITNESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10089 Willow Creek Road, San Diego, CA 92131',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ASPEN_MEDICAL_PRODUCTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6481 Oak Canyon, Irvine, CA 92618',
            guaranteed: null,
          },
        },
        {
          id: 'WILLIS_TOWER_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '233 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_330_HOPE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 S Hope Street, Los Angeles, CA 90071',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CLEARY_GOTTLIEB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Liberty Plz, New York, NY 10006',
            guaranteed: null,
          },
        },
        {
          id: 'd2aa93f7-f926-45fe-9c77-f1e453a33a66',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_KENWOOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8240 Northcreek Drive, Cincinnati, OH 45236',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ONT_TERMINAL_2_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 E Airport Dr, Ontario, CA 91761',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_CLEAR_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 500 W. Medical Center Blvd, Webster , TX 77598',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_KATY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '23900 Katy Freeway, Katy , TX 77494',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_VA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Veterans Dr, Minneapolis, MN 55417',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_HSC_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1969 Zonal Ave, Los Angeles, CA 90089',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_ABERCROMBIE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6621 Fannin St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_GLICK_ROTUNDA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2500 MetroHealth Dr, Cleveland, OH 44109',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_2ND_FLOOR_PAVILION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 SOUTH MAIN STREET, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_MCKEESPORT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 5th Ave, McKeesport, PA 15132',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_KIRKLAND_ELLIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 Lexington Ave, New York, NY 10022',
            guaranteed: null,
          },
        },
        {
          id: 'HINES_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 5th Ave, Hines, IL 60141',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB3_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JEFFERSON_CHERRY_HILL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2201 Public Ave West, Cherry Hill, NJ 08002',
            guaranteed: null,
          },
        },
        {
          id: 'DC_HOWARD_UNIVERSITY_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 N Capitol NE, Washington, DC 20002',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYU_LANGONE_KIMMEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '424 East 34th St, New York, NY 10016',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CHASE_LOUNGE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin , TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CVG_CONCOURSE_B_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3087 Terminal Dr, Hebron, KY 41048',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SHARP_MARY_BIRCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3003 Health Center Dr., San Diego , CA 92123',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '108 Wilmot Rd, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: '5100_RIVER_ROAD_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5100 River Rd, Schiller Park, IL 60176',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ST_PETERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2641 Kennedy Blvd, Jersey City, NJ 07306',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SAN_JACINTO_S1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13735 Beamer Rd, Houston, TX 77089',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HACKENSACK_MEDIPLEX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 Prospect Ave, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LGB3_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4950 Goodman Wy, Eastvale, CA 91752',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_WESTALLIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8901 W Lincoln Ave, West Allis, WI 53227',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MORRISTOWN_MEDICAL_ER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Madison Ave, Morristown, NJ 07960',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_IAH_UNITED_BREAKROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3500 N Terminal Rd, Houston , TX 77032',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_SCRANTON_ROAD_EXOS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9735 Scranton Rd, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'CVS_130_CANAL_CHI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 S Canal St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_MELROSE_WAKEFIELD_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '585 Lebanon Street, Melrose, MA 02176',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TROY_PIZZA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44250 Dequindre Rd, Sterling Heights, MI 48134',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_25TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5555 W Grand Ave, Chicago, IL 60639',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3388 S Cactus Avenue, Jurupa Valley, CA 92509',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT3_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6806 Cal Turner Dr., San Antonio , TX 78220',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_ST_LUKES_ENTRANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 W Oklahoma Ave, Milwaukee, WI 53215',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FALCHI_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 47th Avenue, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'IND_DYER_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '24 Joliet Street, Dyer, IN 46311',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '127 S Davis Ave, Columbus, OH 43222',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_CHILDRENS_HOSPITAL_RB4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '575 Children’s Crossroad,  Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_FALK_MED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 5th Ave, Pittsburgh, PA 15213',
            guaranteed: null,
          },
        },
        {
          id: 'daea6a1e-aed6-4753-96b8-0a5e7f353634',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_METROHEALTH_BROADWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6835 Broadway Ave, Cleveland , OH 44105',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DMC_RECEIVING_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4201 St Antoine, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_MARVIN_ENG_RECEIVING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '498 N Oak St, Inglewood, CA 90302',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_GRR_MAIN_ENTRANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 44th St SE, Grand Rapids, MI 49512',
            guaranteed: null,
          },
        },
        {
          id: 'd88c59d7-630c-4a9c-ae25-b1659132565c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOBBY_AIRPORT_SW_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7800 Airport Blvd, Houston , TX 77061',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M37',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HACKENSACK_UMC_ATRIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 Prospect Ave, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_DALLAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3500 Gaston Ave, Dallas, TX 75246',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ST_ELIZABETH_DEARBORN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Wilson Creek Rd, Lawrencebrug, IN 47025',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CHOC_DOCTOR_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4650 Sunset Blvd, Los Angeles, CA 90027',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_FROEDTERT_SKYWALK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9200 W Wisconsin Ave, Milwaukee, WI 53226',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD6_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8900 Merrill Avenue, Ontario, CA 91762',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BARNES_JEWISH_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Barnes Jewish Hospital Plaza, St Louis, MO 63110',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_WEST_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 10th Avenue, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'MI_GRR_CONCOURSE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 44th St SE, Grand Rapids, MI 49512',
            guaranteed: null,
          },
        },
        {
          id: '222_N_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 N LaSalle St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ICW_15025_INNOVATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15025 Innovation Drive, San Diego, CA 92128',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_STRATFORD_HEIGHT_BLDNG_16',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2634 Stratford Avenue, Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TCC_NORTHEAST_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '828 W Harwood Rd, Hurst, TX 76054',
            guaranteed: null,
          },
        },
        {
          id: 'MN_NORMANDALE_CC_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9700 France Ave S, Bloomington, MN 55431',
            guaranteed: null,
          },
        },
        {
          id: '7e2f4eab-a59f-4ac8-b8bd-9cd29c44daef',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_THOMPSON_COBURN_LLP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '505 N 7th St, St Louis, MO 63101',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T5_DEPARTURES_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_SUGAR_LAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17500 W Grand Pkwy S, Sugar Land, TX 77479',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '435 West 116th St., New York, NY 10027-7297',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_10',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ST_DAVIDS_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '901 W Ben White Blvd, Austin , TX 78704',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_GENERAL_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 N State St, Los Angeles, CA 90033',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18500 Katy Fwy, Houston, TX 77094',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_BAIRD_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '777 E Wisconsin Ave., Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_GRAINGER_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '975 S University Ave, Madison, WI 53706',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_COLUMBIA_STMARYS_WATERTOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2350 N Lake Dr, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SOUTHWESTERN_JAGUAR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '900 Otay Lakes Road, Chula Vista, CA 91910',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ABBOTT_LABS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8701 Bee Cave Rd, Austin, TX 78746',
            guaranteed: null,
          },
        },
        {
          id: 'ABILITY_LABS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '355 E Erie St, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'MI_EMU_MARSHALL_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 W. Circle Drive, Ypsilanti, MI 48197',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_NORTH_CENTRAL_BRONX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3424 Kossuth Ave, Bronx, NY 10467',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_GRADUATE_SCHOOL_OF_JOURNALISM_PULITZER_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2950 Broadway, New York, NY 10027',
            guaranteed: null,
          },
        },
        {
          id: 'HARPER_COLLEGE_BUILDING_Z',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 W Algonquin Rd, Palatine, IL 60067',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_BLANTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Webster Road, Montclair, NJ 07043',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_CANTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 Canton Ctr Rd, Canton, MI 48188',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '185 S Orange Ave., Newark, NJ 07103',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_DREXEL_HEALTH_SCIENCES_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Filbert St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'erins_test',
          type: 'FRIDGE',
          operationConfigs: {
            address: '271 Bunker Hill Street, Boston, IL 02129',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SOUTHWESTERN_OTAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8100 Gigantic St, San Diego, CA 92154',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ELI_LILLY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '893 Delaware St, Indianapolis, IN 46225',
            guaranteed: null,
          },
        },
        {
          id: '00ef48eb-be33-4d99-a3c4-5a8b74debb08',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'WEWORK_20_W_KINZIE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 W Kinzie St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_TECH_TRUAX_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1701 Wright St, Madison, WI 53704',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_WAUKESHA_20975_SWENSON_DRIVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20975 Swenson Dr, Waukesha, WI 53186',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_WOODWINDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1825 Woodwinds Dr, Woodbury, MN 55125',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CARL_STOKES_COURTHOUSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 801 W. Superior Avenue, Cleveland, OH 44113',
            guaranteed: null,
          },
        },
        {
          id: 'ONE_PIERCE_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Pierce Place, Itasca, IL 60143',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_LIVINGSTON_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '84 Joyce Kilmer Ave, Piscataway, NJ 08854',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_VILLANOVA_FALVEY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Lancaster Ave, Villanova, PA 19085',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOAG_HOSPITAL_NEWPORT_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Hoag Dr, Newport Beach, CA 92663',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5143 Cass Ave, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'OFFICE_OF_THE_MAYOR_BREAK_ROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '121 N La Salle St UNIT 509, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEDICAL_CENTER_COMMONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6550 Bertner Ave, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6000 Schertz Pkwy, Schertz, TX 78154',
            guaranteed: null,
          },
        },
        {
          id: '5c6010c2-f210-4eda-be4d-6641e9cae5f4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '63576aaf-389e-424c-8e1e-b3c9113e3776',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENSBOROUGH_CC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222-05 56th Ave., Bayside, NY 11364',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_30TH_STREET_STATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2955 Market St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_EWR7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '275 Omar Ave, Avenel, NJ 07001',
            guaranteed: null,
          },
        },
        {
          id: 'IND_BMO_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '135 N Pennsylvania St., Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_KLOTSCHE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1921 E Hartford Ave., Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_SAMARITAN_4TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 Dixmyth Ave, Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'MN_PUBLIC_SERVICE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 South 4th Street, Minneapolis, MN 55415',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_MISSION_TRAIL_BAPTIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3333 Research Plaza, San Antonio , TX 78235',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENSBOROUGH_CC_SCIENCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222-05 56th Ave, Queens, NY 11364',
            guaranteed: null,
          },
        },
        {
          id: 'REDWOOD_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1765 N Elston Ave, Chicago, IL 60642',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_CHOP_4TH_FLOOR_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '34TH STREET & CIVIC CENTER BLVD, PHILADELPHIA, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: '20_S_CLARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 S Clark St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'b80d5f94-28b5-4c10-84ca-4bc4672ae126',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_USD_BOSLEY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6030 San Dimas Ave, San Diego, CA 92111',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_KESWICK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3910 Keswick Rd, Baltimore, MD 21211',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_SLU_MEDICAL_LRC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3544 Hickory St, St Louis, MO 63104',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_LOVE_AIRPORT_MAIN_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8008 Herb Kelleher Way, Dallas, TX 75325',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_PRINCETON_UNI_PERETSMAN_SCULLY_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '26 College Rd West, Princeton, NJ 08540',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_11',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_AMAZON_BOS3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1450 Osgood St, North Andover, MA 01845',
            guaranteed: null,
          },
        },
        {
          id: '744b8e90-68c0-4d00-b09a-a96347c8802f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_KEAN_UNIVERSITY_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Morris Ave, Union , NJ 07083',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BRIGHAM_WOMENS_HOSPITAL_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 Francis St, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_GROVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 North Meadows Dr, Grove City, OH 43123',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_TEMP_INSTALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '633 Clark St, Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: '8cf7bf7b-2b23-4337-ad3c-ce4ac92166d0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_STAFFORD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10041 Cash Rd, Stafford, TX 77477',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_SE_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11800 Astoria Blvd, Houston, TX 77089',
            guaranteed: null,
          },
        },
        {
          id: 'f935d664-3f9f-4899-afd5-e7f21f145389',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CC_INDUSTRIES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 N LaSalle St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_VA_BLDG_111_SOUTH_ENTRANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 W National Ave, Milwaukee, WI 53295',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HACKENSACK_UMC_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 Prospect Ave, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_UBS_TRADING_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1285 Avenue of the Americas, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_WEST_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5841 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TEXAS_CHILDRENS_HOSPITAL_MEYER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1919 S Braeswood, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'CA_UNIVERSITY_OF_SAN_DIEGO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6030 San Dimas Ave, San Diego, CA 92111',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_VA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4100 Allequippa St., Pittsburgh, PA 15240',
            guaranteed: null,
          },
        },
        {
          id: 'WEWORK_1_W_MONROE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 W Monroe St., Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_CARE_CRAWLEY_BLDNG_MED_CAMPUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3235 Eden Ave, Cincinnati, OH 45229',
            guaranteed: null,
          },
        },
        {
          id: '3a2636e8-8767-4172-8384-70d1d827da5a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MN_RBC_MINNEAPOLIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Nicollet Mall, Minneapolis, MN 55401',
            guaranteed: null,
          },
        },
        {
          id: '7269c1ea-d495-43be-8f7a-2496a38da8fb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_7TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1438 W 63rd St, Chicago, IL 60636',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_BAYTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4401 Garth Rd, Baytown, TX 77521',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_FEDEX_CAJON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7250 N Cajon Blvd, San Bernardino, CA 92407',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_LANSDALE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Medical Campus Dr, Landsdale, PA 19446',
            guaranteed: null,
          },
        },
        {
          id: '10_S_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 South LaSalle St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BU_MED_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '670 Albany St, Boston, MA 02118',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COLUMBIA_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 E 5th St, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_VILLANOVA_DRISCOLL_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Lancaster Ave, Villanova, PA 19085',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_QUEENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 30th Ave #10, Astoria, NY 11102',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BROOKLYN_BOULDERS_QUEENSBRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '23-10 41st Ave, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CONDE_NAST_PUBLICATIONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 World Trade Center, New York, NY 10007',
            guaranteed: null,
          },
        },
        {
          id: 'MN_BUTLER_SQUARE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 N 6th St, Minneapolis, MN 55403',
            guaranteed: null,
          },
        },
        {
          id: '5a78b96f-3720-4f95-b729-ea071ecde722',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'STERICYCLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4010 Commercial Ave., Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'PEAK6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '141 W Jackson Blvd, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'f2da9e61-2474-4ace-ba2e-8bfb1e01b200',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_RECENT_FRIDGEallcustomerorders',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'b2a0fa90-6a57-49d9-863f-6ac7cef58de9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TRAVEL_WIFI_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9251 Park South View, Houston , TX 77051',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DISTRICT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 12th Street NW, Washington, DC 20004',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HARBORSIDE_3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '210 Hudson St, Jersey City, NJ 07302',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_SOUTHEAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11800 Astoria Blvd, Houston , TX 77089',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_LIBERTY_TOWERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33 Hudson Street, Jersey City, NJ 07302',
            guaranteed: null,
          },
        },
        {
          id: 'WILLIS_TOWER_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '233 S Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDX7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 S Gateway Blvd, Forney, TX 75126',
            guaranteed: null,
          },
        },
        {
          id: 'ICNC_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '320 N Damen Ave., Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_MANDALAY_TOWER_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 E John Carpenter Freeway, Irving, TX 75062',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_INTERNATIONAL_PLACE_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Oliver St, Boston, MA 02110',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_A15_BAGGAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_NEWTON_WELLESLEY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2014 Washington St, Newton, MA 02462',
            guaranteed: null,
          },
        },
        {
          id: '123TEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'TEST, TEST, IL 23145',
            guaranteed: null,
          },
        },
        {
          id: 'UCHI_ATHLETIC_CEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5530 S Ellis Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_2ND_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5101 S Wentworth Ave, Chicago, IL 60609',
            guaranteed: null,
          },
        },
        {
          id: 'VA_DCA_MAIN_TERMINAL',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '2401 Ronald Reagan Washington National Airport Access Rd, Arlington, VA 22202',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_SCHOOL_OF_INTERIOR_DESIGN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '170 East 70th St, New York, NY 10021',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_SUFFOLK_UNI_1_COURT_STREET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11 Court St, Boston, MA 02108',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDF5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15501 North Beach St, Roanoke, TX 76262',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_SPECTRUM_RUTLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2433 Rutland Drive Suite 300, Austin , TX 78758',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_QUEENS2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '56-45 Main Street, Flushing, NY 11355',
            guaranteed: null,
          },
        },
        {
          id: '74e44e82-40d5-4c13-aabe-69fe602a7450',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MUSEUM_SCIENCE_INDUSTRY_VENDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Lake Shore Drive, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LUTHERAN_UNIVERSITY_SWENSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '120 Memorial Pkwy, Thousand Oaks, CA 91360',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2920 N Ridgeway Ave, Chicago, IL 60618',
            guaranteed: null,
          },
        },
        {
          id: 'PA_SPRING_HOUSE_BUILDING_7_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '727 Norristown Road, Spring House, PA 19002',
            guaranteed: null,
          },
        },
        {
          id: 'DUPAGE_SSC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Fawell Blvd, Glen Ellyn, IL 60137',
            guaranteed: null,
          },
        },
        {
          id: 'OH_MEDPACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5400 Medpace Way, Cincinnati, OH 45227',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_BAYLOR_ST_LUKES_MCNAIR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7200 Cambridge St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'CVS_101_CLINTON_CHI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 N Clinton St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '155 E Brush Hill Road, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_TABLER_ARTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_NEWTON_WELLESLEY_AMBULATORY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '159 Wells Ave, Newton, MA 02459',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_BUSINESS_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Washington Park, Newark, NJ 07102',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T3_UPPER_L2A_K1',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CAMP_MABRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 W 35th St, Austin , TX 78703',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DTW_EVANS_BAGGAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11050 W G Rogell Dr, Detroit, MI 48242',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BOULDERING_PROJECT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12A Tyler Street, Somerville, MA 02143',
            guaranteed: null,
          },
        },
        {
          id: '7a415949-deb3-40d0-abb1-8fdf6f08910d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'RELATIVITY_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '231 S. Lasalle St, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_LIBRARY_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S Morgan St., Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BELLEVUE_15TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '462 1st Avenue, New York, NY 10016',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_UPENN_WHARTON_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3730 Walnut St, PHILADELPHIA, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_HOSPITAL_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1468 Madison Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MORRISTOWN_MEDICAL_MATERNITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Madison Ave, Morristown, NJ 07960',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TTI_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2441 Northeast Parkway, Fort Worth, TX 76106',
            guaranteed: null,
          },
        },
        {
          id: 'de2b376b-13c9-4377-9d71-94379fa6a692',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_ARLINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3301 Matlock Road, Arlington, TX 76015',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Normal Ave, Montclair, NJ 07043',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ST_LUKES_SUGAR_LAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1317 Lake Pointe Pkwy, Sugarland, TX 77478',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_CARTAMUNDI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5101 Highland Dr, Dallas, TX 75236',
            guaranteed: null,
          },
        },
        {
          id: 'FRIEND_HEALTH_COTTAGE_GROVE_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6250 South Cottage Grove, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DAL2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2601 S Airfield Dr, Dallas, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'PRESIDENTIAL_TOWERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 W Madison St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LGB3_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4950 Goodman Wy, Eastvale, CA 91752',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WALMART_FULFILLMENT_CHINO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6750 Kimball Ave, Chino, CA 91708',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAVITS_DOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '429 11th Ave, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_PANORAMA_MED_CENTER_MOB2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13651 Willard St, Panorama City, CA 91402',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_USD_PALOMAR_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1502 Via Las Cumbres, San Diego, CA 92111',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHROP_GRUMMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Hicks Rd, Rolling Meadows, IL 60008',
            guaranteed: null,
          },
        },
        {
          id: '35757916-c03e-4359-938c-e22ad98579c4',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TAYLOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10000 Telegraph Rd, Taylor, MI 48180',
            guaranteed: null,
          },
        },
        {
          id: 'IND_8711_RIVER_CROSSING_BLVD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8711 River Crossing Blvd, Indianapolis, IN 46240',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_MADISON',
          type: 'DELIVERY',
          operationConfigs: {
            address: '500 W Madison St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FRIED_FRANK_23N',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 New York Plaza, New York City, NY 10004',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JEFFERSON_WASHINGTON_TOWNSHIP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '435 Hurffville - Cross Keys Rd, Sewell, NJ 08080',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COLLIN_COLLEGE_FRISCO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9700 Wade Blvd, Frisco, TX 75035',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_CHILDRENS_HOSPITAL_WOMENS_PAVILLION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6651 Main St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: '1b595ebd-7a35-4c0f-b1f7-416e9edede93',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JOHN_THEURER_CANCER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '92 2nd St, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_ESCONDIDO_POLICE_DEPT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1163 Centre City Parkway, Escondido, CA 92026',
            guaranteed: null,
          },
        },
        {
          id: 'CA_ARE_CAMPUS_POINT_EXOS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10300 Campus Point Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_DEARBORN_2ND_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18101 Oakwood Blvd, Dearborn, MI 48124',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_WORLEY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5995 Rogerdale Rd, Houston , TX 77072',
            guaranteed: null,
          },
        },
        {
          id: '1871',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 W Merchandise Mart Plz, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_PRATT_HIGGINS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Willoughby Ave, Brooklyn, NY 11205',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_HICKMAN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '89 George St, New Brunswick, NJ 08901',
            guaranteed: null,
          },
        },
        {
          id: 'HOLY_CROSS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2701 W 68th Street, Chicago, IL 60629',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LAHEY_HOSPITAL_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '41 Burlington Mall Road, Burlington, MA 01803',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_22ND_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 W Monterey Ave, Chicago, IL 60643',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LONG_BEACH_MEMORIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2801 Atlantic Ave, Long Beach, CA 90806',
            guaranteed: null,
          },
        },
        {
          id: 'FULTON_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1330 W Fulton St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_RICE_MCMURTRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6100 S MAIN ST, Houston , TX 77005',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_WORKHUMAN_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Staples Dr, Framingham, MA 01702',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AMAZON_PIT9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17 William Dr, Imperial , PA 15126',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_SOUTHEAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4000 Spencer Hwy, Pasadena, TX 77504',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COLLIN_COLLEGE_MCKINNEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 West University Drive, McKinney, TX 75071',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_HENRY_FORD_WYANDOTTE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2333 Biddle Ave, Wyandotte, MI 48192',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_WAKEFERN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 Raritan Center Parkway, Edison, NJ 08837',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_IAH5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8903 Warehouse Center Dr, Humble, TX 77338',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LUTHERAN_UNIVERSITY_GILBERT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '67 w Olsen Rd, Thousand Oaks, CA 91360',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_PROGRESSIVE_FIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2401 Ontario St, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_SBS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_SCIENCE_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '402 N Blackford St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_SOUTHWEST_TOPS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2170 Research Row, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TRENTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5450 Fort St, Trenton, MI 48183',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_MEDICAL_OUTPATIENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2160 S 1st Ave., Maywood, IL 60153',
            guaranteed: null,
          },
        },
        {
          id: 'MN_LAKEHOUSE_HEALTHCARE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3737 Bryant Ave S, Minneapolis, MN 55409',
            guaranteed: null,
          },
        },
        {
          id: '8287bd16-0d06-46e8-82b6-3ea548cc647a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_3100_MAIN_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3100 Main St., Houston , TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'OAKBROOK_TWENTY_TWO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 22nd St, Oakbrook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: 'SUNSTAR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '301 E Central Rd, Schaumburg, IL 60195',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SYMMETRY_MANAGEMENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2445 McCabe Way, Irvine, CA 92614',
            guaranteed: null,
          },
        },
        {
          id: 'MA_HARVARD_SCIENCE_ENG_COMPLEX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 Western Ave, Boston, MA 02134',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_POLY_PREP_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9216 7th Ave, Brooklyn, NY 11228',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BCH_LOBBY_DOUBLE_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Longwood Ave, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_1allcustomerorders',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_GM_COLE_ENGINEERING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '29755 Louis Chevrolet Rd, Warren, MI 48093',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_ABINGTON_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 Old York Road, Abington, PA 19001',
            guaranteed: null,
          },
        },
        {
          id: 'WBEZ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '848 East Grand Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: '205_W_RANDOLPH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '205 W Randolph St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_NKU_CALLAHAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3510 Alexandria Pike, Newport, KY 41076',
            guaranteed: null,
          },
        },
        {
          id: 'UNIVERSITY_ST_FRANCIS_TOWER_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Wilcox St, Joliet, IL 60435',
            guaranteed: null,
          },
        },
        {
          id: 'GLANBIA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '948 Meridian Lake Dr, Aurora, IL 60504',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BABSON_REYNOLDS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '231 Forest St, Babson Park, MA 02457',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T5_G7',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'John F. Kennedy International Airport, Terminal 5, Queens, NY 11340',
            guaranteed: null,
          },
        },
        {
          id: 'HOPKINS_OFFICE_0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2122 W Hopkins Pl, Chicago, IL 60643',
            guaranteed: null,
          },
        },
        {
          id: '337f18e5-0a1a-4faf-8773-4ea25e37c3c5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_194_WOOD_AVENUE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '194 Wood Avenue, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'dd7e3d7d-b039-4815-b352-e664a1287633',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'LOVELL_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3001 Green Bay Road, North Chicago, IL 60064',
            guaranteed: null,
          },
        },
        {
          id: '3e3a00b7-2db0-41ab-821a-3148ed65bac3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'UNIVERSITY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 S State St, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'WRIGLEY_BLDG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 N Michigan Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_IKEA_BUILDING_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4762 Borusan Rd, Baytown , TX 77523',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_DREXEL_HAGERTY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3300 Market St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HACKENSACK_MATERNITY_WARD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 Prospect Ave, Hackensack, NJ 07601',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LIBERTY_BROOKLYN_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '850 3rd Ave, Brooklyn, NY 11232',
            guaranteed: null,
          },
        },
        {
          id: '7bfbbde5-ae25-4170-a247-8498a4b7963f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_NU_INTERNATIONAL_VILLAGE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1155 Tremont St., Boston , MA 02120',
            guaranteed: null,
          },
        },
        {
          id: 'USPS_CHICAGO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '433 W. Harrison St., Chicago, IL 60699',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ADM_KENTUCKY_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1260 Pacific Ave, Erlanger, KY 41018',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_HOLY_NAME',
          type: 'FRIDGE',
          operationConfigs: {
            address: '718 Teaneck Road, Teaneck, NJ 07666',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FEDERAL_RESERVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33 Liberty St, New York, NY 10045',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NEW_SCHOOL_55_W_13TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 West 13th St, New York, NY 10011',
            guaranteed: null,
          },
        },
        {
          id: 'ELMHURST_UNIVERSITY_FRICK_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '190 S Prospect Ave, Elmhurst, IL 60126',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_UNIVERSITY_SCHREIBER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16 E Pearson St., Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'VA_ONE_MONUMENT_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12150 Monument Dr, Fairfax, VA 22033',
            guaranteed: null,
          },
        },
        {
          id: 'HYATT_MCCORMICK_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2233 S Martin Luther King Dr, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ST_VINCENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 West 96th Street, Indianapolis, IN 46260',
            guaranteed: null,
          },
        },
        {
          id: 'e9075657-9dcc-4fa7-afa6-191772ab7116',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_PUBLIC_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1603 W Taylor St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ACADEMY_SPORTS_BUILDING_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1800 N Mason Rd, Katy, TX 77449',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_UPENN_BRB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '421 Curie Boulevard, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: '909_DAVIS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '909 Davis St., Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'SCHWAB_REHABILITATION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1401 S California Ave, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ST_LUKES_VINTAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20171 Chasewood Park Dr, Houston , TX 77070',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AIRPORT_EAST_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin, TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AMAZON_AUS2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 E Pecan St, Pflugerville, TX 78660',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB_SATELLITE_2_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_SHIELD_AI',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16240 Gateway Path, Frisco, TX 75033',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CCNY_MARSHAK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 Convent Ave., New York, NY 10031',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MITCHELL_HAMLINE_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '875 Summit Ave, St Paul, MN 55105',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAVITS_INTERNAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '429 11th Ave, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_TEXAS_STATE_ROUND_ROCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1555 University Blvd, Round Rock, TX 78665',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWHEALTH_UNIV_HOSP_CAFE_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Highland Ave, Madison, WI 53792',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ROBINSON_HELICOPTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2901 Airport drive, Torrance, CA 90505',
            guaranteed: null,
          },
        },
        {
          id: 'IND_CROWN_POINT_FRANCISCAN_GRECCIO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12750 Saint Francis Drive, Crown Point, IN 46307',
            guaranteed: null,
          },
        },
        {
          id: 'b56cdc86-1f9a-4dc3-8aa4-d97c9e5d1227',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CVG_ADMIN_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3087 Terminal Dr, Hebron, KY 41048',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JERSEY_SHORE_UMC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1945 Route 33, Neptune City, NJ 07753',
            guaranteed: null,
          },
        },
        {
          id: 'ba4df913-6b65-4cb8-827c-e0c66e817cd9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '6caa25f9-297b-46f4-bc1c-999889d9275e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IIT_MEETING_DEMO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 W 35th St, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_UPENN_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '418 Curie Blvd, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_CHILDRENS_HOSPITAL_OF_NEW_YORK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3959 Broadway, New York, NY 10032',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_WAYNE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33155 Annapolis St, Wayne, MI 48184',
            guaranteed: null,
          },
        },
        {
          id: 'MALCOM_X',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 W Jackson Blvd, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DAL3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 Chalk Hill Rd, Dallas, TX 75211',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_EWR9_DOCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8003 Industrial Hwy, Carteret, NJ 07008',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_RANNY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_FRAMINGHAM_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 State St, Framingham, MA 01701',
            guaranteed: null,
          },
        },
        {
          id: 'IND_RILEY_CHILDRENS_ROC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '705 Riley Hospital Dr, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'CDW_120_S_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '120 S Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'HAROLD_WASHINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 E Lake St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CITY_TECH_NAMM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Jay St, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LENOX_HEALTH_GREENWICH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30 7th Avenue, NY, NY 10011',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_OCC_THE_HARBOUR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1369 Adams Ave, Costa Mesa, CA 92626',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_THE_GARAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2311 Campus Dr., Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_DCAM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5758 S Maryland Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ESLER_COMPANY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S MoPac Expy Bldg C Unit 300, Austin , TX 78749',
            guaranteed: null,
          },
        },
        {
          id: 'af49045d-5988-4773-b6ac-b5c095940e98',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_GOOGLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6420 Sequence Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_HARVARD_WILLIAM_JAMES_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '33 Kirkland Street B12, Cambridge, MA 02138',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_CHRISTIAN_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11133 Dunn Rd, St Louis, MO 63136',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_REPUBLIC_SQUARE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13501 Katy Fwy, Houston , TX 77056',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_WASHU_OLIN_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Brookings Dr, St Louis, MO 63130',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDX2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1398 Industrial Blvd, McKinney, TX 75069',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_COLEMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 Pressler St, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_BRYN_MAWR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '906 New Gulph Road, Bryn Mawr, PA 19010',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_HOSPITAL_ARMOUR_ACADEMIC_7TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 S Paulina St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PA_JOHNSON_AND_JOHNSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 McKean Road, Spring House, PA 19002',
            guaranteed: null,
          },
        },
        {
          id: 'e231bd97-ff1e-40b6-a7a5-fd826914af8d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '400_SKOKIE_BLVD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 Skokie Blvd., Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'LITTLE_COMPANY_MARY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 95th St, Evergreen Park, IL 60805',
            guaranteed: null,
          },
        },
        {
          id: 'f289569a-6856-473e-9aaf-e333eed1fa1f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_GRR_MARKETPLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 44th St SE, Grand Rapids, MI 49512',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SHARP_CHULA_VISTA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '751 Medical Center Ct, San Diego, CA 91911',
            guaranteed: null,
          },
        },
        {
          id: 'EVANSTON_HS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 Dodge Ave, Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_TMC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6445 S Main St. Floor 2, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_44_WHIP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44 Whippany Road, Morristown, NJ 07960',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYIT_MEDICAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 Northern Blvd, Glen Head, NY 11568',
            guaranteed: null,
          },
        },
        {
          id: 'TRUNK_CLUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '325 W Ohio St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_A21_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Aiport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_GM_INNOVATION_CENTER_WHEEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13201 McCallen Pass, Austin , TX 78753',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_EINSTEIN_ELKINS_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Township Line Rd, Elkins Park, PA 19027',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_LAX9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11263 Olleander Ave, Fontana, CA 92337',
            guaranteed: null,
          },
        },
        {
          id: 'PACTIV_EVERGREEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 W Field Court, Lake Forest, IL 60045',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_TEB6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22 Hightstown-Cranbury Station Road, Cranbury, NJ 08512',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_DEPTFORD_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '240 Mantua Grove Road, West Deptford, NJ 08066',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_ST_ELIZABETHS_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '736 Cambridge St, Brighton, MA 02135',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_KINDER_MORGAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1001 Louisiana, Houston , TX 77001',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_19',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_GROSSE_POINTE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '468 Cadieux Rd, Grosse Pointe, MI 48230',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_SCRIPPS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '312 Walnut St, Cincinatti, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'd383ba3e-369d-438e-be70-fe19a565206e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LAHEY_HOSPITAL_EAST_4TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '41 Burlington Mall Rd, Burlington, MA 01805',
            guaranteed: null,
          },
        },
        {
          id: 'LAKE_COUNTY_GOV_LIBERTYVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 W Winchester Rd, Libertyville, IL 60048',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCC_KATY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22910 Colonial Pkwy, Katy , TX 77449',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BARUCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 Lexington Avenue, New York, NY 10010',
            guaranteed: null,
          },
        },
        {
          id: 'WE_ENERGIES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '231 W Michigan St, Milwaukee, WI 53290',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_HPB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17200 Red Oak Drive, Houston , TX 77090',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_WAYFAIR_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Copley Place, Boston, MA 02116',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_MIT_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '84 Mass Ave, Cambridge, MA 02139',
            guaranteed: null,
          },
        },
        {
          id: 'BOEING_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 N Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_VA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4100 Allequippa St., Pittsburgh, PA 15240',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SPACEX_HT01',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Rocket Rd, Hawthorne, CA 90250',
            guaranteed: null,
          },
        },
        {
          id: 'WEWORK_332_S_MICHIGAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '332 S Michigan Ave., Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: '8ce20dc4-e1e0-45f6-aae9-30c5ecee288e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_CHRIST_OUTPATIENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4440 W 95th St, Oak Lawn, IL 60453',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_INFORMATION_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4676 Admiralty Way, Marina Del Rey, CA 90292',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KITE_PHARMA_EL_SEGUNDO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2383 Utah Ave, El Segundo, CA 90245',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_VA_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 W National Ave, Milwaukee, WI 53295',
            guaranteed: null,
          },
        },
        {
          id: 'ALEXIAN_EBERLE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '800 Blesterfield Road, Elk Grove Village, IL 60007',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_AVE_OF_THE_AMERICAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 6th Ave., New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_INTERNATIONAL_PLACE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2 Internation Pl, Boston, MA 02110',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_BERGEN_NB_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 E Ridgewood Ave, Paramus, NJ 07652',
            guaranteed: null,
          },
        },
        {
          id: 'HARPER_COLLEGE_BUILDING_M',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 Algonquin Rd, Palatine, IL 60067',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_CHULA_VISTA_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '353 H St, Chula Vista, CA 91910',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_17220_KATY_FREEWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17220 Katy Freeway, Houston, TX 77095',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_30TH_STREET_STATION_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2955 Market St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LAGUARDIA_CC_BUILDING_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30-20 Thomson Avenue, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'EVANSTON_HIGH_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1600 Dodge Ave, Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_603_MUNGER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '605 Munger Ave, Dallas, TX 75202',
            guaranteed: null,
          },
        },
        {
          id: 'NEIU_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 St Louis Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'WALGREENS_191_N_CLARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '191 N Clark St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_ADRIANC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 W Lake St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '5750_OLD_ORCHARD_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5750 Old Orchard Road, Skokie, IL 60077',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BELLEVUE_12TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '462 1st Avenue, New York, NY 10016',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_770_N_JEFFERSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '770 N Jefferson St, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_GATE_A8_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_ED_VEND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 SOUTH MAIN STREET, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_375_PEARL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '375 Pearl St., New York, NY 10038',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_DEARBORN_HALLWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18101 Oakwood Blvd, Dearborn, MI 48124',
            guaranteed: null,
          },
        },
        {
          id: 'IND_500_N_MERIDIAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 N Meridian St, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: '1200_W_HARRISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 W Harrison Street, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_99_WOOD_AVENUE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '99 S Wood Ave, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_CONTINUING_CARE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 S Fry Rd, Houston, TX 77450',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_11601_WILSHIRE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11601 Wilshire Boulevard, Los Angeles, CA 90025',
            guaranteed: null,
          },
        },
        {
          id: 'erin_test',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1901 N Hoyne, Chicago, IL 60647',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_OAK_BROOK_CBRE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2011 York Rd, Oak Brook, IL 60523',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BRIGHAM_WOMENS_HOSPITAL_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '75 Francis St, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_HOSPITAL_1750_HARRSION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1750 W Harrison Street, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_QUEENS_COLLEGE_STUDENT_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '152-45 Melbourne Ave, Flushing, NY 11367',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LAKEWOOD_CHURCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3700 Southwest Fwy, Houston , TX 77027',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T2_UPPER_F3',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago , IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_SURGERY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: '555_W_HARRISON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 W Harrison St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_15',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MN_3M_HQ_BUILDING_236',
          type: 'FRIDGE',
          operationConfigs: {
            address: '236 3M Center, Maplewood, MN 55119',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_MISSOURI_BAPTIST_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3015 N Ballas Rd, St Louis, MO 63131',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T2_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4300 Glumack Dr, St Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_TIAA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '730 3rd Ave, New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_CONCOURSE_B1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'WILLIS_TOWERS_WATSON_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '233 S Wacker Drive, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '35_E_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '35 E Wacker Drive, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LAX_TRMNL_1_GATE_14',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 World Way, Los Angeles, CA 90045',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_APPLE_CULVER_CITY_22',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3750 S Robertson Rd, Culver City, CA 90232',
            guaranteed: null,
          },
        },
        {
          id: 'HORIZON_PHARMACEUTICALS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 S Saunders Rd, Lake Forest, IL 60045',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_HEALTH_SCIENCES_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '750 Highland Ave, Madison, WI 53705',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7700 Floyd Curl Drive, San Antonio , TX 78229',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_CONCOURSE_A4A',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'TRITON_COLLEGE_STUDENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 5th Ave, River Grove, IL 60171',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ST_ELIZABETH_EDGEWOOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 1 Medical Village Dr., Edgewood, KY 41017',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIKERS_ISLAND_OBCC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16-00 Hazen St, Bronx, NY 11370',
            guaranteed: null,
          },
        },
        {
          id: '33395ebe-d234-4e51-9a06-5c2969da6edc',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T5_DEPARTURES_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_717_TEXAS_CALPINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '717 Texas, Houston , TX 77002',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_AACC_CALT_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 College Pkwy, Arnold, MD 21012',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_DEPTFORD_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '240 Mantua Grove Road, West Deptford, NJ 08066',
            guaranteed: null,
          },
        },
        {
          id: 'DC_US_DEPT_HOMELAND_SECURITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 Nebraska Ave NW, Washington, DC 20528',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_WAVERLEY_OAKS_ATHLETIC_CLUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Waverley Oaks Rd Suite 200, Waltham, MA 02452',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_SIGNAL_PRODUCTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5600 W Adams Blvd, Los Angeles, CA 90016',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_UM_MEDCENTER_DOWNTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22 S Greene St, Baltimore, MD 21202',
            guaranteed: null,
          },
        },
        {
          id: 'MARKETING_MICHIGAN_AVE_POP_UP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '875 N Michigan Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BRONX_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 W Kingsbridge Road, Bronx, NY 10468',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_ST_CLARES_DENVILLE_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: ' 25 Pocono Rd, Denville, NJ 07834',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_333_THORNHALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 Thornall St, Edison, NJ 08837',
            guaranteed: null,
          },
        },
        {
          id: 'MN_USPS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 South 1st Street, Minneapolis, MN 55401',
            guaranteed: null,
          },
        },
        {
          id: 'GROUPON_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7600 Fannin St, Houston , TX 77054',
            guaranteed: null,
          },
        },
        {
          id: 'IND_AIRPORT_GATE_A',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '7800 Col. H. Weir Cook Memorial Dr, Indianapolis, IN 46241',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BU_FITREC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '915 Commonwealth Ave, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'GOOGLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 W Fulton, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'de3e26dd-d439-4bb4-9376-c877b5a1c7bb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_B25',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_SCIENCE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3675 Market St, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: '085ac831-9e3a-47d5-b69e-fd0f82f3bfd2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_CHULA_VISTA_POLICE_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '315 4th Ave, Chula vista, CA 91910',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_CHASE_LOUNGE_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin , TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_3030_THOMSON_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '30-20 Thomson Ave., Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_5TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '727 E 111th St, Chicago, IL 60628',
            guaranteed: null,
          },
        },
        {
          id: '5b3ab6d1-c01a-4d4e-b8da-9cb7fcbefba3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_UTA_UNIVERSITY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 w 1st St, Arlington, TX 76010',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DETROIT_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4646 John R Street, Detroit, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'CBRE_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '321 North Clark Street, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MN_LAKEHOUSE_HEALTHCARE_CENTER_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3737 Bryant Ave S, Minneapolis, MN 55409',
            guaranteed: null,
          },
        },
        {
          id: 'DELNOR_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 Randall Rd, Geneva, IL 60134',
            guaranteed: null,
          },
        },
        {
          id: 'MN_SPS_TOWER_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 S 7th St, Minneapolis, MN 55402',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_GRANITE_3355_ALABAMA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3355 W Alabama St, Houston , TX 77098',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_VALLEY_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '223 N Van Dien Ave, Ridgewood, NJ 07450',
            guaranteed: null,
          },
        },
        {
          id: '1_N_FRANKLIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 N Franklin St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_TECH_COMMERCIAL_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2125 Commercial Ave, Madison, WI 53704',
            guaranteed: null,
          },
        },
        {
          id: 'f2440772-1110-45c5-a71f-37b0537ad3e0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'e3c334cd-3422-40b0-bf0d-8474ab3edd7f',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_GRAPEVINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1650 w College St, Grapevine, TX 76051',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LESLEY_UNIVERSITY_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '89 Brattle St, Cambridge, MA 02138',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BROWN_AND_CROUPPEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4900 Dagget Ave, St Louis, MO 63110',
            guaranteed: null,
          },
        },
        {
          id: 'MOSS_INC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10507 Seymour Avenue, Franklin Park, IL 60131',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_CORE_SPACES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6345 El Cajon Blvd, San Diego, CA 92115',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_DUNN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6565 Fannin, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 N University Blvd, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_PLANO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6200 W Parker Rd, Plano, TX 75093',
            guaranteed: null,
          },
        },
        {
          id: 'IND_ELI_LILLY_NORTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1223 W Morris St, Indianapolis, IN 46221',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_LAWRENCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 Palmer Ave, Bronxville, NY 10708',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_A1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LAHEY_HOSPITAL_BUILDING_31',
          type: 'FRIDGE',
          operationConfigs: {
            address: '31 Burlington Mall Rd, Burlington, MA 01805',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T4_PRESECURITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'JFK International Airport, Queens, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'KEHOE_DESIGNS_INC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2555 S Leavitt St., Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_LIBERTY_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7777 Yankee Rd, Liberty Township, OH 45044',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_AMAZON_STL8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4000 Premier Pkwy, St Peters, MO 63376',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_MEMORIAL_HOSPITAL_BELLEVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 Memorial Dr, Belleville, IL 62226',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_CONCORIDA_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12800 N Lake Shore Drive, Mequon, WI 53097',
            guaranteed: null,
          },
        },
        {
          id: '32470bc6-20a5-4220-83d6-04be4c50822c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '7fac3ff7-3750-474e-bb40-cf55f518dc74',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'FCLOUD1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton Street, Chicago, IL 60612-2363',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_SAINT_MARGARET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '815 Freeport Rd, Pittsburgh, PA 15215',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_SOUTH_SUBURBAN_EMERGENCY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17800 Kedzie Ave, Hazel Crest , IL 60429',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_MMU9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '698 Rte. 46 W., Teterboro, NJ 07608',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SDSU_THE_ESSENTIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5595 Lindo Paseo, San Diego , CA 92115',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_1819_INNOVATION_HUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 Reading Rd, Cincinnati, OH 45206',
            guaranteed: null,
          },
        },
        {
          id: 'cb07c81d-a470-49b8-b797-ded49b76640c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TROY_GARDEN_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44201 Dequindre Rd, Troy, MI 48085',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CMH_CONCOURSE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4600 International Gateway, Columbus, OH 43219',
            guaranteed: null,
          },
        },
        {
          id: 'INTEGRATED_MEDICAL_SYSTEMS_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '594 Territorial Drive, Unit A, Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_COLLINS_AEROSPACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3200 E Renner Rd Building 461, Richardson, TX 75082',
            guaranteed: null,
          },
        },
        {
          id: 'MA_LAHEY_HEALTH_MOUNT_AUBURN_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 Mt Auburn St, Cambridge, MA 02138',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MARQUETTE_COMMUNICATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1131 W Wisconsin Ave, Milwaukee, WI 53233',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HCA_KINGWOOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22999 US-59 N, Kingwood, TX 77339',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LI_STONY_BROOK_CHAPIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Health Sciences Drive, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BABSON_OLIN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4 Babson College Drive, Wellesley, MA 02482',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_HTC_EDEN_PRAIRIE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13100 College View Dr, Eden Prairie, MN 55347',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HSBC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '452 5th Avenue, New York, NY 10018',
            guaranteed: true,
          },
        },
        {
          id: 'NYC_WSJ_FUTURE_OF_EVERYTHING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6 St Johns Ln, New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: 'd39742d4-1f3b-4d06-8578-3571adfd5944',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '7b871a6f-4ca6-486e-a1f7-5d488656ad4d',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '150_N_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 N Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_PHARMACY_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '833 S Wood St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CHOC_NORTH_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 W La Veta Ave, Orange , CA 92868',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_CALL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3801 Main St, Dallas, TX 75226',
            guaranteed: null,
          },
        },
        {
          id: '77_W_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '77 W Wacker Dr., Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'PWC_CHICAGO_1_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 N Wacker Dr, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_UNIVERSITY_SULLIVAN_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6339 N Sheridan Road, Chicago, IL 60660',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '325d0936-6e03-4b4b-8bf7-735b763f227a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_BMS_SUMMIT_S12',
          type: 'FRIDGE',
          operationConfigs: {
            address: '556 Morris Ave, Summit, NJ 07901',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_GLOBE_LIFE_FIELD_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '734 Stadium Dr, Arlington, TX 76011',
            guaranteed: null,
          },
        },
        {
          id: 'ALEXIUS_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1555 Barrington Road, Hoffman Estates, IL 60169',
            guaranteed: null,
          },
        },
        {
          id: 'MN_HENNEPIN_COUNTY_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '730 South 8th St, Minneapolis, MN 55404',
            guaranteed: null,
          },
        },
        {
          id: 'VIRGIN_HOTELS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '203 N Wabash Ave, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SIEMENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 S Wood Ave, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_TOWER_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2 Tower Center Blvd, East Brunswick, NJ 08816',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_LUKES_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 Amsterdam Ave, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_DEPARTMENT_OF_EDUCATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '883 Classon Ave, Brooklyn, NY 11225',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_JOHNS_HOPKINS_EASTERN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 E 33rd St, Baltimore, MD 21218',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_WHOOP_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'One Kenmore Square, Boston, MA 02215',
            guaranteed: null,
          },
        },
        {
          id: 'JOHN_HANCOCK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '875 N Michigan Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: '8de7e115-94da-4586-b1b3-ff4d7e99c3c3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AIRPORT_GATE_23_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin, TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_SHADYSIDE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5230 Centre Ave, Pittsburgh, PA 15232',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_WESLEYAN_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1101 Wesleyan St., Fort Worth , TX 76105',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_TEB9_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 Randolph Rd, Somerset, NJ 08873',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_TERMINAL_1_C25',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10701 Lambert International Blvd, St Louis, MO 63145',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_COLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 E Normal Ave, Montclair , NJ 07043',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JAVITS_4THFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '429 11th Ave, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_PENN_STATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 N Charles st, Baltimore, MD 21201',
            guaranteed: null,
          },
        },
        {
          id: 'DOMINICAN_UNIVERSITY_PARMER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7900 Division St, River Forest, IL 60305',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_MEDICINE_GLENVIEW_OUTPATIENT_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2701 Patriot Blvd, Glenview, IL 60026',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_EWR5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '301 Blair Rd, Avanel, NJ 07001',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CITY_TECH_VORHEES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '186 Jay St, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FEINSTEIN_MEDICAL_RESEARCH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 Community Dr, Manhasset, NY 11030',
            guaranteed: null,
          },
        },
        {
          id: 'HUDSON_ORD_DISTRIBUTION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2447 East Higgins Rd, Elk Grove Village, IL 60007',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_UNIVERSITY_AKRON_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '303 Carroll St., Akron, OH 44325',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_TECH_GOODMAN_SOUTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2429 Perry St, Madison, WI 53713',
            guaranteed: null,
          },
        },
        {
          id: 'NAVISTAR_BUILDING3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2701 Navistar Dr., Lisle, IL 60532',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_EYE_EAR_THROAT_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '210 E 64th St, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB1_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_EXXON_BAYTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 bayway, Baytown , TX 77520',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AA_EMPLOYEE_LOUNGE_C2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_SAMSUNG_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 Challenger Road, Ridgefield Park, NJ 07660',
            guaranteed: null,
          },
        },
        {
          id: 'WEST_LOOP_BROOKLYN_BOULDERS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 S Morgan St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_ED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1468 Madison Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_TRINITY_HEALTH_CARMEL_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6001 East Broad St, Columbus, OH 43123',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_PRESTIGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Prestige Place, Miamisburg, OH 45342',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_BEVERLY_CENTER_MALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8500 Beverly Blvd, Los Angeles, CA 90048',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_RIVERSIDE_METHODIST_ASSOCIATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 Olentangy River Rd, Columbus, OH 43214',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_13',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_SIGNATURE_AVIATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4321 Emma Browning Ave, Austin, TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_WEST_12TH_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Tenth Ave, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_2020_RIDGE_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2020 Ridge Ave., Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_VA_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 W National Ave, Milwaukee, WI 53295',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_MUDD_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2233 Tech Dr., Evanston, IL 60208',
            guaranteed: null,
          },
        },
        {
          id: 'OH_RIVERSIDE_METHODIST_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 Olentangy River Road, Columbus, OH 43214',
            guaranteed: null,
          },
        },
        {
          id: '97e5fc42-a735-4658-a20e-d1bda4a9e2b6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_310_WISCO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '310 W Wisconsin Ave, Milwaukee, WI 53203',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_NURSING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 Highland Ave, Madison, WI 53705',
            guaranteed: null,
          },
        },
        {
          id: '66e4c824-cac2-4fc3-82bd-e9f781401bf5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_MICHIGAN_SCIENCE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5020 John R St, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_PUBLIC_SCHOOLS_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '42 W. Madison St, Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MDW7',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6605 W Monee Manhattan Rd, Monee, IL 60449',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DFW6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '940 W Bethel Rd, Coppell, TX 75019',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_BROOKLYN_FEIRSTEIN_CINEMA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 Washington Ave, Brooklyn, NY 11205',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_CENTER_AT_600_VINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 Vine St, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_APPLE_SND05',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9779 Town Centre Dr, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MONTEFIORE_EINSTEIN_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1695 Eastchester Rd, Bronx, NY 10461',
            guaranteed: null,
          },
        },
        {
          id: 'MIDWESTERN_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '555 31st St., Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_NEWARK_BETH_ISRAEL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 Lyons Ave, Newark, NJ 07112',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BARNES_JEWISH_CHILDRENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: "1 Children's Pl, St Louis, MO 63110",
            guaranteed: null,
          },
        },
        {
          id: 'CDW_VERNON_HILLS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 N Milwaukee Ave, Vernon Hills, IL 60061',
            guaranteed: null,
          },
        },
        {
          id: 'b0795aa4-6da1-45e1-bb73-3ead0e0f31cf',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_RECREATION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2820 Bearcat Way, Cincinnati, OH 45221',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_D38',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2400 Aviation Dr, DFW Airport, TX 75261',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_APPLE_PARMER_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13011 Center Lake Dr, Austin , TX 78753',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HAVEN_ELGIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3719 Elgin St, Houston , TX 77004',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_TEST_TEST_RECENT_FRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CALAMOS_INVESTMENTS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2135 City Gate Lane, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'VA_DCA_TERMINAL_B',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '2401 Ronald Reagan Washington National Airport Access Rd, Arlington, VA 22202',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JFK_T8_GATE_12_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'John F Kennedy International Airport, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'LEWIS_UNI_LRC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 University Pkwy, Romeoville, IL 60446',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_SE_EMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11800 Astoria Blvd, Houston , TX 77089',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_SCHOOL_OF_MEDICINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '160 Convent Ave, New York, NY 10031',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_4680_PARKWAY_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4680 Parkway Dr, Mason, OH 45040',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_BLVD_425',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Washington Blvd, Jersey City, NJ 07310',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_NCH_BEHAVIORAL_PAVILION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '444 Butterfly Gardens Dr, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'FF_PRODFACILITY_CHI1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5370 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_SC_EAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '750 S Halsted St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'MN_PONDVIEW_PLAZA_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5850 Opus Pkwy, Minnetonka, MN 55343',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5221 Gullen Mall, Detroit, MI 48202',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DTW_MCNAMARA_BAGGAGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11050 W G Rogell Dr, Detroit, MI 48242',
            guaranteed: null,
          },
        },
        {
          id: 'FLEX_700_NORTH_LUNCHROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Corporate Grove Dr, Buffalo Grove I, IL 60089',
            guaranteed: null,
          },
        },
        {
          id: 'd0c52794-6b44-43d6-b751-1bf112b6d147',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_BRONX',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3424 Kossuth Ave, Bronx, NY 10467',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_WOODHULL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '760 Broadway, Brooklyn, NY 11206',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_HEART_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MANHATTAN_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '423 E 23rd Street, New York, NY 10010',
            guaranteed: null,
          },
        },
        {
          id: 'JUMP_TRADING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 West Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: '121_W_WACKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '121 W Wacker Dr, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AEROSPACE_CORPORATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2310 El Segundo Blvd, El Segundo, CA 90245',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_FTW3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '15201 Heritage Pkwy, Fort Worth, TX 76177',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RIVERVIEW_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Riverview Plaza, Red Bank, NJ 07701',
            guaranteed: null,
          },
        },
        {
          id: 'ICNC_OFFICE_FRIDGE',
          type: 'DELIVERY',
          operationConfigs: {
            address: '2000 W Fulton, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDA8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8901 Forney Rd, Dallas, TX 75227',
            guaranteed: null,
          },
        },
        {
          id: 'MN_THE_E_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7201 Metro Blvd, Edina, MN 55439',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UW_PHARMACY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '777 Highland Ave, Madison, WI 53705',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_WEILCORNELL_7THFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 E 68th St, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'HINES_VA_578_BUILDING_13',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 5th Ave, Hines, IL 60141',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_T5_BAGGAGE_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'AMAZONGO_WCHICAGO',
          type: 'DELIVERY',
          operationConfigs: {
            address: '600 W Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MADISON_525_JUNCTION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 Junction Rd, Madison, WI 53717',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SDAA_BREAKROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3032 North Harbor Drive, San Diegom, CA 92101',
            guaranteed: null,
          },
        },
        {
          id: 'AMTRAK_UNION_STATION_CONCOURSE_LEFT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '25 S Canal St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MERCH_MART_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 Merchandise Mart Plz, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AMAZON_PIT5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2250 Roswell Dr, Pittsburgh, PA 15205',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_BROOKLYN_METHODIST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '506 6th Street, Brooklyn, NY 11215',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IU_HEALTH_PATHOLOGY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 W 11th Street, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'dd67a65e-b7f6-42ca-bbd6-6f367b24a947',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_RICE_BROCKMAN_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6100 S MAIN ST, Houston , TX 77005',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ST_DAVIDS_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '919 E 32nd St, Austin, TX 78705',
            guaranteed: null,
          },
        },
        {
          id: 'MICHAEL_LEWIS_BREAKROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8900 W 50th street, McCook, IL 60525',
            guaranteed: null,
          },
        },
        {
          id: 'MN_SANNEH_FOUNDATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2090 Conway St, Minneapolis, MN 55104',
            guaranteed: null,
          },
        },
        {
          id: 'NOKIA_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Lucent Ln, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_BURNSVILLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '201 E Nicollet Blvd, Burnsville, MN 55337',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_500_MARYVILLE_CENTRE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '500 Maryville Centre Dr, St Louis, MO 63141',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_DIGNITY_HEALTH_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18400 Avalon Blvd, Carson, CA 90746',
            guaranteed: null,
          },
        },
        {
          id: '123',
          type: 'FRIDGE',
          operationConfigs: {
            address: '456 w 789th St, Minneapolis, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DEPARTMENT_OF_HUD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '451 7th St SW, Washington , DC 20410',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_COMER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5721 S Maryland Ave, Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KAISER_PERMANENTE_ONTARIO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2293 S Vineyard Ave, Ontario, CA 91761',
            guaranteed: null,
          },
        },
        {
          id: 'MN_U_ST_THOMAS_LAW_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Lasalle Ave, Minneapolis, MN 55403',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_LANGSAM_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2911 Woodsive Dr, Cincinnati, OH 45221',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHERN_TRUST_801_S_CANAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S Canal St., Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_UNION_BEER_DISTRIBUTORS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '46 Meadowlands parkway, Secaucus, NJ 07094',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CARS_COM_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '300 S. Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_CAMPUSCENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '402 N Blackford St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'fa29a92b-31bd-4bd7-9e2e-9ac566f43a88',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_BROOKLYN_WEST_QUAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 Bedford Ave, Brooklyn , NY 11210',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_WOODLANDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9250 Pinecroft Dr, Woodlands, TX 77380',
            guaranteed: null,
          },
        },
        {
          id: '1d7eb6dc-611d-4c4b-8bca-f25eefd6fe24',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '261ca7ba-01c3-488c-b971-7d3184194720',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SLB_5950_COURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5950 N Course Dr., Houston , TX 77072',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB_SATELLITE_2_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'OH_VA_COLUMBUS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '420 N James Road, Columbus, OH 43219',
            guaranteed: null,
          },
        },
        {
          id: 'COMMUNITY_FIRST_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5645 W Addison St, Chicago, IL 60634',
            guaranteed: null,
          },
        },
        {
          id: '6522862e-8dbb-4889-b17a-37e304d01b28',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_UTA_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '440 S Nedderman Dr, Arlington, TX 76013',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_USD_KNAUSS_SCHOOL_OF_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5998 Alcala Park, San Diego, CA 92110',
            guaranteed: null,
          },
        },
        {
          id: '75b92b66-fc4e-4e8d-8641-ddf44f61af2c',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS_DMOS_6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13011 T I Blvd, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: 'MN_COMMUNITY_TECHNICAL_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1501 Hennepin Ave, Minneapolis, MN 55403',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LGB_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4100 Donal Douglas Dr, Long Beach, CA 90808',
            guaranteed: null,
          },
        },
        {
          id: '8a2df67b-09dc-4a20-bdb2-d7d0e1752fcb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LENOX_HILL_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 E 77th St, New York, NY 10075',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AIRPORT_CONCOURSE_D',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Airport Blvd, Pittsburgh, PA 15231',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_LONG_BEACH_CITY_COLLEGE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4901 E Carson St, Long Beach, CA 90808',
            guaranteed: null,
          },
        },
        {
          id: 'TEST_CANAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 North Canal Street, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'MCCORMICK_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 S King Drive, Chicago, IL 60616',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_SCRIPPS_ANDERSON_MEDICAL_PAVILLION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9898 Genesee Ave, San Diego, CA 92037',
            guaranteed: null,
          },
        },
        {
          id: '225_W_WASHINGTON_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 W Washington St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '318_W_ADAMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '318 W Adams St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3600 Presidential Blvd, Austin, TX 78719',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_CHRIST_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4440 95th Street, Oak Lawn, IL 60453',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_SIG_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '401 E. City Ave, Bala Cynwyd, PA 19004',
            guaranteed: null,
          },
        },
        {
          id: 'WESTSIDE_RESEARCH_UI_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1747 W Roosevelt Road, Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'CLEVELAND_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '222 N Canal St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '8133de01-4f0b-4375-991b-938a1f1924b0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PRESENCE_SAINT_JOSEPH_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 North Madison Street, Joliet, IL 60435',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HAVAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Hudson St, New York, NY 10013',
            guaranteed: null,
          },
        },
        {
          id: 'BLACKHAWKS_UNITED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1801 W Jackson Blvd, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_GLOBAL_POWER_COMPONENTS_184',
          type: 'FRIDGE',
          operationConfigs: {
            address: '184 W. Oklahoma Ave, Milwaukee, WI 53207',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDA9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3700 San Jacinto Dr, Fort Worth, TX 76116',
            guaranteed: null,
          },
        },
        {
          id: '8152050f-1b27-4aba-9290-2cdf7cabc120',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_BUSCH_STADIUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Clark Ave, St Louis, MO 63102',
            guaranteed: null,
          },
        },
        {
          id: 'e57d6517-ce59-4b4d-8a18-d2d8cea73420',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_SIG_2NDFLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '401 E City Ave, Bala Cynwyd, PA 19004',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CSU_LONG_BEACH_BREEZEWAY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1250 Bellflower Blvd, Long Beach, CA 90840',
            guaranteed: null,
          },
        },
        {
          id: '5TH_AVENUE_STATION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 E 5th Ave, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_KINGWOOD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20000 Kingwood Dr, Kingwood, TX 77339',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_TROY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Main St, Troy, OH 45373',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_OHIO_HEALTH_GRANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 S Grant, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_SOUTHWEST_PILOTS_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DFW_IOC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3550 S 20th Ave, Euless, TX 76039',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ADVENTIST_GLENDALE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1509 Wilson Terrace, Glendale, CA 91206',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_100_BAYVIEW_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Bayview Circle, Newport Beach, CA 92660',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_CAPITAL_HEALTH_HOPEWELL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Capital Way, Pennington, NJ 08534',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_SBD6_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8900 Merrill Avenue, Ontario, CA 91762',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_MEMORIAL_HERMANN_CYPRESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '27800 Northwest Fwy, Cypress , TX 77433',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T2_H1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4300 Glumack Dr, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'ECHO_LOGISTICS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_SHOPS_GRAND_AVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '275 W Wisconsin Ave, Milwaukee, WI 53203',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_UMB_CAMPUS_CENTER_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '621 W. Lombard St., Baltimore, MD 21201',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_KPMG_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 South Hope Street, 11th Floor, Los Angeles, CA 90071',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TWU_DALLAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5500 Southwestern Medical Ave, Dallas, TX 75235',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BSWH_PLANO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4700 Alliance Blvd, Plano, TX 75093',
            guaranteed: null,
          },
        },
        {
          id: '451_N_STATE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '451 N State Street, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_3RD_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7040 s Cottage Grove Ave, Chicago, IL 60619',
            guaranteed: null,
          },
        },
        {
          id: 'SOLDIER_FIELD_UNITED_CLUB',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1410 Museum Campus Dr, Chicago, IL 60605',
            guaranteed: null,
          },
        },
        {
          id: 'WRIGHT_COLLEGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4300 N. Narragansett Ave, Chicago, IL 60634',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_BENTLEY_UNIVERSITY_DANA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '175 Forest St, Waltham, MA 02452',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_CAL_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3434 S Grand Ave, Los Angeles, CA 90089',
            guaranteed: null,
          },
        },
        {
          id: '3040_SALT_CREEK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3040 Salt Creek Lane, Arlington Heights, IL 60005',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_CEWIT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Nicolls Rd, Stony Brook, NY 11794',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_NOTRE_DAME_MARYLAND',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4701 N Charles St, Baltimore, MD 21210',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_KETTERING_HEALTH_HAMILTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '630 Eaton Ave, Hamilton, OH 45013',
            guaranteed: null,
          },
        },
        {
          id: 'G_W_ELECTRIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '305 Crossroads Pkwy, Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'KLUCZYNSKI_FEDERAL_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 S Dearborn St., Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_AMAZON_ONT6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '24208 San Michele Road, Moreno Valley, CA 92551',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_1500_POST_OAK_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 Post Oak Blvd, Houston , TX 77024',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_CLIFTON_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2800 Clifton Ave, Cincinnati, OH 45221',
            guaranteed: null,
          },
        },
        {
          id: 'HIGHLAND_LANDMARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3075 Highland Pky, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: '77557922-c672-4f14-8bae-292acf26213e',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_AIRPORT_AA_BREAKROOM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8000 Essington Ave, Philadelphia, PA 19153',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_COLUMBIA_STMARYS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2301 N Lake Dr., Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_TEXSAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6700 I-10, San Antonio , TX 78201',
            guaranteed: null,
          },
        },
        {
          id: 'PUBLIC_SAFETY_TRAINING_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 N Kilbourn, Chicago, IL 60624',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_G_CONCOURSE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4300 Glumack Dr, St Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'KEHOE_DESIGNS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2555 S Leavitt St., Chicago, IL 60608',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HUDSON_COMMONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '441 9th Avenue, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: '125_S_CLARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '125 S Clark St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_HOWARD_CC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6060 Scholarship Dr, Columbia , MD 21044',
            guaranteed: null,
          },
        },
        {
          id: '7fff0e8f-9f9a-4971-9c39-5d1051b20fda',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MDW2_ENTRANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Emerald Dr, Joliet, IL 60433',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JUILLIARD_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Lincoln Center Plaza, New York, NY 10023',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_DAYTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '405 W Grand Ave, Dayton, OH 45405',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_PROHEALTH_WAUKESHA_MEMORIAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '725 American Ave, Waukesha, WI 53188',
            guaranteed: null,
          },
        },
        {
          id: 'OH_OHIOHEALTH_DOCTORS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5100 West Broad St, Columbus, OH 43228',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NEUBERGER_BERMAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1290 Avenue of the Americas, New York, NY 10104',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_FOUNDATION_MEDICINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11010 Torreyana Rd, San Diego, CA 92121',
            guaranteed: null,
          },
        },
        {
          id: 'MI_GR_TRINITY_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Jefferson Ave SE, Grand Rapids , MI 49503',
            guaranteed: null,
          },
        },
        {
          id: '1d55e1da-ef6a-4dc0-a7c0-eb3126864310',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'JESSE_BROWN_VA_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '820 S. Damen, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '41f764d3-e4d3-4e05-a52f-3c6245f12f41',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_AMAZON_SAT2_3RD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1401 E McCarty Ln, San Marcos, TX 78666',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB_SATELLITE_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RARITAN_BAY_OLD_BRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Hospital Plaza, Old Bridge , NJ 08857',
            guaranteed: null,
          },
        },
        {
          id: '525_VAN_BUREN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '525 W Van Buren St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_ST_DAVIDS_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '901 W Ben White Blvd, Austin , TX 78704',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '31555 US-90, Brookshire, TX 77423',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CVG_CONCOURSE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3087 Terminal Drive, Hebron, KY 41048',
            guaranteed: null,
          },
        },
        {
          id: 'WOODFIELD_PRESERVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '20 N Martingale Rd, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CITY_HARVEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 52nd Street, Brooklyn, NY 11232',
            guaranteed: null,
          },
        },
        {
          id: 'WARREN_BARR_GOLD_COAST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '66 W. Oak St, Chicago, IL 60610',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_101_WOOD_AVENUE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '101 S Wood Ave, Iselin, NJ 08830',
            guaranteed: null,
          },
        },
        {
          id: 'MBX_SYSTEMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 Technology Way, Libertyville, IL 60048',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_WESTCHESTER_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 Woods Rd, Valhalla, NY 10595',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_PARKER_HANNIFIN_IRVINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16666 Von Karmen Ave, Irvine, CA 92606',
            guaranteed: null,
          },
        },
        {
          id: 'ADDISON_PARKS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1776 W Centennial Pl, Addison, IL 60101',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M5',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave., Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MKE_ST_JOSEPH_ASCENSION_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 W Chambers St, Milwaukee, WI 53210',
            guaranteed: null,
          },
        },
        {
          id: 'PA_ST_MARYS_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 Langhorne Newton Rd, Langhorne, PA 19047',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_AMAZON_HOU8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2303 Hurricane Ln, Fresno , TX 77545',
            guaranteed: null,
          },
        },
        {
          id: 'testlocal123',
          type: 'FRIDGE',
          operationConfigs: {
            address: 'test-local-123, Kansas city, IL 01001',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_660_NEWPORT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '660 Newport Center Dr, Newport Beach, CA 92660',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHSHORE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '757 Park Ave W, Highland Park, IL 60035',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_RUTGERS_NEWARK_ROBESON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '350 DR MLK Blvd, Newark, NJ 07102',
            guaranteed: null,
          },
        },
        {
          id: 'CAMPINGWORLD_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '250 Parkway Dr, Lincolnshire, IL 60069',
            guaranteed: null,
          },
        },
        {
          id: 'f2bbc726-f5b4-4f27-b839-96a94b5daeca',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_CCD_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5841 S Maryland Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MSKCC_RESEARCH_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '417 E 68th St, New York, NY 10017',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_17TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4650 N Pulaski Rd, Chicago, IL 60630',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_TRIHEALTH_BETHESDA_CVICU',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10500 Montgomery Rd, Cincinnati, OH 45242',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MET_EMPLOYEE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 5th Ave, New York, NY 10028',
            guaranteed: null,
          },
        },
        {
          id: 'MA_HARVARD_NORTHWEST_LABS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '52 Oxford St, Cambridge, MA 02138',
            guaranteed: null,
          },
        },
        {
          id: 'LURIE_CHILDRENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '225 E Chicago Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOSTON_HARVARD_MEDSCHOOL_COURTYARD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Longwood Ave, Boston, MA 02115',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_411_E_WISCONSIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '411 E Wisconsin Ave, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ST_JOHNS_UNIVERSITY_HENLEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '172-14 Henley Road, Jamacia , NY 11432',
            guaranteed: null,
          },
        },
        {
          id: 'FRIDGE_IN_THE_WILD_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MACYS_STORY_HERALD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '151 W 34th St, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_CHOP_CARDIAC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3401 Civic Center Blvd, Philadelphia, PA 19104',
            guaranteed: null,
          },
        },
        {
          id: '98d63905-2710-49a0-8d95-bdff8b824313',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_JLL_16001_PARK_TEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '16001 Park Ten Place, Houston , TX 77084',
            guaranteed: null,
          },
        },
        {
          id: '550_W_ADAMS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '550 W Adams St, Chicago, IL 60661',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_BRYN_MAWR_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 S Bryn Mawr Avenue, Bryn Mawr, PA 19010',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AIRPORT_CONCOURSE_C',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Airport Blvd, Pittsburgh, PA 15231',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_DIVISIONS_MAINTENANCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 w 5th St, Cincinnati, OH 45202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_ORANGE_COUNTY_GOV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 W. Civic Center Drive, Santa Ana, CA 92701',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHBROOK_COURT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2171 Lake Cook Rd, Northbrook, IL 60062',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_WSP',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Pennsylvania Plz, New York, NY 10199',
            guaranteed: null,
          },
        },
        {
          id: '04ee5f1a-0b6b-4ac3-b676-cf563df7d899',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'SCHAUMBURG_DISTRICT_LIBRARY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '130 S Roselle Rd, Schaumburg, IL 60193',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_SAT_CONCOURSE_B',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9800 Airport Blvd, San Antonio , TX 78216',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HOME_DEPOT_ONTARIO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5655 Ontario Mills Pkwy, Ontario, CA 91764',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_LYONS_VA_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '151 Knollcroft Rd, Lyons, NJ 07939',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_JUILLIARD_SCHOOL_ROSE_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '70 Lincoln Center Plaza, New York, NY 10023',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_HOUSTON_METHODIST_CLEAR_LAKE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '18300 Houston Methodist Dr, Houston, TX 77059',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_TEXAS_STATE_DEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '260 Old Main Dr, San Marcos, TX 78666',
            guaranteed: null,
          },
        },
        {
          id: '1431_OPUS_PL_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1431 Opus Pl, Downers Grove, IL 60515',
            guaranteed: null,
          },
        },
        {
          id: 'DC_VOICE_OF_AMERICA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 Independence Ave SW, Washington , DC 20237',
            guaranteed: null,
          },
        },
        {
          id: '321_NORTH_CLARK_ST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '321 N Clark St, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'OHARE_3K_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 O'Hare Way, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_ROYAL_OAK_BASEMENT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3601 W 13 Mile Rd, Royal Oak, MI 48073',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_FOUND_STUDY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '569 Lexington Ave, New York, NY 10022',
            guaranteed: null,
          },
        },
        {
          id: '6308dfd6-d2a9-4c26-bd1c-cbbdaf6895fe',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_ALSTOM_TRANSPORT_INC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '402 Pan Am Rd, Jamaica, NY 11430',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CVG_CONCOURSE_A',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3087 Terminal Drive, Hebron, KY 41048',
            guaranteed: null,
          },
        },
        {
          id: 'CA_SD_NORTHROP_GRUMMAN_SPECTRUM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9356 Spectrum Center Blvd, San Diego, CA 92123',
            guaranteed: null,
          },
        },
        {
          id: 'OH_DAY_KETTERING_HEALTH_SOIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3535 Pentagon Blvd, Beavercreek, OH 45431',
            guaranteed: null,
          },
        },
        {
          id: 'WOODFIELD_CORP_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 N Martingale Rd, Schaumburg, IL 60173',
            guaranteed: null,
          },
        },
        {
          id: 'WGN_TV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2501 Bradley Pl, Chicago, IL 60618',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_WESTERN_PSYCHIATRIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: "3811 O'Hara St, Pittsburgh, PA 15213",
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_16TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5151 N Milwaukee Ave, Chicago, IL 60630',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_SC_JOHNSON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8310 S 16th Street, Sturtevant, WI 53177',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_HNE1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2205 Lincoln Hwy, Edison, NJ 08817',
            guaranteed: null,
          },
        },
        {
          id: 'GLENOAKS_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '701 Winthrop Ave, Glendale Heights, IL 60139',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_330_E_KILBOURN_NEW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 E Kilbourn Ave, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_ST_LUKES_WOODLANDS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '17200 St Lukes Way, The Woodlands , TX 77384',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6706 Conestoga Trail, Loves Park, IL 61111',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_GM_INNOVATION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13201 McCallen Pass, Austin , TX 78753',
            guaranteed: null,
          },
        },
        {
          id: 'b5663daf-57a1-4d63-8f17-797c3a1254b0',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_HARRIS_HALL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3607 Trousdale Parkway, Los Angeles, CA 90089',
            guaranteed: null,
          },
        },
        {
          id: 'METLIFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '177 S Commons Dr., Aurora, IL 60504',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_DTW_COKE_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11050 W G Rogell Dr #602, Detroit, MI 48242',
            guaranteed: null,
          },
        },
        {
          id: 'MI_ANN_ARBOR_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2215 Fuller Road, Ann Arbor, MI 48105',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_NWQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2025 E Newport Ave, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_VA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 S Lancaster Rd, Dallas, TX 75216',
            guaranteed: null,
          },
        },
        {
          id: 'FLAB_TEST_6',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton, Chicago, IL 60621',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_WAYNE_STATE_400_MACK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '400 Mack, Detroi, MI 48201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_RIVERSIDE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '150 Riverside Drive, New York, NY 10024',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MINNEAPOLIS_M_HEALTH_FAIRVIEW_MAPLE_GROVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '14500 99th Avenue North, Maple Grove, MN 55369',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_AURORA_ST_LUKE_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2900 W Oklahoma Ave, Milwaukee, WI 53215',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_735_N_WATER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '735 N Water St, Milwaukee, WI 53202',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_LONE_STAR_COLLEGE_SYSTEM_OFFICE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5000 Research Forest Dr, Woodlands, TX 77381',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HEALTH_FORT_WORTH_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 Pennsylvania Ave, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CUNY_LAW',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2 Ct Square E, Long Island City, NY 11101',
            guaranteed: null,
          },
        },
        {
          id: 'US_CELLULAR_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8430 W Bryn Mawr Ave, Chicago, IL 60631',
            guaranteed: null,
          },
        },
        {
          id: 'c039f0d5-7756-4aa2-85d2-d2938f3e8d35',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DENTON_WOMENS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3000 N I-35, Denton, TX 76201',
            guaranteed: null,
          },
        },
        {
          id: 'b280190a-4aa4-4806-a23c-31613b9864a5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_COREWELL_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '26901 Beaumont Blvd, Southfield, MI 48033',
            guaranteed: null,
          },
        },
        {
          id: 'DELL_MACHINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: "Farmer's Fridge F-310, Chicago, IL 60612",
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NEW_SCHOOL_66_W_12TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '66 west 12th st, New York, NY 10011',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_KENNEDY_KREIGER_INSTITUTE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '707 N Broadway, Baltimore, MD 21205',
            guaranteed: null,
          },
        },
        {
          id: 'LOYOLA_MEDICAL_BURRRIDGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6800 N Frontage Rd, Burr Ridge, IL 60527',
            guaranteed: null,
          },
        },
        {
          id: 'MUSEUM_CONTEMPORARY_ART',
          type: 'FRIDGE',
          operationConfigs: {
            address: '220 E Chicago Ave, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_WEST_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 10th Avenue, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: '69_W_WASHINGTON_TENANT_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '69 W Washington St., Chicago, IL 60602',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_EWR8',
          type: 'FRIDGE',
          operationConfigs: {
            address: '698 US-46, Teterboro, NJ 07608',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_LAUNDRY_CAPITAL_QUEENS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '48-03 69th St, Queens , NY 11377',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_KLINGENSTEIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1176 5th Avenue, New York, NY 10029',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_MOUNTAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4849 W Illinois Ave, Dallas, TX 75211',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DDF1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1303 Ridgeview Dr, Lewisville, TX 75057',
            guaranteed: null,
          },
        },
        {
          id: 'ef99190b-0cd1-4f4b-a1bd-77b49015e5eb',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'ULTA_BEAUTY_HQ',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 Remington Blvd, Bolingbrook, IL 60440',
            guaranteed: null,
          },
        },
        {
          id: 'KEMPER_LAKES_BUSINESS_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Corporate Dr, Lake Zurich, IL 60047',
            guaranteed: null,
          },
        },
        {
          id: 'DUPAGE_HSC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '425 Fawell Blvd, Glen Ellyn, IL 60137',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HCA_MCKINNEY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4500 Medical Center Drive, McKinney, TX 75069',
            guaranteed: null,
          },
        },
        {
          id: 'DC_DEP_GEN_SERVICES_2ND_FLOOR_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3924 Minnesota Ave NE, Washington, DC 20019',
            guaranteed: null,
          },
        },
        {
          id: 'CHICAGO_POLICE_14TH_DISTRICT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2150 N California Ave, Chicago, IL 60647',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TWU',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6700 Fannin St, Houston, TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'ea8c6fbf-4441-4d54-b55b-37532cff0cb9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: '1245_DIEHL_ROAD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1245 Diehl Road, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_PLACENTIA_LINDA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 N Rose Dr, Placentia, CA 92870',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_LUTHERAN_GENERAL_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1775 Dempster Street, Park Ridge, IL 60068',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_JPS_BEH_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1500 SOUTH MAIN STREET, Fort Worth, TX 76104',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_JPL_CAFE_190',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4800 Oak Grove Dr, Pasadena, CA 91109',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_SOUTHWEST_TECH_OPS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2832 Shorecrest Dr, Dallas, TX 75209',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TWU_MARKET',
          type: 'FRIDGE',
          operationConfigs: {
            address: '304 Administration Dr, Denton, TX 76209',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_UT_DENTISTRY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '7500 Cambridge St, Houston, TX 77054',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_DALLAS_COLLEGE_BROOKHAVEN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3939 Valley View Lane, Farmers Branch, TX 75244',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_EXCHANGE_PLACE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '53 State St, Boston, MA 02109',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MOUNT_SINAI_LUKES_OR_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 Amsterdam Ave, New York, NY 10019',
            guaranteed: null,
          },
        },
        {
          id: 'SWEDISH_COVENANT_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5140 N California Ave, Chicago, IL 60625',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_TYSON_FOODS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 W Kingsbury St., Seguin, TX 78155',
            guaranteed: null,
          },
        },
        {
          id: 'MDW_CENTRAL_MARKET_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Cicero Ave, Chicago, IL 60638',
            guaranteed: null,
          },
        },
        {
          id: 'OH_COL_NATIONWIDE_ARENA_205',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200w Nationwide Blvd, Columbus, OH 43215',
            guaranteed: null,
          },
        },
        {
          id: 'SARGENT_LUNDY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 E Monroe St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_SAN_JACINTO_CC_NORTH_N17',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5800 Uvalde Rd, Houston , TX 77049',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_ROBBINSVILLE_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 New Canton Way, Robbinsville, NJ 08619',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_MONTCLAIR_UNIVERSITY_FELICIANO_BUSINESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Normal Avenue, Montclair, NJ 07043',
            guaranteed: null,
          },
        },
        {
          id: 'ADVOCATE_CONDELL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '801 S Milwaukee Ave, Libertyville, IL 60048',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_JERSEY_SHORE_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1945 NJ-33, Neptune City, NJ 07753',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PHI_JEFFERSON_FRANKFORD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4900 Frankford Ave, Philadelphia, PA 19124',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_COLGATE_PALMOLIVE_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '909 River Rd, Piscataway, NJ 08854',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_BAYLOR_MEDICINE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Baylor Plaza, Houston , TX 77030',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_MARQUETTE_RAYNOR_LIBRARIES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1355 w Wisconsin Ave, Milwaukee, WI 53233',
            guaranteed: null,
          },
        },
        {
          id: 'IND_RILEY_CHILDRENS_2ND_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '705 Riley Hospital Dr, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: 'UIC_ART_ARCHITECTURE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '845 W Harrison St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CMH_CONCOURSE_A',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4600 International Gateway, Columbus, OH 43219',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_STONY_BROOK_ADMIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '221 Administration, Stony Brook, NY 11794-1002',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_GLOBE_LIFE_FIELD',
          type: 'FRIDGE',
          operationConfigs: {
            address: '734 Stadium Dr, Arlington, TX 76011',
            guaranteed: null,
          },
        },
        {
          id: 'VE_TEST_UPGRADE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W. Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'IND_MARKET_TOWER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 West Market St., Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_AMAZON_DAL3_3RD_FLOOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1301 Chalk Hill Road, Dallas, TX 75211',
            guaranteed: null,
          },
        },
        {
          id: 'AMA_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '330 N Wabash Ave., Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'NORTHWESTERN_UNIVERSITY_1201_DAVIS_ST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 Davis St., Evanston, IL 60201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MASPETH_FEDERAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '56-05 69th Street, Maspeth, NY 11378',
            guaranteed: null,
          },
        },
        {
          id: 'IND_CROWN_POINT_FRANCISCAN_HEALTH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1201 S Main Street  , Crown Point, IN 46307',
            guaranteed: null,
          },
        },
        {
          id: 'MORTON_SALT_FLOOR_29',
          type: 'FRIDGE',
          operationConfigs: {
            address: '444 W Lake St, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'IND_U_CHICAGO_MED_CROWN_POINT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10855 Virginia Street, Crown Point, IN 46307',
            guaranteed: null,
          },
        },
        {
          id: 'TAKEDA_PHARMA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1 Takeda Pkwy, Deerfield, IL 60015',
            guaranteed: null,
          },
        },
        {
          id: 'GROUPON_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 W Chicago Ave, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_HEALTH_SCIENCES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3225 Eden Ave, Cincinnati, OH 45220',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_CHOP_WANAMAKER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 E Penn Square, Philadelphia, PA 19107',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_AMAZON_STL4',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '3050 Gateway Commerce Center Dr S, Edwardsville, IL 62025',
            guaranteed: null,
          },
        },
        {
          id: 'MOSAIC_SALES_SOLUTIONS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '320 N Elizabeth St, Chicago, IL 60607',
            guaranteed: null,
          },
        },
        {
          id: 'UCMED_SKYLOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5700 S Maryland Ave., Chicago, IL 60637',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_UNI_OF_CINCINNATI_MARIAN_SPENCER_HALL_VESTIBULE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2911 Scioto Ln, Cincinnati, OH 45219',
            guaranteed: null,
          },
        },
        {
          id: 'MO_STL_WASHU_ZETCHER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2515 Wydown St, St Louis, MO 63105',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_EAST_HEALTH_CENTER_GOVE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Gove St, Boston, MA 02128',
            guaranteed: null,
          },
        },
        {
          id: 'IND_IUPUI_LAW_SCHOOL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '530 W New York St, Indianapolis, IN 46202',
            guaranteed: null,
          },
        },
        {
          id: '541_N_FAIRBANKS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '541 North Fairbanks Court, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_Q2_MAIN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10355 Pecan Park Blvd, Austin , TX 78729',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TMC_INNOVATION_INCUBATOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2450 Holcombe Blvd, Houston, TX 77021',
            guaranteed: null,
          },
        },
        {
          id: 'LORETTO_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '645 S Central Ave, Chicago, IL 60644',
            guaranteed: null,
          },
        },
        {
          id: 'S_C_ELECTRIC_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6601 N Ridge Blvd, Chicago, IL 60626',
            guaranteed: null,
          },
        },
        {
          id: '70c4e4ec-e526-4f88-bc11-c588faf4662a',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_UM_MEDCENTER_DOWNTOWN_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '22 S Greene St, Baltimore, MD 21202',
            guaranteed: null,
          },
        },
        {
          id: 'EE_TEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2849 Eastlake Ave E, Seattle, WA 98102',
            guaranteed: null,
          },
        },
        {
          id: 'KLUCZYNSKI_FEDERAL_BUILDING_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '230 S. Dearborn, Chicago, IL 60604',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_ABE8_FRONT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '309 Cedar Ln, Florence, NJ 08518',
            guaranteed: null,
          },
        },
        {
          id: '600_N_MCCLURG',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 N McClurg Court, Chicago, IL 60611',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HUTCHINS_CONVENTION_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '650 S Griffin St, Dallas, TX 75202',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_WATT_PLAZA_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1875 Century Park E, Los Angeles, CA 90067',
            guaranteed: null,
          },
        },
        {
          id: 'AMAZON_MDW9',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2865 Duke Pkwy, Naperville, IL 60563',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_AIRPORT_GATE_C17_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5300 Riverside Dr, Cleveland, OH 44135',
            guaranteed: null,
          },
        },
        {
          id: 'MD_BAL_UM_MEDCENTER_MIDTOWN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '827 Linden Ave, Baltimore, MD 21201',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HOFSTRA_UNIVERSITY_STUDENT_COMMUTER_LOUNGE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 Hostra University, Hempstead, NY 11549',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_FARMINGTON_HILLS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '28050 Grand River Ave, Farmington Hills, MI 48336',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_COLUMBIA_UNIVERSITY_MED_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '50 Haven Ave, New York, NY 10032',
            guaranteed: null,
          },
        },
        {
          id: 'TX_HOU_TOSHIBA_HEV',
          type: 'FRIDGE',
          operationConfigs: {
            address: '13131 W Little York Rd, Houston , TX 77041',
            guaranteed: null,
          },
        },
        {
          id: 'MAINSTAY_SUITES_HOFFMAN_ESTATES',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2875 Greenspoint Pkwy, Hoffman Estates, IL 60169',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_A2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Road, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M18',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Ave, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'FF_MW_MARKETING_SUBSIDIZED',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2000 W Fulton St, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_BELL_FLIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4151 Amon Carter Blvd, Fort Worth, TX 76155',
            guaranteed: null,
          },
        },
        {
          id: 'TX_SAN_METHODIST_STONE_OAK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1139 E Sonterra Blvd., San Antonio , TX 78258',
            guaranteed: null,
          },
        },
        {
          id: 'EF_TEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1901 N Hoyne, Chicago, IL 60647',
            guaranteed: null,
          },
        },
        {
          id: 'e7dda542-ac3b-4807-918d-e5011ec1f821',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_4705_DUKE_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4705 Duke Dr, Mason, OH 45040',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_MANULIFE_PLAZA',
          type: 'FRIDGE',
          operationConfigs: {
            address: '515 South Figueroa Street, Los Angeles, CA 90071',
            guaranteed: null,
          },
        },
        {
          id: 'HERE_TENANT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 N Riverside Plz, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: '180_N_LASALLE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '180 N LaSalle St, Chicago, IL 60601',
            guaranteed: null,
          },
        },
        {
          id: 'ECHO_LOGISTICS_2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '600 West Chicago Avenue, Chicago , IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'MKE_UWM_STUDENT_UNION',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2200 E Kenwood Blvd, Milwaukee, WI 53211',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_CHILDRENS_SPECIALTY_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '55 Madison Ave, Morristown, NJ 07960',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_INSTRUMENTS_HQ_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '12500 T.I. Blvd, Dallas, TX 75243',
            guaranteed: null,
          },
        },
        {
          id: 'AMWINS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 S LaSalle St, Chicago, IL 60603',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MSKCC_MEMORIAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1275 York Ave, New York, NY 10065',
            guaranteed: null,
          },
        },
        {
          id: 'PHI_LANKENAU_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '100 E Lancaster Ave., Wynnewood, PA 19606',
            guaranteed: null,
          },
        },
        {
          id: 'SILVER_CROSS_HOSPITAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1900 Silver Cross Blvd, New Lenox, IL 60451',
            guaranteed: null,
          },
        },
        {
          id: 'IND_SIMON_MUSIC_SCHOOL_BLOOMINGTON',
          type: 'FRIDGE',
          operationConfigs: {
            address: '200 S Jordan Ave, Bloomington, IN 47405',
            guaranteed: null,
          },
        },
        {
          id: 'ab84ef0a-0374-4da6-b854-8453dd08be77',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MN_9800_HEALTHCARE_LANE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9700 Healthcare Ln, Minnetonka, MN 55343',
            guaranteed: null,
          },
        },
        {
          id: 'TX_AUS_USPS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8225 Cross Park Dr, Austin , TX 78710',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_EWR_TB_1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3 Brewster Rd, Newark, NJ 07114',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_700_S_FLOWER_ST_RIGHT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Flower St, Los Angeles, CA 90017',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_TEXAS_HEALTH_DALLAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '8200 Walnut Hill Ln, Dallas, TX 75231',
            guaranteed: null,
          },
        },
        {
          id: 'NJ_AMAZON_TEB9_BACK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '601 Randolph Rd, Somerset, NJ 08873',
            guaranteed: null,
          },
        },
        {
          id: 'AURORA_UNIVERSITY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1400 Marseillaise Pl, Aurora, IL 60506',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HYUNDAI_MOTOR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10550 Talbert Avenue, Fountain Valley, CA 92708',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CLEVELAND_HEIGHTS_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '10 Severance Cir, Cleveland Heights, OH 44118',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_PASSAVANT_MCCANDLESS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '9100 Babcock Blvd., Pittsburgh, PA 15237',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ST_ELIZABETH_FLORENCE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '4900 Houston Rd, Florence , KY 41042',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HEALTH_HARLEM',
          type: 'FRIDGE',
          operationConfigs: {
            address: '506 Lenox Ave, New York, NY 10037',
            guaranteed: null,
          },
        },
        {
          id: '111_N_CANAL',
          type: 'FRIDGE',
          operationConfigs: {
            address: '111 N Canal Street, Chicago, IL 60606',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CIN_ST_ELIZABETH_FORT_THOMAS',
          type: 'FRIDGE',
          operationConfigs: {
            address: '85 N Grand Ave, Fort Thomas, KY 41075',
            guaranteed: null,
          },
        },
        {
          id: '3e911e21-bee0-4074-bd35-865ea3c1bca5',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_TRINITY_ANN_ARBOR_REICHERT',
          type: 'FRIDGE',
          operationConfigs: {
            address: '5333 McAuley Drive Suite 1007, Ypsilanti, MI 48197',
            guaranteed: null,
          },
        },
        {
          id: 'UNITED_AIRLINES_CHIRR',
          type: 'FRIDGE',
          operationConfigs: {
            address: '11555 W Touhy Ave, Chicago, IL 60666',
            guaranteed: null,
          },
        },
        {
          id: 'bdc34c09-3b69-4ef0-a947-794154b7fea3',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CAPGROUP_333_HOPE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '333 S Hope St, Los Angeles, CA 90071',
            guaranteed: null,
          },
        },
        {
          id: '7_ELEVEN_KINGSBURY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '645 N Kingsbury, Chicago, IL 60654',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_UPMC_MONTEFIORE_4TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '3459 5th Ave, Pittsburgh, PA 15213',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_HUNTINGTON_LOBBY',
          type: 'FRIDGE',
          operationConfigs: {
            address: '270 Park Avenue, Huntington, NY 11743',
            guaranteed: null,
          },
        },
        {
          id: 'OH_CLE_CUYAHOGA_CC_EASTERN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '700 Carnegie Ave, Cleveland, OH 44115',
            guaranteed: null,
          },
        },
        {
          id: 'RUSH_HOSPITAL_CAFE',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1653 W Congress Pkwy, Chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_MACYS_W_34TH',
          type: 'FRIDGE',
          operationConfigs: {
            address: '151 W 34th St, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_CITY_TECH_ACADEMIC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '285 Jay St, Brooklyn, NY 11201',
            guaranteed: null,
          },
        },
        {
          id: 'ef43132f-bce6-4784-8988-c3dbcb4a46b1',
          type: 'FRIDGE',
          operationConfigs: {
            address: '2008 W Fulton St, chicago, IL 60612',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_CRYPTO_ARENA_SUITE_LEVEL_A2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1111 S Figueroa St, Los Angeles, CA 90015',
            guaranteed: null,
          },
        },
        {
          id: 'IND_CIRCLE_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '49 W Maryland St, Indianapolis, IN 46204',
            guaranteed: null,
          },
        },
        {
          id: 'FF_MARKETING_EVENT_TWO',
          type: 'FRIDGE',
          operationConfigs: {
            address: '226 W 26th Street, New York, NY 10001',
            guaranteed: null,
          },
        },
        {
          id: 'UC_CHICAGO_ORLAND_PARK',
          type: 'FRIDGE',
          operationConfigs: {
            address: '14290 S La Grange Rd, Orland Park, IL 60462',
            guaranteed: null,
          },
        },
        {
          id: 'ORD_T5_M9',
          type: 'FRIDGE',
          operationConfigs: {
            address: "10000 W O'Hare Dr, Chicago, IL 60666",
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'PA_PIT_AMAZON_PIT2',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1200 Westport Rd, Imperial, PA 15126',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_USC_SOTO_BUILDING',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1845 N Soto St, Los Angeles, CA 90033',
            guaranteed: null,
          },
        },
        {
          id: 'NYC_NYP_LOWERMANHATTAN',
          type: 'FRIDGE',
          operationConfigs: {
            address: '170 William St., New York, NY 10038',
            guaranteed: null,
          },
        },
        {
          id: 'MN_MSP_T1_C18',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              'Minneapolis Saint Paul International Airport, Saint Paul, MN 55111',
            guaranteed: null,
          },
        },
        {
          id: 'MI_DET_BEAUMONT_TROY_WEST',
          type: 'FRIDGE',
          operationConfigs: {
            address: '44201 Dequindre Rd, Troy, MI 48085',
            guaranteed: null,
          },
        },
        {
          id: 'CA_LA_HARBOR_UCLA_MEDICAL_CENTER',
          type: 'FRIDGE',
          operationConfigs: {
            address: '1000 W Carson St, Torrance, CA 90502',
            guaranteed: null,
          },
        },
        {
          id: 'MA_BOS_MARKFORGED_INC',
          type: 'FRIDGE',
          operationConfigs: {
            address: '60 Tower Rd, Waltham, MA 02541',
            guaranteed: null,
          },
        },
        {
          id: 'TX_DAL_HEALTH_SOUTHWEST_BUILDING_II',
          type: 'FRIDGE',
          operationConfigs: {
            address: '6317 Harris Pkwy, Fort Worth, TX 76132',
            guaranteed: null,
          },
        },
        {
          id: 'IND_AIRPORT_BAGGAGE_CLAIM',
          type: 'FRIDGE',
          operationConfigs: {
            address:
              '7800 Col. H. Weir Cook Memorial Dr, Indianapolis, IN 46241',
            guaranteed: null,
          },
        },
      ],
    },
  },
};
